/* a-basic-input-text--xs */
.a-basic-input-text--xs {
	width: $form-width-xs;

	// we are after XL
	.a-basic-input-text--xl + & {
		margin-left: calc(305px * 0.039);
		float: right;

		@include mq(tablet) {
			margin-left: 10px;
		}
	}

	.l-form-general & {
		width: 100%;
		float: none;
	}

	// Exception vesNumberPlate
	.m-ves-number-plate & {
		width: 100%;

		> input {
			text-transform: uppercase;
			text-align: center;
			padding: 5px 15px 5px 6px;
		}
	}

	// Exception vesNumberPlate
	.l-form-general .m-ves-number-plate & {
		width: 100%;

		> input {
			text-transform: uppercase;
			text-align: center;
			padding: 0 5px;
			height: 42px;
		}
	}

	// exception for l-form--cardamage
	.l-form--cardamage-map & {
		width: 70px;
	}

	.l-form--cardamage-proof & {
		width: 70px;
	}

	> input {
		padding-right: 0;

		.l-form-general & {
			@include mq(tablet) {
				padding-right: 35px;
			}
		}

		& + .js-clear-button {
			display: none;
		}
	}
}