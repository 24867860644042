.m-ves-cluster-box {
	background: $color-secondary-6;
	width: 100%;
	min-height: 175px;
	margin: 0 0 20px 0;
	position: relative;

	@include mq(tablet) {
		height: 100%;
		min-height: 215px;
		max-width: calc(50% - 10px);
		flex: 1 0 calc(50% - 10px);
		margin: 0 20px 20px 0;

		&:nth-child(2n) {
			margin-right: 0;
		}
	}

	.mm-header {
		padding: 15px;

		@include mq(tablet) {
			padding: 20px;
		}

		a {
			@include milo-slab-bold($font-size-18, 24px);
			text-decoration: none;
			outline: none;
			margin-left: 17px;

			.h-roboto & {
				font-family: 'Roboto Bold', sans-serif;
			}

			@include mq(tablet) {
				@include milo-slab-bold($font-size-28, 34px);
				margin-left: 14px;

				.h-roboto & {
					font-family: 'Roboto Bold', sans-serif;
				}
			}

			&:hover {
				text-decoration: underline;
			}

			&:before {
				content: "";
				position: absolute;
				top: 5px;
				left: 1px;
				@include main-icon-arrow-right-black;

				@include mq(tablet) {
					top: 16px;
				}
			}
		}

		.mm-headline {
			padding: 0;
			display: inline;
		}
	}

	.mm-figure {
		margin: 0;

		@include mq(tablet) {
			max-width: 453px;
			text-align: center;
		}

		> img {
			position: absolute;
			width: 100%;

			@include mq(tablet) {
				right: 0;
				bottom: 0;
				width: auto;
				max-width: 453px;
			}
		}
	}

	.mm-main {
		width: 100%;
		height: 100%;
		display: block;
		z-index: 0;

		@include mq(tablet) {
			position: absolute;
			max-height: 140px;
			bottom: 0;

		}

		> section {

			&.mm-img-box {
				display: none;

				@include mq(tablet) {
					display: block;
				}
			}

			&.mm-content-box {
				width: 100%;
				height: 100%;
				padding: 15px;

				@include mq(tablet) {
					width: auto;
					height: auto;
					padding: 0 20px 20px;
					@include absolute($top: 0, $left: 0);
				}
			}
		}
	}

	.mm-content {
		width: 100%;
		height: 100%;
		display: block;
		overflow: hidden;

		.a-basic-btn {
			margin-bottom: 5px;
			display: block;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}