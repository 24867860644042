.m-basic-upload {

	&.is-file-only {
		margin-top: 10px;
		height: auto;
		display: block;

		.l-form-general & {
			margin-top: 0;
		}
	}

	// ***
	// start exceptions .m-basic-upload
	// ***

	// exception .l-form
	.l-form & {
		&:not(.is-file-only) {
			margin-bottom: 0;
		}
	}

	// exception .m-basic-calculator-content-box
	.m-basic-calculator-content-box &:not(.m-basic-upload--noflex) {
		position: relative;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		width: 100%;
		max-width: 360px;

		@include mq(desktop) {
			max-width: none;
		}
	}

	// exception .m-basic-calculator-content-box
	.m-basic-calculator-content-box .mm-tile-container .mm-row-content--left > & {
		@include mq(desktop) {
			flex-flow: row nowrap;
		}
	}

	// exception .m-basic-calculator-content-box .mm-upload-container
	.m-basic-calculator-content-box > .mm-upload-container > .mm-row > .mm-row-content--left > & {

		max-width: 360px;

		// keep a free space for the validation icons
		@include mq(tablet) {
			max-width: 250px;
		}

		@include mq(desktop) {
			max-width: 360px;
		}
	}

	.m-basic-calculator-content-box .mm-row--block & {
		display: block;
	}

	// exception .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration & {
		position: relative;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		width: 100%;
		max-width: 360px;

		@include mq(desktop) {
			max-width: none;
		}
	}

	.m-ves-rs-contentbox--damagedeclaration .ll-item-container.is-desk-left-aligned & {
		flex-flow: column nowrap;
	}

	.m-ves-rs-contentbox--damagedeclaration .mm-row--center & {
		width: auto;
		min-width: 190px;
		transition: padding $form-animation ease;

		@include if-parent('.mm-row--center.is-check.is-error') {
			padding-top: 40px;

			> .ll-check {
				@include mq(desktop) {
					right: -50px;
				}
			}
		}
	}

	// exception .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration .mm-upload-container .mm-row-content--left > &,
	.m-ves-rs-contentbox--damagedeclaration .mm-tile-container .mm-row-content--left > & {

		@include mq(desktop) {
			flex-flow: row nowrap;
		}
	}

	// exception .m-ves-rs-contentbox--damagedeclaration .mm-upload-container
	.m-ves-rs-contentbox--damagedeclaration .mm-upload-container .mm-row-content--left > & {
		@include mq(desktop) {
			max-height: 40px;
			align-items: flex-end;
		}
	}


	.m-basic-upload.is-file-only + &.is-clone {
		> div {
			&:not(.is-no-show) {
				input {
					display: none; //Input variant active for upload needs to be hidden to not validate
					visibility: hidden;
				}
			}
		}
	}

	// ***
	// end exceptions .m-basic-upload
	// ***

	> div {

		@include if-parent('.m-basic-upload.is-file-only') {
			&.is-no-show {
				height: 0;

				> .a-basic-btn {
					opacity: 0;
					pointer-events: none;
				}
			}
		}

		&.mm-error {
			display: none;
			margin-bottom: 5px;
			padding-left: 7px;

			@include if-parent('.m-basic-upload.is-error.is-check') {
				display: inline-block;
			}

			// exception .m-my-adac-dashboard-box-layer
			.m-my-adac-dashboard-box-layer & {
				padding-left: 0;
			}
		}

		// ***
		// start exceptions .m-basic-upload > div
		// ***

		// exception .m-basic-calculator-content-box
		// exception .m-ves-rs-contentbox--damagedeclaration
		.m-basic-calculator-content-box .mm-tile-container .mm-row-content--left > & {
			height: 38px;

			@include mq(desktop) {
				height: 0;
			}

			&:first-child {
				max-width: 210px;

				@include mq(desktop) {
					width: auto;
					max-width: none;
				}
			}

			&.ll-check {
				width: 0;

				&.is-check {
					width: 22px;
					height: 22px;
				}
			}

			&.ll-error-msg {
				opacity: 0;
			}
		}

		.m-basic-calculator-content-box .l-form .ll-row .ll-item-container & {
			height: 0;

			&.ll-error-msg {
				width: 0;
				max-width: calc(100% - 30px);
				text-align: left;
			}

			&.ll-check {
				@include absolute($top: -15px, $right: 5px);
				margin: 3px 0 0 0;
				width: 22px;
				height: 22px;
				visibility: hidden;
				opacity: 0;
				transition: all $form-animation ease;
			}
		}

		.m-basic-calculator-content-box .l-form .ll-row.is-check.is-error .ll-item-container & {
			height: auto;

			&.ll-error-msg {
				width: auto;
				text-align: left;
			}

			&.ll-check {
				height: 22px;
				opacity: 1;
				visibility: visible;
				@include main-icon-close-red;
			}
		}



		.m-ves-rs-contentbox--damagedeclaration .mm-tile-container .mm-row-content--left > & {
			height: 38px;

			@include mq(desktop) {
				height: 0;
			}

			&:first-child {
				max-width: 210px;

				@include mq(desktop) {
					width: auto;
					max-width: none;
				}
			}

			&.ll-check {
				width: 0;

				&.is-check {
					width: 22px;
					height: 22px;
				}
			}

			&.ll-error-msg {
				opacity: 0;
			}
		}

		.m-ves-rs-contentbox--damagedeclaration .mm-upload-container .mm-row-content--left > & {

			&:first-child {
				max-width: 210px;

				@include mq(desktop) {
					width: auto;
					max-width: none;
				}
			}

			@include mq(desktop) {
				height: 0;
			}

			&.ll-check {
				width: 0;

				&.is-check {
					width: 22px;
					height: 22px;
				}
			}

			&.ll-error-msg {
				opacity: 0;
			}
		}

		// exception .m-ves-rs-contentbox--damagedeclaration
		.m-ves-rs-contentbox--damagedeclaration > .mm-additional-cost & {
			height: auto;
		}

		.m-ves-rs-contentbox--damagedeclaration .mm-row--center & {
			height: 0;

			&.ll-error-msg {
				width: auto;
				max-width: calc(100% - 30px);
				text-align: left;
			}

			&.ll-check {
				@include absolute($top: -15px, $right: 5px);
				margin: 3px 0 0 0;
				width: 22px;
				height: 22px;
				visibility: hidden;
				opacity: 0;
				transition: all $form-animation ease;
			}
		}

		.m-ves-rs-contentbox--damagedeclaration .mm-row--center.is-check.is-error & {
			height: auto;

			&.ll-error-msg {
				width: auto;
				text-align: left;
			}

			&.ll-check {
				height: 22px;
				opacity: 1;
				visibility: visible;
				@include main-icon-close-red;
			}
		}

		.m-ves-rs-contentbox--damagedeclaration .l-form .ll-row .ll-item-container & {
			height: 0;

			&.ll-error-msg {
				width: 0;
				max-width: calc(100% - 30px);
				text-align: left;
			}

			&.ll-check {
				@include absolute($top: -15px, $right: 5px);
				margin: 3px 0 0 0;
				width: 22px;
				height: 22px;
				visibility: hidden;
				opacity: 0;
				transition: all $form-animation ease;
			}
		}

		.m-ves-rs-contentbox--damagedeclaration .l-form .ll-row.is-check.is-error .ll-item-container & {
			height: auto;

			&.ll-error-msg {
				width: auto;
				text-align: left;
			}

			&.ll-check {
				height: 22px;
				opacity: 1;
				visibility: visible;
				@include main-icon-close-red;
			}
		}

		.m-ves-rs-contentbox--damagedeclaration .l-form .ll-row .ll-item-container.is-desk-left-aligned & {
			&.ll-check {
				top: 0;
			}
		}

		.m-ves-rs-contentbox--damagedeclaration .l-form .ll-row.is-check.is-valid .ll-item-container.is-desk-left-aligned & {
			&.ll-check {

				left: 195px;
				right: auto;
				transition: right 0s;
				visibility: visible;
				opacity: 1;

				@include mq(tablet) {
					left: auto;
					right: -30px;
				}
			}
		}

		// ***
		// end exceptions .m-basic-upload > div
		// ***

		> label {
			display: none;
		}

		> input {
			width: 0.1px;
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: -1;

			&:focus {
				& + .m-description {
					max-height: 100px;
				}

				.is-keyboard-focus & ~ .a-basic-btn {
					@extend %focus-button-styling;
				}
			}

			&::-webkit-calendar-picker-indicator {
				display: none;
			}

			&[type='date']::-webkit-input-placeholder {
				visibility: hidden;
			}
		}
	}

	> .m-description {
		padding: 7px 0 0 0;

		@include if-parent('.m-basic-upload.is-file-only:last-child') {
			display: block !important;
		}
	}

	&:last-child {
		> .m-description {
			display: block;
		}
	}

	> .m-data-wrap {
		position: relative;
		max-width: 380px;
		display: block;
		margin-top: 10px;

		@include if-parent('.m-basic-upload.is-file-only') {
			display: inline-block;
		}

		@include if-parent('.m-basic-upload--is-deletable') {
			width: fit-content;

			// exception if inside form
			.ll-col-data & {
				max-width: 400px;
				width: initial;
			}
		}

		.m-my-adac-dashboard-box-showcase &,
		.m-my-adac-dashboard-box &,
		.m-my-adac-dashboard-box-layer & {
			max-width: 100%;
		}

		// exception .m-basic-calculator-content-box .mm-upload-container
		.m-basic-calculator-content-box > .mm-upload-container > .mm-row > .mm-row-content--left > & {

			@include mq(tablet) {
				max-width: 100%;
			}
		}

		> .js-data {
			position: relative;
			display: none;
			padding: 7px 38px 6px 7px;
			background-color: $color-secondary-8;
			height: 36px;
			transition: background 0.4s;

			// general ellipsing
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;

			&:focus {
				border: 1px solid $color-primary-1;
			}

			&.is-longer {
				&:after {
					position: absolute;
					content: '...';
					width: 20px;
					height: 25px;
					bottom: 0;
					right: 25px;
				}
			}

			&.is-active {
				display: block;

				& + .js-clear-button {
					display: block;
				}
			}

			&.js-file-stored {
				border: none;
				background-color: transparent;
			}

			&.js-file-deletable {
				border: none;
				width: fit-content;
				max-width: 370px;
				padding: 7px 35px 6px 7px;
				position: relative;
				background-color: transparent;

				&:before {
					content: '';
					position: absolute;
					@include main-icon-delete;
					top: -4px;
					right: -7px;
				}

				@include mq(tablet) {
					max-width: 277px;
				}

				// exception if inside form
				@include if-parent('.m-basic-upload--is-deletable') {
					.ll-col-data & {
						width: initial;
						max-width: initial;
					}
				}

				& + .js-delete-button {
					position: absolute;
					right: 1px;
					top: 2px;
					height: 30px;
					width: 27px;
					cursor: pointer;
				}
			}

			> span {
				display: block;

				// general ellipsing
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			.m-ves-rs-contentbox--damagedeclaration .mm-row-content--right & {
				background-image: none;
			}

			// exception .m-ves-rs-contentbox--damagedeclaration,
			.m-ves-rs-contentbox--damagedeclaration > .mm-additional-cost & {
				width: 215px;
				max-width: 215px;
				min-width: 215px;

				@include mq(desktop) {
					width: 270px;
					max-width: 270px;
					min-width: 270px;
				}
			}

			// exception .m-basic-calculator-content-box
			.m-basic-calculator-content-box .mm-tile-container .mm-row-content--left > & {
				width: 215px;
				max-width: 215px;
				min-width: 215px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				@include mq(desktop) {
					width: 270px;
					max-width: 270px;
					min-width: 270px;
				}

				@include if-parent('.mm-row-content--left.is-check.is-error') {
					border-bottom: 2px solid $color-action-alert;
				}
			}

			// exception .m-ves-rs-contentbox--damagedeclaration
			.m-ves-rs-contentbox--damagedeclaration .mm-upload-container .mm-row-content--left > &,
			.m-ves-rs-contentbox--damagedeclaration .mm-tile-container .mm-row-content--left > & {
				width: 215px;
				max-width: 215px;
				min-width: 215px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				@include mq(desktop) {
					width: 230px;
					max-width: 230px;
					min-width: 230px;
				}

				@include mq(desktop-l) {
					width: 290px;
					max-width: 290px;
					min-width: 290px;
				}

				@include if-parent('.mm-row-content--left.is-check.is-error') {
					border-bottom: 2px solid $color-action-alert;
				}
			}

			// exception .m-ves-rs-contentbox--damagedeclaration
			.m-ves-rs-contentbox--damagedeclaration .mm-upload-container .mm-row &:not(.js-file-stored),
			.m-ves-rs-contentbox--damagedeclaration .mm-tile-container .mm-row &:not(.js-file-stored) {
				@include if-parent('.mm-row.is-check.is-error') {
					border-bottom: 2px solid $color-action-alert;
				}
			}

			.m-ves-rs-contentbox--damagedeclaration .mm-row--center & {
				width: 195px;
				max-width: 195px;
				min-width: 195px;
			}

			// exception .m-ves-rs-contentbox--damagedeclaration if elem is part of .l-form structure
			.m-basic-calculator-content-box .ll-item-container .ll-col-data > &,
			.m-ves-rs-contentbox--damagedeclaration .ll-item-container .ll-col-data > & {
				width: 185px;
				max-width: 185px;
				min-width: 185px;

				@include mq(tablet) {
					width: 215px;
					max-width: 215px;
					min-width: 215px;
				}
			}

			// exception .m-ves-rs-contentbox--damagedeclaration if elem is part of .l-form structure
			.m-ves-rs-contentbox--damagedeclaration .ll-item-container--full .ll-row:not(.is-acc-offender-row) .ll-col-data > & {
				width: 250px;
				max-width: 250px;
				min-width: 250px;

				@include mq(tablet) {
					width: 305px;
					max-width: 305px;
					min-width: 305px;
				}
			}

			// exception .m-ves-rs-contentbox--damagedeclaration if elem is part of .l-form structure
			.m-ves-rs-contentbox--damagedeclaration .ll-item-container--full .ll-row.is-acc-offender-row .ll-col-data > & {
				width: 185px;
				max-width: 185px;
				min-width: 185px;

				@include mq(tablet) {
					width: 305px;
					max-width: 305px;
					min-width: 305px;
				}
			}

			// exception .m-ves-rs-contentbox--damagedeclaration,
			// exception .m-basic-calculator-content-box.h-background,
			.m-ves-rs-contentbox--grey &,
			.m-basic-calculator-content-box.h-background & {

				&:not(.js-file-stored) {
					background-color: $color-primary-2;
				}
			}
		}

		> .js-clear-button {
			display: none;
			position: absolute;
			top: 3px;
			right: 2px;
			height: 30px;
			width: 27px;
			cursor: pointer;

			&:before {
				content: '';
				@include main-icon-cancel;
				position: absolute;
				top: -6px;
				right: -9px;
			}

			// exception .m-ves-rs-contentbox--damagedeclaration if elem is part of .l-form structure
			.m-basic-calculator-content-box .ll-item-container .ll-col-data > &,
			.m-ves-rs-contentbox--damagedeclaration .ll-item-container .ll-col-data > & {
				right: auto;
				left: calc(185px - 28px);

				@include mq(tablet) {
					left: auto;
					right: 0;
				}
			}

			.m-ves-rs-contentbox--damagedeclaration .mm-row-content--right & {
				display: none;
			}
		}

		> .js-filesize {

			@include absolute($top: 7px, $right: -10px);
			display: none;
			text-align: left;
			transform: translate(100%, 0);

			&.is-visible {
				display: block;
			}
		}
	}

	&--my-adac {
		> div {
			height: auto;
		}

		> .m-description {
			padding: 10px 0;
		}

	}

	// "remove-icon" styles if its a sibling to .m-basic-upload
	& ~ .js-clone-remove {
		display: none;

		.m-ves-rs-contentbox--damagedeclaration .mm-row-content--right & {
			display: block;
		}
	}

	// if its a "clone" show icon
	.js-clone-source-true.js-file-stored &.is-active ~ .js-clone-remove,
	.js-clone & ~ .js-clone-remove {
		display: block;
	}

	&.is-active ~ .js-clone-remove {
		display: block !important;
	}
}