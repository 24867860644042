/* o-basic-form--labelontop */
.o-basic-form--labelontop {

	.oo-row {
		max-width: 392px;

		// ##### exceptions #####
		// l-lightbox
		.l-lightbox & {
			margin-bottom: 11px;
		}
	}

	.oo-col-data {
		float: none !important;
		width: 100%;

		&--only-data {
			margin-left: 0;
		}
	}

	.oo-col-label {
		float: none !important;
		width: 100%;
		margin-bottom: 5px;

		+ .oo-col-data {
			width: 100%;
		}

		// ##### exceptions #####
		// l-lightbox
		.l-lightbox & {
			padding-bottom: 0;
		}
	}

}