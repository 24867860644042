.m-adac-maps-header {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 30px;
	font-family: 'Roboto Bold', sans-serif;

	> .mm-headline {
		margin-right: 15px;
	}

	> .mm-logo-box {
		position: relative;
		width: 45px;
		height: 45px;

		> img {
			max-height: 45px;
			display: block;
		}
	}
}