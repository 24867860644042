/* o-basic-form */
.o-basic-form {
	margin: 0 0 20px 0;

	@include mq(tablet) {
		margin-right: 0;
	}

	@include mq(print) {
		margin-right: 0;
	}

	// Expeption: l-main-cols--line
	.l-main-cols--line > & {
		padding-right: 20px;
	}

	.oo-fieldset {
		margin: 0 0 20px 0;

		> legend {
			display: block;
			width: 100%;
			font-weight: bold;
			margin: 0 0 10px 0;
			padding: 6px 10px 6px 10px;
			@include milo-bold();
			background-color: $color-secondary-10;

			@include mq(tablet) {
				margin-bottom: 15px;
			}
		}

		.oo-row {
			margin: 0 0 15px 10px;

			&.oo-depend {
				margin-left: 0;
			}

			// Exception in checkbox label
			.a-basic-input-checkbox .oo-row {
				margin-left: 0;
			}

			&--multilabels {

				@include mq(tablet) {
					margin-top: 23px
				}
			}
		}
	}

	.oo-row {
		max-width: 430px;
		position: relative;
		padding: 0 30px 0 0;
		margin: 0 0 15px 0;

		@include mq(tablet) {
			max-width: 563px;

			&--full {
				max-width: 700px;
			}
		}

		@include mq(print) {
			max-width: 563px;
		}

		.oo-check {
			opacity: 0;
			width: 22px;
			height: 22px;
			margin: 3px 0 0 0;
			visibility: hidden;
			transition: all $form-animation ease;
			@include absolute($right: 5px);
		}

		&.is-error.is-check .oo-check {
			opacity: 1;
			visibility: visible;

			&:after {
				content: '';
				position: absolute;
				@include main-icon-close-red;
				right: -15px;
				bottom: -15px;
			}
		}

		&.is-valid.is-check .oo-check {
			opacity: 1;
			visibility: visible;

			&:after {
				content: '';
				position: absolute;
				@include main-icon-check-green;
				right: -15px;
				bottom: -15px;
			}
		}

		.oo-error-msg {
			opacity: 0;
			padding: 0;
			max-height: 0;
			overflow: hidden;
			color: $color-action-alert;
			transition: all $form-animation ease;

			> a {
				color: $color-action-alert;
			}
		}

		&.is-error .oo-error-msg {
			opacity: 1;
			max-height: 300px;
			padding: 5px 0;
		}
	}

	.oo-depend {
		display: none;
	}

	.oo-col-label {
		margin: 0 0 5px 0;
		@include milo-bold();

		@include mq(tablet) {
			margin: 0;
			float: left;
			width: 170px;
			padding: 5px 10px 0 0;
		}

		@include mq(print) {
			float: left;
			width: 170px;
		}

		.oo-label > span {
			@include milo-regular();
		}

		// Exeption l-lightbox
		.l-lightbox & {
			width: 100%;
			padding: 0 0 5px 0;
		}
	}

	.oo-col-data {
		&--only-data {
			@include mq(tablet) {
				margin: 0 0 0 170px;
			}

			@include mq(print) {
				margin: 0 0 0 170px;
			}
		}

		@include mq(print) {
			float: left;
			width: calc(100% - 170px);
		}
	}

	.oo-col-label + .oo-col-data {
		@include mq(tablet) {
			float: left;
			width: calc(100% - 170px);

			// Expeption: l-main-cols--line
			.l-main-cols--line & {
				width: 100%;
			}
		}
	}

	.oo-description {
		clear: both;
		padding-top: 5px;
		line-height: normal;

		// required for transition
		&:before {
			content: "";
			display: block;
			margin-top: 5px;
		}
	}

	.ll-main-cols-aside {

		&.ll-main-cols-aside--first {
			display: block;

			@include mq(tablet) {
				border-right: 0px none transparent;
				width: 100%;
			}

			@include mq(desktop) {
				display: table-cell;
				border-right: 20px solid transparent;
				width: 66%;
			}
		}

		&.ll-main-cols-aside--first + .ll-main-cols-aside {
			position: absolute;
			top: 0;
			right: 0;

			@include mq(desktop) {
				position: relative;
			}
		}

		@include mq(desktop) {
			display: table-cell;
		}
	}

	.oo-aside-open {
		display: block;

		@include mq(desktop) {
			display: none;
		}

		width:40px;
		height:40px;
		position:fixed;
		right:0;
		top:50%;

		@include mq(mobile-max) {
			top: 33.5%;
		}

		@include mq(tablet-max-height) {
			top: 245px;
		}

		@include mq(mobile-max-height) {
			top: 162px;
		}

		background:$color-action-alert;
		padding:5px;
		cursor:pointer;
		z-index:45;

		span {
			display: block;
			border: 2px solid $color-primary-2;
			-webkit-border-radius: 16px;
			-moz-border-radius: 16px;
			border-radius: 16px;
			padding: 3px 0 0 2px;
			width: 31px;
			height: 31px;
		}
	}

	.oo-aside {
		width: 305px;
		display: none;

		@include mq(tablet-max) {
			position: fixed !important;
			top: 50% !important;
			right: 0 !important;
			bottom: auto !important;
		}

		@include mq(mobile-max) {
			top: 33.5% !important;
		}

		@include mq(tablet-max-height) {
			top: 245px !important;
		}

		@include mq(mobile-max-height) {
			top: 162px !important;
		}

		@include mq(desktop) {
			display: block;
			position: absolute;
			top: 0px;
			right: auto;
		}

		z-index:101;
		border:1px solid $color-secondary-6;

		.oo-aside-head {
			padding: 1px 37px 2px 10px;
			position: relative;
			background: $color-primary-1;
			@include franklin-demi($font-size-24);
			border-bottom: 1px solid $color-secondary-6;

			.oo-aside-edit {
				position: absolute;
				top: 5px;
				right: 7px;
				cursor: pointer;
			}
		}

		.oo-aside-content {
			padding: 8px 10px 2px;
			background: $color-primary-2;

			h2 {
				@include franklin-demi($font-size-22);
				padding-bottom: 2px;
			}

			.oo-contribution {
				padding: 10px 0 7px 0;
				@include franklin-demi($font-size-28);
				border-top: 1px solid $color-secondary-6;
			}
		}
	}
}