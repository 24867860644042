.m-basic-calculator-content-box--image,
.m-basic-calculator-content-box-newform--image {

	> .mm-tile-container {
		display: flex;
		flex-flow: row wrap;
		margin: 5px -10px 5px -10px;

		&.is-odd {
			flex-flow: row wrap;
			padding: 0;
			justify-content: center;
		}

		//exception in Form
		.l-form .ll-row & {
			&:first-child {
				@include mq(desktop) {
					flex-wrap: nowrap;
				}
			}
		}

		> .mm-tile {

			@include if-parent('.mm-tile-container.is-odd') {
				&.is-last {
					margin-bottom: 20px;
				}
			}

			&--radio {
				> label {
					text-align: center;

					> img {
						width: auto; //http://localhost:8081/pages-fv-examples-bicycle-damage-declaration?process=1&step1=1
						height: auto; //http://localhost:8081/pages-basic-components-molecules-basiccalculatorcontentbox#
						max-width: 100%;
						max-height: calc(100% - 20px);
						margin: 10px 0;
						pointer-events: none;
						user-select: none;

						@include if-parent('label.img-on-top') {
							position: relative;
							top: -24px;
						}
					}
				}
			}

			&--checkbox {
				> label {
					text-align: center;

					> img {
						max-width: 100%;
						max-height: 100%;
						pointer-events: none;
						user-select: none;
						margin: 10px 0; //http://localhost:8081/pages-basic-components-molecules-basiccalculatorcontentbox#

						@include if-parent('label.img-on-top') {
							position: relative;
							top: -24px;
						}
					}
				}
			}

			&-empty {
				width: 130px;

				@include mq(tablet) {
					display: none;
				}
			}

			> label {

				@include if-parent('.mm-tile-container:not(.mm-tile-container--no-img)') {
					display: block;
					height: 130px;
					width: 130px;
					position: relative;
				}

				> .mm-text-container {

					@include if-parent('.mm-tile-container:not(.mm-tile-container--no-img)') {
						position: absolute;
						width: 100%;
						display: flex;
						flex-flow: column nowrap;
						left: 50%;
						transform: translate(-50%, 0);
						padding: 0 5px;
						bottom: 12px;
						text-align: center;

						@include if-parent('.mm-tile-container.is-odd') {
							display: flex;
							padding: 0 5px;
						}
					}

					> .mm-tile-desc-sub {

						@include if-parent('.mm-tile-container:not(.mm-tile-container--no-img).is-odd') {
							display: block;
							padding-left: 0;
						}
					}
				}
			}
		}
	}
}