/* m-basic-table--no-border */
.m-basic-table--no-border {
	> tbody > tr {
		&:last-child {
			border-bottom: 1px solid $color-secondary-16;

			@include if-parent('.m-basic-table--no-border.m-basic-table--white') {
				border-bottom: 2px solid $color-secondary-16;
			}
		}
	}
}

.js-basic-table-wrap {
	margin: 0;
	padding-top: 0;
}