/**************************************/
/* Begin: Styling for Magnific Popup - Lightbox
/**************************************/

/* l-lightbox */
.l-lightbox {

	%close {
		font-size: 0;
		@include main-icon-close();
		right: 3px;
		top: 2px;
		padding: 0;
		color: #333;
		text-align: center;
		cursor: pointer;
	}

	&.mfp-gallery {

		> .mfp-container {
			max-width: 865px;
			position: relative;
			margin: 0 auto;
		}
	}

	&.mfp-bg {
		z-index: 1150;
	}

	&.mfp-wrap {
		z-index: 1151;
	}

	> .mfp-container {
		padding: 0;

		@include mq(tablet) {
			padding: 0 6px;
		}

		> .mfp-content {
			padding: 0;
			width: auto;
			max-width: 100%;

			@include mq('tablet') {
				max-width: 898px;
			}

			.mfp-explanation & {
				@include mq('tablet') {
					padding: 0 50px;
				}
			}

			> .mfp-figure {
				padding: 0;
				padding-left: $l-lightbox-inner-padding-side;
				padding-right: $l-lightbox-inner-padding-side;
				background: $color-primary-2;

				@include mq(tablet) {
					padding-left: $l-lightbox-inner-padding-side-tablet;
					padding-right: $l-lightbox-inner-padding-side-tablet;
				}

				@include mq(desktop) {
					padding-left: $l-lightbox-inner-padding-side-desktop;
					padding-right: $l-lightbox-inner-padding-side-desktop;
				}

				.mfp-close {
					@extend %close;
				}
			}

			> div {
				&:first-child {
					> h2 {
						&:first-child {
							// Selects the first h2 in the first div if a h2 is set out of components.
							@include font-size($font-size-24, 29px);
							padding-bottom: 28px;

							@include mq(tablet) {
								@include font-size($font-size-36, 40px);
							}
						}
					}
				}
			}

			/* text-based popup styling */
			.ll-lightbox-inner {
				position: relative;
				background: $color-primary-2;
				padding: 40px 19px;
				width: auto;
				min-width: 300px;
				margin: 0 auto;
				border-radius: 0;
				max-height: 100vh;
				overflow: auto;

				@include mq(tablet) {
					padding: 40px 39px;
					max-width: 765px;
					border-radius: 8px;
				}


				&--gallery {
					max-width: 100%;
					height: auto;
					max-height: 100%;
					padding: 15px 19px;
					border-radius: 0;

					@include mq(tablet) {
						max-width: 95vw;
						padding: 0 19px;
					}

					@include mq(desktop) {
						padding: 0 19px;
					}
				}

				&--healthinsurance {
					background-color: $color-primary-2;

					@include mq(tablet) {
						padding: 19px 29px;
						max-width: 848px;
					}

					.mfp-arrow {
						top: auto;
					}

					> .a-basic-link.mfp-close {
						background: none;
						display: table;
						@include milo-regular($font-size-16);
						padding: 0 0 0 12px;
						position: relative;
						opacity: 1;
						width: auto;
						height: auto;

						&:before {
							@include main-icon-arrow-right-black;
							left: -19px;
							top: 0;
						}
					}
				}

				&.my-adac {
					border-radius: 10px;
					padding: 35px 15px 15px;
					margin: 0 10px;

					@include mq(tablet) {
						margin: 0 auto;
					}

					> .m-button-box {

						@include mq(tablet) {
							display: flex;
							justify-content: space-between;
						}
					}
				}

				.mfp-close {
					@extend %close;

					@include if-parent('.l-lightbox .ll-lightbox-inner--gallery') {
						top: 15px;

						@include mq(tablet) {
							top: 15px;
							right: 5px;
						}
					}

					@include if-parent('.ll-lightbox-inner.my-adac') {
						@include main-icon-close;
						right: 5px;
						top: 5px;
					}
				}
			}
		}
	}
}

.mfp-explanation,
.mfp-adac-quiz {
	.mfp-container {
		.mfp-content {
			width: auto;
			max-width: 100%;

			@include mq('tablet') {
				max-width: 898px;
			}

			.mfp-explanation & {
				@include mq('tablet') {
					padding: 0 50px;
				}
			}

			.m-adac-quiz {
				margin-left: 0;
				margin-right: 0;
			}

			.mm-explanation {
				position: relative;
				background: $color-primary-2;
				margin: 0 auto;

				&.ll-lightbox-inner {
					border: 0;
				}

				.mm-explanation-wrapper {
					padding: 10px 40px 20px;
					display: inline-block;

					@include mq ('tablet') {
						padding: 45px 140px 65px 40px;
					}

					> .mm-ex-headline {
						@include milo-bold($font-size-18);
						margin-bottom: 10px;
						position: relative;
						margin-left: 25px;

						@include mq(tablet) {
							margin-left: 90px;
						}

						&:before {
							content: '';
							display: inline-block;
							@include my-adac-icon-lightbulb_small;
							left: -35px;
							top: -9px;
							position: absolute;

							@include mq(tablet) {
								@include my-adac-icon-lightbulb;
								left: -103px;
								top: -26px;
								position: absolute;
							}
						}

						@include mq(tablet) {
							font-size: $font-size-28;
							margin-bottom: 5px;
						}
					}

					> .mm-ex-info-text {
						max-height: 220px;
						overflow-y: auto;
						margin-left: 26px;

						@include mq(tablet) {
							max-height: 100%;
							margin-left: 90px;
						}
					}
				}

				> .a-basic-link {
					display: none;
					position: absolute;
					right: 20px;
					bottom: 30px;
					margin-bottom: 0;
					cursor: pointer;

					&.back-to-quiz {
						display: block;
						margin-left: 86px;
						margin-top: 20px;
						position: relative;

						@include mq (tablet) {
							position: absolute;
							right: 20px;
							bottom: 30px;
							margin: 0;
						}
					}

					@include mq ('tablet') {
						display: block;
					}
				}

			}

			.mfp-close {
				font-size: 0;
				@include my-adac-icon-close;
				opacity: unset;
				right: 10px;
				top: 10px;

				.mfp-explanation & {
					right: 10px;
					top: 10px;
				}
			}
		}
	}
}


/**************************************/
/* Originals
/**************************************/

.mfp-zoom-out-cur {
	cursor: default;
}

/**************************************/
/* Open Effects
/**************************************/

/*=========== Zoom effect ===========*/

.mfp-zoom-in {

	/* start state */
	.mfp-with-anim {
		opacity: 0;
		transition: all 0.2s ease-in-out;
		transform: scale(0.8);
	}

	&.mfp-bg {
		opacity: 0;
		transition: all 0.3s ease-out;
	}

	/* animate in */
	&.mfp-ready {
		.mfp-with-anim {
			opacity: 1;
			transform: scale(1);
		}

		&.mfp-bg {
			opacity: 0.8;
		}
	}

	/* animate out */
	&.mfp-removing {

		.mfp-with-anim {
			transform: scale(0.8);
			opacity: 0;
		}

		&.mfp-bg {
			opacity: 0;
		}

	}

}