/* a-basic-input-radio--haptik */
.a-basic-input-radio--haptik {
	margin: 5px 7px 5px 0;

	&:last-child {
		margin-right: 0;
	}

	.l-form-general & {
		margin: 5px 8px 0 0;

		&.h-space-s {
			margin: 5px 8px 15px 0;
		}

		&.h-space-m {
			margin: 5px 8px 30px 0;
		}
	}

	// exception for tourset to center the radio-buttons
	// 30px/60px reserverd for the error or check icons
	// if u dont do this, the buttons cant be centered
	.ll-item-container--full .ll-row-content-wrapper & {

		&:first-child {
			margin: 5px 20px 5px 30px;

			@include mq(tablet) {
				margin: 5px 20px 5px 60px;
			}
		}
	}

	input ~ div {
		background: none !important;
		padding: 0;

		&:before {
			width: 0 !important;
		}

		.l-form-general & {
			&:after {
				display: none;
			}
		}
	}

	> input {
		@extend %h-visuallyhidden;

		&:checked ~ div > label {
			background-color: $color-primary-1;
			border-color: $color-primary-1;

			.l-form-general & {
				border-width: 2px;
				padding: 4px 24px;
				border-color: $color-formfield-border-standard;
			}
		}

		&:focus ~ div > label {
			border-color: $color-primary-1;

			.l-form-general & {
				border-width: 2px;
				padding: 4px 24px;

				.is-keyboard-focus & {
					border-color: $color-primary-3;

					&:after {
						content: '';
						position: absolute;
						left: -6px;
						top: -6px;
						width: calc(100% + 12px);
						height: calc(100% + 12px);
						border: 3px solid $color-primary-3;
						border-radius: 8px;
					}
				}
			}

			.is-keyboard-focus .l-form-general .ll-row.is-error .ll-col-data & {
				background-color: $color-secondary-6;
			}
		}

		& ~ div {
			padding: 0 !important;

			&:before {
				width: 0 !important;
			}

			> label {
				padding: 5px 25px;
			}
		}
	}

	&:nth-child(2) {
		> input {
			& ~ div {
				padding: 0 0 0 45px;
			}
		}
	}

	.ll-row--sep & {

		@include mq(tablet) {
			margin-right: 20px !important;
		}
	}

	> div {

		&:before {
			width: 0 !important;
		}

		> label {
			font-weight: bold;
			border: 2px solid $color-primary-3;
			border-radius: 5px;
			cursor: pointer;
			background-color: $color-primary-2;
			text-align: center;

			.l-form-general & {
				@include milo-bold();
				min-height: 36px;
				border: 1px solid $color-formfield-border-standard;
			}

			.l-form-general .ll-row.is-error .ll-col-data & {
				color: $color-primary-3;
				border-color: $color-action-alert;
				border-width: 2px;
				padding: 4px 24px;
			}

			> span {
				display: block;
				@include milo-regular();
			}

			.l-form .ll-row.is-error .ll-col-data & {
				color: $color-primary-2;
				background-color: $color-action-alert;
			}
		}
	}
}