/* a-basic-link--my-adac */
.a-basic-link--my-adac-promotion {

	&:not(.a-basic-link--info) {
		padding: 0 0 0 16px;

		&:before {
			content: '';
			display: inline-block;
			@include main-icon-arrow-right-grey2;
		}
	}

	&:after {
		content: none;
	}


	.m-my-adac-dashboard-box-intro & {
		margin: 0 15px;
	}

	// Exception for mm-data-protection-hint on my-adac-content sites && boxes
	.mm-data-protection-hint & {
		margin: 0 20px;
		padding: 0 0 0 30px;
	}
}