.m-adac-quiz {
	background: $color-secondary-8;
	padding: 8px 0;
	margin: 0 -20px;

	@include mq('tablet') {
		padding: 15px 0;
		margin: 0;
	}

	> .mm-quiz-pagination-wrapper {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-items: flex-start;
		padding: 0 20px 8px;
		@include milo-regular($font-size-18);

		@include mq(tablet) {
			padding: 0 50px 15px;
			justify-content: space-between;
			flex-wrap: unset;
		}

		&.is-hidden {
			display: none;
		}

		> .mm-quiz-headline {
			margin-bottom: 5px;
			width: 100%;
			text-align: center;

			@include mq('tablet') {
				@include font-size($font-size-20);
				margin-bottom: 0;
				width: auto;
				text-align: left;
				padding-right: 30px;
			}

			&.is-hidden {
				display: none;

				@include mq('tablet') {
					display: block;
					visibility: hidden;
				}
			}
		}

		> .mm-quiz-pagination {
			@include milo-regular($font-size-16);
			display: flex;
			justify-content: center;
			align-items: center;

			> .quiz-counter {
				margin-right: 7px;
			}

			> .mm-rates {
				border-radius: 50%;
				background: $color-primary-2;
				height: 15px;
				border: 1px solid $color-secondary-9;
				width: 15px;
				margin-left: 3px;

				&.is-active {
					background: #ffcc05;
					border: 0;
				}

				&.is-right {
					background: $color-action-valid;
					border: 0;
				}

				&.is-wrong {
					background: $color-action-alert;
					border: 0;
				}
			}
		}
	}

	> .mm-quiz-page {
		&--start {
			position: relative;
			visibility: visible;
			opacity: 1;
			transition: opacity 1.5s;
			margin-bottom: -8px;

			@include mq('tablet') {
				margin-bottom: -15px;
			}

			> .mm-quiz-header {
				> .mm-quiz-subheadline {
					display: block;
				}
			}
		}

		&--end {
			position: absolute;
			visibility: hidden;
			opacity: 0;
			transition: opacity 1.5s;
			display: block;

			> .mm-quiz-header {
				> .mm-quiz-subheadline {
					display: none;

					&.is-active {
						display: block;
					}
				}
			}

			.mm-quiz-rating {
				@include milo-regular($font-size-16);
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 25px;

				@include mq('tablet') {
					margin-top: 35px;
					margin-bottom: 57px;
				}

				> .mm-rates {
					border-radius: 50%;
					background: $color-primary-2;
					height: 15px;
					border: 1px solid grey;
					width: 15px;
					margin-left: 6px;

					@include mq('tablet') {
						height: 25px;
						width: 25px;
					}

					&.is-right {
						background: $color-action-valid;
						border: 0;
					}

					&.is-wrong {
						background: $color-action-alert;
						border: 0;
					}
				}
			}

			.mm-ex-rates {
				@include milo-bold();
			}
		}

		> .mm-quiz-header {
			background: $color-primary-1;
			text-align: center;
			padding: 35px 0 20px;

			@include mq('tablet') {
				padding: 50px 0 30px;
			}

			@include if-parent('.mm-quiz-page--end') {
				background: transparent;
			}

			> .mm-quiz-subheadline {
				padding: 0 33px;
				@include milo-slab-bold($font-size-30, 36px);
				margin-bottom: 10px;

				@include mq('tablet') {
					@include milo-slab-bold($font-size-50, 60px);
					margin-bottom: 20px;
					padding: 0 200px;
				}
			}

			> .mm-quiz-date {
				font-size: $font-size-18;
				margin-bottom: 10px;

				@include mq(tablet) {
					font-size: $font-size-24;
					margin-bottom: 30px;
				}
			}

			> .mm-quiz-btn {
				display: inline-block;
				text-decoration: none;
				padding: 5px 16px 5px 30px;
				font-size: $font-size-23;
				cursor: pointer;
				position: relative;
				background-color: $color-primary-2;

				&:before {
					content: '';
					display: inline-block;
					@include main-icon-btn-arrow_large;
					position: absolute;
					left: -4px;
					top: 2px;
				}

				&:hover {
					background-color: $color-secondary-8;
				}

				&:focus {
					background-color: $color-secondary-8;
				}

				&--yellow {
					background-color: $color-primary-1;

					&:hover {
						background-color: $color-action-btn;
					}

					&:focus {
						background-color: $color-action-btn;
					}
				}

				&--end {
					margin-bottom: 15px;
				}
			}

			> .a-basic-link {
				@include if-parent('.mm-quiz-page--end') {
					margin: 0 auto;
				}
			}

			.mm-quiz-explanation {
				padding: 20px 15px 10px;
				font-size: $font-size-18;
				text-align: center;

				@include mq('tablet') {
					padding: 20px 130px 10px;
				}
			}
		}

	}

	> .mm-question-wrapper {
		position: relative;
	}

	.mm-quiz-description {
		padding: 20px 15px;
		font-size: $font-size-16;
		text-align: center;

		@include mq('tablet') {
			font-size: $font-size-18;
			padding: 45px 130px;
		}

		> .mm-quiz-desc-img {
			display: none;
		}

		&--startimg {
			display: flex;
			flex-wrap: wrap;
			padding: 0;

			> .mm-quiz-desc-img {
				display: block;
				width: 100%;

				@include mq(tablet) {
					width: 45%;
				}

				> .m-basic-image {
					> figure {
						padding-bottom: 0;
					}
				}
			}

			> .mm-quiz-desc-text {
				width: 100%;
				padding: 20px;
				font-size: $font-size-16;
				text-align: center;

				@include mq('tablet') {
					width: 55%;
					font-size: $font-size-18;
					padding: 50px 35px;
					text-align: left;
				}
			}
		}
	}

	.mfp-quiz-popup-link {
		display: none;

		.mfp-content & {
			display: flex;
			justify-content: flex-end;
			margin-right: 20px;
		}

		.mfp-close-link {
			&:before {
				@include main-icon-arrow-right-grey;
			}
		}
	}
}