/* m-layout-main-nav-left */
.m-layout-main-nav-left {
	position: absolute;
	top: 50px;
	width: calc(100% - 20px);
	margin: 0 0 0 10px;
	z-index: 800;
	@include milo-regular(16px);

	.h-roboto & {
		font-family: 'Roboto', sans-serif;
	}

	@include mq(tablet) {
		width: 100%;
		min-width: 300px;
		max-width: 500px;
		margin: 0;
		position: absolute;
		top: 50px;
		display: block;
	}

	@include mq(desktop) {
		float: left;
		min-width: 0;
		padding-top: 0;
		z-index: 90;
		top: 85px;
		width: 18%;
	}

	@include mq(desktop-l) {
		left: auto;
		max-width: 212px;
		margin-left: 30px;
	}

	@include mq(desktop-xl) {
		float: none;
		position: absolute;
		margin: 0 0 0 -252px;
		left: auto;
		width: 212px;
	}

	@include mq(print) {
		display: none;
	}

	&.is-hidden {
		opacity: 0;
		visibility: hidden;
		transition: all 0.25s ease;
	}

	&.is-stuck {

		@include mq(desktop) {
			max-width: 212px;
		}

		@include mq(desktop-l) {
			top: auto;
			left: auto;
		}
	}

	// exception for .m-basic-header
	.m-basic-header ~ .l-main > .ll-main-center > & {
		top: 0;

		@include mq(desktop) {
			top: 12px;
		}
	}

	> ul {
		display: none;
		position: absolute;
		top: 51px;
		z-index: 20 !important;
		width: 100%;
		max-width: 500px;
		padding: 0;

		@include mq(desktop) {
			display: block !important;
			position: static
		}

		// exception for .m-basic-header
		.m-basic-header ~ .l-main & {
			top: 50px;
			border: 2px solid $color-primary-1;
			border-width: 0 2px 2px 2px;
			border-radius: 0 0 4px 4px;

			@include mq(desktop) {
				border: 0;
			}
		}

		> li {
			margin: 0 0 1px 0;

			&:last-child {
				> a {
					&:hover {

						// exception for .m-basic-header
						.m-basic-header ~ .l-main &:not(.is-open) {
							@include mq(desktop) {
								border-bottom: 1px solid $color-secondary-9;
							}
						}
					}
				}
			}

			// exception for .m-basic-header
			.m-basic-header ~ .l-main & {
				margin-bottom: 0;
				border-top: 1px solid $color-secondary-9;
				position: relative;

				@include mq(desktop) {
					margin-bottom: 1px;
					border-top: 0;
				}
			}

			&.js-clusterpoint {

				margin-bottom: 0;

				&.js-clusterpoint-first {
					> a {
						padding-top: 18px;

						&:before {
							top: 7px;
						}
					}
				}

				&.js-clusterpoint-single {
					> a {
						padding-top: 18px;
						padding-bottom: 18px;

						&:before {
							top: 7px;
						}
					}
				}


				&.js-clusterpoint-last {
					> a {
						padding-bottom: 18px;
					}
				}

				> a {

					padding: 4px 32px 4px 34px;
					@include milo-regular(16px);
					background-color: $color-primary-2;

					@include mq(desktop) {
						padding: 0;
						margin: 0 15px 12px 25px;
						background: none;
					}

					&:hover {
						background: $color-primary-2;
						text-decoration: underline;

						// exception for .m-basic-header
						.m-basic-header ~ .l-main & {
							border: none;
							margin-top: 0;
						}
					}

					&:before {
						content: "";
						position: absolute;
						top: -6px;
						left: -5px;
						width: 12px;
						height: 12px;
						@include main-icon-arrow-left-black;

						@include mq(desktop) {
							content: "";
							position: absolute;
							top: -9px;
							left: -42px;
							width: 12px;
							height: 12px;
							@include main-icon-arrow-left-black;
						}

						// exception for .m-basic-header
						.m-basic-header ~ .l-main & {
							background-image: none;

							@include mq(desktop) {
								@include main-icon-arrow-left-black;
								position: absolute;
								left: -42px;
								top: -11px;
							}
						}
					}

					// exception h-roboto
					.h-roboto & {
						font-family: 'Roboto', sans-serif;
					}

					// exception for .m-basic-header
					.m-basic-header ~ .l-main & {
						background-color: $color-primary-2;
						padding: 12px 30px 12px 42px;

						@include mq(desktop) {
							padding: 0;
							border-bottom: none;
						}
					}
				}
			}

			.is-keyboard-focus &:has(a:focus) {
				outline: 0 !important;
				border: 2px solid $color-primary-3;
				border-radius: 4px;
			}


			> a {
				position: relative;
				display: block;
				padding: 12px 30px 12px 34px;
				@include font-size($font-size-16);
				text-decoration: none;
				background: $color-primary-1;

				@include mq(desktop) {
					padding-left: 10px;
				}

				&:focus {

					// exception for .m-basic-header
					.m-basic-header ~ .l-main & {
						outline: none;
					}
				}

				&:hover {
					background: $color-primary-2;

					// exception for .m-basic-header
					.m-basic-header ~ .l-main & {
						background: $color-secondary-8;

						@include mq(desktop) {
							margin-top: -1px;
							border-top: 1px solid $color-secondary-9;
							border-bottom: 1px solid transparent;
							background: $color-primary-2;
						}
					}
				}

				&:after {

					// exception for .m-basic-header
					.m-basic-header ~ .l-main & {
						display: block;
						content: "";
						@include absolute(0, 0);
						@include main-icon-arrow-right-grey-dark;
						transform: scale(0.7);

						@include mq(desktop) {
							display: none;
						}
					}
				}

				&.is-open {
					background: $color-primary-1;
					color: $color-primary-3;
					@include milo-bold(16px);
					margin: 0 0 1px 0;

					&:hover {

						// exception for .m-basic-header
						.m-basic-header ~ .l-main & {

							@include mq(desktop) {
								border-top: none;
								margin-top: 0;
								border-bottom: 1px solid $color-secondary-9;
							}
						}
					}

					+ ul {
						display: block;
					}

					// exception for .m-basic-header
					.m-basic-header ~ .l-main & {
						margin-bottom: 0;
					}

					.h-roboto & {
						font-family: 'Roboto Bold', sans-serif;
					}
				}

				&.is-active {
					margin: 0 0 1px 0;
					background: $color-primary-1;
					color: $color-primary-3;
					@include milo-bold(16px);

					.h-roboto & {
						font-family: 'Roboto Bold', sans-serif;
					}

					+ ul {
						display: block;
					}

					// exception for .m-basic-header
					.m-basic-header ~ .l-main & {
						margin-bottom: 0;
					}
				}

				&.is-self {
					color: $color-primary-3;
				}

				// exception for .m-basic-header
				.m-basic-header ~ .l-main & {
					padding-left: 42px;
					background-color: $color-primary-2;

					@include mq(desktop) {
						padding-left: 10px;
						background: $color-primary-1;
						border-bottom: 1px solid $color-primary-1;
					}
				}
			}

			> ul {
				display: none;
				margin: 0;
				padding: 0;

				// exception for .m-basic-header
				.m-basic-header ~ .l-main & {
					border-top: 1px solid $color-secondary-9;

					@include mq(desktop) {
						border-top: 0;
					}
				}

				> li {
					margin: 0 0 1px 0;
					border-bottom: 1px solid $color-secondary-9;

					&:last-child {
						border-bottom: none;

						> a {

							// exception for .m-basic-header
							.m-basic-header ~ .l-main & {
								border: none
							}
						}
					}

					@include mq(desktop) {
						&.mm-first {
							display: none;
						}
					}

					// exception for .m-basic-header
					.m-basic-header ~ .l-main & {
						margin-bottom: 0;
						background: white;
						border: none;
					}

					> a {
						position: relative;
						display: block;
						font-size: $font-size-14;
						padding: 10px 30px 9px 44px;
						text-decoration: none;
						background: $color-primary-2;

						@include mq(desktop) {
							padding-left: 20px;
						}

						&:hover {
							background: $color-primary-2;

							// exception for .m-basic-header
							.m-basic-header ~ .l-main & {
								background: $color-secondary-8;
							}
						}

						&:after {

							// exception for .m-basic-header
							.m-basic-header ~ .l-main & {
								display: block;
								content: "";
								@include absolute(0, 0);
								@include main-icon-arrow-right-grey-dark;
								transform: scale(0.7);
								left: 13px;
								top: -2px;

								@include mq(desktop) {
									display: none;
								}
							}
						}

						&.is-active {
							background: $color-primary-2;
							color: $color-primary-3;
							@include milo-bold(14px);

							.h-roboto & {
								font-family: 'Roboto Bold', sans-serif;
							}
						}

						&.is-open {
							color: $color-primary-3;
							@include milo-regular(14px);
							font-weight: bold;
							background: $color-primary-2;

							.h-roboto & {
								font-family: 'Roboto', sans-serif;
							}
						}

						// exception for .m-basic-header
						.m-basic-header ~ .l-main & {
							padding-left: 54px;
							border-bottom: 1px solid $color-secondary-9;

							@include mq(desktop) {
								padding-left: 30px;
							}
						}
					}
				}
			}
		}
	}

	.mm-btn {
		display: flex;
		vertical-align: middle;
		position: relative;
		align-items: center;
		margin-top: 5px;
		width: 100%;
		padding-left: 38px;
		height: 50px;
		max-width: 500px;
		cursor: pointer;
		border-right: 15px solid $color-primary-1;
		@include milo-regular(17px);
		text-align: left;
		outline: none;
		background-color: $color-primary-1;

		// only one line
		white-space: nowrap;
		overflow: hidden;

		@include mq(desktop) {
			display: none;
		}

		&:before {

			@include mq(desktop) {
				content: '';
				display: block;
				@include main-icon-expand-more;
				position: absolute;
				left: 0;
			}
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			// background: linear-gradient(to right, rgba($color-primary-1, 0), $color-primary-1); /* Standard syntax */
			@include main-icon-expand-more;
			height: 46px;

			// exception for .m-basic-header
			.m-basic-header ~ .l-main & {
				@include main-icon-expand-more;
				background-color: $color-primary-1;
				height: 46px;
			}
		}

		&.is-open {
			&:before {

				@include mq(desktop) {
					content: '';
					display: block;
					@include main-icon-expand-less;
					position: absolute;
					left: 0;
				}
			}

			&:after {

				// exception for .m-basic-header
				.m-basic-header ~ .l-main & {
					@include main-icon-expand-less;
					background-color: $color-primary-1;
					height: 46px;
				}
			}

			// exception for .m-basic-header
			.m-basic-header ~ .l-main & {
				border-radius: 4px 4px 0 0;
			}
		}

		// exception h-roboto
		.h-roboto & {
			font-family: 'Roboto', sans-serif;
		}

		// exception for .m-basic-header
		.m-basic-header ~ .l-main & {
			padding: 12px 50px 12px 18px;
			border: 2px solid $color-primary-1;
			border-radius: 4px;
			background: $color-primary-2;
			text-overflow: ellipsis;
			width: 100%;
		}
	}

	.mm-loader {
		display: block;
		position: absolute;
		right: 5px;
		top: 50%;
		width: 24px;
		height: 24px;
		margin: -12px 0 0 0;
		@include main-icon-refresh;

		// spin animation
		-webkit-animation: spin 0.8s infinite linear;
		animation: spin 0.8s infinite linear;

		@keyframes spin {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}

			100% {
				-webkit-transform: rotate(359deg);
				transform: rotate(359deg);
			}
		}

		@-webkit-keyframes webkit-spin {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}

			100% {
				-webkit-transform: rotate(359deg);
				transform: rotate(359deg);
			}
		}

		@-moz-keyframes moz-spin {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}

			100% {
				-webkit-transform: rotate(359deg);
				transform: rotate(359deg);
			}
		}

		@-o-keyframes o-spin {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}

			100% {
				-webkit-transform: rotate(359deg);
				transform: rotate(359deg);
			}
		}

	}
}