/* a-basic-btn--white */
.a-basic-btn--white {
	background-color: $color-primary-2;

	&:hover {
		background-color: $color-secondary-25;
	}

	&:focus,
	&:active {
		background-color: $color-secondary-25;
		border: 2px solid $color-secondary-22 !important;

		.is-keyboard-focus & {
			border: 2px solid $color-focus-tab;
			background-color: $color-secondary-25;
		}
	}

	// exception if in .m-my-adac-stage
	.m-my-adac-stage & {
		border: 2px solid $color-secondary-1;

		@include mq(tablet) {
			border: none;
		}
	}

	// exception .o-layout-main-stage
	.o-layout-main-stage .oo-slide-content-wrapper .oo-box & {

		background-color: $color-primary-1;

		@include mq(desktop) {
			background-color: $color-primary-2;
		}

		&:hover {
			background-color: $color-primary-1;

			@include mq(desktop) {
				background-color: $color-secondary-8;
			}
		}

		&:focus {
			background-color: $color-primary-1;

			@include mq(desktop) {
				background-color: $color-secondary-8;
			}
		}
	}
}