.m-basic-calc-contact {
	padding-bottom: 0;
	display: flex;
	flex-flow: row wrap;
	padding-top: 0;
	justify-content: flex-end;

	.m-basic-calculator-content-box.h-background + & {
		padding-top: 40px;
	}


	.m-basic-calculator-tiles--classic + &,
	.mm-clone-wrapper + & {
		padding-top: 40px;
	}

	// exception .m-ves-rs-contentbox
	.m-ves-rs-contentbox + & {
		padding-top: 40px;
	}

	> .mm-contact-button {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin: 6px 0 40px 2px;
		order: 0;

		&--single-right {
			justify-content: flex-end;
		}

		&--reverse {
			flex-direction: row-reverse;
		}
	}

	> .mm-contact-badge {
		flex: 0 0 100%;
		margin: 30px 0 0;
		order: 2;

		@include mq(tablet) {
			flex: 0 1 250px;
			margin: 0;
			order: 1;
		}

		&:not(:empty) {
			padding: 0 0 0 15px;

			@include mq(tablet) {
				padding: 0;
			}
		}

		img {
			width: auto;
			height: auto;
			max-height: 150px;
			max-width: 250px;
		}

		& + .mm-contact {
			order: 1;

			@include mq(tablet) {
				flex: 1;
				order: 2;
			}
		}
	}

	> .mm-contact {
		display: flex;
		flex-flow: row nowrap;
		margin-left: 15px;

		@include mq(tablet) {
			justify-content: flex-end;
		}

		> .mm-contact-icon {
			padding-right: 10px;
			height: 50px;
			width: 50px;
		}

		> .mm-contact-content {
			display: flex;
			flex-flow: column nowrap;

			@include mq(tablet) {
				width: calc(420px - 50px);
			}

			> .mm-contact-head {
				line-height: 18px;
				padding-bottom: 5px;
				@include milo-bold();
			}

			> .mm-contact-text {
				display: flex;
				flex-flow: row wrap;
				align-items: baseline;

				> .mm-contact-tel {
					padding-right: 10px;
					@include milo-bold($font-size-24);
					font-weight: normal;
					max-width: none;
					text-decoration: none;
				}
			}


			> .mm-contact-components {
				margin-top: 40px;
			}
		}
	}
}