/* m-basic-text--asidesmall */
.m-basic-text--asidesmall {

	> .mm-flex {

		> .mm-aside {
			width: auto;
			min-width: 100px;
			margin-right: 20px;
			float: left;
		}

		> .mm-content {
			max-width: 700px;
		}
	}
}