/* a-basic-btn--mobile-full */
.a-basic-btn--mobile-full {
	display: flex;
	width: 100%;
	text-align: center;
	justify-content: center;
	max-width: inherit;

	@include mq(tablet) {
		display: inline-flex;
		width: auto;
	}
}