/* a-basic-btn */
.a-basic-btn {
	display: inline-flex;
	max-width: 340px;
	min-height: 36px;
	padding: 10px 22px 7px 23px;
	background: $color-primary-1;
	text-decoration: none;
	cursor: pointer;
	text-align: left;
	font-weight: 300;
	border-radius: 4px;
	outline: none;
	position: relative;
	@include milo-bold(14px);
	border: 2px solid $color-secondary-22;
	@extend %focus-border-buttons;

	&:hover {
		text-decoration: none;
		background-color: $color-action-btn2;
	}

	.h-roboto & {
		font-family: 'Roboto', sans-serif;
	}

	//exception l-form-general
	.l-form-general .ll-row--location-desc & {
		margin-top: 7px;
	}

	//Exception survey
	.m-basic-layer-survey section & {
		&.h-space-s {
			margin-bottom: -20px;
		}

		&:nth-child(2) {
			margin-top: 30px;

			@include mq(tablet) {
				margin-left: 10px;
				margin-top: initial;
			}
		}
	}

	//exception for some buttons in sticky footer which should not be visible unless user scrolled to bottom
	&.is-initial-hidden {
		visibility: hidden;

		.is-button-visible & {
			visibility: visible;
		}
	}

	&.first-child {
		margin-top: -60px !important;
	}

	&.last-child {
		margin-top: 60px;
	}

	&[href^="mailto:"] {
		padding: 10px 22px 7px 40px;

		&:before {
			left: -5px;
			top: -5px;
		}
	}

	&--search {
		margin-left: 20px;
		padding: 14px 22px 14px 29px;
		background: $color-primary-1;
		min-height: 50px;
	}

	&--text-center {
		text-align: center;
	}

	&--center {
		margin: 0 auto;
	}

	&--large {
		max-width: 420px
	}

	&--general {
		min-height: 55px;
		padding-top: 18px;
	}

	// ***
	// .a-basic-btn animation classes
	// ***

	// .a-basic-btn height anim
	&.is-height-animate {

		&.is-hidden-animate:not(.is-shown-animate) {
			display: block;
			max-height: 0;
			min-height: 0;
			padding: 0;
			border: 0;
			transition: min-height .5s ease-in-out .8s;
		}
	}

	// .a-basic-btn fade out
	&.is-hidden-animate {
		opacity: 0;
		transition: opacity .5s ease-out;

		&:not(.is-shown-animate) {
			pointer-events: none;
		}
	}

	// .a-basic-btn fade in
	&.is-shown-animate {
		opacity: 1;
		visibility: visible;
		transition: opacity .5s ease-out;

		&.is-shown-animate-delay {
			&--200 {
				transition-delay: .2s;
			}

			&--600 {
				transition-delay: .6s;
			}
		}
	}

	// exception for .m-basic-newsbox
	.m-basic-newsbox .mm-content & {
		margin: 14px 0;
	}

	.mm-layer-tt-buttons & {
		display: block;

		&.h-space-s {
			margin-bottom: 15px;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	// Exception ll-row--mgl-discount
	.ll-row--mgl-discount > & {
		align-self: flex-start;
	}

	@include mq(print) {
		display: none;
	}

	&.is-disabled {
		background: $color-secondary-10;
		color: $color-secondary-3;
	}

	&.h-space-s {
		margin-bottom: 0;

		// exception if basicbtn is inside of l-form--labelontop
		.l-form--labelontop & {
			margin-bottom: 15px;
		}
	}

	&.h-space-m {
		margin-bottom: 20px !important;
	}

	// allow bottom space if the element is not wrapped with outer atom, molecule or organism
	&--allow-bottom-space {
		&.h-space-s {
			margin-bottom: 15px;
		}
	}

	.ll-depend-change & {
		&.h-space-s {
			margin-bottom: 15px;
		}
	}

	// ########## exceptions ##########

	.m-basic-upload.is-error & {
		border-color: $color-action-alert;
	}

	// exception for ADAC Maps
	.m-adac-maps & {
		margin-right: auto;

		&--search {
			display: inline-block;
			margin-right: 15px;
			position: relative;
		}
	}

	.m-adac-maps > .mm-maps-mgl-wrapper > .mm-result-list-wrap li p > span > & {
		margin-top: 10px;
	}

	.m-adac-maps > .mm-maps-mgl-wrapper > .mm-result-list-wrap li.is-highlight & {
		background-color: $color-action-btn;
	}

	// exception .m-adac-maps--cardamage when btn is overlaying picture elem
	.m-adac-maps.m-adac-maps--cardamage > .mm-maps-mgl-wrapper > .mm-map-wrapper > picture + & {
		@include absolute($top: 10px, $left: 10px);
	}

	.m-basic-text.h-space-s + & {
		margin-top: 7px;
	}

	.m-basic-text--center & {
		left: 50%;
		transform: translateX(-50%);
	}

	// exception .m-basic-hint -> horizontal orientation
	.m-basic-hint .mm-flex-container & {
		height: fit-content;
	}

	// Plain Input field display none in beginning
	&.js-edit-plain--abort-edit,
	&.js-edit-plain--save-edit {
		display: none;
	}

	// organism: BasicForm
	.o-basic-form .oo-row > & {
		float: right;
	}

	// organism: LayoutMainStage
	.o-layout-main-stage & {
		position: relative;
		bottom: auto;
		width: calc(100% - 30px);
		margin: 0;

		@include mq(tablet) {
			width: auto;
		}
	}

	// exception for oo-flex-box-content in a organism: LayoutMainStage
	.o-layout-main-stage .oo-flex-box-content &:first-child {
		margin: 0 0 20px 0;

		@include mq(tablet) {
			margin: 0;
		}
	}

	.m-basic-teaser-flex & {
		width: auto;
	}

	.o-layout-main-stage--legalprotection & {
		position: absolute;
	}

	.o-layout-main-stage .oo-box--col-3 & {
		position: static;
		width: 100%;
		margin: 10px 0 0 0;

		@include mq(tablet) {
			position: relative;
			bottom: auto;
			width: auto;
			margin: 0;
		}
	}

	.o-layout-main-stage .oo-slide-content-wrapper > .oo-box > & {
		width: auto;

		// exception if no copy text is present
		&:first-child {

			@include mq(desktop) {
				float: left;
			}

		}
	}

	// organism: BasicClusterBox
	.o-basic-cluster-box & {
		width: 100%;
		//https://www.adac.de/mitgliedschaft/vorteilswelt/
		//buttons dont break with inline-flex
		display: table;

		@include mq(tablet) {
			width: 210px;
		}

		@include mq(print) {
			display: inline-block;
			width: 210px;
		}

		&--big {
			width: 262px;
		}
	}

	// organism: BasicClusterBox
	.o-basic-cluster-box &:not(.h-space-m):not(.h-space-l) {
		margin: 5px 0;

		@include mq(tablet) {
			margin: 0 0 5px 0;
		}
	}


	.o-basic-cluster-box .mm-calc & {
		@include absolute($bottom: 15px, $right: 15px);
		width: auto;
		margin-bottom: 0;
		overflow: hidden;

		@include mq(print) {
			width: auto;
			margin-bottom: 0;
			overflow: hidden;
			@include absolute($bottom: 21px, $right: 21px);
		}

		@include mq(tablet) {
			@include absolute($bottom: 10px);
		}
	}

	.o-basic-cluster-box h3 + & {
		margin-top: 10px;
	}

	// organism: LayoutHeaderMetanav
	.o-layout-header-metanav & {
		margin: 0 0 15px 0;
		width: 100%;
		background-color: $color-primary-2;

		&:last-child {
			margin-bottom: 0;
		}
	}

	// organism: m-ves-calculator-short
	.m-ves-calculator-short & {
		position: absolute;
		right: 20px;
		bottom: 20px;
	}

	// ll-main-triangle

	.ll-main-triangle & {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -57px;
		margin-top: -30px;

		@include mq(tablet) {
			top: 50%;
			margin-top: -30px;
		}
	}

	// basic table
	.m-basic-table &:not(.a-basic-btn--inline) {
		display: block;
		width: 100%;
		margin: 3px 0;

		@include mq(tablet) {
			width: auto;
		}
	}

	.m-basic-table--tests & {
		padding-top: 5px;
		padding-bottom: 5px;
		margin-bottom: 10px;
	}

	// exception for m-basic-table--break
	.m-basic-table--break & {
		margin-bottom: 15px;
		max-width: 200px;

		// exception for js-add-to-compare-sticky in mobile version
		&.js-add-to-compare-sticky {
			display: inline-block;
			max-width: 100%;
			text-align: center;
		}
	}

	// exception when in ves-rs-sticky
	.mm-layer-links & {
		float: left;
		@include milo-regular($font-size-16);

		.h-roboto & {
			font-family: 'Roboto', sans-serif;
		}

		&--details {
			padding-left: 25px;
			padding-right: 10px;
			background: transparent;
			display: inline-block;

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-expand-less;
				position: absolute;
				left: -10px;
				top: -2px;
			}

			&.is-opened {

				&:before {
					content: '';
					display: inline-block;
					@include main-icon-expand-more;
					position: absolute;
					left: -10px;
				}
			}
		}
	}

	// exception when in ves-rs-sticky
	.mm-layer-content-inner & {
		&--offer {
			order: 2;
			align-self: flex-end;
			margin: 0 0 6px 0;
			z-index: 52;

			@include mq(tablet) {
				margin: -80px 0 0 0;
			}

			@include mq(desktop-l) {
				order: 3;
				@include absolute($right: -120px, $top: -1px);
				margin: 0;
				align-self: auto;
			}
		}
	}

	// exception when in ves-rs-sticky + ves-rs-stickybar
	.m-tabcontent-inside &,
	.mm-layer-inner & {
		margin-left: auto;
		margin-right: 0;
		margin-top: 20px;

		@include mq(desktop) {
			margin-right: 30px;
		}
	}

	// exception
	.mm-flex--row-reverse .mm-content & {
		display: inline-block;
		margin-right: 15px;

		@include mq(tablet) {
			margin-right: 35px;
		}
	}

	// exception
	.ll-dashboard &,
	.ll-dashboard-right &,
	.ll-dashboard-left & {
		@include absolute($bottom: 20px);
	}

	// exception .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration > & {
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}
	}

	// exception .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration > .m-my-adac-dashboard-list > .mm-wrapper > & {
		margin: 40px auto 0 auto;
		left: 50%;
		transform: translateX(-50%);
	}

	// exception .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration .mm-upload-container .mm-row-content > .m-basic-upload &,
	.m-ves-rs-contentbox--damagedeclaration .mm-tile-container .mm-row-content > .m-basic-upload & {

		width: 215px;

		@include mq(desktop) {
			width: 100%;
		}
	}

	// exception .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration .mm-upload-container .mm-row--center > .m-basic-upload & {
		&:before {
			content: '';
			@include absolute($top: 4px, $left: 170px);
			width: 22px;
			height: 22px;
			@include damage-declaration-icon-camera;

			@include mq(desktop) {
				display: none;
				content: none;
			}
		}
	}

	.m-ves-rs-contentbox--damagedeclaration .mm-upload-container .mm-row-content--button-wrap > &,
	.m-ves-rs-contentbox--damagedeclaration .mm-tile-container .mm-row-content--button-wrap > & {

		&:not(:only-child):not(:last-child) {
			margin-bottom: 10px;
		}
	}


	// exception for upload-btns
	// hide button on default
	.m-ves-rs-contentbox--damagedeclaration .mm-row-content--left ~ .mm-row-content--button-wrap > & {
		&.js-upload-button {
			display: none;
		}
	}

	// exception for upload-btns
	// show button if prev upload-input is not js-file-stored
	.m-ves-rs-contentbox--damagedeclaration .mm-row-content--left:not(.js-file-stored) ~ .mm-row-content--button-wrap > & {
		&.js-upload-button {
			display: block;
		}
	}

	.m-ves-rs-contentbox--damagedeclaration .mm-additional-cost .mm-row-content--right ~ .mm-row-content--button-wrap > & {
		&.js-upload-button {
			display: none;
		}
	}

	.m-ves-rs-contentbox--damagedeclaration .mm-additional-cost .mm-row-content--right:not(.js-file-stored) ~ .mm-row-content--button-wrap > & {
		&.js-upload-button {
			display: block;
		}
	}

	.m-ves-rs-contentbox--damagedeclaration > .l-form > .ll-fieldset.js-all-files-stored & {
		&.js-upload-button {
			display: none;
		}
	}

	// exception for basic-calculator-content-box-newform
	.m-basic-calculator-content-box-newform & {
		width: fit-content;
	}

	.l-main-content .ll-rating .ll-rating-left & {
		position: static;
	}

	&--close {
		&:hover {
			background-color: unset;
			text-decoration: underline;
		}
	}

	&--clone {

		// exception .m-ves-rs-contentbox--damagedeclaration
		// exception .m-basic-calculator-content-box
		.m-ves-rs-contentbox--damagedeclaration .mm-upload-container > .mm-row &,
		.m-ves-rs-contentbox--damagedeclaration .mm-tile-container > .mm-row & {

			position: relative;
			width: 100%;
			max-width: 215px;
			margin-top: -5px;

			@include mq(tablet) {
				max-width: 300px;
				margin-top: 0;
			}

			// HINT: use general modifier --upload instead of this
			&:before {
				content: '';
				@include absolute($top: -2px, $right: -50px);
				@include damage-declaration-icon-camera;

				@include mq(desktop) {
					display: none;
					content: none;
				}
			}

			&.is-disabled {
				&:before {
					display: none;
					content: none;
				}
			}
		}

		.m-basic-calculator-content-box .ll-row &.h-space-s:not(:last-child),
		.m-ves-rs-contentbox--damagedeclaration .ll-row &.h-space-s:not(:last-child) {
			margin-bottom: 10px;
		}
	}

	.m-adac-maps--mgldiscountmaps & {
		&--search {
			margin-right: 0;
		}
	}

	// organism: BasicClusterBox
	.ll-main-center--mgldiscount & {
		margin: 5px 0 10px;

		@include mq(tablet) {
			margin: 0 0 5px 0;
		}
	}

	// Exception autocomplete-suggestion
	.autocomplete-suggestion & {
		padding-top: 6px;
		margin: 0 auto;
	}

	// .m-basic-list--teaser-center
	.o-layout-main-stage .oo-box--teaser-center > & {
		@include absolute($bottom: auto, $left: 20px);
		max-width: 150px;

		@include mq(tablet) {
			left: auto;
			right: 38px;
			bottom: 25px;
			max-width: 100%;
		}
	}

	.l-form--sticky-contact & {
		margin-top: 5px;
	}

	// exception .m-basic-list-wrapper
	.m-basic-list-wrapper > & {
		margin: 0 auto;

		@include mq(tablet) {
			margin: 0 20px;
		}
	}

	.m-basic-form-footer-sticky & {
		max-width: 100%;
		width: 100%;
		text-align: center;

		@include mq(tablet) {
			width: auto;
			flex-shrink: 0;
		}

		@include mq(desktop) {

			flex-shrink: 1;
		}
	}

	.m-basic-form-footer-sticky .mm-btnbox-layer & {
		max-width: 100%;
		width: 100%;
		text-align: center;
	}

	.m-ves-tab--filter .mm-btn-box & {
		margin: 0 auto;
	}

	// Exception: m-basic-cookie
	.m-basic-cookie > .m-cookie-center & {
		padding-left: 15px;
		padding-right: 15px;
	}

	// exception .m-basic-radius-search
	.m-basic-radius-search > .l-form .ll-fieldset + & {
		display: table;
		margin-left: 10px;
		width: auto;
		margin-bottom: 15px;
	}

	.m-basic-radius-search > .l-form-general .ll-fieldset + & {
		display: table;
		margin-left: 10px;
		width: auto;
		margin-bottom: 10px;
	}

	.m-basic-table-toggle & {
		width: 100%;
		display: inline-block;

		@include mq('tablet') {
			width: auto;
		}
	}

	// exception for button in stage with larger slide content wrapper
	.oo-slide-content-wrapper--big .l-main-cols & {
		width: auto;
		margin-top: 15px;

		@include mq(tablet) {
			margin-top: 0;
		}
	}

	.oo-box-flex & {
		width: auto;
	}

	// exception ll-fieldset--search
	.l-form .ll-fieldset--search .ll-row .ll-col-data & {
		float: right;

		@include mq(tablet) {
			float: left;
			margin-left: 10px;
		}
	}

	// exception ll-fieldset--select
	.l-form .ll-fieldset--select .ll-row .ll-col-data & {
		@include mq(tablet) {
			float: left;
			margin-left: 20px;
		}
	}

	// exception .m-basic-header
	.m-basic-header > .mm-navi-wrap > .mm-main > .mm-main-btn > .mm-section > .mm-layer > .mm-layer-content & {
		display: block;
		max-width: none;
		min-height: 40px;
		margin-bottom: 18px;
		padding: 12px 24px;
		text-align: center;
		@include font-size($font-size-14, 16px);
	}

	// exception .m-basic-calculator-content-box mm-upload-container
	.m-basic-calculator-content-box > .mm-upload-container > .mm-row > .mm-upload-button-wrap > & {

		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}

	.m-ves-rs-contentbox--damagedeclaration .mm-row-content--button-wrap > & {
		width: 100%;
	}

	// exception .h-btn-resp-alignment
	.h-flex-resp-alignment & {
		max-width: inherit;
		text-align: center;
		justify-content: center;

		@include mq(tablet) {
			height: fit-content;
		}
	}
}

input[type="submit"].a-basic-btn {
	padding: 6px 22px 6px 29px;
}