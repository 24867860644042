/* a-basic-stopper--reverse */
.a-basic-stopper--reverse {

	.aa-inner-content {

		border-top-right-radius: 0;
		border-bottom-left-radius: 12px;

		&::before {
			@include mq(tablet) {
				top: -15px;
				bottom: auto;
				left: auto;
				right: -2px;
				border-top-color: transparent;
				border-right-color: $color-secondary-1;
			}
		}

		&:after {
			@include mq(tablet) {
				top: -9px;
				bottom: auto;
				left: auto;
				right: 0;
				border-top-color: transparent;
				border-right-color: $color-primary-2;
			}

			@include if-parent('.a-basic-stopper--reverse.a-basic-stopper--blue') {
				@include mq(tablet) {
					border-right-color: $color-secondary-11;
				}
			}
		}
	}
}