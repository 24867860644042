/* m-adac-maps--searchresults */
.m-adac-maps--searchresults {
	margin-bottom: 60px;

	address {
		margin-bottom: 15px;

		> span {
			display: block;
		}
	}

	> .mm-switch-map {
		background-color: $color-primary-2;
		overflow: visible;

		> a:focus {
			outline-color: transparent;
		}

		&.is-stuck {
			padding-top: 5px;
		}

		> .l-main-cols {
			> .ll-main-cols-m {
				> .icon-maps {
					@include main-icon-map;
					width: 44px;
					height: 44px;
					display: inline-block;
					position: relative;
					top: -10px;
				}

				> .icon-list {
					@include main-icon-list;
					display: inline-block;
					width: 44px;
					height: 44px;
					position: relative;
					top: -10px;
				}
			}
		}

		.mm-switch-map-headline {
			display: inline-block;
		}
	}

	> .mm-maps-mgl-wrapper {
		> .mm-result-list-wrap {

			// Namics has extra div container per h2 and ul
			> div {

				&:last-child {
					> .mm-result-list {
						margin-bottom: 0;
					}
				}

				> .mm-result-list {
					margin-bottom: 20px;
				}
			}

			> .mm-result-list {
				&:not(:last-child) {
					margin-bottom: 20px;
				}

				> li {
					padding-top: 20px;
					padding-bottom: 20px;

					&:last-child {
						border-bottom: 1px solid $color-secondary-9;
					}
				}
			}

			.mm-result-list {
				.mm-pin {
					width: 50px;
					top: 10px;
					@include main-icon-place-light-grey-36px;
					@include absolute(-5px, $right: -17px);

					&-orange {
						@include main-icon-place-orange-36px;
					}

					&-lightblue {
						@include main-icon-place-lightblue-36px;
					}

					&-yellow {
						@include main-icon-place-yellow-36px;
					}

					&-green {
						@include main-icon-place-green-36px;
					}

					> span {
						display: inline-block;
						margin-top: 8px;
						width: 100%;
					}
				}
			}

			// display output as digits
			.mm-digits {
				@extend %display-as-digits;
				font-size: 26px;
			}
		}

		> .mm-map-wrapper {
			z-index: 10;

			> .mm-distance-wrapper {
				display: flex;
				margin-bottom: 20px;
				width: 100%;

				> .mm-distance-label {
					width: 35%;
					padding-top: 11px;
					font-weight: bold;
				}
			}
		}
	}
}