.m-basic-toggle-container {
	> .mm-formular {
		> .mm-toggle-container {
			border-bottom: 1px solid $color-secondary-9;

			&:first-child {
				border-top: 1px solid $color-secondary-9;
			}

			> .mm-toggle-headline {
				position: relative;
				display: flex;
				justify-content: flex-start;
				padding: 15px 10px 15px 40px;
				cursor: pointer;

				&:after {
					position: absolute;
					content: '';
					display: block;
					@include main-icon-keyboard-arrow-down;
					left: 5px;
					top: 5px;
				}

				&.is-current {
					&:after {
						@include main-icon-keyboard-arrow-up;
						left: 4px;
						top: 5px;
					}
				}

				> .mm-close {
					position: absolute;
					right: 15px;
					top: 10px;
					cursor: pointer;
					width: 48px;
					height: 48px;
					overflow: hidden;
					display: block;

					&:after {
						content: '';
						position: absolute;
						left: -10px;
						top: -5px;
						@include main-icon-cancel;
					}

					img {
						display: none;
					}
				}

				> .mm-t-index {
					padding-left: 5px;
				}
			}


			> .mm-toggle-content {
				display: none;
				margin-top: 25px;

				&.is-current {
					display: block;
				}

				.a-basic-btn {
					margin-left: auto;
					margin-right: auto;
				}
			}
		}

		> .a-basic-btn {
			margin-top: 30px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}