/* o-fdl-creditcardconfig */
.o-fdl-creditcardconfig {
	position: relative;

	> .oo-togglecontainer {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 30px;
		z-index: 1;

		@include mq(tablet) {
			flex-flow: row wrap;
		}

		> .oo-packagecontainer {
			position: relative;
			width: 100%;
			margin: 0;
			box-sizing: border-box;

			@include mq(tablet) {
				width: 32.5%;
			}

			&:first-child {
				> .oo-packagebox {
					padding-top: 16px;

					@include mq(tablet) {
						padding-top: 40px;
					}
				}
			}

			&:last-child {
				> .oo-packagebox {
					padding-bottom: 16px;

					@include mq(tablet) {
						padding-bottom: 0;
					}
				}
			}

			> .oo-packagebox {
				position: relative;
				width: 100%;
				margin: 0;
				padding: 8px 8px 0 8px;
				background: #ededed;
				overflow: hidden;
				box-sizing: border-box;

				@include mq(tablet) {
					padding-top: 40px;
				}

				&:first-child {
					padding-top: 20px;

					@include mq(tablet) {
						padding-top: 40px;
					}
				}

				&:last-child {
					padding-bottom: 20px;

					@include mq(tablet) {
						padding-bottom: 10px;
					}
				}

				> .oo-price-old {
					display: block;
					width: 100%;
					padding-right: 10px;
					text-align: right;
					text-decoration: line-through;

					@include mq(tablet) {
						position: absolute;
						top: 20px;
						right: 0;
						padding-right: 20px;
					}
				}

				> .oo-input {
					justify-content: flex-start;
					padding-right: 10px;

					> .oo-checkbox {
						> .a-basic-input-checkbox {

							@include mq(tablet) {
								margin-right: 10px;
							}
						}
					}
				}

				> .oo-content-items {
					margin-top: 2px;

					@include mq(tablet) {
						margin-top: 15px;
						height: auto;
					}

					> .m-basic-toggle {

						//custom scrollbar container
						.mm-content {

							&.jspScrollable {

								&:focus {
									outline: none;
								}

								> .jspContainer {

									//scrollbar wrapper
									> .jspVerticalBar {

										width: 5px;
										right: 10px;

										> .jspTrack {
											background: $color-secondary-5;

											> .jspDrag {
												background: $color-secondary-2;
											}
										}

										> .jspArrow {

											// dont show arrow-btns
											background: $color-secondary-4;
											cursor: default;
											height: 15px;
										}
									}

									> .jspHorizontalBar {
										display: none;
									}
								}
							}
						}
					}
				}
			}

			> .oo-special-arrows {
				display: none;
				position: absolute;
				width: 100%;
				margin: 1px 0 0 -6px;

				&--posTop {
					top: -270px;

					@include mq(tablet) {
						top: -43px;
					}

					&.mobileArrow {
						top: -45px;
					}

					> svg {
						transform: rotate(180deg);
					}
				}

				> svg {
					display: block;
					width: 104%;
					padding: 1px 0;
					margin: auto;

					> * {
						stroke: $color-primary-2;
						stroke-width: 2;
					}
				}
			}
		}
	}


	> .oo-specialbox {
		display: flex;
		position: relative;
		justify-content: space-between;
		width: 100%;
		margin: 36px 0 30px;
		padding: 20px 10px 12px 8px;
		background: $color-primary-1;

		@include mq(tablet) {
			padding: 38px 10px 19px 10px;
		}

		@include mq(desktop) {
			padding: 38px 19px 14px 10px;
		}

		&--posTop {
			margin: 10px 0 34px;
		}

		> .oo-input {
			display: block;

			> .oo-checkbox {

				> .a-basic-input-checkbox {

					> div {
						background-position: -2px 0px;
						padding-top: 1px;
					}

					label {
						@include milo-bold($font-size-18);

						@include mq(tablet) {
							@include milo-bold($font-size-21);
						}
					}
				}
			}
		}

		> .oo-pricing-box {
			margin-top: -2px;

			@include mq(tablet) {
				min-width: 105px;
			}

			> .oo-price-text {
				display: block;
				text-align: right;
				@include milo-bold($font-size-18);

				@include mq(tablet) {
					@include milo-bold($font-size-21);
				}

				> .oo-price-old {
					display: block;
					text-align: right;
					text-decoration: line-through;
					@include milo-regular($font-size-15);
					white-space: nowrap;

					@include mq(tablet) {
						position: absolute;
						top: 15px;
						right: 0;
						padding-right: 10px;
					}

					@include mq(desktop) {
						padding-right: 20px;
					}
				}

				> .oo-price-value {
					white-space: nowrap;
				}

			}

			> .oo-price-time {
				display: block;
				margin-top: -2px;
				text-align: right;
				@include milo-regular($font-size-15);
			}
		}
	}

	> .oo-fdl-stickyfooter {
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		padding: 20px 0 10px 0;
		margin-bottom: 5px;

		background: $color-primary-2;

		border-top: 3px double $color-secondary-1;
		border-bottom: 1px solid $color-secondary-1;

		z-index: 99;

		@include mq(tablet) {
			flex-flow: row nowrap;
			padding: 20px 10px 10px 10px;
		}

		@include mq(desktop) {
			padding: 10px 10px 0 10px;
		}

		&.is-sticky {
			position: fixed;
			left: 50%;
			width: calc(100% - 40px);
			max-width: 927px;
			margin: 0 0 -2px 0;
			transform: translate(-50%);
			animation-name: footerRollIn;
			animation-delay: 1s;

			@include mq(tablet) {
				max-width: calc(928px - 100px);
				width: calc(100% - 60px);
			}

			@include mq(desktop) {
				max-width: calc(928px - 30px);
			}

			&.is-sidenav {

				@include mq('tablet') {
					max-width: calc(928px - 90px);
				}

				@include mq(desktop) {
					left: auto;
					transform: none;
					width: calc(82% - 75px);
					max-width: calc(928px - 70px);
				}

				@include mq(desktop-l) {
					width: 100%;
					max-width: calc(928px - 80px);
				}

				@include mq(desktop-xl) {
					left: 50%;
					transform: translate(-50%);
					max-width: calc(928px - 30px);
				}
			}
		}

		> .oo-stickyfooter-text {
			width: 100%;

			@include mq(tablet) {
				display: flex;
				flex-flow: column wrap;
				flex-shrink: 4;
				flex-basis: 23%;
				width: auto;
				margin-right: 8px;
				padding-top: 10px;
			}

			@include mq(desktop) {
				flex-basis: auto;
				margin-right: 10px;
			}

			> h3 {
				letter-spacing: -0.5px;
				padding-bottom: 2px;
			}

			> p {
				margin-bottom: 3px;
			}
		}

		> .oo-stickyfooter-input {
			display: flex;
			flex-flow: row wrap;
			flex: 1;
			margin-top: 5px;

			@include mq(tablet) {
				flex-flow: row nowrap;
				flex-shrink: 1;
				margin-top: 0;
			}

			@include mq(desktop) {
				flex-grow: 20;
			}

			> .a-basic-input-radio {

				margin-top: 12px;
				margin-right: 5px;

				@include mq(tablet) {
					margin-top: 20px;
				}

				@include mq(desktop) {
					margin-right: 15px;
				}

				&:last-child {
					margin-right: 5px;
				}

				> input + label + div {
					padding-left: 30px;
				}
			}

			img {
				width: 70px;
				cursor: pointer;
				margin-top: -12px;

				@include mq(tablet) {
					width: 98px;
					margin-top: -20px;
				}
			}
		}

		> .oo-stickyfooter-right {

			flex-flow: row nowrap;
			margin-top: 5px;
			padding-top: 10px;

			@include mq(tablet) {
				display: flex;
				flex-shrink: 1;
				flex-flow: row wrap;
				margin-top: 0;
			}

			@include mq(desktop) {
				flex-grow: 1;
			}

			> .oo-stickyfooter-price {
				display: flex;
				flex-flow: column;
				flex: 1;

				@include mq(tablet) {
					padding-right: 12px;
					flex: 1 0 auto;
				}

				> .oo-monthly {

					@include mq(tablet) {
						display: flex;
						flex-flow: column nowrap;
						align-items: flex-end;
					}

					@include mq(desktop) {
						flex-flow: row nowrap;
						justify-content: space-between;
					}

					> span {
						&:not(.oo-monthly-val) {

							@include mq(tablet) {
								order: 2;
								margin-top: -6px;
							}

							@include mq(desktop) {
								order: 1;
								margin-top: -6px;
								line-height: 32px;
							}

						}
					}

					> .oo-monthly-val {
						white-space: nowrap;
						@include milo-bold($font-size-22);

						@include mq(tablet) {
							order: 1;
							@include milo-bold($font-size-30);
						}

						@include mq(desktop) {
							order: 2;
							justify-content: space-between;
						}
					}
				}
			}

			> .oo-stickyfooter-button {
				margin-top: 15px;
				padding-left: 8px;

				@include mq('tablet') {
					margin-top: 8px;
					margin-left: auto;
				}

				@include mq('desktop') {
					margin-left: auto;
				}

				> a {
					white-space: nowrap;
				}
			}
		}
	}

	.oo-input {
		display: flex;

		> .oo-pricing-box {
			position: relative;
			margin-left: auto;
			text-align: right;

			> .oo-price {
				display: block;
				@include milo-bold($font-size-21);
				white-space: nowrap;
			}

			> .oo-price-time {
				@include milo-regular($font-size-13);
			}
		}
	}

	@at-root .pkg-tipp-layer-btncontainer {
		display: flex;
		flex-flow: row wrap;

		> .ll-main-cols-m {
			width: auto;
			border-left: none;

			&:first-child {
				border-width: 40px;
			}

			&:last-child {
				margin-left: 1px;
				padding-top: 4px;

				@include mq('tablet') {
					margin-left: 0;
				}
			}
		}

	}
}