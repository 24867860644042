//m-fdl-calculator--creditreplacement
.m-fdl-calculator--creditreplacement {

	.mm-triangle {
		@include mq(tablet) {
			top: 158px;
		}
	}

	.mm-creditinfo-container {
		margin: 0 0 20px 0;

		a {
			text-decoration: none;
		}

		.js-negativ,
		.js-equals,
		.js-positiv {
			display: none;
		}

		.mm-claim-headline {
			font-weight: bold;
		}
	}

	.mm-infotext {
		@include mq(tablet) {
			clear: both;
			float: left;
		}
	}
}