// .m-basic-radius-search--short
.m-basic-radius-search--short {

	> .l-form {

		> .l-main-cols {

			flex-flow: column nowrap;
			align-items: flex-start;
			justify-content: flex-start;

			@include mq(tablet) {
				flex-flow: row nowrap;
			}

			> .ll-fieldset {

				&:first-of-type {
					margin-right: 0;
					width: 100%;

					@include mq(tablet) {
						margin-right: 10px;
						flex: 0 0 340px;
					}
				}

				&:last-of-type {
					flex: 0;
					width: 180px;
					padding-left: 30px;

					@include mq(tablet) {
						padding-left: 0;
						flex: 0 0 180px;
					}
				}
			}
		}
	}
}