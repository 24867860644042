/* a-basic-input-text--edit*/
.a-basic-input-text--unit {
	display: flex;
	align-items: center;
	overflow: visible;

	.aa-unit {
		padding-left: 10px;
		flex: 1 0 auto;
	}

	input {
		flex: 0 0 auto;
	}
}