/* m-basic-teaser-across--rating */
.m-basic-teaser-across--rating {
	.l-main-content & {
		background: none;

		&:first-child {
			padding-left: 0;
			padding-right: 0;
			padding-top: 0;
		}
	}

	> article {
		width: 201px;
		background: none;
		border: 1px solid $color-secondary-3;
		border-left: none;

		> header {
			padding: 10px;
			border-bottom: 1px solid $color-secondary-3;

			> .mm-rating-header {
				@include font-size($font-size-19);
				line-height: 20px;
			}

			.a-basic-rating {
				margin-right: 0;
			}
		}

		> section {
			padding: 10px;
			@include font-size($font-size-19);
			line-height: 20px;
			border-bottom: 1px solid $color-secondary-3;

			> .a-basic-rating {
				margin-bottom: 0;
			}
		}

		> figure {
			height: auto;

			&.is-empty {
				width: 200px;
				height: 200px;
			}

			> a {
				> img {
					width: 200px;
					height: auto;
				}
			}
		}

		.mm-article-content {
			background: none;
			border-top: 1px solid $color-secondary-3;
			padding-top: 10px;
			padding-bottom: 10px;
			height: 60px;
		}
	}
}