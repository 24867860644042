/* m-basic-image--auto-width*/
.m-basic-image--auto-width {

	> figure {

		> .mm-stopper-wrapper {

			> .mm-ratio-container {

				> picture {
					width: auto;
					max-width: 100%;

					> img {
						width: auto;
						max-width: 100%;

						.l-outer--my-adac-content & {
							margin: 0 auto;
						}
					}
				}
			}
		}
	}

}