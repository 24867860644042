// display none without !important
.h-none {
	display: none;
}

// marked text found by jQuery highlight
.h-search {
	padding: 0 1px;
	background: $color-secondary-9;
	font-weight: bold;
}

// for invisible content shown in lightboxcontent or after action
.h-invisible-lightboxcontent {
	visibility: hidden;
	height: 1px;
	overflow: hidden;
}

// smartphone only
.h-smartphone-only {
	@include mq(tablet) {
		display: none !important;
	}
}

// tablet
.h-tablet {
	display: none !important;

	@include mq(tablet) {
		display: block !important;

		&.l-main-cols {
			display: flex !important;
		}

		// exception span.h-tablet
		@at-root span#{&} {
			display: inline !important;
		}

		// exception tr.h-tablet
		@at-root tr#{&} {
			display: table-row !important;
		}

		// exception td.h-tablet
		@at-root td#{&} {
			display: table-cell !important;
		}

		// exception img.h-tablet in m-basic-header
		@at-root .m-basic-header > .mm-navi-wrap > .mm-main .mm-btn > img#{&} {
			display: inline !important;
		}

		@at-root .m-basic-header > .mm-navi-wrap > .mm-main .mm-btn > svg#{&} {
			display: inline !important;
		}
	}
}

// max tablet
.h-max-tablet {
	@include mq(desktop) {
		display: none !important;
	}
}

// tablet only
.h-tablet-only {
	display: none !important;

	@include mq(tablet) {
		display: block !important;
	}

	@include mq(desktop) {
		display: none !important;
	}

	// exception span.h-tablet-only
	@at-root span#{&} {
		display: none !important;

		@include mq(tablet) {
			display: inline !important;
		}

		@include mq(desktop) {
			display: none !important;
		}
	}
}

// desktop
.h-desktop {
	display: none !important;

	@include mq(desktop) {
		display: block !important;
	}
}

// desktop only
.h-desktop-only {
	display: none !important;

	@include mq(desktop) {
		display: block !important;
	}

	@include mq(desktop-l) {
		display: none !important;
	}
}

// desktop-l
.h-desktop-l {
	display: none !important;

	@include mq(desktop-l) {
		display: block !important;
	}
}

// max desktop-l
.h-max-desktop-l {
	@include mq(desktop-l) {
		display: none !important;
	}
}

// print only
.h-print-only {
	display: none !important;

	@include mq(print) {
		display: block !important;
	}
}

// screen only
.h-screen-only {

	@include mq(print) {
		display: none !important;
	}
}

// loggedin only
.h-loggedin-only {
	display: none !important;

	.l-outer--loggedin & {
		display: block !important;
	}
}

// loggedout only
.h-loggedout-only {
	.l-outer--loggedin & {
		display: none !important;
	}
}

// helper to generate space
// h-space-none
.h-space-none {
	margin-bottom: 0;
}

// h-space-s
.h-space-s {
	margin-bottom: 15px;
}

// h-space-m
.h-space-m {
	margin-bottom: 30px;
}

// h-space-l
.h-space-l {
	margin-bottom: 60px;
}

// h-space-resp
.h-space-resp {
	margin-bottom: 15px;

	@include mq(tablet) {
		margin-bottom: 30px;
	}

	@include mq(desktop) {
		margin-bottom: 60px;
	}
}

// h-space-resp-tablet
.h-space-resp-tablet {
	margin-bottom: 30px;

	@include mq(tablet) {
		margin-bottom: 60px;
	}
}

// h-img-fluid
.h-img-fluid {
	width: 100%;
	height: auto;
}

.h-img-fluid-smartphone-only {
	width: 100%;
	height: auto;

	@include mq(tablet) {
		width: auto;
	}
}

span.h-optional {
	@include milo-regular();

	// Exception for big headlines
	h1 > &,
	h2 > &,
	.h-h1 > &,
	.h-h2 > & {
		font-size: $font-size-16;
	}

	.h-roboto & {
		font-family: 'Roboto', sans-serif;
	}
}

// h-error
.h-error {
	color: $color-action-alert;
}

// for keeping folowing block elements aside
.h-inline-block-left {
	display: inline-block;
	margin-right: 15px;
}

// body.is-noscroll - prevents scrolling
body.is-noscroll {
	overflow-y: hidden;
	height: 100vh;
}

// body.is-noscroll-navi with fixed position - prevents scrolling
body.is-noscroll-navi {
	position: fixed;
	height: 100%;
	overflow-y: hidden;
}

.h-flex-tablet {
	@include mq(tablet) {
		display: flex;
	}
}

// h-flex-justify-space-between
.h-flex-justify-space-between {
	display: flex;
	justify-content: space-between;
}

// h-flex-resp-alignment
.h-flex-resp-alignment {
	display: flex;
	flex-direction: column;

	@include mq(tablet) {
		flex-direction: row-reverse;
		justify-content: space-between;
	}
}

// padding optional for webviews
.h-webview-outer {
	padding: 12px 15px;

	@include mq(tablet) {
		padding: 27px 30px;
	}
}

/* add style for orig ebot7 sticky-button */
.h-ebot7 {
	border: none;
	width: 60px;
	height: 60px;
	border-radius: 100%;
	cursor: pointer;
	text-align: center;
	background: #FFCC00;
	bottom: 135px;
	display: block;
	position: fixed;
	right: 10px;
	z-index: 9000000;
	box-shadow: rgba(0, 0, 0, 0.36) 0px 1px 10px;

	@include mq(tablet) {
		bottom: 15%;
	}
}

// float-right
.h-float-right {
	float: right;
}