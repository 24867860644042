.m-basic-sticky-service-contact {
	position: fixed;
	right: 10px;
	top: 30%;
	z-index: 1500;

	@include mq(tablet) {
		top: 54%;
	}

	> .mm-trigger {
		height: 45px;
		width: 45px;
		background: $color-primary-1 url(/assets/img/icons/icon-contact.svg) no-repeat center center;
		cursor: pointer;
		border-radius: 4px;
		border: 1px solid $color-primary-2;
	}

	> .mm-layer-details {
		height: 100vh;
		width: 100vw;
		position: fixed;
		background: $color-primary-2;
		left: 0;
		top: 0;
		padding: 10px 15px 0;
		display: none;
		max-height: 100vh;
		border: 1px solid $color-primary-2;

		@include mq(tablet) {
			width: 300px;
			right: 0;
			padding: 10px 10px 30px;
			left: auto;
			top: -450%;
			height: 570px;
			position: absolute;
			background: $color-secondary-10;

		}

		> .mm-tab-header {

			> .mm-header-list {
				display: flex;

				> .mm-tab-headline {
					padding: 0 7px;
					cursor: pointer;
					text-align: center;
					border-bottom: 2px solid $color-secondary-4;
					margin-bottom: 10px;
					position: relative;
					flex-grow: 1;

					@include mq(tablet) {
						width: auto;
					}

					&.is-active {
						border-bottom: 2px solid $color-primary-1;

						> .mm-tab-icon {
							position: absolute;
							width: 25px;
							height: 15px;
							background-image: url(/assets/img/icons/my-adac/icon-tab-myadac.svg);
							background-repeat: no-repeat;
							background-size: 13px 7px;
							left: calc(50% - 13px / 2);
							top: 34px;
						}
					}
				}

				> .mm-trigger-close {
					cursor: pointer;
					@include main-icon-close;
					height: 34px;
					border-bottom: 2px solid $color-secondary-4;
					width: 20px;

					@include mq(tablet) {
						position: absolute;
						right: 10px;
						bottom: 10px;
						width: 15px;
						height: 15px;
						border-bottom: 0;
					}
				}
			}
		}

		> .mm-tab-content {

			> .mm-inner-content {
				display: none;
				height: calc(100vh - 70px);
				overflow: auto;

				@include mq(tablet) {
					height: 490px;
				}

				&.is-active {
					display: block;
				}

				// custom scrollbar styles
				&.jspScrollable {

					&:focus {
						outline: none;
					}

					> .jspContainer {

						//scrollbar wrapper
						> .jspVerticalBar {
							width: 5px;
							right: 10px;

							> .jspArrow {
								background: $color-secondary-7;
								height: 0;
								cursor: default;

								@include mq(tablet) {
									background: $color-secondary-10;
								}
							}

							> .jspTrack {
								background: $color-secondary-5;

								> .jspDrag {
									background: $color-secondary-2;
									border-bottom: 1px solid $color-primary-2;
								}
							}
						}

						> .jspHorizontalBar {
							display: none;
						}
					}
				}

				.mm-scroll-content {
					h2 {
						padding-bottom: 5px;
						font-size: $font-size-22;

						@include mq(tablet) {
							font-size: $font-size-28;
						}
					}

					.mm-contact-hint {
						margin-bottom: 30px;

						&:last-child {
							margin-bottom: 0;
						}

						> .mm-hint-label {
							@include milo-bold();
						}

						> .mm-hint-number {
							@include milo-bold($font-size-20);
							text-decoration: none;
							margin-bottom: 5px;
							display: block;
							margin-top: 5px;
							padding: 5px 0 5px 40px;

							@include mq(tablet) {
								font-size: $font-size-24
							}
						}

						&--support {
							> .mm-hint-number {
								background: url(/assets/img/icons/icon-contact-phone-circle.svg) no-repeat left center;
							}
						}

						&--whatsapp {
							> .mm-hint-number {
								background: url(/assets/img/icons/icon-contact-whatsapp-02.svg) no-repeat left center;

								@include mq(tablet) {
									font-size: $font-size-22
								}
							}
						}

					}

					> .mm-location-info {
						margin-bottom: 10px;
					}

					> .mm-contact-img-text {
						display: flex;
						margin-bottom: 20px;

						> .mm-contact-img {
							margin-right: 10px;
						}

						> .mm-contact-text {
							> .mm-contact-name {
								@include milo-bold();
							}
						}
					}

					> .mm-chat-container {

						> .mm-chat-content {
							min-height: 210px;
							max-height: 210px;
							overflow: auto;
							scroll-snap-align: end;

							> .mm-chat {
								position: relative;
								background: $color-secondary-9;
								border-radius: 10px 10px 10px 0;
								margin-bottom: 20px;
								padding: 10px;

								&:after {
									content: '';
									position: absolute;
									bottom: 0;
									width: 0;
									left: 11px;
									height: 0;
									border: 12px solid transparent;
									border-top-color: $color-secondary-9;
									border-bottom: 0;
									border-left: 0;
									margin-left: -11px;
									margin-bottom: -12px;
								}

								&--ext {
									background: $color-secondary-10;
									border-radius: 10px 10px 0 10px;

									&:after {
										right: 0;
										left: auto;
										border-top-color: $color-secondary-10;
										border-right: 0;
										border-left: 12px solid transparent;
									}
								}
							}
						}

						> .mm-chat-text-container {
							margin-top: 10px;
							border-top: 1px solid $color-secondary-9;
							padding-top: 15px;

							> form {
								> .mm-chat-text-content {
									display: flex;
									align-items: flex-end;

									> .mm-chat-textarea {
										border: 1px solid $color-secondary-9;
										width: calc(100% - 30px);
										height: 75px;
										max-height: 75px;
										padding-left: 5px;
										padding-right: 5px;
									}

									> .mm-chat-submit {
										cursor: pointer;
										width: 30px;
										height: 20px;
										background: url(/assets/img/icons/icon-contact-enter.svg) no-repeat center center;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}