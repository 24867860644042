/* a-basic-input-text--ds-filter*/
.a-basic-input-text--ds-filter {
	.l-form-general & {
		input {
			height: 40px;
			padding-top: 0;
		}

		.aa-calendar {
			top: -1px;
		}

		.aa-location {
			bottom: -6px;
		}

		&:not(.a-basic-input-text--xs):not(.a-basic-input-text--date) {
			input {
				padding-top: 0;
			}
		}

		> .js-clear-button {
			top: 6px !important;
		}

		> .aa-col-label {
			top: 9px;
		}
	}
}