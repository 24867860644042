.m-my-adac-info-box--image {

	> .mm-info-box-wrapper {
		.mm-info-box-text {
			text-align: left;
		}

		.mm-button-wrapper {
			justify-content: flex-start;
		}
	}
}