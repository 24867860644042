/* m-basic-keywordfilter */
.m-basic-keywordfilter {

	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
	padding: 5px 0 0 0;

	@include mq(print) {
		display: none;
	}

	@include mq(tablet) {
		width: 483px;
	}

	&[data-backend="true"] {
		.mm-searchbtn {
			cursor: pointer;
		}
	}

	&:not(.h-space-m):not(.h-space-l) {
		margin-bottom: 15px;

		@include mq(tablet) {
			margin-bottom: 22px;
		}
	}

	// Exception ll-fieldset
	.l-form .ll-fieldset & {
		margin-left: 10px;
	}

	// exception for m-basic-teaser-across
	.m-basic-teaser-across & {
		@include mq(tablet) {
			width: auto;
			margin-bottom: 0;
			padding-top: 7px;
		}
	}

	.mm-searchbar {
		position: relative;

		> .is-floating {

			> input[value]:not([value=""]),
			> input:not(.is-empty),
			> input:placeholder-shown:not(.is-empty),
			> input:not([placeholder=" "]),
			> input:focus,
			> .js-clear,
			> .a-basic-input-text--clear {
				& ~ .aa-col-label {
					.l-form-general & {
						top: 7px;
					}
				}
			}
		}

		> button {
			position: absolute;
			top: 3px;
			right: 0;
			padding: 4px 3px 0 0;
			@include main-icon-search;
			text-indent: -10000px;
			cursor: default;
			width: 33px;
			height: 33px;

			.l-form-general & {
				top: 14px;
			}

			// exception for myAdac
			.l-outer--my-adac &,
			.l-outer--my-adac-content & {
				@include main-icon-search;
				width: 33px;
				height: 33px;
				top: 25px;
				bottom: auto;
				right: 10px;
			}
		}
	}

	.mm-controller {
		display: inline-block;
		float: right;

		@include mq(tablet) {
			margin: 5px 0 0 0;
		}

		> button {
			padding: 0 0 0 12px;
			outline: none;
			display: flex;
			position: relative;

			&:before {
				content: '';
				@include main-icon-link;
				top: -11px;
				position: absolute;
				left: -20px;
			}

			// exception for myAdac
			.l-outer--my-adac & {
				padding: 0 0 0 40px;
				@include main-icon-dashboard;
			}

			&:focus {
				color: $color-secondary-1;

				// exception for myAdac
				.l-outer--my-adac & {
					color: $color-secondary-1;
				}
			}

			&.is-active {
				color: $color-secondary-1;

				&:before {
					content: '';
					@include main-icon-arrow-drop-down;
					top: -10px;
				}

				// exception for myAdac
				.l-outer--my-adac & {
					@include main-icon-dashboard;
					border: solid 4px red;
					color: $color-secondary-1;
				}
			}

			&.js-focus {
				color: $color-secondary-1;

				// exception for myAdac
				.l-outer--my-adac & {
					color: $color-secondary-1;
				}
			}
		}
	}

	// exception for myAdac
	.l-outer--my-adac &:not(.m-basic-keywordfilter--select),
	.l-outer--my-adac-content &:not(.m-basic-keywordfilter--select) {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		width: 100%;
		margin: 0 auto;
		padding: 0 20px;

		> .mm-searchbar {
			width: 100%;
			height: 80px;
			align-self: center;

			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 30px;

			@include mq(tablet) {
				max-width: 567px;
				width: 567px;
			}
		}

		> .mm-controller {
			align-self: flex-end;
			margin: 5px -20px 0 0;
		}
	}

	.l-outer--my-adac-content & {
		padding: 0;
	}

	&--select:not(.h-space-m):not(.h-space-l) {
		margin-bottom: 0;
		width: auto;
		min-width: 130px;

		.l-form-general & {
			width: 100%;
		}
	}
}