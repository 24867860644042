// ==========================================================================
//   mixins and functions:  DO NOT CHANGE!  updated: 2015-08-27 (PeDo)
// ==========================================================================


// converts several units to em (supports px, pt, pc, in, mm, cm)
@function em($size) {
	@if not unitless($size) {
		@if unit($size)==em {
			@return $size;
		}

		@return ((0px + $size) / 16px * 1em);
	}

	@return null;
}

// converts several units to rem (supports px, pt, pc, in, mm, cm)
@function rem($size) {
	$size: em($size);

	@if $size {
		@return em($size) / 1em + rem;
	}

	@return null;
}

// FONT SIZE MIXIN for rem
@mixin font-size($size, $line-height: null) {
	font-size: $size;
	font-size: rem($size);

	@if $line-height {
		@include line-height($line-height);
	}
}

// responsive img, max-width
@mixin img($max-width) {
	max-width: $max-width;
	width: 100%;
	height: auto;
}

// LINE HEIGHT MIXIN for rem
@mixin line-height($size) {
	line-height: $size;
	line-height: rem($size);
}

// POSITIONING MIXIN for absolute positioned elements
// WE: do not flip $left and $right order!
@mixin absolute($top: null, $left: null, $bottom: null, $right: null) {
	top: $top;
	left: $left;
	right: $right;
	bottom: $bottom;
	position: absolute;
}

@mixin fixed($top: null, $left: null, $bottom: null, $right: null) {
	top: $top;
	left: $left;
	right: $right;
	bottom: $bottom;
	position: fixed;
}

@mixin relative($top: null, $left: null, $bottom: null, $right: null) {
	top: $top;
	left: $left;
	right: $right;
	bottom: $bottom;
	position: relative;
}

// SEMI-TRANSPARENT BACKGROUND with old IE fallback
@mixin background-color($color) {
	@if opacity($color) < 1 {
		background-color: opacify($color, 1);
	}

	background-color: $color;
}

// MEDIA QUERY Mixin
@mixin mq($type) {
	@if map-has-key($breakpoints, $type) {
		@media #{map-get($breakpoints, $type)} {
			@content;
		}

		// duplicate style for ie8
		@if index($breakpoints-ie8, $type) {
			@if & {
				.lt-ie9 & {
					@content;
				}
			}

			@else {
				.lt-ie9 {
					@content;
				}
			}
		}
	}

	@else {
		@warn "Unknown breakpoint #{$type}!";
	}
}

@mixin autoalpha($alpha) {
	opacity: $alpha;
	visibility: if($alpha ==0, hidden, visible);
}

@function _if-parent-to-dummy($selector) {
	@if type-of($selector)==string and str-index($selector, '%') {
		@return $selector;
	}

	@return selector-replace(selector-replace($selector, ':before', '.dummy-before'), ':after', '.dummy-after');
}

@function _if-parent-from-dummy($selector) {
	@if type-of($selector)==string and str-index($selector, '%') {
		@return $selector;
	}

	@return selector-replace(selector-replace($selector, '.dummy-before', ':before'), '.dummy-after', ':after');
}

/// Test hierarchical SCSS parents for state- or pseudo-classes
///
/// @throw Could not match all parents
/// @content
///
/// @example
///     .mod-example {
///         > .m-inner {
///             > .m-box {
///                 background: gray;
///
///                 // if the 'variant' modifier class is set
///                 @include if-parent('.mod-example--variant') {
///                     background: red;
///                 }
///
///                 // if .m-inner has the .is-active class OR .m-inner has the :hover pseudo-class (logical OR)
///                 @include if-parent('.m-inner.is-active, .m-inner:hover') {
///                     background: green;
///                 }
///
///                 // if both apply (locical AND)
///                 @include if-parent('.mod-example--variant .m-inner.is-active') {
///                     background: blue;
///                 }
///             }
///         }
///     }
///
/// @param {string}  $parent-selectors          CSS selectors matching one or more parents
/// @param {boolean} $debug            [false]  Outputs debug comment block
///
@mixin if-parent($parent-selectors, $debug: false) {
	$rules: &;
	$new-rules: (
	);
$matched: false;
$parent-selectors: selector-parse($parent-selectors);

// allow multiple parent selectors
@each $parent-selector in $parent-selectors {

	// rules are separated by commas
	@each $rule in $rules {
		$new-rule: (
		);
	$parent-index: 1;
	$parent-matched: 0;
	$selector-index: 1;

	// selectors are separated by spaces
	@each $selector in $rule {
		@if $parent-index <=length($parent-selector) {
			$parent-super: nth(simple-selectors(nth($parent-selector, $parent-index)), 1);
			$parent-modifier: str-index($parent-super, '--');

			// selector is a modifier "--modifier"
			@if $selector-index ==1 and $parent-modifier ==1 {
				$selector-super: nth(simple-selectors($selector), 1);
				$selector: str-insert($selector, $parent-super, str-length($selector-super) + 1);
				$parent-selector: nth(selector-replace($parent-selector, $parent-super, '#{$selector-super}#{$parent-super}'), 1);
				$parent-super: '#{$selector-super}#{$parent-super}';
			}

			// selector contains a modifier "mod-test--modifier"
			@else if $parent-modifier !=null {
				$parent-modifier-super: str-slice($parent-super, 1, $parent-modifier - 1);

				@if is-superselector($parent-modifier-super, _if-parent-to-dummy($selector)) {
					$selector: '#{$parent-super}#{str-slice($selector, $parent-modifier)}';
				}
			}

			// this is our super selector, merge the selector
			@if is-superselector($parent-super, _if-parent-to-dummy($selector)) {
				$selector: _if-parent-from-dummy(selector-unify(_if-parent-to-dummy($selector), nth($parent-selector, $parent-index)));
				$parent-index: $parent-index + 1;
				$parent-matched: $parent-matched + 1;
			}
		}

		$new-rule: append($new-rule, $selector, space);
		$selector-index: $selector-index + 1;
	}

	@if $parent-matched ==length($parent-selector) {
		$matched: true;
	}

	$new-rules: append($new-rules, $new-rule, comma);
}
}

// report error if we couldn't match all parents
@if not $matched {
	@error 'Could not match all parents: #{$parent-selectors}';
}

@at-root #{$new-rules} {
	@if $debug {
		// sass-lint:disable-block no-css-comments
		/**************************************************************
             * Debug:  if-parent('#{$parent-selectors}')
             * Before: #{$rules}
             * After:  #{$new-rules}
             **************************************************************/
	}

	@content;
}
}