.o-layout-main-stage--legalprotection {

	> .swiper {

		> .swiper-wrapper {

			> .swiper-slide {

				> .oo-figure {

					@include mq(tablet) {
						height: auto;
					}

					> img {
						min-width: 100%;
						min-height: 100%;
						height: auto;
						top: 0;

						@include mq(tablet) {
							width: auto;
						}
					}
				}

				> .oo-box {
					margin: 0 auto;
					padding: 15px 20px;
					min-height: 255px;
					height: auto;

					@include mq(tablet) {
						padding: 40px;
						width: 700px;
						bottom: 45px;
						left: 0;
						right: 0;
					}

					@include mq(desktop) {
						width: 767px;
					}

					> header {
						min-height: auto;

						> .oo-box-head {
							@include milo-slab-bold($font-size-23);
							max-width: 55%;
							margin-bottom: 30px;

							@include mq(tablet) {
								@include font-size(40px);
								max-width: 100%;
								margin-bottom: 15px;
							}
						}
					}

					> .oo-box-text {
						height: auto;
						overflow: visible;
						max-width: 480px;

						@include mq(tablet) {
							max-width: 530px;
						}
					}

					> .a-basic-btn {
						left: 20px;
						right: auto;
						bottom: auto;
						max-width: 150px;

						@include mq(tablet) {
							left: auto;
							right: 38px;
							bottom: 25px;
							max-width: 100%;
						}
					}
				}
			}
		}
	}
}