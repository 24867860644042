.m-basic-progress-nav--only-active-text-mb {
	ul {

		li {
			.mm-text {
				display: none;

				@include mq(tablet) {
					display: inline-block;
				}
			}

			&.mm-active {

				.mm-text {
					display: inline-block;

					@include mq(tablet) {
						display: inline-block;
					}
				}

			}

		}
	}
}