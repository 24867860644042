// m-basic-list--teaser
.m-basic-list--teaser {
	display: flex;
	flex-flow: column wrap;
	justify-content: flex-start;
	visibility: visible;
	max-width: none;
	padding: 0;

	&-each-row {
		flex-direction: column;
	}

	&:not(.m-basic-list--teaser-each-row) {
		@include mq(tablet) {
			flex-direction: row;
			margin-bottom: -20px;

			&.h-space-s {
				margin-bottom: -20px;
			}
		}
	}

	> .mm-variant-teaser {
		position: relative;
		padding-left: 40px;
		margin-bottom: 12px;
		visibility: visible;
		background: 0;

		@include mq(tablet) {
			width: 201px;
			margin-right: 30px;
			margin-bottom: 20px;
		}

		@include mq(desktop) {
			width: 252px;
			margin-right: 33px;
		}

		&:before {
			background: none;

			@include if-parent('.m-basic-list--teaser.m-basic-list--teaser-each-row') {
				content: none;
			}
		}

		&:last-child {
			margin-right: 0;
		}

		@include if-parent('.m-basic-list--teaser.m-basic-list--teaser-each-row') {
			width: 100%;
		}

		// Exception .o-layout-main-stage if sibling of .oo-slide-content-wrapper
		.o-layout-main-stage .swiper .swiper-wrapper .swiper-slide .oo-slide-content-wrapper ~ & {

			@include mq('tablet') {
				margin-bottom: 30px;
			}

			@include mq(desktop) {
				margin-left: 16px;
				margin-right: 16px;
				margin-bottom: 15px;
			}
		}

		img {
			@include absolute($left: 0);

			@include if-parent('.m-basic-list--teaser.m-basic-list--teaser-each-row') {
				@include absolute($left: 0, $top: 2px);
			}
		}

		> .mm-headline {
			@include milo-bold($font-size-16);
			font-weight: normal;

			@include if-parent('.m-basic-list--teaser:not(.m-basic-list--teaser-each-row)') {
				margin: 7px 0 10px 0;
			}
		}
	}

	// Exception .o-layout-main-stage if sibling of .oo-slide-content-wrapper
	.o-layout-main-stage .swiper .swiper-wrapper .swiper-slide .oo-slide-content-wrapper ~ & {
		padding: 0 20px;

		@include mq(tablet) {
			padding: 0 30px 0 30px;
			background: $color-primary-2;
			max-width: 920px;
			border-radius: 8px;
		}

		@include mq(desktop) {
			position: relative;
			max-width: 960px;
			margin: -125px auto 0 auto;
			padding-top: 50px;
		}

		> .mm-teaser-headline {

			@include mq(tablet) {
				text-align: center;
				width: 100%;
			}
		}

	}

	// Exception .o-layout-main-stage if sibling of .oo-slide-content-wrapper
	.o-layout-main-stage .swiper .swiper-wrapper .swiper-slide .oo-slide-content-wrapper ~ :not(.h-h2) + & {

		@include mq(desktop) {
			margin: -75px auto 0 auto;
		}
	}

	// Exception .o-layout-main-stage if sibling of .oo-slide-content-wrapper--fade-overlay

	.o-layout-main-stage .swiper .swiper-wrapper .swiper-slide .oo-slide-content-wrapper.oo-slide-content-wrapper--fade-overlay ~ & {

		$fading-gradient-tablet: linear-gradient(rgba($color-primary-2, 0), rgba($color-primary-2, 1) 90%);

		padding-top: 0;

		@include mq(desktop) {
			padding-top: 95px;
			background: none;
		}

		&:before {
			content: '';
			position: absolute;
			top: -115px;
			left: -100%;
			display: none;
			height: 190px;
			width: 200vw;
			transform: translate(0, 0);
			background: $fading-gradient-tablet;
			z-index: -1;

			@include mq(desktop) {
				display: block;
				padding-top: 75px;
			}
		}
	}

	// Exception .o-layout-main-stage if sibling of .oo-slide-content-wrapper--overlay
	.o-layout-main-stage .swiper .swiper-wrapper .swiper-slide .oo-slide-content-wrapper--overlay ~ & {

		padding-top: 0;


		@include mq(desktop) {
			margin: -75px auto 0 auto;
			padding-top: 35px;
			background: $color-primary-2;
		}

	}

	// Exception .o-layout-main-stage if sibling of .oo-slide-content-wrapper--overlay and h-h2 is set
	.o-layout-main-stage .swiper .swiper-wrapper .swiper-slide .oo-slide-content-wrapper--overlay ~ .h-h2 + & {

		@include mq(desktop) {
			margin: -130px auto 0 auto;
			padding-top: 85px;
		}
	}

	// Exception .o-layout-main-stage if sibling of .oo-slide-content-wrapper--overlay and has the class .oo-slide-content-wrapper--fade-overlay and h-h2 is set
	// "fade-overlay": true,
	// "overlay": true,
	.o-layout-main-stage .swiper .swiper-wrapper .swiper-slide .oo-slide-content-wrapper--overlay.oo-slide-content-wrapper--fade-overlay ~ .h-h2 + & {

		@include mq(desktop) {
			margin: -125px auto 0 auto;
			padding-top: 85px;
		}
	}
}