.m-adac-quiz-question {
	background: $color-secondary-8;
	top: 0;
	position: absolute;
	visibility: hidden;
	opacity: 0;
	transition: opacity 2s;
	padding: 0 20px 20px;
	width: 100%;

	@include mq('tablet') {
		padding: 0 50px 35px;
	}

	&.is-fadein {
		visibility: visible;
		opacity: 1;
	}

	&.is-fadeout {
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.3s;
	}

	&.is-active {
		position: relative;
		visibility: visible;
		opacity: 1;
	}

	> .mm-question-content-wrapper {
		height: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;


		> .mm-question--image {
			display: none;

			.mm-question-wrapper--image & {
				@include milo-bold($font-size-18);
				@include line-height($font-size-22);
				margin-bottom: 25px;
				width: 100%;

				@include mq('tablet') {
					@include milo-bold($font-size-28);
					@include line-height($font-size-32);
					margin-bottom: 35px;
				}

				@include mq(desktop) {
					display: block;
				}
			}

		}

		> .mm-question-col {
			width: 100%;

			&--1 {
				width: 100%;

				.mm-question-wrapper--image & {
					@include mq(desktop) {
						width: 40%;
					}
				}
			}

			&--2 {
				width: 100%;

				.mm-question-wrapper--image & {
					@include mq(desktop) {
						display: flex;
						flex-wrap: wrap;
						width: 55%;
					}
				}
			}

			> .mm-question-img-box {
				width: 100%;

				> img {
					width: 100%;
					margin-bottom: 12px;

					@include mq('tablet') {
						margin-bottom: 25px;
					}
				}
			}

			> .mm-question--noimage {
				@include milo-bold($font-size-18);
				@include line-height($font-size-22);
				margin-bottom: 25px;
				width: 100%;
				display: block;

				@include mq('tablet') {
					@include milo-bold($font-size-28);
					@include line-height($font-size-32);
					margin-bottom: 35px;
				}

				.mm-question-wrapper--image & {
					@include mq(desktop) {
						display: none;
					}
				}

			}

			> .mm-answer-wrapper {
				display: flex;
				justify-content: space-between;
				align-content: flex-start;
				flex-wrap: wrap;
				margin-bottom: 20px;
				width: 100%;
				min-height: 220px;

				@include mq(tablet) {
					min-height: 0;
				}

				> .mm-answer {
					width: 100%;
					padding: 3px 22px 4px 37px;
					font-size: $font-size-22;
					margin-bottom: 10px;
					cursor: pointer;
					position: relative;
					background-color: $color-primary-2;

					&:before {
						content: '';
						display: inline-block;
						@include main-icon-arrow-right-black;
						position: absolute;
						left: -8px;
						top: -3px;
					}

					@include mq('tablet') {
						width: 47.5%;
					}

					.mm-question-wrapper--image & {
						@include mq('desktop') {
							width: 100%;
						}
					}

					&.is-grey {
						background-color: $color-secondary-10;
						cursor: default;
					}

					&.is-correct {
						@include milo-bold();
						cursor: default;
					}

					&.is-false {
						@include milo-bold();
						background-color: $color-primary-2;
						cursor: default;
					}

					&.is-choosed {
						background-color: $color-primary-2;
						@include milo-bold();
						cursor: default;
					}

					> .mm-answer-p {
						margin: 0 0 0 -15px;
						display: inline-block;
					}

					> .mm-icon {
						width: 25px;
						height: 25px;
						position: absolute;
						display: none;
						top: 50%;
						right: -12px;
						transform: translate(0, -50%);

						@include if-parent('.mm-answer.is-correct') {
							@include my-adac-icon-correct;
							position: absolute;
							top: 18px;
							right: -24px;
							display: block;
						}

						@include if-parent('.mm-answer.is-false') {
							@include my-adac-icon-incorrect;
							position: absolute;
							top: 18px;
							right: -24px;
							display: block;
						}
					}
				}
			}
		}

		> .mm-question-btn-box {
			visibility: hidden;
			align-self: flex-end;
			width: 100%;
			display: flex;
			justify-content: flex-end;

			@include if-parent('.m-adac-quiz-question.is-answered') {
				visibility: visible;
			}

			> .mm-lightbox-link {
				display: none;

				&.is-active {
					display: block;
				}
			}

			> .a-basic-btn {
				margin-left: 15px;

				@include mq('tablet') {
					margin-left: 20px;
				}
			}
		}
	}
}