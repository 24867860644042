/* a-basic-icon-verified */
.a-basic-icon-verified {
	position: relative;
	padding: 0 0 0 19px;

	&.h-space-s {
		margin-bottom: 0;
	}

	.aa-verified-icon-wrapper {
		display: block;
		position: absolute;
		left: -12px;
		top: -15px;
		@include main-icon-verified;
	}

	// exception myAdac
	.l-outer--my-adac-content &,
	.l-outer--my-adac & {
		.aa-verified-icon-wrapper {
			@include main-icon-verified-grey;
		}
	}
}