/* o-basic-form-overview---toggle  */
.o-basic-form-overview--toggle {

	.a-basic-btn {
		&.oo-edit {
			@include absolute($top: 0px, $right: 0px);
			padding: 5px 10px 5px 25px;
			background-color: unset;
		}
	}

	.oo-fieldset {

		margin-top: 2px;

		.oo-row {
			display: none;
			flex-flow: row wrap;

			&.hidden {
				display: none !important;
			}
		}

		> legend {

			margin-bottom: 0;
			padding-left: 20px;
			cursor: pointer;

			&:before {
				content: '';
				@include absolute($top: -5px, $left: -10px);
				@include main-icon-link;
			}
		}

		&.is-open {

			padding-bottom: 12px;


			> legend {

				margin-bottom: 16px;

				&:before {
					content: '';
					@include main-icon-arrow-drop-down;
				}
			}

			.oo-row {
				display: flex;
			}
		}
	}



}