.m-basic-text--mgldiscountusa {
	.mm-content {
		> ul {
			> li {
				> a {
					text-decoration: none;

					&:hover {
						text-decoration: underline;

						> .a-basic-icon-pdf {
							text-decoration: underline;
						}
					}

					> .a-basic-icon-pdf {
						text-decoration: none;
					}
				}
			}
		}
	}
}