/* a-my-adac-list */
.a-my-adac-list {

	.l-outer--my-adac & {
		margin: 0 0 24px 0;
	}

	> .mm-my-adac-list-wrapper {
		margin: 0;
		padding: 0;
	}

	> .mm-my-adac-toggle-wrapper {
		margin: 0 -8px;
		padding: 0;

		> .mm-my-adac-toggle {
			border-bottom: 1px solid $color-secondary-9;

			@include if-parent('.a-my-adac-list--borderTop') {

				// bei Fragen m-mueller
				&:first-child {
					border-top: 1px solid $color-secondary-9;
				}
			}

			&.is-initially-hidden {
				display: none;

				@include if-parent('.a-my-adac-list.is-show-all') {
					display: block;
				}
			}
		}

		> .mm-my-adac-toggle-items-container {
			color: $color-secondary-1;
			margin: 0;
			position: relative;
			@include milo-regular(16px);
			cursor: pointer;

			> span {
				display: block;
				padding: 9px 8px;
				border-bottom: 1px solid $color-secondary-9;

				&.js-less {
					display: none;

					@include if-parent('.a-my-adac-list.is-show-all') {
						display: block;
					}
				}

				&.js-more {
					@include if-parent('.a-my-adac-list.is-show-all') {
						display: none;
					}
				}
			}
		}
	}
}