/* a-basic-input-text--calendar */
.a-basic-input-text--calendar {
	overflow: visible;
	float: left;
	margin: 0 10px 0 0;

	.l-form & {
		max-width: 150px;
	}

	@include mq(desktop-l) {
		margin-right: 51px;
	}

	&.h-space-s {
		margin-bottom: 15px;
	}

	&.a-basic-input-text--s {
		.mm-content--bg & {
			width: 120px;
		}
	}

	.l-form-general & {
		margin: 0;
	}

	input {

		-webkit-appearance: none !important;

		.l-form-general & {
			&::placeholder {
				color: $color-secondary-23;
			}
		}


		&::-webkit-inner-spin-button,
		&::-webkit-calendar-picker-indicator {
			display: none !important;
			-webkit-appearance: none !important;
		}

		&.a-basic-input-text--clear {
			padding-right: 5px;
		}

		&:disabled ~ .aa-calendar {
			cursor: auto;
			@include main-icon-event-grey;
			background-repeat: no-repeat;

			.l-form-general & {
				display: none;
			}
		}
	}

	.aa-calendar {
		display: block;
		position: absolute;
		top: -3px;
		right: -40px;
		cursor: pointer;
		@include main-icon-event;
		background-repeat: no-repeat;

		@include mq(desktop) {
			right: -40px;
		}

		.l-form-general & {
			bottom: 4px;
			top: auto;
			right: 5px;

			@include mq(desktop) {
				right: 0;
			}
		}

		.l-form-general .ll-row.is-check.is-error &,
		.l-form-general .ll-row.is-check.is-valid & {
			right: 30px;
		}

		// Exception and Touch Device
		.is-touch & {
			right: -39px;
		}

		.is-touch .l-form-general & {
			right: 0;
		}

		// exception for m-basic-radius-search--small
		.m-basic-radius-search--small & {
			right: 10px;
		}
	}

	// Exception for m-ves-rs-contentbox
	.m-ves-rs-contentbox & {
		display: flex;
		justify-content: space-between;

		> .aa-calendar {
			right: -35px;
			@include main-icon-event;
			background-repeat: no-repeat;

			@include mq(desktop-l) {
				display: block;
			}

			// Exception for touch devices
			.l-outer.is-touch & {
				@include mq(desktop-l) {
					display: none;
				}
			}
		}
	}

	&.a-basic-input-text--my-adac-content {
		> .aa-calendar {
			right: 0;
			left: 105px;
			height: 40px;

			.l-form-general & {
				left: auto;

			}
		}

		> input:disabled + .aa-calendar {
			display: none;
		}
	}

	// exception for m-basic-radius-search--small
	.m-basic-radius-search--small {
		> .l-form .ll-col-label + .ll-col-data & {
			width: 100%;
		}
	}
}