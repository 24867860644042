/* o-layout-main-header */
.o-layout-main-header {
	position: relative;
	min-height: 50px;
	height: auto;
	transition: padding-top 0.2s, min-height 0.2s;

	@include mq(tablet) {
		min-height: 30px;
	}

	@include mq(desktop) {
		min-height: 60px;
	}

	.ll-main-center--cluster & {
		min-height: 0;

		@include mq(tablet) {
			min-height: 30px;
		}

		@include mq(desktop) {
			min-height: 60px;
		}
	}

	// exception
	.o-layout-main-stage + .ll-main-center & {
		min-height: 0;
	}

	.oo-headline {
		right: 0;
		margin: 0;
		display: none;
		position: relative;
		bottom: 0;
		padding: 40px 0 30px 0;
		@include milo-slab-bold($font-size-36, 43px);

		@include mq(desktop) {
			display: block;
		}

		@include mq(print) {
			position: static;
			@include franklin-demi($font-size-36);
			margin-right: 0;
			padding: 10px 0 10px 120px;
			@include line-height(36px);
		}

		// Exception Cluster Page
		.ll-main-center--cluster & {
			display: block;
			margin-right: 10px;
			padding: 0;
			margin-top: -10px;
			margin-bottom: -3px;

			@include mq(tablet) {
				padding: 10px 0 22px 0;
				margin-top: 0;
				margin-bottom: 0;
			}

			@include mq(desktop) {
				padding: 40px 0 30px 0;
			}
		}

		// Exception for l-outer--nav
		.l-outer--nav & {
			display: none;
			position: absolute;
			padding: 0;

			@include mq(desktop) {
				display: block;
			}

			@include mq(print) {
				display: block;
			}
		}

		// Exception for l-header.headroom as sibling of l-main
		.l-header.headroom + .l-main & {
			margin-top: 0;
		}

		// Exception when o-layout-main-stage is followed by in ll-main-center--cluster with header-elem
		.o-layout-main-stage + .ll-main-center--cluster & {
			@include mq(tablet) {
				position: relative;
				padding: 40px 0 30px 0;
			}
		}
	}

	// Exception for l-outer--nav
	.l-outer--nav & {

		@include mq(tablet) {
			width: 100%;
			min-height: 40px;
			margin-left: auto;
		}

		@include mq(desktop) {
			min-height: 60px;
		}

		@include mq(desktop-xl) {
			width: auto;
			margin: 0;
		}
	}

	&.is-redc-open-nav {
		min-height: 105px;
		padding-top: 45px;
	}

	// Exception when o-layout-main-stage is followed by in ll-main-center--cluster with header-elem
	.o-layout-main-stage + .ll-main-center--cluster & {

		@include mq(tablet) {
			min-height: 30px;
		}
	}

	// Exception ll-main-center--cluster
	.ll-main-center--cluster > & {
		@include mq(desktop) {
			height: 123px;
		}
	}
}