.m-basic-rating-ekomi {
	width: 280px;
	background: $color-primary-2;
	border-radius: 4px;
	padding: 30px 20px;
	height: 245px;
	margin-right: 10px;
	margin-left: 10px;

	.swiper-slide:first-child & {
		margin-left: 0;
	}

	.swiper-slide:last-child & {
		margin-right: 0;
	}

	> .mm-headline {
		@include milo-bold();
		text-align: center;
		margin-bottom: 5px;
	}

	> .mm-stars {
		margin: 10px 0;
		display: flex;
		justify-content: center;
		align-items: center;

		> .mm-star {
			margin-right: 3px;

			> img {
				display: block;
			}
		}

		> .mm-counter {
			margin-left: 2px;
		}
	}

	> .mm-date {
		text-align: center;
		margin-bottom: 15px;
	}

	> .mm-rating {
		height: 90px;
		overflow: hidden;
	}
}