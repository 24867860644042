/* a-basc-link--german */
.a-basic-link--german {
	background-size: 25px;
	padding-left: 40px;

	&:before {
		content: '';
		display: inline-block;
		@include main-icon-german;
		top: -13px;
		left: -10px;
	}

	&:after {
		position: relative;
	}

	@include mq('print') {
		display: none;
	}
}