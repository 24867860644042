/* m-basic-table--white */
.m-basic-table--white {
	> tbody {
		tr {
			&:nth-child(even) {
				background-color: $color-primary-2;
			}

			&:not(:last-child) {
				border-bottom: 2px solid $color-secondary-16;
			}

			&.mm-subheading {
				& ~ tr:nth-child(odd) {
					background-color: $color-primary-2 !important;
				}

				& ~ tr:nth-child(even) {
					background-color: $color-primary-2 !important;
				}
			}
		}
	}
}