/* m-basic-text--left */
.m-basic-text--left {

	.mm-flex {
		display: block;

		.mm-aside {
			@include mq(tablet) {
				float: left;
			}
		}
	}
}