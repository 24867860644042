.m-ves-rs-list--reason {
	visibility: visible;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: flex-start;

	@include mq(tablet) {
		flex-direction: row;
	}

	> .mm-variant-teaser {
		text-align: left;
		max-width: 100%;
		position: relative;
		padding-left: 40px;
		margin-bottom: 12px;

		@include mq(tablet) {
			max-width: 201px;
			margin-right: 30px;
			margin-bottom: 0;
		}

		@include mq(desktop) {
			max-width: 252px;
		}

		// Exception m-ves-rs-list--stage-rate
		@include if-parent('.m-ves-rs-list--reason.m-ves-rs-list--stage-rate') {
			padding-left: 60px;
		}

		&:last-child {
			margin-right: 0;
		}

		// Exception o-layout-main-stage
		.o-layout-main-stage .oo-box > & {
			max-width: none;
			margin: 0;
		}

		img {
			@include absolute($left: 5px, $top: 5px);
		}

		> .mm-headline {
			@include milo-bold($font-size-16);
			font-weight: normal;
			margin: 7px 0 10px 0;

			@include mq(desktop) {
				min-height: 42px;
			}

			// Exception o-layout-main-stage
			.o-layout-main-stage .oo-box > & {
				@include mq(desktop) {
					min-height: 0;
				}
			}

			// Exception m-ves-rs-list--stage-rate
			@include if-parent('.m-ves-rs-list--reason.m-ves-rs-list--stage-rate') {
				@include milo-bold(16px, 20px);
				margin: 10px 0;

				@include mq(tablet) {
					@include milo-bold(20px, 26px);
				}
			}
		}

		> .mm-subheadline {
			@include milo-bold($font-size-16);
			margin: 10px 0 7px 0;

			@include mq(desktop) {
				min-height: 42px;
			}

			// Exception o-layout-main-stage
			.o-layout-main-stage .oo-box > & {
				@include mq(desktop) {
					min-height: 0;
				}
			}
		}
	}

	// Exception o-layout-main-stage
	.o-layout-main-stage .oo-box > & {
		flex-flow: column nowrap;
	}

	// Exception o-layout-main-stage -> sibling a-basic-stopper
	// prevent the text floating in the stopper
	.o-layout-main-stage .oo-box > .a-basic-stopper + & {
		max-width: calc(100% - 85px);
	}
}