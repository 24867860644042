/* a-basic-stopper--hint */
.a-basic-stopper--hint {
	right: 50%;
	top: -5px;
	margin-right: -22px;

	// Exception table
	.m-basic-table .mm-toggle-row-content & {
		position: static;
		margin: 5px 0 0 0;
	}

	// Exception table compare
	.m-basic-table > thead & {
		z-index: 70;
		top: -15px;

		@include mq(tablet) {
			top: -30px;
			right: 0;
			margin-right: 0;
		}
	}

	.swiper-container-table .m-basic-table > thead & {

		@include mq(tablet) {
			top: 0;
		}
	}

	.m-basic-table--break .mm-toggle-row-content & {
		position: relative;
		right: 0;
		top: 0;
		margin-bottom: 10px;

		@include mq(tablet) {
			position: absolute;
		}
	}

	.aa-inner-content {
		margin-left: 0;
		min-width: 0;

		// Exception table
		.m-basic-table .mm-toggle-row-content & {
			width: auto;
			padding: 2px 10px 5px 10px;
			display: inline-block;
		}

		.m-basic-table--break .mm-toggle-row-content & {
			padding: 10px 10px 5px 10px;
		}

		&:before,
		&:after {

			// Exception table
			.m-basic-table .mm-toggle-row-content & {
				display: none;
			}

		}

		.aa-headline {
			margin: 3px 0 0 -2px;
		}

		.aa-content {
			position: relative;
			top: 0;
			@include font-size($font-size-15);
			font-weight: normal;
		}
	}
}