/* m-ves-rs-sticky--calculator */
.m-ves-rs-sticky--calculator {
	padding: 19px 0 10px 0;

	@include mq(tablet) {
		padding: 19px 0 15px 0;
	}

	> .mm-sticky-module {

		> .mm-layer-content {

			> .mm-layer-content-inner {

				> .mm-layer-links {
					order: 3;
					align-self: flex-start;
					display: flex;
					justify-content: center;
					align-items: flex-start;
					margin-top: -32px;

					@include mq(tablet) {
						align-self: flex-end;
						justify-content: flex-end;
						align-items: flex-end;
						margin-top: 15px;
						margin-left: 56px;
					}

					@include mq(desktop-l) {
						position: absolute;
						top: -12px;
						right: 55px;
						display: block;
					}
				}
			}
		}
	}
}