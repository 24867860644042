/* a-basc-link--number */
.a-basic-link--number {
	> .aa-number {
		@include milo-bold(12px);
		display: inline-block;
		width: 20px;
		height: 20px;
		text-align: center;
		background: $color-primary-1;
		border-radius: 50%;
		padding-top: 2px;
		line-height: 16px;
	}
}