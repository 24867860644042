// ui-date-picker
.ui-datepicker {

	width: 280px;
	padding: 0;
	border: 1px solid $color-secondary-5;
	background: $color-secondary-6;
	color: $color-secondary-1;
	z-index: 99999 !important;

	// class is set if calendar is inside .l-form-general
	&.is-new-form {
		border: 1px solid $color-primary-3;
		border-radius: 4px;
		background: $color-primary-2;
		padding: 5px 35px 20px 35px;
		width: calc(100% - 82px);

		@include mq(tablet) {
			width: calc(400px - #{$form-width-s-general + $form-gutter-restyle});
		}
	}

	// Exception BasicInputText
	.ui-datepicker-wrap & {
		margin-top: -88px;

		@include mq(desktop) {
			margin-left: 200px;
		}
	}

	.ui-widget-header {
		background: $color-secondary-6;
		border: 0;

		@include if-parent('.ui-datepicker.is-new-form') {
			background: $color-primary-2;
			margin-top: 12px;
			margin-bottom: 5px
		}

		.ui-datepicker-title {

			@include if-parent('.ui-datepicker.is-new-form') {
				margin: 0;
			}

			select {
				font-weight: normal;

				@include if-parent('.ui-datepicker.is-new-form') {
					padding: 5px;
					border: .5px solid $color-secondary-2;
					border-radius: 4px;
					color: $color-primary-3;
					@include milo-bold($font-size-14);
				}

				option {
					@include if-parent('.ui-datepicker.is-new-form') {
						@include milo-regular($font-size-12);
					}
				}
			}
		}

		.ui-datepicker-month {
			margin-right: 5px;

			@include if-parent('.ui-datepicker.is-new-form') {
				width: 50%;
				cursor: pointer;
			}
		}

		.ui-datepicker-year {
			margin-left: 5px;

			@include if-parent('.ui-datepicker.is-new-form') {
				width: 38%;
				cursor: pointer;
			}
		}

		.ui-datepicker-next,
		.ui-datepicker-next-hover {
			right: 2px;
			top: -3px;
			border: 0;
			cursor: pointer;
			@include main-icon-link;

			@include if-parent('.ui-datepicker.is-new-form') {
				@include main-icon-arrow-right-grey-dark;
				right: -35px;
				top: 1px;
			}
		}

		.ui-datepicker-prev,
		.ui-datepicker-prev-hover {
			left: 2px;
			top: -3px;
			border: 0;
			cursor: pointer;
			@include main-icon-link-back;

			@include if-parent('.ui-datepicker.is-new-form') {
				@include main-icon-arrow-right-grey-dark;
				transform: rotate(180deg);
				left: -35px;
				top: 1px;
			}
		}
	}

	.ui-datepicker-calendar {
		margin: 0;

		thead {
			th {
				padding: 2px;
				border: 1px solid $color-primary-2;
				border-bottom: 0;
				border-right: 0;

				@include if-parent('.ui-datepicker.is-new-form') {
					@include milo-regular($font-size-12);
					color: $color-secondary-20;
					font-weight: initial;
				}
			}
		}

		tbody {
			tr {
				td {
					padding: 0;
					border: 1px solid $color-primary-2;
					border-bottom: 0;
					border-right: 0;

					@include if-parent('.ui-datepicker.is-new-form') {
						@include milo-regular($font-size-12);
					}

					&:first-child {
						border-left: 0;
					}

					> span,
					> a {
						padding: 2px;

						@include if-parent('.ui-datepicker.is-new-form') {
							@include milo-regular($font-size-12);
							padding: 6px 2px;
						}
					}

					.ui-state-default {
						background: transparent;
						border: 0;
					}

					.ui-state-highlight {
						color: $color-secondary-1;
						background: $color-primary-2;
					}

					.ui-state-hover,
					.ui-state-active {
						color: $color-secondary-1;
						background: $color-primary-1;

						@include if-parent('.ui-datepicker.is-new-form') {
							background: initial;
							border: 1px solid $color-primary-3;
							border-radius: 4px;
							padding: 6px 1px 4px 1px;
						}
					}
				}
			}
		}
	}
}