/* a-basic-btn--autoright */
.a-basic-btn--autoright {
	margin-left: auto;

	// exception for cardamage-forms
	.l-form .ll-row--cardamage & {
		align-self: flex-end;
	}

	//Exception for .l-form-general.l-form-general--mgl-standard
	.l-form-general.l-form-general--mgl-standard & {
		left: 28px;
	}
}