/* my-adac-confirmation-icon */
.m-basic-text--my-adac-confirmation-icon {
	padding-left: 40px;
	position: relative;

	.mm-lefticon {
		position: absolute;
		left: 0;
		top: -1px;

		@include mq(tablet) {
			top: 2px;
		}
	}

	p {
		@include milo-regular(18px, 22px);

		@include mq(tablet) {
			@include milo-regular(26px, 32px);
		}
	}
}