/* m-basic-table--tests */
.m-basic-table--tests {

	> thead {
		> tr {
			> th {
				white-space: nowrap;
			}
		}
	}

	> tbody {
		> tr {

			//remove spacing between opened item and next
			&.is-active + .mm-toggle-row {
				border-bottom: 0;
			}

			> td {

				// Checkbox
				&:first-child {
					width: 42px;
					max-width: 42px;
					overflow: hidden;

					> .a-basic-input-checkbox {
						margin-top: 0;
					}
				}

				//Hersteller/Modell
				&.mm-product-cell {
					position: relative;

					> .mm-product-cell-inner {
						display: flex;

						.mm-stopper-wrapper {
							border: none;
						}

						> .mm-table-link-wrapper {
							display: inline-block;
							width: 100%;

							@include mq(tablet) {
								width: 120px;
							}
						}
					}
				}

				//Größe
				&.mm-size-cell {
					min-width: 100px;
					overflow: hidden;
				}
			}
		}
	}
}