/* o-basic-banderole--reduced */
.o-basic-banderole--reduced {
	padding-bottom: 0;

	&.h-space-s {
		margin-bottom: 0;
	}

	> .swiper {
		margin-top: 0;
		padding-top: 0;

		> .swiper-wrapper {

			> .swiper-slide {
				padding-top: 12px;

				@include mq(tablet) {
					padding-top: 22px;
				}
			}
		}
	}
}