.a-ruf-climate {

	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;
	width: 35px;
	margin: 0 auto;

	background: $color-secondary-10;

	.mm-tac--yellow & {
		background: $color-weather-table-1;
	}

	.mm-tac--light-yellow & {
		background: $color-weather-table-2;
	}

	.mm-tac--blue & {
		background: $color-weather-table-3;
	}

	.mm-tac--light-blue & {
		background: $color-weather-table-4;
	}

	.aa-top {
		min-height: 1px;

		.mm-tac--yellow & {
			background: $color-weather-table-1;
		}

		.mm-tac--light-yellow & {
			background: $color-weather-table-2;
		}

		.mm-tac--blue & {
			background: $color-weather-table-3;
		}

		.mm-tac--light-blue & {
			background: $color-weather-table-4;
		}
	}

	.aa-bottom {
		padding: 1px;
	}

}