/* a-layout-darkness--loader */
.a-layout-darkness--loader {

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(359deg);
		}
	}

	&:before {
		content: "";
		display: block;
		position: fixed;
		left: 50%;
		top: 50%;
		width: 180px;
		height: 180px;
		margin: -90px 0 0 -90px;
		@include main-icon-refresh-180px;

		@include if-parent('.a-layout-darkness--loader.a-layout-darkness--layer') {
			display: none;
		}

		// spin animation
		animation: spin 1.5s infinite linear;
	}

	> .aa-layer {
		position: absolute;
		width: calc(100% - 40px);
		max-width: 500px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-height: calc(100% - 100px);
		text-align: center;
		padding: 30px 30px 170px 30px;
		background-color: $color-primary-2;
		border-radius: 15px;
		box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.20);

		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 180px;
			height: 180px;
			margin: -90px 0 0 -90px;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			@include main-icon-refresh-180px;

			// spin animation
			animation: spin 1.5s infinite linear;
		}

		> .aa-text {
			display: block;
			margin-bottom: 20px;
			color: $color-primary-3;
		}
	}
}