/* m-basic-table--us */
.m-basic-table--us {
	width: 100%;

	&.is-cloned-header {
		margin-bottom: 40px;
	}

	> thead {
		> tr {
			border: none;

			&:first-child {
				border: none;
			}

			> th {
				background: none;
				border: none;
				text-align: left;

				&:first-child {
					border: none;
				}

				&:last-child {
					border: none;
				}
			}
		}
	}

	> tbody {

		tr {

			td {
				&:first-child {
					border-right: none;
					border-left: none;
				}

				&:last-child {
					padding-right: 40px;
					text-align: right;
				}

				border-right: none;
				border-left: none;
			}
		}
	}
}