/* m-ves-number-plate */
.m-ves-number-plate {

	position: relative;
	width: 242px;
	height: 60px;
	padding: 10px 10px 10px 36px;
	background: url(/assets/img/bg/ves/car-license-plaid.gif) no-repeat;

	@include mq(tablet) {
		top: -6px;
		margin: 0 0 -6px 0;

		// Exception is-error
		.l-form .ll-row.is-error & {
			top: 0;
			margin-bottom: 0;
		}
	}

	&.h-space-s {
		margin-bottom: -6px;
	}

	&.h-space-l {
		margin: 0 0 10px 0;

		@include mq(tablet) {
			margin: 0 0 31px 0;
		}
	}

	> .mm-col-1,
	> .mm-col-2,
	> .mm-col-3 {
		float: left;
	}

	> .mm-col-1 {
		width: 45px;
		margin: 0 27px 0 0;
	}

	> .mm-col-2 {
		width: 45px;
		margin: 0 10px 0 0;
	}

	> .mm-col-3 {
		width: 67px;
	}
}