/* a-basic-input-checkbox */
.a-basic-input-checkbox {
	float: left;
	display: block;
	position: relative;
	margin-top: 5px;
	margin-right: (2 * $form-gutter);
	z-index: 50;

	// exception for new layout l-form-general
	.l-form-general .ll-row & {
		margin-right: (2 * $form-gutter-restyle);

		&:last-child {
			margin-right: 0;
		}
	}

	// exception for new layout l-form-general
	.m-basic-calculator-tiles .l-form-general .ll-row .a-basic-input-text + & {
		margin-top: 25px;
	}

	// exception myAdac Content site´s
	.l-outer--my-adac-content & {
		float: none;
	}

	// Exception ll-col-data
	.l-form .ll-col-label + .ll-col-data & {
		max-width: 320px;
	}

	// Exception ll-row--thin-padding
	.l-form .ll-row--thin-padding .ll-col-label + .ll-col-data & {
		max-width: none;
	}

	// Exception for m-newsletter-category
	.mm-newsletter-theme & {
		margin-top: 0;
		margin-right: 7px;
	}

	// Exception for m-newsletter-category
	.m-newsletter-category > & {
		width: 100%;
	}

	// exception m-basic-keywordfilter
	.m-basic-keywordfilter & {
		margin-top: 0;
		margin-bottom: 0;
		@include line-height($font-size-19);
	}

	.m-basic-keywordfilter--mobilelayer & {
		margin-bottom: 15px;
	}

	// exception organism o-basic-form
	.o-basic-form .l-main-cols & {
		padding-top: 2px;
	}

	// Exception m-basic-toggle--link :: in basickeyfilter--extended
	.m-basic-toggle--link & {
		float: none;
		margin-bottom: 15px;

		@include mq(tablet) {
			float: left;
			width: calc(33.3% - 20px);
			height: 40px;
			margin-bottom: 5px;
		}

		@include mq(desktop) {
			width: calc(25% - 20px);
		}
	}

	// Exception: o-fdl-creditcardconfig
	.o-fdl-creditcardconfig & {

		margin-top: 0;

		> label {
			top: 4px;
		}

		> div {

			padding-top: 3px;

			> label {
				@include milo-bold($font-size-21);
				line-height: 1.1em;

				.h-roboto & {
					font-family: 'Roboto Bold', sans-serif;
				}
			}
		}
	}

	// exception .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration > & {
		clear: both;
		float: none;
	}

	// exception .m-basic-calculator-content-box if checkbox is direct sibling of a-basic-input-text--date
	.m-basic-calculator-content-box .a-basic-input-text--date + & {
		margin-top: 15px;
		margin-right: 0;
	}

	&:last-child {
		margin-right: 0;
	}

	&.h-space-s {
		margin-bottom: 5px;
	}

	// overwrite exception
	.basic-dropdown-layer &.h-space-s {
		margin-bottom: 15px;
	}

	> input {
		@extend %h-visuallyhidden;

		& + label {
			width: 22px;
			height: 22px;
			cursor: pointer;
			@include absolute();
			z-index: 10;
		}

		& ~ div {
			position: relative;
			padding: 0;

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-check-box-outline;
				background-repeat: no-repeat;
				margin-top: 0;
				position: absolute;
				left: -12px;
				top: -11px;
				z-index: -9;
			}

			// Exception: m-basic-keywordfilter
			.m-basic-keywordfilter--filter & {
				padding-top: 1px;
			}

			//Exception for l-form label nested in p tag
			> p {

				> label {
					display: inline-block;
					cursor: pointer;
					padding-left: 25px;
				}
			}

			> label {
				// display inline-block enable label clicks between two lines
				//display: inline-block; can't be used without filled label
				cursor: pointer;
				padding-left: 25px;

				// Exception for l-form
				.l-form form .ll-fieldset & {
					margin-top: 0;
				}

				& + * {
					//margin-top: 15px; too much space in addition to margin-bottom of label

					@include if-parent('.a-basic-input-checkbox.a-basic-input-checkbox--legend') {
						margin-top: 6px;
					}
				}

				// exception .m-basic-calculator-content-box
				.m-basic-calculator-content-box & {
					position: relative;
				}
			}

			> .a-basic-input-checkbox:only-of-type {
				float: none;
			}

			// exception for links in cbx label
			> p > label > a {
				position: relative;
			}

			// Exception: o-fdl-creditcardconfig
			.o-fdl-creditcardconfig & {
				background-position: -2px 2px;
			}
		}

		// if focus
		&:focus ~ div {

			.is-keyboard-focus & {
				&:before {
					content: '';
					display: inline-block;
					left: -12px;
					top: -11px;
					background-repeat: no-repeat;
					margin-top: 0;
					position: absolute;
					z-index: -9;
					@include main-icon-check-box-outline-focus;
				}

				&:after {
					content: '';
					min-width: 26px;
					min-height: 26px;
					position: absolute;
					border: 3px solid $color-primary-3;
					border-radius: 4px;
					left: -3px;
					top: -2px;
				}
			}

			// if is mouseover
			@include if-parent('.a-basic-input-checkbox.js-clicked') {

				&:before {
					content: '';
					display: inline-block;
					@include main-icon-check-box-outline;
					background-repeat: no-repeat;
					margin-top: 0;
					position: absolute;
					top: -11px;
					z-index: -9;
				}
			}
		}

		&:checked {
			& ~ div {

				&:before {
					content: '';
					display: inline-block;
					@include main-icon-check-box;
					background-repeat: no-repeat;
					margin-top: 0;
					position: absolute;
					top: -11px;
					z-index: -9;
				}
			}

			// if checked + disabled
			&:disabled + label {
				cursor: default;

				& ~ div {

					.m-basic-table-toggle & {

						&:before {
							content: '';
							display: inline-block;
							@include main-icon-check-box;
							background-repeat: no-repeat;
							margin-top: 0;
							position: absolute;
							top: -11px;
							z-index: -9;
						}
					}
				}
			}

			// if focus && checked
			&:focus ~ div {

				&:before {
					content: '';
					display: inline-block;
					@include main-icon-check-box;
					background-repeat: no-repeat;
					margin-top: 0;
					position: absolute;
					top: -11px;
					z-index: -9;
				}

				&:after {
					.is-keyboard-focus & {
						content: '';
						min-width: 26px;
						min-height: 26px;
						position: absolute;
						border: 3px solid $color-primary-3;
						border-radius: 4px;
						left: -3px;
						top: -2px;
					}
				}
			}

			// if focus by click && checked
			@include if-parent('.a-basic-input-checkbox.js-clicked') {
				& ~ div {

					&:before {
						content: '';
						display: inline-block;
						@include main-icon-check-box;
						background-repeat: no-repeat;
						margin-top: 0;
						position: absolute;
						top: -11px;
						z-index: -9;
					}
				}
			}
		}

		&:disabled {
			& ~ div {

				> label {
					color: $color-secondary-3;
				}
			}
		}


		// error state
		.o-basic-form .is-error & ~ div {

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-check-box-outline-red;
				height: 28px;
				width: 30px;
				background-repeat: no-repeat;
				margin-top: 0;
				position: absolute;
				top: 1px;
				z-index: -9;
			}
		}

		.o-basic-form .is-error &:checked ~ div {

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-check-box-outline-red;
				height: 28px;
				width: 30px;
				background-repeat: no-repeat;
				margin-top: 0;
				position: absolute;
				top: 1px;
				z-index: -9;
			}
		}
	}

	> div {
		min-height: 20px;

		.a-basic-input-checkbox {
			display: inline-block;
			position: relative;
			left: 25px;
			width: 100%;
		}

		.m-my-adac-checkbox-image & {
			display: block;
			width: 100%;
		}

		> label {
			display: inline-block;

			&.aa-inline {
				display: inline;
			}

			.m-my-adac-checkbox-image & {
				display: block;
				width: 100%;
				height: 240px;
				position: relative;
			}

			.m-my-adac-checkbox-image--round-edges & {
				position: static;
			}

			> img {

				&.mm-icon {
					position: absolute;
					bottom: 0;
					margin-left: 5px;
				}

				.m-my-adac-checkbox-image & {
					position: absolute;
					margin: 10px 0 0 0;
					left: 40%;
					top: 50%;
					transform: translate(-50%, -50%);
					width: auto;
					height: auto;
					max-height: calc(100% - 30px);
					max-width: 100%;
					display: block;
				}

				.m-my-adac-checkbox-image--round-edges & {
					max-height: 100%;
					border-radius: 15px;
					left: 50%;
				}
			}
		}
	}

	.mm-toggle-tab-content & {

		> input {

			&:disabled {
				& ~ div {

					&:before {
						content: '';
						display: inline-block;
						@include my-adac-icon-check-box-inactive;
						background-repeat: no-repeat;
						position: absolute;
						top: -11px;
						z-index: -9;
					}

					> label {
						color: $color-secondary-3;
					}
				}
			}
		}
	}

	// exception if (first) column has a checkbox in m-basic-table
	.m-basic-table & {
		margin-right: 0;
		z-index: 1;

		> input ~ div {
			background-position: 0 0;
		}
	}
}