.a-basic-input-copy {
	display: block;
	max-width: 400px;
	min-width: 220px;
	width: 100%;

	> .aa-content-wrapper {
		position: relative;

		> .aa-success-message {
			padding: 12px 5px 10px 35px;
			position: relative;
			display: none;

			&:after {
				@include main-icon-check-grey;
				content: '';
				position: absolute;
				left: -10px;
				top: 0;
			}
		}

		> .aa-input-wrapper {
			button {
				position: absolute;
				width: 25px;
				height: 30px;
				right: 10px;
				top: 20px;
				overflow: hidden;

				> span {
					@include main-icon-copy();
					position: absolute;
					left: -14px;
					top: -10px;
				}
			}
		}

		> .aa-button-wrapper {
			margin-top: 20px;
		}
	}
}