/**
 * swiper spinner
 */
@keyframes swiper-preloader-spin {
	100% {
		transform: rotate(360deg);
	}
}

/**
 * buorg (old browser support animation)
 */
@keyframes buorgfly2 {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}