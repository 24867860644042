/* m-basic-list--advantage */
.m-basic-list--pw-check {

	> li {

		&:before {
			@include main-icon-check-light-grey;
			background-color: transparent !important;
			width: 45px !important;
			height: 25px !important;
		}

		&.is-valid {
			&:before {
				@include main-icon-check-green;
			}
		}
	}
}