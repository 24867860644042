.o-ruf-rating {
	padding: 20px;
	background: $color-secondary-6;

	> .oo-subtitle-wrapper {

		@include mq(tablet) {
			display: flex;
			justify-content: flex-start;
		}

		> .oo-subtitle {
			margin-right: 10px;
			line-height: 28px;
		}
	}

	.oo-btn-wrapper {
		margin-bottom: 15px;
		display: flex;
		flex-wrap: wrap-reverse;
		justify-content: space-between;

		@include mq(tablet) {
			justify-content: space-between;
			align-items: flex-end;
			flex-wrap: unset;
		}

		> .toggle-rating {
			padding: 1px 0 0 10px;
			cursor: pointer;
			display: inline-block;
			position: relative;

			&:before {
				content: " ";
				position: absolute;
				display: block;
				@include main-icon-arrow-drop-down;
				left: -25px;
				top: -10px;
			}

			> .no-rating {
				display: block;
			}

			> .all-rating {
				display: none;
			}

			&.rating-close {
				padding: 1px 0 0 10px;

				&:before {
					@include main-icon-link;
				}

				> .no-rating {
					display: none;
				}

				> .all-rating {
					display: block;
				}
			}
		}

		> .a-basic-btn {
			margin-bottom: 15px;

			@include mq(tablet) {
				margin-bottom: 0;
			}
		}

		&.oo-btn-wrapper-bottom {
			flex-wrap: wrap;

			@include mq(tablet) {
				display: flex;
				justify-content: space-between;
				padding: 18px 0 0;
				flex-wrap: unset;
			}

			> .toggle-rating {
				margin-top: 15px;

				@include mq(tablet) {
					margin-top: 0;
				}
			}

			> .a-basic-btn {
				margin-top: 15px;

				@include mq(tablet) {
					margin-top: 0;
				}
			}
		}
	}

	.oo-content-wrapper {
		display: none;

		.oo-sort-wrapper {
			padding: 10px 0;
			border-top: 1px solid $color-secondary-9;
			border-bottom: 1px solid $color-secondary-9;

			@include mq(tablet) {
				display: flex;
				justify-content: flex-end;
				align-items: center;
			}

			> label {
				display: inline-block;
				margin-bottom: 10px;

				@include mq(tablet) {
					margin-bottom: 0;
				}
			}
		}

		.oo-item-container {
			.oo-rating-item {
				padding: 25px 0;
				border-bottom: 1px solid $color-secondary-9;

				> .oo-header-box {
					margin: 0 0 10px 0;

					@include mq(tablet) {
						display: flex;
						justify-content: space-between;
					}

					> .oo-meta {
						line-height: 28px;

						// Exception Basic-Cluster-Box
						.o-basic-cluster-box & {
							margin-bottom: 0;
						}

						.oo-meta-item {
							display: inline;

							// adds pipe on end if not the only or last elem
							&:not(:only-child):not(:last-child):after {
								margin: 0 8px 0 8px;
								content: "|";
							}

							> address {
								display: inline;

							}
						}
					}

					.m-basic-rating {
						margin-bottom: 0;
					}
				}

				> .oo-headline {
					display: block;
					padding: 0 0 10px 0;

					// Exception Basic-Cluster-Box
					.o-basic-cluster-box & {
						padding-bottom: 5px;
					}
				}

				> .oo-subline {

					@include mq(tablet) {
						padding-right: 150px;
					}

					@include mq(desktop) {
						padding-right: 250px;
					}
				}
			}

			&.is-hidden {
				display: none;
			}
		}
	}
}