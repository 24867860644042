/* m-basic-teaser-wrapper-ves */
.m-basic-teaser-wrapper-ves {
	position: relative;
	padding: 40px 0;

	@include mq(desktop) {
		width: calc(100vw - 40px);
		left: 50%;
		transform: translateX(-50%);
	}

	@include mq(desktop-l) {
		width: 1200px;
	}

	&:after {
		content: '';
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
		height: 100%;
		width: 100vw;
		background-color: $color-secondary-8;
	}

	> * {
		position: relative;
		z-index: 1;
	}

	> .mm-wrapper {
		display: flex;
		column-gap: 20px;
		row-gap: 20px;
		flex-direction: column;

		@include mq(desktop) {
			flex-direction: row;
		}

		> .mm-item {
			background-color: $color-primary-2;
			flex: 1 0 auto;
			padding: 20px 25px;
			display: flex;
			flex-direction: column;
			border-radius: 8px;
			position: relative;

			@include if-parent('.m-basic-teaser-wrapper-ves--2col') {
				@include mq(desktop) {
					width: calc((100% - 20px) / 2);
				}
			}

			@include if-parent('.m-basic-teaser-wrapper-ves--3col') {
				@include mq(desktop) {
					width: calc((100% - 40px) / 3);
				}
			}

			@include if-parent('.m-basic-teaser-wrapper-ves--4col') {
				@include mq(desktop) {
					width: calc((100% - 60px) / 4);
				}
			}

			@include mq(desktop) {
				justify-content: space-between;
			}

			> .mm-content-wrapper {
				margin-bottom: 30px;

				> .mm-headline {
					@include milo-slab-bold($font-size-24, 24px);
					padding: 0 0 5px 0;

					&--short {
						padding: 0 100px 5px 0;
					}
				}

				> .mm-content {
					margin-top: 30px;

					//exception for weird yellow lines no one knows where they came from
					hr.yellow {
						&:nth-of-type(odd) {
							margin: 0 0 30px 0
						}

						&:nth-of-type(even) {
							margin: 30px 0 0 0
						}
					}

					> .mm-breaker {
						position: relative;
						@include main-icon-add-black-big;
						left: 50%;
						transform: scale(2) translateX(-50%);
						margin: 35px 0;
					}
				}
			}

			> .mm-cta-wrapper {
				padding-top: 15px;
				border-top: 1px solid $color-secondary-10;

				> .mm-price {
					display: flex;
					justify-content: flex-end;
					column-gap: 10px;
					align-items: flex-end;
					margin-bottom: 10px;

					> .mm-price-price {
						@include milo-slab-bold(26px, 26px);

						@include mq(tablet) {
							@include milo-slab-bold(32px, 32px);
						}
					}
				}
			}
		}
	}
}