/* m-basic-text--crashtest */
.m-basic-text--crashtest {

	.mm-flex {

		.mm-aside {

			@include mq(tablet) {
				width: 30%;
			}

			> figure {

				.mm-ratio-container {
					display: flex;

					> picture {
						> img {
							max-width: 100%;
							height: auto;
						}
					}
				}
			}
		}
	}
}