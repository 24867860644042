/* m-basic-teaser-wrapper */
.m-basic-teaser-wrapper {
	width: 100%;
	height: auto;

	&--wide {
		position: relative;
		width: calc(100% + 40px);
		margin-left: -20px;
		margin-right: -20px;

		@include mq(desktop) {
			width: calc(100% + 240px);
			margin-right: -120px;
			margin-left: -120px;
		}

		@include mq(desktop-l) {
			width: calc(100% + 360px);
			margin-right: -180px;
			margin-left: -180px;
		}

		@include mq(desktop-xl) {
			width: calc(100% + 440px);
			margin-right: -220px;
			margin-left: -220px;
		}

		&:before {
			display: none;
			top: 0;
			content: '';
			z-index: -1;
			width: calc(100vw + 40px);
			height: 100%;
			left: calc(50% - 50vw); // 50% - "halbes width"
			position: absolute;
			background-color: transparent;

			@include mq(tablet) {
				display: block;
			}
		}
	}

	// beides setzen, im before und hier
	// l-main-content hat weißen HG
	&[data-background="grey"] {
		background-color: $color-secondary-8;

		&:before {
			background-color: $color-secondary-8;
		}
	}

	> .mm-wrapper {
		max-width: 1270px;
		margin: 0 auto;
		padding: 25px 15px 25px;

		@include mq(desktop) {
			max-width: 1280px;
			padding: 40px;
		}

		@include if-parent('.m-basic-teaser-wrapper:not(.m-basic-teaser-wrapper--wide)') {
			padding: 0;

			@include if-parent('.m-basic-teaser-wrapper[data-background]') {
				padding: 15px;

				@include mq(desktop) {
					padding: 20px;
				}
			}
		}

		@include if-parent('.m-basic-teaser-wrapper:not([data-background]).m-basic-teaser-wrapper--wide') {
			padding: 0 15px 25px 15px;

			@include mq(desktop) {
				padding: 0 40px 20px 40px;
			}
		}

		/* autoprefixer grid: autoplace */
		> .mm-teaser-wrapper {
			display: -ms-grid;
			display: grid;
			align-content: stretch;
			justify-items: stretch;
			align-items: stretch;

			$available-grid-gaps: 10, 15, 20;

			@each $grid-gap in $available-grid-gaps {
				&[data-grid-gap="#{$grid-gap}"] {
					grid-gap: ($grid-gap+px);
					-ms-grid-columns: 1fr;
					grid-template-columns: 1fr;
					-ms-grid-rows: auto ($grid-gap+px) auto ($grid-gap+px) auto;
					grid-template-rows: auto auto auto;
					margin: 0 auto;

					@include mq(tablet) {
						-ms-grid-rows: auto ($grid-gap+px) auto;
						grid-template-rows: auto auto;
						-ms-grid-columns: 1fr ($grid-gap+px) 1fr;
						grid-template-columns: 1fr 1fr;
					}

					@include mq(desktop) {
						-ms-grid-columns: 1fr ($grid-gap+px) 1fr ($grid-gap+px) 1fr;
						grid-template-columns: 1fr 1fr 1fr;
						-ms-grid-rows: auto;
						grid-template-rows: auto;
					}

					&[data-stretch="true"] {
						justify-items: stretch;
					}

					&[data-columns="2"] {
						-ms-grid-rows: auto ($grid-gap+px) auto;
						grid-template-rows: auto auto;

						@include mq(tablet) {
							-ms-grid-rows: auto ($grid-gap+px) auto;
							grid-template-rows: auto auto;
							-ms-grid-columns: 1fr ($grid-gap+px) 1fr;
							grid-template-columns: 1fr 1fr;
						}

						@include mq(desktop-l) {
							-ms-grid-columns: 1fr ($grid-gap+px) 1fr;
							grid-template-columns: 1fr 1fr;
							-ms-grid-rows: auto;
							grid-template-rows: auto;
						}
					}

					&[data-columns="4"] {
						-ms-grid-rows: auto ($grid-gap+px) auto ($grid-gap+px) auto ($grid-gap+px) auto;
						grid-template-rows: auto auto auto auto;

						@include mq(tablet) {
							-ms-grid-columns: 1fr ($grid-gap+px) 1fr;
							grid-template-columns: 1fr 1fr;
							-ms-grid-rows: auto ($grid-gap+px) auto;
							grid-template-rows: auto auto;
						}

						@include mq(desktop) {
							-ms-grid-columns: 1fr ($grid-gap+px) 1fr ($grid-gap+px) 1fr ($grid-gap+px) 1fr;
							grid-template-columns: 1fr 1fr 1fr 1fr;
							-ms-grid-rows: auto;
							grid-template-rows: auto;
						}

						&[data-rows="2"] {
							-ms-grid-rows: 1fr ($grid-gap+px) 1fr ($grid-gap+px) 1fr ($grid-gap+px) 1fr;
							grid-template-rows: 1fr 1fr 1fr 1fr;

							@include mq(tablet) {
								-ms-grid-columns: 1fr ($grid-gap+px) 1fr;
								grid-template-columns: 1fr 1fr;
								-ms-grid-rows: auto ($grid-gap+px) auto;
								grid-template-rows: auto auto;
							}
						}

						&[data-stretch="true"] {
							margin: initial;
						}
					}

					&[data-columns="5"] {
						-ms-grid-rows: auto ($grid-gap+px) auto ($grid-gap+px) auto ($grid-gap+px) auto ($grid-gap+px) auto;
						grid-template-rows: auto auto auto auto auto;

						@include mq(tablet) {
							-ms-grid-columns: 1fr ($grid-gap+px) 1fr;
							grid-template-columns: 1fr 1fr;
							-ms-grid-rows: auto ($grid-gap+px) auto ($grid-gap+px) auto;
							grid-template-rows: auto auto auto;
						}

						@include mq(desktop-l) {
							-ms-grid-columns: 1fr ($grid-gap+px) 1fr ($grid-gap+px) 1fr ($grid-gap+px) 1fr ($grid-gap+px) 1fr;
							grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
							-ms-grid-rows: auto;
							grid-template-rows: auto;
						}

						&[data-rows="2"] {
							-ms-grid-rows: auto ($grid-gap+px) auto ($grid-gap+px) auto ($grid-gap+px) auto ($grid-gap+px) auto;
							grid-template-rows: auto auto auto auto auto;

							@include mq(tablet) {
								-ms-grid-columns: 1fr ($grid-gap+px) 1fr;
								grid-template-columns: 1fr 1fr;
								-ms-grid-rows: auto ($grid-gap+px) auto ($grid-gap+px) auto;
								grid-template-rows: auto auto auto;
							}

							@include mq(desktop) {
								-ms-grid-columns: 1fr ($grid-gap+px) 1fr ($grid-gap+px) 1fr;
								grid-template-columns: 1fr 1fr 1fr;
								-ms-grid-rows: auto ($grid-gap+px) auto;
								grid-template-rows: auto auto;
							}
						}

						&[data-stretch="true"] {
							margin: initial;
						}
					}

					&[data-columns="6"] {
						-ms-grid-rows: auto ($grid-gap+px) auto ($grid-gap+px) auto ($grid-gap+px) auto ($grid-gap+px) auto ($grid-gap+px) auto;
						grid-template-rows: auto auto auto auto auto auto;

						@include mq(tablet) {
							-ms-grid-columns: 1fr ($grid-gap+px) 1fr ($grid-gap+px) 1fr;
							grid-template-columns: 1fr 1fr 1fr;
							-ms-grid-rows: auto ($grid-gap+px) auto;
							grid-template-rows: auto auto;
						}

						@include mq(desktop) {
							-ms-grid-columns: 1fr ($grid-gap+px) 1fr ($grid-gap+px) 1fr;
							grid-template-columns: 1fr 1fr 1fr;
							-ms-grid-rows: auto ($grid-gap+px) auto;
							grid-template-rows: auto auto;
						}

						@include mq(desktop-l) {
							-ms-grid-columns: 1fr ($grid-gap+px) 1fr ($grid-gap+px) 1fr ($grid-gap+px) 1fr ($grid-gap+px) 1fr ($grid-gap+px) 1fr;
							grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
							-ms-grid-rows: auto;
							grid-template-rows: auto;
						}

						&[data-rows="2"] {
							-ms-grid-rows: auto ($grid-gap+px) auto ($grid-gap+px) auto ($grid-gap+px) auto ($grid-gap+px) auto ($grid-gap+px) auto;
							grid-template-rows: auto auto auto auto auto auto;

							@include mq(tablet) {
								-ms-grid-columns: 1fr ($grid-gap+px) 1fr;
								grid-template-columns: 1fr 1fr;
								-ms-grid-rows: auto ($grid-gap+px) auto ($grid-gap+px) auto;
								grid-template-rows: auto auto auto;
							}

							@include mq(desktop) {
								-ms-grid-columns: 1fr ($grid-gap+px) 1fr ($grid-gap+px) 1fr;
								grid-template-columns: 1fr 1fr 1fr;
								-ms-grid-rows: auto ($grid-gap+px) auto;
								grid-template-rows: auto auto;
							}

							@include mq(desktop-xl) {
								-ms-grid-columns: 1fr ($grid-gap+px) 1fr ($grid-gap+px) 1fr;
								grid-template-columns: 1fr 1fr 1fr;
								-ms-grid-rows: auto ($grid-gap+px) auto;
								grid-template-rows: auto auto;
							}
						}
					}
				}

				> .mm-teaser-box {
					max-width: calc((1200px - (#{$grid-gap+px} * 2)) / 3);

					@include if-parent('.mm-teaser-wrapper[data-rows="2"]') {
						max-width: 355px;

						@include mq(tablet) {
							max-width: calc((1200px - #{$grid-gap+px}) / 2);
						}
					}

					@include if-parent('.mm-teaser-wrapper[data-columns="3"]') {
						max-width: 355px;

						@include mq(tablet) {
							max-width: calc((1200px - (#{$grid-gap+px} * 2)) / 3);
						}
					}

					@include if-parent('.mm-teaser-wrapper[data-columns="4"]') {
						max-width: 355px;

						@include mq(tablet) {
							max-width: calc((1200px - (#{$grid-gap+px} * 3)) / 2);
						}

						@include mq(desktop-l) {
							max-width: calc((1200px - (#{$grid-gap+px} * 3)) / 4);
						}

						@include if-parent('.mm-teaser-wrapper[data-rows="2"]') {
							max-width: 355px;

							@include mq(tablet) {
								max-width: calc((1200px - #{$grid-gap+px}) / 2);
							}
						}
					}

					@include if-parent('.mm-teaser-wrapper[data-columns="5"]') {
						max-width: 355px;

						@include mq(tablet) {
							max-width: calc((1200px - (#{$grid-gap+px} * 4)) / 5);
						}

						@include if-parent('.mm-teaser-wrapper[data-rows="2"]') {
							max-width: 355px;

							@include mq(tablet) {
								max-width: calc((1200px - (#{$grid-gap+px} * 2)) / 3);
							}
						}
					}

					@include if-parent('.mm-teaser-wrapper[data-columns="6"]') {
						max-width: 355px;

						@include mq(tablet) {
							max-width: calc((1200px - (#{$grid-gap+px} * 2)) / 3);
						}

						@include mq(desktop-l) {
							max-width: calc((1200px - (#{$grid-gap+px} * 5)) / 6);
						}

						@include if-parent('.mm-teaser-wrapper[data-rows="2"]') {
							max-width: 355px;

							@include mq(tablet) {
								max-width: calc((1200px - (#{$grid-gap+px} * 2)) / 3);
							}
						}
					}
				}
			}

			// exception for button width respecting column size for .m-basic-teaser-wrapper--product-advanced
			&[data-columns="3"] {
				> .mm-teaser-box {
					> .mm-info-button {
						@include if-parent('.m-basic-teaser-wrapper--product-advanced') {
							@include mq(desktop) {
								flex: 0 1 80%;
							}
						}
					}
				}
			}

			@include if-parent('.m-basic-teaser-wrapper--product-advanced') {

				&[data-columns="1"],
				&[data-columns="2"] {
					@include mq(desktop) {
						max-width: 816px;
						grid-template-columns: 1fr 1fr;
					}

					@include mq(desktop-l) {
						max-width: 898px;
					}

					@include mq(desktop-xl) {
						max-width: 858px;
					}
				}
			}

			> .mm-teaser-box {
				width: 100%;
				display: flex;
				flex-flow: column nowrap;
				justify-content: flex-start;
				height: auto;
				position: relative;
				border-radius: 8px;
				background: $color-primary-2;
				padding: 15px;

				@include mq(tablet) {
					padding: 15px 20px;
				}

				// product details
				@include if-parent('.m-basic-teaser-wrapper--product-details') {
					padding: 0;
					border-radius: 0;
				}

				// product additional
				@include if-parent('.m-basic-teaser-wrapper--product-additional') {
					padding: 0;
					border-radius: 0 0 8px 8px;
				}

				// product advanced
				@include if-parent('.m-basic-teaser-wrapper--product-advanced') {
					padding: 0;
					border-radius: 0 0 8px 8px;
				}

				// in logged out state add a cover
				@include if-parent('.m-basic-teaser-wrapper--product-disabled') {
					&:before {
						content: "";
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						width: 100%;
						z-index: 10;
						background-color: transparentize($color-primary-2, 0.3);
					}
				}

				// center true
				@include if-parent('.mm-teaser-wrapper[data-center-slides="true"]') {
					// for IE 11
					margin: 0 auto;
				}

				// stretch to given space
				@include if-parent('.mm-teaser-wrapper[data-stretch="true"]') {
					min-width: 100%;
				}

				@include if-parent('.mm-teaser-wrapper.mm-teaser-border--grey') {
					border: 1px solid $color-secondary-16;
				}

				// same position for rows: 1/2/3/4/5/6
				&:nth-child(1) {
					-ms-grid-row: 1;
					-ms-grid-column: 1;
				}

				// same position for rows: 2/3/4/5/6
				// same position for columns: 2
				&:nth-child(2) {
					-ms-grid-row: 3;
					-ms-grid-column: 1;

					@include mq(tablet) {
						-ms-grid-row: 1;
						-ms-grid-column: 3;
					}
				}

				// same position for rows: 2/3/4/5/6
				// same position for columns: 2 | rows 1/2/3/4
				&:nth-child(3) {
					-ms-grid-row: 5;
					-ms-grid-column: 1;

					@include mq(tablet) {
						-ms-grid-row: 3;
						-ms-grid-column: 1;
					}

					@include mq(desktop) {
						-ms-grid-row: 1;
						-ms-grid-column: 5;
					}

					@include mq(desktop-l) {
						-ms-grid-row: 1;
						-ms-grid-column: 5;
					}

					// same position for columns: 2 | rows 5/6
					@include if-parent('.mm-teaser-wrapper[data-rows="2"][data-columns="5"], .mm-teaser-wrapper[data-rows="2"][data-columns="6"]') {

						@include mq(tablet) {
							-ms-grid-row: 3;
							-ms-grid-column: 1;
						}

						@include mq(desktop-l) {
							-ms-grid-row: 1;
							-ms-grid-column: 5;
						}
					}
				}

				// same position for rows: 4/5/6 -> for 1/2/3 there is no nth-child(4)
				&:nth-child(4) {
					-ms-grid-row: 7;
					-ms-grid-column: 1;

					@include mq(tablet) {
						-ms-grid-row: 3;
						-ms-grid-column: 3;
					}

					@include mq(desktop) {
						-ms-grid-row: 1;
						-ms-grid-column: 7;
					}

					// same position for columns: 2 | rows 4
					@include if-parent('.mm-teaser-wrapper[data-rows="2"]') {

						@include mq(tablet) {
							-ms-grid-row: 3;
							-ms-grid-column: 3;
						}

						@include mq(desktop-l) {
							-ms-grid-row: 3;
							-ms-grid-column: 3;
						}
					}
				}

				// same position for rows: 5/6 -> for 1/2/3/4 there is no nth-child(5)
				&:nth-child(5) {
					-ms-grid-row: 9;
					-ms-grid-column: 1;

					@include mq(tablet) {
						-ms-grid-row: 5;
						-ms-grid-column: 1;
					}

					@include mq(desktop-l) {
						-ms-grid-row: 1;
						-ms-grid-column: 9;
					}

					// same position for columns: 2 | rows: 5/6
					@include if-parent('.mm-teaser-wrapper[data-rows="2"]') {

						@include mq(tablet) {
							-ms-grid-row: 5;
							-ms-grid-column: 1;
						}

						@include mq(desktop-l) {
							-ms-grid-row: 3;
							-ms-grid-column: 1;
						}
					}
				}

				// same position for rows: 6 -> for 1/2/3/4/5 there is no nth-child(6)
				&:nth-child(6) {
					-ms-grid-row: 11;
					-ms-grid-column: 1;

					@include mq(tablet) {
						-ms-grid-row: 5;
						-ms-grid-column: 3;
					}

					@include mq(desktop-l) {
						-ms-grid-row: 1;
						-ms-grid-column: 11;
					}

					@include if-parent('.mm-teaser-wrapper[data-rows="2"]') {

						@include mq(tablet) {
							-ms-grid-row: 5;
							-ms-grid-column: 3;
						}

						@include mq(desktop-l) {
							-ms-grid-row: 3;
							-ms-grid-column: 5;
						}
					}
				}

				// flex: 0 0 auto necassery to fix all issues in IE11
				> .mm-teaser-headline {
					flex: 0 0 auto;
					margin: 0 0 15px 0;
					@include milo-slab-bold($font-size-18);
					@include line-height(22px);
					// vom ADAC so gewollt -
					// um eine dauerhafte gute Anzeige zu gewährleisten, trotz Störer
					max-width: calc(100% - 50px);
					hyphens: auto;

					@include mq(tablet) {
						@include milo-slab-bold($font-size-24);
						@include line-height(28px);
					}

					@include if-parent('.m-basic-teaser-wrapper--product-additional, .m-basic-teaser-wrapper--product-advanced') {
						padding: 0 15px;

						@include mq(tablet) {
							padding: 0 20px;
						}
					}

					@include if-parent('.m-basic-teaser-wrapper--product-details') {
						padding: 0 10px;

						@include mq(tablet) {
							padding: 0 20px;
						}
					}

					> .mm-additional-text {
						@include milo-regular($font-size-16);
						margin-left: 5px;
						white-space: nowrap;
					}
				}

				> .mm-teaser-content {
					flex: 0 1 auto;
					margin: 0 0 10px 0;

					@include mq(tablet) {
						flex: 1 1 auto;
					}

					@include if-parent('.mm-teaser-wrapper.mm-hide-content') {
						overflow: hidden;
						max-height: 0;
						transition: max-height 1.5s cubic-bezier(0, 1, 0, 1);

						@include mq(tablet) {
							overflow: visible;
							max-height: 1000px;
							transition: max-height 1.5s ease-in-out;
						}
					}

					&.is-shown {
						max-height: 1000px;
						transition: max-height 1.5s ease-in-out;
					}

					@include if-parent('.m-basic-teaser-wrapper--product-details') {
						flex: 0 1 auto;
						padding: 0 10px;

						@include mq(tablet) {
							padding: 0 20px;
						}
					}

					@include if-parent('.m-basic-teaser-wrapper--product-additional') {
						flex: 1 0 auto;
						padding: 0 15px;

						@include mq(tablet) {
							padding: 0 20px;
						}
					}

					@include if-parent('.m-basic-teaser-wrapper--product-advanced') {
						padding: 0 15px;

						@include mq(tablet) {
							padding: 0 20px;
						}
					}
				}

				> .mm-teaser-info-wrapper {
					flex: 0 1 auto;
					width: 100%;
					height: 100%;
					display: flex;
					flex-flow: row wrap;
					justify-content: flex-start;
					align-content: flex-start;

					position: relative;

					@include if-parent('.m-basic-teaser-wrapper--product, .m-basic-teaser-wrapper--product-advanced') {
						height: auto;
						align-content: flex-end;
						align-self: flex-end;

						@include mq(desktop-l) {
							align-content: flex-start;
						}
					}

					@include if-parent('.mm-teaser-info-wrapper--button-price') {
						align-self: flex-start;
					}

					@include if-parent('.m-basic-teaser-wrapper--product-additional') {
						align-content: flex-end;
						padding: 0 15px 15px 15px;

						@include mq(tablet) {
							padding: 0 20px 20px 20px;
						}
					}

					@include if-parent('.m-basic-teaser-wrapper--product-advanced') {
						padding: 0 15px 15px 15px;
					}

					// exception ll-depend
					&.ll-depend {
						display: none;
						clear: both;
					}

					> .mm-link-wrapper {
						flex: 1 1 100%;
						order: 1;

						> .mm-info-link {
							align-self: flex-end;
							margin: 0;
							flex: 1 1 100%;
							padding: 0 0 0 6px;

							@include mq(tablet) {
								align-self: flex-start;
							}

							@include if-parent('.m-basic-teaser-wrapper--product') {
								order: 1;
								margin: 0 0 20px 0;
							}

							@include if-parent('.m-basic-teaser-wrapper--product-additional') {
								margin: 0 0 20px 0;

								@include mq(tablet) {
									margin: 0 0 40px 0;
								}

								@include mq(desktop-l) {
									margin: 0 0 20px 0;
								}
							}

							@include if-parent('.m-basic-teaser-wrapper--ves') {
								@include mq(tablet) {
									float: left;
									margin-top: 8px;
									margin-bottom: 0;
								}
							}
						}

						> .mm-info-button {
							@include if-parent('.m-basic-teaser-wrapper--ves') {
								@include mq(tablet) {
									float: right;
								}
							}
						}
					}

					> .mm-info-price {
						margin: 0 0 20px 0;
						display: flex;
						flex-flow: row nowrap;
						justify-content: flex-end;
						flex: 1 1 55%;

						@include if-parent('.m-basic-teaser-wrapper--product') {
							order: 0;
						}

						@include if-parent('.mm-teaser-info-wrapper--button-price') {
							flex: 0 0 48%;
							margin: 0;
							align-self: center;
							align-items: center;

							@include mq(tablet) {
								position: absolute;
								bottom: 75px;
								right: 20px;
								width: 100%;
								height: 30px;
							}

							@include mq(desktop-l) {
								position: static;
								align-self: flex-end;
								flex: 0 0 48%;
								margin: 0 0 12px 0;
							}
						}

						> .mm-price-info {
							align-self: flex-end;
							margin: 0 5px 0 0;
						}

						> .mm-price {
							align-self: flex-end;
							@include milo-slab-bold(26px, 26px);

							@include mq(tablet) {
								@include milo-slab-bold(32px, 32px);
							}
						}

						> .mm-price-price-period {
							align-self: flex-end;
							margin: 0 0 0 5px;
						}
					}

					> .mm-info-button {
						flex: 1 1 100%;

						@include if-parent('.m-basic-teaser-wrapper--product') {
							order: 2;
						}

						@include if-parent('.m-basic-teaser-wrapper--product-advanced') {
							flex: 0 1 100%;

							@include if-parent('.mm-teaser-wrapper[data-columns="3"]') {
								flex: 0 1 70%;
							}

							@include if-parent('.mm-teaser-wrapper[data-columns="4"]') {
								flex: 0 1 80%;
							}
						}

						@include if-parent('.mm-teaser-info-wrapper--button-price') {
							flex: 1 1 52%;
							align-self: flex-start;

							@include mq(tablet) {
								flex: 1 1 100%;
								align-self: flex-end;
							}

							@include mq(desktop-l) {
								flex: 1 1 52%;
								// vertical centering buttons and price
								display: flex;
								align-items: center;
								// issue to center the buttons vertically if they have one line or more
								// so you have to so set a height
								height: 55px;
							}
						}

						&.is-hidden {
							@include if-parent('.m-basic-teaser-wrapper--product, .m-basic-teaser-wrapper--product-additional') {
								visibility: hidden;
							}
						}
					}
				}

				> .mm-teaser-image {
					flex: 0 0 auto;
				}
			}
		}
	}
}