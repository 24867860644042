//basic-calculator-tiles
.m-basic-calculator-tiles {
	padding: 35px 0;

	@include mq(tablet) {
		padding: 60px 0;
	}

	&.h-space-none {
		padding: 10px 0;
		margin: 0;
	}


	&--left {
		display: flex;
		justify-content: flex-start;
	}

	&.h-background {
		position: relative;

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0;
			height: 100%;
			width: 50%;
			background-color: $color-secondary-8;
		}

		&:before {
			left: -50%;
		}

		&:after {
			right: -50%;
		}
	}

	&.h-background.h-transparent {
		padding-top: 15px;
		margin-bottom: 0;
		background-color: $color-primary-2;

		@include mq(tablet) {
			padding-top: 15px;
			margin-bottom: 15px;
		}

		&:before,
		&:after {
			background-color: $color-primary-2;
		}
	}

	//exception fieldset first child

	.ll-fieldset & {
		&:first-child {
			padding-top: 0;
		}
	}

	> .mm-wrapper {
		position: relative;
		z-index: 10;

		> .mm-intro {
			> h2 {
				text-align: center;
				margin-bottom: 10px;
				position: relative;

				@include if-parent('.m-basic-calculator-tiles.m-basic-calculator-tiles--left') {
					text-align: left;
				}
			}

			> .mm-text {
				margin-bottom: 30px;
				text-align: center;

				&--small {
					max-width: 700px;
					margin: 0 auto 30px auto;
				}

				&--left {
					text-align: left;
				}
			}
		}

		.mm-tile-wrapper {
			@include mq(tablet) {
				display: flex;
				justify-content: center;
				column-gap: 30px;
				flex-wrap: wrap;
			}

			&--nowrap {
				@include mq(desktop) {
					flex-wrap: nowrap;
				}
			}

			&--stretch {
				@include mq(tablet) {
					align-items: stretch;
				}
			}

			@include if-parent('.m-basic-calculator-tiles--image') {
				@include mq(tablet) {
					justify-content: flex-start;
				}
			}

			@include if-parent('.m-basic-calculator-tiles--left') {
				@include mq(tablet) {
					justify-content: flex-start;
				}
			}

			+ .mm-content-row {
				margin-top: 30px;
			}

			~ .mm-subrowheadline {
				margin-top: 30px;
			}

			+ .m-basic-text {
				@include mq(tablet) {
					margin-top: 25px;
				}
			}

			> .mm-tilerow {

				&:not(:last-child) {
					margin-bottom: 40px;

					@include if-parent('.m-basic-calculator-tiles--product') {
						margin-bottom: 15px;
					}

					@include mq(tablet) {
						margin-bottom: 0;
					}
				}

				&--multirow {
					max-width: 305px;
				}

				> h3 {
					margin-bottom: 20px;
				}

				> .mm-tiles-section {

					@include if-parent('.mm-tile-wrapper.mm-tile-wrapper--stretch') {
						@include mq(tablet) {
							height: 100%;
						}
					}

					> .mm-tile {
						width: 305px;

						@include if-parent('.mm-tile-wrapper.mm-tile-wrapper--stretch') {
							@include mq(tablet) {
								height: 100%;
							}
						}

						@include if-parent('.m-basic-calculator-tiles--image') {
							max-width: 375px;
							width: 100%;
						}

						@include if-parent('.m-basic-calculator-tiles--product') {
							max-width: 375px;
							width: 100%;

							@include mq(tablet) {
								width: 330px;
							}

							@include mq(desktop) {
								width: 350px;
							}
						}

						&:last-of-type {
							margin-bottom: 20px;
						}

						&:not(:first-child) {
							margin-top: 20px;

							@include mq(tablet) {
								margin-top: 30px;
							}
						}

						> .mm-tile-main {
							position: relative;

							@include mq(tablet) {
								margin-bottom: -5px;
							}

							@include if-parent('.mm-tile-wrapper.mm-tile-wrapper--stretch') {
								@include mq(tablet) {
									height: 100%;
								}
							}

							> input {
								position: absolute;
								z-index: 0;
								pointer-events: none;
								left: 5px;
								top: 5px;

								&:focus ~ label {
									.is-keyboard-focus & {
										padding: 9px 14px;
										border: 2px solid $color-secondary-22;

										&:before {
											content: '';
											position: absolute;
											left: -6px;
											top: -6px;
											width: calc(100% + 12px);
											height: calc(100% + 12px);
											border: 3px solid $color-secondary-1;
											border-radius: 8px;
										}
									}
								}

								&:checked ~ label {
									padding: 9px 14px;
									background-color: $color-primary-1;
									border: 2px solid $color-secondary-22;

									@include if-parent('.m-basic-calculator-tiles--product') {
										padding: 10px 20px;

										@include mq(tablet) {
											padding-bottom: 78px;
										}
									}

									> .mm-info {
										> .mm-copy {
											color: $color-secondary-17;
										}
									}

									> .mm-handler {
										&:after {
											content: '';
											@include main-icon-check-yellow;
											position: absolute;
											left: -16px;
											top: -16px;
										}

										&--radio {
											&:after {
												content: '';
												position: absolute;
												top: 2px;
												left: 2px;
												width: 9px;
												height: 9px;
												background: $color-secondary-1;
												border-radius: 50%;

												@include if-parent('.m-basic-calculator-tiles--product') {
													width: 12px;
													height: 12px;
													background: $color-secondary-22;
												}
											}
										}
									}
								}
							}

							> label {
								padding: 10px 15px;
								position: relative;
								display: flex;
								align-items: center;
								border: 1px solid $color-secondary-23;
								border-radius: 8px;
								cursor: pointer;
								background-color: $color-primary-2;

								@include if-parent('.mm-tile-wrapper.mm-tile-wrapper--stretch') {
									@include mq(tablet) {
										height: calc(100% - 20px);
										align-items: start;
										margin-top: 15px;
									}
								}

								@include if-parent('.m-basic-calculator-tiles--image') {
									flex-wrap: wrap;
									align-items: center;

									@include mq(tablet) {
										align-items: flex-start;
									}
								}

								@include if-parent('.m-basic-calculator-tiles--product') {
									padding: 10px 20px;

									@include mq(tablet) {
										padding-bottom: 80px;
									}
								}

								&:hover {
									background-color: $color-secondary-6;
									border: 1px solid $color-secondary-22;
								}

								span {
									display: block;
								}

								> .mm-icon {
									margin-right: 15px;
									width: 48px;
									height: 48px;
									background-color: $color-primary-1;
									border-radius: 50%;
									overflow: hidden;
									flex: 0 0 48px;

									@include if-parent('.m-basic-calculator-tiles--image') {
										width: 100%;
										height: auto;
										flex: 0 0 auto;
										overflow: visible;
										background-color: transparent;
										margin-bottom: 10px;

										@include mq(tablet) {
											width: 100px;
											margin-bottom: 0;
										}
									}

									> img {
										left: 50%;
										top: 50%;
										position: relative;
										transform: translate(-50%, -50%);

										@include if-parent('.m-basic-calculator-tiles--image') {
											width: 100%;
											max-width: 110px;
											height: auto;
											transform: translate(-50%, 0);
											left: 50%;
											top: 0;

											@include mq(tablet) {
												transform: none;
												left: 0;
											}
										}
									}
								}

								> .mm-info {
									flex: 1;
									padding-top: 5px;

									> .mm-headline {
										@include milo-bold();
										margin-bottom: 5px;
										line-height: 1.2;

										@include if-parent('.m-basic-calculator-tiles--product') {
											margin-bottom: 10px;
											padding-left: 38px;
											@include font-size(23px);
											color: $color-secondary-22;
										}
									}

									> .mm-tarif {
										@include milo-bold();
										background-color: $color-primary-2;
										padding: 0 5px;
										display: inline-block;
										margin-bottom: 5px;
										position: relative;
										left: -5px;
									}

									> .mm-copy {
										color: $color-secondary-20;
										line-height: 1.2;

										@include if-parent('.m-basic-calculator-tiles--product') {
											margin-bottom: 17px;
											color: $color-secondary-22;
										}
									}

									> .mm-list {
										display: block;

										& + .mm-list {
											margin-top: 15px;
										}

										> .mm-list-item {
											display: block;
											position: relative;
											margin: 0 0 3px 0;
											padding: 0 0 0 30px;
											@include line-height(19px);

											&:before {
												content: '';
												display: inline-block;
												@include main-icon-bullet;
												position: absolute;
												left: -9px;
												top: -12px;

												@include if-parent('.mm-list--plus') {
													background: $color-secondary-22;
													left: 9px;
													top: 9px;
													width: 8px;
													height: 2px;
												}
											}

											&:after {
												@include if-parent('.mm-list.mm-list--plus') {
													content: '';
													display: block;
													position: absolute;
													width: 2px;
													height: 8px;
													top: 6px;
													left: 12px;
													background: $color-secondary-22;
												}
											}
										}
									}

									> .mm-price {
										margin-top: 17px;
										padding: 5px 0 5px 3px;
										border-top: 1px solid $color-secondary-21;

										@include mq(tablet) {
											position: absolute;
											bottom: 15px;
											width: calc(100% - 40px);
										}

										> span {

											> strong {
												@include milo-bold(24px);
											}
										}
									}
								}

								> .mm-handler {
									position: relative;
									width: 17px;
									height: 17px;
									flex: 0 0 17px;
									border: 2px solid $color-secondary-1;
									border-radius: 4px;

									&--radio {
										border-radius: 50%;
									}

									@include if-parent('.mm-tile-wrapper.mm-tile-wrapper--stretch') {
										@include mq(tablet) {
											margin-top: 15px;
										}
									}

									@include if-parent('.m-basic-calculator-tiles--image') {
										@include mq(tablet) {
											top: 50%;
											transform: translateY(-50%);
										}
									}

									@include if-parent('.m-basic-calculator-tiles--product .mm-tile-wrapper.mm-tile-wrapper--stretch') {
										position: absolute;
										top: 18px;
										left: 20px;
										border: 2px solid $color-secondary-22;
										width: 20px;
										height: 20px;

										@include mq(tablet) {
											margin-top: 0;
										}
									}
								}
							}
						}

						> .mm-additional-info {
							@include if-parent('.mm-tile-wrapper.mm-tile-wrapper--stretch') {
								@include mq(tablet) {
									position: absolute;
									margin-top: -20px;
								}
							}

							> * {
								margin-bottom: 10px;
							}
						}
					}

					> .mm-hidden-content {
						@include mq(tablet) {
							width: 400px;
						}

						@include if-parent('.mm-tilerow.mm-tilerow--multirow') {
							@include mq(tablet) {
								width: auto;
							}
						}

						> div {
							margin-top: 15px;
						}

						.mm-clone-content {
							.ll-clone-wrapper {
								&:not(:last-child) {
									margin-bottom: 30px;

									@include mq(tablet) {
										margin-bottom: 40px;
									}
								}
							}

							.mm-button-trigger {
								&--plus {
									position: relative;
									padding-left: 40px;
									cursor: pointer;

									&:before {
										display: block;
										width: 22px;
										height: 22px;
										content: '';
										@include main-icon-clone;
										position: absolute;
										top: -13px;
										left: -13px;
									}
								}
							}
						}
					}
				}
			}
		}

		> .mm-content-row {

			&--flex {
				display: flex;

				> div {
					flex: 1 1 auto;
					max-width: 400px;
				}

				&.l-form {
					> div {
						max-width: none;
					}
				}
			}

			&--centered {
				justify-content: center;
			}

			.ll-row {
				&:only-child {
					.ll-col-data {
						justify-content: center;
					}
				}
			}
		}
	}

	.mm-subrowheadline {
		position: relative;
		max-width: none;
		width: 100%;
		text-align: center;
		margin-bottom: 25px;

		@include mq(tablet) {
			max-width: 610px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 30px;
		}

		&.is-left {
			text-align: left;
		}
	}

	.is-forced-hidden {
		display: none !important;
	}

	.js-row {
		> .mm-error-msg {
			color: $color-action-alert;
			text-align: center;
			margin-bottom: 10px;
			display: none;
		}
	}

	.js-row.is-error {
		> .mm-error-msg {
			display: block;
		}
	}
}