/* m-basic-table--compare-mgl */
.m-basic-table--compare-mgl {

	> tbody {


		> tr {
			background: $color-primary-2 !important;

			> td {
				@include mq(tablet) {
					min-width: 0;
				}
			}
		}
	}

	> thead {
		> tr {
			&:nth-child(2) {
				background: $color-primary-2 !important;
			}

			> th {
				@include mq(tablet) {
					min-width: 0;
				}
			}
		}
	}
}