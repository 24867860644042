.o-basic-cluster-box--in-content {
	background: $color-primary-2;

	> .oo-header {
		padding: 17px 0;
	}

	> .oo-main {
		> section.oo-content-box {
			padding: 10px 0;

			@include mq(tablet) {
				padding-top: 0;
				padding-left: 25px;
			}
		}
	}
}