/*.m-my-adac-image*/
.m-my-adac-image {

	.l-outer--my-adac & {
		margin: 0 0 24px 0;
	}

	> .mm-wrapper {

		> .mm-image {
			display: flex;
			justify-content: center;
			width: 100%;

			> .mm-img-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;

				> img {
					width: auto;
					min-height: 130px;
					max-height: 130px;

					@include if-parent('.mm-image--big') {
						min-height: 180px;
						max-height: 180px;
					}
				}
			}

		}
	}

}