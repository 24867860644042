/* .m-adac-cardamage */

$tableBorderStyle: 1px solid $color-secondary-3;

.m-adac-cardamage {
	background-color: $color-secondary-8;
	padding: 8px 15px 10px 15px;
	width: 100%;

	@include mq(tablet) {
		padding: 10px 25px 20px 25px;
	}

	// exception for l-main-content--cluster
	.l-main-content--cluster > & {
		padding: 8px 15px 10px 15px;

		@include mq(desktop) {
			padding: 10px 25px 20px 25px;
		}
	}

	// exception for lightbox/layer
	.ll-lightbox-inner & {
		background-color: $color-primary-2;
	}

	> .mm-head {
		@include milo-slab-bold($font-size-18, 24px);
		margin-bottom: 10px;

		.h-roboto & {
			font-family: 'Roboto Bold', sans-serif;
		}

		@include mq(tablet) {
			@include font-size($font-size-28, 34px);
			margin-bottom: 32px;
		}
	}

	> .mm-content {

		display: flex;
		justify-content: flex-start;
		flex-flow: column wrap;

		@include mq(desktop) {
			flex-flow: row wrap;
			justify-content: space-between;
		}

		// exception for lightbox/layer
		.ll-lightbox-inner & {
			padding: 0;
		}

		> .mm-left {

			@include mq(desktop) {
				width: 50%;
				padding-right: 20px;
				min-height: 242px;
				position: relative;
			}

			// exception for lightbox/layer
			.ll-lightbox-inner & {

				display: flex;
				flex-flow: column nowrap;
				padding-right: 0;

				@include mq(desktop) {
					width: 100%;
					height: auto;
					position: relative;
				}
			}



			.mm-timestamp {
				@include milo-regular($font-size-16);
				color: $color-secondary-3;

				.h-roboto & {
					font-family: 'Roboto', sans-serif;
				}
			}

			.mm-item {

				&.is-high-load {
					border: 2px solid $color-action-alert;
					border-radius: 5px;
					padding: 12px 15px 0 15px;

					@include mq(tablet) {
						padding: 20px 15px 0 15px;
					}
				}

				> .mm-infotable {

					@include mq(desktop) {
						max-height: 135px;
					}
				}
			}

			.mm-item,
			.mm-layeritem {

				display: none;
				margin-bottom: 15px;

				&.is-visible {
					display: block;
				}

				> .mm-status-info {
					position: relative;
					font-weight: bold;
					padding-left: 40px;
					margin-bottom: 10px;
					@include milo-bold($font-size-16, 18px);

					.h-roboto & {
						font-family: 'Roboto Bold', sans-serif;
					}

					@include mq(desktop) {
						@include font-size($font-size-20, 22px);
						margin-bottom: 13px;
					}

					@include if-parent('.mm-item.is-high-load') {
						padding: 0 85px 0 45px;
						min-height: 42px;
						align-items: center;

						@include mq(tablet) {
							padding: 0 90px 0 45px;
						}
					}

					&--missed-call {
						padding-left: 35px;
					}

					> .mm-icon {
						position: absolute;
						left: 0;

						&:after {
							content: '';
							position: absolute;
							top: 1px;
							left: 1px;
						}

						&--vehicle {
							&:after {
								padding-left: 38px;
								top: -8px;
								left: -8px;
								@include main-icon-vehicle;
								width: initial;

								@include if-parent('.m-adac-cardamage.m-adac-cardamage-layer') {
									top: -11px;

									@include mq(tablet) {
										top: 2px;
									}
								}
							}
						}

						// missedCall has to be in camel case
						&--missedCall {
							&:after {
								padding-left: 43px;
								top: -9px;
								left: -8px;
								@include main-icon-missedCall;
								width: initial;
								transform: scale(0.8);

								@include if-parent('.m-adac-cardamage.m-adac-cardamage-layer') {
									top: -12px;

									@include mq(tablet) {
										top: 1px;
									}
								}
							}
						}

						&--not-solved {
							&:after {
								padding-left: 43px;
								top: -14px;
								left: -8px;
								@include damage-declaration-icon-panne();
								width: initial;

								@include if-parent('.m-adac-cardamage.m-adac-cardamage-layer') {
									top: -17px;

									@include mq(tablet) {
										top: -5px;
									}
								}
							}
						}

						&--user {
							&:after {
								padding-left: 38px;
								top: -8px;
								left: -8px;
								@include main-icon-user;
								width: initial;

								@include if-parent('.m-adac-cardamage.m-adac-cardamage-layer') {
									top: -11px;

									@include mq(tablet) {
										top: 2px;
									}
								}
							}
						}

						&--report-received {
							&:after {
								@include if-parent('.m-adac-cardamage.m-adac-cardamage-layer') {
									top: 0;

									@include mq(tablet) {
										top: 11px;
									}
								}
							}
						}

						&--cancellation {
							&:after {
								padding-left: 44px;
								top: -20px;
								left: -8px;
								@include main-icon-nicht-kompatibel();
								width: initial;
								transform: scale(0.8);

								@include if-parent('.m-adac-cardamage.m-adac-cardamage-layer') {
									top: -24px;
									left: -10px;
									transform: scale(0.6);

									@include mq(tablet) {
										top: -12px;
									}
								}
							}
						}
					}

					> img {
						@include if-parent('.m-adac-cardamage.m-adac-cardamage-layer') {
							// necessary because we are dealing with both sprite icons images with inline style and 'normal' icons
							transform: translateY(1px);
							top: 2px;
						}
					}

					> div {
						@include if-parent('.m-adac-cardamage.m-adac-cardamage-layer') {
							top: 2px;

							@include mq(tablet) {
								top: -8px;
							}
						}
					}

					> .mm-status-info-text {
						max-width: 265px;
						display: inline-block;

						@include mq(tablet) {
							max-width: initial;
						}
					}

					> .mm-timestamp {
						margin-bottom: 5px;
						margin-top: 7px;

						@include mq(tablet) {
							margin-top: 0;
						}

						@include if-parent('.mm-item.is-high-load') {
							margin-bottom: 0;
						}

						> .mm-status-info-text {
							font-weight: bold;
							@include milo-bold($font-size-16, 18px);
							color: $color-primary-3;
							margin-right: 10px;

							.h-roboto & {
								font-family: 'Roboto Bold', sans-serif;
							}

							@include mq(desktop) {
								@include font-size($font-size-20, 22px);
							}
						}

						span:last-child {
							white-space: nowrap;
						}
					}

					// exception for lightbox/layer
					.ll-lightbox-inner & {
						@include mq(tablet) {
							@include font-size($font-size-21, 21px);
							margin-bottom: 13px;
						}
					}
				}

				> .mm-infotable {

					margin-bottom: 10px;
					overflow-x: hidden;
					overflow-y: hidden;

					@include mq(desktop) {
						margin-top: 14px;
						margin-bottom: 12px;
					}

					// custom scrollbar styles
					&.jspScrollable {

						&:focus {
							outline: none;
						}

						> .jspContainer {

							//scrollbar wrapper
							> .jspVerticalBar {
								width: 5px;
								right: 5px;

								> .jspTrack {
									background: $color-secondary-5;

									> .jspDrag {
										background: $color-secondary-2;
									}
								}
							}

							> .jspHorizontalBar {
								display: none;
							}
						}
					}

					&:not(.jspScrollable) {
						> .jspContainer {
							height: 20px;
						}
					}

					table {
						width: 100%;
						border-collapse: collapse;
						table-layout: fixed;

						@include mq(desktop) {
							table-layout: auto;
						}

						> tbody {

							> tr {
								border: $tableBorderStyle;

								> th {
									width: 36%;
									padding: 5px 10px;
									border: $tableBorderStyle;
									text-align: left;

									@include mq(tablet) {
										width: 130px;
									}

									@include mq(desktop) {
										width: auto;
									}
								}

								> td {
									width: 65%;
									border: $tableBorderStyle;
									padding: 5px 10px;
									word-wrap: break-word;

									> .mm-date {
										display: flex;
										flex-flow: row wrap;

										> span {
											padding: 0;
										}

										> .td-date1 {
											padding-right: 5px;
										}
									}
								}
							}
						}
					}

				}

				> .mm-infotext {
					display: block;
					margin-bottom: 10px;
				}

				> .mm-telnum {
					display: inline-block;
					margin-bottom: 8px;
					font-weight: bold;
					text-decoration: none;
					align-self: flex-start;

					@include milo-bold($font-size-16, 18px);

					.h-roboto & {
						font-family: 'Roboto Bold', sans-serif;
					}

					@include mq(desktop) {
						@include font-size($font-size-20, 22px);
						margin-bottom: 10px;
					}
				}

				// exception for lightbox/layer
				.ll-lightbox-inner & {
					display: flex;
					flex-flow: column nowrap;
					margin-bottom: 20px;
					border-bottom: 1px solid $color-secondary-5;


					&:first-child {
						border: none;
					}

					> .mm-timestamp {
						color: $color-secondary-3;
						margin-bottom: 16px;
					}
				}
			}

			> .mm-bottom {
				margin-left: 1px;
				position: relative;

				@include mq(desktop) {
					display: flex;
					flex-flow: column wrap;
					justify-content: flex-start;
					position: static;
				}

				// exception for lightbox/layer
				.ll-lightbox-inner & {

					margin-bottom: 10px;

					@include mq(desktop) {
						margin-bottom: 36px;
					}

					> .mm-layer-headline {
						@include milo-slab-bold($font-size-18, 24px);
						margin-bottom: 10px;

						.h-roboto & {
							font-family: 'Roboto Bold', sans-serif;
						}

						@include mq(tablet) {
							@include font-size($font-size-28, 34px);
							margin-bottom: 30px;
						}

						> img {
							margin-right: 8px;
						}

						> .aa-number {
							display: inline-block;
							width: 20px;
							height: 20px;
							text-align: center;
							background: $color-primary-1;
							border-radius: 50%;
							position: relative;
							top: 3px;
							left: 8px;

							> span {
								@include milo-bold(12px);
								display: block;
								position: relative;
								top: -7px;
							}
						}
					}

				}

				> .mm-linklist {

					width: 100%;
					margin: 0 0 15px 0;

					@include mq(desktop) {
						margin: 0;
					}

					> a:not(:first-child) {
						margin-top: 5px;
					}

					&--row {

						@include mq(desktop) {
							display: flex;
							justify-content: space-between;
							margin-top: -3px;
						}

						> a:not(:first-child) {

							@include mq(desktop) {
								margin-top: 0;
							}

						}
					}
				}

				> .mm-timerefresh {
					position: relative;
					line-height: 18px;

					@include mq(desktop) {
						width: calc(100% - 20px);
						padding-right: 36px;
						margin-top: 14px;
					}

					// exception for lightbox/layer
					.ll-lightbox-inner & {
						display: block;

						@include mq(desktop) {
							position: relative;
							width: 100%;
						}
					}

					> .mm-timerefresh-content {
						position: relative;
						padding: 10px 0 10px 5px;

						display: flex;
						flex-flow: column nowrap;
						justify-content: center;

						@include mq(tablet) {
							flex-flow: row wrap;
							justify-content: flex-start;
						}

						@include mq(desktop) {
							width: 100%;
							padding: 3px 0 5px 0;
						}

						> .mm-last {
							margin-right: 5px;
						}

						> .mm-timestamp {
							white-space: nowrap;
						}

						> .mm-refresh-icon {
							position: absolute;
							right: 0;
							bottom: 12px;
							width: 30px;
							height: 30px;
							background-color: $color-primary-1;
							cursor: pointer;

							> img {
								margin: 3px 0 0 3px;
							}

							@include mq(tablet) {
								bottom: 4px;
							}

							@include mq(desktop) {
								right: -36px;
								top: -2px;
								bottom: unset;
							}
						}
					}

					> hr {
						border-color: $color-secondary-9;
						margin: 0;

						&:first-of-type {
							margin-top: 15px;

							@include mq(desktop) {
								margin-top: 10px;
							}
						}

						&:last-of-type {
							margin-bottom: 15px;

							@include mq(desktop) {
								margin-bottom: 0;
							}
						}
					}
				}

				> .mm-timestamp {
					margin-top: -5px;
					margin-bottom: 10px;

					@include mq(tablet) {
						margin-top: 0;
					}
				}

			}
		}

		> .mm-right {
			position: relative;
			width: 100%;
			max-width: 420px;

			@include mq(desktop) {
				padding-top: 2px;
				width: 50%;
			}

			> img {
				width: 100%;
			}

			> .mm-location {
				position: absolute;
				bottom: 0;
				display: flex;
				flex-flow: column nowrap;
				width: 100%;
				min-height: 70px;
				padding: 14px 18px;
				background-color: $color-secondary-10;


				> .mm-location-head {
					width: 100%;
					@include milo-bold();

					.h-roboto & {
						font-family: 'Roboto Bold', sans-serif;
					}
				}
			}
		}
	}
}