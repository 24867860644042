.m-ves-rs-list--my-adac {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	visibility: visible;

	@include mq(tablet) {
		flex-flow: row nowrap;
	}

	&.h-space-l {
		margin-bottom: 15px;

		@include mq(tablet) {
			margin-bottom: 40px;
		}
	}

	> .mm-variant-teaser {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		max-width: 100%;
		margin: 0 0 20px 0;

		@include mq(tablet) {
			display: inline-block;
			max-width: 180px;
			text-align: center;
			margin: 0 20px 0 0;
		}

		&:last-child {
			margin-right: 0;
		}

		img {
			display: inline-block;
			margin-right: 20px;

			@include mq(tablet) {
				margin: 0 auto 5px auto;
				display: block;
			}
		}
	}
}