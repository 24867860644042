/* a-basic-btn--grey */
.a-basic-btn--grey {
	background: $color-secondary-9;
	background-color: $color-secondary-9;
	color: $color-secondary-1;

	&:hover {
		background-color: $color-secondary-9;
		color: $color-secondary-1;
	}

	&:focus {
		background-color: $color-secondary-9;
	}
}