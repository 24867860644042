/* m-layout-footer-linklist */
.m-layout-footer-linklist {
	text-align: center;

	@include mq(print) {
		display: none;
	}

	> ul {
		padding: 8px 0 0 0;
		list-style: none;
		font-size: $font-size-14;

		> li {
			display: inline-block;
			padding: 0 3px;

			&:after {
				content: "|";
				padding: 0 3px 0 13px;
			}

			&.mm-last:after {
				content: none;
			}
		}
	}
}