.m-basic-form-footer-sticky {
	background: $color-primary-1;
	width: 100%;
	position: fixed;
	left: 0;
	margin: 0 auto;
	z-index: 1002;
	display: none;
	bottom: 0;
	box-shadow: 0 -2px 4px -1px rgba(0, 0, 0, 0.2), 0 -1px 10px 0 rgba(0, 0, 0, 0.12), 0 -4px 5px 0 rgba(0, 0, 0, 0.14);

	@include mq(tablet) {
		width: 100vw;
		max-width: 927px; //898px
		left: 50%;
		transform: translate3d(-50%, 0, 0);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding-bottom: 0;
	}

	@include mq(print) {
		position: relative !important;
		transform: none;
		left: 0;
	}

	&.is-visible {
		display: block;
	}

	&.is-hidden:not(.is-stuck-bottom) {
		display: none;
	}

	&.is-stuck-bottom {
		position: absolute;

		&.is-social-sharing {
			bottom: -216px;

			@include mq(tablet) {
				bottom: -89px;
			}

			@include mq(desktop) {
				bottom: -79px;
			}
		}
	}

	&--error {
		background: $color-action-alert;
		color: $color-primary-2;
	}

	> .mm-header {
		padding: 10px 15px;
		max-width: 898px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: flex-end;
		position: relative;

		@include mq(tablet) {
			padding: 35px 30px;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;
		}

		@include if-parent('.m-basic-form-footer-sticky--whole-footer') {
			@include mq(desktop) {
				padding: 35px 0;
			}
		}

		@include if-parent('.m-basic-form-footer-sticky--subhead') {
			@include mq(tablet) {
				padding: 25px 30px;
			}
		}

		@include if-parent('.m-basic-form-footer-sticky--error') {
			display: block;
		}

		> .mm-btn-row {
			display: flex;
			flex-direction: column;

			@include mq(desktop) {
				flex-direction: row;
				justify-content: space-between;
				width: 100%;
			}

			> * {
				&:first-child {
					order: 2;
					margin-top: 10px;

					@include mq(desktop) {
						order: 1;
						margin-top: 0;
					}
				}

				&:nth-child(2) {
					order: 1;

					@include mq(desktop) {
						order: 2;
					}
				}
			}
		}

		> .mm-error-text {
			padding-left: 30px;
			position: relative;

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-info-white;
				position: absolute;
				left: -10px;
				top: 0;

				@include mq(tablet) {
					top: -10px;
				}
			}
		}

		> .mm-price-row {
			margin-bottom: 20px; //10px

			@include mq(tablet) {
				margin-bottom: 0;
				margin-right: auto;
			}

			&--long {
				margin-bottom: 0;
				width: 100%;
			}

			> .mm-subhead {
				padding-left: 3px;
				padding-right: 50px;
				margin-bottom: 12px;
				@include milo-bold($font-size-16, 24px);

				@include mq(tablet) {
					padding-right: 0;
				}
			}

			> .mm-price-box-big {
				display: flex;
				width: 100%;
				padding-right: 0;

				@include if-parent('.mm-price-row.mm-price-row--long') {
					display: block;
					width: 100%;

					@include mq(tablet) {
						display: flex;
					}
				}

				&.is-no-events {
					cursor: default;
				}

				> .mm-header-label {
					display: block;
					@include milo-bold($font-size-16, 24px);
					padding-right: 10px;
					vertical-align: bottom;
					white-space: nowrap;

					@include mq(tablet) {
						background-position: left 2px;
						padding-right: 20px;
						@include milo-bold($font-size-22, 28px);
					}

					@include if-parent('.mm-price-row.mm-price-row--long') {
						display: block;
						text-align: left;
					}

					@include if-parent('.m-basic-form-footer-sticky--subhead') {
						@include mq(tablet) {
							padding-right: 10px;
						}
					}

					@include if-parent('.m-basic-form-footer-sticky--whole-footer') {
						@include mq(tablet) {
							padding-right: 10px;
						}

						@include mq(desktop) {
							padding-right: 20px;
						}
					}

					&.has-content {
						padding-left: 40px;
						max-width: 360px;
						cursor: pointer;
						position: relative;

						@include if-parent('.mm-price-row.mm-price-row--long') {
							max-width: none;
						}

						&:after {
							@include main-icon-arrow-round-up;
							position: absolute;
							content: '';
							left: -14px;
							top: -12px;

							@include mq(tablet) {
								left: -20px;
							}
						}
					}

					&.is-open.has-content {
						&:after {
							@include main-icon-arrow-round-down;
						}
					}

					&.has-content + .mm-price-normal {
						cursor: pointer;

						@include if-parent('.mm-price-row.mm-price-row--long') {
							padding-left: 40px;
						}
					}
				}

				> .mm-price-normal {
					display: block;
					width: 100%;
					text-align: right;
					vertical-align: bottom;
					white-space: nowrap;

					@include if-parent('.mm-price-row.mm-price-row--long') {
						display: block;
						text-align: left;
					}

					@include if-parent('.m-basic-form-footer-sticky--subhead') {
						@include mq(tablet) {
							width: auto;
							text-align: left;
						}
					}

					> .mm-price {
						@include milo-bold($font-size-24, 24px);

						@include mq(tablet) {
							@include milo-bold($font-size-32, 23px);
						}

						@include if-parent('.mm-price-row.mm-price-row--long') {
							@include mq(tablet) {
								@include milo-bold($font-size-24, 24px);
							}
						}

						&.mm-animation-puls {
							display: inline-block;
							animation-name: pulse;
							animation-duration: 1.5s;
						}

						&.mm-animation-jitter {
							display: inline-block;
							animation-name: jitter;
							animation-duration: 0.3s;
							animation-iteration-count: 3;
						}

						&.mm-animation-color {
							display: inline-block;
							animation-name: color;
							animation-duration: 1s;
						}
					}
				}
			}

			> .mm-price-box-add {
				display: flex;
				justify-content: space-between;

				> .mm-header-label-add {
					&.has-content {
						padding-left: 40px;
					}
				}

				> .mm-header-add-label {
					display: table-cell;
					@include milo-regular($font-size-16, 16px);
					vertical-align: bottom;

					@include mq(tablet) {
						@include milo-regular($font-size-16, 16px);
					}
				}

				> .mm-price-add {
					display: table-cell;
					text-align: right;
					vertical-align: bottom;

					> .mm-price {
						@include milo-bold();
					}
				}
			}
		}

		> .mm-btnbox-layer {
			position: absolute;
			background: $color-primary-2;
			bottom: 65px;
			border-radius: 8px;
			display: none;
			padding: 50px 20px 20px;
			border: 1px solid $color-secondary-4;

			&.is-open {
				display: flex;
			}

			> .mm-layer-close {
				position: absolute;
				right: 5px;
				top: 5px;
				cursor: pointer;
				@include main-icon-close-grey;
			}

			> .mm-layer-btn-col {
				display: flex;
				flex-direction: column;

				> .mm-layer-btn {
					margin-bottom: 10px;
					margin-right: 15px;

					&:last-child {
						margin-bottom: 0;
					}
				}

				> .mm-layer-text {
					height: 36px;
					margin-bottom: 10px;
					display: flex;
					align-items: center;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		> .a-basic-btn {
			text-align: center;
			display: block;

			@include mq(tablet) {
				order: 3;
			}

			@include if-parent('.m-basic-form-footer-sticky--whole-footer') {
				@include mq(tablet) {
					padding: 10px 8px 7px 8px;
				}

				@include mq(desktop) {
					padding: 10px 15px 7px 15px;
				}
			}
		}

		> .mm-link-box {
			align-self: flex-end;
			display: flex;
			margin-top: 10px;

			@include mq(tablet) {
				align-self: initial;
				display: block;
				margin-top: 0;
				flex-shrink: 0;
				margin-left: 20px;
			}

			@include mq(desktop) {
				flex-shrink: 1;
				margin-left: 40px;
			}

			> .a-basic-link:first-child {
				margin-right: 3px;

				@include mq(tablet) {
					margin-right: 25px;
				}

				@include if-parent('.m-basic-form-footer-sticky--whole-footer') {
					@include mq(tablet) {
						margin-right: 15px;
					}

					@include mq(desktop) {
						margin-right: 25px;
					}
				}
			}
		}
	}

	> .mm-content {
		background: $color-secondary-8;
		display: block;
		overflow: auto;
		max-height: 50vh;

		&.is-close {
			height: 0;
			overflow: hidden;
		}

		> .mm-inner-content {
			max-width: 898px;
			max-height: calc(50vh + 180px);
			padding: 25px 15px;

			@include mq(tablet) {
				padding: 25px 30px;
				max-height: 50vh;
			}

			@include if-parent('.m-basic-form-footer-sticky:not(.m-basic-form-footer-sticky--multiple)') {
				display: flex;
				flex-flow: column nowrap;

				@include mq(tablet) {
					flex-flow: row nowrap;
				}
			}

			> .mm-inner-badge {
				grid-area: badge;
				flex: 1;
				order: 1;
				margin: 30px 0 0;
				max-height: 150px;
				max-width: 250px;

				@include mq(tablet) {
					flex: 0 1 250px;
					text-align: right;
					margin: 0;
				}

				img {
					max-width: 100%;
					height: auto;
				}

				& + .mm-inner-table {
					flex: 1 1 calc(100% - 250px);
					order: 0;
				}
			}

			> .mm-inner-table {
				max-width: 430px;

				@include mq(tablet) {
					max-width: 630px;
				}

				> .mm-result-row {
					border-top: 1px solid $color-primary-3;
					border-bottom: 1px solid $color-primary-3;
					padding: 10px 0;
					margin-bottom: 20px;
					display: flex;

					&.is-column {
						position: relative;
						flex-flow: column nowrap;
						margin-bottom: 0;
						// space for price val
						padding-right: 90px;
					}

					+ .mm-result-row {
						border-top: none;
					}

					> .mm-price-label {
						flex: 1 0 auto;
						padding-right: 10px;
						max-width: 250px;

						@include mq(tablet) {
							flex: 0 0 auto;
							padding-right: 15px;
						}
					}

					> .mm-total-price {
						flex: 0 0 auto;
						padding-right: 10px;
						@include milo-bold();

						@include mq(tablet) {
							flex: 1 0 auto;
							padding-right: 15px;
						}

						&.is-top-right {
							@include absolute(9px, $right: 0);
						}
					}

					> .mm-icon-link {
						flex: 0 0 auto;
						text-decoration: none;

						@include mq(tablet) {
							flex: 0 0 auto
						}

						@include mq(print) {
							display: none;
						}

						> .mm-icon-label {
							display: none;

							@include mq(tablet) {
								display: inline-block;
							}
						}
					}
				}
			}
		}
	}

	> .mm-footer {
		padding: 10px 0;
		justify-content: center;
		display: flex;
		border-top: 1px solid #fff;
	}
}

@keyframes pulse {
	0% {
		transform: scale(1)
	}

	50% {
		transform: scale(1.5)
	}

	100% {
		transform: scale(1)
	}
}

@keyframes jitter {
	0% {
		transform: rotate(0)
	}

	25% {
		transform: rotate(15deg)
	}

	75% {
		transform: rotate(-15deg)
	}

	100% {
		transform: scale(1)
	}
}

@keyframes color {
	0% {
		color: $color-primary-3
	}

	50% {
		color: $color-primary-2
	}

	100% {
		color: $color-primary-3
	}
}