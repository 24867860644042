/* m-basic-list--social */
.m-basic-list--center {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	display: inline-block;

	li {
		float: left;
		clear: left;
	}
}