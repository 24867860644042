// .m-basic-radius-search--small
.m-basic-radius-search--small {
	> .l-form {

		&:not(.h-space-s):not(.h-space-m):not(.h-space-l) {
			margin-bottom: 0;
		}

		> .l-main-cols {
			justify-content: flex-start;

			@include mq(tablet) {
				flex-flow: row nowrap;
			}

			> .ll-fieldset {
				padding-bottom: 0;

				&:first-of-type {
					flex: 1 0 50%;

					@include mq(desktop) {
						flex: 1 0 55%;
					}

					.ll-row {
						> .ll-col-label {
							padding-left: 0;
						}
					}
				}

				&:last-of-type {
					flex: 1 0 140px;
				}
			}
		}
	}
}