/* a-basic-input-checkbox--col-1-4 */
.a-basic-input-checkbox--col-1-4 {
	width: 100%;
	margin-right: 10px;

	@include mq(tablet) {
		clear: none;
		float: left;
		margin-right: $form-gutter;
		width: calc(100% / 4 - #{$form-gutter});
	}
}