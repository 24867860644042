/* o-basic-banderole--clusterbox */
.o-basic-banderole--clusterbox {
	width: 100%;

	> .swiper {
		padding: 0 15px;

		@include mq(tablet) {
			padding: 0;
		}

		> .swiper-wrapper {
			align-items: stretch;

			> .swiper-slide {
				max-width: 100%;
				width: 100% !important;
				height: auto;
			}
		}
	}
}