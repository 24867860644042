.m-vehicles-results {

	> .mm-models {

		> .mm-result-row {
			display: flex;
			flex-flow: row wrap;
			border-top: 1px solid $color-secondary-3;
			padding: 15px 10px 12px 10px;

			@include mq(tablet) {
				padding: 7px 10px 25px;
			}

			&:last-of-type {
				border-bottom: 1px solid $color-secondary-3;
				margin-bottom: 42px;

				@include mq(tablet) {
					margin-bottom: 82px;
				}
			}

			> .mm-image-col {
				display: flex;
				align-items: flex-start;
				width: 100%;
				position: relative;

				@include mq(tablet) {
					flex: 1 0 50%;
					align-items: flex-end;
					margin-bottom: 0;
					width: 50%;
				}

				> .mm-description {
					flex: 1 0 66%;
					margin-left: 25px;
				}

				> .mm-content {
					margin-left: 14px;
					margin-bottom: 24px;

					@include mq(tablet) {
						margin-bottom: 0;
					}

					> .mm-carname {
						margin-bottom: 15px;

						> h2,
						h3,
						h4 {
							margin-bottom: 0;
							padding-bottom: 0;
							font-size: 16px;
						}
					}
				}
			}

			> .mm-infolink-col {
				display: block;

				@include mq(tablet) {
					display: flex;
					flex: 1 0 50%;
					align-items: flex-end;
					justify-items: right;
					padding-left: 80px;
					padding-bottom: 2px;
				}

				@include mq(desktop) {
					padding-left: 120px;
				}
			}
		}
	}

}