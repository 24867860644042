/* m-basic-teaser-product */
.m-basic-teaser-product {
	padding-top: 20px;

	@include mq(desktop) {
		margin-left: -27px;
		margin-right: -27px;
	}

	> .mm-status {
		@include mq(desktop) {
			padding-left: 30px;
		}

		> .js-basickeywordfilter-results-false {

			> .mm-recommendations {
				padding: 0 0 15px 0;
				@include milo-regular($font-size-24, 29px);

				.h-roboto & {
					font-family: 'Roboto', sans-serif;
				}
			}
		}
	}

	> .mm-wrap {
		width: 100%;
		margin: 0 -15px 0 0;
		display: flex;
		flex-direction: row;
		justify-content: center;
		flex-wrap: wrap;

		@include mq(tablet) {
			justify-content: inherit;
			width: auto;
		}

		@include mq(desktop) {
			margin-left: 27px;
			margin-right: 0;
		}

		> .mm-box {

			width: 290px;
			margin: 0 3px 20px 3px;
			position: relative;

			@include mq(tablet) {
				margin: 0 25px 30px 0;
			}

			@include mq(desktop) {
				margin-right: 15px;
			}

			> .mm-img-box {

				> .mm-figure {
					width: 290px;
					height: 219px;
					margin: 0;
					background: $color-secondary-7 url(/assets/img/bg/preview.png) no-repeat center center;

					> a {
						display: block;

						> img {
							max-width: 100%;
							max-height: 100%;
						}
					}
				}
			}


			> .mm-main {
				background: $color-secondary-6;
				position: relative;


				// Exception stopper
				.a-basic-stopper {
					top: 10px;

					& + .mm-top {
						padding-right: 80px;
					}

				}

				> .mm-top {
					position: relative;
					border-bottom: 1px solid $color-secondary-3;
					padding: 7px 10px;
					height: 90px;

					> header {

						> h2 {
							padding: 0;
							@include font-size($font-size-15);
							@include line-height($font-size-18);
							@include milo-bold();

							.h-roboto & {
								font-family: 'Roboto Bold', sans-serif;
							}
						}
					}

					.mm-copayment {
						position: absolute;
						bottom: 5px;
						font-weight: bold;
					}

					> .mm-keyword {
						position: absolute;
						bottom: 0;
						visibility: hidden;
					}
				}

				> .mm-bottom {
					@extend %cf;
					padding: 6px 6px 6px 10px;
				}
			}
		}
	}
}