/* a-basic-btn--yellow */
.a-basic-btn--yellow {
	background-color: $color-primary-1;
	color: $color-primary-3;
	max-width: 100%;

	@include mq(tablet) {
		max-width: none;
		white-space: nowrap;
	}

	&:hover {
		background-color: $color-action-btn;
		color: $color-primary-3;
	}

	&:focus {
		background-color: $color-primary-1;
		color: $color-primary-3;
	}
}

/* a-basic-btn--yellow-centered */
.a-basic-btn--yellow-centered {
	background-color: $color-primary-1;
	color: $color-primary-3;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;

	@include mq(tablet) {
		max-width: none;
		white-space: nowrap;
	}

	&:hover {
		background-color: $color-action-btn;
		color: $color-primary-3;
	}

	&:focus {
		background-color: $color-primary-1;
		color: $color-primary-3;
	}
}

// basic buttons from RTE (Richtext)
.l-outer--my-adac .m-basic-text.mm-content .mm-content .a-basic-btn {
	@extend .a-basic-btn--yellow;
}