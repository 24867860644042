/* m-basic-breadcrumb */
.m-basic-breadcrumb {
	position: relative;
	display: block;
	min-height: 40px;
	margin: 0 auto;
	background: $color-primary-2;
	opacity: 1;
	box-shadow:
		0 2px 4px -1px rgba(0, 0, 0, 0.2),
		0 1px 10px 0 rgba(0, 0, 0, 0.12),
		0 4px 5px 0 rgba(0, 0, 0, 0.14);
	z-index: 500;
	transform: translate(0, 0);

	@include mq(tablet) {
		min-height: 48px;
	}

	@include mq(desktop) {
		width: auto;
		max-width: none;
		float: none;
	}

	@include mq(desktop-l) {
		@include absolute(152px, 0);
		min-height: 52px;
		width: 100%;
		z-index: 100;
	}

	@include mq(print) {
		display: none;
	}

	// Exception: if is set basic-stage, hide breadcrumb
	.o-layout-main-stage ~ .ll-main-center & {
		display: none;
	}

	// Exception l-outer-nav
	.l-outer--nav & {
		width: 100%;
		max-width: none;
		clear: both;
	}

	// Exception .m-basic-header.is-stuck
	.m-basic-header.is-stuck + & {

		@include mq(desktop-l) {
			margin-top: 0;
		}
	}

	// Exception .m-basic-header.is-stuck.is-dropshadow
	.m-basic-header.is-stuck.is-dropshadow + & {

		opacity: 0;

		@include mq(desktop-l) {
			margin-top: 0;
		}
	}

	// Exception .m-basic-header while in sticky animation
	.m-basic-header + &.is-header-shifted {
		@include mq(desktop-l) {
			// -1 * (header-height)
			transform: translate(0, -152px);
		}
	}

	// Exception .m-basic-header while in sticky animation
	.m-basic-header + &.is-header-anim {
		transform: translate(0, 0);
		z-index: 980;
		transition: transform .5s ease-in-out;
	}

	// Exception .m-basic-header--reduced
	.m-basic-header--reduced + & {
		@include mq(desktop-l) {
			top: 64px;
		}
	}

	> .swiper {
		z-index: 35;
		position: absolute;
		left: 0;
		right: 0;
		padding: 5px 0;

		@include mq(tablet) {
			cursor: default;
			padding: 9px 0;
		}

		@include mq(desktop) {
			padding: 13px 0;
		}

		@include mq(desktop-l) {
			padding: 15px 0;
		}

		// Exception m-basic-header
		.m-basic-header + & {
			width: 100% !important;
			max-width: 1440px;
		}

		> .mm-baselevel {
			padding: 0;
			max-width: none;
			background: transparent;
			border-bottom: none;
			margin: 0;

			// Exception m-basic-header
			.m-basic-header + & {
				margin: 0 16px;

				@include mq(tablet) {
					margin: 0 32px;
				}
			}

			> li {
				display: inline-block;
				position: relative;
				background: transparent;
				white-space: nowrap;
				width: auto;
				padding-top: 4px;

				@include mq(desktop) {
					padding: 0;
					background: transparent;
				}

				&.is-opened {
					display: block;
				}

				&:before {
					content: "";
					display: inline-block;
					@include absolute();
					@include line-height(19px);
					@include main-icon-breadcrumb-right;
					margin-left: -9px;
					top: -6px;
					left: -1px;

					@include mq(desktop) {
						top: -10px;
					}

					// Exception m-basic-header
					.m-basic-header + & {
						transform: scale(.85);
					}
				}

				&:last-child {
					margin-right: 0;

					// Exception m-basic-header
					.m-basic-header + & {
						margin-right: 16px !important;

						@include mq(tablet) {
							margin-right: 32px !important;
						}
					}

					> a {
						color: $color-secondary-17;

						// Exception m-basic-header
						.m-basic-header + & {
							color: rgba(0, 0, 0, 0.9);
						}
					}
				}

				&.mm-home {
					display: block;
					margin-right: 15px;
					position: relative;

					> a {
						display: inline-block;
						position: relative;
						overflow: hidden;
						text-indent: -10000px;
						padding: 0;
						width: 24px;
						height: 24px;

						&:before {
							content: "";
							position: absolute;
							@include main-icon-home-light-grey;
							top: -7px;
							left: -10px;

							@include mq(desktop) {
								top: -10px;
							}
						}
					}

					&:before {
						background: none;
					}

					// Exception m-basic-header
					.m-basic-header + & {
						margin-top: -4px;

						@include mq(desktop) {
							margin-top: -2px;
						}
					}
				}

				&.js-dropdown {
					position: relative;
					top: -2px;
					margin-left: 10px;
					order: 0;
					border: 2px solid $color-primary-1;
					border-radius: 4px;
					padding-top: 0;
					cursor: pointer;

					&:before {
						display: none;
					}

					// Exception m-basic-header
					.m-basic-header + & {
						margin-right: 32px !important;

						@include mq(desktop) {
							margin-right: 48px !important;
						}
					}

					> a {
						padding: 3px 39px 3px 15px;
						position: relative;
						z-index: 2;

						&:before {
							@include main-icon-expand-more;
							position: absolute;
							content: "";
							display: inline-block;
							top: -7px;
							right: -6px;
							z-index: 2;

							@include mq(desktop) {
								@include main-icon-expand-more;
								top: -9px;
								right: -10px;
							}
						}

						&:after {
							content: '';
							display: inline-block;
							width: 33px;
							height: 30px;
							background-color: $color-primary-1;
							position: absolute;
							right: -1px;
							top: 0;
							border: solid 1px $color-primary-1;
							border-radius: 1px;
							z-index: -4;

							@include mq(desktop) {
								width: 25px;
								height: 25px;
							}
						}

						@include mq('desktop') {
							padding: 1px 39px 1px 15px;
						}

						// Exception m-basic-header
						.m-basic-header + & {
							&:before {
								top: -8px;
								right: -7px;

								@include mq(desktop) {
									top: -10px;
									right: -11px;
								}
							}
						}
					}

					&.is-opened {

						a {
							padding-bottom: 1px;

							&:before {
								@include main-icon-expand-less;
								position: absolute;
								content: "";
								display: inline-block;
								top: -8px;
								right: -6px;

								@include mq(desktop) {
									top: -8px;
									right: -10px;
								}
							}

							&:after {
								content: '';
								display: inline-block;
								width: 33px;
								height: 28px;
								background-color: $color-primary-1;
								position: absolute;
								right: 0;
								top: 0;
								border: solid 1px $color-primary-1;
								border-radius: 1px;
								z-index: -4;

								@include mq(desktop) {
									width: 25px;
									height: 25px;
								}
							}

							// Exception m-basic-header
							.m-basic-header + & {
								padding-bottom: 3px;

								&:before {
									top: -10px;
									right: -10px;
								}
							}
						}
					}

					> .mm-firstlevel {
						margin: 0 0 -2px 0;
						padding: 0;
						z-index: 35;
						display: none;
						border: 2px solid $color-primary-1;

						width: auto;
						visibility: hidden;
						height: 1px;
						overflow: hidden;

						&.is-opened {
							visibility: visible;
							height: auto;
							display: block;
							overflow: visible;
						}

						li {
							width: 100%;

							&:after {
								left: 5px;
							}

							&:first-child {
								> a {
									border-top: 0;
								}
							}

							a {
								@include milo-regular($font-size-14, 20px);
								text-decoration: none;
								display: block;
								background: $color-primary-2;
								padding: 10px 15px 10px 15px;
								border-top: 1px solid $color-secondary-9;
								margin: 0 -2px 0;
								z-index: 1;

								.h-roboto & {
									font-family: 'Roboto', sans-serif;
								}

								&:focus {
									outline: none;
								}

								&:hover,
								&:focus {
									background: $color-secondary-8;
								}

								&.is-active {
									@include milo-bold();

									.h-roboto & {
										font-family: 'Roboto Bold', sans-serif;
									}
								}
							}

							> .mm-firstlevel {
								margin: 0;
								padding: 0;
								display: none;

								&.is-opened {
									display: block;
								}

								li {

									a {
										background: $color-primary-2;
										padding: 10px 15px 10px 40px;
										@include font-size($font-size-14, 20px);
										z-index: 1;

										&:hover,
										&:focus {
											background: $color-secondary-8;
										}
									}
								}
							}
						}
					}
				}

				> a {
					text-decoration: none;
					@include milo-regular($font-size-16, 23px);
					padding: 1px 19px 1px 39px;
					display: block;
					color: rgba(0, 0, 0, 0.6);
					overflow: hidden; // i m not sure why this was on the li - but moved it to here for focus outline

					@include mq(tablet) {
						padding: 1px 15px 1px 39px;
					}

					.h-roboto & {
						font-family: 'Roboto', sans-serif;
					}

					// Exception m-basic-header
					.m-basic-header + & {
						@include line-height(20px);
					}
				}
			}
		}

		> .swiper-button-prev {
			background: none;
			width: 17.81%;
			max-height: 33px;
			overflow: hidden;
			top: 20px;
			left: 0;

			@include mq(desktop) {
				top: 15px;
			}

			&:before {
				top: 0;
				height: 100%;
				background-image: none;
			}

			&:after {
				border: none;
				border-radius: 0;
				width: 100%;
				background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
				left: 0;
				margin-left: 0;
				top: 0;
			}
		}

		> .swiper-button-next {
			background: none;
			width: 17.81%;
			max-height: 33px;
			overflow: hidden;
			right: 0;

			&:before {
				top: 0;
				height: 100%;
				background-image: none;
			}

			&:after {
				border: none;
				border-radius: 0;
				width: 100%;
				right: 0;
				left: auto;
				margin-left: 0;
				top: 0;
				background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
			}
		}
	}
}