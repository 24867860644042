/* o-layout-main-stage */
.o-layout-main-stage {
	position: relative;
	overflow: hidden;
	z-index: 10;
	max-width: 1280px;
	margin: 0 auto;

	%box-col-2-head {
		display: block;
		padding: 0 0 10px 0;
	}

	%box-col-3-head {
		display: block;
		padding: 0 0 10px 0;
	}

	@include mq(tablet) {
		background: $color-secondary-5;
	}

	@include mq(desktop-xl) {

		// sitecore label help
		.is-page-editor &:before {
			content: "Bühne";
			position: absolute;
			top: calc(50% - 20px);
			left: calc(50% - 60px);
			color: $color-secondary-2;
			@include milo-slab-bold($font-size-50, 29px);

			.h-roboto & {
				font-family: 'Roboto Bold', sans-serif;
			}
		}
	}

	&--white {
		@include mq(tablet) {
			background: $color-primary-2;
		}
	}

	.oo-figure {
		overflow: hidden;
		min-height: 155px;
		max-height: 420px;
		margin: 0;

		@include mq(tablet) {
			min-height: 330px;
		}

		.oo-img {
			position: relative;
			left: -48%;
			width: 148%;
			height: auto;
			display: block;

			@include mq(tablet) {
				left: -33%
			}

			@include mq(desktop) {
				position: static;
				width: 100%;
			}

			&--col-3 {
				left: -15%;

				@include mq(tablet) {
					left: -36%;
					width: 170%;
				}

				@include mq(desktop) {
					position: relative;
					left: -16%;
					width: 128%;
				}

				@include mq(desktop-l) {
					left: 0;
					width: 110%;
				}

				@include mq(desktop-xl) {
					width: 100%;
				}
			}

			&.is-fixed-height {

				@include mq(tablet) {
					position: relative;
				}

				@include mq(desktop) {
					width: auto;
					height: 100%;
				}
			}
		}

		&--fade-overlay,
		&--overlay {
			@include mq(tablet) {
				position: relative;
				z-index: -1;
			}
		}

		&--overlay {

			@include mq(tablet) {
				min-height: 420px;
			}

			.oo-img {
				@include mq(tablet) {
					left: 50%;
					transform: translate(-50%, 0);
					width: auto;
					height: 100%;
				}
			}
		}
	}

	.oo-slide-content-wrapper {
		position: static;
		display: block;
		padding: 10px 20px 0 20px;
		background: $color-primary-2;
		height: auto;

		@include mq(tablet) {
			height: 100%;
			padding: 20px 30px 0 30px;
			max-width: 898px;
			margin: 0 auto;
		}

		@include mq(desktop) {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, 0);
			width: 640px;
			padding: 20px 30px 30px;
			background: none;

			@include if-parent('.o-layout-main-stage.o-layout-main-stage--wide-center') {
				transform: translate(-55%, 0);
			}
		}

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			display: none;
			height: 100%;
			width: 50vw;
			transform: translate(0, 0);
			background: $color-primary-2;
			z-index: -1;

			@include mq(tablet) {
				display: inline-block;
			}

			@include mq(desktop) {
				display: none;
			}
		}

		&:after {
			left: auto;
			right: 0;
		}

		> .oo-slide-head {
			display: inline-block;
			width: 100%;
			font-weight: lighter;

			@include mq(tablet) {
				position: absolute;
				top: 30px;
				right: 30px;
				min-width: 470px;
				width: auto;
				max-width: 75%;
				margin-bottom: 0;
			}

			@include mq(desktop) {
				position: static;
				padding: 0 20px;
				min-width: 800px;
				max-width: 800px;
			}

			&.is-white {
				color: $color-primary-3;

				@include mq('tablet') {
					color: $color-primary-2;
				}
			}
		}

		> .oo-box {

			position: static;
			padding: 0;
			margin: 0 0 30px 0;
			width: 100%;
			max-width: none;

			&--advantage-list {
				@include mq(desktop) {
					position: absolute;
					left: auto;
					bottom: 30px;
					width: 580px;
					padding: 25px 30px;
					margin: 0;
				}
			}

			&--advantage-list-rate-stage {
				@include mq(desktop) {
					position: absolute;
					left: auto;
					bottom: 30px;
					width: 640px;
					padding: 25px 30px;
					margin: 0;
					border-radius: 8px;
				}

				@include mq(desktop-l) {
					bottom: 60px;
				}
			}

			&--advantage-tourset-stage {

				@include mq(desktop) {
					position: absolute;
					left: auto;
					bottom: 20px;
					width: 640px;
					padding: 20px 30px;
					margin: 0;
					border-radius: 8px;
				}

				@include mq(desktop-l) {
					bottom: 50px;
					padding: 25px 30px;
				}
			}

			&--blank {
				@include mq(desktop) {
					position: absolute;
					left: auto;
					bottom: 30px;
					width: 580px;
					padding: 25px 30px;
					margin: 0;
				}
			}

			> .oo-box-flex {
				display: flex;
				flex-direction: column;

				@include mq('tablet') {
					flex-direction: row;
					align-items: center;
				}

				> .oo-flex-item {
					margin-bottom: 15px;

					@include mq('tablet') {
						margin-bottom: 0;
						margin-right: 15px;
					}

					&:last-child {
						margin-bottom: 0;

						@include mq('tablet') {
							margin-right: 0;
						}
					}
				}
			}
		}

		// small if badge is big
		&--small {

			@include mq(desktop) {
				left: 42%;
			}

			@include mq(desktop-l) {
				left: 50%;
			}
		}

		&--fade-overlay {

			// bg gradients
			$fading-gradient-mobile: linear-gradient(rgba($color-primary-2, 0),
					rgba($color-primary-2, 1) 70%);

			$fading-gradient-tablet: linear-gradient(rgba($color-primary-2, 0),
					rgba($color-primary-2, 1) 50%);

			margin-top: -100px;
			max-width: none;
			background: none;

			&:before,
			&:after {
				display: none;
			}

			@include mq(tablet) {
				background: $fading-gradient-tablet;
			}

			@include mq(desktop) {
				height: auto;
				max-width: 480px;
				margin-top: 0;
				padding-top: 30px;
				background: none;
			}

			> .oo-slide-head {
				position: relative;
				background: $fading-gradient-mobile;
				padding-top: 50px;

				&:before,
				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: -100%;
					display: block;
					height: 100%;
					width: 100%;
					transform: translate(0, 0);
					background: $fading-gradient-mobile;
					z-index: 0;

					@include mq(tablet) {
						display: none;
					}
				}

				&:after {
					left: auto;
					right: -100%;
				}

				@include mq(tablet) {
					position: absolute;
					top: 30px;
					left: auto;
					right: 30px;
					padding-top: 0;
					background: none;
				}

				@include mq(desktop) {
					position: static;
					padding: 0 20px;
					min-width: 800px;
					max-width: 800px;
				}
			}

			> .oo-box {

				@include mq(tablet) {
					padding-top: 35px;
					z-index: 1;
					background: none;
				}

				@include mq(desktop) {
					position: relative;
					left: auto;
					bottom: -10px;
					margin-bottom: 0;
					padding: 25px 20px 30px 25px;
					max-width: 480px;
					background: rgba(255, 255, 255, 0.8);
				}

				&--blank {

					background: none;

					@include mq(desktop) {
						top: auto;
						right: auto;
						bottom: auto;
						width: auto;
						padding-left: 20px;
						padding-right: 20px;
					}
				}
			}
		}

		// larger slide content wrapper
		&--big {
			width: auto;

			@include mq(desktop) {
				max-width: 860px;
				width: 860px;
			}

			> .oo-slide-head {

				@include mq(desktop) {
					padding: 0 0 17px 0;
				}
			}

			> .oo-box {
				@include mq(tablet) {
					padding-top: 35px;
					z-index: 1;
					background: none;
				}

				@include mq(desktop) {
					position: relative;
					left: auto;
					bottom: -10px;
					margin-bottom: 0;
					padding: 35px 40px;
					max-width: 800px;
					background: transparentize($color-primary-2, 0.2);
				}

				&--blank {
					background: none;

					@include mq(desktop) {
						top: auto;
						right: auto;
						bottom: auto;
						width: auto;
						padding-left: 20px;
						padding-right: 20px;
					}
				}
			}
		}

		&--overlay {
			border-radius: 8px;

			@include mq(tablet) {
				max-width: none;
				width: calc(100% - 20px);
				margin-top: -80px;
				padding-top: 34px;
			}

			@include mq(desktop) {
				display: flex;
				flex-flow: column nowrap;
				left: 30px;
				right: auto;
				min-width: 0;
				width: auto;
				max-width: 520px;
				height: auto;
				max-height: 295px;
				margin-top: 0;
				padding-top: 40px;
				background: none;
				transform: none;
			}

			&:before,
			&:after {

				@include mq(tablet) {
					display: none;
				}
			}

			> .oo-slide-head {
				@include mq(tablet) {
					left: 30px;
					right: auto;
					max-width: 480px;
				}

				@include mq(desktop) {
					position: static;
					padding: 0 20px;
					min-width: 800px;
					max-width: 800px;
				}
			}

			> .oo-box {

				@include mq(desktop) {
					position: relative;
					left: auto;
					bottom: -10px;
					margin-bottom: 0;
					padding: 25px 20px 30px 25px;
					max-width: 480px;
				}

				&--blank {
					@include mq(desktop) {
						position: relative;
						top: auto;
						left: auto;
						right: auto;
						bottom: auto;
						width: auto;
						padding-left: 20px;
						padding-top: 15px;
					}
				}
			}
		}

		&--overlay ~ .h-h2,
		&--fade-overlay ~ .h-h2 {

			text-align: left;
			padding-left: 20px;
			padding-right: 20px;
			display: inline-block;

			@include mq(tablet) {
				padding-left: 30px;
				padding-right: 30px;
			}

			@include mq(desktop) {
				text-align: center;
				position: relative;
				width: 100%;
				top: -45px;
				z-index: 1;
			}
		}

		&--overlay ~ .h-h2 {
			@include mq(desktop) {
				display: block;
				top: -50px;
				max-width: 960px;
				margin: 0 auto;
				text-align: left;
			}
		}
	}

	.is-init .oo-img {
		visibility: hidden;
	}

	.oo-box {
		position: relative;
		height: auto;
		z-index: 15;
		margin: -30px 10px 0 10px;
		padding: 15px;
		background: $color-primary-2;

		@include mq(tablet) {
			position: absolute;
			bottom: 60px;
			left: 50px;
			width: 465px;
			margin: 0;
			padding-top: 25px;
			padding-right: 100px;
			padding-left: 30px;
			background: rgba(255, 255, 255, 0.8);
			border-radius: 8px;
		}

		@include mq(desktop) {
			width: 535px;
			left: 205px;
			padding-right: 170px;
		}

		// exception if basic-stopper is prev sibling
		.a-basic-stopper ~ header .oo-box-overhead {
			padding-right: 70px;
		}

		// exception if basic-stopper is prev sibling
		.a-basic-stopper ~ header .oo-box-head {
			padding-right: 70px;
		}

		// exception if basic-stopper is prev sibling
		.a-basic-stopper ~ .oo-box-text {
			padding-right: 70px;
		}

		.oo-box-headline {
			display: none;

			@include mq(tablet) {
				display: block;
				position: absolute;
				right: 0;
				bottom: calc(100% + 8px);
				@include milo-slab-bold($font-size-36, 47px);
			}

			@include if-parent('.oo-box.oo-box--advantage-list-rate-stage') {
				display: block;
				position: static;
				@include milo-slab-bold($font-size-22, 29px);
				max-width: 250px;

				@include mq(tablet) {
					@include font-size($font-size-36, 43px);
					position: static;
					width: 658px;
					max-width: 660px;
				}

				@include mq(desktop) {
					@include font-size($font-size-40, 48px);
					bottom: calc(100% + 25px);
					position: absolute;
					right: auto;
					left: 0;
				}

				@include mq(desktop-l) {
					bottom: calc(100% + 40px);
				}
			}

			@include if-parent('.oo-box.oo-box--advantage-tourset-stage') {
				display: block;
				position: static;
				@include milo-slab-bold($font-size-22, 29px);
				max-width: 250px;

				@include mq(tablet) {
					@include font-size($font-size-36, 43px);
					position: static;
					width: 658px;
					max-width: 660px;
				}

				@include mq(desktop) {
					@include font-size($font-size-40, 48px);
					bottom: calc(100% + 15px);
					position: absolute;
					right: auto;
					left: 25px;
				}
			}

			> .oo-box-headline-registered-sign {
				@include font-size($font-size-16, 20px);

				@include mq(desktop) {
					@include font-size($font-size-24, 43px);
				}
			}
		}

		> header {
			padding: 10px 0 0 0;
			min-height: 60px;

			@include mq(tablet) {
				min-height: 0;
				padding: 0;
			}
		}

		&--blank {
			background: none;
		}

		&--col-2 {
			height: auto;
			bottom: 36px;

			@include mq(tablet) {
				left: 30px;
				width: calc(100% - 60px);
				height: 342px;
				padding-left: 0;
				padding-right: 0;
				justify-content: space-between;
			}

			@include mq(desktop) {
				left: 50%;
				max-width: 828px;
				margin-left: -414px;
			}

			.oo-box-col-2-headline {
				display: none;

				@include mq(tablet) {
					display: block;
					position: absolute;
					right: 0;
					bottom: calc(100% + 8px);
					@include milo-slab-bold($font-size-36, 47px);
					color: $color-primary-2;
				}

				&--black {
					color: $color-primary-3;
				}
			}

			.oo-box-col-2--child {
				margin: 0 0 10px 0;
				padding: 15px 20px;
				flex: 1 1 auto;
				flex-basis: 66%;
				border-bottom: 1px solid $color-secondary-5;

				@include mq(tablet) {
					margin: 0;
					padding: 0 20px;
					border-bottom: 0;
				}

				&:last-child {
					margin-bottom: 0;
					padding-bottom: 0;
					border: 0;
					flex-basis: 262px;
				}


				.oo-box-col-2-head {
					@extend %box-col-2-head;
				}

				.oo-box-col-2-text {
					@include mq(tablet) {
						height: 78px;
						overflow: hidden;
					}

					.oo-box-col-2-head {
						@extend %box-col-2-head;
					}

					.oo-box-col-2-subhead {
						display: block;
						padding: 0 0 5px 0;
					}
				}
			}
		}

		&--col-3 {
			height: auto;

			@include mq(tablet) {
				display: flex;
				left: 30px;
				width: calc(100% - 60px);
				height: 200px;
				padding-left: 0;
				padding-right: 0;
			}

			@include mq(desktop) {
				left: 50%;
				max-width: 828px;
				margin-left: -414px;
			}

			.oo-box-col-3-headline {
				display: none;

				@include mq(tablet) {
					display: block;
					position: absolute;
					right: 20px;
					bottom: calc(100% + 69px);
					@include milo-slab-bold($font-size-50, 47px);
					color: $color-primary-2;
				}
			}

			.oo-box-col-3 {
				margin: 0 0 10px 0;
				padding: 0 0 15px 0;
				border-bottom: 1px solid $color-secondary-5;

				&:last-child {
					margin-bottom: 0;
					padding-bottom: 0;
					border: 0;
				}

				@include mq(tablet) {
					width: calc(100% / 3);
					margin: 0;
					padding: 0 20px;
					border-right: 1px solid $color-secondary-5;
					border-bottom: 0;
				}

				.oo-box-col-3-head {
					@extend %box-col-3-head;
				}

				.oo-box-col-3-text {
					@include mq(tablet) {
						height: 78px;
						overflow: hidden;
					}

					&--full {
						@include mq(tablet) {
							height: 140px;
						}
					}

					.oo-box-col-3-head {
						@extend %box-col-3-head;
					}

					.oo-box-col-3-subhead {
						display: block;
						padding: 0 0 5px 0;
					}
				}
			}
		}

		&--teaser-center {
			margin: 0 auto;
			padding: 15px 20px;
			min-height: 255px;
			height: auto;

			@include mq(tablet) {
				padding: 40px;
				width: 700px;
				bottom: 45px;
				left: 0;
				right: 0;
			}

			@include mq(desktop) {
				width: 767px;
			}

			> header {
				min-height: auto;

				@include mq(tablet) {
					padding-right: 60px;
				}

				> .oo-box-head {
					@include milo-slab-bold($font-size-23);
					max-width: 55%;
					margin-bottom: 30px;

					@include mq(tablet) {
						@include font-size(40px);
						max-width: 100%;
						margin-bottom: 15px;
					}
				}
			}

			.oo-box-subhead {
				display: inline-block;
				margin-bottom: 10px;
			}

			// exception if basic-stopper is prev sibling to header
			.a-basic-stopper + header {

				@include mq(tablet) {
					padding-top: 18px;
				}
			}

			> .oo-box-text {
				height: auto;
				overflow: visible;
				max-width: 480px;

				@include mq(tablet) {
					max-width: 530px;
				}
			}

			> .a-basic-btn {
				left: 20px;
				right: auto;
				bottom: 20px;
				max-width: 150px;

				@include mq(tablet) {
					left: auto;
					bottom: 25px;
					right: 38px;
					max-width: 100%;
				}
			}

			> .oo-button-box {
				display: flex;
				flex-flow: column nowrap;
				align-items: flex-end;
				padding: 20px 0 40px 0;
				border-bottom: 1px solid $color-secondary-9;

				@include mq(tablet) {
					flex-flow: row wrap;
					align-items: center;
					justify-content: flex-end;
					border-bottom: 0;
					padding-bottom: 10px;
				}

				&.has-border-top {
					@include mq(tablet) {
						border-top: 1px solid $color-secondary-9;
					}
				}

				&.is-left-aligned-mobile {
					align-items: flex-start;

					@include mq(tablet) {
						align-items: center;
					}
				}

				> a {

					margin-bottom: 10px;

					@include mq(tablet) {
						margin: 0 20px;
					}

					&:first-of-type {
						@include mq(tablet) {
							margin-left: 10px;
						}
					}

					&:last-of-type {
						margin-bottom: 0;

						@include mq(tablet) {
							margin-right: 0;
						}
					}

					> img {
						display: block;
						max-width: 100%;
						max-height: 40px;
					}
				}
			}
		}

		.oo-box-overhead {
			display: block;
			max-width: 400px;
			margin: 0 0 1px 0;

			@include mq(tablet) {
				margin-bottom: 2px;
				font-weight: bold;
			}
		}

		.oo-box-head {
			display: block;
			max-width: 400px;
			padding: 0;

			@include mq(tablet) {
				margin-bottom: 15px;
			}

			@at-root #{selector-unify(&, span)} {

				margin: 0 0 4px 0;
				@include milo-slab-bold($font-size-21, 24px);

				@include mq(tablet) {
					margin-bottom: 15px;
				}
			}

			@at-root #{selector-unify(&, h1)} {
				margin: 0 0 12px 0;
				line-height: 1em;
			}
		}

		// Exception if span is prev sibling
		span.oo-box-overhead + span.oo-box-head {
			@include mq(tablet) {
				margin-top: 8px;
			}
		}

		.oo-box-text {
			display: block;
			max-width: 400px;
			margin-bottom: 0;

			@include mq(tablet) {
				overflow: hidden;
				height: auto;
				max-height: 45px;
			}
		}

		.oo-row-wrapper {
			@include mq(tablet) {
				display: flex;
				align-items: center;
			}

			> .a-basic-btn {
				margin-top: 20px;

				@include if-parent('.oo-box--advantage-list') {
					margin-top: 20px;
				}

				@include if-parent('.oo-box--blank') {
					margin-top: 20px;
				}
			}

			> * {
				&:first-child {
					margin-bottom: 20px;

					@include mq(tablet) {
						margin-bottom: 0;
					}
				}

				&:not(:last-child) {
					@include mq(tablet) {
						margin-right: 15px;
					}
				}
			}
		}

		// only for two content
		.oo-flex-box {
			display: flex;
			flex-flow: column nowrap;
			width: 100%;

			@include mq(tablet) {
				flex-flow: row nowrap;
				align-items: center;
			}

			> .oo-flex-box-content {

				&:first-child {
					flex: 1 1 100%;

					@include mq(tablet) {
						flex: 0 0 50%;
					}
				}

				&:last-child {
					flex: 1 1 100%;

					@include mq(tablet) {
						flex: 0 0 50%;
					}
				}
			}
		}
	}

	.oo-badge {

		padding-left: 20px;
		margin-bottom: 30px;

		@include mq(tablet) {
			position: absolute;
			right: 35px;
			bottom: calc(50% + 60px);
			padding: 0;
			margin-bottom: 0;
			transform: translate(0, 50%);
		}

		@include mq(desktop) {
			bottom: 30px;
			right: auto;
			left: calc(50% + 390px);
			transform: translate(-50%, 0);
		}

		&--big {
			@include mq(desktop) {
				left: calc(44% + 390px);
				transform: translate(-44%, 0);
			}

			@include mq(desktop-l) {
				left: calc(50% + 430px);
				transform: translate(-50%, 0);
			}
		}

		> img {
			vertical-align: top;
		}
	}

	> .swiper {
		position: relative;
	}

	.oo-slide-content-wrapper--fade-overlay + .oo-badge {
		@include mq(tablet) {
			top: -10px;
			left: auto;
			right: 30px;
			bottom: auto;
			transform: translate(0, 90%);
		}

		@include mq(desktop) {
			top: 45px;
			transform: translate(0, 40%);
		}

		&--big {
			@include mq(tablet) {
				top: 35px;
			}

			@include mq(desktop) {
				top: 100px;
				right: 50px;
				transform: translate(0, 40%);
			}

			@include mq(desktop-l) {
				right: calc(50% - 450px);
				transform: translate(50%, 40%);
			}
		}
	}

	.oo-slide-content-wrapper--overlay + .oo-badge {
		@include mq(tablet) {
			top: 0;
			left: 30px;
			right: auto;
			bottom: auto;
			transform: translate(0, 90%);
		}

		@include mq(desktop) {
			top: 110px;
			left: auto;
			right: 100px;
			transform: none;
		}

		&--big {
			@include mq(desktop) {
				left: auto;
				right: 100px;
			}
		}
	}

	// Exception if child of .l-main-content
	.l-main-content > & {
		top: -40px;
		left: -20px;
		width: 100vw;
		margin-bottom: 10px;

		@include mq(tablet) {
			top: -60px;
			left: 50%;
			transform: translate(-50%, 0);
			margin-bottom: -10px;
		}

		// Exception if child of .ll-main-center and no m-basic-breadcrumb and not reduced
		.m-basic-header:not(.m-basic-header--reduced):not(.has-breadcrumb) ~ .l-main > .ll-main-center > & {

			@include mq(desktop-l) {
				top: -64px;
			}
		}
	}

	// Exception m-basic-header
	.m-basic-header ~ .l-main > & {
		margin-top: -40px;

		@include mq(tablet) {
			margin-top: -42px;
			margin-bottom: 50px;
		}

		@include mq(desktop) {
			margin-top: -64px;
		}
	}

	// Exception m-basic-header if m-basic-breadcrumb is present
	.m-basic-header + .m-basic-breadcrumb ~ .l-main > & {
		margin-top: -40px;

		@include mq(desktop) {
			margin-top: -60px;
		}
	}

	// Exception m-basic-breadcrumb
	.m-basic-header--reduced.has-breadcrumb ~ .l-main > .ll-main-center > .l-main-content > & {
		top: -56px;

		@include mq(tablet) {
			top: -84px;
		}

		@include mq(desktop-l) {
			top: 36px;
			margin-bottom: 70px;
		}
	}

	// Exception m-basic-breadcrumb
	.m-basic-header--reduced ~ .l-main > .ll-main-center > .l-main-content > & {

		@include mq(desktop-l) {
			top: -64px;

		}
	}
}