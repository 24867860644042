//.m-fdl-calculator--clusterbox
.m-fdl-calculator--clusterbox {

	.mm-calc {
		padding-bottom: 90px;

		@include mq(tablet) {
			padding-bottom: 30px;
		}

		&.mm-stopper {
			max-width: 80%;
		}

		> strong {
			margin: 0;
			padding: 0;
			background: 0;

			@include mq(print) {
				box-shadow: none;
				@include font-size(17px);
			}
		}
	}

	.mm-result {
		height: 55px;
		padding: 4px 8px;
		margin: 0 0 5px 0;
		@include absolute($bottom: 55px, $left: 15px);
		background: $color-primary-2;
		width: 150px;

		@include mq(tablet) {
			@include absolute($bottom: 10px, $left: 25px);
		}

		> p {
			margin-bottom: 0;
			@include milo-bold();

			.h-roboto & {
				font-family: 'Roboto Bold', sans-serif;
			}
		}

		.mm-amount,
		.mm-unit {
			@include font-size($font-size-24);
			line-height: 1.125;
			@include milo-bold();
			margin-bottom: 0;

			.h-roboto & {
				font-family: 'Roboto Bold', sans-serif;
			}
		}

	}
}