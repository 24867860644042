/* m-adac-maps--tableaddress */
.m-adac-maps--tableaddress {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@include mq(desktop-l) {
		flex-direction: row;
	}

	> .mm-table-address {
		width: 100%;
		overflow: hidden;
		margin-bottom: 20px;

		@include mq(desktop-l) {
			box-sizing: border-box;
			max-width: 430px;
			width: calc(100% - 460px);
			margin-right: 40px;
			margin-bottom: 40px;
		}

		@include mq(desktop-l) {
			max-height: 350px;
		}

		> .js-basic-table-wrap {
			padding-top: 0;
			margin-top: 0;
			height: 100%;

			> table {
				height: calc(100% - 2px);

				tr:last-child {
					height: 100%;
				}

				.a-basic-link {
					top: 0;
				}
			}

			> .swiper-container-wrap {
				.a-basic-link {
					top: 0;
				}
			}
		}
	}

	> .mm-map-wrapper {
		padding-bottom: 320px;
		margin-bottom: 40px;

		@include mq(desktop-l) {
			max-width: 430px;
			width: 420px;
		}

		&:only-child {
			@include mq(desktop-l) {
				width: 100%;
				max-width: 100%;
			}
		}
	}
}