.basic-dropdown-layer--wide {

	> .mm-layer {
		@include mq(desktop-l) {
			width: 594px;
		}

		// exception mgl rabatte
		.m-basic-keywordfilter & {

			@include mq(desktop-l) {
				right: 0;
			}

			@include mq(desktop-xl) {
				right: auto;
				left: 0;
			}
		}
	}
}