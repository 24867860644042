/* a-basic-input-radio--col-1-3 */
.a-basic-input-radio--col-1-3 {
	width: 100%;
	margin-right: 10px;

	@include mq(tablet) {
		margin-right: $form-gutter;
		width: calc(100% / 3 - 11px);

		&:nth-of-type(3n+1) {
			clear: both;
		}
	}
}