.o-layout-main-stage--cluster {

	@include mq(tablet) {
		height: 500px;
	}

	@include mq(desktop-xl) {
		height: 500px;
	}

	.oo-figure {
		max-height: 500px;
	}

	> .swiper {
		> .swiper-wrapper {
			> .swiper-slide {
				> .oo-figure {

					@include mq(tablet) {
						height: auto;
					}

					img {
						min-width: 100%;
						min-height: 100%;
						height: auto;
						top: 0;

						@include mq(tablet) {
							width: auto;
							left: -28%;
						}

						@include mq(desktop) {
							left: inherit;
						}
					}
				}

				> .oo-box--col-2 {
					padding: 0;
					bottom: -4px;

					@include mq(tablet) {
						bottom: 36px;
					}

					> .oo-box-childwrapper {
						display: flex;
						justify-content: space-between;
						align-items: flex-end;
						flex-wrap: wrap;
						padding: 15px 20px 20px 20px;
						height: 100%;

						@include mq(tablet) {
							padding: 15px 60px 20px 20px;
						}

						> .oo-boxheadline {
							@include font-size(38px);
							@include line-height(40px);
							flex-basis: 100%;
							align-self: flex-start;
						}

						> .oo-box-col-2--child {
							padding: 0 0 20px 0;
							flex-basis: 100%;

							&:first-of-type {
								@include mq(tablet) {
									border-right: 1px solid $color-secondary-4;
									padding-right: 20px;
									margin-right: 20px;
								}
							}

							@include mq(tablet) {
								flex-basis: 64%;
								padding-bottom: 0;
							}

							&.col-2--flexbuttons {
								padding-top: 10px;
								display: flex;
								flex-direction: column;
								justify-content: flex-end;

								@include mq(tablet) {
									flex-basis: 200px;
									flex-grow: 0;
									padding-top: 0;
								}

								> .a-basic-btn {
									position: static;
									margin-bottom: 5px;
									width: 100%;

									@include mq(tablet) {
										width: 200px;
									}

									&:last-of-type {
										margin-bottom: 15px;
									}
								}
							}

							> .ll-form {
								clear: both;

								> .a-basic-input-radio {
									display: block;
									float: none;
									margin-bottom: 15px;

									&:last-of-type {
										margin-bottom: 30px;
									}
								}

								> .a-basic-btn {
									position: static;
									display: inline-block;
									width: 100%;

									@include mq(tablet) {
										width: auto;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}