/* .m-my-adac-checkbox-image*/
.m-my-adac-checkbox-image {
	max-width: 100%;
	width: 100%;

	> .mm-wrapper {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: flex-start;

		@include mq(tablet) {
			flex-flow: row wrap;
			align-items: flex-start;
		}

		.l-outer--my-adac-content & {
			width: auto;

			@include mq(tablet) {
				margin: 0 auto;
				width: 600px;
			}

			@include mq(desktop-l) {
				width: auto;
				position: relative;
				margin: 0 -110px;
				z-index: 10;
			}
		}

		// tile validation error styles
		&.is-error {

			> .ll-error-msg {
				max-height: 300px;
				opacity: 1;
				margin: 0 0 10px 0;
			}
		}

		// tile validation default styles
		> .ll-error-msg {
			max-height: 0;
			width: 100%;
			opacity: 0;
			text-align: center;
			color: $color-action-alert;
			transition: all $form-animation ease;

			> a {
				color: $color-action-alert;
			}
		}

		> .mm-content {
			// auto for the height - column
			flex: 1 0 auto;
			width: 280px;
			max-width: 280px;
			height: auto;
			display: flex;
			flex-flow: column nowrap;
			margin: 0 0 40px 0;

			@include mq(tablet) {
				flex: 0 0 auto;
				margin: 0 0 20px 0;
			}

			@include if-parent('.m-my-adac-checkbox-image--round-edges') {
				@include mq(tablet) {
					position: relative;
					left: 10px
				}
			}

			> .mm-text-wrapper {
				@include if-parent('.m-my-adac-checkbox-image--round-edges') {
					position: relative;
					top: -15px;
					margin-right: 20px;
				}
			}
		}
	}
}