/* o-basic-banderole--product */
.o-basic-banderole--product {
	margin-left: 0;
	margin-right: 0;
	padding-bottom: 20px;
	padding-top: 17px;

	.oo-headline {
		padding-bottom: 10px;
		padding-left: 18px;
		@include milo-slab-bold($font-size-18);
		color: $color-secondary-1;


		@include mq(tablet) {
			padding-left: 28px;
			@include milo-slab-bold($font-size-28);
			color: $color-secondary-1;
		}
	}

	.swiper {
		margin-top: 0;
		padding-top: 0;
	}
}