//basic-calculator-tiles--classic
.m-basic-calculator-tiles--classic {

	> .mm-wrapper {
		z-index: unset;
		position: static;

		> .mm-intro {
			> h2 {
				margin-bottom: 5px;
			}

			> .mm-text {
				margin-bottom: 30px;

				> strong {
					&.mm-large {
						@include milo-slab-bold($font-size-18, 24px);
						font-weight: normal;

						@include mq(tablet) {
							@include font-size(25px, 34px);
						}
					}
				}
			}
		}

		> .mm-tile-wrapper {
			display: block;

			&--border {
				border: 1px solid $color-secondary-9;
				padding: 20px;
				border-radius: 12px;

				@include mq(tablet) {
					padding: 25px 50px;
				}
			}

			h3 {
				text-align: center;
				max-width: none;
				margin-bottom: 10px;
			}

			> .mm-rowheadline {
				text-align: center;
				margin-bottom: 15px;

				> * {
					max-width: none;
				}
			}

			> .mm-subrowheadline {
				text-align: center;
				margin: 0 auto 30px auto;
				max-width: 610px;
			}

			.mm-tilerow {
				margin-right: 0;
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(130px, max-content));
				justify-content: center;
				column-gap: 40px;
				row-gap: 40px;
				margin-bottom: 0;
				grid-auto-flow: dense;

				&:not(:last-child) {
					margin-right: 0;
					margin-bottom: 0;
				}

				&.h-space-s {
					margin-bottom: 10px;
				}

				&.h-space-m {
					margin-bottom: 30px;
				}

				&.h-space-l {
					margin-bottom: 30px;

					@include mq(tablet) {
						margin-bottom: 50px;
					}

				}

				&--no-image {
					grid-template-columns: repeat(auto-fit, minmax(140px, max-content));
				}

				&--small {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					column-gap: 20px;
					row-gap: 20px;
				}

				&--medium {
					display: block;

					@include mq(tablet) {
						display: grid;
						grid-template-columns: repeat(auto-fit, minmax(150px, max-content));
					}
				}

				&--large {
					display: block;

					@include mq(tablet) {
						display: grid;
						grid-template-columns: repeat(auto-fit, minmax(120px, max-content));
					}
				}

				&--flex {
					display: flex;
					flex-wrap: wrap;
				}

				> .mm-tile {
					width: 130px;
					height: 130px;
					position: relative;

					&--wide {
						width: 100%;
						grid-column: span 2;

						@include if-parent('.mm-tilerow.mm-tilerow--flex') {
							width: 210px;
						}
					}

					@include if-parent('.mm-tilerow.mm-tilerow--no-image') {
						width: 140px;
						height: auto;
						min-height: 65px;
					}

					@include if-parent('.mm-tilerow.mm-tilerow--small') {
						width: auto;
						height: auto;
					}

					@include if-parent('.mm-tilerow.mm-tilerow--medium') {
						width: calc(100% - 35px);
						margin-left: auto;
						margin-right: auto;

						@include mq(tablet) {
							width: 150px;
						}

						&:not(:last-child) {
							margin-bottom: 30px;

							@include mq(tablet) {
								margin-bottom: 0;
							}
						}
					}

					@include if-parent('.mm-tilerow.mm-tilerow--large') {
						width: calc(100% - 35px);
						margin-bottom: 40px;
						margin-left: auto;
						margin-right: auto;

						@include mq(tablet) {
							width: 250px;
							margin-bottom: 20px;
						}
					}

					@include if-parent('.mm-tilerow.mm-tilerow--xlarge') {
						width: calc(100% - 35px);
						margin-bottom: 20px;
						margin-left: auto;
						margin-right: auto;
						height: auto;

						@include mq(tablet) {
							width: 640px;
						}
					}

					> .mm-legend {
						position: absolute;
						left: 15px;
						top: -12px;
						z-index: 1;
						padding: 2px 10px;
						background: $color-secondary-11;
						color: $color-primary-2;
					}

					> input {
						position: absolute;
						opacity: 0;

						&:hover:not(:disabled) + label {
							background-color: $color-secondary-19;
						}

						.is-keyboard-focus &:focus + label {
							border: 2px solid $color-secondary-1;
							padding: 0;

							&:before {
								content: '';
								position: absolute;
								left: -6px;
								top: -6px;
								width: calc(100% + 12px);
								height: calc(100% + 12px);
								border: 3px solid $color-secondary-1;
								border-radius: 8px;
							}
						}

						&[type="checkbox"] + label {
							&:after {
								display: block;
								@include main-icon-choose-small;

								@include mq(tablet) {
									@include main-icon-choose-thin;
								}

								@include if-parent('.m-basic-calculator-tiles--classic.h-background') {
									@include main-icon-choose-small-grey;

									@include mq(tablet) {
										@include main-icon-choose-thin-grey;
									}
								}
							}
						}

						&:checked + label {
							background-color: $color-primary-1;
							border: 2px solid $color-secondary-1;
							padding: 0;

							&:after {
								display: block;
								@include main-icon-selected-small;

								@include mq(tablet) {
									@include main-icon-selected-thin;
								}

								@include if-parent('.m-basic-calculator-tiles--classic.h-background') {
									@include main-icon-selected-small-grey;

									@include mq(tablet) {
										@include main-icon-selected-thin-grey;
									}
								}

								@include if-parent('.mm-tilerow.mm-tilerow--small') {
									display: none;
								}
							}

							.mm-subline {
								text-align: center;

								&--unchecked {
									display: none;
								}

								&--checked {
									display: block !important;
								}
							}
						}

						&:focus:checked + label {
							.is-keyboard-focus & {
								background-color: $color-action-btn2;
							}
						}

						&:hover:checked:not(:disabled) + label {
							background-color: $color-action-btn2;
						}

						&:disabled + label {
							cursor: default;
						}

						&:disabled:not(:checked) + label {
							opacity: 0.3;
						}
					}

					> label {
						padding: 1px; //changing border size
						height: 100%;
						display: flex;
						flex-direction: column;
						justify-content: flex-end;
						border: 1px solid $color-secondary-23;
						border-radius: 4px;
						cursor: pointer;
						position: relative;
						background-color: $color-primary-2;

						&:after {
							display: none;
							content: "";
							position: absolute;
							width: 44px;
							height: 44px;
							top: -27px;
							right: -27px;
						}

						@include if-parent('.mm-tilerow.mm-tilerow--no-image, .mm-tilerow.mm-tilerow--small, .mm-tilerow.mm-tilerow--medium') {
							justify-content: center;
						}

						@include if-parent('.mm-tilerow.mm-tilerow--before-image') {
							flex-direction: row;
							justify-content: center;
							align-items: center;
						}

						@include if-parent('.mm-tilerow.mm-tilerow--medium') {
							height: auto;
							min-height: 69px;
						}

						@include if-parent('.mm-tilerow.mm-tilerow--large') {
							justify-content: flex-start;
						}

						@include if-parent('.mm-tilerow.mm-tilerow--xlarge') {
							height: auto;
							min-height: 50px;
						}

						> span {
							display: block;

							&.mm-icon {
								position: absolute;
								width: 100%;
								top: 0;
								white-space: nowrap;

								@include if-parent('.mm-tilerow.mm-tilerow--before-image') {
									position: relative;
									width: auto;
								}

								img {
									position: relative;
									left: 50%;
									top: -2px;
									transform: translateX(-50%);

									@include if-parent('.mm-icon.mm-icon--limited') {
										top: 10px;
									}

									@include if-parent('.mm-tilerow.mm-tilerow--before-image') {
										transform: translateX(0);
										left: 0;
										top: 0;
									}

									@include if-parent('.mm-tile--multiple') {
										transform: translateX(0);
										left: 0;
										top: 0;
									}
								}
							}

							&.mm-desc {
								padding: 12px 5px;

								@include if-parent('.mm-tilerow.mm-tilerow--small') {
									padding: 8px 25px;
								}

								@include if-parent('.mm-tilerow.mm-tilerow--before-image') {
									padding: 0 10px;
								}

								> .mm-headline {
									@include milo-bold($font-size-16, 18px);
									letter-spacing: -0.07em;
									text-align: center;
								}

								> .mm-subline {
									text-align: center;
									min-height: 22px;

									&--small {
										font-size: 12px;
									}

									&--checked {
										display: none;
									}
								}

								> .mm-content-inner {
									margin-top: 20px;
								}
							}

							> span {
								display: block;
							}
						}
					}

					> .mm-disabled-layer {
						display: none;
					}

					> .mm-content {
						margin-top: 40px;
					}
				}
			}

			> .mm-content-row {
				position: relative;
				//z-index: 1; not possible for z-index controlling of layer inside this level

				&--flex {
					display: flex;

					> div {
						flex: 1 1 auto;
						max-width: 400px;
					}

					&.l-form {
						> div {
							max-width: none;
						}
					}
				}

				&--centered {
					justify-content: center;
				}

				&--centered-full {
					justify-content: center;

					> div {
						max-width: none;

						> * {
							margin: 0 auto;
						}
					}

					.ll-col-data {
						justify-content: center;
					}
				}

				&--divider {
					padding-bottom: 30px;
					margin-bottom: 30px;
					border-bottom: 1px solid $color-secondary-5;
				}

				&.mm-content-row--centered-natural {
					> div {
						width: 100%;
						max-width: none;
					}
				}

				.mm-disabled-layer {
					display: none;
				}

				.ll-multirow {
					> div:not(.is-size-s):not(.is-size-m):not(.is-size-l) {
						flex: 1;
					}
				}

				.mm-clone-content {
					.ll-clone-wrapper {
						&:not(:last-child) {
							margin-bottom: 30px;

							@include mq(tablet) {
								margin-bottom: 40px;
							}
						}
					}

					.mm-button-trigger {
						&--plus {
							position: relative;
							padding-left: 40px;
							cursor: pointer;

							&:before {
								display: block;
								width: 22px;
								height: 22px;
								content: "";
								@include main-icon-clone;
								position: absolute;
								top: -13px;
								left: -13px;
							}
						}
					}
				}

				.mm-clone-wrapper {
					h2 {
						position: relative;
					}
				}
			}
		}

		.js-delete-select-clone {
			@include main-icon-delete();
			display: inline-block;
			top: -11px;
			position: absolute;
			cursor: pointer;

			@include mq(tablet) {
				top: -4px;
			}
		}
	}
}