/* o-basic-banderole--award */
.o-basic-banderole--colored-slides {

	> .swiper {

		> .swiper-wrapper {

			> .swiper-slide {
				background-color: $color-secondary-8;
			}
		}
	}


}