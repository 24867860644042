.m-basic-compare-teaser {
	position: relative;

	> .mm-wraper-swiper {
		position: relative;
		padding-top: 35px;
	}

	> h2 {
		margin-bottom: 4px;


		@include mq(tablet) {
			margin-bottom: 0;
		}
	}

	.swiper-pagination {
		top: 0;
	}

	.swiper {
		> .swiper-wrapper {
			> .swiper-slide {
				background-color: $color-primary-2;
				max-width: 445px;
				flex: 1 0 295px;
				width: 100%;
				height: auto;

				&:not(:last-child) {
					margin-right: 14px;
				}

				&.is-stopper {
					padding-right: 14px;
					margin-right: 0;

					:-ms-fullscreen,
					:root & {
						padding-right: 20px;
					}
				}

				> .mm-teaser-wrapper {
					position: relative;
					background-color: $color-secondary-8;
					min-height: 90px;
					height: 100%;

					> .mm-image {

						@include if-parent('.swiper-slide.is-stopper') {
							overflow: hidden;
						}

						> picture {
							> img {
								width: 100%;
								display: block;

								@include if-parent('.swiper-slide.is-stopper') {
									width: calc(100% + 14px);
								}
							}
						}
					}

					> .mm-content {
						padding: 10px 10px 0 10px;
						border-bottom: 1px solid $color-secondary-4;

						> .mm-headline {
							@include milo-bold(20px);
							max-width: 200px;
							line-height: 24px;
							margin-bottom: 20px;
						}

						> .mm-price-wrapper {
							display: flex;
							justify-content: space-between;
							margin-bottom: 10px;

							> span {
								> .mm-price {
									@include milo-bold(20px);
									padding-right: 5px;
								}
							}
						}

						> .mm-list-wrapper {
							margin-bottom: 10px;

							> ul {
								> li {
									padding-left: 17px;
								}
							}
						}

						> .mm-link-wrapper {
							margin-bottom: 15px;
						}

						> .mm-btn-wrapper {
							margin-bottom: 20px;
						}
					}

					> .mm-subtext {
						padding: 10px 10px 15px 10px;

						> .mm-condition {
							position: relative;
							padding-left: 8px;
							margin-bottom: 10px;

							&:before {
								content: '*';
								position: absolute;
								top: 0;
								left: 0;
							}

							> span {
								display: block;
							}
						}
					}
				}
			}
		}

		.swiper-button-next,
		.swiper-button-prev {
			&:focus-within {
				outline: none;

				.is-keyboard-focus & {
					outline: 2px solid #000;
				}
			}
		}
	}
}