/* m-basic-teaser-across--incontent */
.m-basic-teaser-across--incontent {
	border-bottom: 1px solid $color-secondary-5;
	padding-bottom: 0;
	background: $color-primary-2 !important;

	> article {
		width: 289px;

		> .mm-article-content {
			height: 86px;
			background: $color-primary-2;

			> .mm-header {
				padding: 7px 0;
			}

			.a-basic-link {
				background-position: -10px -2px;
			}
		}

		> figure {
			height: 113px;

			> a {

				> img {

					@include mq(tablet) {
						height: 113px;
						width: 289px;

					}
				}
			}
		}

	}

	.o-basic-banderole & {
		padding-top: 0;
		padding-bottom: 0;

		&.mm-first {
			padding-left: 0;
		}

		&.mm-last {
			padding-right: 0;
		}
	}
}