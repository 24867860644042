.m-basic-calculator-content-box--left,
.m-basic-calculator-content-box-newform--left {

	.mm-tile-container {
		display: flex;
		flex-flow: row wrap;
		margin: 5px -10px 5px -10px;
		justify-content: flex-start;

		&.is-odd {
			justify-content: flex-start;
		}

		> .mm-tile {
			margin: 0 10px 20px 10px;

			@include mq('tablet') {
				margin: 0 30px 20px 10px;

				//exception in Form
				.l-form .ll-row .ll-col-data & {
					margin: 0 30px 20px 20px;
				}
			}

			@include if-parent('.mm-tile-container.is-odd') {
				margin-bottom: 20px;

				&-empty {
					margin-bottom: auto;
				}

				&.is-last {
					margin-bottom: auto;
				}
			}

			&-empty {
				margin-bottom: auto;
			}

			> label {
				> .mm-text-container {
					padding: 0 5px;

					@include if-parent('.mm-tile-container.is-odd') {
						padding: 0 5px;
					}
				}
			}
		}
	}

}