/* m-basic-rating */
.m-basic-rating--ekomi-ves {
	position: relative;
	min-height: 160px;
	background: $color-secondary-8;
	padding: 20px 20px;
	border-radius: 10px;

	@include mq(tablet) {
		padding: 40px 40px;
	}

	> .mm-headline {
		margin-bottom: -10px;
	}

	> .mm-valid {

		> .mm-rating-content {
			display: flex;
			flex-direction: column;
			position: relative;

			@include mq(tablet) {
				flex-direction: row;
			}

			> .mm-star-container {
				order: 3;
				width: 100%;
				margin: 0 0 10px 0;
				display: flex;
				flex-direction: row;

				@include mq(tablet) {
					order: 1;
					flex-grow: 1;
					width: 50%;
					min-width: 360px;
					margin: 0 0 10px 0;
				}

				&:only-child {
					@include mq(tablet) {
						justify-content: center;
						margin: 0;
						width: 100%;
					}
				}

				> .mm-ekomi-logo {
					margin: 5px 10px 10px 0;
					max-width: 70px;

					@include mq(tablet) {
						margin: 5px 20px 10px 0;
						flex-grow: 1;
						position: relative;
						top: -10px;
					}
				}

				> div {
					margin-right: 10px;
					margin-top: 15px;
					white-space: nowrap;

					@include mq(tablet) {
						margin-top: 5px;
					}

					> div {

						&:first-child {
							margin-left: -10px;
						}

						> .mm-star--ekomi {
							height: 26px;
							width: 26px;
							display: inline-block;

							&:before {
								position: absolute;
								content: '';
								margin-top: -10px;
								@include main-icon-star-00;
							}

							&-10 {
								&:before {
									@include main-icon-star-10;
								}
							}

							&-20 {
								&:before {
									@include main-icon-star-20;
								}
							}

							&-30 {
								&:before {
									@include main-icon-star-30;
								}
							}

							&-40 {
								&:before {
									@include main-icon-star-40;
								}
							}

							&-50 {
								&:before {
									@include main-icon-star-50;
								}
							}

							&-60 {
								&:before {
									@include main-icon-star-60;
								}
							}

							&-70 {
								&:before {
									@include main-icon-star-70;
								}
							}

							&-80 {
								&:before {
									@include main-icon-star-80;
								}
							}

							&-90 {
								&:before {
									@include main-icon-star-90;
								}
							}

							&-full {
								&:before {
									@include main-icon-star-full-black;
								}
							}
						}

						> .mm-reviews {
							padding-left: 2px;
							font-weight: normal;
							color: #666666;
						}
					}
				}

				> .mm-quota {
					font-weight: bold;
					margin-bottom: 15px;
					margin-top: 20px;
					margin-right: 0;
					white-space: nowrap;

					@include mq(tablet) {
						margin-bottom: 0;
						margin-top: 5px;
						flex-grow: 1;
					}

					@include if-parent('.mm-star-container:only-child') {
						@include mq(tablet) {
							flex-grow: initial;
						}
					}

					> .mm-recommendation {
						margin-top: -7px;
						display: inline-block;
						color: #666666;
						@include milo-regular(24px);
						font-weight: initial;

						&:first-child {
							color: black;
							@include milo-bold(24px);

							@include mq(tablet) {
								@include milo-bold(26px);
							}
						}

						@include mq(tablet) {
							margin-top: -5px;
							@include milo-regular(26px);
						}
					}
				}
			}

			> .mm-quote {
				order: 1;
				width: 100%;
				margin: 0 25px 10px 0;
				@include line-height($font-size-17);
				color: #666666;
				line-height: 25px;
				font-style: italic;

				@include mq(tablet) {
					margin: 5px 0 25px 0;
					max-width: 600px;
					order: 2;
					width: 50%;
					@include font-size($font-size-19);
				}

				@include mq(desktop) {
					margin: 5px 30px 10px 0;
				}
			}

			> .mm-additional-text {
				order: 2;
				color: #666666;
				margin-bottom: 30px;
				text-align: right;
				margin-right: 0;

				@include mq(tablet) {
					position: absolute;
					right: 0;
					bottom: -5px;
					margin-bottom: 0;
					text-align: initial;
				}
			}
		}
	}

	> .mm-fail {
		background-color: $color-action-alert;
		color: $color-primary-2;
		line-height: 1.125rem;
		margin: 8px 0 20px;
		min-height: 50px;
		padding: 12px 10px 6px 40px;
		position: relative;

		&:before {
			@include main-icon-error-symbol-white;
			content: '';
			display: block;
			left: 0;
			position: absolute;
			top: 0;
		}

		> p {
			margin: 0;
		}
	}

	> .mm-loader {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, 0.8);

		@include if-parent('.m-basic-rating--ekomi-ves.is-loaded') {
			display: none;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 48px;
			height: 48px;
			margin: -40px 0 0 -34px;
			@include main-icon-refresh-48px;
			animation: rotate 1s infinite linear;

			@keyframes rotate {
				0% {
					transform: rotate(0deg);
				}

				100% {
					transform: rotate(360deg);
				}
			}
		}
	}
}