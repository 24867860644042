/* m-basic-legend */
.m-basic-legend {
	padding: 8px 24px 8px 2px;
	list-style: none;
	display: flex;
	flex: 1 1 auto;
	flex-direction: row;
	border: 1px solid $color-secondary-4;
	position: relative;
	align-items: flex-start;
	align-content: center;
	flex-wrap: wrap;
	background: $color-primary-2;
	z-index: 51;

	@include mq(tablet) {
		flex-wrap: nowrap;
		flex: 0 0 auto;
	}

	> .mm-legend-item {
		display: flex;
		flex-direction: row;
		flex: 0 1 auto;
		align-items: center;
		width: 50%;
		margin-bottom: 5px;
		min-height: 0;

		@include mq(tablet) {
			width: auto;
			margin: 0;
			flex: 0 1 auto;
		}

		&:first-child {
			@include mq(tablet) {
				flex-basis: 120px;
			}
		}

		&:nth-child(2) {
			@include mq(tablet) {
				flex-basis: 123px;
			}
		}

		&:nth-child(3) {
			@include mq(tablet) {
				flex-basis: 175px;
			}
		}

		&:nth-child(4) {
			@include mq(tablet) {
				flex-basis: 175px;
			}
		}

		&:nth-child(5) {
			@include mq(tablet) {
				flex-basis: 200px;
			}
		}

		> img {
			align-self: flex-start;
			margin-right: 3px;
		}

		> span {
			@include font-size(12px);
			@include line-height(15px);
		}
	}

	> .mm-legend-close {
		position: absolute;
		top: 8px;
		right: 10px;
		width: 14px;
		height: 14px;

		> button {
			@include main-icon-close;
			top: -10px;
			right: -10px;
			position: absolute;
			border: none;
			padding: 0;
		}
	}
}