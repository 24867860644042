.m-my-adac-postbox {

	%delete {
		position: absolute;
		top: 15px;
		right: 30px;
		text-decoration: none;
		outline: none;

		@include mq(desktop-l) {
			top: 25px;
			right: 40px;
			padding-left: 45px;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: -12px;
			left: -17px;
			@include my-adac-icon-delete;

			@include mq(desktop-l) {
				left: -10px;
			}

			.l-outer--my-adac-dashboard & {
				@include my-adac-icon-delete-grey;
			}
		}

		> span {
			display: none;

			@include mq(desktop-l) {
				display: block;
			}
		}
	}

	%checkbox {
		position: absolute;
		bottom: 12px;
		z-index: 1;

		@include mq(desktop-l) {
			bottom: 4px;
		}

		> div {

			> label {
				position: relative;
				top: 1px;
			}
		}
	}

	@include mq(desktop-l) {
		position: relative;
		left: 50%;
		transform: translate(-50%);
		width: calc(100% + 262px);
	}

	// exception
	.m-my-adac-dashboard-box-showcase & {
		width: 100%;

		&.h-space-s,
		&.h-space-m,
		&.h-space-l {
			margin-bottom: 0;
		}
	}

	> .mm-wrapper {

		position: relative;
		padding-bottom: 17px;
		border: 1px solid $color-secondary-10;
		border-radius: 10px;

		@include mq(desktop-l) {
			padding: 70px 30px 30px 30px;
		}

		// exception
		.m-my-adac-dashboard-box-showcase & {
			padding-left: 0;
			padding-right: 0;
			border: 0;

			@include mq(desktop-l) {
				padding-top: 0;
			}
		}

		> .mm-delete {
			@extend %delete;

			.m-my-adac-dashboard-box-showcase & {
				right: 15px;

				@include mq(desktop-l) {
					right: 19px;
					top: -45px;
				}
			}
		}


		> .mm-scrollpane {
			.m-my-adac-dashboard-box-showcase & {
				@include mq(desktop-l) {
					padding-right: 9px;
				}
			}

			@include if-parent('.m-my-adac-postbox.is-scrollpane') {
				max-height: 590px;
			}

			&:focus {
				outline: none;
			}

			> .jspContainer {

				//scrollbar wrapper
				> .jspVerticalBar {
					width: 5px;
					right: 0;

					> .jspTrack {
						background: $color-secondary-5;

						> .jspDrag {
							background: $color-secondary-2;
						}
					}
				}

				> .jspHorizontalBar {
					display: none;
				}
			}

			.mm-table-wrapper {
				padding-bottom: 20px;

				table {
					width: 100%;

					> thead {

						> tr {
							border-bottom: 1px solid $color-secondary-10;

							> th {
								position: relative;
								height: 55px;
								padding: 2px 10px 8px 10px;
								text-align: left;
								@include milo-bold(16px);

								@include mq(desktop-l) {
									height: auto;
								}

								// checkbox
								&:first-child {
									width: 35px;

									@include mq(desktop-l) {
										width: 120px;
									}
								}

								// date
								&:nth-child(2) {
									display: none;

									@include mq(desktop-l) {
										display: table-cell;
										width: 150px;
									}
								}

								// from
								&:nth-child(3) {
									display: none;

									@include mq(desktop-l) {
										display: table-cell;
										min-width: 250px;
									}
								}

								// subject
								&:nth-child(4) {
									text-indent: -10000px;

									@include mq(desktop-l) {
										text-indent: inherit;
									}
								}

								// attachement
								&:last-child {
									text-indent: -10000px;

									@include mq(desktop-l) {
										text-indent: inherit;
									}
								}

								> .a-basic-input-checkbox {
									@extend %checkbox;

									> div {
										padding-left: 37px;

										> label {
											@include milo-bold(16px);
											padding: 0 0 0 25px;
										}
									}
								}

								> .mm-sort {
									cursor: pointer;

									> span {
										position: relative;
										width: 24px;
										height: 24px;

										&:before {
											content: '';
											display: block;
											position: absolute;
											top: -14px;
											right: -42px;
											@include main-icon-sort;

											@include if-parent('.mm-sort.is-up') {
												@include main-icon-sort-up;
											}

											@include if-parent('.mm-sort.is-down') {
												@include main-icon-sort-down;
											}
										}
									}
								}
							}
						}
					}

					> tbody {

						> tr {

							&.mm-head {
								cursor: pointer;
								border-bottom: 1px solid $color-secondary-10;
							}

							&.is-open {
								border-bottom: 1px solid $color-secondary-10;
							}

							> td {
								position: relative;
								padding: 9px 9px 7px 9px;
								text-align: left;
								vertical-align: top;
								@include font-size(14px);
								@include milo-bold();

								@include mq(desktop-l) {
									padding: 14px 10px;
									@include font-size(16px);
								}

								@include if-parent('tr.was-read') {
									@include milo-regular();
									font-weight: normal;
								}

								@include if-parent('tr.mm-content') {
									padding: 0;
								}

								// date
								&:nth-child(2) {
									display: none;

									@include mq(desktop-l) {
										display: table-cell;
									}
								}

								// from
								&:nth-child(3) {
									display: none;

									@include mq(desktop-l) {
										display: table-cell;
									}
								}

								// subject
								&:nth-child(4) {

									@include if-parent('tr.was-read') {
										@include mq(desktop-l) {
											@include milo-regular();
											font-weight: normal;
										}
									}
								}

								> .mm-flex {

									@include mq(desktop-l) {
										display: flex;
									}

									> .a-basic-input-checkbox {
										margin: 0;
										top: 1px;
										z-index: 1;

										@include mq(desktop-l) {
											margin-right: 10px;
										}
									}

									> .mm-toggle {
										display: none;
										position: relative;
										top: -1px;
										width: 24px;
										height: 24px;
										margin-right: 2px;
										margin-left: 20px;

										&:before {
											content: '';
											position: absolute;
											top: -10px;
											left: -10px;
											@include main-icon-chevron-down-light-grey;
										}

										.l-outer--my-adac-dashboard & {
											&:before {
												@include main-icon-chevron-down-grey;
											}
										}

										@include mq(desktop-l) {
											display: block;
										}

										@include if-parent('tr.is-open') {
											&:before {
												@include main-icon-chevron-up-light-grey;
											}

											.l-outer--my-adac-dashboard & {
												&:before {
													@include main-icon-chevron-up-grey;
												}
											}
										}
									}

									> .mm-read {
										position: relative;
										top: -1px;
										display: none;
										width: 24px;
										height: 24px;

										&:before {
											content: '';
											position: absolute;
											top: -10px;
											left: -10px;
											@include main-icon-email-grey;
										}

										.l-outer--my-adac-dashboard & {
											&:before {
												@include main-icon-email-grey2;
											}
										}

										@include mq(desktop-l) {
											display: block;
										}

										@include mq(desktop-l) {
											display: block;
										}

										@include if-parent('tr.was-read') {
											&:before {
												@include main-icon-email-draft;
											}

											.l-outer--my-adac-dashboard & {
												&:before {
													@include main-icon-email-draft-grey;
												}
											}
										}
									}
								}

								> .js-sortable-text {
									display: none;
								}

								// js generated
								> .mm-from-mobile {
									font-weight: bold;
									padding-right: 30px;

									@include if-parent('tr.was-read') {
										@include milo-regular();
										font-weight: normal;
									}

									@include mq(desktop-l) {
										display: none;
									}
								}

								> .mm-toggle-content-wrapper {

									@include if-parent('tr.is-open') {
										height: auto;
										overflow: inherit;
									}

									> .mm-toggle-content {
										height: 0;
										overflow: hidden;
										padding-left: 9px;
										padding-right: 9px;
										transition: all 0.5s ease-in-out;


										@include mq(desktop-l) {
											padding-left: 10px;
											padding-right: 10px;
										}

										@include if-parent('tr.is-open') {
											padding-top: 9px;
											padding-bottom: 9px;
										}

										@include mq(desktop-l) {
											@include if-parent('tr.is-open') {
												padding-top: 14px;
												padding-bottom: 14px;
											}
										}

										&.is-not-overflow-hidden {
											height: auto;
											overflow: inherit;
										}

										> .a-basic-link {
											padding-top: 12px;
											@include font-size(16px);

											&:after {
												top: calc(50% - 1px);
												transform: translateY(-50%);
												right: -10px;
											}

											> .aa-link-icon-wrapper {
												left: -10px;
												top: calc(50% - 1px);
												transform: translateY(-50%);
											}
										}

									}
								}

								// js generated
								> .mm-date-mobile {
									position: absolute;
									top: 11px;
									right: 12px;
									@include font-size(11px);

									@include mq(desktop-l) {
										display: none;
									}

									@include if-parent('tr.was-read') {
										color: $color-secondary-3;
									}

									.m-my-adac-dashboard-box-showcase & {
										right: 22px;
									}
								}

								> .mm-attachement {
									position: absolute;
									top: 11px;
									right: -5px;
									width: 17px;
									height: 17px;

									&:before {
										content: '';
										position: absolute;
										top: -10px;
										left: -10px;
										@include main-icon-attachement;
									}

									.l-outer--my-adac-dashboard & {
										&:before {
											@include main-icon-attachement-grey;
										}
									}

									@include mq(desktop-l) {
										top: 50%;
										left: 50%;
										right: auto;
										width: 24px;
										height: 24px;
										transform: translate(-50%, -50%);
									}

									.m-my-adac-dashboard-box-showcase & {
										right: 5px;
									}
								}
							}
						}
					}

					> tfoot {

						// exception
						.m-my-adac-dashboard-box-showcase & {
							display: none;
						}

						> tr {

							&:first-child {
								border-bottom: 1px solid $color-secondary-10;
							}

							> td {
								position: relative;
								height: 55px;
								padding: 9px 9px 7px 9px;
								text-align: left;
								vertical-align: top;
								@include font-size(14px);

								@include mq(desktop-l) {
									padding: 14px 10px;
									@include font-size(16px);
								}

								> .a-basic-input-checkbox {
									@extend %checkbox;
									top: 10px;
									bottom: auto;

									> div {
										padding-left: 37px;

										> label {
											@include milo-bold(16px);
											padding: 0 0 0 25px;
										}
									}
								}

								> .a-basic-btn {
									display: inline-block;
									position: absolute;
									top: 17px;
									left: 50%;
									transform: translate(-50%);

									@include mq(desktop-l) {
										top: 25px;
									}
								}

								> .mm-delete {
									@extend %delete;
									position: absolute;
									top: 16px;
									right: 22px;

									@include mq(desktop-l) {
										right: 10px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}