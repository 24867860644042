/* Webview Cardamage Font */
// Roboto
@font-face {
	font-family: "Roboto";
	src: url("/assets/font/roboto/Roboto-Regular.ttf") format("truetype");
}

/* Webview Cardamage Font */
// Roboto
@font-face {
	font-family: "Roboto Bold";
	src: url("/assets/font/roboto/Roboto-Bold.ttf") format("truetype");
}

// DS Digital Italic for petrol prices
@font-face {
	font-family: "DS-Digital-Italic";
	src: url("/assets/font/ds-digital-italic/DS-DIGII.eot");
	src: url("/assets/font/ds-digital-italic/DS-DIGII.eot?#iefix") format("embedded-opentype"),
		url("/assets/font/ds-digital-italic/DS-DIGII.otf") format("opentype"),
		url("/assets/font/ds-digital-italic/DS-DIGII.woff") format("woff"),
		url("/assets/font/ds-digital-italic/DS-DIGII.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}