/* m-basic-image--full */

.m-basic-image--full {
	margin: 0px -20px 20px -20px;
	position: relative;

	@include mq(tablet) {
		margin: 0px -30px 20px -30px
	}

	@include mq(desktop) {
		margin: 0px -40px 20px -40px;
	}

	@include mq(print) {
		page-break-before: avoid;
		page-break-inside: avoid;
	}

	// exception
	&:first-child {
		margin-top: -16px;
		margin-bottom: 0;

		@include mq(tablet) {
			margin-top: -12px;
		}

		@include mq(desktop) {
			margin-top: -12px;
		}

		// exception .m-basic-header
		.m-basic-header ~ .l-main & {
			margin-top: 0;
		}
	}

	> figure {

		> .mm-figurecaption {
			padding: 7px 20px 0 20px;
			width: 100%;
			font-size: 78%;

			@include mq(tablet) {
				padding-left: 30px;
			}

			@include mq(desktop) {
				padding-left: 40px;
			}

			&--right {
				text-align: right;
				padding-right: 20px;
				padding-left: 20px;

				@include mq(tablet) {
					padding-right: 30px;
					padding-left: 30px;
				}

				@include mq(desktop) {
					padding-right: 40px;
					padding-left: 40px;
				}
			}

		}

	}
}