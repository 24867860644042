/* m-basic-teaser-across--reduced */
.m-basic-teaser-across--reduced {
	background: $color-primary-2 !important;
	border-bottom: 1px solid $color-secondary-9;

	&.mm-first {

		.o-basic-banderole & {
			margin-left: 0;
			padding-left: 0;
		}
	}

	&.mm-last {
		.o-basic-banderole & {
			margin-right: 0;
			padding-right: 0;
		}
	}

	> article {
		.mm-article-content {
			background: $color-primary-2;

			.mm-link {
				border-bottom: none;
				padding: 6px 10px 16px 10px;
			}
		}
	}
}