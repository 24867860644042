/* .m-basic-hint--address */
.m-basic-hint--address {

	> address {
		display: block;
		padding: 1px 0 8px 0;

		@include mq(desktop) {
			position: static;
			float: right;
			width: 30%;
			padding-top: 8px;
			text-align: right;
		}
	}


}