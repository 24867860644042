.m-basic-teaser-flex {
	position: relative;
	padding: 25px 0;
	background: $color-secondary-8;

	@include mq('tablet') {
		padding: 40px 0;
	}

	> .mm-flex-content {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		max-width: 898px;
		margin: 0 auto;

		@include mq('tablet') {
			flex-direction: row;
		}

		> .mm-teaser {
			position: relative;
			background: $color-primary-2;
			border-radius: 4px;
			padding: 15px 80px 60px 20px;
			margin: 0 20px 20px;

			@include mq('tablet') {
				flex: 1 1 0;
				max-width: 50%;
				margin: 0 10px;
			}

			&:first-child {
				@include mq('tablet') {
					margin-left: 0;
				}
			}

			&:last-child {
				margin-bottom: 0;

				@include mq('tablet') {
					margin-right: 0;
				}
			}

			> .mm-btn-box {
				position: absolute;
				bottom: 20px;
			}
		}
	}
}