/* ==========================================================================
   BASE STYLES
   ========================================================================== */

html,
body,
button,
input,
select,
textarea,
label {
	color: $color-secondary-1;
	font-family: "Calibri", "Arial", sans-serif;
	@include milo-regular($font-size-16);
	border: 0;

	.h-roboto & {
		font-family: 'Roboto', sans-serif;
	}

	@include mq(print) {
		@include milo-regular($font-size-16);

		.h-roboto & {
			font-family: 'Roboto', sans-serif;
		}
	}

	@include placeholder {
		color: $color-secondary-3;
	}
}

button {
	background: none;
	@extend %focus-outline;
}

html {
	box-sizing: border-box;
	overflow-y: scroll; //INFO: Problem: Because of lightbox, this value had to be deaktivated
	font-size: 100%;
	/* default 16px */
	line-height: 1.4;
	color-adjust: exact; // force Sprite-Background color to be printed and not changed by browser
	-webkit-print-color-adjust: exact; // force Sprite-Background color to be printed and not changed by browser
}

body {
	overflow: hidden;
	background: $color-primary-2;
	@include milo-regular($font-size-16);

	.h-roboto & {
		font-family: 'Roboto', sans-serif;
	}

	&.is-simulating-spacing * {
		letter-spacing: 2px;
		line-height: 2rem !important;
	}
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

b,
strong {
	@include milo-bold();

	.h-roboto & {
		font-family: 'Roboto Bold', sans-serif;
	}
}

a {
	color: $color-secondary-1;
	@include font-size($font-size-16);
	text-decoration: underline;

	&:not(.a-basic-btn) {
		@extend %focus-outline;
	}

	&:hover {
		color: $color-secondary-1;
		outline: 0 !important;
	}

	&:visited {
		color: $color-secondary-1;
	}

	&:focus {
		outline: 1px solid $color-action-focus;
	}

	&[href^="mailto:"] {
		padding-left: 25px;
		position: relative;

		&:before {
			content: '';
			display: inline-block;
			@include main-icon-contact-email;
			position: absolute;
			top: -14px;
			left: -16px;
		}
	}

	&.a-basic-link--my-adac[href^="mailto:"] {
		background: none;
		padding-left: 48px;
	}

	.m-my-adac-content-hint & {
		&[href^="mailto:"] {
			background: none;
			// ### no padding-left needed in /pages-myadac-examples-verify-phase1 ###
		}
	}

	.m-basic-sticky-service-contact & {
		&[href^="mailto:"] {
			background: none;
			padding-left: 0;
		}
	}
}

p {
	margin: 0 0 15px 0;
	@include font-size($font-size-16, 21px);
}

dl,
menu,
ol,
ul {
	margin: 0 0 15px 0;
	padding: 0 0 0 15px;
	@include font-size($font-size-16, 21px);
	list-style-type: none;
}

h1,
.h-h1 {
	margin: 0;
	padding: 0 0 14px 0;
	@include milo-slab-bold($font-size-22, 29px);

	@include mq(tablet) {
		padding: 0 0 17px 0;
		@include font-size($font-size-36, 43px);
	}

	@include mq(desktop) {
		@include font-size($font-size-40, 48px);
	}

	@include mq(print) {
		@include milo-slab-bold($font-size-24, 31px);
	}

	.h-roboto & {
		font-family: 'Roboto Bold', sans-serif;
	}

	&.is-white {
		color: $color-primary-2;
	}
}

h2,
.h-h2 {
	margin: 0;
	padding: 0 0 10px 0;
	@include milo-slab-bold($font-size-18, 24px);
	font-weight: normal;

	@include mq(tablet) {
		padding: 0 0 18px 0;
		@include font-size($font-size-28, 34px);
	}

	@include mq(print) {
		@include milo-slab-bold($font-size-18, 24px);
	}

	.h-roboto & {
		font-family: 'Roboto Bold', sans-serif;
	}
}

.h-h2-margin {
	margin-bottom: 20px;
}

h3,
.h-h3 {
	margin: 0;
	padding: 0 0 6px 0;
	font-weight: bolder;
	@include milo-bold($font-size-16, 21px);

	@include mq(tablet) {
		@include font-size($font-size-20, 24px);
	}

	.h-roboto & {
		font-family: 'Roboto Bold', sans-serif;
	}
}

h4,
.h-h4 {
	margin: 0;
	padding: 0 0 6px 0;
	font-weight: bolder;
	@include milo-bold($font-size-16, 21px);

	// Exception for m-newsletter-category
	.mm-newsletter-theme & {
		padding-bottom: 0;
	}

	.h-roboto & {
		font-family: 'Roboto Bold', sans-serif;
	}
}

th {
	font-weight: normal;
	@include milo-bold();

	.h-roboto & {
		font-family: 'Roboto Bold', sans-serif;
	}
}

::-moz-selection {
	background: $color-primary-1;
	text-shadow: none;
}

::selection {
	background: $color-primary-1;
	text-shadow: none;
}

hr {
	display: block;
	height: 1px;
	margin: 30px 0;
	padding: 0;
	border: 0;
	border-top: 1px solid $color-secondary-5;

	&.yellow {
		border-top: 2px solid $color-primary-1;
	}
}

address {
	font-style: normal;
}

/*
 * Remove default fieldset styles.
 */
fieldset {
	margin: 0;
	padding: 0;
	border: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */
textarea {
	resize: vertical;
}

input:focus::-moz-placeholder {
	/* Firefox 19+ */
	color: transparent !important;
}

textarea:focus::-moz-placeholder {
	/* Firefox 19+ */
	color: transparent !important;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
	opacity: 1;
}

input,
textarea {
	-webkit-tap-highlight-color: transparent;
	border-radius: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

input[type=submit],
button {
	&:hover {
		outline: 0 !important;
	}
}

textarea:focus,
input:focus {
	outline: 0;
}

::-ms-clear {
	display: none;
}

small {
	display: inline-block;
	@include font-size($font-size-12);

	@include mq(print) {
		@include font-size($font-size-10);
	}
}

.buorg {
	max-width: 620px;
	width: 100vw !important;
	left: 50% !important;
	top: 50% !important;
	transform: translate(-50%, -50%);
	border: none !important;
	animation: buorgfly2 1s ease-out 0s !important;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100vw;
		height: 100vh;
		background-color: transparentize($color-primary-3, 0.6);
	}

	&.is-hidden {
		display: none;
	}

	> .buorg-pad {
		padding: 40px 25px;
		position: relative;
		z-index: 2;
		background-color: $color-primary-2 !important;
		border-radius: 8px;
	}

	.m-image {
		margin-bottom: 10px;

		img {
			width: 100%;
			max-width: 100%;
		}
	}

	p {
		margin: 0;
	}

	.close-buoop {
		position: absolute;
		right: 10px;
		top: 0;
		cursor: pointer;
		width: 40px;
		height: 40px;
		@include main-icon-close;
	}
}

img {

	// prevent showing broken img icon
	&[src="#"],
	&:not([src]) {
		visibility: hidden;
	}

	// only append lazy images styling to images with lazyloading-class
	&.swiper-lazy,
	&.lazyload {

		// with the standard "#" src
		// and with an width
		// and an actual width (not empty)
		// else take the normal stylings (its broken or not lazy)
		&[src="#"][width]:not([width=""]) {
			@extend %lazy-img;
		}
	}
}


// for code examples
pre {
	max-width: 600px;

	&.prettyprint {
		margin: 0.2em 0 1em !important;
	}
}

code.mm-dev-code {
	background-color: #eee;
	border: 1px solid transparentize(black, 0.9);
	border-radius: 3px;
	font-family: courier, monospace;
	font-size: 0.9em;
	padding: 0 3px;
}