.o-layout-ves-stage--image-right {
	> .oo-wrapper {
		> .oo-item {
			&--image {
				@include mq(desktop) {
					order: 2;
				}
			}

			&--content {
				@include mq(desktop) {
					order: 1;
				}

				> .oo-stopper {
					@include mq(desktop) {
						transform: translate(0, -100%);
						right: calc(-100% + 20px);
					}

					@include mq(desktop-l) {
						right: 40px;
						transform: none;
					}
				}
			}
		}
	}
}