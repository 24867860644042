.m-basic-toggle {
	.has-search-result {
		background: $color-secondary-9;
	}

	.mm-head {
		@include font-size($font-size-16, 21px);
		border: 1px solid $color-secondary-9;
		width: 100%;
		position: relative;
		padding: 15px 9px 15px 43px;
		cursor: pointer;

		@include mq(tablet) {
			padding: 15px 9px 15px 75px;
		}

		&:first-child {
			border-top-left-radius: 7px;
			border-top-right-radius: 7px;
		}

		&:not(:first-child) {
			border-top: none;
		}

		&:nth-last-child(2) {
			border-bottom-left-radius: 7px;
			border-bottom-right-radius: 7px;
		}

		&::before {
			content: '';
			display: inline-block;
			@include main-icon-arrow-up;
			position: absolute;
			left: 0;
			top: 3px;

			@include mq(tablet) {
				left: 21px;
				top: 2px;
			}
		}

		@include mq(print) {
			box-shadow: inset 0 0 0 1000px $color-secondary-5;
		}

		@include if-parent('.m-basic-toggle--directory') {
			border: none;

			&::before {
				top: -2px;
				@include main-icon-arrow-up;
			}
		}

		@include if-parent('.m-basic-toggle--link') {
			&::before {
				@include main-icon-arrow-up;
			}
		}

		&.mm-opened {
			border-bottom: 1px solid $color-secondary-9;
			position: relative;

			&:before {
				@include main-icon-arrow-down;
			}

			@include if-parent('.m-basic-toggle--directory') {
				&:before {
					content: '';
					display: inline-block;
					@include main-icon-arrow-down;
					position: absolute;
				}
			}

			&:nth-last-child(2) {
				border-radius: 0;
			}

			@include if-parent('.m-basic-toggle--no-toggle-both') {
				background: none;
				cursor: initial;
				padding-left: 10px;

				&::before {
					background: none;
				}
			}

			@include if-parent('.m-basic-toggle--no-toggle-mobile') {
				background: none;
				cursor: initial;
				padding-left: 10px;

				&::before {
					background: none;
				}

				@include mq(tablet) {
					cursor: pointer;
					padding-left: 30px;

					&::before {
						content: '';
						display: inline-block;
						@include main-icon-arrow-down;
						position: absolute;
					}
				}
			}

			@include if-parent('.m-basic-toggle--no-toggle-desktop') {
				cursor: pointer;

				&::before {
					content: '';
					display: inline-block;
					@include main-icon-arrow-down;
					position: absolute;
				}

				@include mq(tablet) {
					background: none;
					cursor: initial;
					padding-left: 10px;

					&::before {
						background: none;
					}
				}
			}
		}


		&.mm-opened + .mm-content {
			height: auto;
			padding: 17px 20px 2px 43px;
			border-left: 1px solid $color-secondary-9;
			border-right: 1px solid $color-secondary-9;
			border-bottom: 1px solid $color-secondary-9;

			@include mq(tablet) {
				padding: 17px 30px 2px 75px;
			}

			&:last-child {
				border-bottom-left-radius: 7px;
				border-bottom-right-radius: 7px;
			}

			&.is-editmode {
				padding-top: 50px;

				@include mq(tablet) {
					padding-top: 17px;
					padding-right: 60px;
				}
			}
		}

		&.js-reset {
			+ .mm-content {
				padding: 0;
				display: block !important;
			}
		}

		p {
			margin: 0;
			text-align: left;
		}

		h2,
		h3 {
			padding: 0;
		}
	}

	> h2,
	> h3 {
		& + .mm-head:nth-child(2) {
			border-top: 1px solid $color-secondary-9;
			border-top-left-radius: 7px;
			border-top-right-radius: 7px;
		}
	}

	.mm-content {
		padding: 0 20px;
		height: 0;
		overflow: hidden;
		position: relative;

		> :first-child {
			&.js-basic-table-wrap {
				padding-top: 30px;
			}
		}

		> .mm-edit {
			display: block;
			@include main-icon-edit;
			position: absolute;
			right: 8px;
			top: 10px;
			width: 44px;
			height: 44px;
		}
	}

	// Exception for Directory
	&--directory {
		background: $color-secondary-19;
		border: none;
		padding: 10px 15px;
		border-radius: 10px;

		.mm-head {
			&::before {
				left: -7px;
				top: -1px;
			}

			&.mm-opened {
				border-bottom: 1px solid $color-secondary-9;
			}
		}
	}

	// Exception for Newsletter
	&--newsletter {
		border-bottom: none;

		.mm-head {
			padding: 10px 75px 10px 30px;
			border-bottom: 1px solid $color-secondary-9;

			&.mm-opened + .mm-content {
				padding: 17px 10px 2px 10px;
			}
		}
	}

	&--infoboxlist,
	&--directory,
	&--link {
		.mm-head {
			padding: 10px 75px 10px 30px;
			border: none;
			border-radius: 0;

			&:first-child {
				border-radius: 0;
			}

			&.mm-opened + .mm-content {
				border: none;
				padding: 17px 10px 2px 10px;
			}
		}
	}

	&--infoboxlist {
		.mm-head {
			&::before {
				display: none;
			}
		}
	}

	&--link {
		.mm-head {
			&::before {
				left: -13px;
				top: -2px;
			}
		}
	}

	&--highlight {
		.mm-head {
			&.mm-opened + .mm-content {
				border: none;
			}
		}
	}
}