/* m-basic-teaser-across--white */
.m-basic-teaser-across--white {
	> article {
		> .mm-article-content {
			background-color: $color-primary-2;
		}

		> .mm-footer {
			background-color: $color-primary-2;
		}
	}
}