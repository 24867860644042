// .m-basic-radius-search--short
.m-basic-radius-search--locksmith {
	display: flex;
	min-width: 305px;
	row-gap: 20px;
	column-gap: 20px;

	.l-form-general & {
		align-items: center;
	}

	& ~ .ll-check {
		.l-form-general & {
			left: 160px;

			@include mq(tablet) {
				left: 180px;
			}
		}
	}

	> .l-form-general {
		max-width: none;

		> .ll-row {
			width: 100%;
			max-width: inherit;
			margin-bottom: 0;
		}
	}
}