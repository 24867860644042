/* o-compare-product */
.o-compare-product {
	background: $color-primary-2;
	opacity: 0.3;
	transition: all 0.5s;


	&.is-init {
		opacity: 1;
	}

	> .swiper {
		.swiper-wrapper {
			.swiper-slide {
				position: relative;
				width: 202px;

				> .oo-compare-product-box {
					background-color: $color-primary-2;
					position: relative;
					top: 0;
					width: 202px;
					margin: 0;
					z-index: 11;
					border-top: 1px solid $color-secondary-3;
					border-right: 1px solid $color-secondary-3;
					border-bottom: 1px solid $color-secondary-3;
					transform: translate3d(0, 0, 0);
					transition: all 0.5s;

					@include mq(desktop) {
						margin-right: 0;
					}

					> a {
						> .oo-figure-icon {
							margin: 0;
							cursor: pointer;
							@include absolute($right: 10px, $top: 10px);
							z-index: 10;
						}
					}

					> .oo-header-box {
						position: relative;
						padding: 7px;
						height: auto;
						border-bottom: 1px solid $color-secondary-3;
						background: $color-primary-2;

						> header {
							position: relative;

							> h3 {
								padding: 0;
								@include arial($font-size-20);
								font-weight: bold;
								max-width: 145px;
							}
						}
					}

					> .oo-img-box {

						> .oo-figure-product {
							width: 200px;
							height: 150px;
							margin: 0;
							position: relative;
							background: $color-secondary-7 url(/assets/img/bg/preview.png) no-repeat center center;

							> a {
								display: block;
								width: 200px;
								height: 150px;

								> .oo-product {
									width: 200px;
									height: 150px;
								}
							}

							> .oo-icon-drag {
								@include absolute($right: 6px, $top: 7px);
								z-index: 10;
								display: none;

								@include mq(tablet) {
									display: block;
								}
							}
						}
					}
				}

				> .oo-test-information {
					background: $color-primary-2;
					border-right: 1px solid $color-secondary-3;
					padding: 10px 9px 1px 12px;
					height: 40px;
				}

				> .oo-compare-toggle {
					max-width: 202px;
					margin-bottom: 1px;

					&:first-child {
						.oo-head {
							border-bottom: none;

							&.oo-opened {
								border-bottom: 2px solid $color-secondary-1;
							}
						}
					}

					&:last-child {
						margin-bottom: 0;
					}

					> .oo-head {
						border-top: 2px solid $color-secondary-1;
						border-bottom: 2px solid $color-secondary-1;
						width: 100%;
						min-height: 45px;
						padding: 10px 9px 10px 24px;
						background-color: transparent;
						cursor: pointer;

						> p {
							margin: 0;
						}

						&.oo-opened + .oo-content {
							height: auto;
							padding: 0;
						}
					}

					> .oo-opinion-information {
						background: $color-secondary-4;
						border-right: 1px solid $color-secondary-3;
						height: 40px;
						padding: 10px 9px 1px 12px;
					}

					> .oo-content {

						> .oo-list {

							> .oo-head {
								min-height: 38px;
								padding: 7px 23px 8px 23px;
								background: $color-primary-2;

								> p {
									margin: 0;
									position: relative;
									z-index: 10;
								}

								&--dark {
									background: $color-secondary-3;
									color: $color-primary-2;
								}
							}

							> .oo-result {
								min-height: 43px;
								padding: 9px 9px 7px 9px;
								border-left: 1px solid $color-secondary-3;
								border-top: 1px solid $color-secondary-3;
								border-bottom: 1px solid $color-secondary-3;

								> p {
									margin: 0;
									max-width: 160px;
								}

								> .h-space-s {
									margin-bottom: 0;
								}
							}

							&--rating {
								> .oo-result {
									padding: 9px 9px 7px 42px;

									&--contra {
										> p {
											&:before {
												content: '–';
												background: $color-rating-5;
											}
										}
									}

									&--pro {
										> p {
											&:before {
												content: '+';
												background: $color-rating-1;
											}
										}
									}

									> p {
										&:before {
											width: 21px;
											height: 21px;
											color: #fff;
											font-weight: bold;
											text-align: center;
											vertical-align: middle;
											@include absolute($left: 10px, $top: 10px);
										}
									}
								}
							}
						}
					}

					.js-compare-content--last {
						border-left: 1px solid $color-secondary-3;

						> .oo-content {
							border-bottom: 1px solid $color-secondary-3;

							&:last-child {
								border-bottom: none;
							}

							> .oo-list {

								> .oo-result {
									min-height: 43px;
									padding: 9px 9px 7px 9px;
									border: none;
									position: relative;

									> p {
										margin: 0;
										max-width: 160px;
									}

									> .h-space-s {
										margin-bottom: 0;
									}

									&:last-child {
										border-bottom: none
									}
								}

								&--rating {
									> .oo-result {
										padding: 9px 9px 7px 42px;

										&--contra {
											> p {
												&:before {
													content: '–';
													background: $color-rating-5;
												}
											}
										}

										&--pro {
											> p {
												&:before {
													content: '+';
													background: $color-rating-1;
												}
											}
										}

										> p {
											&:before {
												width: 21px;
												height: 21px;
												color: #fff;
												font-weight: bold;
												text-align: center;
												vertical-align: middle;
												@include absolute($left: 10px, $top: 10px);
											}
										}
									}
								}
							}
						}
					}

					&--ratings {
						border-bottom: 1px solid $color-secondary-3;

						> .oo-content {

							> .oo-list--rating {

								> .oo-result {
									border-top: none;
									border-bottom: none;
								}
							}
						}
					}
				}

				> .oo-compare-notoggle {
					max-width: 202px;
					margin-bottom: 1px;

					&:first-child {
						.oo-head {
							border-bottom: none;
						}
					}

					&:last-child {
						margin-bottom: 0;
					}

					> .oo-content {

						> .oo-list {

							> .oo-head {
								min-height: 38px;
								padding: 7px 23px 8px 23px;
								background: $color-primary-2;
								border-top: 1px solid $color-secondary-3;

								> p {
									margin: 0;
									position: relative;
									z-index: 10;
								}

								&--dark {
									background: $color-secondary-3;
									color: $color-primary-2;
								}
							}

							> .oo-result {
								min-height: 43px;
								padding: 9px 9px 7px 9px;
								border-left: 1px solid $color-secondary-3;
								border-top: 1px solid $color-secondary-3;
								border-bottom: 1px solid $color-secondary-3;

								> p {
									margin: 0;
									max-width: 160px;
								}

								> .h-space-s {
									margin-bottom: 0;
								}
							}
						}
					}

					.js-compare-content--last {
						border-left: 1px solid $color-secondary-3;

						> .oo-content {
							border-bottom: 1px solid $color-secondary-3;

							&:last-child {
								border-bottom: none;
							}

							> .oo-list {

								> .oo-result {
									min-height: 43px;
									padding: 9px 9px 7px 9px;
									border: none;
									position: relative;

									> p {
										margin: 0;
										max-width: 160px;
									}

									> .h-space-s {
										margin-bottom: 0;
									}

									&:last-child {
										border-bottom: none
									}
								}

								&--rating {
									> .oo-result {
										padding: 9px 9px 7px 42px;

										&--contra {
											> p {
												&:before {
													content: '–';
													background: $color-rating-5;
												}
											}
										}

										&--pro {
											> p {
												&:before {
													content: '+';
													background: $color-rating-1;
												}
											}
										}

										> p {
											&:before {
												width: 21px;
												height: 21px;
												color: #fff;
												font-weight: bold;
												text-align: center;
												vertical-align: middle;
												@include absolute($left: 10px, $top: 10px);
											}
										}
									}
								}
							}
						}
					}
				}

				&:nth-child(odd) {
					> .oo-compare-product-box {
						background-color: $color-secondary-10;

						> .oo-header-box {
							background-color: $color-secondary-10;
						}
					}

					> .oo-test-information {
						background-color: $color-secondary-10;
					}

					> .oo-compare-toggle,
					> .oo-compare-notoggle {
						> .oo-content {
							> .oo-list {
								> .oo-result {
									background-color: $color-secondary-10;
								}
							}
						}
					}
				}

				&:nth-child(even) {

					> .oo-compare-toggle,
					> .oo-compare-notoggle {
						> .oo-content {
							> .oo-list {
								> .oo-result {
									background-color: $color-primary-2;
								}
							}
						}
					}
				}

				&:first-child {
					> .oo-compare-product-box {
						border-left: 1px solid $color-secondary-3 !important;
					}

					> .oo-test-information {
						border-left: 1px solid $color-secondary-3 !important;
					}

					> .oo-compare-toggle,
					> .oo-compare-notoggle {

						> .oo-head {
							border-left: 2px solid $color-secondary-1;
							position: relative;

							&:before {
								@include milo-bold();

								.h-roboto & {
									font-family: 'Roboto Bold', sans-serif;
								}
							}

							&:first-child {
								&::after {
									content: '';
									@include main-icon-link;
									position: absolute;
									top: 0;
									left: -10px;
								}

								&.oo-opened {
									&::after {
										@include main-icon-arrow-drop-down;
									}
								}
							}

							&--data {
								&:before {
									content: attr(data-head);
								}
							}

							&--result {
								&:before {
									content: attr(data-head);
								}
							}

							&--information {
								&:before {
									content: attr(data-head);
								}
							}

							> p {
								position: relative;
								z-index: 10;
							}
						}

						> .oo-opinion-information {
							> a {
								> p {
									&:before {
										content: attr(data-head);
										@include milo-bold();

										.h-roboto & {
											font-family: 'Roboto Bold', sans-serif;
										}
									}
								}
							}
						}

						> .oo-content {
							> .oo-list {
								> .oo-head {
									border-left: 1px solid $color-secondary-3;

									&:before {
										content: attr(data-head);
										@include milo-bold();

										.h-roboto & {
											font-family: 'Roboto Bold', sans-serif;
										}
									}
								}
							}
						}
					}
				}

				&:last-child {

					> .oo-compare-toggle,
					> .oo-compare-notoggle {
						> .oo-head {
							border-right: 2px solid $color-secondary-1;
						}

						> .oo-content {
							> .oo-list {
								> .oo-head {
									border-right: 1px solid $color-secondary-3;
								}

								> .oo-result {
									border-right: 1px solid $color-secondary-3;
								}
							}
						}

						.js-compare-content--last {
							border-right: 1px solid $color-secondary-3;
						}
					}
				}

				&:nth-child(3n + 1) {

					> .oo-compare-toggle,
					> .oo-compare-notoggle {
						> .oo-content {
							> .oo-list {
								> .oo-result {
									background: $color-table-compare-1;
								}
							}
						}
					}

					> .oo-compare-toggle--ratings {
						background: transparent;
					}
				}

				&:nth-child(3n + 2) {

					> .oo-compare-toggle,
					> .oo-compare-notoggle {
						> .oo-content {
							> .oo-list {
								> .oo-result {
									background: $color-table-compare-2;
								}
							}
						}
					}

					> .oo-compare-toggle--ratings {
						background: transparent;
					}
				}

				&:nth-child(3n) {

					> .oo-compare-toggle,
					> .oo-compare-notoggle {
						> .oo-content {
							> .oo-list {
								> .oo-result {
									background: $color-table-compare-3;
								}
							}
						}
					}

					> .oo-compare-toggle--ratings {
						background: transparent;
					}
				}
			}
		}

		.swiper-button-next,
		.swiper-button-prev {
			transition: all 0.5s;
		}
	}
}