/* o-basic-banderole--rating */
.o-basic-banderole--rating {
	padding-bottom: 0;
	background: $color-secondary-8;

	.oo-headline {
		padding-bottom: 0;
		color: $color-primary-3;
	}

}

/* o-basic-banderole--ratingEkomi */
.o-basic-banderole--ratingEkomi {
	padding-top: 30px;
	background: $color-secondary-8;

	.oo-headline {
		background: url("/dev/img/dummy/aks-refresh/ekomi.png") no-repeat left 20px center;
		padding-top: 15px;
		padding-bottom: 15px;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		padding-left: 90px;
		padding-right: 15px;

		@include if-parent('.o-basic-banderole--ratingEkomi.is-center') {
			background-position: left center;
			padding-left: 70px;
		}

		@include mq('tablet') {
			flex-direction: row;
		}

		> .oo-main-rating {
			display: flex;
			align-items: center;

			@include mq('tablet') {
				@include milo-regular($font-size-20);
			}

			> .oo-star {
				margin-right: 2px;

				> img {
					display: block;

					@include mq('tablet') {
						height: 35px;
					}
				}
			}

			> .oo-counter {
				margin-left: 8px;
			}
		}
	}

	> .mm-btn-box {
		display: flex;
		padding: 0 15px;
		justify-content: space-between;
		flex-direction: column-reverse;
		max-width: 898px;
		margin: 0 auto 15px;

		@include mq('tablet') {
			flex-direction: row;
		}

		> div {
			margin-bottom: 5px;

			@include mq('tablet') {
				margin-bottom: 0;
			}
		}
	}

}