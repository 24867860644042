/* m-basic-teaser-wrapper */
.m-basic-teaser-wrapper--calculator {
	.m-wrapper {
		text-align: center;
		max-width: 525px;

		.mm-teaser-headline {
			max-width: none;
			font-size: 16px;
		}
	}
}