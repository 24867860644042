/* m-my-adac-dashboard-box */
.m-my-adac-dashboard-box-banner {
	width: 100%;
	height: auto;
	padding: 20px;
	background: $color-primary-1;

	.l-outer--my-adac & {
		margin: 0 0 24px 0;
	}

	> .mm-wrapper {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		margin: 0 auto;
		max-width: 100%;

		@include mq(tablet) {
			max-width: 75%;
		}

		> .mm-headline {
			@include milo-bold(16px);
			text-align: center;
			margin-bottom: 10px;
		}

		> .mm-text {
			text-align: center;
		}
	}
}