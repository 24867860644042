/* m-basic-keywordfilter--button */
.m-basic-keywordfilter--location {
	padding-top: 0;
	width: 100%;

	.l-form .ll-fieldset &,
	.l-form-general .ll-fieldset & {
		margin-left: 0;
		margin-bottom: 0;
	}

	.mm-location {
		display: block;
		position: absolute;
		top: -5px;
		left: -12px;
		@include main-icon-near-me;
		cursor: pointer;

		.l-form-general & {
			top: 3px;
			left: -15px;
		}
	}

	@include mq(tablet) {
		width: auto;
	}

	.mm-keywordHeadline {
		padding-bottom: 5px;
	}

	.mm-searchbar {
		font-size: 0;
		margin-bottom: 10px;
		margin-left: 30px;
		width: calc(100% - 152px);
		float: left;

		@include mq(tablet) {
			margin-bottom: 0;
			width: 350px;
			margin-right: 0;
		}

		.l-form-general & {
			margin-bottom: 0;
			width: calc(100% - 157px);

			@include mq(tablet) {
				width: 350px;
			}
		}

		.ll-multirow--search & {
			width: calc(100% - 30px);

			@include mq(tablet) {
				width: 350px;
			}
		}

		> button {
			top: 4px;
		}
	}

	.mm-select-wrapper {
		> label {
			float: left;
			margin-right: 10px;
			line-height: 30px
		}
	}

	.mm-controller {
		display: block;
		position: relative;
		float: left;
		width: 122px;
		height: 29px;
		margin-top: 0;
		vertical-align: top;
		z-index: 10;

		> button {
			position: relative;
			padding: 5px 8px 4px 20px;
			text-align: left;
			background-color: $color-primary-2;

			.l-form-general & {
				padding-top: 19px;
			}

			&:before {
				content: '';
				@include main-icon-link;
				position: absolute;
				top: 8px;
				left: -12px;

				@include if-parent('button.is-active') {
					@include main-icon-arrow-drop-down;
					top: 8px;
					left: -12px;
				}
			}

			&.is-active {
				width: 122px;
				padding-top: 4px;
				padding-left: 19px;
				background-color: $color-primary-2;
				border-top: 1px solid $color-secondary-4;
				border-right: 1px solid $color-secondary-4;

				.l-form-general & {
					height: 61px;
					left: 5px;
					padding-top: 18px;
					border-top: 1px solid $color-formfield-border-standard;
					border-left: 1px solid $color-formfield-border-standard;
					border-right: 1px solid $color-formfield-border-standard;
					border-bottom: 1px solid $color-primary-2;
					border-radius: 4px 4px 0 0;
				}
			}

			&:focus {
				outline: none;
			}
		}
	}

	.mm-filter {
		display: none;
		clear: both;
		position: relative;
		top: -1px;
		width: 100%;
		max-width: 100%;
		padding: 10px 20px;
		background: $color-primary-2;
		z-index: 9;
		border: 1px solid $color-secondary-4;

		.l-form-general & {
			top: 4px;
			border: 1px solid $color-formfield-border-standard;
			border-radius: 4px 0 4px 4px;

			@include mq(tablet) {
				top: 5px
			}
		}

		@include mq(tablet) {
			.l-form-general & {
				border-radius: 4px;
			}
		}

		&--columns {
			display: flex;
			flex-flow: column nowrap;

			@include mq(tablet) {
				flex-flow: row nowrap;
			}
		}

		.mm-filter-col {
			position: relative;
			padding: 20px 0;
			margin: 0;

			@include mq(tablet) {
				padding-right: 10px;
				padding-top: 0;
				width: 26%;
			}

			&--first {

				padding-top: 0;

				@include mq(tablet) {
					padding-left: 0;
				}
			}

			&--last {
				padding-bottom: 0;

				@include mq(tablet) {
					padding-right: 0;
					padding-left: 0;
					width: auto;
				}
			}

			&--columnborder {
				border-bottom: 1px solid $color-secondary-5;

				@include mq(tablet) {
					border-left: 1px solid $color-secondary-5;
					border-bottom: 0;
				}

				&.mm-filter-col--first {
					border-left: 0;
				}

				&.mm-filter-col--last {
					border-bottom: 0;
				}
			}
		}

		> .mm-filter-col-wrapper {
			display: flex;
			flex-flow: column nowrap;
			width: 100%;

			@include mq(tablet) {
				width: 50%;
			}

			@include mq(desktop) {
				flex-flow: row wrap;
				width: 70%;
			}

			&:first-child {
				@include mq(desktop) {
					width: 30%;
				}
			}

			> .mm-filter-col {
				padding: 0;
				margin-bottom: 9px;

				&--first {
					order: -1;

					.mm-col-head--spacer {
						@include mq(tablet) {
							display: inline-block;
							height: 20px;
						}
					}
				}

				&--1 {
					order: 3;

					.mm-col-head--spacer {
						@include mq(desktop) {
							display: inline-block;
							height: 20px;
						}
					}
				}

				&--2 {
					order: 2;
				}

				&--last {
					order: 99;
				}

				&:only-child {
					@include mq(desktop) {
						width: auto;
					}
				}

				@include mq(desktop) {
					order: 0;
					width: 50%;
				}
			}
		}

		li {
			@extend %cf;
			margin: 0 0 9px 0;

			&:last-child {
				margin-bottom: 0;
			}

			&.mm-separator ~ .mm-separator {
				padding-bottom: 4px;
				border-bottom: 1px solid $color-secondary-5;
			}

			&.mm-child {
				margin-left: 25px;
			}

			> .mm-col-head {
				display: inline-block;
				width: 100%;
				margin-bottom: 5px;
				@include milo-bold();

				&--spacer {
					display: none;
					margin: 0;
				}
			}

			> .mm-filtergroup {
				@extend %cf;
				padding: 0;
				border-bottom: 0;


				> .mm-filtergroup-head {
					padding-bottom: 5px;
					font-weight: normal;

					@include mq(tablet) {
						display: flex;
						width: 200px;
					}
				}

				// if filter-group is followed by another elem, e.g. mm-filter-button;
				&:not(:last-child) {
					margin-bottom: 10px;
				}
			}

			.mm-filter-button {
				float: right;

				@include mq(tablet) {
					float: none;
				}
			}
		}
	}

	& + .a-basic-btn {
		margin-top: 10px;

		@include mq(tablet) {
			margin-top: initial;
		}
	}
}