// m-basic-member-puzzle
.m-basic-member-puzzle {
	> .mm-member-gallery-wrapper {
		margin-right: -10px;
		position: relative;

		> .swiper {
			&.js-members {
				margin-bottom: 20px;

				&.is-inactive {
					cursor: auto;
					max-height: none;
				}

				> .swiper-wrapper {
					padding: 20px 0;

					@include if-parent('.swiper.is-inactive') {
						display: flex;
						flex-wrap: wrap;
					}

					> .swiper-slide {
						padding: 0;
						margin: 0;
						cursor: auto;
						max-width: 135px;
						flex-basis: 135px;
						border: 2px solid transparent;

						@include mq(tablet) {
							max-width: 161px;
							flex-basis: 161px;
						}

						&.js-puzzle-content {
							cursor: pointer;
						}

						@include if-parent('.swiper.is-inactive') {
							margin: 0 10px 10px 0;
						}

						&:focus-visible {
							outline: none;

							.is-keyboard-focus & {
								border: 2px solid $color-primary-3;
							}
						}

						> img {
							max-width: 100%;
							height: auto;
						}

						> figcaption {
							padding: 5px 8px 5px 0;
							position: relative;

							@include if-parent('.swiper-slide.js-puzzle-content') {
								padding: 5px 8px 5px 10px;

								&:before {
									content: '';
									display: inline-block;
									@include main-icon-link;
									position: absolute;
									left: -18px;
									top: -7px;
								}
							}

							> .mm-data {
								> .mm-name {
									font-weight: bold;
								}
							}
						}

						.mm-ratio-container {
							display: inline-block;
							position: relative;

							&:not(.is-lazyloaded) {
								background: $color-secondary-6;

								&::before {
									animation: swiper-preloader-spin 1s steps(12, end) infinite;
									display: block;
									z-index: 100;
									position: absolute;
									top: calc(50% - 34px);
									left: calc(50% - 34px);
									content: '';
									opacity: 1;
									transition: opacity 300ms;
									@include main-icon-refresh-48px;
									width: 68px;
									height: 68px;
								}
							}

							> img {
								height: auto;
								max-width: 100%;
							}
						}
					}
				}

				> .is-disabled {
					display: none;
				}
			}
		}

		.swiper-button-next,
		.swiper-button-prev {
			&:focus-within {
				outline: none;

				.is-keyboard-focus & {
					outline: 2px solid $color-primary-3;
				}
			}
		}
	}

	> .mm-member-content-wrapper {
		position: relative;

		&.is-inactive {
			display: none;
		}

		> .swiper-wrapper {
			@include mq(tablet) {
				min-height: 302px;
			}

			.mm-aside {
				margin-bottom: 20px;
			}
		}

		> .mm-layer-buttons {
			padding: 0;
			margin: 0 0 0 auto;
			width: 100px;
			height: 36px;
			list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);

			> li {
				display: inline-block !important;

				> button {
					padding: 0;
					outline: none;
					background: none;
					border: none;
					position: relative;

					&:before {
						content: '';
						position: absolute;
						top: -6px;
						left: -7px;
					}

					&.mm-prev {
						width: 30px;
						height: 30px;

						&:before {
							@include main-icon-arrow-left-black;
						}
					}

					&.mm-next {
						width: 30px;
						height: 30px;

						&:before {
							@include main-icon-arrow-right-black;
						}
					}

					&.mm-close {
						width: 30px;
						height: 30px;

						&:before {
							@include main-icon-close;
						}
					}

					&:focus {
						.is-keyboard-focus & {
							outline: 2px solid $color-primary-3;
						}
					}
				}
			}
		}
	}

	&--location {
		.mm-content {
			h3 {
				width: 100%;
				max-width: 100%;
			}
		}
	}

	&--ruf {
		margin-bottom: 0;

		> .mm-member-gallery-wrapper {
			> .swiper {
				margin-bottom: 0;

				> .swiper-wrapper {
					padding: 20px 0 0 0;

					> a {
						display: block;
						margin: 0 10px 10px 0;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}

						> .swiper-slide {
							> img {
								max-width: 155px;

								@include mq(tablet) {
									max-width: 100%;
								}
							}

							> figcaption {
								padding: 5px 8px 5px 0;

								> .mm-data {
									> .mm-name {
										@include milo-bold();
										position: relative;
										padding: 0 0 0 12px;

										&:before {
											content: '';
											display: inline-block;
											@include main-icon-arrow-right-black;
											position: absolute;
											left: 4px;
											top: 50%;
											transform: translate(-50%, -50%);
										}

										.h-roboto & {
											font-family: 'Roboto Bold', sans-serif;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}