/* m-layout-header-nav--brs */
.m-layout-header-nav--brs {

	> nav {

		> ul {

			@include mq(tablet) {
				display: none;
			}
		}
	}
}