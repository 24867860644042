// .is-print-section
// helper class for the <body> (!!!) to hide every node except the print section
.is-print-hidden {

	// mq here on purpose cause all styles apply only for print
	@include mq(print) {

		visibility: hidden !important;

		// set all child elems to display.none...
		& .l-main-content *:not(.is-print-visible) {
			display: none !important;
		}

		// ...except for the print section
		& .is-print-visible {
			visibility: visible !important;
		}
	}
}