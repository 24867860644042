/*.m-my-adac-vehicle-image*/
.m-my-adac-vehicle-image {

	.l-outer--my-adac & {
		margin: 0 0 24px 0;
	}

	.a-basic-link & {
		width: 120px;
	}

	> .mm-wrapper {

		> .mm-license-plate {
			margin: 0 0 25px 0;

			@include mq(tablet) {
				margin: 0 0 40px 0;
			}

			&.h-space-none {
				margin: 0;
			}

			> .mm-license-wrapper {

				> .mm-license {
					display: flex;
					justify-content: center;

					.a-basic-link & {
						display: block;
					}

					> .mm-img-wrapper {
						width: 100%;
						height: 100%;
						position: relative;
						display: flex;

						&--motorcycle {
							background: url('/assets/img/bg/my-adac-vehicles/licenseplate-motorbike.jpg') no-repeat center center;
							background-size: 100% 100%;
							width: 60px;
							height: 59px;
							flex-flow: column nowrap;

							@include if-parent('.m-my-adac-vehicle-image--foreign') {
								background: white no-repeat center center;
								border: 2px solid black;
								border-radius: 5px;
							}
						}

						&--car {
							background: url('/assets/img/bg/my-adac-vehicles/licenseplate-car.png') no-repeat center center;
							background-size: 100% 100%;
							width: 156px;
							height: 37px;
							flex-flow: row nowrap;
							padding: 0 10px 0 24px;

							@include if-parent('.m-my-adac-vehicle-image--foreign') {
								background: white no-repeat center center;
								border: 2px solid black;
								border-radius: 5px;
							}

							.a-basic-link & {
								height: 28px;
								width: auto;
							}
						}

						> .mm-car-wrapper {
							display: flex;
							flex-flow: row nowrap;
							justify-content: center;
							align-content: center;
							width: 100%;
							@include milo-bold(18px);

							.a-basic-link & {
								@include milo-bold(14px);
							}

							> .mm-left {
								align-self: center;
							}

							> .mm-hyphen {
								align-self: center;
							}

							> .mm-right {
								align-self: center;
							}
						}

						> .mm-motorcycle-wrapper {
							display: flex;
							flex-flow: column nowrap;
							justify-content: center;
							align-items: center;
							width: 100%;
							height: 100%;
							@include milo-bold(16px);

							> .mm-top {
								align-self: center;
								padding: 0 0 0 6px;
							}

							> .mm-bottom {
								align-self: center;

								> .mm-right {
									white-space: nowrap;
								}
							}
						}

						> .mm-moped-wrapper {
							@include milo-bold(16px);
							position: relative;
							text-align: center;
							padding: 1px 2px;
							border-radius: 5px;
							border: 2px solid $color-secondary-18;
							outline: 1px solid $color-secondary-9;
							background-color: $color-primary-2;
						}
					}
				}
			}
		}

		> .mm-default {

			> .mm-circle-wrapper {
				display: flex;
				justify-content: center;

				> .mm-circle {
					height: 130px;
					width: 130px;
					border-radius: 50%;
					background: $color-secondary-8;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					outline: none;

					.m-my-adac-dashboard-box-showcase & {
						background: $color-primary-2;
					}

					> .mm-circle-icon {
						width: 30px;
						height: 30px;
						position: relative;

						&:before {
							content: '';
							position: absolute;
							top: -4px;
							left: -4px;
						}

						.m-my-adac-dashboard-box-showcase & {
							width: 35px;
							height: 33px;

							&:before {
								@include main-icon-add-black-big;
								transform: scale(2.2);
							}
						}
					}
				}
			}
		}

		> .mm-vehicle-image {
			display: flex;
			justify-content: center;
			width: 100%;

			> .mm-img-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 40%;
				height: auto;
				min-width: 130px;
				max-width: 40%;
				overflow: hidden;
				border-radius: 50%;

				@include if-parent('.m-my-adac-vehicle-image--no-radius') {
					border-radius: 0;
					width: 50%;
					max-width: 50%;
				}

				.m-my-adac-membership-content & {
					width: 32%;
				}

				> img {
					width: auto;
					min-height: 130px;
					max-height: 130px;

					.m-my-adac-membership-content & {
						width: 100%;
						min-height: auto;
						max-height: 100%;
					}
				}
			}

		}
	}

}