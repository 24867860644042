//m-ves-rs-contentbox--damagedeclaration
.m-ves-rs-contentbox--damagedeclaration {

	&.is-plain-wrapper {
		margin: 0;
		padding: 0;

		> h1 {
			text-align: center;
		}
	}

	h2 {
		position: relative;
		margin: 0;
		text-align: center;

		@include mq(tablet) {
			margin: 0 auto;
		}
	}

	label {
		> small {
			@include milo-regular($font-size-14);
			vertical-align: text-top;
		}
	}

	> .mm-tile-container {

		position: relative;
		transition: padding $form-animation ease;

		// tile validation error styles
		&.is-error.is-check {

			@include mq(desktop) {
				padding-top: 50px;
			}

			> .ll-error-msg,
			> .mm-error-msg {
				max-height: 300px;
				margin-bottom: 15px;
				padding: 5px 34px 5px 20px;
				opacity: 1;
			}

			> .ll-check,
			> .mm-check {
				opacity: 1;
				visibility: visible;

				&:before {
					content: '';
					position: absolute;
					top: -10px;
					left: -10px;
					@include main-icon-close-red;
				}
			}

			> .mm-subheadline {

				position: relative;

				> h2 {
					margin-bottom: 0;
				}

				> .ll-error-msg,
				> .mm-error-msg {
					opacity: 1;
					max-height: 300px;
					padding: 5px 30px 5px 20px;
					margin-bottom: 15px;

					> a {
						color: $color-action-alert;
					}
				}

				> .ll-check,
				> .mm-check {
					opacity: 1;
					visibility: visible;
					position: relative;

					&:before {
						content: '';
						position: absolute;
						top: -10px;
						left: -10px;
						@include main-icon-close-red;
					}
				}
			}
		}

		// special case for cost upload tiles
		&--col-1-5 {

			flex-flow: column nowrap;
			margin: 0;

			@include mq(desktop) {
				flex-flow: row nowrap;
				margin: 5px -10px 30px -10px;
			}

			> .mm-tile {

				width: 130px;
				margin: 0 auto 40px auto;

				@include mq(desktop) {
					width: auto;
					margin: 0 17px;
				}

				&.mm-tile-empty {
					display: none;
				}
			}

			> .mm-row {
				display: flex;
				flex-flow: column wrap;
				position: relative;
				margin-bottom: 40px;
				padding: 20px 0 15px calc(50% - 150px);
				border-top: 1px solid $color-secondary-9;
				border-bottom: 1px solid $color-secondary-9;

				@include mq(desktop) {
					flex-flow: row wrap;
					margin: 0;
					padding-top: 15px;
					padding-left: 0;
					max-width: none;
					border-top: 0;
				}

				&:first-child {
					border-top: 1px solid $color-secondary-9;
				}

				&:last-child,
				&.is-last {
					margin-bottom: 0;
				}

				// general validation styles
				.ll-check,
				.mm-check {
					@include absolute($top: 0, $right: 5px);
					width: 22px;
					height: 22px;
					margin: 3px 0 0 0;
					opacity: 0;
					visibility: hidden;
					transition: opacity $form-animation ease;
				}

				.ll-error-msg,
				.mm-error-msg {
					width: 100%;
					max-height: 0;
					padding: 0;
					color: $color-action-alert;
					opacity: 0;
					overflow: hidden;
					transition: max-height $form-animation ease, opacity $form-animation ease;
				}

				// .mm-row validation styles
				> .ll-check,
				> .mm-check {
					top: 16px;
					left: calc(50% + 140px);
					right: auto;

					@include if-parent('.mm-row.is-check.is-error') {
						opacity: 1;
						visibility: visible;
						position: absolute;

						&:before {
							content: '';
							position: absolute;
							top: -10px;
							left: -10px;
							@include main-icon-close-red;
						}
					}
				}

				> .ll-error-msg,
				> .mm-error-msg {
					text-align: left;
					max-width: 285px;

					@include if-parent('.mm-row.is-check.is-error') {
						opacity: 1;
						max-height: 300px;
						padding-bottom: 5px;
					}
				}

				&--center {
					position: relative;
					justify-content: center;
					border: 0;

					&:last-child {
						margin-bottom: 40px;
					}

					&.is-check {
						&.is-valid {
							&:after {
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								@include main-icon-check-green;
							}
						}

						&.is-error {
							&:after {
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								@include main-icon-close-red;
							}
						}
					}

					// Exception m-basic-upload
					> .m-basic-upload {
						position: relative;
						display: flex;
						flex-flow: row nowrap;
						justify-content: flex-start;
						width: auto;

						> div {
							height: 0;

							&.ll-check,
							&.mm-check {
								width: 0;

								&.is-check {
									width: 22px;
									height: 22px;
								}
							}

							&.ll-error-msg,
							&.mm-error-msg {
								opacity: 0;
							}
						}

						> .m-data-wrap {

							margin-left: 15px;

							> .js-data {
								width: 195px;
								max-width: 195px;
								min-width: 195px;

								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}

							> .js-filesize {
								@include absolute($top: 5px, $right: -80px);
								text-align: right;

								@include mq(desktop) {
									right: -75px;
								}
							}
						}

					}
				}

				&--summary {
					position: relative;
					margin-top: 5px;
					border-top: 1px solid $color-secondary-9;
					border-bottom: 0;
					padding-left: 0;
					padding-right: 0;
				}

				> .mm-row-content {
					display: flex;
					flex-flow: row wrap;
					justify-content: flex-start;
					max-width: 215px;
					margin-bottom: 8px;

					@include mq(desktop) {
						flex-flow: row nowrap;
						width: calc(100% - 66%);
						max-width: none;
						margin-bottom: 0;
					}

					&.js-clone-source-costdeclaration {
						flex-wrap: wrap;
					}

					// Exception ll-col-label
					.ll-col-label {
						position: relative;
						padding: 4px 0 0 0;
						width: 100%;

						@include mq(desktop) {
							padding: 4px 0 0 10px;
							width: auto;
							min-width: 215px;
							max-width: 215px;
							overflow: hidden;
						}

						> label {

							@include milo-bold();

							@include mq(desktop) {
								display: block;
								padding-left: 45px;
							}

							.costUploadVersion2 & {
								@include mq(desktop) {
									padding-left: 0;
								}
							}

							> img {
								@include absolute($top: -8px, $left: 0);
								width: 55px;
								height: auto;

								&.is-largeIcon {
									top: -5px;
								}

								.costUploadVersion2 & {
									@include mq(desktop) {
										display: none;
									}
								}
							}
						}
					}

					// Exception a-basic-input-text
					.a-basic-input-text {

						&--s {
							width: 100px;
						}
					}

					// Exception a-basic-input-select
					.a-basic-input-select {

						&--s {
							width: 100px;
						}
					}

					.ll-error-msg,
					.mm-error-msg {
						@include if-parent('.mm-row-content.is-check.is-error') {
							opacity: 1;
							max-height: 300px;
							padding-bottom: 5px;
							overflow: visible;
						}
					}

					.ll-check,
					.mm-check {
						right: -35px;
						margin: 3px 0 0 0;
						position: absolute;

						@include if-parent('.mm-row-content.is-check.is-valid') {
							opacity: 1;
							visibility: visible;

							&:before {
								content: '';
								position: absolute;
								top: -5px;
								left: -10px;
								@include main-icon-check-green;
							}
						}

						@include if-parent('.mm-row-content.is-check.is-error') {
							opacity: 1;
							visibility: visible;

							&:before {
								content: '';
								position: absolute;
								top: -10px;
								left: -10px;
								@include main-icon-close-red;
							}
						}
					}

					&--left {
						position: relative;
						width: 100%;
						max-width: 250px;
						padding-right: 0;

						@include mq(desktop) {
							width: 66%;
							padding-right: 15px;
							margin-bottom: 5px;
						}

						> .ll-col-label {

							display: none;

							@include mq(desktop) {
								display: block;
							}

						}

						> .ll-error-msg,
						> .mm-error-msg {
							text-align: left;
							max-width: 270px;
							padding-right: 20px;
						}

						> .ll-check,
						> .mm-check {
							right: -60px;

							@include if-parent('.mm-row-content--left.is-no-error') {
								display: none;
							}

							@include if-parent('.mm-row-content--left.is-error.is-check') {
								right: 0;
							}
						}
					}

					&--right {
						position: relative;
						flex-wrap: nowrap;
						padding-left: 0;
						margin-bottom: 15px;

						@include mq(desktop) {
							flex-wrap: wrap;
							margin-bottom: 0;
						}

						> .ll-col-data {
							margin-right: 15px;

							// we expect only two data siblings
							& + .ll-col-data {
								margin-right: 0;
							}
						}

						> .js-clone-remove {
							top: auto;
							left: auto;
							right: -34px;
							bottom: 14px;
							z-index: 2;

							@include if-parent('.mm-row.is-check.is-valid') {
								right: -60px;
							}

							@include if-parent('.mm-row-content--right:not(.js-file-stored)') {
								display: none;
							}
						}

						&.is-check {
							> .js-clone-remove {
								right: -32px;
							}
						}
					}

					// cost summary stuff
					&--full {
						width: 100%;
						margin-left: 0;
						justify-content: space-between;
						max-width: none;

						@include mq(desktop) {
							margin-left: 66%;
							justify-content: flex-start;
						}

						&:first-child {
							margin-bottom: 5px;
						}

						> .ll-col-label {
							min-width: 0;
							width: auto;
							padding: 0 5px 0 0;

							@include mq(tablet) {
								padding: 0 35px 0 0;
							}

							> label {
								padding: 0;
							}

						}

						> .ll-col-data {
							@include milo-bold();
						}
					}

					&--half {
						max-width: none;

						@include mq(desktop) {
							width: 425px;
						}

						> .ll-col-label {
							width: 100%;
							min-height: 36px;

							> .m-basic-info-layer {

								display: inline-block;

								.mm-infolayer-icon {
									position: relative;
									top: 5px;
									bottom: auto;
									right: auto;
									margin-left: 5px;
								}

							}
						}

					}
				}
			}
		}

		// tile validation default styles
		> .ll-error-msg,
		> .mm-error-msg {
			width: 100%;
			max-height: 0;
			text-align: center;
			color: $color-action-alert;
			overflow: hidden;
			opacity: 0;
			transition: opacity $form-animation ease, height $form-animation ease;

			> a {
				color: $color-action-alert;
			}

			@include mq(desktop) {
				@include absolute($top: 0, $left: auto);
			}
		}

		> .ll-check,
		> .mm-check {
			@include absolute($top: 0, $right: 10px);
			width: 22px;
			height: 22px;
			margin: 3px 0 0 0;
			opacity: 0;
			visibility: hidden;
			transition: opacity $form-animation ease;
		}

		> .mm-subheadline {
			padding: 0 25px;
			margin: 0;

			> h2 {
				position: relative;
				display: inline-block;
				margin-bottom: 5px;
				left: 50%;
				transform: translateX(-50%);
			}

			> p {
				max-width: 600px;
				margin-left: auto;
				margin-right: auto;
			}

			> .ll-error-msg,
			> .mm-error-msg {
				max-height: 0;
				max-width: calc(100% - 30px);
				padding: 0 30px 0 20px;
				opacity: 0;
				overflow: hidden;
				text-align: center;
				color: $color-action-alert;
				transition: all $form-animation ease;
			}

			> .ll-check,
			> .mm-check {
				@include absolute($bottom: calc(50% - 11px), $right: 5px);
				opacity: 0;
				width: 22px;
				height: 22px;
				margin: 3px 0 0 0;
				visibility: hidden;
				transition: all $form-animation ease;

				@include mq(tablet) {
					top: auto;
					bottom: 18px;
				}
			}
		}

		// tile styles
		> .mm-tile {

			&--reduced {

				> label {
					height: 50px;
					width: 100px;

					> .mm-text-container {
						bottom: 15px;
					}
				}

				& ~ .mm-tile-empty {
					width: 100px;
				}
			}
		}

		// spacing for copy text
		+ div {
			> p {
				@include mq(tablet) {
					padding-top: 40px;
				}
			}
		}
	}

	.mm-upload-container {
		border-top: 1px solid $color-secondary-9;
		border-bottom: 1px solid $color-secondary-9;

		@include mq(desktop) {
			border-top: 0;
			border-bottom: 0;
		}

		&--centered {
			margin: 0 25% 0 25%;
		}

		> h3 {
			padding-top: 20px;

			@include mq(desktop) {
				padding-top: 0;
			}
		}

		> p {
			margin-bottom: 0;
			padding: 15px 0;
			border-bottom: 1px solid $color-secondary-9;

			&.no-border {
				border: 0;
				padding-bottom: 10px;
			}

			&.no-center {
				text-align: left;
			}
		}

		> .mm-row {

			display: flex;
			flex-flow: column wrap;
			position: relative;
			padding: 20px 0 15px calc(50% - 150px);
			margin-bottom: 40px;
			border-top: 1px solid $color-secondary-9;
			border-bottom: 1px solid $color-secondary-9;

			@include mq(desktop) {
				flex-flow: row wrap;
				margin: 0;
				padding-top: 15px;
				padding-left: 0;
				max-width: none;
				border-top: 0;
			}

			@include if-parent('.mm-upload-container.mm-upload-container--rvv') {
				&:first-child:not(.mm-row--summary) {
					border-top: none;
				}
			}

			&:last-child {
				margin-bottom: 0;
			}

			&--center {
				justify-content: center;
				border: 0;
				min-height: 67px;

				&:last-child {
					margin-bottom: 40px;
				}

				// upload validation default styles
				.ll-error-msg,
				.mm-error-msg {
					@include absolute($top: -15px, $left: 0);
					width: 300px;
					text-align: left;
				}
			}

			&--summary {
				position: relative;
				margin-top: 5px;
				border: 0;
				border-top: 1px solid $color-secondary-9;
				padding-left: 0;
				padding-right: 0;
				justify-content: flex-start;

				> .mm-fileSize {
					width: 100%;
					border: 0;
					margin-top: 0;
					margin-right: 15px;
					padding-top: 0;
					padding-right: 0;

					@include mq(desktop) {
						flex: 1 0 70%;
						width: auto;
						max-width: calc(70% - 15px);
					}

					> span.js-fileSizeSum {
						padding-right: 28px;
					}

					> span.js-fileSizeSumUnit {
						@include absolute($top: 0px, $right: 0px);

						@include mq(desktop) {
							margin-right: 0
						}
					}

					> span.filesize-label {
						@include absolute($top: 0px, $left: 0px);
						margin: 0;

						@include mq(desktop) {
							left: 215px
						}
					}
				}

				> .mm-costVal {
					width: 100%;
					padding-bottom: 10px;
					border-bottom: 1px solid $color-secondary-9;

					@include mq(desktop) {
						flex: 1 0 30%;
						width: auto;
						border-bottom: 0;
					}

					> .mm-row-content {
						margin: 0;
					}
				}

				> .mm-fileSize.is-hidden + .mm-costVal {
					@include mq(desktop) {
						margin-left: auto;
						width: calc(100% - 66%);
					}
				}
			}

			.mm-row-content {
				position: relative;
				display: flex;
				flex-flow: row wrap;
				justify-content: flex-start;
				max-width: 215px;
				margin-bottom: 8px;

				@include mq(desktop) {
					flex-wrap: nowrap;
					max-width: none;
					align-content: flex-end;
				}

				&--left {
					position: relative;
					width: 100%;
					padding-right: 0;

					@include mq(desktop) {
						flex: 1 0 70%;
						padding-right: 15px;
						align-items: flex-end;
					}

					> .ll-col-label {

						display: none;
						position: relative;
						padding: 4px 0 0 0;
						width: 100%;

						@include mq(desktop) {
							display: block;
							max-width: 215px;
							padding: 0 0 9px 55px;
						}

						@include if-parent('.mm-row-content--left.is-check.is-error') {
							@include mq(desktop) {
								order: 2;
							}
						}

						> .ll-label {

							@include milo-bold();

							@include mq(desktop) {
								display: inline-block;
								padding-top: 0;
							}

							> img {
								@include absolute($top: -8px, $left: 0);
								width: 55px;
								height: auto;

								&:not(.mm-top) {
									@include mq(desktop) {
										top: 50%;
										transform: translateY(-50%);
										padding-bottom: 9px;
									}
								}
							}
						}
					}

					// mm-row-content--left > .ll-error-msg validation
					> .ll-error-msg,
					> .mm-error-msg {

						@include if-parent('.mm-row-content--left.is-check.is-error') {

							@include mq(desktop) {
								width: 100%;
								max-width: calc(100% - 255px);
								order: 1;
								margin-left: 215px;
							}
						}
					}

					// mm-row-content--left > .ll-check validation
					> .ll-check,
					> .mm-check {

						@include mq(desktop) {
							top: auto;
							bottom: 12px;
							right: 20px;
						}

						// validation error
						@include if-parent('.mm-row-content--left.is-check.is-error') {

							@include mq(desktop) {
								top: 0;
								bottom: auto;
							}
						}
					}

					&.is-check.is-error {

						@include mq(desktop) {
							flex-flow: row wrap;
							margin-bottom: 15px;
						}

						// mm-row-content--left.is-check.is-error + .mm-row-content--right
						& + .mm-row-content--right {

							@include mq(desktop) {
								margin-bottom: 11px;
							}
						}
					}

					&.js-file-stored.js-file-upload + .mm-row-content--right {
						> .js-clone-remove {
							display: block !important;
						}
					}
				}

				&--right {
					position: relative;
					flex-wrap: wrap;
					padding-left: 0;
					margin-bottom: 15px;

					@include mq(desktop) {
						flex: 1 0 30%;
						margin-bottom: 4px;
					}

					> .ll-col-data {

						+ .ll-col-data {
							padding-left: 15px;
						}
					}

					// mm-row-content--right > .ll-error-msg validation
					> .ll-error-msg,
					> .mm-error-msg {

						// validation error
						@include if-parent('.mm-row-content--right.is-check.is-error') {
							@include mq(desktop) {
								max-width: calc(100% - 60px);
							}
						}
					}

					> .js-clone-remove {
						@include absolute($top: 6px, $right: -80px);
						left: auto;
						z-index: 2;

						@include mq(desktop) {
							top: auto;
							bottom: 15px;
							right: 0;
						}

						@include if-parent('.mm-upload-container.mm-upload-container--rvv') {
							@include mq(desktop) {
								left: 305px;
								right: auto;
								top: 8px;
							}
						}

						@include if-parent('.mm-row-content--right.is-check.is-valid') {
							@include mq(desktop) {
								top: auto;
								bottom: 15px;
								right: -30px;
							}
						}

						// hide remove icon if no clone is created ( => src is second last child)
						@include if-parent('.mm-row-content--right:not(.js-clone):not(.js-file-upload.js-file-stored)') {
							display: none;
						}
					}

					&.is-check.is-error {
						@include mq(desktop) {
							flex-flow: row wrap;
						}
					}
				}

				// cost summary stuff
				&--full {
					width: 100%;
					margin-left: 0;
					justify-content: space-between;
					max-width: none;

					@include mq(desktop) {
						margin-left: 66%;
						justify-content: flex-start;
					}

					&:first-child {
						margin-bottom: 5px;
					}

					> .ll-col-label {
						min-width: 0;
						width: auto;
						padding: 0 5px 0 0;

						@include mq(tablet) {
							padding: 0 35px 0 0;
						}

						> label {
							padding: 0;
							@include milo-bold();
						}

					}

					> .ll-col-data {
						@include milo-bold();
					}
				}

				&--half {
					max-width: none;

					@include mq(desktop) {
						width: 425px;
					}

					> .ll-col-label {
						width: 100%;
						align-self: center;
					}

				}

				&--button-wrap {

					display: flex;
					flex-flow: column nowrap;
					width: 100%;
					padding-top: 10px;

					@include mq(tablet) {
						align-items: flex-start;
						width: auto;
					}

					@include mq(desktop) {
						margin-left: 215px;
						padding-top: 0;
					}

					@include if-parent('.mm-upload-container--centered') {
						@include mq(desktop) {
							margin-left: 150px;
						}
					}

					// validation error
					&.is-check.is-error {
						@include mq(desktop) {
							flex-flow: column nowrap;
						}
					}
				}

				> .ll-col-label {

					// exception .m-basic-upload when in ves-rs-contentbox-damagedeclaration cost upload
					& ~ .m-basic-upload {

						// validation error
						@include if-parent('.mm-row-content.is-check.is-error') {
							@include mq(desktop) {
								max-width: calc(100% - 215px);
								order: 3;
							}

						}

						> div {
							@include mq(desktop) {
								height: 38px;
							}
						}
					}
				}

				// mm-row-content > .ll-error-msg validation
				> .ll-error-msg,
				> .mm-error-msg {

					@include if-parent('.mm-row-content.is-check.is-error') {
						opacity: 1;
						max-height: 300px;

						@include mq(desktop) {
							margin-bottom: 5px;
							padding: 5px 0;
						}
					}
				}

				// mm-row-content > .ll-check validation
				> .ll-check,
				> .mm-check {
					@include if-parent('.mm-row-content.is-check.is-error') {
						opacity: 1;
						visibility: visible;

						&:before {
							content: '';
							position: absolute;
							top: -5px;
							left: -10px;
							@include main-icon-close-red;
						}
					}

					@include if-parent('.mm-row-content.is-check.is-valid') {
						opacity: 1;
						visibility: visible;

						&:before {
							content: '';
							position: absolute;
							top: -5px;
							left: -10px;
							@include main-icon-check-green;
						}
					}
				}
			}

			// general mm-row validation styles
			.ll-error-msg,
			.mm-error-msg {
				width: 100%;
				max-width: 0;
				max-height: 0;
				padding: 0;
				color: $color-action-alert;
				text-align: left;
				overflow: hidden;
				opacity: 0;
				transition: max-height $form-animation ease, opacity $form-animation ease;

				@include mq(desktop) {
					text-align: left;
				}

				> a {

					// validation error
					@include if-parent('.mm-row.is-check.is-error') {
						color: $color-action-alert;
					}
				}
			}

			// general mm-row validation styles
			.ll-check,
			.mm-check {
				@include absolute($top: 0, $right: 80px);
				width: 22px;
				height: 22px;
				margin: 3px 0 0 0;
				opacity: 0;
				visibility: hidden;

				@include mq(desktop) {
					right: 0;
				}

				@include if-parent('.mm-row.is-check.is-error') {
					&:before {
						content: '';
						position: absolute;
						top: -10px;
						left: -10px;
						@include main-icon-close-red;
					}
				}

				@include if-parent('.mm-row.is-check.is-valid') {
					&:before {
						content: '';
						position: absolute;
						top: -10px;
						left: -10px;
						@include main-icon-check-green;
					}
				}
			}

			// mm-row > .ll-error-msg validation styles
			> .ll-error-msg,
			> .mm-error-msg {
				margin-bottom: 5px;
				padding: 10px 0 5px;

				@include if-parent('.mm-row.is-check.is-error') {
					padding-right: 60px;
					opacity: 1;
					max-width: 215px;
					max-height: 300px;

					@include mq(desktop) {
						max-width: none;
						margin-left: 215px;
						margin-bottom: 5px;
						padding: 5px 25px 5px 0;
					}
				}
			}

			// mm-row > .ll-check validation styles
			> .ll-check,
			> .mm-check {
				display: none;
				top: 18px;

				// validation error
				@include if-parent('.mm-row.is-check.is-error') {
					display: block;
					opacity: 1;
					visibility: visible;
				}

				@include if-parent('.mm-row.is-check.is-valid') {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	.mm-tile-container--col-1-5 + .mm-upload-container {
		border: 0;
	}

	.mm-fileSize {
		position: relative;
		width: 100%;
		margin-top: 5px;
		padding: 10px 0;
		border-top: 1px solid $color-secondary-9;
		border-bottom: 1px solid $color-secondary-9;
		text-align: right;
		@include milo-bold();

		@include mq(desktop) {
			border-bottom: 0;
			padding: 10px 35px 0 0;
		}

		&.is-error {
			color: $color-action-alert;
		}

		> span.js-fileSizeSumUnit {
			@include mq(desktop) {
				margin-right: 282px;
			}
		}

		> span.filesize-label {
			@include absolute($top: 10px, $left: 0px);
			margin: 0;

			@include mq(desktop) {
				position: static;
				margin: 0 70px 0 0;
			}
		}

		&.is-add-cost {
			display: none;

			@include mq(desktop) {
				display: block;
			}

			> span.filesize-label {
				@include mq(desktop) {
					margin-right: 0;
					position: absolute;
					left: 215px;
				}
			}
		}

		// exception for police file upload
		&.is-police-fileSize {
			padding-top: 20px;
			border-bottom: 0;

			// double border
			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 5px;
				width: 100%;
				height: 1px;
				background-color: $color-secondary-9;
			}

			> span.filesize-label {
				top: 20px;
				left: 0;

				@include mq(tablet) {
					position: absolute;
					margin-right: 0;
					left: calc(50% - 150px);
				}

				@include mq(desktop) {
					left: 297px;
				}
			}

			> span.js-fileSizeSumUnit {
				@include mq(tablet) {
					margin-right: calc(50% - 160px);
				}

				@include mq(desktop) {
					margin-right: 257px;
				}
			}
		}
	}

	> .mm-additional-cost {

		margin-top: 0;

		> p:last-of-type {
			margin-bottom: 30px;

			@include mq(desktop) {
				margin-bottom: 15px;
			}
		}

		> .mm-upload-container {
			border: 0;

			> .mm-row {
				margin-bottom: 0;
				border: 0;

				@include mq(desktop) {
					padding-top: 12px;
					padding-left: 0;
				}

				&:not(:first-child) {
					border-top: 0;
				}

				> .mm-row {
					> .mm-row-content {
						@include mq(desktop) {
							margin-bottom: 10px;
							max-width: 300px;
						}
					}
				}

				> .ll-error-msg,
				> .mm-error-msg {

					// validation error
					@include if-parent('.mm-row.is-check.is-error') {
						max-width: none;
						padding: 0 30px 0 0;
					}
				}

				> .ll-check,
				> .mm-check {

					right: 5px;

					@include mq(desktop) {
						top: 12px;
						right: 30px;
					}
				}

				> .mm-row-content {

					position: relative;

					@include mq(desktop) {
						margin-bottom: 10px;
					}

					&--half {
						@include mq(desktop) {
							max-width: 215px;
						}

						@include if-parent('.mm-upload-container--centered') {
							max-width: 150px;
						}

						&.js-clone-costdeclaration {
							display: none;

							@include mq(desktop) {
								display: flex;
							}
						}

						label {
							@include milo-bold();
						}
					}

					&--right {
						@include mq(desktop) {
							flex: 1 0 auto;
							width: calc(100% - 215px);
						}

						@include if-parent('.mm-upload-container--centered') {
							width: calc(100% - 150px);
						}
					}

					> .ll-col-label {
						max-width: 200px;

						@include mq(tablet) {
							max-width: none;
						}

						@include mq(desktop) {
							max-width: 200px;
						}

						> label {
							@include mq(desktop) {
								display: inline;
								max-width: 200px;
								padding-left: 0;
							}
						}
					}

					> .ll-error-msg,
					> .mm-error-msg {
						width: auto;
						max-width: 270px !important;
						text-align: left;
					}

					> .ll-check,
					> .mm-check {
						right: -34px;

						@include mq(desktop) {
							top: 5px;
							left: 280px;
							right: auto;
						}
					}
				}

				.ll-check,
				.mm-check {
					margin: 0;
				}
			}
		}

		&:not(:first-child) {
			@include mq(desktop) {
				margin-top: 60px;
			}
		}
	}

	// clone remove button (bin icon)
	.js-clone-remove {
		@include absolute($top: 2px, $left: calc(100% - 40px));
		cursor: pointer;
		width: 20px;
		height: 20px;

		&:before {
			content: '';
			position: absolute;
			top: -10px;
			left: -10px;
			@include main-icon-delete;
		}

		@include mq(tablet) {
			top: 8px;
		}

		&.hidden {
			display: none;
		}
	}

	.m-basic-upload + .js-clone-remove {
		&:before {
			top: 0;
		}
	}

	// exception for acc offender
	.ll-col-data > .m-basic-text .js-clone-remove {
		left: auto;
		right: -40px;

		@include mq(tablet) {
			top: 2px;
		}
	}

	// exception for police files
	.ll-row > .ll-item-container .js-clone-remove {
		top: 5px;
		left: 295px;
		right: auto;

		@include mq(tablet) {
			left: auto;
			right: -40px;
		}
	}

	.ll-row.is-error.is-check > .ll-item-container .js-clone-remove {
		top: auto;
		bottom: 11px;
	}

	// plain buttons
	.vesrs-plain-button {
		display: block;
		position: relative;
		padding: 0;
		cursor: pointer;
		text-align: left;

		@include mq(tablet) {
			text-align: center;
			margin-left: auto;
			margin-right: auto;
		}

		// clone btn
		&--clone {
			padding-left: 30px;

			&:before {
				display: block;
				content: "";
				@include main-icon-clone;
				position: absolute;
				top: -11px;
				left: -11px;
				z-index: 100;
				transform: scale(.9);
			}

		}
	}
}