/* m-basic-text--my-adac */
.m-basic-text--my-adac {

	//exception for my-adac-dashbox site´s
	.l-outer--my-adac & {
		margin: 0 0 24px 0;
	}

	//exception for my-adac-hints - hint errors
	.m-basic-hint--my-adac & {
		margin: 0;
		color: $color-action-alert;
	}

	//exception for my-adac-hints - hint errors
	.m-basic-hint--my-adac-black & {
		color: $color-secondary-1;
	}

	//exception for my-adac-hints - hint phone
	.m-basic-hint--my-adac-phone & {
		margin: 0;
		color: $color-secondary-1;
	}

	// exception for myAdac promotionBoxes (grey)
	&.m-basic-text--my-adac-promotion {
		margin: 0;
	}

	/*m-basic-text--my-adac-intro */
	&.m-basic-text--my-adac-intro {
		margin: 0 auto;
	}

	//exception for a-my-adac-list
	.a-my-adac-list & {
		margin-bottom: 10px;
	}

	/*m-basic-text--my-adac-input */
	&.m-basic-text--my-adac-input {
		margin: 0 auto;
		max-width: 100%;

		@include mq(tablet) {
			max-width: 370px;
		}
	}

	/*m-basic-text--my-adac-headline */
	&.m-basic-text--my-adac-headline {
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;

		@include mq(tablet) {
			max-width: 340px;
		}
	}

	.ll-lightbox-inner.my-adac & {
		@include mq(tablet) {
			padding-right: 0;
		}
	}

	/*m-basic-text--my-adac-layer */
	&.m-basic-text--my-adac-layer {
		margin: 0 0 12px 0;
	}

	/* m-basic-text--my-adac */
	&.m-basic-text--my-adac-confirmation {
		padding: 0 0 0 40px;
		position: relative;

		@include mq(tablet) {
			padding: 0 0 0 45px;
		}

		&:before {
			display: inline-block;
			position: absolute;
			left: 0;
			top: -6px;
			content: '';
			@include main-icon-check-green;
		}
	}

	/* m-basic-text--my-adac */
	&.m-basic-text--my-adac-confirmation-default {
		padding: 0 0 0 40px;
		position: relative;

		@include mq(tablet) {
			padding: 0 0 0 45px;
		}

		&:before {
			display: inline-block;
			position: absolute;
			left: -14px;
			top: -11px;
			content: '';
			@include main-icon-close-red;

			@include mq(tablet) {
				left: -15px;
				top: -7px;
			}
		}
	}

	/*m-basic-text--my-adac-content-text */
	&.m-basic-text--my-adac-content-text {
		margin: 0 0 12px 0;
	}

	&.m-basic-text--outofcontent {

		@include mq(tablet) {
			margin-left: -40px;
			margin-right: -40px;
		}

		@include mq(desktop) {
			margin-left: -100px;
			margin-right: -100px;
		}

		> h3 {
			@include milo-bold(16px, 20px);
		}

		> p {
			max-width: 100%;
			@include font-size($font-size-16);
		}
	}

	> h1,
	> .h-h1 {
		text-align: center;
		padding: 0;
		@include milo-slab-bold(22px, 26px);
		max-width: none;

		@include mq(tablet) {
			@include milo-slab-bold(40px, 48px);
		}

		// exception for myAdac not logged in site
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-intro') {
			text-align: center;
		}

		//exception for my-adac-dashbox site´s
		.l-outer--my-adac & {
			margin-bottom: 0;

			@include mq(tablet) {
				margin-bottom: 24px;
			}
		}

		// exception for my-adac-stage
		.m-my-adac-stage & {
			margin-bottom: 15px;

			@include mq(tablet) {
				margin-bottom: 10px;
			}
		}

		.l-outer--my-adac-content & {
			@include mq(tablet) {
				margin-left: auto;
				margin-right: auto;
			}
		}

		&:only-child {
			margin-bottom: 0;
		}
	}

	> h2 {

		// exception for promotion boxes
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-promotion') {
			@include milo-regular(18px, 20px);
			margin-bottom: 2px;
		}

		// exception for myAdac not logged in site
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-intro') {
			@include milo-bold(20px, 24px);
			max-width: 780px;
			text-align: center;
			margin: 0 auto 44px auto;
		}

		// exception for myAdac content site´s
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-content-headline') {
			@include milo-slab-bold(18px, 22px);
			text-align: center;

			@include mq(tablet) {
				@include milo-slab-bold(28px, 32px);
			}
		}

		// exception for myAdac content site´s
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-content') {
			@include milo-slab-bold(18px, 22px);

			@include mq(tablet) {
				@include milo-slab-bold(24px, 32px);
			}
		}
	}

	> h3 {
		// Todo check if its needed
		@include milo-regular(18px, 22px);
		padding: 0;
		margin-bottom: 12px;

		// exception for promotion boxes
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-promotion') {
			@include milo-regular(18px, 20px);
			margin-bottom: 2px;
		}

		// exception for myAdac not logged in site
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-intro') {
			@include milo-bold(20px, 24px);
			max-width: 780px;
			text-align: center;
			margin: 0 auto 44px auto;
		}

		// exception for myAdac content site´s
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-content') {
			@include milo-slab-bold(18px, 22px);

			@include mq(tablet) {
				@include milo-slab-bold(24px, 32px);
			}
		}

		.m-my-adac-dashboard-box-layer & {
			@include milo-bold(18px, 22px);
		}
	}

	> .mm-content {
		max-width: 100%;
		margin-bottom: initial;

		> p {
			max-width: 100%;
		}
	}

	> p {

		// exception for content site´s -> normal hint in a form width 370px
		.l-outer--my-adac-content .m-basic-hint--my-adac & {
			margin-left: 15px;
			margin-right: 0;
		}

		// exception for promotion boxes
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-text') {
			@include milo-regular(18px, 22px);
		}

		// exception for promotion boxes
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-promotion') {
			@include milo-regular(16px, 20px);
		}

		// exception for myAdac content site´s
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-content-text') {
			@include milo-regular(16px, 20px);
		}

		// exception for myAdac content layer
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-layer') {
			@include milo-regular(16px, 20px);
		}

		// exception for myAdac content site´s
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-confirmation') {
			@include milo-regular(18px, 22px);

			@include mq(tablet) {
				@include milo-regular(26px, 32px);
			}
		}

		// exception for myAdac content site´s
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-confirmation-default') {
			@include milo-regular(18px, 22px);
			color: $color-action-alert;

			@include mq(tablet) {
				@include milo-regular(24px, 30px);
			}
		}

		// exception for my-adac-stage
		.m-my-adac-stage & {
			max-width: 100%;

			@include mq(tablet) {
				text-align: center;
			}
		}

		// exception for myAdac content site´s
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-content-text-centered') {
			text-align: center;
			@include milo-regular(16px, 20px);
			padding-bottom: 10px;
			max-width: 100%; //700px notwendig?
		}

		// exception for myAdac content site´s
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-content-text-tablet-centered') {
			@include milo-regular(16px, 20px);
			padding-bottom: 10px;
			max-width: 100%; //700px notwendig?

			@include mq(tablet) {
				text-align: center;
			}
		}

		// exception for myAdac content site´s
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-content-text-headline-tablet-centered') {
			@include milo-regular(18px, 22px);
			max-width: 100%; //700px notwendig?

			@include mq(tablet) {
				@include milo-regular(26px, 32px);
				text-align: center;
			}
		}

		// exception for BasicText above an input
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-input') {
			@include milo-bold(16px, 20px);
		}

		// exception for BasicText small centered text
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-small-center') {
			@include milo-regular(14px, 18px);
			text-align: center;
		}

		// exception for BasicText small left grey text
		@include if-parent('.m-basic-text--my-adac.m-basic-text--my-adac-small-grey') {
			@include milo-regular(14px, 18px);
			color: $color-secondary-3;

			.l-outer--my-adac-dashboard & {
				color: $color-secondary-1;
			}
		}
	}
}