/* a-basic-input-radio */
.a-basic-input-radio {
	float: left;
	display: block;
	position: relative;
	margin: 5px (3 * $form-gutter) 5px 0;

	&.h-space-s {
		margin-bottom: 7px;

		// exception for myAdac
		@include if-parent('.a-basic-input-radio--my-adac') {
			margin-bottom: 0;
		}
	}

	&.h-space-m {
		margin-bottom: 15px;
	}

	&.h-space-l {
		margin-bottom: 30px;
	}

	&.h-space-no {
		margin-bottom: 0;
	}

	// Exception ll-col-data
	.l-form .ll-col-label + .ll-col-data & {
		max-width: 320px;
	}

	.l-form .ll-aside-content & {
		margin-right: 8px;
	}

	// Exception m-basic-toggle--link :: in basickeyfilter--extended
	.m-basic-toggle--link & {
		float: none;
		margin-bottom: 15px;

		@include mq(tablet) {
			float: left;
			width: calc(33.3% - 20px);
			height: 40px;
			margin-bottom: 5px;
		}

		@include mq(desktop) {
			width: calc(25% - 20px);
		}
	}

	.mm-teaser-box & {
		float: none;
	}

	// exception when in .m-basic-keywordfilter
	.m-basic-keywordfilter > .mm-filter--columns .mm-filtergroup > & {

		float: none;
		margin-bottom: 15px;

		@include mq(tablet) {
			float: left;
			margin-bottom: 5px;
		}
	}

	// exception when in .m-ves-rs-contentbox
	.m-ves-rs-contentbox & {
		margin-bottom: 0;
	}

	// exception when in cardamage-forms
	.l-form .ll-row--cardamage .ll-col-data--grid & {

		min-width: 140px;
		margin: 5px 0;
		padding: 0 10px 0 0;

		@include mq(tablet) {
			min-width: 120px;
			padding: 0 30px 0 0;
		}

		> label {
			display: inline-block;
			cursor: pointer;

			> img {
				margin: 0 0 5px 2px;
			}

			& + input {
				& + label {
					left: 5px;
					bottom: 0;
				}
			}
		}
	}

	.l-form-general .ll-multirow & {
		width: fit-content;
	}

	// needed for multirows, where label is only set on first input field
	.l-form-general .ll-multirow .ll-row:not(.is-floating) + .ll-row .ll-item-container > :first-child:not(.ll-col-label) &:not(.is-floating),
	.l-form-general .ll-multirow .ll-row:not(.is-floating) + .ll-row > :first-child:not(.ll-col-label):not(.ll-item-container) &:not(.is-floating) {
		margin-top: 27.7px;
	}

	> label {
		display: inline-block;
		cursor: pointer;

		+ input + label {
			@include absolute($bottom: 0, $left: 0);
		}
	}

	// exception radio toggle {

	div > label + .ll-depend.is-open & {
		flex-wrap: wrap;
	}

	> input {
		@extend %h-visuallyhidden;

		& + label {
			@include absolute();
			width: 22px;
			height: 22px;
			cursor: pointer;
		}

		& ~ div {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;

			.a-basic-input-radio--col-1-2 & {
				flex-wrap: nowrap;
			}

			@include if-parent('.a-basic-input-radio.a-basic-input-radio--col-1-2') {
				flex-wrap: nowrap;
			}

			.ll-depend.is-open & {
				flex-wrap: wrap;
			}

			@include if-parent('.a-basic-input-radio--my-adac') {
				flex-wrap: wrap;
			}

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-radio-button-unchecked;
				background-repeat: no-repeat;
				position: absolute;
				margin-top: -11px;
				margin-left: -13px;

				.m-basic-table-toggle & {
					margin-left: -12px;
				}

				.l-form-general & {
					display: none;
				}
			}

			.l-form-general & {
				background: none;
				position: relative;
				flex-direction: column;

				&:after {
					content: '';
					position: absolute;
					left: 0;
					top: 2px;
					width: 18px;
					height: 18px;
					border: 1px solid $color-secondary-17;
					border-radius: 50%;
					background-color: $color-primary-2;
					pointer-events: none;
				}
			}

			.l-form .ll-aside-content & {
				padding-left: 23px;
			}

			// exception when in .m-ves-rs-contentbox
			.m-ves-rs-contentbox & {

				@include mq(mobile-max) {
					display: inline-block;
					padding-left: 30px;
				}
			}

			> label {
				// display inline-block enable label clicks between two lines
				display: inline-block;
				cursor: pointer;
				padding-left: 34px;
				position: relative;

				@include if-parent('.a-basic-input-radio--my-adac') {
					.l-form-general & {
						margin-left: 0;
					}
				}

				// Exception m-basic-toggle--link
				.m-basic-toggle--link & {
					display: inline-block;
					padding-top: 2px;
					@include line-height(18px);
				}

				.m-ves-calculator-short & {
					width: 100%;
				}

				+ * {
					margin-top: 15px;
					margin-right: 0;
				}
			}
		}

		// if focus
		&:focus ~ div {

			&:before {
				.is-keyboard-focus & {
					@include main-icon-radio-button-unchecked-focus-black;
				}
			}

			.l-form-general &:not(.a-basic-input-radio--my-adac) {
				background: none;

				&:after {
					border: 2px solid $color-secondary-22;

					.is-keyboard-focus & {
						box-shadow: 0 0 0 2px $color-primary-2, 0 0 0 5px $color-primary-3;
					}
				}
			}
		}


		&:checked ~ div {

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-radio-button-checked;
				background-repeat: no-repeat;
				margin-top: -11px;
			}

			.l-form-general &:not(.a-basic-input-radio--my-adac) {
				background: none;

				&:before {
					content: '';
					position: absolute;
					left: 17px;
					top: 17px;
					width: 10px;
					height: 10px;
					background-color: $color-secondary-17;
					border-radius: 50%;
					z-index: 1;
					pointer-events: none;
				}
			}

			// Exception for h-background
			.h-background & {

				&:before {
					content: '';
					display: inline-block;
					@include main-icon-radio-button-checked;
					background-repeat: no-repeat;
				}
			}
		}

		&:checked {

			// if focus && checked
			&:focus ~ div {

				&:before {
					content: '';
					display: inline-block;
					background-repeat: no-repeat;

					.is-keyboard-focus & {
						@include main-icon-radio-button-checked-focus-black;
					}
				}

				.l-form-general &:not(.a-basic-input-radio--my-adac) {
					background: none;

					&:after {
						border-color: $color-secondary-22;
						border-width: 2px;
					}

					&:before {
						content: '';
						position: absolute;
						left: 17px;
						top: 17px;
						width: 10px;
						height: 10px;
						background-color: $color-secondary-22;
						border-radius: 50%;
						z-index: 1;
					}
				}
			}

			// if focus by click && checked
			@include if-parent('.a-basic-input-radio.js-clicked') {
				& ~ div {

					&:before {
						@include main-icon-radio-button-checked;
						content: '';
						display: inline-block;
					}

					.l-form-general &:not(.a-basic-input-radio--my-adac) {
						background: none;

						&:after {
							background-color: $color-primary-2;
							border-color: $color-secondary-17;
						}

						&:before {
							content: '';
							position: absolute;
							left: 17px;
							top: 17px;
							width: 10px;
							height: 10px;
							background-color: $color-secondary-17;
							border-radius: 50%;
							z-index: 1;
						}
					}

					// Exception for h-background
					.h-background & {

						&:before {
							content: '';
							display: inline-block;
							@include main-icon-radio-button-checked-white;
							background-repeat: no-repeat;
						}
					}
				}
			}
		}

		&:disabled ~ div {
			opacity: 0.5;
		}


		// error state
		.l-form .is-error &.is-first-radio ~ div {

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-radio-button-unchecked-red;
				background-repeat: no-repeat;
			}
		}

		.l-form .is-error &.is-first-radio:checked ~ div {

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-radio-button-checked-red;
				background-repeat: no-repeat;
			}
		}

		.o-basic-form .is-error &.is-first-radio ~ div {

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-radio-button-unchecked-red;
				background-repeat: no-repeat;
			}
		}

		.o-basic-form .is-error &.is-first-radio:checked ~ div {

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-radio-button-checked-red;
				background-repeat: no-repeat;
			}
		}
	}

	// exception for depend box in radio
	> div > label + .ll-depend.is-open {
		padding-top: 0;
		margin-left: 35px;
	}

	.l-form--newsletter & {

		> div > label + .ll-depend.is-open {
			margin-top: 20px;

			@include mq(tablet) {
				width: 580px;
			}
		}
	}

	.m-adac-maps--mgldiscountmaps & {
		float: none;

		@include mq(tablet) {
			margin-top: 4px;
			margin-right: 15px;
			float: left;
		}
	}

	.m-basic-keywordfilter--location & {
		margin-right: 15px;
	}
}