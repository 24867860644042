/* m-basic-list--positive */
.m-basic-list--positive {

	padding: 0 0 10px 8px;

	@include mq(print) {
		padding-left: 0;
	}

	// Exception table
	.m-basic-table & {
		padding-bottom: 0;
		padding-left: 0;
	}

	> li {
		margin-bottom: 10px;
		padding: 0 0 2px 24px;
		background: none;

		&:before {
			@include main-icon-add;
			top: -14px;
			left: -12px;

			@include mq(print) {
				@include main-icon-add-green;
			}
		}

		@include mq(print) {
			background: none;
		}

		// exception
		.m-basic-table table tr:nth-child(even) td & {
			@include mq(print) {
				box-shadow: inset 0 0 0 1000px $color-secondary-6;
			}
		}

		> a {
			text-decoration: none;
		}
	}
}