/* m-basic-table--compare-result */
.m-basic-table--compare-result {
	margin-right: -$l-main-content-padding-side;

	@include mq(tablet) {
		margin-right: -$l-main-content-padding-side-tablet;
	}

	@include mq(desktop) {
		margin-right: -$l-main-content-padding-side-desktop;
	}

	> tbody {
		> tr {
			td {
				min-width: 0;
				padding: 15px 20px;
			}
		}
	}

	> thead {
		> tr {
			th {
				min-width: 0;
				padding: 15px 20px;
			}
		}
	}

	a {
		&.a-basic-btn {
			&.product-btn {
				padding-top: 5px;
				padding-bottom: 5px;
				margin-bottom: 10px;
				@include arial($font-size-15);
			}
		}

		&.a-basic-link {
			&.default-product-link {
				margin-left: 2px;
			}
		}
	}
}