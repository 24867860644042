/* .m-basic-hint--background */
.m-basic-hint--background-yellow {
	background: $color-primary-1;
	border: none;
	position: relative;
	padding: 13px 25px;
	border-radius: 8px;

	@include mq(tablet) {
		padding: 30px 83px 40px 83px;
	}
}