/* .m-basic-hint--background */
.m-basic-hint--padding {
	padding: 13px 25px 13px 40px;

	@include mq(tablet) {
		padding: 23px 83px;
	}

	> img {
		top: 15px;
		left: 10px;

		@include mq(tablet) {
			top: 31px;
			left: 45px;
		}
	}
}