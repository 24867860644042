/* o-layout-header-metanav--loggedin */
.o-layout-header-metanav--loggedin {

	.oo-list {

		> li {
			&.oo-login {
				@include mq(tablet) {
					width: 195px;
					text-align: left;
					padding: 0 14px 0 8px;
					white-space: nowrap;

					> a {
						cursor: default;

						> img {
							position: relative;
							top: -1px;
							float: none;
						}

						> span {
							width: calc(100% - 20px);
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							float: none;
							display: inline-block;
						}
					}
				}
			}
		}
	}

	.oo-loggedin {
		display: none;
		padding: 45px 20px 16px 20px;

		@include mq(tablet) {
			width: 300px;
			position: absolute;
			z-index: 61;
			top: 0;
			right: 0;
			padding: 56px 15px 9px 15px;
			background: $color-primary-1;
			border: 1px solid $color-primary-2;
			border-top: none;
			border-bottom: none;
		}

		@include mq(desktop-l) {
			right: 0;
			width: 295px;
			padding-top: 65px;
			padding-bottom: 17px;
		}


		> ul {
			margin: 0;
			padding: 0;

			> li {
				padding-bottom: 3px;

				&.oo-last {
					padding-bottom: 0;
				}

				a {
					padding: 0 0 0 16px;
					text-decoration: none;
					background: url(/assets/img/icons/icon-arrow-right-black.svg) no-repeat -5px -2px;

					&:hover {
						text-decoration: underline;
					}
				}

				button {
					padding: 0 0 0 16px;
					text-decoration: none;
					background: url(/assets/img/icons/icon-arrow-right-black.svg) no-repeat -5px -2px;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}