/* a-basic-input-text--autocomplete */
.a-basic-input-text--autocomplete {

	&.is-noresult {
		overflow: visible;
		height: 36px;
	}

	> .ll-noresult {
		display: none;
		position: absolute;
		height: auto;
		width: 100%;
		padding: 12px 10px;
		background-color: $color-secondary-7;
		border-top: 0;
		z-index: 10;

		&:hover {
			cursor: default;
		}

		> p {
			margin-bottom: 10px;
		}
	}

}