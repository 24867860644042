/* a-basic-badge */
.a-basic-badge {
	position: absolute;
	border-radius: 15px;
	padding: 0 8px 2px;

	&--red {
		background-color: $color-action-alert;
		border: 1px solid $color-action-alert;
	}

	// exception for .m-adac-cardamage
	.m-adac-cardamage & {
		display: none;
		right: 1px;
		top: -2px;
	}

	// exception for .m-adac-cardamage + cancellation class 'is-high-load'
	.m-adac-cardamage .is-high-load & {
		display: initial;
	}

	.aa-badge-text {
		@include milo-bold($font-size-14, 16px);

		@include if-parent('.a-basic-badge.a-basic-badge--red') {
			color: $color-primary-2;
		}
	}
}