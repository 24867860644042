.m-basic-range-slide {
	padding: 0 5px;

	&.no-last-val {
		.noUi-value-horizontal:last-child {
			display: none;
		}
	}

	// exception .m-basic-calculator-content-box
	.m-basic-calculator-content-box .mm-form-container > .mm-fieldset > .mm-row--center > & {
		width: 100%;
		max-width: 480px;
	}

	.m-basic-calculator-tiles--classic .mm-content-row--centered & {
		width: 100%;
		max-width: 480px;
		margin: 0 auto;
	}

	&.has-large-values {
		padding-left: 25px;
		padding-right: 25px;
	}

	> .mm-label {
		margin: 0 -5px 15px -5px;

		@include if-parent('.m-basic-range-slide.has-large-values') {
			margin-left: -25px;
			margin-right: -25px;
		}
	}

	> .mm-range-container {
		padding: 30px 0;

		> .mm-range {
			height: 2px;

			// exception .m-basic-calculator-content-box .mm-row--center
			.m-basic-calculator-content-box .mm-row--center & {
				margin: 0 auto;
			}
		}
	}

	/* Functional styling;
     * These styles are required for noUiSlider to function.
     * You don't need to change these rules to apply your design.
     */
	.noUi-target,
	.noUi-target * {
		box-sizing: border-box;
	}

	.noUi-target {
		position: relative;
		direction: ltr;
	}

	.noUi-base {
		width: 100%;
		height: 3px;
		position: relative;
		top: -1px;
		z-index: 1;
		/* Fix 401 */
		transform: translate3d(0, 0, 0);

		&:before {
			content: '';
			display: block;
			position: absolute;
			background: transparent;
			height: 20px;
			width: calc(100% + 14px);
			left: -7px;
			top: -10px;
			cursor: pointer;
			z-index: 10;
		}
	}

	.noUi-stacking .noUi-handle {
		/* This class is applied to the lower origin when
           its values is > 50%. */
		z-index: 10;
	}

	.noUi-state-tap .noUi-origin {
		transition: left 0.3s, top 0.3s;
	}

	.noUi-state-drag * {
		cursor: inherit !important;
	}

	.noUi-horizontal .noUi-handle {
		width: 20px;
		height: 20px;
		right: -12px;
		top: -9px;
		background: $color-primary-1;
		border-radius: 50%;
		cursor: pointer;
		border: none;
		box-shadow: none;

		&:before,
		&:after {
			display: none;
		}
	}

	/* Styling;
     */
	.noUi-background {
		background: $color-secondary-9;

		@include mq(print) {
			background: none;
			box-shadow: inset 0 0 0 1000px $color-secondary-9;
		}
	}

	.noUi-connect {
		background: $color-primary-1;
		transition: background 450ms;
	}

	.noUi-origin {
		background: $color-primary-1;
		position: absolute;
		right: 0;
		top: 0;
		left: 0;
		bottom: 0;

		@include mq(print) {
			background: none;
			box-shadow: inset 0 0 0 1000px $color-primary-1;
		}

		& + .noUi-origin {
			background: $color-secondary-9;
		}
	}

	/* Handles and cursors;
     */
	.noUi-draggable {
		cursor: w-resize;
	}

	.noUi-vertical .noUi-draggable {
		cursor: n-resize;
	}

	.noUi-handle {
		z-index: 11;
		cursor: default;

		@include mq(print) {
			background: none;
			box-shadow: inset 0 0 0 1000px $color-primary-1;
		}
	}

	/* Disabled state;
     */
	[disabled].noUi-connect,
	[disabled] .noUi-connect {
		background: #B8B8B8;
	}

	[disabled].noUi-origin,
	[disabled] .noUi-handle {
		cursor: not-allowed;
	}

	/* PIPS
     *
     */
	.noUi-pips {
		position: absolute;
		color: $color-primary-3;
	}

	/* Values;
     *
     */
	.noUi-value {
		position: absolute;
		white-space: nowrap;
		text-align: center;
	}

	.noUi-value-sub {
		color: #ccc;
		font-size: 10px;
	}

	/* Markings;
     *
     */
	.noUi-marker {
		position: absolute;
		background: #CCC;

		&.is-active {
			background: $color-primary-1;
		}
	}

	.noUi-marker-sub {
		background: #AAA;
	}

	.noUi-marker-large {
		background: $color-secondary-9;
	}

	/* Horizontal layout;
     *
     */
	.noUi-pips-horizontal {
		padding: 0;
		height: 30px;
		top: -7px;
		left: 0;
		width: 100%;
	}

	.noUi-value-horizontal {
		transform: translate(-50%, -100%);
	}

	.noUi-rtl .noUi-value-horizontal {
		transform: translate(50%, 50%);
	}

	.noUi-marker-horizontal.noUi-marker {
		margin-left: -1px;
		width: 2px;
		height: 5px;
	}

	.noUi-marker-horizontal.noUi-marker-sub {
		height: 10px;
		display: none;
	}

	.noUi-marker-horizontal.noUi-marker-large {
		height: 15px;
		width: 15px;
		border-radius: 50%;
		margin-left: -7.5px;
		cursor: pointer;
	}
}