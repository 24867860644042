/* a-basic-input-text--date */
.a-basic-input-text--search {
	display: block;
	width: calc(100% / 2);

	// exception for m-layout-main-nav-left
	.m-layout-main-nav-left & {
		margin: 0 30px 0 34px;
		width: calc(100% - 64px);

		@include mq(desktop) {
			margin: 0 10px;
			width: calc(100% - 20px);
		}

		> input {
			background-color: $color-primary-2;
		}
	}

	> .mm-searchbtn {
		position: absolute;
		top: -2px;
		right: -4px;
		padding: 4px 3px 0 0;
		@include main-icon-search;
		background-repeat: no-repeat;
		text-indent: -10000px;
	}
}