/* m-basic-list--my-adac-link */
.m-basic-list--my-adac-link {

	// exception for myAdac
	.l-outer--my-adac & {
		margin: 0;
	}

	li {
		position: static;
		margin: 0;
		padding: 0;
		background: none;

		&:hover {
			text-decoration: none;
		}

		&:visited {
			color: $color-secondary-1;
		}

		&:before {
			display: none;
			position: static;
		}

		> a {

			@include if-parent('.m-basic-list--my-adac-link.m-basic-list--link') {
				display: flex;
				flex-flow: row-reverse;
				justify-content: flex-end;
				margin: 0 -8px 0 -8px;
				padding: 10px 25px 10px 48px;
				position: relative;
				@include font-size(18px, 26px);

				&:after {
					position: absolute;
					content: '';
					display: block;
					@include main-icon-arrow-right-grey;
					width: 24px;
					height: 33px;
					right: 0;
					top: 12px;
				}
			}
		}
	}
}