/* m-basic-teaser-wrapper-ves */
.m-basic-teaser-wrapper-ves--uneven {
	> .mm-wrapper {

		@include mq(desktop) {
			align-items: flex-end;
		}

		> .mm-item {
			justify-content: flex-start;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-top: 5px solid $color-primary-1
		}
	}
}