.m-basic-layer-survey {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	width: 95%;
	min-height: 325px;
	transform: translate(-50%, -50%);
	z-index: 2000;
	outline: none;

	@include mq(tablet) {
		width: 350px;
		min-height: 350px;
	}

	@include mq(desktop) {
		width: auto;
		max-width: 620px;
		min-height: 400px;
	}

	&:before {
		content: '';
		display: block;
		@include absolute(50%, 50%);
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.7);
		transform: translate(-50%, -50%);
	}

	> .mm-content {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 32px 32px 5px;
		background: $color-primary-2;
		border-radius: 8px;

		@include mq(tablet) {
			padding: 40px 40px 5px;
		}

		> .mm-close {
			position: absolute;
			right: 10px;
			top: 10px;
			cursor: pointer;
			width: 44px;
			height: 44px;

			&::before {
				content: '';
				@include main-icon-close-black;
				position: absolute;
				top: 0;
				right: 0;
			}

			> img {
				display: none;
			}
		}

		> article {
			> header {
				margin-bottom: 15px;

				@include mq(tablet) {
					margin-bottom: 20px;
				}

				> .mm-headline {
					width: 100%;
					display: inline-block;
					@include milo-slab-bold($font-size-22, 29px);

					@include mq(tablet) {
						@include milo-slab-bold($font-size-24, 31px);
					}

					> .mm-headline-highlighting {
						background-color: $color-primary-1;
						padding: 0 10px;

						@include mq(tablet) {
							padding: 5px 10px;
						}
					}
				}
			}

			> section {
				margin-bottom: 30px;

				> .mm-timestamp {
					margin-left: 30px;
					margin-top: -5px;
					margin-bottom: -6px;

					@include mq(tablet) {
						margin-top: -10px;
					}

					&:before {
						content: '';
						display: inline-block;
						@include damage-declaration-icon-schedule;
						margin-left: -45px;
						margin-top: -12px;
						position: absolute;
					}
				}
			}
		}
	}
}