.m-basic-promotion-teaser {
	margin-bottom: 40px;
	border: 1px solid $color-secondary-9;
	border-radius: 5px;
	padding: 30px;

	@include mq(tablet) {
		margin-bottom: 60px;
		padding: 40px 60px;
	}

	&.is-hidden {
		display: none;
	}

	&--center {
		text-align: center;

		> .mm-content {
			> * {
				margin: 0 auto;

				&:not(:last-child) {
					margin-bottom: 20px;
				}
			}
		}
	}

	> h2 {
		margin-bottom: 4px;


		@include mq(tablet) {
			margin-bottom: 0;
		}
	}

	> .mm-content {
		> * {
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
	}
}