/* mm-my-adac-toggle-element*/
.mm-my-adac-toggle-element {

	> .mm-wrapper {

		.mm-toggle-tab-content & {
			border-bottom: 1px solid $color-secondary-9;
		}

		> .mm-toggle-headline-wrapper {
			cursor: pointer;

			> .mm-toggle-icon-wrapper {
				position: absolute;
				margin-top: 7px;
				margin-left: 5px;

				& + .mm-toggle-headline {
					padding: 9px 35px 9px 50px;
				}

				.m-my-adac-dashboard-box .mm-box:not(.is-extended) & {
					display: none;
				}

				.mm-my-adac-toggle-items-container-content & {
					display: none;
				}

				@include if-parent('.mm-toggle-headline-wrapper.mm-toggle-headline-wrapper--icon-car') {
					margin-left: 8px;
				}
			}

			> .mm-toggle-headline {
				padding: 9px 8px;
				color: $color-secondary-1;
				margin: 0;
				position: relative;
				@include milo-regular(16px);
				max-width: none;

				.mm-toggle-tab-content & {
					color: $color-secondary-3;
					letter-spacing: 0;
					border-top: 1px solid $color-secondary-9;
					@include font-size($font-size-15);
				}

				.mm-my-adac-toggle-items-container-content & {
					border-bottom: 1px solid $color-secondary-3;
					padding: 9px 35px 9px 50px;

					&:before {
						position: absolute;
						content: '';
						display: block;
						@include main-icon-bike-full;
						left: -5px;
						top: -3px;
						cursor: pointer;

						@include if-parent('.mm-toggle-headline-wrapper.mm-toggle-headline-wrapper--icon-car') {
							@include main-icon-crash-car;
							left: -2px;
						}
					}
				}

				&:after {
					position: absolute;
					content: '';
					display: block;
					@include main-icon-arrow-right-grey;
					transform: rotate(90deg);
					right: -2px;
					top: 0;
					cursor: pointer;

					@include mq(tablet) {
						top: 2px;
					}

					.l-outer--my-adac-dashboard & {
						@include main-icon-arrow-right-grey2;
					}

					@include if-parent('.mm-my-adac-toggle-element.is-active') {
						transform: rotate(-90deg);
					}
				}

				button {
					width: 100%;
					text-align: left;
				}
			}
		}

		> .mm-toggle-content {
			display: none;
			height: auto;
			padding-top: 24px;

			@include if-parent('.mm-my-adac-toggle-element.is-active') {
				display: block;
			}

			.mm-toggle-tab-content & {
				padding-bottom: 10px;
			}

			&--image {
				padding: 0 0 12px 0;
			}

			> .mm-my-adac-toggle-wrapper {
				padding: 0;
				margin: 0;

				> .mm-my-adac-toggle {

					> .mm-list-text {
						@include milo-regular(18px, 22px);
						margin-bottom: 12px;
					}
				}
			}

			> .ll-row {
				> .ll-col-data {
					.mm-toggle-tab-content & {
						width: 100%;
					}
				}
			}

		}
	}
}