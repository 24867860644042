.m-ruf-weather-detail {

	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-flow: column nowrap;

	@include mq(tablet) {
		flex-flow: row wrap;
	}

	> .mm-detail-item {
		width: 100%;
		margin-bottom: 20px;

		@include mq(tablet) {
			width: calc(50% - 20px);
		}

		&:last-child {
			margin-bottom: 0;
		}

		> .mm-head {
			height: 42px;
			padding: 8px 10px;
			border: 2px solid $color-secondary-1;
			@include milo-bold();
		}

		> .mm-content {

			display: flex;
			flex-flow: row nowrap;
			border: 1px solid $color-secondary-3;
			border-top: 0;

			img {
				display: block;
				margin: 0 auto;
			}

			.mm-description {

				text-align: center;

				> span {

					&.mm-strong {
						@include milo-bold();
					}

				}

			}

			> .mm-left {

				width: 66%;
				padding: 5px 0;
				border-right: 1px solid $color-secondary-3;

				@include mq(tablet) {
					padding-bottom: 30px;
				}

				> img {
					width: 110px;

					@include mq(tablet) {
						width: 150px;
					}
				}

				> .mm-description {

					> span {

						&.mm-strong {


							@include mq(tablet) {
								font-size: 20px;
							}
						}

						&.mm-strong + span.mm-desc {
							display: block;
							margin: -2px 0 5px 0;
						}

					}

				}

			}

			> .mm-right {
				width: 34%;

				img {
					width: 60px;

					@include mq(tablet) {
						width: 70px;
					}
				}

				> .mm-small {
					display: flex;
					flex-flow: column nowrap;
					justify-content: center;
					height: 50%;

					&--bottom {
						border-top: 1px solid $color-secondary-3;
					}
				}
			}

		}

	}

}