/* o-layout-header-metanav */
.o-layout-header-metanav {

	.oo-list {
		margin: 16px 14px 0 0;
		float: right;
		min-height: 49px;

		@include mq(tablet) {
			margin-right: 30px;

			@include if-parent('.o-layout-header-metanav--brs') {
				margin-right: 20px;
			}
		}

		@include mq(desktop) {
			padding: 0;
			margin-top: 26px;
			margin-right: 0;

			@include if-parent('.o-layout-header-metanav--brs') {
				margin-right: 20px;
			}
		}

		@include mq(desktop-l) {
			margin-right: 0;

			@include if-parent('.o-layout-header-metanav--brs') {
				margin-right: 0;
			}
		}

		@include mq(print) {
			margin-right: 0;
		}

		.l-outer--nav & {
			@include mq(desktop-l) {
				margin-right: 0;
			}
		}

		> li {
			position: relative;
			height: 30px;
			padding: 0 12px;
			display: block;
			float: left;
			z-index: 2;

			@include mq(print) {
				&:not(.h-print-only) {
					display: none !important;
				}
			}

			&:first-child {

				.l-header & {

					@include mq(tablet) {
						display: none;
					}

					@include if-parent('.o-layout-header-metanav--brs') {

						@include mq(tablet) {
							display: block;
						}
					}
				}
			}

			&:nth-child(3) {
				.l-header & {
					@include if-parent('.o-layout-header-metanav--loggedin') {
						@include mq(tablet) {
							padding-right: 0;
						}
					}
				}
			}

			&:nth-child(5) {
				.l-outer--nav .l-header & {
					@include mq(desktop) {
						padding-right: 0;
					}
				}

				@include mq(tablet) {
					display: none;
				}

				@include mq(desktop-xl) {
					padding-right: 0;
				}

				@include if-parent('.o-layout-header-metanav--brs') {
					display: block;
				}
			}

			&.oo-login {
				@include mq(tablet) {
					width: auto;
					z-index: 80;
					padding-right: 0;
					max-width: 200px;
				}
			}

			&.h-loggedout-only {
				padding: 0;
			}

			> a {
				padding: 0;
				text-decoration: none;
				outline: none;

				@include mq(tablet) {
					background: $color-primary-1;
					min-height: 30px;
				}

				> svg,
				> img {
					position: relative;
					top: 3px;
					float: left;

					.l-header & {
						margin: 0;
						top: 3px;

						@include if-parent('.o-layout-header-metanav li:nth-child(3)') {
							top: 3px;
							left: -4px;
						}

						@include if-parent('.o-layout-header-metanav li:nth-child(4)') {
							top: 5px;
						}

						@include if-parent('.o-layout-header-metanav li:nth-child(5)') {
							top: 0;
						}
					}

					@include mq(tablet) {
						top: 6px;
						margin: 0 5px 0 0;
					}
				}

				> span {
					display: none;
					@include milo-regular($font-size-16);

					@include mq(tablet) {
						display: block;
						position: relative;
						bottom: 1px;
						float: left;
						padding: 8px 0 0 0;
					}

					@include if-parent('.o-layout-header-metanav--brs') {
						display: none;

						@include mq(tablet) {
							display: none;
						}
					}

					&.h-smartphone-visible {
						display: block;

						&.h-smartphone-visible--menuTxt {
							position: absolute;
							right: 10px;
							padding: 0;
							font-size: 0.8em;
							@include milo-regular();
							top: 19px;

							@include mq(desktop-xl) {
								right: -2px;
							}

							.l-outer--nav & {
								@include if-parent('li:nth-child(5)') {
									@include mq(desktop) {
										right: -2px;
									}
								}
							}
						}
					}
				}
			}

			&.h-print-only {
				> span {
					@include franklin-book($font-size-18);
					position: relative;
					bottom: 1px;
					float: left;
					padding: 2px 0 0 0;
				}
			}
		}
	}

	.oo-content {
		background: $color-primary-1;

		@include mq(tablet) {
			display: block !important;
		}
	}

	.oo-loggedin {
		display: none;
		border-top: 1px solid $color-primary-2;
		padding: 20px;

		@include mq(tablet) {
			border-top: 0;
		}
	}

	.oo-loggedout {
		display: none;
		padding: 20px;
		border-top: 1px solid $color-primary-2;

		@include mq(tablet) {
			display: none !important;
		}

		> p {
			padding: 15px 0 0 0;
		}

		.a-basic-link {
			display: block;
			width: 100%;
			padding-bottom: 15px;
			border-bottom: 1px solid $color-primary-2;
			background-position: -10px -1px;
		}
	}
}