.m-my-adac-promotion-box {

	> .mm-wrapper {
		display: flex;
		flex-flow: row nowrap;

		> .mm-promotion-img-wrapper {
			// img: 140px + padding: 10px
			flex: 0 1 150px;
			padding-right: 10px;

			> img {
				max-width: 100%;
			}
		}

		> .mm-promotion-text-wrapper {
			flex: 1 1 50%;
			padding-left: 10px;

			> .mm-text {
				margin-bottom: 5px;
			}
		}
	}
}