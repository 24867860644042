/* m-basic-list--document */
.m-basic-list--icon {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	> li {
		padding: 8px 15px;
		background: none;

		.l-outer--my-adac-content & {
			padding: 8px 15px;
		}

		&:before {
			display: none;
		}

		> span {
			display: block;
			text-align: center;

			&.mm-text {
				@include milo-bold();
			}

			> img {
				opacity: 0.6;
			}
		}
	}
}