.m-basic-toggle--reduced-border {

	.mm-head {
		border-left: unset;
		border-right: unset;
		border-top: unset;
		border-radius: 0;

		&:nth-last-child(2) {
			border-radius: 0;
		}

		&.mm-opened + .mm-content {
			border: unset;
			padding: 17px 10px 2px 10px;
		}
	}
}