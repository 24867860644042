/* m-basic-table--break */
.m-basic-table--break {

	%mm-tac-smartphone {
		text-align: left;

		@include mq(tablet) {
			text-align: left;
		}
	}

	> tbody {

		> tr {
			&:nth-last-child(2) {
				border-color: transparent;

				@include mq(tablet) {
					border-color: $color-secondary-16;
				}

				&:not(.is-active) {
					.js-toggle-controller {
						border-bottom-left-radius: 10px;
						border-bottom-right-radius: 10px;
						width: 100%;
					}
				}
			}

			// toggle content generated width JS
			&.mm-toggle-row {
				@include mq(tablet) {
					display: none;
				}

				@include mq(print) {
					display: table-row !important;
				}

				&:last-child {
					border-bottom: 0;

					> td {
						.mm-toggle-close {
							margin-bottom: 1px;
						}
					}
				}

				> td {
					padding: 0;
					border: 0;

					@include mq(print) {
						padding: 0 20px;
					}

					> .mm-toggle-row-content {
						display: none;
						margin: 0 0 -1px 0;

						@include if-parent('.m-basic-table--break .mm-toggle-row:last-child') {
							border-bottom: 0;
						}

						@include mq(print) {
							display: block !important;
						}

						> .mm-toggletext-content {
							max-width: inherit !important;
							padding: 10px;
							background: $color-primary-2;
						}

						> table {

							> tbody {

								> tr {

									td,
									th {
										padding-left: 15px;
										padding-right: 15px;

										@include mq(tablet) {
											padding-left: 0;
											padding-right: 0;
										}
									}
								}
							}
						}

						> .js-lightbox {
							display: block;
							padding: 10px;
							border-top: 0;
							background: $color-primary-2;

							&.a-basic-link {
								padding: 10px 10px 10px 18px;
								margin-left: 10px;

								&:before {
									top: -1px;
								}
							}

							&:focus {
								outline: none;
							}
						}

						> .mm-toggle-close {
							padding: 15px;
							border-bottom-left-radius: 10px;
							border-bottom-right-radius: 10px;

							@include if-parent('.m-basic-table--break.m-basic-table--ruf-weather') {
								background: $color-primary-2;
								border-top: none;
							}
						}
					}
				}
			}

			> td {
				> .mm-toggletext-head {
					@include mq(tablet) {
						position: relative;
						left: -3px;
						padding: 0 0 0 15px;
						font-weight: bold;
						cursor: pointer;
					}

					&:before {

						@include mq(tablet) {
							content: '';
							display: inline-block;
							@include main-icon-link;
							width: 44px;
							left: -16px;
							position: absolute;
							top: -11px;
						}
					}

					&.is-active {
						&:before {

							@include mq(tablet) {
								@include main-icon-arrow-drop-down;
								width: 44px;
								height: 26px;
								top: 2px;
								left: -15px;
							}
						}
					}
				}

				> .mm-toggletext-content {
					height: 0;
					overflow: hidden;

					@include mq(tablet) {
						display: none;
						height: auto;
						overflow: visible;
						padding: 0 0 0 13px;
					}
				}

				.mm-tac-smartphone {
					@extend %mm-tac-smartphone;

					&--center {
						text-align: center;

						@include mq(tablet) {
							text-align: center;
						}
					}
				}
			}
		}
	}

	// hide all
	.mm-hide-all {
		display: none !important;
	}

	// hide phone
	.mm-hide-phone {
		display: none !important;

		@include mq(tablet) {
			display: table-cell !important;
		}
	}

	// hide tablet
	.mm-hide-tablet {
		@include mq(tablet) {
			display: none !important;
		}
	}

	// hide desktop
	.mm-hide-desktop {
		@include mq(desktop) {
			display: none !important;
		}
	}

	// colspan hidden siblings
	.mm-colspan-full-sibling {
		display: none !important;

		&.js-colspan-full-sibling-tablet {
			display: table-cell !important;

			@include mq(tablet) {
				display: none !important;
			}
		}

		&.js-colspan-full-sibling-desktop {
			display: table-cell !important;

			@include mq(desktop) {
				display: none !important;
			}
		}
	}

	// toggle controller
	.js-toggle-controller {
		padding-left: 30px;
		cursor: pointer;
		font-weight: bold;

		@include mq(tablet) {
			padding-left: 10px;
			background: 0;
			cursor: default;
			font-weight: normal;
		}

		&:before {
			content: '';
			display: inline-block;
			@include main-icon-arrow-right-grey;
			width: 44px;
			top: 4px;
			position: absolute;
			left: -8px;

			@include mq(tablet) {
				background: none;
			}
		}

		&.is-active {

			&:before {
				@include main-icon-arrow-down-grey;
				width: 44px;
				top: 4px;
				left: -10px;

				@include mq(tablet) {
					background: none;
				}
			}
		}
	}

	&.m-basic-table--detail {
		.mm-toggle-close {
			position: relative;

			.a-basic-link--left {
				position: absolute;
				top: 10px;
				left: 10px;
			}
		}
	}
}