/* l-main-cols */
.l-main-cols {
	display: flex;
	position: relative;
	width: 100%;

	@include mq(tablet) {
		padding-right: 0;
	}

	&--border-top {
		border-top: 1px solid $color-secondary-9;
	}

	// needed for spacing buttons
	.l-form-general .l-main-cols &,
	.l-form-general .ll-fieldset & {
		min-width: 350px;
		justify-content: space-between;

		@include mq(tablet) {
			min-width: initial;
		}
	}

	.l-form-general .ll-fieldset .m-basic-hint & {
		min-width: inherit;
	}

	// exception organsim o-basic-form
	.o-basic-form & & {
		padding-right: 20px;

		@include mq(tablet) {
			padding-right: 0;
		}
	}

	.o-basic-form > & {

		@include mq(tablet) {
			padding-right: 0;
		}
	}

	//exception lightbox
	.l-lightbox .o-basic-form & {
		padding-right: 0;

		@include mq(tablet) {
			padding-right: 0;
		}
	}

	&--flex-end {
		justify-content: flex-end;
	}

	&--align-center {
		align-items: center;
	}

	// exception .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration + & {
		margin-top: 30px;
	}

	// Exception ll-main-content-switch
	.ll-main-content-switchbox > & {
		padding-right: 0;
	}

	&--calculator {
		flex-direction: column-reverse;

		@include mq(tablet) {
			flex-direction: row;

			&.l-main-cols--inline-on-init {
				flex-direction: column-reverse;
			}
		}

		@include mq(desktop) {
			&.l-main-cols--inline-on-init {
				flex-direction: row;
			}
		}
	}

	// exception cardamage forms
	.l-form--cardamage-map > form > &,
	.l-form-general--cardamage-map > form > & {
		flex-direction: column-reverse;

		@include mq(desktop) {
			flex-flow: row wrap;

			&.l-main-cols--inline-on-init {
				flex-direction: row;
			}
		}
	}

	// exception cardamage forms
	.l-form--cardamage-map .ll-main-cols-aside &,
	.l-form-general--cardamage-map .ll-main-cols-aside & {
		justify-content: space-between;
		flex-flow: row nowrap;
		max-width: 730px;
		padding-right: 0;

		> :first-child {
			margin-right: 12px;
		}
	}

	&--mobile {
		flex-direction: row !important;
		flex-wrap: wrap !important;
		align-items: flex-end !important;

		@include mq(tablet) {
			align-items: flex-start !important;
		}

		.m-basic-calculator-content-box .m-basic-radius-search & {
			margin-top: 20px;

			@include mq(tablet) {
				margin-top: 0;
			}
		}

		.ll-row--flex {
			.m-basic-calculator-content-box .m-basic-radius-search & {
				display: block;
			}

			@include mq(tablet) {
				.m-basic-calculator-content-box .m-basic-radius-search & {
					display: flex;
				}
			}

			.ll-col-label {
				.m-basic-calculator-content-box .m-basic-radius-search & {
					padding: 0 !important;
				}

				@include mq(tablet) {
					.m-basic-calculator-content-box .m-basic-radius-search & {
						padding-left: 30px !important;
					}
				}
			}
		}

		.a-basic-btn {
			.m-basic-calculator-content-box .m-basic-radius-search & {
				margin-left: 0 !important;
			}

			@include mq(tablet) {
				.m-basic-calculator-content-box .m-basic-radius-search & {
					margin-left: 10px !important;
				}
			}
		}

		.a-basic-input-select {
			.m-basic-calculator-content-box .m-basic-radius-search & {
				padding: 0;
				width: 100% !important;
			}
		}

		fieldset {
			&:last-child {
				.m-basic-calculator-content-box .m-basic-radius-search & {
					padding: 0 !important;
				}
			}
		}
	}

	&--tablet {
		flex-direction: column;

		@include mq(tablet) {
			flex-direction: row;
		}
	}

	&--desktop {
		flex-direction: column;

		@include mq(desktop-l) {
			flex-direction: row;
		}
	}

	.l-form & & {
		padding-right: 20px;

		@include mq(tablet) {
			padding-right: 0;
		}

		// exception for m-basic-socialshare
		.m-basic-socialshare & {
			padding-right: 0;
		}
	}

	.l-form > & {

		@include mq(tablet) {
			padding-right: 0;
		}
	}

	.l-form .ll-fieldset > & {
		margin-left: 10px
	}

	//exception lightbox
	.l-lightbox .l-form & {
		padding-right: 0;

		@include mq(tablet) {
			padding-right: 0;
		}
	}

	.ll-main-cols--right {
		text-align: right;
	}

	.ll-main-cols-s {
		width: 25%;
		vertical-align: top;
		border-left: 10px solid transparent;
		transition: border-color 0.5s, opacity 0.5s;

		&:after {
			content: ".";
			display: block;
			font-size: 1px;
			height: 1px;

			.ll-lightbox-inner & {
				content: none;
			}
		}

		&--first {
			border-left: none;
			border-right: 10px solid transparent;
		}

		&--tablet {
			width: 100%;
			border-left: 0px;
			border-right: 0px;

			&.ll-main-cols-s--first {
				@include mq(tablet) {
					border-right: 10px solid transparent;
				}

				&.h-space-s {
					@include mq(tablet) {
						margin-bottom: 0;
					}
				}
			}

			@include mq(tablet) {
				width: 25%;

				&:not(.ll-main-cols-s--first) {
					border-left: 10px solid transparent;
				}
			}
		}

		&--small-space-between {
			margin: 0 10px;
		}
	}

	.ll-main-cols-m {
		width: 100%;
		vertical-align: top;
		transition: border-color 0.5s, opacity 0.5s;

		@include mq(tablet) {
			width: 50%;
			border-left: 10px solid transparent;
		}

		&:after {
			content: ".";
			display: block;
			font-size: 1px;
			height: 1px;

			.ll-lightbox-inner & {
				content: none;
			}
		}

		&--first {
			border-left: none;

			@include mq(tablet) {
				border-right: 10px solid transparent;
			}

			// exception m-fdl-calculator
			.m-fdl-calculator & {
				border-left: 0;
				border-right: 0;

				@include mq(tablet) {
					border-right: 31px solid transparent;
				}
			}
		}

		&--tablet {
			width: 100%;
			border-left: 0;
			border-right: 0;

			&.ll-main-cols-m--first {
				margin-bottom: 30px;

				@include mq(tablet) {
					border-right: 10px solid transparent;
					margin-bottom: 0;
				}

				&.h-space-s {
					@include mq(tablet) {
						margin-bottom: 0;
					}
				}

				&.h-space-m {
					@include mq(tablet) {
						margin-bottom: 0;
					}
				}
			}

			@include mq(tablet) {
				width: 50%;

				&:not(.ll-main-cols-m--first) {
					border-left: 10px solid transparent;
				}
			}

			// exception m-fdl-calculator
			.m-fdl-calculator & {
				border-left: 0;
			}
		}

		.l-form-general & {
			&:not(.ll-main-cols-m--first) {
				padding: 0 45px 0 0;

				@include mq(tablet) {
					padding: initial;
				}
			}
		}
	}

	.ll-main-cols-l {
		width: 75%;
		vertical-align: top;
		border-left: 10px solid transparent;
		transition: border-color 0.5s, opacity 0.5s;

		&:after {
			content: ".";
			display: block;
			font-size: 1px;
			height: 1px;

			.ll-lightbox-inner & {
				content: none;
			}
		}

		&--first {
			border-left: none;
			border-right: 10px solid transparent;
		}

		&--tablet {
			width: 100%;
			border-left: 0px;
			border-right: 0px;

			&.ll-main-cols-l--first {
				margin-bottom: 20px;

				@include mq(tablet) {
					margin-bottom: 0;
					border-right: 10px solid transparent;
				}
			}

			@include mq(tablet) {
				width: 75%;

				&:not(.ll-main-cols-l--first) {
					border-left: 10px solid transparent;
				}
			}
		}
	}

	.ll-main-cols-full {
		width: 100%;

		.ll-fieldset {

			.ll-row {
				max-width: 100%;
			}
		}
	}

	.ll-main-cols-flex {
		width: 100%;
		vertical-align: top;

		@include mq('tablet') {
			width: auto;
		}

		&--first {
			border-right: 30px solid transparent;

			@include if-parent('.l-main-cols--tablet') {
				margin-bottom: 15px;

				@include mq(tablet) {
					margin-bottom: 0;
				}
			}

			@include if-parent('.l-main-cols--desktop') {
				margin-bottom: 15px;

				@include mq(tablet) {
					margin-bottom: 0;
				}
			}
		}

		&--last {
			border-left: 30px solid transparent;

			// exception molecule m-adac-maps modifier --mgldiscountmaps
			.m-adac-maps--mgldiscountmaps .mm-result-list-wrap & {
				border-left: 0;

				@include mq(desktop) {
					max-width: calc(100% - 181px);
					overflow: hidden;
				}
			}
		}

		&--big {
			@include mq(tablet) {
				flex: 1 0 auto;
			}
		}

		&--thirds {
			@include mq(tablet) {
				max-width: calc(100% / 3 - (80px / 3));
			}
		}

		&--align-center {
			align-self: center;
		}

		// case if more than 2 elems are present
		// 2nd child is not last
		// => Expand to max and align contents centered
		&:nth-child(2n):not(:last-child) {
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			flex: 1 1 auto;

			@include mq(tablet) {
				flex: 1 0 auto;
			}
		}

		&--margin {
			padding-top: 20px;
			display: block !important;

			&:not(:last-child) {
				@include mq(tablet) {
					margin-right: 40px;
				}
			}
		}
	}

	&--offer {
		position: relative;
		padding-bottom: 30px;

		.l-form .l-main-cols & {
			padding-right: 0;
		}

		@include mq(tablet) {
			padding-right: 20px;
			padding-bottom: 0;
			height: auto;
		}

		.ll-main-cols-l {

			@include mq(tablet) {
				display: flex;
				justify-content: flex-end;
				align-items: center;
			}

			.a-basic-link {
				position: absolute;
				bottom: 0;
				right: 0;
				margin: 0 auto;

				@include mq(tablet) {
					position: relative;
				}
			}
		}
	}

	&--table {
		flex-direction: column;

		@include mq(tablet) {
			flex-direction: row;
		}

		.ll-main-cols-s {
			float: none;
			width: auto;
			display: block;

			&--tablet {
				width: 100%;
				border-left: 0px;
				border-right: 0px;

				@include mq(tablet) {
					width: 25%;

					&:not(.ll-main-cols-s--first) {
						border-left: 10px solid transparent;
					}
				}
			}
		}

		.ll-main-cols-m {
			width: auto;
			display: table-cell;

			&--tablet {
				width: 100%;
				border-left: 0px;
				border-right: 0px;

				&.ll-main-cols-m--first {
					@include mq(tablet) {
						border-right: 10px solid transparent;
					}

					&.h-space-m {
						@include mq(tablet) {
							margin-bottom: 0;
						}
					}
				}

				@include mq(tablet) {
					width: 50%;

					&:not(.ll-main-cols-m--first) {
						border-left: 10px solid transparent;
					}
				}

				// exception m-fdl-calculator

				.m-fdl-calculator & {
					border-left: 0;
				}

			}
		}

		.ll-main-cols-l {
			width: auto;

			&--tablet {
				width: 100%;
				border-left: 0;
				border-right: 0;
				display: block;

				&.ll-main-cols-l--first {
					margin-bottom: 20px;

					@include mq(tablet) {
						margin-bottom: 0;
						border-right: 10px solid transparent;
					}
				}

				@include mq(tablet) {
					width: 75%;

					&:not(.ll-main-cols-l--first) {
						border-left: 10px solid transparent;
					}
				}
			}
		}
	}

	&--line {
		position: relative;
		overflow: hidden;
		margin: 10px 0 0 0;

		> .ll-main-cols-m {
			width: 100%;
			border: 0;
			opacity: 1;

			@include mq(tablet) {
				width: 50%;
				padding: 0 0 0 40px;
				border-left: 1px solid $color-secondary-5;

				&:first-child {
					padding-right: 30px;
					padding-left: 0;
					border-left: 0;
				}
			}

			&:after {
				content: none;
			}

			+ .ll-main-cols-m {
				position: absolute;
				left: 100%;
				background: $color-primary-2;

				@include mq(tablet) {
					position: static;
				}
			}
		}

		&.l-main-cols--line-secondary {
			> .ll-main-cols-m {
				position: absolute;

				@include mq(tablet) {
					position: static;
					opacity: 1;
				}

				+ .ll-main-cols-m {
					position: relative;
					opacity: 1;

					@include mq(tablet) {
						position: static;
					}
				}
			}
		}
	}

	// flex order to switch column order
	&--flex-order {
		flex-direction: column;

		@include mq(tablet) {
			flex-direction: row;
		}

		> .ll-main-cols {

			&--tablet-order-1 {
				flex: 1 0 100%;
				order: 2;

				@include mq(tablet) {
					flex: 0 0 50%;
					order: 1;
				}
			}

			&--tablet-order-2 {
				flex: 1 0 100%;
				order: 1;

				@include mq(tablet) {
					flex: 0 0 50%;
					order: 2;
				}
			}
		}

		> .ll-main-cols-s,
		> .ll-main-cols-m,
		> .ll-main-cols-l {
			&:first-of-type {
				border-left: 0;
			}
		}
	}

	.ll-main-cols-slidercontent {
		display: none;
	}

	.ll-main-cols-aside {

		@include mq(desktop) {
			width: 265px;
		}

		@include if-parent('.l-main-cols.l-main-cols--calculator:not(.l-main-cols--inline-on-init)') {
			width: 100%;
			border-bottom: 30px solid transparent;

			@include mq(tablet) {
				width: 255px;
				border-bottom: none;
			}

			@include mq(desktop) {
				width: 265px;
			}
		}

		//exception for cardamage form
		.l-form--cardamage-map &,
		.l-form-general--cardamage-map & {

			@include mq(desktop) {
				width: 305px;
			}

			&.is-sticky {
				@include mq(desktop) {
					position: fixed;
				}
			}
		}

		&--first {
			@include mq(tablet) {
				display: block;
			}

			@include mq(desktop) {
				width: calc(100% - 265px);
				border-right: 20px solid transparent;
			}

			@include if-parent('.l-main-cols.l-main-cols--calculator:not(.l-main-cols--inline-on-init)') {

				@include mq(tablet) {
					width: calc(100% - 305px);
					border-right: 20px solid transparent;
				}

				@include mq(desktop) {
					width: calc(100% - 265px);
				}
			}

			//exception for cardamage form
			.l-form--cardamage-map &,
			.l-form-general--cardamage-map & {

				position: static;

				@include mq(desktop) {
					width: calc(100% - 305px);
				}
			}
		}

		.ll-aside {
			.l-form-general & {
				background: $color-primary-2;
				z-index: 55;
				border: 1px solid $color-secondary-5;
				transition: transform 1.5s;

				> .ll-aside-head {
					padding: 1px 37px 2px 10px;
					position: relative;
					background: $color-primary-1;
					@include milo-slab-bold($font-size-24);
					border-bottom: 1px solid $color-secondary-6;

					> .ll-aside-edit {
						position: absolute;
						top: 5px;
						right: 7px;
						cursor: pointer;
					}
				}

				> .ll-aside-content {
					padding: 8px 10px 2px;
					background: #fff;
					position: relative;
				}
			}
		}
	}

	.l-main-content--dashboardteaser & {
		&--tablet {
			.ll-main-cols-m {
				width: 100%;
				border-left: none;

				@include mq(desktop) {
					width: 50%;
					border-left: 10px solid transparent;
				}

				&--first {
					@include mq(desktop) {
						border-left: none;
					}
				}
			}
		}
	}

	// exception for button in stage with larger slide content wrapper
	.oo-slide-content-wrapper--big & {
		flex-wrap: wrap;

		@include mq(tablet) {
			flex-wrap: nowrap;
		}
	}
}