.a-basic-calculator-slider--filter-tab-layer {
	position: relative;
	width: 100%;

	> .ll-row {

		> .ll-col-label {

			> .ll-label {
				display: inline-block;
				float: left;
				width: 35px;

				padding: 3px 0px;
				margin: 5px 0 0 0;

				@include mq(tablet) {
					width: auto;
					margin-right: 10px;
				}

				@include if-parent('.ll-col-label:nth-child(3)') {
					@include mq(tablet) {
						margin-left: 10px;
					}
				}
			}
		}

		> .ll-col-data {

			margin-bottom: 5px;

			@include mq(tablet) {
				float: left;
			}

			> .aa-result {
				width: 200px;

				@include mq(tablet) {
					width: 120px;
				}

			}

			> .ll-unit {
				position: absolute;
				left: 245px;
				bottom: 40px;

				@include mq(tablet) {
					left: 325px;
				}
			}
		}
	}

	> .aa-slider {
		max-width: 390px;
		margin-top: 15px;

		@include mq(tablet) {
			max-width: 315px;
		}
	}

}