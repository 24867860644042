/* m-basic-list--myadac-icon */
.m-basic-list--myadac-icon {
	margin-top: 15px;

	//Exception override basicText Exception
	.m-basic-text .mm-content & {
		padding-top: 15px;
	}

	li {
		padding-left: 0;
		margin-bottom: 30px;
		display: flex;

		//Exception override basicText Exception
		.m-basic-text .mm-content & {
			margin-bottom: 30px;
		}

		&:before {
			display: none;
		}

		&:not(:last-child):after {
			content: '';
			position: absolute;
			top: 0;
			left: 17px;
			width: 1px;
			height: calc(100% + 30px);
			background: $color-secondary-16;
		}

		> span {
			display: inline-block;

			&:first-child {
				width: 35px;
				min-width: 35px;
				height: 35px;
				margin-right: 10px;
				background: $color-primary-1;
				border-radius: 50%;
				position: relative;
				top: -8px;
				z-index: 1;
				text-align: center;

				> img {
					position: relative;
					top: 5px;
				}
			}
		}
	}
}