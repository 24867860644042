.o-basic-cluster-box--image-align-right {

	.oo-main > section {
		&.oo-img-box {
			@include mq(print) {
				width: 410px;
			}
		}
	}

	.oo-figure > img {
		@include mq(tablet) {
			right: calc(15% + 73px);
			margin: 0 -50% 0 0;
			left: auto;
		}

		@include mq(desktop) {
			left: 50%;
			margin: 0 0 0 -100%;
		}

		@include mq(print) {
			left: 0;
			margin: 0;
		}
	}
}