/* m-basic-teaser-app */
.m-basic-teaser-app {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	width: 289px;
	height: 120px;
	background: $color-primary-2;
	margin: 0 15px 0 0;
	padding: 10px;

	figure {
		flex: 0 0 100px;
		margin: 0;

		> img {
			@extend %lazy-img;
		}
	}

	> .mm-appcontent {
		position: relative;
		height: 100%;
		padding: 0 0 0 10px;
		flex: 0 0 160px;

		> .mm-appheadline {
			font-weight: bold;
			@include milo-regular ($font-size-18, 22px);

			.h-roboto & {
				font-family: 'Roboto', sans-serif;
			}

		}

		> .mm-appicons {
			display: flex;
			flex-flow: row nowrap;
			position: absolute;
			bottom: 3px;
			left: 10px;

			> a {
				display: inline-block;
				outline: none;

				> .mm-applink {
					float: left;
					width: 35px;
					height: 30px;
					position: relative;

					&:before {
						content: '';
						position: absolute;
						top: -26px;
						left: -12px;
					}
				}

				> .mm-applink--apple {
					&:before {
						@include main-icon-apple;
					}
				}

				> .mm-applink--android {
					&:before {
						@include main-icon-android;
					}
				}

				> .mm-applink--windows {
					width: 37px;

					&:before {
						@include main-icon-windows;
						left: -11px;
					}
				}

				> .mm-applink--blackberry {
					width: 42px;

					&:before {
						@include main-icon-blackberry;
						left: -9px;
					}
				}
			}
		}
	}
}