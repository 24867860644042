.m-basic-table-restyle--separate-header {

	> .mm-table {

		thead {
			> tr {

				> th {
					text-align: center;

					&:first-child {
						display: none;
						text-align: left;

						@include mq('tablet') {
							display: table-cell;
						}
					}

					&.js-separate {
						display: table-cell;
						padding-bottom: 0;
					}
				}

				&.is-tablet-hidden {
					border-bottom: 0;

					@include mq('tablet') {
						display: none;
					}
				}
			}
		}

		tbody {
			tr {

				&:last-child {
					border-bottom: 0;
				}

				&.is-tablet-hidden {
					border-bottom: 0;

					@include mq('tablet') {
						display: none;
					}
				}

				> td {
					vertical-align: middle;
					text-align: center;

					&:first-child {
						display: none;
						text-align: left;

						@include mq('tablet') {
							display: table-cell;
						}
					}

					&.js-separate {
						display: table-cell;
						padding-bottom: 0;
					}
				}
			}
		}
	}
}