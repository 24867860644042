/* m-basic-breadcrumb--refresh */
.m-basic-breadcrumb--refresh {
	margin-left: 15px;
	margin-right: 15px;
	display: block;
	position: relative;

	@include mq(tablet) {
		margin-left: 0;
	}

	@include mq(desktop) {
		display: block;
	}

	// Exception: if is set basic-stage, hide breadcrumb
	.o-layout-main-stage ~ .ll-main-center & {
		display: none;
	}

	// Exception l-outer-nav
	.l-outer--nav & {

		@include mq(desktop) {
			width: calc(82% - 35px);
			max-width: 898px;
			padding-right: 40px;
			float: right;
		}

		@include mq(desktop-xl) {
			width: 100%;
			max-width: 898px;
			padding-right: 0;
			float: left;
		}
	}

	// Exception: if child of o-layout-main-header
	.o-layout-main-header > & {

		@include mq(tablet) {
			padding-top: 15px;
		}

	}

	> .swiper {
		padding: 9px 0 0 0;
		z-index: 35;
		position: absolute;
		left: 0;
		right: 0;

		@include mq(tablet) {
			padding: 15px 0 25px 0;
			cursor: default;
		}

		.mm-baselevel {
			padding: 0;
			max-width: none;
			background: transparent;
			border-bottom: none;
			margin: 0;

			> li {
				position: relative;
				padding: 0;
				border: none;
				background: transparent;
				white-space: nowrap;
				width: auto;

				@include mq(desktop) {
					padding: 0;
					border: none;
					background: transparent;
				}

				&.is-opened {
					display: block;
				}

				&:before {
					content: '';
					@include main-icon-arrow-left-black;
					left: 7px;
					@include absolute();
					width: 26px;
					height: 28px;


					@include mq(desktop) {
						// background-position: -2px -3px
					}
				}

				&:last-child {
					margin-right: 0;
				}

				&.mm-home {
					display: block;
					margin-right: -25px;

					> a {
						display: inline-block;
						overflow: hidden;
						text-indent: -10000px;
						margin: 0;
						@include main-icon-home;
						width: 22px;
						height: 24px;
						margin-top: -5px;

					}

					&:before {
						background: none;
						width: 0;
						height: 0;
					}
				}

				&.js-dropdown {
					background: $color-primary-1;
					cursor: pointer;
					padding: 2px 0;
					display: none;
					margin-left: 10px;
					order: 0;
					position: relative;
					top: -2px;
					border: 2px solid transparent;

					@include mq(tablet) {
						display: block;
					}

					&:before {
						display: none;
					}

					> a {

						&:before {
							@include main-icon-expand-more;
							left: 10px;
							margin-right: 3px;
							content: "";
							display: inline-block;
							position: absolute;
							width: 27px;
							height: 29px;
						}
					}

					&.is-opened {

						padding-bottom: 0;

						a {
							min-height: 28px;

							&:before {
								@include main-icon-expand-less;
								width: 22px;
								height: 35px;
								left: 0;
							}
						}
					}

					> .mm-firstlevel {
						margin: 0 0 -2px 0;
						padding: 0;
						z-index: 35;
						display: none;

						width: auto;
						visibility: hidden;
						height: 1px;
						overflow: hidden;

						&.is-opened {
							visibility: visible;
							height: auto;
							display: block;
							overflow: visible;
						}

						li {
							width: 100%;

							&:after {
								left: 5px;
							}

							&:last-child {
								border-bottom: 1px solid $color-secondary-9;
							}

							a {
								@include milo-regular($font-size-14, 20px);
								text-decoration: none;
								display: block;
								background: $color-primary-2;
								padding: 10px 15px 10px 40px;
								border-top: 1px solid $color-secondary-9;
								border-right: 1px solid $color-secondary-9;
								border-left: 1px solid $color-secondary-9;
								margin: 0 -2px;

								&:focus {
									outline: none;
								}

								&:hover,
								&:focus {
									background: $color-secondary-8;
								}

								&.is-active {
									@include milo-bold();
								}
							}

							> .mm-firstlevel {
								margin: 0;
								padding: 0;
								display: none;

								&.is-opened {
									display: block;
								}

								li {

									a {
										background: $color-primary-2;
										padding: 10px 15px 10px 40px;
										@include font-size($font-size-14, 20px);

										&:hover,
										&:focus {
											background: $color-secondary-8;
										}
									}
								}
							}
						}
					}
				}

				> a {
					@include milo-regular($font-size-16);
					padding: 1px 10px 1px 30px;
					display: block;
					@include line-height(23px);
				}
			}
		}

		> .swiper-button-prev {
			background: none;
			width: 17.81%;

			&:before {
				top: 0;
				height: 100%;
				background-image: none;
			}

			&:after {
				width: 100%;
				background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
				left: 0;
				margin-left: 0;
			}
		}

		> .swiper-button-next {
			background: none;
			width: 17.81%;

			&:before {
				top: 0;
				height: 100%;
				background-image: none;
			}

			&:after {
				width: 100%;
				right: 0;
				left: auto;
				margin-left: 0;
				background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
			}
		}
	}
}