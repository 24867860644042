/* m-basic-newsbox--img */
.m-basic-newsbox--img {

	$figurePaddingBottom: 16px;

	> figure {
		width: 100%;
		max-width: 100%;
		margin: 0;
		padding: 0 0 $figurePaddingBottom 0;
		z-index: 2;
		position: relative;

		@include mq(tablet) {
			float: left;
			width: 290px;
			margin-top: 4px;
		}

		img {
			width: 100%;
			height: auto;
		}

		// Exception for m-ves-tab
		// fix the heights to prevent document.height changes when content is lazyloaded
		.m-ves-tab & {

			// Images are in 290:120 format ( = 2.417 : 1 ) and scale in mobile
			$mainContentPadding: 40px;
			$mobileHeight: calc((100vw - #{$mainContentPadding}) / 2.417 + #{$figurePaddingBottom});

			min-height: $mobileHeight;
			max-height: $mobileHeight;
			height: $mobileHeight;
			overflow: hidden;

			@include mq(tablet) {
				min-height: 167px;
				max-height: 167px;
				height: 167px;
			}
		}
	}

	> .a-basic-stopper {
		right: -10px;

		@include mq(tablet) {
			top: 20px;
		}

		& + .mm-content {
			margin-right: 0;

			@include mq(tablet) {
				padding-right: 100px;
			}
		}
	}

	> .mm-content {
		@include mq(tablet) {
			padding: 0 20px 0 15px;
			width: calc(100% - 290px);
			float: left;
		}

		> p {

			$lineHeight: 21px;
			$maxLines: 4;

			/// Exception .m-ves-tab
			.m-ves-tab & {
				max-height: calc(#{$maxLines} * #{$lineHeight});
			}
		}
	}
}