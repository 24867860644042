/* .m-basic-hint--error */
.m-basic-hint--error {

	// Exception my-adac
	.l-outer--my-adac & {
		border-top: 2px solid $color-action-alert;
		border-bottom: 2px solid $color-action-alert;
	}

	// Exception my-adac-content
	.l-outer--my-adac-content & {
		border-top: 2px solid $color-action-alert;
		border-bottom: 2px solid $color-action-alert;
		padding-left: 44px;
		padding-right: 12px;
		width: 100%;

		> img {
			left: 12px;
		}
	}


	&.m-basic-hint--error-text {
		color: $color-action-alert;
		border-top: 1px solid $color-secondary-9;
		border-bottom: 1px solid $color-secondary-9;

		a {
			color: $color-action-alert;
			cursor: pointer;
		}
	}

	h2 {
		@include milo-slab-bold($font-size-24);
		color: $color-action-alert;
		margin-bottom: 5px;

		@include if-parent('.m-basic-hint--error.h-roboto') {
			font-family: 'Roboto Bold', sans-serif;
		}
	}
}