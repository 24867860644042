/* a-basic-date-range--general  */
.a-basic-date-range--general {

	> .aa-date-wrapper {
		> .js-date {
			top: 50%;
			transform: translateY(-50%);

			.is-check.is-valid &,
			.is-check.is-error & {
				right: 30px;
			}

			> span {
				@include main-icon-event;
			}
		}
	}
}