/* a-basc-link--table */
.a-basic-link--compare {
	padding: 10px 16px 10px 20px;
	background-color: $color-primary-1;
	background-position: 0 8px;
	border: 1px solid $color-secondary-5;
	border-top: none;
	display: none;
	padding-left: 30px;

	@include mq(tablet) {
		display: table;
	}

	// Exception
	.m-basic-table.h-space-s + & {
		position: relative;
		top: -15px;
	}

	&:before {
		top: -1px;
		left: -5px;
	}
}