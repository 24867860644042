/* m-basic-list--img-top */
.m-basic-list--img-top {
	display: flex;
	justify-content: center;
	max-width: 100%;
	flex-direction: column;

	@include mq(tablet) {
		flex-direction: row;
	}

	> li {
		background: none;
		padding: 0;
		@include milo-bold();
		text-align: center;
		max-width: 210px;
		margin: 0 auto 20px;

		&:before {
			background: none;
		}

		@include mq(tablet) {
			margin: 0 60px 0 0;

			&:last-child {
				margin-right: 0;
			}
		}

		> .mm-img-content {
			margin-bottom: 5px;

		}
	}
}