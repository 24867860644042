/* a-layout-darkness */
.a-layout-darkness {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	// basicformfootersticky => z-index 1002
	z-index: 1005;

	&:not(.a-layout-darkness--loader):not(.a-layout-darkness--search):not(.a-layout-darkness--info-icon) {
		@include mq(desktop) {
			display: none !important;
		}
	}
}