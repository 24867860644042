/* m-basic-anchornav--newsbox */
.m-basic-anchornav--newsbox {
	.mm-anchorlist {
		@include mq(tablet) {
			margin: 0 0 6px 0;
		}

		ul {
			.mm-anchor {
				@include mq(tablet) {
					max-width: inherit;
				}
			}
		}
	}

	.mm-contentwrapper {
		.mm-content {
			@include mq(tablet) {
				margin-bottom: 23px;
				padding: 0;
			}

			.m-basic-newsbox {

				&:nth-child(2) {
					@include mq(tablet) {
						border-top: none;
						padding: 0;
						margin: 0;
						padding-bottom: 3px;
						padding-top: 8px;
					}
				}

				&:not(:last-child) {
					@include mq(tablet) {
						border-bottom: none;
						padding-top: 7px;
					}
				}

				&.h-space-s {
					margin: 0;
				}
			}

			&:first-child {
				@include mq(tablet) {
					padding-top: 11px;
				}
			}

			&:last-child {
				.m-basic-newsbox {
					@include mq(tablet) {
						border-bottom: none;
					}
				}
			}
		}
	}
}