/* a-basic-input-select--ds-filter-not-round */
.a-basic-input-select--ds-filter-not-round {
	.l-form-general & {
		height: 36px;

		@include mq(tablet) {
			height: 40px;
		}

		> select {
			padding: 0 40px 0 15px;
			min-height: 36px;

			@include mq(tablet) {
				min-height: 40px;
			}

			&:focus {
				padding: 0 39px 0 14px;
			}

			> option {
				background-color: $color-primary-2;

				&:disabled {
					display: none;
				}
			}
		}

		.aa-controller {
			top: -3px;

			@include mq(tablet) {
				top: -1px;
			}
		}
	}
}