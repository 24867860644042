/* m-ves-tab--filter */
.m-ves-tab--filter {
	max-width: 100%;

	> .m-select-container {
		@include mq(tablet) {
			visibility: hidden;
			height: 0;
			overflow: hidden;
		}
	}

	.mm-nav {
		overflow: visible;

		li {
			border: 0;
			border-bottom: 2px solid $color-secondary-9;

			&:first-child {
				border-left: 0;
			}

			&.is-active {
				border: 0;
				border-bottom: 2px solid $color-primary-1;

				&:first-child {
					border-left: 0;
				}

				a {
					background: none;
					border: 0;

					&:after {
						content: "";
						@include my-adac-icon-tab-myadac;
						@include absolute($top: -10px, $left: -10px)
					}
				}
			}

			> a {
				background: none;
				margin-bottom: 0;
				padding: 10px 15px;
				border: 0;
				@include milo-regular();

				.h-roboto & {
					font-family: 'Roboto', sans-serif;
				}
			}

			&.is-default {
				> a {
					color: $color-secondary-9;
				}
			}
		}
	}

	> .mm-tabcontent-important {
		background: $color-secondary-8;
		padding: 15px 20px 5px;
		margin-top: 10px;
		margin-bottom: 10px;

		> h3 {
			padding-bottom: 10px;
		}

		@include mq('tablet') {
			margin-top: 30px;
			margin-bottom: 20px;
		}
	}

	// Exception for tabs with form and img-components
	> .mm-tabcontent {
		padding: 10px 0;
		border-top: 0;

		@include mq(tablet) {
			padding: 30px 0;
		}

		> .is-hidden {
			display: none;
		}

		> .mm-btn-box {
			padding: 20px 0;
		}
	}

	//exception when swiper is active
	.swiper {
		visibility: hidden;
		height: 0;
		padding: 10px 0;

		&:after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 10px;
			width: 100%;
			height: 2px;
			background: $color-secondary-9;
		}

		@include mq(tablet) {
			visibility: visible;
			height: auto;
		}

		> .swiper-wrapper {
			display: flex;
			width: 2000px;
			max-width: none;

			> .swiper-slide {
				width: auto;
			}
		}

		> .swiper-button-prev {
			&:before {
				top: 0;
				height: 100%;
				background-image: url('/assets/img/bg/swiper-thumbs-shadow-right.png');
			}
		}

		> .swiper-button-next {
			&:before {
				top: 0;
				height: 100%;
				background-image: url('/assets/img/bg/swiper-thumbs-shadow-left.png');
			}
		}
	}
}