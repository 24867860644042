/* m-basic-table--compare */
.m-basic-table--compare {

	> thead > tr,
	> tbody > tr {

		&.h-smartphone-only:first-child {
			border-top: 0;
		}

		&.mm-compare-footline {
			padding-left: 10px;
		}

		> th,
		> td {
			padding: 10px 5px;
			text-align: center;

			@include mq(tablet) {
				text-align: left;
				padding: 10px;
				min-width: 165px;
			}

			&:first-child {
				@include mq(tablet) {
					padding-left: 10px;
				}
			}

			&:last-child {
				@include mq(tablet) {
					padding-right: 10px;
				}
			}

			&:nth-child(1) {

				@include if-parent('.m-basic-table--compare tr:not(.h-smartphone-only)') {
					display: none;

					@include mq(tablet) {
						display: table-cell;
					}
				}
			}

			&:nth-child(2) {
				background: $color-secondary-8;
			}

			@include if-parent('.m-basic-table--compare tr.mm-compare-footline') {
				padding-top: 4px;
				padding-left: 10px;
				background: $color-primary-2;
				text-align: left;
				border: 0;

			}

			&.js-compare-head {
				padding: 15px 10px 5px 10px;
				text-align: left;
				background: $color-primary-2;

				// Exception in sticky clone header
				.js-basic-table-header-clone-wrap.is-sticky-header & {
					display: none;
				}

				> strong {
					@include milo-regular($font-size-21, 26px);

					.h-roboto & {
						font-family: 'Roboto', sans-serif;
					}
				}
			}

			&.mm-compare-subhead {
				padding-left: 10px;
				text-align: left;
			}

			.mm-phone-small {
				@include milo-regular($font-size-12);

				.h-roboto & {
					font-family: 'Roboto', sans-serif;
				}

				@include mq(tablet) {
					@include milo-regular($font-size-15);

					.h-roboto & {
						font-family: 'Roboto', sans-serif;
					}
				}
			}
		}
	}

	> thead {

		> tr {

			&.h-smartphone-only {
				border-top: none;

				th.js-compare-head {
					border-left: none;
					border-right: none;
				}

			}

			> th {
				// reserve space for stopper
				padding-top: 30px;
				padding-bottom: 5px;

				@include if-parent('.m-basic-table--compare.is-cloned') {
					&:first-child {
						border-left: 0;
					}
				}

				@include mq(desktop) {
					padding-bottom: 10px;
				}
			}
		}
	}
}