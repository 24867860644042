// m-ds-result
.m-ds-result {
	margin-bottom: 40px;
	display: flex;
	flex-direction: column;

	@include mq(desktop) {
		margin-bottom: 60px;
	}

	> * {
		order: 2;
	}

	> .mm-head-location-wrapper {
		padding: 0 0 10px 0;

		@include mq(tablet) {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 0 18px 0;
		}

		> h2 {
			@include mq(tablet) {
				padding-bottom: 0;
			}
		}

		> .mm-location-wrapper {
			> * {
				//@include mq(tablet){
				justify-content: flex-end;
				//}
			}
		}
	}

	> .mm-sort-wrapper {
		display: flex;
		column-gap: 10px;
		justify-content: flex-end;
		padding-bottom: 15px;

		&--border {
			border-bottom: 1px solid $color-secondary-16;
		}

		> .mm-filter-trigger {
			height: 36px;
			border-radius: 18px;
			border: 1px solid $color-formfield-border-standard;
			padding-right: 10px;
			display: flex;
			align-items: center;
			position: relative;
			cursor: pointer;

			&:before {
				content: '';
				@include main-icon-filter-grey;
			}

			@include mq(tablet) {
				display: none;
			}

			> .js-filter-count {
				margin-left: 10px;
				border-radius: 10px;
				width: 25px;
				height: 20px;
				background-color: $color-primary-1;
				text-align: center;
				font-size: 14px;
			}
		}
	}

	> .mm-filter-wrapper {
		border-top: 1px solid $color-secondary-16;
		margin: 30px 0;
		background-color: $color-secondary-16;
		border-radius: 10px;
		padding: 15px 15px 20px 15px;
		display: flex;
		column-gap: 20px;
		row-gap: 20px;

		@include mq(tablet) {
			margin: 0 0 50px 0;
			padding: 20px 30px;
			order: 1;
		}

		> .mm-icon {
			display: none;

			@include mq('tablet') {
				width: 100px;
				display: block;
			}

			> img {
				width: 100%
			}
		}

		> .mm-filter-content {
			flex: 1;

			h3 {
				margin-bottom: 10px;
			}

			h4 {
				margin-bottom: 5px;
			}
		}
	}

	> .mm-wrapper {
		> .mm-divider-headline-wrapper {
			display: flex;
			column-gap: 20px;
			align-items: center;
			padding: 30px 0 20px 0;

			&:not(:first-child) {
				border-top: 1px solid $color-secondary-16;
			}

			> .mm-circle {
				position: relative;
				width: 40px;
				height: 40px;
				background-color: $color-primary-1;
				border-radius: 50%;

				> img {
					display: block;
					position: relative;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}

			> .mm-divider-headline {
				@include milo-bold($font-size-16, 21px);

				@include mq(tablet) {
					@include font-size($font-size-20, 24px);
				}
			}
		}

		> .mm-result-items-wrapper {
			> .mm-result-item {
				border-top: 1px solid $color-secondary-16;
				padding: 20px 0 15px 0;

				&:last-child {
					border-bottom: 1px solid $color-secondary-16;
				}

				> .mm-headline-wrapper {
					display: flex;
					column-gap: 20px;
					row-gap: 10px;
					flex-wrap: wrap;
					align-items: center;
					margin-bottom: 20px;

					> .mm-chip-wrapper {
						display: flex;
						flex-wrap: wrap;
						column-gap: 10px;
						row-gap: 10px;

						> .mm-chip {
							border: 1px solid $color-secondary-2;
							border-radius: 20px;
							padding: 3px 11px;

							&.is-highlight {
								position: relative;
								background-color: $color-primary-1;
								padding-left: 30px;

								&:after {
									@include main-icon-star;
									content: '';
									position: absolute;
									left: -2px;
									top: -7px;
									width: 40px;
									height: 40px;
									transform: scale(0.5);
								}
							}
						}
					}
				}

				> .mm-result-item-wrapper {
					@include mq(tablet) {
						display: flex;
						column-gap: 50px;
						justify-content: space-between;
					}

					> .mm-content-wrapper {
						> .mm-text-wrapper {
							@include mq(tablet) {
								display: grid;
								grid-template-columns: 160px 1fr;
								column-gap: 20px;
							}

							.mm-distance {
								font-size: 14px;
								margin-bottom: 10px;

								> span {
									position: relative;
									padding-left: 20px;

									&:after {
										content: '';
										@include fs-location();
										transform: scale(0.8);
										position: absolute;
										left: -16px;
										top: -12px;
									}
								}

								&--bottom {
									margin-top: 10px;

									> span {
										&:after {
											transform: scale(1);
											top: -15px;
										}
									}
								}
							}

							.mm-headline-wrapper {
								margin-bottom: 10px;

								@include mq(tablet) {
									margin-bottom: 5px;
									grid-row: 1;
									grid-column: 2;
								}

								.mm-subheadline {
									display: block;
								}
							}

							.mm-image {
								float: left;
								margin-right: 15px;

								@include if-parent('.m-ds-result.m-ds-result--mobile-no-image') {
									display: none;
								}

								@include mq(tablet) {
									float: none;
									grid-row: span 3;
									grid-column: 1;
									margin-bottom: 0;

									@include if-parent('.m-ds-result.m-ds-result--mobile-no-image') {
										display: inline-block;
									}
								}

								img {
									@include mq(tablet) {
										display: block;
									}
								}
							}

							.mm-info {
								@include mq(tablet) {
									grid-row: 2;
									grid-column: 2;
								}
							}
						}
					}

					> .mm-action {
						min-width: 225px;
						margin-top: 20px;

						@include mq(tablet) {
							margin-top: 0;
						}

						> .mm-action-date {
							margin-top: 20px;
							margin-bottom: 5px;
							position: relative;
							font-size: 14px;
							padding-left: 20px;

							@include mq(tablet) {
								min-height: 20px;
								margin-top: 0;
								margin-bottom: 15px;
							}

							&:after {
								content: '';
								@include fs-calendar-check();
								position: absolute;
								left: -10px;
								top: -8px;
							}
						}

						> .mm-action-price {
							margin-bottom: 15px;
							font-size: 18px;

							@include mq(tablet) {
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}
}