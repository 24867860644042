/* a-basic-input-text--xl */
.a-basic-input-text--xl {
	width: calc(#{$form-width-general-mobile} - #{$form-width-xs + $form-gutter});

	@include mq(tablet) {
		width: calc(100% - #{$form-width-xs + $form-gutter});
	}

	// we are after XS
	.a-basic-input-text--xs + & {
		float: right;
	}

	.l-form-general & {
		width: 100%;
		float: none;
	}

	// exception .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration .l-form .ll-item-container & {
		width: 265px;
		max-width: 265px;

		@include mq(tablet) {
			width: 305px;
			max-width: 305px;
		}

		& + p.ll-inputformatinfo {
			text-align: left;
		}
	}

	.m-ves-rs-contentbox--damagedeclaration .l-form .ll-item-container--full > .ll-input-container & {
		width: 305px;
	}

	// exception for tourset inputs in xl
	.l-form--small-content & {
		width: 100%;
		max-width: 100%;
	}

	// exception .o-basic-form-overview inputs with an editButton
	.o-basic-form-overview .oo-fieldset .oo-row &.a-basic-input-text--edit {
		width: 100%;
		max-width: 340px;
	}
}