/* a-basic-input-select--imgpick  */
.a-basic-input-select--imgpick {
	border: 1px solid $color-formfield-border-standard;
	border-radius: 4px;

	&:hover {
		border: 1px solid $color-formfield-border-hover;
	}

	//exception old form
	.l-form & {
		border: none;
		border-bottom: 1px solid $color-secondary-9;
		border-radius: 0;
		height: auto;

		&:hover {
			border: none;
			border-bottom: 1px solid $color-secondary-9;
		}
	}

	> select {
		display: none;
	}

	> .aa-btn-img-selection {
		display: block;
		width: 100%;
		height: 40px;
		position: relative;
		padding: 9px 0 0 15px;
		background: $color-secondary-7;
		@include milo-regular($font-size-16);
		text-decoration: none;
		cursor: pointer;

		//exception old form
		.l-form & {
			background: $color-secondary-8;
		}

		.l-form-general & {
			height: 53px;
			border-radius: 4px;
			padding-top: 16px;
			margin-bottom: -1.8px;
			background: $color-primary-2;
			padding-left: 14px;

			@include if-parent('.a-basic-input-select--imgpick.is-floating') {
				padding-top: 25px;
				transition: top 0.5s;
			}

			&:after {
				top: 5px;
			}

			&.is-opened {
				border-radius: 4px 4px 0 0;
			}

			&.has-img {
				padding-left: 60px;
			}

			& ~ .aa-col-label {
				@include if-parent('.a-basic-input-select--imgpick.is-floating') {
					top: 2px;
				}


				> .aa-label {
					@include if-parent('.a-basic-input-select--imgpick.is-floating') {
						color: $color-secondary-20;
						@include milo-regular($font-size-14);
					}

					.l-form-general .ll-row.is-check.is-error & {
						color: $color-action-alert;
					}
				}
			}
		}

		&.is-opened {
			&:after {
				@include main-icon-arrow-drop-up;
				background-repeat: no-repeat;
			}
		}

		&.has-img {
			padding-left: 55px;
		}

		&:after {
			content: " ";
			display: block;
			height: 28px;
			width: 28px;
			position: absolute;
			top: 0;
			right: 1px;
			@include main-icon-arrow-drop-down;
			background-repeat: no-repeat;
		}

		> .aa-imgbox {
			display: block;
			height: 25px;
			width: 40px;
			position: absolute;
			top: 8px;
			left: 10px;

			.l-form-general & {
				top: 17px;
				left: 14px;

				@include if-parent('.a-basic-input-select--imgpick.is-floating') {
					top: 25px;
				}
			}
		}

	}

	> .aa-img-options {

		display: none;
		width: 100%;
		max-width: 100%;
		height: 0;
		max-height: 210px;
		padding: 0;
		position: absolute;
		visibility: hidden;
		overflow: hidden;
		background: $color-secondary-7;
		border: 1px solid $color-secondary-5;
		border-top: 0;
		z-index: 51;

		.l-form-general & {
			right: -1px;
			min-width: 400px;
			border: 1px solid black;
			border-radius: 0 0 4px 4px;
			background: $color-primary-2;
			border-top: 1px solid $color-secondary-5;

			&:not(.jspScrollable) {
				border-top: none;

				ul.aa-img-options-list {
					> li {
						border-top: none;

						&:first-child {
							border-top: 1px solid $color-secondary-5;
							margin: 0 10px;
							padding-left: 45px;

							&.no-img {
								padding-left: 0;
							}

							> .aa-imgbox {
								left: 0;
							}

							&.active {
								margin: 0;
								padding-left: 35px;

								&.no-img {
									padding-left: 10px;
								}

								> .aa-imgbox {
									left: 10px;
								}
							}
						}
					}
				}
			}
		}

		ul {
			li {

				&:hover,
				.is-keyboard-focus &:focus {
					outline: none;
					background-color: $color-secondary-8;
				}
			}
		}

		&.is-opened {
			visibility: visible;
			height: auto;
		}

		// custom scrollbar styles
		&.jspScrollable {

			&:focus {
				outline: none;
			}

			> .jspContainer {

				//scrollable content wrapper
				> .jspPane {

					> .aa-img-options-list {
						padding-right: 17px;
					}

				}

				//scrollbar wrapper
				> .jspVerticalBar {
					width: 5px;
					right: 10px;

					> .jspArrow {
						background: $color-secondary-7;
						height: 15px;
						cursor: default;
					}

					> .jspTrack {
						background: $color-secondary-5;

						> .jspDrag {
							background: $color-secondary-2;
							border-bottom: 1px solid $color-primary-2;
						}
					}
				}

				> .jspHorizontalBar {
					display: none;
				}
			}
		}

		ul.aa-img-options-list {

			padding: 0 0 0 0;
			margin: 0;

			> li {
				position: relative;
				height: 40px;
				padding: 11px 0 0 55px;
				border-top: 1px solid $color-secondary-5;
				@include milo-regular($font-size-16);
				cursor: pointer;

				&:first-child {
					border-top: 0;
				}

				&.active {
					background: $color-secondary-5;
				}

				&.no-img {
					padding-left: 10px;
				}

				> .aa-imgbox {
					display: block;
					height: 25px;
					width: 40px;
					position: absolute;
					top: 10px;
					left: 10px;
				}


			}
		}
	}

	> .aa-col-label {
		.l-form-general & {
			position: absolute;
			z-index: 1;
			top: 16px;
			left: 15px;
			transition: top 0.5s;
		}

		> .aa-label {
			.l-form-general & {
				@include milo-regular($font-size-16);
				font-weight: normal;
				color: initial
			}
		}
	}
}