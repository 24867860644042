/* m-basic-text--leftreverse */
.m-basic-text--leftreverse {
	.mm-flex {
		@include mq(tablet) {
			display: block;
		}

		.mm-aside {
			@include mq(tablet) {
				float: right;
				margin: 0 20px 0 0;
			}

			@include mq(desktop) {
				margin: 0 40px 0 0;
			}

			+ .mm-content {
				@include mq(tablet) {
					float: none;
					width: inherit;
				}
			}
		}

		&--row-reverse {
			flex-direction: row;

			@include mq(tablet) {
				display: flex;
			}

			// exception for molecule: m-basic-teaser-dashboard--full
			.m-basic-teaser-dashboard--full & {
				display: flex;
				flex-flow: column nowrap;

				@include mq(tablet) {
					flex-flow: row nowrap;
					justify-content: space-between;
				}

				> .mm-content {
					@include mq(tablet) {
						width: 50%;
						padding-right: 20px;
					}

					> p {
						display: block;
						width: calc(100% - 1px);
						overflow: hidden;

						> strong {
							display: block;
							max-width: 400px;
						}
					}
				}

				> .mm-aside {
					order: 2;
					margin: 0;

					@include mq(tablet) {
						width: calc(50% - 20px);
						padding-left: 20px;
					}
				}
			}
		}
	}
}