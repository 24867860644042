.m-basic-help {
	position: fixed;
	right: 15px;
	bottom: 20px;
	z-index: 1500;

	@include mq(tablet) {
		bottom: 170px;
	}

	&.has-basic-form-footer-sticky {
		bottom: 270px;

		@include mq(tablet) {
			bottom: 200px;
		}
	}

	> .mm-trigger {
		height: 48px;
		width: 48px;
		background: $color-primary-1;
		cursor: pointer;
		border-radius: 50%;
		box-shadow: 0 0 7px 2px $color-secondary-4;
		color: $color-secondary-1;
		text-align: center;

		@include if-parent('.m-basic-help.is-open') {
			display: none;
		}

		&:hover {
			> span {
				&.mm-def {
					display: none;
				}

				&.mm-hover {
					display: block;
				}
			}
		}

		> span {
			&.mm-def {
				position: relative;
				height: 68px;
				display: block;

				&::before {
					content: '';
					@include boat-icon-hilfe;
					position: absolute;
					top: -10px;
					right: -10px;
				}

				img {
					display: none;
					padding-top: 4px;
					margin-top: -5px;
				}
			}

			&.mm-hover {
				@include milo-slab-bold(11px);
				line-height: 48px;
				display: none;
			}
		}
	}

	> .mm-content-layer {
		height: 100vh;
		width: 100vw;
		max-height: 100vh;
		position: fixed;
		background: $color-primary-2;
		left: 0;
		top: 0;
		padding: 10px 15px 0;
		border: 1px solid $color-primary-2;
		overflow-y: auto;
		overflow-x: hidden;
		opacity: 0;
		pointer-events: none;
		transition: all 0.5s;

		@include mq(tablet) {
			max-width: 375px;
			max-height: 585px;
			height: auto;
			right: 15px;
			top: 15px;
			left: auto;
			padding: 10px 10px 30px;
			position: fixed;
			border-radius: 8px;
			box-shadow: 0 0 7px 2px $color-secondary-4;
		}

		&.is-visible {
			pointer-events: auto;
			opacity: 1;
		}

		> .mm-navigation {
			position: relative;

			> .mm-close {
				cursor: pointer;
				position: absolute;
				right: -5px;
				top: -5px;
				z-index: 1;
				width: 40px;
				height: 40px;
				@include main-icon-close;
			}

			.mm-headline {
				@include milo-bold($font-size-18);
				padding: 35px 0 20px 0;
			}

			.mm-divider {
				border-top: 1px solid $color-secondary-9;
				margin-bottom: 15px;
			}

			.mm-wrapper {
				padding: 0 10px;
			}
		}
	}



	.jspContainer {

		//scrollbar wrapper
		> .jspVerticalBar {
			width: 5px;
			right: 0;

			> .jspTrack {
				background: $color-secondary-5;

				> .jspDrag {
					background: $color-secondary-2;
				}
			}
		}

		> .jspHorizontalBar {
			display: none;
		}
	}
}