.a-basic-rating--mid {

	> .aa-inner-wrap {
		padding-left: 29px;

		> .aa-inner {
			padding: 0 7px;
			@include font-size(19px);
		}
	}
}