/* o-basic-banderole--highlight */
.o-basic-banderole--highlight {
	padding-bottom: 10px;
	padding-top: 7px;
	background: $color-primary-1;
	margin: 0;
	display: block;

	.oo-headline {
		padding-bottom: 0;
		color: $color-secondary-1;
		@include milo-slab-bold($font-size-24, 28px);

		@include mq(tablet) {
			@include font-size($font-size-28);
		}
	}

	.swiper {
		margin-top: 10px;
		padding-top: 16px;
	}

	.m-basic-teaser-across {
		> article {
			background: $color-primary-2;

			.mm-article-content {
				.mm-header {
					.mm-attention {
						position: absolute;
						top: -18px;
						text-transform: uppercase;
						color: $color-secondary-1;
						right: 0px;
						background: 0;
						box-shadow: none;
						@include milo-regular($font-size-12);
					}
				}
			}
		}
	}
}