// .m-ves-rs-contentbox--grey
.m-ves-rs-contentbox--grey {
	background: $color-secondary-8;

	&:before,
	&:after {
		background: $color-secondary-8;
	}

	> .mm-tile-container {

		> .mm-tile {

			&--checkbox,
			&--radio {

				// style tile if input is checked
				> input:checked + label {
					&:after {
						@include main-icon-selected-small-grey;

						@include mq(tablet) {
							@include main-icon-selected-thin-grey;
						}
					}
				}
			}

			&--checkbox {
				> label {

					// initial icon
					&:after {
						@include main-icon-choose-small-grey;

						@include mq(tablet) {
							@include main-icon-choose-thin-grey;
						}
					}
				}
			}
		}
	}
}