/* .m-adac-cardamage--rework */
.m-adac-cardamage--rework {
	padding: initial;
	width: 100%;
	background-color: initial;

	&-layer {
		.mm-layeritem {
			.mm-timestamp {
				position: initial;
				padding-top: 5px;
				@include milo-regular($font-size-15);

				.h-roboto & {
					font-family: 'Roboto', sans-serif;
				}

				> span {
					color: $color-secondary-21;

					&:nth-child(2) {
						padding: 0 5px;
					}
				}
			}
		}
	}

	> .mm-content {

		> .mm-left,
		> .mm-right {
			min-width: calc(50% - 20px);
			border: 1px solid $color-secondary-16;
			border-radius: 8px;
			padding: 12px 8px;
			margin-bottom: 20px;

			@include mq(tablet) {
				padding: 20px 15px;
				margin-bottom: initial;
			}

			.mm-item,
			.mm-layeritem {
				margin-bottom: 12px;

				@include mq(tablet) {
					margin-bottom: 20px;
				}

				&.is-visible {

					+ .mm-bottom,
					+ .m-adac-maps {
						border-top: 1px solid $color-secondary-16;
						padding-top: 12px;

						@include mq(tablet) {
							padding-top: 20px;
						}
					}
				}

				> div:only-child {
					@include if-parent('.mm-item.is-visible') {
						margin-bottom: 0;
					}
				}

				> .mm-status-info {
					position: relative;
					max-width: 300px;
					padding-left: 65px;
					@include milo-regular($font-size-16);
					margin-bottom: 12px;

					@include mq(tablet) {
						margin-bottom: 20px;
					}

					.h-roboto & {
						font-family: 'Roboto', sans-serif;
					}

					+ .mm-infotext {
						border-top: 1px solid $color-secondary-16;
						padding-top: 12px;

						@include mq(tablet) {
							padding-top: 20px;
						}
					}

					> .mm-icon {
						position: absolute;
						left: 15px;
						top: -1px;

						@include mq(tablet) {
							top: 13px;
						}
					}

					.mm-timestamp {
						position: initial;
						padding-top: 5px;
						margin-bottom: 0;
						@include milo-regular($font-size-15);

						.h-roboto & {
							font-family: 'Roboto', sans-serif;
						}

						> span {
							color: $color-secondary-21;

							&:nth-child(2) {
								padding: 0 5px;
							}
						}
					}
				}

				> .mm-infotext {
					padding: 0 15px 0 7px;
				}
			}

			> .mm-bottom {

				> .mm-linklist {
					padding: 0 15px;
					margin-bottom: 12px;

					@include mq(tablet) {
						margin-bottom: 15px;
					}
				}
			}
		}

		> .mm-left {
			padding: 12px 8px 0 8px;

			@include mq(tablet) {
				padding: 20px 15px;
			}
		}
	}
}