/* m-basic-rating */
.m-basic-rating {
	background: $color-secondary-6;
	padding: 20px;

	> .mm-rating-content {
		display: flex;
		flex-direction: column;

		@include mq(tablet) {
			flex-direction: row;
		}

		> .mm-star-container {
			margin: 0 10px 10px 0;

			@include mq(tablet) {
				flex-grow: 1;
			}

			@include if-parent('.m-basic-rating--chart-rating') {
				justify-content: center;
			}

			> .mm-label {
				font-weight: bolder;
			}

			> div > .mm-star {
				width: 29px;
				height: 36px;
				display: inline-block;
				position: relative;

				&:before {
					content: '';
					@include main-icon-star-empty;
					position: absolute;
					top: -10px;
				}

				&--full {
					&:before {
						@include main-icon-star-full;
					}
				}

				&--half {
					&:before {
						@include main-icon-star-half;
					}
				}

				&:first-child {
					&:before {
						@include main-icon-star-full;
					}
				}
			}

			// exception m-basic-rating
			.ll-row > .ll-inline & {
				margin: 0;
			}
		}

		> .mm-quota {
			font-weight: bolder;
			margin-bottom: 15px;
			margin-right: 10px;

			@include mq(tablet) {
				margin-bottom: 0;
				flex-grow: 1;
			}

			> .mm-percentage {
				margin-top: -4px;
				display: inline-block;
				@include font-size($font-size-20);


				@include mq(tablet) {
					@include font-size($font-size-28);
				}
			}
		}

		> .mm-check24-logo {
			height: 42px;
			overflow: hidden;

			padding-top: 5px;

			@include mq(tablet) {
				flex-grow: 1;
			}

			> img {
				position: relative;
				top: -35px;
				max-width: 157px;
			}
		}
	}

	> .mm-buttons {
		width: 626px;

		> .a-basic-link {
			display: inline-block;
			padding-top: 4px;
			background-position: -10px 3px;
		}
	}
}