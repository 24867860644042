/* m-basic-socialshare--quiz */

.m-basic-socialshare--quiz {
	padding-top: 0;
	border-top: 0;
	display: flex;
	justify-content: flex-end;
	min-height: 42px;
	padding-right: 40px;
	border-bottom: 1px solid $color-primary-2;

	> .shariff {
		height: 30px;

		> .theme-grey {

			> li {
				margin-bottom: 0;
			}
		}
	}
}