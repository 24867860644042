/* .m-basic-hint--error */
.m-basic-hint--my-adac-phone {
	display: flex;
	flex-flow: column nowrap;

	// 16px for the minus margin
	width: calc(100% + 16px);
	margin: 0 -8px 24px -8px;
	padding: 24px 8px;
	border-top: 1px solid $color-secondary-9;
	border-bottom: 1px solid $color-secondary-9;

	.l-outer--my-adac & {
		margin: 0 -8px 24px -8px;
	}

	.m-basic-hint--my-adac & {
		border: none;
		padding: 24px 0 0 0;
		margin: 0 -8px 0 -8px;
		// for baschint-my-adac´s padding: 20px 15px 20px 40px;
		position: relative;
		left: -24px;
	}

	> .mm-phone-number-wrapper {
		display: flex;
		flex-flow: row nowrap;
		align-content: center;
		margin: 0 0 15px 0;

		> .mm-phone-img {
			position: static;
			flex: 0 0 20px;
			align-self: flex-start;
		}

		> .mm-phone-number-big {
			flex: 0 0 calc(100% - 20px);
			@include milo-slab-bold(24px);
			margin: 0;
			padding: 0 0 0 30px;
		}
	}
}