/* m-basic-gallery */
.m-basic-gallery {
	position: relative;
	display: block;

	&.is-enlarged {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1000;
		width: calc(100% - 50px);

		@include mq('tablet') {
			max-width: 900px;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			z-index: 2;
			top: 50%;
			left: 50%;
			width: calc(100% + 40px);
			height: calc(100% + 40px);
			transform: translate(-50%, -50%);
			background-color: $color-primary-2;
		}
	}

	> * {
		position: relative;
		z-index: 3;

		@include if-parent('.m-basic-gallery.is-invisible') {
			opacity: 0;
		}
	}

	> .js-soft-layer {
		opacity: 0;
		transition: opacity 1s;

		@include if-parent('.m-basic-gallery.is-enlarged') {
			content: '';
			display: block;
			opacity: 1;
			position: absolute;
			z-index: 1;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			transform: translate(-50%, -50%);
			background-color: transparentize($color-primary-3, 0.2);
		}
	}

	> .mm-enlarge-details {
		margin-bottom: 10px;
		display: none;

		@include if-parent('.m-basic-gallery.is-enlarged') {
			display: flex;
		}

		> .m-slide-count {
			flex: 1;
			text-align: center;
		}

		> .m-close {
			width: 24px;
			position: relative;
			cursor: pointer;

			&:after {
				content: '';
				@include main-icon-close();
				position: absolute;
				left: -10px;
				top: - 11px;
			}
		}
	}

	> .js-swiper {
		height: 164px;
		box-sizing: content-box;

		@include mq('tablet') {
			height: 336px;
		}

		@include if-parent('.m-basic-gallery.is-enlarged') {
			@include mq('tablet') {
				height: calc(100vh - 260px);
			}
		}

		> .swiper-wrapper {
			> .swiper-slide {
				img {
					height: 164px;
					width: auto;

					@include mq('tablet') {
						height: 336px;
					}

					@include if-parent('.m-basic-gallery.is-enlarged') {
						@include mq('tablet') {
							height: auto;
							max-width: 100%;
							max-height: 100%;
						}

						&.js-img {
							@include mq('tablet') {
								position: relative;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
							}
						}
					}
				}

				.mm-movie-container.is-init,
				.mm-poster-container,
				.mm-cloudinary-container,
				.mm-youtube-link-container,
				.mm-youtube-container {
					position: relative;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);

					.m-canvas {
						width: 100% !important;
						height: auto !important;
					}
				}

				&.has-youtube-video,
				&.has-cloudinary-video {
					width: 321px;

					@include mq(tablet) {
						width: 597px;
					}
				}
			}
		}
	}

	> .js-thumbnail-swiper {
		height: 102px;
		padding: 10px 0;
		display: none;

		@include mq('tablet') {
			display: block;
		}

		@include if-parent('.m-basic-gallery.m-basic-gallery--no-thumbnail') {
			@include mq('tablet') {
				display: none;
			}
		}

		@include if-parent('.m-basic-gallery.m-basic-gallery--no-thumbnail.is-enlarged') {
			@include mq('tablet') {
				display: block;
			}
		}

		> .swiper-wrapper {
			> .swiper-slide {

				> * {
					pointer-events: none;
				}

				&.swiper-slide-thumb-active {
					position: relative;

					&:before {
						content: '';
						display: block;
						position: absolute;
						border: 4px solid $color-primary-3;
						outline: 0;
						width: 100%;
						height: 100%;
						z-index: 100;
						top: 0;
						left: 0;
					}
				}

				img {
					height: 82px;
					width: auto;
				}

				.mm-link-description {
					display: none;
				}

				&.has-youtube-video,
				&.has-cloudinary-video {
					width: 292px;

					@include mq(tablet) {
						width: 80px;
					}

					@include mq(desktop) {
						width: 144px;
					}
				}

				&.has-youtube-video,
				&.has-youtube-link-video,
				&.has-immersive-3d-video,
				&.has-immersive-video,
				&.has-cloudinary-video {
					display: block;
				}
			}
		}
	}

	> .swiper {
		> .swiper-wrapper {
			background: $color-primary-2;

			> .swiper-slide {
				background: $color-secondary-5;
				display: inline;
				width: auto;

				.is-keyboard-focus &:focus-visible {
					border: 5px solid $color-primary-3;
					outline: 0;

					@include if-parent('.m-basic-gallery.is-enlarged') {
						border: none;
						outline: 0;
					}
				}

				&:focus-visible {
					@include if-parent('.m-basic-gallery.is-enlarged') {
						border: none;
						outline: 0;
					}
				}

				img {
					.is-keyboard-focus &:focus-visible {
						border: 5px solid $color-primary-3;
						outline: 0;

						@include if-parent('.m-basic-gallery.is-enlarged') {
							border: none;
							outline: 0;
						}
					}

					&:focus-visible {
						@include if-parent('.m-basic-gallery.is-enlarged') {
							border: none;
							outline: 0;
						}
					}
				}
			}
		}
	}

	> .mm-caption {
		font-size: 13px;
		margin-top: 5px;
		margin-bottom: 15px;

		@include mq(tablet) {
			margin-top: 0;
		}
	}

	> .mm-button-box {
		display: flex;
		flex-flow: column nowrap;
		padding: 20px 0 40px 0;
		border-bottom: 1px solid $color-secondary-9;

		@include mq(tablet) {
			flex-flow: row wrap;
			align-items: center;
			justify-content: flex-end;
			padding-top: 30px;
		}

		> :first-child {
			margin-bottom: 10px;

			@include mq(tablet) {
				margin-bottom: 0;
			}
		}

		> a {

			margin-bottom: 10px;

			@include mq(tablet) {
				margin: 0 20px;
			}

			&:first-of-type {
				@include mq(tablet) {
					margin-left: 10px;
				}
			}

			&:last-of-type {
				margin-bottom: 0;

				@include mq(tablet) {
					margin-right: 0;
				}
			}

			> img {
				display: block;
				max-width: 100%;
				max-height: 40px;
			}
		}
	}
}