/* m-my-adac-dashboard-box-layer */
.m-my-adac-dashboard-box-layer {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 105;
	border-radius: 10px;

	&.is-hidden {
		display: none;
	}

	// on resize, if boxes getting closed from tablet/mobile
	// hide the layer
	.m-my-adac-dashboard-list-showcase .mm-box:not(.is-extended) &,
	.m-my-adac-dashboard-box .mm-box:not(.is-extended) & {
		display: none;
	}

	// exception for theme-option-layer
	.mm-dashbox-theme-option-layer & {
		width: 350px;
		left: auto;
		right: 50%;
		background-color: transparent;

		@include mq(tablet) {
			right: 135px;
		}
	}

	> .mm-wrapper {
		position: relative;
		max-width: 340px;
		height: 100%;

		@include mq(desktop) {
			max-width: 370px;
		}

		> .mm-overlay {
			display: none;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			min-height: 100vh;
			z-index: 0;

			@include if-parent('.m-my-adac-dashboard-box-layer:not(.is-hidden)') {
				display: block;
			}
		}

		> .mm-layer-content {
			position: absolute;
			width: calc(100% - 20px);
			height: auto;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
			background-color: $color-primary-2;
			opacity: 1;
			margin: 0 10px;
			border-radius: 10px;
			z-index: 105;
			padding: 16px;

			// exception for theme-option-layer
			.mm-dashbox-theme-option-layer & {
				max-width: 320px;
				width: 100%;
				top: 0;
				left: calc(50% + 10px);
				transform: translate(0, 30px);
				box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.3);

				@include mq(tablet) {
					max-width: 340px;
					position: static;
					width: calc(100% - 20px);
					transform: translate(0, -15px);
				}
			}

			> .mm-close-button {
				width: 100%;
				height: 24px;
				position: relative;
				margin-bottom: 10px;

				// exception for theme-option-layer
				.mm-dashbox-theme-option-layer & {
					margin-bottom: 0;
				}

				> img {
					position: absolute;
					top: -4px;
					right: -4px;
					max-width: 100%;
					cursor: pointer;
				}
			}
		}
	}
}