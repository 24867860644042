.m-basic-table-toggle {
	position: relative;

	.swiper-container-wrap & {
		&:after {
			display: none;
		}
	}

	> .mm-table-content {

		&.is-hidden {
			visibility: hidden;
			height: 0;
			position: absolute;
		}

		> h3 {
			margin-bottom: 10px;
		}

		.is-cloned-header {
			height: 0;
			position: absolute;
		}

		.is-sticky-header {
			> .is-cloned-header {
				position: static;
				height: auto;
			}
		}

		.mm-filter-layer {
			position: absolute;
			width: 100%;
			z-index: 100;
			display: none;

			&.is-active {
				display: block;
			}

			&.is-sticky {
				position: fixed;
			}

			&:before {
				background: $color-secondary-17;
				opacity: 0.25;
				content: "";
				position: fixed;
				width: 100vw;
				height: 100vh;
				left: calc(50% - 50vw);
				top: 0;
				z-index: -1;
			}

			> .mm-layer-header {
				border-top-right-radius: 5px;
				border-top-left-radius: 5px;
				background: $color-primary-2;
				font-size: $font-size-14;
				display: table;
				padding-left: 4px;

				@include mq('tablet') {
					font-size: $font-size-16;
					padding-left: 55px;
				}

				> .mm-customize-view-active {
					display: table-row;

					> .mm-filter-icon {
						@include main-icon-customize-view-active;
						height: 36px;
						display: inline-block;
						left: -6px;
						position: relative;
						top: -2px;

						@include mq('tablet') {
							top: 1px;
							left: 2px;
						}
					}

					> .mm-filter-header-cnt {
						padding: 20px 5px 23px;
						width: 148px;
						display: table-cell;

						@include mq('tablet') {
							width: 200px;
							padding: 20px 0 23px 11px;
						}

						> div {
							float: left;

							&.mm-filter-layer-close {
								cursor: pointer;
								@include main-icon-close-dark-grey;
								padding-right: 5px;
								height: 30px;
								width: 34px;
								float: right;
								top: -10px;
								position: relative;

								@include mq('tablet') {
									height: 29px;
									margin-right: 15px
								}
							}
						}
					}
				}
			}

			> .mm-layer-content {
				background: $color-primary-2;
				border-bottom-right-radius: 10px;
				border-bottom-left-radius: 10px;
				padding: 20px 15px 15px;

				> .mm-filter-form {
					display: flex;
					flex-direction: column;

					@include mq('tablet') {
						flex-direction: row;
					}

					> .mm-form-col {
						position: relative;
						padding-bottom: 40px;

						@include mq('tablet') {
							padding-left: 20px;
							width: 50%;
						}

						&:first-child {
							border-bottom: 1px solid $color-secondary-16;
							padding-left: 0;
							padding-bottom: 0;
							margin-bottom: 10px;

							@include mq('tablet') {
								border-bottom: 0;
								margin-bottom: 0;
								border-right: 1px solid $color-secondary-16;
							}
						}

						&:only-child {
							padding-bottom: 40px;
							border-bottom: 0;

							@include mq('tablet') {
								border-right: 0;
								padding-left: 0;
								width: 100%;
							}

							> .mm-error-msg {
								display: none;
								padding: 0 0 0 30px;

								&:before {
									content: '';
									display: none;
									@include autoversicherung-icon-info;
									height: 31px;
									position: absolute;
									left: -3px;
								}

								&.is-active {
									display: block;
									position: absolute;
									right: 0;
									width: 50%;
									bottom: 70px;

									&:before {
										display: inline-block;
									}
								}
							}
						}

						> ul {
							padding: 0;
							margin: 0 0 100px;

							@include mq('tablet') {
								margin-bottom: 0;
							}

							@include if-parent('.mm-form-col:first-child') {
								margin-bottom: 0;

								@include mq('tablet') {
									margin: 0 0 170px;
								}
							}

							@include if-parent('.mm-form-col:only-child') {
								margin-bottom: 0;

								@include mq('tablet') {
									margin-bottom: 0;
								}
							}

							> li {
								display: block;
								margin-bottom: 10px;

								@include mq('tablet') {
									margin-bottom: 20px;
								}

								> .mm-list-label {
									margin-bottom: 10px;
									@include milo-bold();
								}

								> .mm-list-input {
									overflow: hidden;
								}

								> .mm-error-msg {
									display: none;
									padding: 0 30px;

									&:before {
										content: '';
										display: none;
										@include autoversicherung-icon-info;
										height: 31px;
										position: absolute;
										left: -3px;
									}

									&.is-active {
										display: block;
										position: absolute;

										@include mq('tablet') {
											position: relative;
										}

										&:before {
											display: inline-block;
										}
									}
								}

							}
						}

						> .mm-btn-ctn {
							position: absolute;
							bottom: 10px;
							width: 100%;

							@include mq('tablet') {
								right: 10px;
								width: auto;
							}
						}
					}
				}
			}
		}

		.mm-table {
			width: 100%;
			background: $color-primary-2;
			position: relative;
			border-radius: 10px;
			min-width: 0;

			thead {
				> tr {
					&:last-child {
						border-bottom: 1px solid $color-secondary-16;

						@include mq(print) {
							border-bottom: 1px solid $color-secondary-1;
						}

						> td {
							padding: 0 5px 10px;
						}
					}

					&:first-child:only-child {
						border-bottom: 1px solid $color-secondary-16;

						@include mq(print) {
							border-bottom: 1px solid $color-secondary-1;
						}
					}

					&:first-child {
						@include mq('tablet') {
							border-bottom: 1px solid $color-secondary-16;

							@include mq(print) {
								border-bottom: 1px solid $color-secondary-1;
							}
						}
					}
				}
			}

			tr {
				width: 100%;
				position: relative;

				td,
				th {
					&:first-child {
						@include mq(print) {
							display: none;
						}
					}
				}

				&:not(:last-child):not(:nth-last-child(2)):not(.js-toggle-btn) {
					border-bottom: 1px solid $color-secondary-16;

					@include mq(print) {
						border-bottom: 1px solid $color-secondary-1;
					}
				}

				&.js-toggle-row {
					height: 0;

					> td {
						&:nth-child(2) {
							@include mq(tablet) {
								padding-left: 20px;
							}
						}
					}
				}

				&.js-toggle-btn {
					cursor: pointer;

					.is-keyboard-focus &:focus {
						outline: 0;
						position: relative;

						&:nth-child(1) {
							border-top: 2px solid transparent; // for TAB controling highlighting
						}

						> td:nth-child(1) {
							&:before {
								outline: 2px solid $color-primary-3;
								border-radius: 3px;
							}
						}

						> td:nth-child(2) {
							&:before {
								content: '';
								display: block;
								position: absolute;
								top: -1px;
								left: 0;
								width: 100%;
								height: 2px;
								background-color: $color-primary-3;
								z-index: 100;
							}

							&:after {
								content: '';
								display: block;
								position: absolute;
								left: 0;
								bottom: 0;
								width: 100%;
								height: 2px;
								background-color: $color-primary-3;
							}
						}
					}

					&:first-child {
						border-top: 0;
					}
				}

				&.is-filter {

					&.is-filter-hidden {
						display: none;
					}
				}

				&.mm-row-headline {
					cursor: auto;
				}

				> th {
					padding: 10px 5px 10px 0;
					font-size: $font-size-14;

					@include mq('tablet') {
						padding: 20px;
						font-size: $font-size-16;
					}

					&:first-child {
						@include mq('tablet') {
							padding-left: 40px !important;
						}
					}

					> .mm-subline {
						@include milo-regular();
						min-width: 50px;

						@include mq('tablet') {
							margin-bottom: 15px;
						}
					}

					&.mm-customize-view {
						cursor: pointer;
						width: 30px;
						padding: 10px 0;
						position: relative;

						&:before {
							content: '';
							display: inline-block;
							@include main-icon-customize-view-inactive;
							position: absolute;
							top: 50%;
							left: -4px;
							transform: translateY(-50%);
						}

						@include mq(tablet) {
							width: 20px;

							&:before {
								left: auto;
								right: -10px;
							}
						}
					}

					&.mm-customize-text {
						text-align: left;
						cursor: pointer;
						padding: 20px 5px;
						@include milo-regular();

						@include mq('tablet') {
							padding: 20px;
							max-width: 100%;
						}
					}

					&.no-filter {
						text-align: left;
						padding-left: 0;
						vertical-align: bottom;

						@include mq('tablet') {
							padding-bottom: 56px;
						}
					}

					&.is-hidden {
						display: none;
					}

					> .a-basic-btn {
						display: none;

						@include mq('tablet') {
							display: block;
							text-align: center;
						}
					}
				}

				> td {
					text-align: center;
					padding: 10px 5px;
					font-size: $font-size-14;
					vertical-align: top;
					max-width: 90px;
					box-sizing: content-box;
					overflow: hidden;
					text-overflow: ellipsis;

					@include mq('tablet') {
						padding: 15px 20px;
						font-size: $font-size-16;
						max-width: 100%;
						overflow: inherit;
						text-overflow: inherit;
					}

					@include mq(print) {
						padding: 15px 5px;
					}

					@include if-parent('tr.mm-row-headline') {
						padding: 30px 10px;
						text-align: left;
					}

					&:not(:first-child) {
						@include mq('tablet') {
							min-width: 100px;
						}

						@include mq(print) {
							min-width: 0;
						}
					}

					&:first-child {
						@include mq('tablet') {
							padding-left: 40px !important;
						}
					}

					&.is-desc {
						text-align: left;
					}

					@include if-parent('tr.js-toggle-row') {
						padding: 0 5px 0 5px;

						@include mq('tablet') {
							padding: 0 20px;
						}
					}

					&.mm-table-arrow {
						position: relative;
						width: 30px;
						height: 100%;
						padding: 0;

						&:before {
							content: '';
							position: absolute;
							@include main-icon-keyboard-arrow-down;
							top: -3px;
							left: -4px;
						}

						@include mq('tablet') {
							width: 20px;

							&:before {
								left: auto;
								right: -10px;
								top: 4px;
							}
						}

						@include if-parent('tr.is-active') {
							&:before {
								@include main-icon-keyboard-arrow-up;
							}
						}
					}

					&.is-hidden {
						display: none;
						@include main-icon-keyboard-arrow-up;
					}


					> .mm-toggle-content {
						height: 0;

						@include if-parent('tr:not(.is-active)') {
							overflow: hidden;
						}
					}

					> .mm-positive,
					> .mm-negative {
						position: relative;
						width: 24px;
						height: 24px;
						overflow: hidden;
						left: 50%;
						transform: translateX(-50%);

						&:after {
							content: '';
							position: absolute;
							left: -10px;
							top: -10px;
						}

						> img {
							display: none;
						}
					}

					> .mm-positive {
						&:after {
							@include main-icon-check-green;
						}
					}

					> .mm-negative {
						&:after {
							@include main-icon-close-red;
						}
					}
				}
			}

			tfoot {
				border-top: 1px solid $color-secondary-16;

				@include mq(print) {
					border-top: 1px solid $color-secondary-1;
				}

				> tr:last-child {
					border-bottom: 0;

					> td {
						padding: 20px 0 0;
					}
				}

				.a-basic-btn {
					display: none;

					@include mq('tablet') {
						display: inline-block;
					}
				}
			}
		}

		.mm-row-btn {
			text-align: center;
			padding: 20px 10px;
			margin-top: 20px;

			> button {
				display: inline;
			}
		}
	}

	&.is-open {
		.js-basic-table-header-clone-wrap {
			.mm-table {
				tr {
					&.is-filter {
						display: table-row !important;
					}
				}
			}
		}
	}
}

.js-basic-table-header-clone-wrap {
	margin-top: 0;
}

.js-main-border {
	border: 1px solid $color-secondary-16;
	border-radius: 10px;

	@include mq(tablet) {
		padding: 0 30px;
	}

	@include mq(print) {
		padding: 0;
		border: 2px solid $color-secondary-1;
	}
}