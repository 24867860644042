/* m-basic-newsbox--searchresult */
.m-basic-newsbox--searchresult {
	border-bottom: none;

	&.h-space-s {
		margin-bottom: 0;
	}

	&:last-of-type {
		border-bottom: 1px solid $color-secondary-5;
	}

	&:only-child {
		border-bottom: none;

		&:not(.h-space-m):not(.h-space-l) {
			margin-bottom: 0;
		}
	}

	& + .m-basic-newsbox {
		padding-top: 0;
	}

	// Excelption keywordfilter lexicon
	.m-basic-keywordfilter--lexicon & {
		padding-left: 10px;
		padding-right: 10px;
		border-bottom: 0;

		&:first-of-type {
			border-top: 0;
		}
	}

	.m-basic-keywordfilter--lexicon .mm-lexicon-category &.is-hidden + .m-basic-newsbox--searchresult {
		border-top: 0;
	}

	// if backToTop anchor is displayed, limit width
	> .mm-content {
		&:not(:only-child) {
			@include mq(tablet) {
				max-width: calc(100% - 90px);
			}
		}
	}

	// exception for mm-content-link
	&:not(.m-basic-newsbox--img) {
		> .mm-content-link {
			height: calc(100% - 16px);
		}
	}

	> .mm-btnWrap {
		text-align: right;

		@include mq(tablet) {
			position: absolute;
			right: 0;
		}

		> .a-basic-link--top {
			display: inline-block;
		}
	}
}