/* a-basic-date-range */
.a-basic-date-range {
	display: block;
	max-width: 400px;
	min-width: 220px;
	width: 100%;

	&--fit {
		max-width: 220px;
	}

	> .aa-date-wrapper {
		display: flex;
		position: relative;

		> .a-basic-input-text {
			flex: 1 1 100%;

			&:nth-child(1) {
				max-width: calc(50% - 15px);
			}

			&:nth-child(2) {
				max-width: calc(50% + 15px);
			}
		}

		> .js-date {
			width: 45px;
			height: 40px;
			position: absolute;
			right: 0;
			top: 0;

			> span {
				@include main-icon-event-dark-grey;
				display: block;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}

// datepicker class
.datepicker-is-in-range {
	background-color: $color-primary-1 !important;
}