.m-basic-linklist {

	> .mm-flex-wrap {
		margin: 0;
		padding: 0;
		width: 100%;

		> .mm-flex-inner {
			@include relative();

			@include mq(tablet) {
				margin-right: 70px;
				float: left;
				width: calc(100% / 3);
				max-width: 150px;
			}

			@include mq(desktop) {
				width: calc(100% / 6);
				min-width: 140px;
			}

			> .a-basic-link {
				background: none;
				padding: 0;

				> figure {
					@include absolute($top: 0, $left: 0);
					margin: 0;

					@include mq(tablet) {
						top: -5px;
					}

					> img {
						margin-right: 0;
					}

					+ .mm-description {
						display: block;
						margin-bottom: 12px;
						padding: 0;
						transform: translate(55px, 5px);

						&:before {
							background: none;
						}

						@include mq(tablet) {
							transform: translate(55px, 0);
						}
					}
				}

				> .mm-description {
					display: flex;
					margin: 0 0 12px 0;
					padding: 0 0 0 12px;
				}
			}
		}
	}
}