/* l-footer */
.l-footer {
	background: $color-footer-background;
	min-height: 76px;

	@include mq(tablet) {
		min-height: 80px;
	}

	@include mq(print) {
		display: none;
	}

	.ll-footer-center {
		position: relative;
		max-width: $layout-header-center;
		margin: 0 auto;
		padding: 0 10px;
	}

	&.is-conversion {
		min-height: auto;
		z-index: 105;
	}

	&.is-hidden {
		display: none;
	}
}