/* m-my-adac-dashboard-list--block */
.m-my-adac-dashboard-list--block {
	> .mm-wrapper {
		> .mm-list-wrapper {
			column-gap: 20px;

			@include mq(desktop) {
				column-gap: 30px;
			}

			> .mm-left-list-wrapper {
				text-align: left;
				width: calc(40% - 10px);
				flex: 0 0 calc(40% - 10px);
				word-break: break-word;
				border: none;

				@include mq(tablet) {
					width: calc(30% - 15px);
					flex: 0 0 calc(30% - 15px);
				}

				> .mm-list-point-headline {
					color: $color-secondary-21;
				}
			}

			> .mm-right-list-wrapper {
				width: calc(60% - 10px);
				flex: 0 0 calc(60% - 10px);
				word-break: break-word;

				@include mq(tablet) {
					width: calc(70% - 15px);
					flex: 0 0 calc(70% - 15px);
				}

				&.mm-has-info-icon {
					flex: 0 0 auto;
					display: inline-block;
					width: auto;
				}
			}
		}
	}
}