/* m-layout-main-nav-left */
.m-layout-main-nav-left-my-adac {
	width: calc(100% - 40px);
	margin: 0 auto 20px auto;
	padding-top: 15px;

	@include mq(tablet) {
		width: 100%;
		margin-left: 0;
	}

	@include mq(desktop) {
		margin: 0 70px 0 0;
		max-width: 310px;
	}

	@include mq(desktop-l) {
		padding-top: 30px;
	}

	&.is-hidden {
		opacity: 0;
		visibility: hidden;
		transition: all 0.25s ease;
	}

	> ul {
		display: none;
		position: absolute;
		top: 70px;
		z-index: 20;
		width: calc(100% - 40px);
		max-width: 500px;
		padding: 0;
		border: 2px solid $color-primary-3;
		border-width: 0 2px 2px 2px;
		border-radius: 0 0 4px 4px;
		box-shadow: 0 8px 10px $color-secondary-4;

		@include mq(desktop) {
			padding: 0;
			display: block !important;
			width: 100%;
			position: static;
			border: 0;
			box-shadow: none;
		}

		> li {
			position: relative;
			background: $color-primary-2;

			&:first-child {
				padding-top: 12px;

				@include mq(desktop) {
					padding-top: 0;
				}


				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 5px;
					width: calc(100% - 10px);
					height: 1px;
					background: $color-secondary-9;

					@include mq(desktop) {
						display: none;
					}
				}
			}

			&:last-child {
				padding-bottom: 12px;

				@include mq(desktop) {
					padding-bottom: 0;
				}
			}

			> a {
				@include font-size($font-size-16);
				position: relative;
				padding: 6px 17px 5px 17px;
				text-decoration: none;
				display: flex;
				width: 100%;
				align-items: center;
				column-gap: 15px;
				background: $color-primary-2;

				@include mq(desktop) {
					border: 1px solid $color-secondary-16;
					border-bottom: 0;
					padding: 15px 20px;
				}

				@include if-parent('li:first-child') {
					@include mq(desktop) {
						border-top-left-radius: 4px;
						border-top-right-radius: 4px;
					}
				}

				@include if-parent('li:last-child') {
					@include mq(desktop) {
						border-bottom-left-radius: 4px;
						border-bottom-right-radius: 4px;
						border-bottom: 1px solid $color-secondary-16;
					}
				}

				.is-keyboard-focus &:focus {
					outline: 0 !important;
					padding: 4px 15px 3px 15px;
					border: 2px solid $color-primary-3;
					border-radius: 4px;

					@include mq(desktop) {
						padding: 14px 19px;
					}

					&.is-active {
						@include mq(desktop) {
							border-left: 4px solid $color-primary-3;
							border-top-left-radius: 0;
							border-bottom-left-radius: 0;
							padding: 14px 19px 14px 17px;
						}
					}
				}

				&:hover {
					@include milo-bold($font-size-16);
					background: $color-primary-1;
					color: $color-primary-3;

					@include mq(desktop) {
						@include milo-regular($font-size-16);
						border: 1px solid $color-primary-1;
						border-bottom: none;
					}

					@include if-parent('li:last-child') {
						@include mq(desktop) {
							border: 1px solid $color-primary-1;
						}
					}
				}

				&.is-active {
					@include milo-bold();
					background: $color-primary-1;
					color: $color-primary-3;

					@include mq(desktop) {
						@include milo-regular();
						border: 1px solid $color-secondary-24;
						border-left: 4px solid $color-primary-3;
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
						padding: 15px 20px 15px 17px;
						font-weight: normal;
					}
				}

				> .mm-alert {
					@include milo-regular(14px);
					line-height: 22px;
					display: inline-block;
					background-color: $color-primary-1;
					padding: 0 10px;
					border-radius: 12px;

					@include if-parent('a.is-active') {
						background-color: $color-primary-2;
					}

					&--icon {
						width: 30px;
						height: 30px;
						border-radius: 50%;
						padding: 0;
						margin-left: 10px;

						@include mq(desktop) {
							margin-left: 0;
						}
					}
				}
			}
		}
	}

	.mm-btn {
		@include milo-regular(17px);
		position: relative;
		margin-top: 5px;
		width: 100%;
		height: 55px;
		max-width: 500px;
		cursor: pointer;
		text-align: left;
		outline: none;
		padding: 12px 50px 12px 18px;
		border: 1px solid $color-secondary-16;
		border-radius: 4px;
		background: $color-primary-2;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		display: flex;
		align-items: center;
		column-gap: 20px;

		@include mq(desktop) {
			display: none;
		}

		&:before {
			content: '';
			@include main-icon-arrow-drop-down;
			position: absolute;
			right: 0;
			top: 4px;

			@include mq(desktop) {
				display: none;
			}
		}

		&.is-open {
			border: 2px solid $color-primary-3;
			padding: 11px 49px 11px 17px;
			color: $color-secondary-20;

			&:before {
				@include main-icon-arrow-drop-up;
				top: 3px;

				@include mq(desktop) {
					display: none;
				}
			}
		}

	}

	.mm-loader {
		display: block;
		position: absolute;
		right: 5px;
		top: 50%;
		width: 24px;
		height: 24px;
		margin: -12px 0 0 0;
		@include main-icon-refresh;

		// spin animation
		-webkit-animation: spin 0.8s infinite linear;
		animation: spin 0.8s infinite linear;

		@keyframes spin {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}

			100% {
				-webkit-transform: rotate(359deg);
				transform: rotate(359deg);
			}
		}

		@-webkit-keyframes webkit-spin {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}

			100% {
				-webkit-transform: rotate(359deg);
				transform: rotate(359deg);
			}
		}

		@-moz-keyframes moz-spin {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}

			100% {
				-webkit-transform: rotate(359deg);
				transform: rotate(359deg);
			}
		}

		@-o-keyframes o-spin {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}

			100% {
				-webkit-transform: rotate(359deg);
				transform: rotate(359deg);
			}
		}
	}
}