/* m-basic-list--teaser-grid & m-basic-list--teaser-grid-border */
.m-basic-list--teaser-grid {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	row-gap: 30px;
	padding: 0;

	// im-basic-list--teaser-grid-border mplemented here instead of own file because of compiler fuck up. Alternative
	// would have been to make everything !important
	&.m-basic-list--teaser-grid-border {
		row-gap: 15px;

		@include mq(tablet) {
			grid-template-columns: repeat(2, minmax(min-content, 50%));
			column-gap: 15px;
		}
	}

	@include mq(tablet) {
		display: grid;
		justify-content: space-between;
		grid-template-columns: repeat(3, minmax(min-content, 250px));
		column-gap: 30px;
		row-gap: 40px;
	}

	> li {
		display: block;

		@include if-parent('.m-basic-list--teaser-grid.m-basic-list--teaser-grid-border') {
			width: 100%;
		}

		> a {
			@include if-parent('.m-basic-list--teaser-grid.m-basic-list--teaser-grid-border') {
				text-decoration: none;
				display: block;
				border: 1px solid $color-secondary-5;
				border-radius: 8px;
				padding: 15px 25px;
				position: relative;

				.is-keyboard-focus &:focus {
					outline: none;
					border-radius: 8px;
					border: 1px solid $color-primary-3;
					box-shadow: 0 0 0 1px $color-primary-3;
				}

				@include mq(tablet) {
					height: 195px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				&:before {
					@include main-icon-arrow-right-grey2;
					position: absolute;
					content: '';
					display: inline-block;
					right: 0;
					top: 50%;
					transform: translateY(-50%);

					@include mq(tablet) {
						display: none;
					}
				}
			}
		}

		span {
			display: block;

			&.mm-head {
				margin-bottom: 10px;
				column-gap: 20px;
				display: flex;
				align-items: center;

				@include mq(tablet) {
					flex-direction: column;
				}

				@include if-parent('.m-basic-list--teaser-grid.m-basic-list--teaser-grid-border') {
					margin-bottom: 0;
				}

				> img {
					height: 24px;
					width: 24px;

					@include mq(tablet) {
						height: 48px;
						width: 48px;
						margin-bottom: 10px;
						display: block;
					}

					@include if-parent('.m-basic-list--teaser-grid.m-basic-list--teaser-grid-border') {
						@include mq(tablet) {
							margin-bottom: 35px;
						}
					}
				}

				> .mm-headline {
					@include milo-bold(20px);
					padding: 0;
					display: flex;
					align-items: center;
					column-gap: 10px;

					@include mq(tablet) {
						@include milo-bold(24px);
						text-align: center;
					}

					@include if-parent('.m-basic-list--teaser-grid.m-basic-list--teaser-grid-border') {
						@include milo-regular(18px);

						@include mq(tablet) {
							@include milo-bold(20px);
						}
					}

					> .mm-alert {
						@include milo-regular(14px);
						line-height: 22px;
						display: inline-block;
						background-color: $color-primary-1;
						padding: 0 10px;
						border-radius: 12px;

						&--icon {
							width: 30px;
							height: 30px;
							border-radius: 50%;
							padding: 0;
						}
					}
				}
			}

			&.mm-text {
				@include mq(tablet) {
					text-align: center;
				}
			}
		}
	}
}