/* m-basic-rating--banderole */
.m-basic-rating--banderole {
	width: 267px;
	height: 240px;
	background: white;
	padding: 0;
	margin-right: 20px;

	> .mm-content-wrapper {
		padding: 15px;
		height: 200px;
		border-bottom: 1px solid $color-secondary-5;

		> .mm-star-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-bottom: 20px;

			> .mm-label {
				font-weight: bolder;
				margin-bottom: 8px;
			}

			> .mm-stars {
				margin-bottom: -1px;
				margin-left: -17px;
				height: 41.3906px;

				> .mm-star {
					width: 29px;
					height: 100%;
					display: inline-block;
					position: relative;

					&:before {
						content: '';
						@include main-icon-star-empty-black;
						position: absolute;
						top: -10px;
					}

					&--full {
						&:before {
							@include main-icon-star-fill-black;
						}
					}

					&--half {
						&:before {
							@include main-icon-star-half-black;
						}
					}

					&:first-child {
						&:before {
							@include main-icon-star-fill-black;
						}
					}
				}
			}

			> .mm-mark {
				font-weight: bolder;
				@include font-size($font-size-32);
				margin-top: -6px;
			}
		}

		> .mm-quote {
			@include line-height($font-size-17);
		}
	}

	> .mm-rate {
		padding: 8px 10px 10px 10px;
	}
}