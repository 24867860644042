/* a-basic-input-text--l */
.a-basic-input-text--l {
	width: calc(#{$form-width-general-mobile} - #{$form-width-s-mobile + $form-gutter});

	@include mq(tablet) {
		width: calc(100% - #{$form-width-s + $form-gutter});
	}

	// we are after S
	.a-basic-input-text--s + & {
		float: right;
		width: calc(#{$form-width-general-mobile} - #{$form-width-s-mobile + $form-gutter});
	}

	.l-form-general &:not(.a-basic-input-text--right-border) {
		float: none;
		width: 100%;
	}

	// exception phone combination
	.l-form-general .a-basic-input-text--right-border + & {
		width: calc(100% - #{$form-width-s});
	}

	.m-basic-radius-search .l-form-general & {
		margin-right: 20px;
	}


	// exception for cardamage-forms
	.l-form .ll-row--cardamage .ll-col-label ~ .ll-col-data & {

		width: 100%;
		max-width: 320px;

		@include mq(tablet) {
			width: 320px;
			max-width: none;
		}

		@include mq(desktop) {
			width: 330px;
		}
	}

	// exception .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration .l-form .ll-item-container--full > .ll-input-container & {
		width: 100%;
		max-width: 170px;

		@include mq(tablet) {
			width: 210px;
			max-width: 210px;
		}
	}
}