.m-basic-calculator-content-box--nospecwrap,
.m-basic-calculator-content-box-newform--nospecwrap {

	.mm-tile-container,
	.mm-tile-container.is-odd {
		flex-direction: row;
		padding: 0;

		> .mm-tile {

			margin-bottom: 20px;

			&-empty {
				width: 140px;
				margin-bottom: 0;
			}

			> label {
				height: 65px;
				width: 140px;
				display: flex;

				> .mm-text-container {
					display: inline;
					text-align: center;
				}
			}
		}
	}

	.mm-tile-container.is-odd {
		> .mm-tile {
			&-empty {
				margin-bottom: 0;
			}
		}
	}
}