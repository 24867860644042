// m-ds-result-time
.m-ds-result-time {
	margin-bottom: 30px;

	> .l-form-general {

		> .ll-row {
			width: 100%;
			max-width: inherit;
		}
	}

	.mm-wrapper {
		border: 1px solid $color-secondary-16;
		border-radius: 8px;
		margin-bottom: 30px;
		overflow: hidden;
		padding-left: 1px;

		> table {
			width: 100%;
			border-collapse: collapse;

			thead {
				display: none;

				@include mq(tablet) {
					display: contents;
				}
			}

			tbody {
				tr {
					border-top: 1px solid $color-secondary-16;
					cursor: pointer;

					.is-keyboard-focus &.is-focus {
						border: 1px solid $color-primary-3;
					}

					&.is-active {
						background-color: $color-primary-1;
						border: 1px solid $color-primary-1;
					}
				}
			}

			td,
			th {
				display: block;
				text-align: left;
				padding: 5px 15px;

				@include mq(tablet) {
					padding: 15px;
					display: table-cell;
				}

				&:first-child {
					padding-top: 15px;

					@include mq(tablet) {
						padding-left: 40px;
					}
				}

				&:last-child {
					padding-bottom: 15px;

					@include mq(tablet) {
						padding-right: 40px;
					}
				}

				> .mm-input {
					position: relative;

					input {
						position: absolute;
						z-index: 0;
						top: 0;
						left: 0;
						opacity: 0;

						&:checked + label {
							&:after {
								border-color: $color-primary-3;
								background-color: $color-primary-1;
							}

							&:before {
								display: block;
							}
						}
					}

					label {
						position: relative;
						padding-left: 30px;

						&:after {
							content: '';
							position: absolute;
							left: 0;
							top: -2px;
							background-color: $color-primary-2;
							width: 20px;
							height: 20px;
							border: 2px solid $color-secondary-20;
							border-radius: 50%;
						}

						&:before {
							content: '';
							display: none;
							position: absolute;
							left: 5px;
							top: 3px;
							z-index: 1;
							background-color: $color-primary-3;
							width: 10px;
							height: 10px;
							border-radius: 50%;
						}
					}
				}

				> span {
					padding-left: 30px;

					@include mq(tablet) {
						padding-left: 0;
					}
				}
			}
		}

	}

	> .mm-btn-wrapper {
		display: flex;
		justify-content: flex-end;
	}
}