// .m-basic-info-layer
.m-basic-info-layer {
	display: block;
	position: relative;

	&.active {
		z-index: 20;
	}

	&--inline {
		display: inline;
	}

	.l-form-general .a-basic-input-textarea ~ & {
		position: absolute;
		right: 0;
		top: 15px;
	}

	.l-form-general .ll-row .a-basic-input-radio--haptik + &:not(:last-child) {
		margin-right: 40px;
	}

	//Exception headlline without wrapper & infolayer label
	.ll-label--infolayer &,
	.m-basic-calculator-content-box > h2 & {
		display: inline;
	}

	//Exception l-form-general
	.l-form-general .ll-row .ll-col-data label + & {
		margin: 0;
		display: inline-block;
	}

	> .mm-infolayer-icon {
		display: block;
		position: absolute;
		bottom: -11px;
		right: -35px;
		cursor: pointer;
		@include main-icon-info;
		z-index: 10;

		@include if-parent('.m-basic-info-layer.is-no-trigger') {
			display: none;
		}

		.is-keyboard-focus &:focus {
			outline: none;
			border: none;

			&:after {
				content: '';
				position: absolute;
				left: 7px;
				top: 7px;
				border: 2px solid $color-primary-3;
				width: 26px;
				height: 26px;
				border-radius: 50%;

			}
		}

		&.active {
			@include main-icon-info-yellow;
			bottom: -9px;

			//Exception components
			.m-basic-calculator-tiles &,
			.m-basic-text &,
			.m-basic-table & {
				bottom: -11px;
			}

			//Exception l-form-general
			.l-form-general .ll-row .ll-col-data label + & {
				bottom: -15px;
			}

			.l-form-general .ll-row .ll-col-data label & {
				bottom: -10px;
			}

			.l-form-general .ll-row.is-filled & {
				bottom: -3px;
			}

			.l-form-general .ll-row & {
				bottom: -15px;
			}

			.aa-unit & {
				bottom: -21px;
			}

			// Exception m-basic-calculator-content-box--image
			.m-basic-calculator-content-box--image .mm-tile & {
				bottom: -2px;

				@include mq(tablet) {
					bottom: -9px;
				}
			}
		}

		.a-basic-link & {
			bottom: -9px;
			right: -40px;
		}

		// Exception vreslist
		.m-ves-rs-list--combinations .mm-variant-teaser .mm-option-service & {
			bottom: -35px;
			right: auto;
			left: -40px;
		}

		.m-ves-rs-contentbox .l-form .a-basic-input-radio + & {
			bottom: -37px;
			right: 0;

			@include mq(tablet) {
				right: -25px;
			}
		}

		.m-ves-rs-contentbox .l-form .a-basic-input-text ~ & {
			bottom: 13px;
		}

		.m-ves-rs-contentbox .l-form .a-basic-input-text--date ~ & {
			bottom: 13px;
			right: -60px;
		}

		.ll-row.is-clone-select-row & {
			bottom: 14px;
		}

		legend &,
		h2 & {
			bottom: -9px;

			@include mq(tablet) {
				bottom: -5px;

				.m-basic-calculator-tiles & {
					bottom: -5px;
				}
			}

			@include if-parent('.mm-infolayer-icon.active') {
				bottom: -4px;

				.m-basic-calculator-tiles & {
					bottom: -4px;
				}
			}
		}

		h3 & {
			bottom: -9px;
		}

		h3 + & {
			right: -25px;

			@include mq(desktop) {
				right: -35px;
			}
		}

		//Exception l-form-general
		.l-form-general .ll-row & {
			bottom: -15px;
		}

		.l-form-general .ll-row .a-basic-input-radio--haptik + & {
			bottom: -10px;
		}

		.l-form-general .ll-row .ll-col-label & {
			bottom: -9px;
		}

		.l-form-general .ll-row .ll-label--infolayer & {
			bottom: -11px;
		}

		.l-form-general .ll-row.is-floating & {
			bottom: 0;
			top: 10px;
		}

		.l-form-general .ll-row.is-floating .m-basic-text & {
			top: unset;
			bottom: -11px
		}

		.l-form-general .ll-row .ll-col-data label & {
			bottom: -10px;
		}

		.l-form-general .ll-row.is-filled & {
			bottom: -5px;
		}

		.aa-unit & {
			bottom: -20px;
		}

		.mm-col-label & {
			bottom: -8px;
		}

		// exception .m-my-adac-dashboard-box-showcase .a-basic-btn
		.m-my-adac-dashboard-box-showcase .mm-wrapper .mm-content .a-basic-btn + & {
			right: -30px;
			bottom: 14px;
		}

		// Exception m-basic-calculator-content-box
		.m-basic-calculator-content-box .a-basic-input-text & {
			right: -25px;
		}

		.m-basic-calculator-content-box-newform .a-basic-input-text .aa-unit &,
		.m-basic-calculator-content-box .a-basic-input-text .aa-unit & {
			bottom: -21px;
			right: -25px;
		}

		.m-basic-calculator-content-box .mm-col-label--layer & {
			right: -25px;

			@include mq(tablet) {
				right: -35px;
			}
		}

		.m-basic-calculator-content-box .mm-tile & {
			position: relative;
			bottom: 6px;
			right: 0;

			@include mq(tablet) {
				position: absolute;
				bottom: -11px;
				right: -35px;
			}
		}

		.m-basic-calculator-content-box:not(.m-basic-calculator-content-box--image) .mm-tile-container.is-odd & {
			right: -15px;

			@include mq(tablet) {
				right: -35px;
			}
		}

		.m-basic-calculator-content-box .a-basic-input-checkbox & {
			bottom: 5px;
		}

		> span {
			display: none;
		}
	}

	> .mm-infolayer-link {
		cursor: pointer;
	}

	// styles for alert and error
	> .mm-infolayer-content {
		display: none;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 360px;
		padding: 25px 50px 40px 20px;
		margin: 18px 0 0 0;
		border: 1px solid $color-secondary-4;
		background: $color-primary-2;
		z-index: 2;
		pointer-events: auto;
		text-align: left;

		@include mq(tablet) {
			position: absolute;
			top: 0;
			left: 0;
			transform: initial;
		}

		&--error {
			border-color: $color-action-alert;
			color: $color-action-alert;

			a {
				color: $color-action-alert;
			}
		}

		&.pos-right {

			left: auto;
			right: 0;

			&:after {
				left: auto;
				right: 20px;
			}
		}

		&.hidden {
			display: block;
			visibility: hidden;
		}

		&.visible {
			display: block;
			z-index: 11;
		}

		.mm-anchor-layer-content {
			opacity: 0;
		}

		// Exception if layer is direct sibling of a-basic-input-radio
		.a-basic-input-radio + & {
			margin-top: 40px;
			margin-right: 150px;
		}

		//Exception for m-ves-rs-contenbox
		.m-ves-rs-contentbox .mm-tile > & {

			width: 280px;

			@include mq(tablet) {
				width: 300px;
			}
		}

		> .mm-info-head {
			display: block;
			@include milo-bold();
			// FF-addition to prevent aliasing
			font-weight: normal;

			.h-roboto & {
				font-family: 'Roboto Bold', sans-serif;
			}
		}

		> .mm-info-text {
			word-break: break-word;

			@include mq(tablet) {
				word-break: unset;
			}
		}

		> .mm-infolayer-close {
			position: absolute;
			top: 18px;
			right: 18px;
			width: 20px;
			height: 20px;
			padding: 0;
			font-size: 0;
			@include main-icon-close;
		}

		// Exception for use in a-basic-input-text
		.a-basic-input-text ~ & {
			margin-top: 24px;
		}
	}
}

// Exception for default-layer in lightbox
.ll-lightbox-inner {
	border: 1px solid $color-secondary-4;

	> .mm-infolayer-content {
		max-height: calc(100vh - 100px);
		overflow: auto;

		//Exception for detail Layer inspection
		&--inspection-details {
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: $color-primary-2;
				z-index: 1;

				@include mq(tablet) {
					border-radius: 8px;
				}
			}

			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100vw;
				height: 100vh;
				background: rgba(0, 0, 0, 0.2);
				transform: translate(-50%, -50%);
				z-index: 0;
			}

			> * {
				position: relative;
				z-index: 2;
			}

			> .mm-infolayer-close {
				display: none;
			}
		}

		> .mm-infolayer-close {
			outline: none;
		}

		> .mm-info-head {
			display: block;
			margin-bottom: 16px;
			@include milo-bold($font-size-20);

			.h-roboto & {
				font-family: 'Roboto Bold', sans-serif;
			}
		}

		.mm-info-text {
			display: inline-block;
			width: 100%;
		}
	}
}

.l-lightbox.is-small {
	width: auto;
	height: auto;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding-bottom: 1px;

	> .mfp-container {
		position: initial;
	}

	.jspContainer {

		//scrollable content wrapper
		> .jspPane {
			box-sizing: border-box;
		}

		//scrollbar wrapper
		> .jspVerticalBar {
			width: 5px;
			right: 0;

			> .jspTrack {
				background: $color-secondary-5;

				> .jspDrag {
					background: $color-secondary-2;
				}
			}
		}

		> .jspHorizontalBar {
			display: none;
		}
	}
}