/* .m-basic-hint--ekomi */
.m-basic-hint--ekomi {

	border: 1px solid $color-secondary-9;
	border-radius: 8px;
	padding: 20px 10px 20px 120px;

	@include mq(desktop) {
		padding: 40px 40px 40px 180px;
	}

	> img {
		top: 20px;
		left: 20px;
		max-width: 80px;

		@include mq(desktop) {
			top: 40px;
			left: 40px;
			max-width: 100px;
		}

	}
}