/* m-ves-tab--swipeable */
.m-ves-tab--swipeable {
	max-width: 100%;

	.mm-nav {
		&:after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 1px;
			background: $color-secondary-3;
		}

		> li {

			border-bottom: 1px solid $color-secondary-3;

			> a {
				margin-bottom: 0;
				padding-bottom: 5px;
			}
		}
	}

	// Exception for tabs with form and img-components
	> .mm-tabcontent {

		border-top: 0;

		> .mm-tabcontent-inside {

			> .l-form,
			> .l-form-general {
				.ll-row {
					max-width: 730px;
				}

				.l-main-cols {
					> .ll-main-cols-m {
						width: 100%;
						max-width: 360px;

						@include mq(tablet) {
							width: 50%;
						}

						&--first {
							border: 0;

							@include mq(tablet) {
								border-right: 10px solid transparent;
							}
						}

						&:not(.ll-main-cols-m--first) {
							border: 0;

							@include mq(tablet) {
								border-left: 10px solid transparent;
							}
						}

					}
				}
			}
		}
	}

	//exception when swiper is active
	.swiper {
		padding: 10px 0;

		> .swiper-wrapper {
			display: flex;
			width: 2000px;
			max-width: none;

			> .swiper-slide {
				width: auto;
			}
		}
	}
}