/* a-basc-link--layer-contact */
.a-basic-link--layer-contact {
	padding: 10px 0 10px 18px;

	&:before {
		top: -3px;
		transform: scale(1.6);
	}

	&:hover {
		text-decoration: none;
	}

	.aa-contact-layer {
		display: none;
	}
}