/* m-basic-footnote */
.m-basic-footnote--hint {
	position: relative;
	padding-left: 9px;

	.mm-hint {
		position: absolute;
		top: 0px;
		left: 0px;
	}
}