// m-ds-filter-time
.m-ds-filter-time {
	margin-bottom: 40px;

	.mm-wrapper {
		border-top: 1px solid $color-secondary-16;
		border-bottom: 1px solid $color-secondary-16;
		margin-bottom: 30px;
		padding: 20px 0;

		@include mq(tablet) {
			display: flex;
			row-gap: 20px;
			column-gap: 20px;
		}

		> .mm-item {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			max-width: 400px;

			&:not(:last-child) {
				margin-bottom: 20px;

				@include mq(tablet) {
					margin-bottom: 0;
				}
			}

			&:not(.mm-item--small) {
				flex: 1;
			}

			&--small {
				display: inline-block;
				width: auto;
			}
		}
	}

	> .mm-btn-wrapper {
		display: flex;
		justify-content: flex-end;
	}

	.mm-error {
		margin-bottom: 5px;
		color: $color-action-alert;
	}
}