/* .m-basic-hint */
.m-basic-hint {
	position: relative;
	padding: 15px 0 16px 30px;
	border-top: 1px solid $color-secondary-9;
	border-bottom: 1px solid $color-secondary-9;
	width: 100%;

	@include mq(tablet) {
		padding-top: 11px;
	}

	&.m-basic-hint--noicon {
		padding: 15px 0 15px 10px;
	}

	// Exception l-form ll-aside :: js-result-valid
	.l-form .ll-aside .js-result-valid & {
		margin-top: -11px;
		border-top: none;
	}

	// Exception l-form ll-aside :: js-result-valid
	.l-form .m-basic-calculator-content-box .ll-row .ll-col-data .a-basic-input-text--autocomplete ~ & {
		padding: 0 0 0 30px;
	}

	//exception .l-form-general .m-basic-calculator-content-box-newform
	.l-form-general .m-basic-calculator-content-box-newform & {
		max-width: 400px;
	}

	// Exception my-adac
	.l-outer--my-adac & {
		margin-left: -8px;
		margin-right: -8px;
		width: calc(100% + 16px);
		padding-left: 45px;
		padding-right: 15px;
	}

	// Exception my-adac + inside toggle
	.l-outer--my-adac .mm-my-adac-toggle & {
		margin: 0;
	}

	// Exeption my-adac-membershop-content if hind inside other hind
	.m-my-adac-membership-content .m-basic-hint & {
		margin-bottom: -35px;
	}

	> img {
		position: absolute;
		top: 20px;
		left: 2px;

		@include mq(tablet) {
			top: 14px;
			left: -3px;
		}

		// exception for myAdac
		.l-outer--my-adac-content & {
			@include if-parent('.m-basic-hint--nomyadac') {
				top: 14px;
				left: 2px;
			}
		}

		.m-my-adac-dashboard-box-showcase &,
		.m-my-adac-dashboard-box & {
			top: 22px;
			left: 5px;
		}
	}

	&.is-error {
		color: $color-action-alert;

	}

	// exception for phone numbers
	& p.mm-phone {

		> a {
			@include milo-bold($font-size-16, 21px);
			text-decoration: none;

			.h-roboto & {
				font-family: 'Roboto Bold', sans-serif;
			}
		}

	}

	//exception for tel-links
	a.mm-phone[href^="tel"] {
		text-decoration: none;
	}

	.mm-flex-container {
		display: flex;
		justify-content: space-between;
	}

	p {
		@include if-parent('.m-basic-hint.m-basic-hint--textleft') {
			text-align: left;
		}
	}
}