/* m-basic-list--my-adac */
.m-basic-list--my-adac {

	// exception for myAdac
	.l-outer--my-adac & {
		margin: 0 0 24px 0;
	}

	// exception for layers
	.mfp-container .mfp-content & {
		margin-left: 15px;

		&.h-space-s {
			margin-bottom: 15px;
		}
	}

	li {
		position: relative;
		height: auto;
		margin: 0;
		padding: 8px 0 8px 40px;
		background: none;
		@include font-size(16px, 20px);

		&:hover {
			text-decoration: none;
		}

		&:before {
			position: absolute;
			width: 8px;
			height: 8px;
			top: 14px;
			left: 0;
			content: '';
			background-color: $color-primary-1;
			border-radius: 50%;
		}

		@include if-parent('.m-basic-list--my-adac.m-basic-list--my-adac-black') {
			&:before {
				background-color: $color-secondary-17;
			}
		}
	}
}