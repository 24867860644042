/* m-basic-image--inline-block */
.m-basic-image--inline-block {
	display: inline-block;

	> figure {
		> .mm-stopper-wrapper {
			> .mm-ratio-container {
				> picture {
					width: auto;
					max-width: 100%;
					display: inline-block;

					> img {
						width: 100%;
					}
				}
			}
		}
	}
}