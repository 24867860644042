.basic-dropdown-layer {
	position: relative;
	width: 100%;

	.m-basic-keywordfilter--wide & {
		@include mq(tablet) {
			min-width: 400px;
		}
	}

	> .mm-head {
		position: relative;
		padding: 7px 50px 7px 15px;
		background: $color-secondary-8;
		cursor: pointer;
		border-bottom: 1px solid $color-secondary-3;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: -4px;
			right: 2px;
			@include main-icon-chevron-down;
			transform: scale(1.2);

			@include if-parent('.mm-head.is-open') {
				@include main-icon-chevron-up;
				top: -2px;
				right: 2px;
			}
		}
	}

	> .mm-layer {
		display: none;
		position: absolute;
		top: 100%;
		width: 100%;
		padding: 12px 15px;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		background: $color-secondary-8;
		z-index: 100;

		// exception
		.mm-mobile-layer.is-visible & {
			position: relative;
		}

		> .mm-category-wrapper {

			@include mq(tablet) {
				display: flex;
				flex-wrap: wrap;
			}

			> .mm-category {
				margin-bottom: 12px;

				@include mq(tablet) {
					width: 50%;
				}

				&:nth-child(2n+1) {
					padding-right: 10px;
				}

				> .mm-category-head {
					margin-bottom: 9px;
					@include milo-bold();
				}
			}
		}
	}
}