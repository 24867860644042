/* .m-basic-hint--center */
.m-basic-hint--center {
	position: relative;
	max-width: 100%;
	z-index: 10;
	padding: 13px 20px 13px 80px;
	margin-left: 0;
	margin-right: 0;

	@include mq('tablet') {
		padding: 23px 160px;
	}

	> img {
		left: 15px;

		@include mq('tablet') {
			left: 85px;
		}
	}
}