/* a-basic-stopper--my-adac */
.a-basic-stopper--my-adac {
	right: -8px;
	top: 24px;

	&.a-basic-stopper--my-adac-top-wrapper {
		right: -8px;
		top: -8px;
	}

	// only if the stopper has the class for positioning outside the normal wrapper
	// and positions itself under the mm-box-headline
	.m-my-adac-info-box--stopper-outside & {
		right: -8px;
		top: -8px;
		visibility: hidden;
		opacity: 0;

		&.is-shown {
			visibility: visible;
			opacity: 1;
			transition: opacity .1s ease-in;
		}

		.mm-box:not(.is-extended) & {
			visibility: hidden;
		}
	}

	// if the button exists in an info-box don´t need margin
	.a-basic-btn--my-adac-stopper & {
		left: calc(100% - 20px);
		top: 50%;
		transform: translate(0, -50%);

		> .aa-inner-content {
			min-width: 90px;
			white-space: nowrap;
			border: 2px solid $color-secondary-1;
			border-bottom-left-radius: 12px;
			border-top-right-radius: 12px;
			border-top-left-radius: 0;
			@include milo-bold();

			&:before,
			&:after {
				content: '';
				display: block;
				position: absolute;
				width: 0;
				height: 0;
				border-style: solid;
			}

			&::before {
				border-width: 9px 18px;
				border-color: transparent transparent transparent $color-secondary-1;
				top: -10px;
				bottom: auto;
				left: -2px;
			}

			&:after {
				top: -7px;
				bottom: auto;
				border-width: 8px 15px;
				border-color: transparent transparent transparent $color-primary-2;
				left: 0;
			}
		}
	}

	> .aa-inner-content {

		> .aa-headline {
			@include milo-bold(11px);
			margin: 0 0 3px 0;
		}

		> .aa-content {
			@include milo-bold();
			margin: 0 0 4px 0;

			.l-outer--my-adac & {
				margin: 0;
				@include milo-bold(18px);
			}

			.l-outer--my-adac-content & {
				margin: 0;
			}
		}

		> .aa-thirdline {
			@include milo-bold();
			max-height: 13px;
		}
	}
}