// svg sprite style sheet

    // svg src
    @mixin weather-svgSprite {
        background: url("/assets/img/svg-sprite/weather-sprite.svg") no-repeat;
    }

    // common name
    @mixin weather-sprite-icons {
        @include weather-svgSprite;
    }

    // svg-sprite icon mixin
    @mixin weather-cloudy {
        @include weather-sprite-icons;
        @include weather-cloudy-dim;
        background-position: 0 0;
    }

    // icon dimensions
    @mixin weather-cloudy-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-cloudy-night {
        @include weather-sprite-icons;
        @include weather-cloudy-night-dim;
        background-position: 15.113350125944585% 0;
    }

    // icon dimensions
    @mixin weather-cloudy-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-fog {
        @include weather-sprite-icons;
        @include weather-fog-dim;
        background-position: 0 16.666666666666668%;
    }

    // icon dimensions
    @mixin weather-fog-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-fog-night {
        @include weather-sprite-icons;
        @include weather-fog-night-dim;
        background-position: 15.113350125944585% 16.666666666666668%;
    }

    // icon dimensions
    @mixin weather-fog-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-freezingrain {
        @include weather-sprite-icons;
        @include weather-freezingrain-dim;
        background-position: 30.22670025188917% 0;
    }

    // icon dimensions
    @mixin weather-freezingrain-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-freezingrain-night {
        @include weather-sprite-icons;
        @include weather-freezingrain-night-dim;
        background-position: 30.22670025188917% 16.666666666666668%;
    }

    // icon dimensions
    @mixin weather-freezingrain-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-overcast {
        @include weather-sprite-icons;
        @include weather-overcast-dim;
        background-position: 0 33.333333333333336%;
    }

    // icon dimensions
    @mixin weather-overcast-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-overcast-night {
        @include weather-sprite-icons;
        @include weather-overcast-night-dim;
        background-position: 15.113350125944585% 33.333333333333336%;
    }

    // icon dimensions
    @mixin weather-overcast-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-partlycloudy {
        @include weather-sprite-icons;
        @include weather-partlycloudy-dim;
        background-position: 30.22670025188917% 33.333333333333336%;
    }

    // icon dimensions
    @mixin weather-partlycloudy-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-partlycloudy-night {
        @include weather-sprite-icons;
        @include weather-partlycloudy-night-dim;
        background-position: 45.340050377833755% 0;
    }

    // icon dimensions
    @mixin weather-partlycloudy-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-rain {
        @include weather-sprite-icons;
        @include weather-rain-dim;
        background-position: 45.340050377833755% 16.666666666666668%;
    }

    // icon dimensions
    @mixin weather-rain-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-rain-night {
        @include weather-sprite-icons;
        @include weather-rain-night-dim;
        background-position: 45.340050377833755% 33.333333333333336%;
    }

    // icon dimensions
    @mixin weather-rain-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-rain_light {
        @include weather-sprite-icons;
        @include weather-rain_light-dim;
        background-position: 0 50%;
    }

    // icon dimensions
    @mixin weather-rain_light-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-raindrizzle {
        @include weather-sprite-icons;
        @include weather-raindrizzle-dim;
        background-position: 15.113350125944585% 50%;
    }

    // icon dimensions
    @mixin weather-raindrizzle-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-raindrizzle-night {
        @include weather-sprite-icons;
        @include weather-raindrizzle-night-dim;
        background-position: 30.22670025188917% 50%;
    }

    // icon dimensions
    @mixin weather-raindrizzle-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-rainheavy {
        @include weather-sprite-icons;
        @include weather-rainheavy-dim;
        background-position: 45.340050377833755% 50%;
    }

    // icon dimensions
    @mixin weather-rainheavy-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-rainheavy-night {
        @include weather-sprite-icons;
        @include weather-rainheavy-night-dim;
        background-position: 60.45340050377834% 0;
    }

    // icon dimensions
    @mixin weather-rainheavy-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-severethunderstorm {
        @include weather-sprite-icons;
        @include weather-severethunderstorm-dim;
        background-position: 60.45340050377834% 16.666666666666668%;
    }

    // icon dimensions
    @mixin weather-severethunderstorm-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-showers {
        @include weather-sprite-icons;
        @include weather-showers-dim;
        background-position: 60.45340050377834% 33.333333333333336%;
    }

    // icon dimensions
    @mixin weather-showers-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-showers-night {
        @include weather-sprite-icons;
        @include weather-showers-night-dim;
        background-position: 60.45340050377834% 50%;
    }

    // icon dimensions
    @mixin weather-showers-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-showers_light {
        @include weather-sprite-icons;
        @include weather-showers_light-dim;
        background-position: 0 66.66666666666667%;
    }

    // icon dimensions
    @mixin weather-showers_light-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-showersheavy {
        @include weather-sprite-icons;
        @include weather-showersheavy-dim;
        background-position: 15.113350125944585% 66.66666666666667%;
    }

    // icon dimensions
    @mixin weather-showersheavy-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-showersheavy-night {
        @include weather-sprite-icons;
        @include weather-showersheavy-night-dim;
        background-position: 30.22670025188917% 66.66666666666667%;
    }

    // icon dimensions
    @mixin weather-showersheavy-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snow {
        @include weather-sprite-icons;
        @include weather-snow-dim;
        background-position: 45.340050377833755% 66.66666666666667%;
    }

    // icon dimensions
    @mixin weather-snow-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowflake {
        @include weather-sprite-icons;
        @include weather-snowflake-dim;
        background-position: 14.423076923076923% 94.24083769633508%;
    }

    // icon dimensions
    @mixin weather-snowflake-dim {
        width: 41px;
        height: 38px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowheavy {
        @include weather-sprite-icons;
        @include weather-snowheavy-dim;
        background-position: 60.45340050377834% 66.66666666666667%;
    }

    // icon dimensions
    @mixin weather-snowheavy-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowrain {
        @include weather-sprite-icons;
        @include weather-snowrain-dim;
        background-position: 75.56675062972292% 0;
    }

    // icon dimensions
    @mixin weather-snowrain-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowrain-night {
        @include weather-sprite-icons;
        @include weather-snowrain-night-dim;
        background-position: 75.56675062972292% 16.666666666666668%;
    }

    // icon dimensions
    @mixin weather-snowrain-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowrainheavy {
        @include weather-sprite-icons;
        @include weather-snowrainheavy-dim;
        background-position: 75.56675062972292% 33.333333333333336%;
    }

    // icon dimensions
    @mixin weather-snowrainheavy-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowrainheavy-night {
        @include weather-sprite-icons;
        @include weather-snowrainheavy-night-dim;
        background-position: 75.56675062972292% 50%;
    }

    // icon dimensions
    @mixin weather-snowrainheavy-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowrainshowers {
        @include weather-sprite-icons;
        @include weather-snowrainshowers-dim;
        background-position: 75.56675062972292% 66.66666666666667%;
    }

    // icon dimensions
    @mixin weather-snowrainshowers-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowrainshowers-night {
        @include weather-sprite-icons;
        @include weather-snowrainshowers-night-dim;
        background-position: 0 83.33333333333333%;
    }

    // icon dimensions
    @mixin weather-snowrainshowers-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowrainshowers_light {
        @include weather-sprite-icons;
        @include weather-snowrainshowers_light-dim;
        background-position: 15.113350125944585% 83.33333333333333%;
    }

    // icon dimensions
    @mixin weather-snowrainshowers_light-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowshower-night {
        @include weather-sprite-icons;
        @include weather-snowshower-night-dim;
        background-position: 30.22670025188917% 83.33333333333333%;
    }

    // icon dimensions
    @mixin weather-snowshower-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowshower_light {
        @include weather-sprite-icons;
        @include weather-snowshower_light-dim;
        background-position: 45.340050377833755% 83.33333333333333%;
    }

    // icon dimensions
    @mixin weather-snowshower_light-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowshowerheavy {
        @include weather-sprite-icons;
        @include weather-snowshowerheavy-dim;
        background-position: 60.45340050377834% 83.33333333333333%;
    }

    // icon dimensions
    @mixin weather-snowshowerheavy-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowshowerheavy-night {
        @include weather-sprite-icons;
        @include weather-snowshowerheavy-night-dim;
        background-position: 75.56675062972292% 83.33333333333333%;
    }

    // icon dimensions
    @mixin weather-snowshowerheavy-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowshowers {
        @include weather-sprite-icons;
        @include weather-snowshowers-dim;
        background-position: 90.68010075566751% 0;
    }

    // icon dimensions
    @mixin weather-snowshowers-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowshowers_light {
        @include weather-sprite-icons;
        @include weather-snowshowers_light-dim;
        background-position: 90.68010075566751% 16.666666666666668%;
    }

    // icon dimensions
    @mixin weather-snowshowers_light-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-sunshine {
        @include weather-sprite-icons;
        @include weather-sunshine-dim;
        background-position: 90.68010075566751% 33.333333333333336%;
    }

    // icon dimensions
    @mixin weather-sunshine-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-sunshine-night {
        @include weather-sprite-icons;
        @include weather-sunshine-night-dim;
        background-position: 90.68010075566751% 50%;
    }

    // icon dimensions
    @mixin weather-sunshine-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-thunderstorm {
        @include weather-sprite-icons;
        @include weather-thunderstorm-dim;
        background-position: 90.68010075566751% 66.66666666666667%;
    }

    // icon dimensions
    @mixin weather-thunderstorm-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-thunderstorm-night {
        @include weather-sprite-icons;
        @include weather-thunderstorm-night-dim;
        background-position: 90.68010075566751% 83.33333333333333%;
    }

    // icon dimensions
    @mixin weather-thunderstorm-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-umbrella {
        @include weather-sprite-icons;
        @include weather-umbrella-dim;
        background-position: 100% 0;
    }

    // icon dimensions
    @mixin weather-umbrella-dim {
        width: 37px;
        height: 39px;
    }


    // svg-sprite icon mixin
    @mixin weather-wind {
        @include weather-sprite-icons;
        @include weather-wind-dim;
        background-position: 0 100%;
    }

    // icon dimensions
    @mixin weather-wind-dim {
        width: 60px;
        height: 60px;
    }

