/* a-basic-link */
.a-basic-link {
	margin: 0 0 17px 0;
	padding: 0 0 0 18px;
	text-decoration: none;
	display: table;
	outline: none;
	cursor: pointer;
	position: relative;

	.m-basic-header > .mm-navi-wrap > .mm-main > .mm-main-btn > .mm-section > .mm-layer .mm-wrap.mm-login-wrap {
		padding-left: 30px;
	}

	&::before {
		// if you change this, there is also an exception for .specific-product-link and .basictable-compare-result further down
		content: '';
		display: inline-block;
		@include main-icon-arrow-right-grey;
		position: absolute;
		top: -11px;
		left: -18px;

		.mm-wrap.mm-login-wrap & {
			top: -1px;
		}

		.mm-list-point-text.mm-list-point-text--link & {
			top: -13px;
		}

		// exception .m-basic-header
		.m-basic-header > .mm-navi-wrap > .mm-main > .mm-main-btn > .mm-section > .mm-layer .mm-wrap.mm-login-wrap .mm-wrap & {
			content: none;
		}
	}

	&[href^="mailto:"] {
		padding-left: 25px;
		position: relative;

		&:before {
			content: '';
			display: inline-block;
			@include main-icon-contact-email;
			position: absolute;
			top: -14px;
			left: -16px;

			.a-my-adac-list & {
				background: none;
			}
		}
	}

	&.my-adac-quiz-popup-btn {

		&:after {
			height: 21px;
		}
	}

	&:focus {
		outline: none;
	}

	&:hover {
		text-decoration: underline;

		// exception for m-newsletter-category
		.mm-newsletter-theme & {
			text-decoration: none;
		}

		// exception .m-basic-header
		.m-basic-header > .mm-navi-wrap > .mm-main > .mm-main-btn > .mm-section & {
			position: relative;
			text-decoration: none;
			border-radius: 4px;
			background-color: $color-secondary-19;

			&:after {
				content: '';
				display: inline-block;
				@include main-icon-arrow-right-black;
				width: 31px;
				height: 29px;
				position: absolute;
				background: none;
			}
		}

		// exception for basictable-compare-result
		.m-basic-table--compare-result &.specific-product-link {
			text-decoration: none;
		}
	}

	&--inline {
		display: inline-block;
		margin-right: 0;

		@include mq(tablet) {
			margin-right: 60px;
		}

		// exception in main stage to set a margin to the left
		.o-layout-main-stage .oo-box > p & {
			margin-left: 6px;
			margin-right: 0;

			@include mq(tablet) {
				display: inline-block;
				margin-left: 12px;
			}
		}
	}

	&--maps {
		float: left;
		margin: 10px 0 15px 0;

		&.h-space-s {
			margin-bottom: 15px;
		}
	}

	&--mapsFirst {
		margin-top: 5px;
	}

	&--pdf {
		.m-basic-sticky-service-contact & {
			margin-bottom: 10px;
		}
	}

	&.h-space-s {
		margin-bottom: 0;

		// exception mm-content--flex-row
		.m-basic-hint--flex-row & {
			margin-bottom: 15px;
		}
	}

	&.h-space-m {
		margin-bottom: 30px;
	}

	&.h-space-l {
		margin-bottom: 60px;
	}

	&.specific-product-link {

		// exception for basictable-compare-result
		// there is no better solution for this case
		// - you cant catch the .m-basic-table--compare-result exceptions with the
		//     .a-basic-link.specific-product-link class in the ::before like (.m-basic-table--compare-result &.specific-product-link) -> doesn´t work
		.m-basic-table--compare-result & {
			padding: 0;

			@include mq(tablet) {
				padding-left: 15px;
			}

			&::before {
				@include main-icon-link-red;
				content: '';
				display: none;
				position: absolute;
				top: -11px;
				left: -18px;

				@include mq(tablet) {
					display: inline-block;
				}
			}
		}
	}

	//exception centerd Text
	.m-basic-text--center & {
		margin: 0 auto;
	}

	.m-basic-text & {
		margin-top: 4px;
	}

	// exception for molecule m-adac-maps--searchresults
	.m-adac-maps--searchresults & {
		margin-left: 9px;
		padding-left: 14px;
	}

	// exception for .m-basic-big-link
	.m-basic-big-link & {
		right: -5px;
		top: -10px;

		@include mq(tablet) {
			right: 0;
		}
	}

	// exception for table toggle controler
	.js-toggle-controller & {
		background: none;
		padding-left: 0;

		&:before {
			display: none;
		}
	}

	// exception for l-outer--my-adac-content
	.l-outer--my-adac-content .m-basic-hint--my-adac & {
		background: none;
		position: relative;
		padding: 0;

		&:before {
			position: absolute;
			content: '';
			display: block;
			@include main-icon-arrow-right-grey;
			width: 30px;
			left: -54px;
		}

		&:after {
			content: none;
		}
	}

	// exception to put two links next to each other
	// just beware of the basicText, it should be 100% to fill the content and the rest wraps
	.m-basic-hint--flex-row & {
		flex: 1 1 100%;

		@include mq(tablet) {
			flex: 0 1 50%;
			align-self: flex-start;
		}
	}

	// exception for l-form--newsletter
	.l-form--newsletter & {
		&.h-space-s {
			margin-bottom: 12px;
		}
	}

	// exception for ll-row--flex
	.ll-row--flex & {
		margin-bottom: 0;
	}

	// exception for m-newsletter-category
	.mm-newsletter-theme & {
		display: block;

		@include mq(tablet) {
			display: none;
		}
	}

	// Exception for BasicHint
	.m-basic-hint & {
		&.h-space-s {
			margin-bottom: 5px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	// exception for ADAC Maps mm-result-list
	.m-adac-maps .mm-result-list & {
		text-decoration: underline;
		@include font-size($font-size-16);
		margin-bottom: 5px;
		top: 0;
	}

	.m-adac-maps .mm-result-list .m-basic-text--mglmaps & {
		text-decoration: none;
	}

	h3 & {
		display: inline-block;
		margin-left: 10px;
		font-weight: normal;
	}

	// Exception in table
	td & {
		display: inline-block;
	}

	// Exception m-baic-table-break mobile close btn
	.m-basic-table--break .mm-toggle-close & {
		display: block;
		margin: 0;
	}

	// Exception: m-basic-teaser-product
	.m-basic-teaser-product & {
		margin-top: 5px;
	}

	// Exception .m-basic-teaser-across
	.m-basic-teaser-across > article .mm-footer & {
		margin: 0;

		@include mq(tablet) {
			margin: 4px 0 0 0;
		}
	}

	// Exception m-basic-table-tests product links in TDs
	.m-basic-table--tests .mm-table-link-wrapper & {

		&.default-product-link {
			margin-left: 2px;
		}

		&.specific-product-link {
			background: none;
		}
	}

	// exception for links in m-basic-table--break
	.m-basic-table--break & {
		margin: 0;
	}

	// exception for links in m-basic-table--vertical-center
	.m-basic-table--vertical-center & {
		&.h-space-s {
			margin-bottom: 0;
		}
	}

	// exception m-basic-newsbox
	.m-basic-newsbox & {
		margin-bottom: 12px;
	}

	// exception in form overview
	.o-basic-form-overview .oo-fieldset .oo-row .oo-col-data .oo-description & {
		margin-bottom: 0;
	}

	//exception in form-general multilabel
	.l-form-general .ll-row--multilabels & {
		position: absolute;
		display: inline-block;
		right: 35px;
		top: 0;

		@include mq(tablet) {
			right: 5px;
		}
	}

	//exception in form-general multilabel + is-floating
	.l-form-general .ll-row--multilabels.is-floating & {
		top: -23px;

		@include mq(tablet) {
			right: 5px;
		}
	}

	//exception in form multilabel
	.o-basic-form .oo-row--multilabels &,
	.l-form .ll-row--multilabels & {
		font-weight: normal;
		float: right;
		margin-bottom: 0;
		display: inline-block;

		@include mq(tablet) {
			position: absolute;
			top: -25px;
			right: 30px;
			margin-right: 0;
			margin-bottom: 0;
		}
	}

	// exception when in ves-rs-sticky
	.mm-layer-links & {
		padding-left: 15px;
		margin-left: 20px;
		background-position: 7px -1px;
		@include milo-regular($font-size-16);
		height: 27px;

		.h-roboto & {
			font-family: 'Roboto', sans-serif;
		}
	}

	// exception
	.mm-flex--row-reverse .mm-content & {
		display: inline-block;
	}

	// exception when in .m-basic-hint-background
	.m-basic-hint--background &:not(.a-basic-link--contact) {
		padding: 0 0 0 12px;
	}

	// exception when in .m-basic-rating--user
	.m-basic-rating--user & {

		@include mq(tablet) {
			white-space: nowrap;
		}
	}

	// exception .m-basic-list-wrapper
	.m-basic-list-wrapper > & {
		margin: 0 auto;
	}

	.m-basic-list--link & {
		background: none;
		top: 0;
		padding-left: 0;
		margin-bottom: 0;

		&:before {
			@include main-icon-link-grey2;
		}
	}

	// exception for .m-basic-text .m-basic-list--link
	.m-basic-text .m-basic-list--link & {
		top: -4px;
		padding-left: 4px;

		@include mq(tablet) {
			padding-left: 0;
		}

		&:before {
			display: none;
		}
	}

	// exception m-basic-teaser-across--reduced
	.m-basic-teaser-across--reduced > article > .mm-article-content > .mm-link > & {
		background-position-y: -3px;
	}

	// exception .ll-main-cols to align elem vertically centered (-> "kraftstoffe-search-location")
	.ll-main-cols-l > & {
		&:only-child {
			@include mq(tablet) {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				margin: 0;
			}
		}
	}

	// exception .m-basic-header
	.m-basic-header > .mm-navi-wrap > .mm-main > .mm-main-btn > .mm-section & {
		width: 100%;
		padding: 10px 8px 10px 27px;
		@include font-size($font-size-16, 20px);

		&:after {
			background: none;
		}

		&:before {
			content: '';
			display: inline-block;
			@include main-icon-arrow-right-grey-dark;
			top: -2px;
			left: -12px;
		}

		&--contact {
			padding-left: 0;

			&:before {
				background: none;
				display: none;
			}
		}
	}

	// exception .m-basic-header .mm-contact layer
	.m-basic-header > .mm-navi-wrap > .mm-main > .mm-main-btn > .mm-section .mm-layer.mm-contact &:not(.a-basic-link--contact) {
		@include font-size($font-size-14, 20px);
	}

	// exception ll-main-language
	.ll-main-language > & {
		margin-bottom: 0;
	}
}