/* o-compare-product--dynamic */
.o-compare-product--dynamic {
	> .swiper {

		.swiper-wrapper {

			.swiper-slide {
				> .oo-compare-product-box {
					> .oo-header-box {
						> header {
							> h3 {
								@include arial($font-size-15);
							}
						}
					}
				}

				> .oo-compare-toggle {
					> .oo-head {
						padding-left: 37px;
						position: relative;

						&--data {
							&::before {
								position: absolute;
								width: 100%;
							}
						}
					}

					> .oo-content {
						> .oo-list {
							> .oo-head {
								padding-right: 12px;
								padding-left: 12px;
							}
						}
					}
				}

				&:nth-child(2n) {
					> .oo-compare-toggle {
						> .oo-content {
							> .oo-list {
								> .oo-result {
									background: $color-primary-2;
								}
							}
						}
					}
				}

				&:nth-child(2n + 1) {
					> .oo-compare-toggle {
						> .oo-content {
							> .oo-list {
								> .oo-result {
									background: $color-secondary-10;
								}
							}
						}
					}
				}

			}
		}
	}
}