.m-my-adac-stage {
	display: flex;
	flex-direction: column;

	> .mm-my-adac-stage-image {
		position: relative;
		margin-top: -54px;
		margin-left: -20px;
		margin-right: -20px;

		@include mq(tablet) {
			order: 2;
			margin-top: initial;
			margin-left: initial;
			margin-right: initial;
		}
	}

	> .mm-my-adac-stage-headline {
		@include mq(tablet) {
			order: 1;
		}
	}

	> .mm-my-adac-stage-btn {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		@include mq(tablet) {
			position: relative;
			order: 3;
			flex-direction: row;
			top: -100px;
			margin-bottom: -60px;
			z-index: 15;
		}

		&:first-child {
			@include mq(tablet) {
				top: auto;
				margin-bottom: 0;
			}
		}

		> .mm-my-adac-stage-btn-yellow {

			@include mq(tablet) {
				margin-right: 20px;
			}
		}
	}
}