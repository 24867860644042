.m-basic-rate {
	> .mm-star-wrapper {
		display: flex;

		> .mm-item {
			position: relative;
			width: 30px;
			height: 30px;
			overflow: hidden;
			cursor: pointer;

			&.is-hover,
			&.is-filled {
				> .mm-full {
					opacity: 1;
				}

				> .mm-empty {
					opacity: 0;
				}
			}

			> .mm-empty,
			> .mm-full {
				position: absolute;
				left: -8px;
				top: -8px;
				transition: opacity 0.3s;
			}

			> .mm-full {
				@include main-icon-star-full();
				opacity: 0;
			}

			> .mm-empty {
				@include main-icon-star-empty();
				opacity: 1;
			}
		}
	}
}