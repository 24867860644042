/* a-basic-input-select*/
.a-basic-input-select {

	// settings
	$bg: $color-secondary-7;
	$bg-refresh: $color-secondary-8;
	$bg-reverse: $color-primary-2;

	position: relative;
	max-width: 400px;
	font-weight: normal;
	height: 36px;
	border-bottom: 1px solid #cccccc;

	&:focus-within:not(.a-basic-input-select--colorpick) {
		border-bottom: 2px solid $color-primary-1;
	}

	// exception adacmaps-searchresults
	.m-adac-maps .mm-map-wrapper & {
		width: 65%;
	}

	&.h-space-s {
		margin-bottom: 0;

		.m-my-adac-dashboard-box-layer & {
			margin-bottom: 10px
		}
	}

	// exception for old formulars .l-form with background
	.h-background .l-form &,
	.l-form .h-background & {
		overflow: hidden;
		border-bottom: none;
	}

	.l-form-general & {
		width: 100%;
		height: 55px;
		background-color: $color-primary-2;
	}

	.m-basic-radius-search .l-form-general & {
		margin-right: 20px;
	}

	> select {
		width: 100%;
		height: 36px;
		padding: 0 40px 0 12px;

		// reset
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border: none;
		border-radius: 0;
		background: $bg-refresh;
		overflow: hidden;

		&::-ms-expand {
			display: none;
		}

		&::-ms-value {
			background: none;
			color: #42413D;
		}

		.l-form-general & {
			min-height: 55px;
			padding-left: 14px;
			padding-top: 17px;
			background: transparent;
			border: 1px solid $color-formfield-border-standard;
			border-radius: 4px;
			box-sizing: border-box;

			&:before,
			&:after {
				box-sizing: border-box;
			}

			&:hover {
				border: 1px solid $color-formfield-border-hover;
			}
		}

		.l-form-general .m-basic-keywordfilter & {
			padding-top: 0;
		}

		.l-form-general .ll-row.is-error & {
			border: 2px solid $color-action-alert;
		}

		.o-layout-main-stage .m-basic-radius-search & {
			background-color: $color-primary-2;
		}

		.mm-content--bg & {
			background-color: $color-primary-2;
		}

		@include if-parent('.a-basic-input-select.is-error') {
			border-bottom: 2px solid $color-action-alert;
		}

		&:disabled {
			.l-form-general & {
				background: $color-primary-2;
				border-color: $color-secondary-5;
				color: $color-secondary-20;

				& + .aa-controller {
					@include main-icon-lock;
					opacity: 0.3;
				}
			}
		}

		&:focus {
			outline: 0;
			color: rgba(0, 0, 0, 0);
			text-shadow: 0 0 0 $color-secondary-1;
			padding-top: 2px;

			.l-form-general & {
				border: 2px solid $color-secondary-17;
				padding-left: 13px;
				padding-top: 17px;
			}

			.l-form-general .m-basic-keywordfilter & {
				padding-top: 0;
			}
		}

		&.is-error {
			border-bottom: 2px solid $color-action-alert;
		}

		.m-basic-sticky-service-contact & {
			@include mq(tablet) {
				background-color: $color-primary-2;
			}
		}

		// exception for .l-form--background
		.l-form--background & {
			background-color: $color-primary-2;
		}

		// exception for .m-ves-rs-contentbox--grey
		.m-ves-rs-contentbox--grey & {
			background: $bg-reverse;
		}

		// exception for .h-background
		.h-background & {
			background: $bg-reverse;
		}

		// exception for cardamage forms
		.l-form.l-form--cardamage-map &:disabled {
			color: $color-secondary-9;

			+ .aa-controller {
				@include main-icon-arrow-drop-down-disabled;
				background-repeat: no-repeat;
			}
		}

		// exception for modifier background
		.l-form.l-form--background &:disabled {
			color: $color-secondary-9;

			+ .aa-controller {
				@include main-icon-arrow-drop-down-disabled;
				background-repeat: no-repeat;
			}
		}

		// exception for cardamage forms
		.l-form.l-form--tet &:disabled {
			color: $color-secondary-9;

			+ .aa-controller {
				@include main-icon-arrow-drop-down-disabled;
				background-repeat: no-repeat;
			}
		}

		> option {
			padding: 5px 7px;
			border-top: 1px solid $color-secondary-4;
			border-left: 0;
			color: $color-secondary-1;
			background: $bg-refresh;

			// exception for .m-ves-rs-contentbox--grey
			.m-ves-rs-contentbox--grey & {
				background: $bg-reverse;
			}

			// exception for .h-background
			.h-background & {
				background: $bg-reverse;
			}

			.l-form-general & {
				border: none;
				background-color: $color-primary-2;
			}
		}
	}

	.aa-controller {
		width: 36px;
		height: 36px;
		pointer-events: none;
		content: " ";
		display: block;
		position: absolute;
		top: -3px;
		right: 2px;
		@include main-icon-arrow-drop-down;
		transform: rotate(360deg);
		background-repeat: no-repeat;

		.l-form-general & {
			top: 4px;
		}

		.l-form-general .ll-row.is-check.is-error > .ll-col-data > &,
		.l-form-general .ll-row.is-check.is-valid > .ll-col-data > & {
			right: 30px;
		}

		.m-basic-sticky-service-contact & {
			@include mq(tablet) {
				background-color: $color-primary-2;
			}
		}
	}

	.aa-col-label {
		position: absolute;
		z-index: 1;
		top: 3px;
		left: 15px;

		.l-form-general & {
			max-width: calc(100% - 55px);
			overflow: hidden;
			white-space: nowrap;
		}

		> .aa-label {
			.l-form-general & {
				color: $color-secondary-20;
				@include milo-regular($font-size-14);
			}

			.l-form-general .ll-row.is-check.is-error & {
				color: $color-action-alert;
			}
		}
	}

	.aa-unit {
		> * {
			position: absolute;
			right: -10px;
			top: 50%;
		}
	}

	.js-select-overlay {
		@include absolute($top: 0, $left: 0);
		width: 100%;
		height: 100%;
		padding: 7px 24px 0 12px;
		background: $color-secondary-8;
		cursor: pointer;
		pointer-events: none;
		overflow: hidden;
		text-overflow: ellipsis;

		.m-ves-rs-contentbox--grey & {
			background: $color-primary-2;
		}
	}

	// exception when in .ll-row--cardamage
	.ll-row--cardamage .ll-col-data & {

		$bg: $color-secondary-7;
		max-width: 320px;

		@include mq(tablet) {
			width: 273px;
			max-width: none;
		}

		@include mq(desktop) {
			width: 293px;
		}
	}

	.l-form--sticky-contact .ll-col-data & {
		margin-bottom: 5px;
	}

	// exception for cardamage forms
	.l-form.l-form--cardamage-map & {
		max-width: 305px;

		& + .a-basic-input-select {
			margin-top: 10px;
		}
	}

	// Exception ll-data-input-text
	.l-form .ll-data-input-text + & {
		float: left;
		margin: 0 10px 0 0;
	}

	// Exception ll-row--mgl-discount
	.ll-row--mgl-discount & {

		@include mq(tablet) {
			width: 120px;
		}
	}

	// exception when in .m-ves-rs-contentbox
	.m-ves-rs-contentbox & {
		width: 250px;
		height: 30px;

		@include mq(tablet) {
			width: 180px;
		}

		> select {
			&:disabled {
				color: $color-secondary-9;

				+ .aa-controller {
					@include main-icon-arrow-drop-down-disabled;
					background-repeat: no-repeat;
				}
			}
		}
	}

	// exception .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration & {
		width: 230px;
	}

	// exception .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration .l-form .ll-item-container--full > .ll-col-data & {
		width: 85%;

		@include mq(tablet) {
			width: 305px;
		}
	}

	// exception .m-basic-calculator-content-box
	.m-basic-calculator-content-box & {
		width: calc(100% - 30px);
		min-width: 60px;

		@include mq(tablet) {
			width: 100%;
		}
	}

	// exception .m-basic-calculator-content-box .mm-row if a-basic-input-text--date is prev sibling
	.m-basic-calculator-content-box .mm-form-container > .mm-fieldset > .mm-row > .mm-inputcontainer > .a-basic-input-text--date ~ & {
		margin-left: 40px;
		padding-bottom: 0;

		@include mq(desktop) {
			margin-left: 50px;
		}
	}

	// exception .m-basic-calculator-content-box
	.m-basic-calculator-content-box > .mm-upload-container > .mm-row > .mm-row-content--right > & {
		@include mq(tablet) {
			max-width: 100px;
		}
	}

	.o-layout-main-stage .oo-box-col-3 & {
		overflow: hidden;
		width: 100%;
		display: inline-block;
	}

	.o-basic-cluster-box--form & {
		> select {
			background: $color-primary-2;

			> option {
				background: $color-primary-2;
			}
		}
	}

	// exception .m-basic-radius-search
	.m-basic-radius-search > .l-form .ll-col-label + .ll-col-data &,
	.m-basic-radius-search > .l-form-general .ll-col-label + .ll-col-data & {
		width: 100%;
		max-width: none;
		padding-bottom: 0;
		overflow: hidden;
	}

	// exception for select in .m-adac-maps--searchresults
	.m-adac-maps--searchresults .mm-map-wrapper > & {
		margin-bottom: 20px;
		max-width: none;
		padding-bottom: 0;
	}

	// exception for select in .m-basic-keywordfilter
	.m-basic-keywordfilter .mm-searchbar > & {
		padding-bottom: 0;
	}

	// exception ll-fieldset--select
	.l-form .ll-fieldset--select .ll-row .ll-col-data & {
		@include mq(tablet) {
			float: left;
			width: calc(50% - 5px);
		}

		&:not(.h-space-m):not(.h-space-l) {
			margin-bottom: 15px;
		}
	}
}