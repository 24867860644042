/* .m-basic-hint--outline */
.m-basic-hint--outline {
	border-color: $color-secondary-9;
	padding: 12px 0 16px 32px;
	width: 100%;

	@include mq(tablet) {
		padding: 12px 12px 16px 44px;
	}

	&:not(.m-basic-hint--outline-full) {
		@include mq(tablet) {
			max-width: 307px;
			padding: 7px 7px 16px 44px;
		}
	}

	// exception if m-basic-hint--error is set as well
	&.m-basic-hint--error {
		border-color: $color-action-alert;
	}

	> img {
		top: 16px;
		left: 2px;

		@include mq(tablet) {
			top: 15.5px;
			left: 10px;
		}
	}
}