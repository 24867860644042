.m-basic-categories {
	position: relative;

	@include mq(tablet) {
		position: absolute;
		bottom: 35px;
		z-index: 5000;
		left: 0;
		width: 100%;
		padding: 0;
	}

	@include mq(desktop) {
		left: calc(50% - 422px);
		width: 845px;
	}

	> .swiper {
		> .swiper-wrapper {
			display: block;

			@include mq(tablet) {
				display: flex;
			}

			> .swiper-slide {
				border: 2px solid $color-secondary-1;
				background: $color-primary-2;
				width: 140px;
				float: left;
				margin-right: 10px;
				margin-left: 10px;
				margin-bottom: 20px;

				&:last-child {
					margin-left: 10px !important;
					margin-right: 10px !important;
				}

				@include mq(tablet) {
					float: none;
					width: 130px;
					margin-right: 0;
					margin-bottom: 0;
					margin-left: 0;

					&:last-child {
						margin-left: 0 !important;
						margin-right: 0 !important;
					}

				}

				> .mm-box-teaser {

					max-height: 126px;
					overflow: hidden;

					> a {

						> img {
							display: block;
							max-width: 100%;
							max-height: 100%;
							min-height: 126px;
							margin: 0 auto 5px auto;
							padding-bottom: 40px;

							@include mq(tablet) {
								margin: 0 auto;
							}
						}
					}

					> .mm-box-link {
						position: absolute;
						display: block;
						bottom: 0;
						margin: 0 0 0 10px;
						word-wrap: break-word;
						padding-right: 10px;
						min-height: 40px;
						@include milo-bold($font-size-16, 20px);

						.h-roboto & {
							font-family: 'Roboto Bold', sans-serif;
						}

						@include mq(tablet) {
							width: 100%;
						}
					}
				}
			}
		}
	}
}