/* m-basic-text--width-auto-center */
.m-basic-text--width-auto-center {

	> .mm-flex {

		> .mm-aside {
			width: 50%;

			> figure {

				> .mm-ratio-container {
					display: flex;
					justify-content: center;

					> picture {
						position: relative;

						> img {
							width: auto;
						}
					}
				}
			}
		}
	}
}