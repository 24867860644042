/* m-basic-list--img-left */
.m-basic-list--img-left {

	> li {
		background: none;
		padding: 0;
		color: $color-secondary-3;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 45px;

		&:before {
			background: none;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.m-ves-rs-contentbox--grey & {
			color: $color-primary-3;
			@include milo-bold();
		}

		> .mm-img-content {
			width: 55px;
			margin-right: 20px;
			flex-shrink: 0;
			flex-grow: 0;
			align-self: center;

			> img {
				max-width: 100%;
				max-height: 100%;
				margin: 0 auto;
			}
		}
	}
}