/* a-basic-icon--sprite */
.a-basic-icon--sprite {
	position: relative;
	display: block;
	padding: 12px 0 20px 35px;

	&:before {
		content: '';
		display: block;
		@include absolute(0, 0);
	}

	&.icon-arrow-drop-down {
		&:before {
			@include main-icon-arrow-drop-down;
		}
	}

	&.icon-arrow-drop-down-active {
		&:before {
			@include main-icon-arrow-drop-down-active;
		}
	}

	&.icon-arrow-drop-down-disabled {

		&:before {
			@include main-icon-arrow-drop-down-disabled;
		}
	}

	> p {
		margin: 0;
	}
}

/* a-basic-icon--embed */
.a-basic-icon--embed {

	&.icon-arrow-drop-down {
		> img {
			@include main-icon-arrow-drop-down;
		}
	}

	&.icon-arrow-drop-down-active {
		> img {
			@include main-icon-arrow-drop-down-active;
		}
	}

	&.icon-arrow-drop-down-disabled {
		> img {
			@include main-icon-arrow-drop-down-disabled;
		}
	}
}