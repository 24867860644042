/* m-my-adac-dashboard-box-showcase */
.m-my-adac-dashboard-box {
	display: flex;
	flex-flow: column nowrap;
	width: 100%;

	// Firefox needs this, else it only prints blank/content/blank sites
	@include mq(print) {
		display: block;
	}

	> .mm-wrapper {

		> .mm-dashbox-theme-wrapper {

			> .mm-dashbox-theme {
				// insgesamt 38px (20px von den boxen)
				margin-bottom: 18px;
				position: relative;
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;

				@include mq(tablet) {
					// insgesamt 58px (20px von den boxen)
					margin: 0 0 38px 0;
				}

				@include mq(desktop) {
					flex-flow: row wrap;
				}

				> .mm-dashbox-theme-option-layer {
					margin-bottom: 15px;
					z-index: 10;
					order: 2;
					flex: 0 0 auto;

					@include mq(tablet) {
						margin-bottom: 40px;
					}

					@include mq(desktop) {
						@include absolute(10px, $right: 0);
						margin-bottom: 0;
					}

					> .mm-theme-option {
						display: flex;
						justify-content: center;
						position: relative;

						// Firefox needs this, else it only prints blank/content/blank sites
						@include mq(print) {
							display: block;
						}

						> .mm-option-wrapper {
							display: flex;
							cursor: pointer;

							// Firefox needs this, else it only prints blank/content/blank sites
							@include mq(print) {
								display: block;
							}

							> .mm-icon {
								@include main-icon-list-grey2;
								top: -11px;
								left: 20px;
								position: relative;

								@include if-parent('.mm-theme-option.is-open') {
									display: none;
								}
							}

							> .mm-text {
								margin: 0;
								padding: 0 0 0 25px;
							}
						}
					}
				}

				> .mm-dashbox-theme-headline {
					text-align: center;
					position: relative;
					cursor: pointer;
					user-select: none;
					padding: 0 0 20px 0;
					order: 1;
					flex: 1 0 auto;

					@include mq(tablet) {
						padding: 0 0 40px 0;
					}

					&:after {
						content: '';
						@include main-arrow-btn-down;
						display: inline-block;
						cursor: pointer;
						margin: 0 3px 0 25px;
						position: relative;
						left: -10px;
						top: 15px;

						@include if-parent('.mm-dashbox-theme-headline.is-extended') {
							@include main-arrow-btn-up;
						}

						@include mq(tablet) {
							top: 12px;
						}
					}
				}

				> .mm-box-wrapper {
					display: flex;
					flex-flow: column wrap;
					align-items: center;
					//plus 24px for the minus margin -24px
					width: calc(100% + 24px);
					margin-left: -24px;
					order: 3;

					// Firefox needs this, else it only prints blank/content/blank sites
					@include mq(print) {
						display: block;
					}

					@include mq(tablet) {
						align-items: stretch;
						justify-content: flex-start;
						flex-flow: row wrap;
						width: calc(100% + 24px);
					}

					@include if-parent('.mm-dashbox-theme.mm-single-box') {
						@include mq(tablet) {
							align-items: flex-start;
						}
					}

					> .mm-box {
						width: calc(100% - 24px);
						height: 100%;
						min-width: 300px;
						max-width: 340px;
						background: $color-secondary-8;
						border: 1px solid $color-secondary-6;
						border-radius: 10px;
						margin: 0 0 24px 24px;

						box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
						position: relative;

						@include mq(tablet) {
							height: auto;
						}

						@include mq(desktop) {
							min-width: 370px;
							max-width: 370px;
						}

						@include mq(print) {
							min-width: 100%;
							max-width: 100%;
						}

						@include if-parent('.m-my-adac-dashboard-box--logged-out') {
							position: relative;

							> .mm-box-example-cover {
								position: absolute;
								width: 100%;
								height: 100%;
								background-color: transparent;
								z-index: 100;
								display: flex;
								justify-content: center;
								align-items: center;
								text-decoration: none;
								overflow: hidden;

								// Firefox needs this, else it only prints blank/content/blank sites
								@include mq(print) {
									display: block;
									overflow: auto;
								}

								&:hover,
								&:visited {
									color: $color-secondary-6;
								}

								> .mm-text {
									@include milo-bold(90px);
									@include line-height(90px);
									transform: rotate(-30deg);
									color: $color-secondary-6;
									opacity: 0.65;
									margin-top: 40px;
									text-align: center;

									@include mq(tablet) {
										@include milo-bold(100px);
										@include line-height(100px);
									}
								}
							}
						}

						// myAdac promotion boxes - grey boxes
						&--promotion {
							background: $color-secondary-8;
						}

						&.is-hovered {
							background: $color-primary-2;

							@include mq(tablet) {
								background: $color-primary-1;
							}
						}

						& + .mm-text-hint {
							margin: 80px 0 0 24px;
						}

						& + .mm-data-protection-hint {
							margin: 0 0 0 24px;
						}

						.mm-box-headline {
							border-bottom: none;
							padding: 24px;
							border-radius: 10px;
							cursor: pointer;


							> h3 {
								user-select: none;
								padding: 0;
								max-width: 100%;
								margin: 0;
								cursor: pointer;

								.mm-box-subheadline ~ {
									margin: 0 0 12px 0;
								}
							}

							> .mm-box-subheadline {
								margin: 12px 0 0 0;
								@include milo-regular(15px, 18px);
								font-weight: normal;

								@include if-parent('.mm-box.is-extended') {
									display: none;
								}
							}
						}

						.mm-content-wrapper {
							height: 0;
							overflow: hidden;

							@include if-parent('.mm-box.is-extended') {
								height: auto;
								overflow: visible;
							}

							@include mq(print) {
								height: auto;
							}

							@include if-parent('.mm-box.is-extended.is-loading') {
								position: relative;
								max-width: 100%;
								min-height: 330px;
								max-height: 330px;
								overflow: hidden;
								border-top: 1px solid $color-secondary-3;

								&:before {
									display: inline-block;
									position: absolute;
									width: 100%;
									height: 100%;
									content: '';
									background: $color-secondary-8;
									z-index: 40;
								}

								&:after {
									@include main-icon-refresh-180px;
									display: inline-block;
									position: absolute;
									content: '';
									top: 0;
									left: calc(50% - 100px);
									z-index: 45;

									// spin animation
									animation: spin 1.5s infinite linear;

									@keyframes spin {
										0% {
											transform: rotate(0deg);
										}

										100% {
											transform: rotate(359deg);
										}
									}
								}
							}

							> .mm-content {
								padding: 0 24px;

								// myAdac promotion boxes - grey boxes
								@include if-parent('.mm-box--promotion') {
									padding: 24px 0;
									margin: 0 12px;
									border-bottom: 1px solid $color-secondary-3;

									&.mm-breaker {
										border: none;
										margin: 0;
									}

									&:last-child {
										border: none;
										padding: 24px 0;
									}
								}

								// myAdac logged-out boxes - grey boxes
								&--full {
									padding: 0;
									margin: 0;
								}

								&--push-up {
									margin-top: -24px;
								}

								&.mm-breaker {
									padding: 0;
								}

								&.mm-hide-desktop {
									display: block;

									@include mq(desktop) {
										display: none;
									}
								}

								> .mm-content-headline {
									margin: 0 0 24px 0;

									> h5 {
										margin: 0;
										padding: 0;
										@include milo-regular(14px, 18px);
										font-weight: normal;
										font-style: normal;

									}
								}

								> .mm-list-text {
									@include milo-regular(18px, 22px);
									margin-bottom: 12px;
								}

								> .mm-timerefresh {
									position: relative;
									line-height: 18px;

									@include mq(desktop) {
										margin-top: 14px;
									}

									> .mm-timerefresh-content {
										border-top: 1px solid $color-secondary-3;
										border-bottom: 1px solid $color-secondary-3;
										position: relative;
										padding: 10px 5px;
										margin-bottom: 30px;

										display: flex;
										flex-wrap: wrap;
										align-items: center;


										> .mm-timerefresh-wrapper {
											flex: 1;

											> .mm-timestamp {
												white-space: nowrap;
												color: $color-secondary-3;
											}
										}

										> .mm-refresh-icon {
											width: 30px;
											height: 30px;
											background-color: $color-primary-1;
											cursor: pointer;

											> img {
												margin: 3px 0 0 3px;
											}
										}
									}
								}
							}
						}
					}

					> .mm-data-protection-hint,
					> .mm-text-hint {
						margin: 40px 0 0 24px;
						max-width: 340px;
						width: 100%;
						height: auto;
						display: none;

						@include mq(tablet) {
							max-width: calc(100% - 24px);
						}

						@include if-parent('.mm-dashbox-theme.m-show-data-hint') {
							display: block;
						}

						@include if-parent('.mm-dashbox-theme.m-show-text-hint') {
							display: block;
							margin: 0;
						}

						&:first-child {
							margin: 0 0 0 24px;
						}
					}
				}
			}
		}
	}
}