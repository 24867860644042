// 2022.03.18 BK
// .m-basic-calculator-content-box-newform
.m-basic-calculator-content-box-newform {
	position: relative;
	padding: 34px 10px;

	@include mq(tablet) {
		padding: 60px 0;
	}

	&--wrapper {
		padding: 0;
	}

	// exception to reset paddings
	&--nospace {
		padding: 10px 0;

		> .mm-subheadline {
			padding-bottom: 8px;
		}
	}

	.ll-row + &--nospace {
		padding-top: 0;
	}

	.m-basic-toggle-tab-box & {
		padding: 0;

		@include mq('tablet') {
			padding: 0 0 20px;
		}
	}

	.l-form-general.l-form-general--mgl-standard & {
		@include mq('tablet') {
			min-width: 870px;
			padding-left: 28%; //60%;
		}
	}

	//style box as fullwidth
	&:before,
	&:after {
		content: "";
		position: absolute;
		top: 0;
		width: 50%;
		height: 100%;
	}

	&:before {
		left: -50%;
	}

	&:after {
		right: -50%;
	}

	.mm-toggle-container:not(:only-child) & {

		&:before,
		&:after {
			display: none;
		}
	}

	.is-hidden,
	&.is-hidden {
		display: none !important;
	}

	&--nomargin {
		padding-top: 0;
		margin-top: -20px;
	}

	// if two boxes follow each other

	// background boxes
	&.h-background:not(.h-hidden) + .m-basic-calculator-content-box-newform.h-background {
		padding-top: 0;
		margin-top: -20px;
	}

	// no background boxes
	&:not(.h-background):not(.h-hidden) + .m-basic-calculator-content-box-newform:not(.h-background) {
		padding-top: 0;
		margin-top: -20px;
	}

	.mm-wrapper {
		display: flex;
		justify-content: center;
		align-items: baseline;

		h2,
		h3 {
			width: auto !important;
			margin: 0 12px 0 0 !important;
		}

		h2 + .m-basic-info-layer {
			top: 2px;
		}

		h3 + .m-basic-info-layer {
			top: 4px;
		}

		& + .mm-subrowheadline {
			.mm-infolayer-icon {
				position: absolute;
			}
		}

	}

	// wrapper to show borders around content
	> .mm-border-wrap {
		padding: 15px 25px;
		border: 1px solid $color-secondary-9;
		border-radius: 12px;

		@include mq(tablet) {
			padding: 25px 0;
		}

		&--padding {
			@include mq(tablet) {
				padding: 25px 50px;
			}
		}
	}

	.mm-subheadline {

		> h2,
		h3,
		p {
			text-align: center;
		}

		> p {
			margin-bottom: 25px;

			@include mq(desktop-l) {
				max-width: 720px;
				margin-left: auto;
				margin-right: auto;
			}

			@include if-parent('.mm-subheadline:last-child()') {
				margin-bottom: 0;
			}
		}
	}

	h2,
	h3,
	p,
	.mm-subrowheadline {
		max-width: none;
		width: 100%;
		text-align: center;

		&.is-left {
			text-align: left;
		}
	}

	p {
		@include mq(tablet) {
			max-width: 610px;
			margin-left: auto;
			margin-right: auto;
		}

		&:not(:last-child) {
			@include mq(tablet) {
				margin-bottom: 30px;
			}
		}
	}

	.mm-subrowheadline {
		position: relative;

		@include mq(tablet) {
			max-width: 610px;
			margin-left: auto;
			margin-right: auto;
		}

		&:not(:last-child) {
			margin-bottom: 25px;

			@include mq(tablet) {
				margin-bottom: 30px;
			}
		}
	}

	.mm-tile-container {
		position: relative;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		margin: 5px -10px 5px -10px;

		@include mq(tablet) {
			margin: 5px 0 0 0;
			padding: 0;
		}

		&:not(.h-hidden) + .mm-tile-container {
			@include mq(tablet) {
				margin-top: 40px;
			}
		}

		.l-form-general.l-form-general--mgl-standard & {
			flex-flow: column;

			@include mq(tablet) {
				flex-flow: initial;
			}
		}

		&.hidden {
			display: none;
		}

		&.h-space-s {
			margin-bottom: 10px;
		}

		&.h-space-m {
			margin-bottom: 30px;
		}

		&.h-space-l {
			margin-bottom: 30px;

			@include mq(tablet) {
				margin-bottom: 60px;
			}

		}

		&.is-odd {
			flex-direction: column;
			padding: 0 5px;

			@include mq('tablet') {
				flex-direction: row;
				padding: 0;
			}
		}

		&--grow {
			flex-flow: column nowrap;
			height: auto;

			@include mq(tablet) {
				flex-flow: row nowrap;
			}
		}

		&--wide {
			flex-flow: column nowrap;

			@include mq(tablet) {
				flex-flow: row wrap;
			}
		}

		&--large {
			display: block;

			@include mq(tablet) {
				display: flex;
			}
		}

		> .mm-subheadline {
			width: 100%;
			margin: 0 0 5px 0;
		}

		> .mm-error-msg {
			width: 100%;
			max-height: 0.1px;
			padding: 0;
			overflow: hidden;
			opacity: 0;
			color: $color-action-alert;
			transition: all 1.35s ease;
			text-align: center;

			@include if-parent('.mm-tile-container.is-check.is-error') {
				opacity: 1;
				max-height: 300px;
				padding: 0 0 20px 0;
			}
		}

		> .mm-check {
			// hide the 'x' behind error messages
			display: none;
		}

		> .mm-tile {
			position: relative;
			margin: 0 15px 20px 15px;
			width: 130px;

			@include mq(tablet) {
				margin: 0 17px 20px 17px;
				width: initial;
			}

			@include if-parent('.mm-tile-container.mm-tile-container--grow') {
				margin-bottom: 40px;

				@include mq(tablet) {
					width: auto;
					margin-bottom: 20px;
				}
			}

			@include if-parent('.mm-tile-container.is-odd') {
				margin-bottom: 20px;

				&-empty {
					margin-bottom: 0;
				}
			}

			&--small {
				margin: 0 8px 20px 8px;

				@include mq(tablet) {
					margin: 0 10px 20px 10px;
				}

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}

				label {
					&:after {
						display: none !important;
					}
				}
			}

			&-empty {
				display: block;
				width: 140px;
				height: 0;
				margin-top: 0;
				margin-bottom: 0;

				@include mq(tablet) {
					display: none;
				}
			}

			> input {

				// style tile label if input is checked
				&:checked {
					+ label {
						border-color: $color-primary-1;
						background: $color-primary-1;

						&:after {
							display: inline-block;
							content: '';
							position: absolute;
							top: -24px;
							right: -24px;
							@include main-icon-selected-small;

							@include mq(tablet) {
								@include main-icon-selected-thin;
								top: -28px;
								right: -28px;
							}
						}

						&:before {
							display: none;
						}

						@include if-parent('.m-basic-calculator-content-box-newform.h-background .mm-tile.mm-tile--radio') {
							&:after {
								@include main-icon-selected-small-grey;

								@include mq(tablet) {
									@include main-icon-selected-thin-grey;
								}
							}
						}
					}

					@include if-parent('.mm-tile-container.mm-tile-container--grow') {

						~ .mm-tile-content {
							border-color: $color-primary-1;
							background: $color-primary-1;
						}
					}
				}

				&:disabled {
					pointer-events: none;
				}

				&:disabled + label {
					cursor: default;
				}

				// style tile label if input is not checked, but disabled
				&:not(:checked):disabled + label {
					opacity: 0.3;

					&:after {
						display: none;
					}

					> .mm-text-container {
						> .mm-tile-desc-info {
							visibility: hidden;
						}
					}
				}
			}

			> label {
				height: 65px;
				width: 140px;
				border: 2px solid $color-secondary-1;
				border-radius: 4px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;

				&:after {
					display: none;
					// had to fix this: IE 11 cant handle this right (only when its hidden), if the background is applied to the pseudo-after element via checked + label
					//      - on init, with default checked tile -> IE displays the icon incorrectly (after a few clicking, its ok)
					//          - fix: apply the background and change the display
					@include main-icon-selected-small;
					content: "";
					position: absolute;
					top: -24px;
					right: -24px;

					@include mq(tablet) {
						@include main-icon-selected-thin;
						top: -26px;
						right: -30px;
					}
				}

				@include if-parent('.m-basic-calculator-content-box-newform--choose') {
					&:after {
						content: "";
						@include main-icon-choose-small;
						position: absolute;
						top: -15px;
						right: -15px;

						@include mq(tablet) {
							@include main-icon-choose-thin;
							top: -18px;
							right: -18px;
						}
					}
				}

				// if there is an h-background set, the grey icons needed
				@include if-parent('.m-basic-calculator-content-box-newform--choose.h-background') {
					&:after {
						@include main-icon-choose-small-grey;
						top: -24px;
						right: -24px;

						@include mq(tablet) {
							@include main-icon-choose-thin-grey;
							top: -28px;
							right: -28px;
						}

						// modifier-choose: adds choose and selected icon to the inputs
						// exception:
						// if there is a multiple choice and the hidden context are radio-buttons - they only have to have selected items (not the "choose" ones)
						@include if-parent('.m-basic-calculator-content-box-newform--choose .mm-tile-container--radio[data-js-hidden-content]') {
							display: none;
						}
					}
				}

				@include if-parent('.mm-tile-container.is-odd') {
					width: calc(100% - 30px);
					position: relative;
					margin: 0 auto;

					@include mq('tablet') {
						width: 150px;
						display: flex;
						position: static;
					}

					> .mm-text-container {
						display: block;
						text-align: left;
						padding: 10px 5px;

						@include mq('tablet') {
							display: inline;
							text-align: center;
							padding: 0;
						}

						> .mm-tile-desc-sub {
							display: inline;
							padding-left: 10px;

							@include mq('tablet') {
								display: block;
								padding-left: 0;
							}
						}
					}
				}

				@include if-parent('.mm-tile-container.mm-tile-container--large') {
					width: 100%;

					@include mq('tablet') {
						width: 250px;
						padding: 20px;
					}
				}

				@include if-parent('.mm-tile.mm-tile--large') {
					width: 300px;
					padding: 20px;

					@include mq('tablet') {
						width: 250px;
					}
				}

				@include if-parent('.mm-tile.mm-tile--small') {
					width: auto;
					min-width: 80px;
					padding: 0 12px;
					height: 36px;

					@include mq('tablet') {
						padding: 0 20px;
					}
				}

				@include if-parent('.m-basic-calculator-content-box-newform.h-background') {
					background-color: $color-primary-2;

					@include if-parent('.mm-tile-container.mm-tile-container--grow') {
						background: none;
						border: none;
					}
				}

				@include if-parent('.mm-tile-container.mm-tile-container--grow') {

					@include absolute(0, 0);
					width: 100%;
					height: 100%;
					padding: 0;
					z-index: 1;
				}

				@include if-parent('.mm-tile-container.mm-tile-container--flex-height') {
					height: 100%;
					align-items: flex-start;
					padding: 5px 5px 20px 5px;

					> .mm-text-container {
						height: 100%;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
					}
				}

				@include if-parent('.mm-tile-container.mm-tile-container--wide') {

					@include mq(tablet) {
						width: 230px;
					}

					@include mq(desktop-l) {
						width: 250px;
					}
				}

				img {
					&.is-no-measure {
						margin-top: 10px;
					}
				}

				> .mm-text-container {
					text-align: center;

					@include if-parent('.mm-tile-container.mm-tile-container--grow') {
						width: 100%;
					}

					@include if-parent('.mm-tile-container.mm-tile-container--wide') {
						text-align: center;
					}

					> .mm-tile-desc-head {
						@include milo-bold($font-size-16, 18px);
						letter-spacing: -0.07em;
					}

					> .mm-tile-desc-sub {
						display: block;
					}

					> .mm-tile-desc-info {
						@include font-size($font-size-12);

						&--active {
							display: none;
						}
					}
				}
			}

			> .mm-tile-content {
				display: block;
				position: relative;
				max-width: 150px;
				padding-top: 10px;

				@include mq('tablet') {
					padding-top: 20px;
					max-width: 150px;
				}

				@include if-parent('.mm-tile-container.mm-tile-container--grow') {
					max-width: none;
					width: auto;
					padding: 20px 10px 10px 10px;
					border: 2px solid $color-secondary-1;
					border-radius: 4px;
					background: $color-primary-2;

					@include mq('tablet') {
						width: 250px;
						height: 100%;
						padding-left: 20px;
						padding-right: 20px;
					}
				}

				> p {
					margin-bottom: 10px;
				}
			}

			&--checkbox {
				> input {

					// show other text if cbx is checked
					&:checked + label {
						&:after {
							@include main-icon-selected-small;
							top: -24px;
							right: -24px;

							@include if-parent('.m-basic-calculator-content-box-newform.h-background') {
								@include main-icon-selected-small-grey;
							}

							@include mq(tablet) {
								@include main-icon-selected-thin;
								top: -26px;
								right: -30px;

								@include if-parent('.m-basic-calculator-content-box-newform.h-background') {
									@include main-icon-selected-thin-grey;
									top: -28px;
									right: -28px;
								}
							}
						}

						> .mm-text-container {

							> .mm-tile-desc-info {

								&--default {
									display: none;
								}

								&--active {
									display: block;
								}
							}
						}
					}
				}

				> label {

					// initial icon
					&:after {
						display: block;
						@include main-icon-choose-small;

						@include mq(tablet) {
							@include main-icon-choose-thin;
						}
					}

					@include if-parent('.m-basic-calculator-content-box-newform.h-background') {
						&:after {
							@include main-icon-choose-small-grey;
							top: -24px;
							right: -24px;

							@include mq(tablet) {
								@include main-icon-choose-thin-grey;
								top: -28px;
								right: -28px;
							}
						}
					}
				}
			}
		}

		> .mm-contact {

			> .mm-contact-content {

				> .mm-contact-head {
					@include milo-bold()
				}
			}

		}
	}

	.mm-endtext {
		text-align: left;
		padding: 0 9px;

		.l-form-general.l-form-general--mgl-standard & {
			padding: 0;
			max-width: initial;
		}
	}

	.mm-fileSize {
		position: relative;
		width: 100%;
		margin-top: 5px;
		padding: 10px 0;
		border-top: 1px solid $color-secondary-9;
		border-bottom: 1px solid $color-secondary-9;
		text-align: right;
		@include milo-bold();

		@include mq(desktop) {
			border-bottom: 0;
			padding: 10px 35px 0 0;
		}

		&.is-error {
			color: $color-action-alert;
		}

		> span.js-fileSizeSumUnit {
			@include mq(desktop) {
				margin-right: 282px;
			}
		}

		> span.filesize-label {
			@include absolute($top: 10px, $left: 0px);
			margin: 0;

			@include mq(desktop) {
				position: static;
				margin: 0 70px 0 0;
			}
		}

		&.is-add-cost {
			display: none;

			@include mq(desktop) {
				display: block;
			}

			> span.filesize-label {
				@include mq(desktop) {
					margin-right: 0;
					position: absolute;
					left: 215px;
				}
			}
		}

		// exception for police file upload
		&.is-police-fileSize {
			padding-top: 20px;
			border-bottom: 0;

			// double border
			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 5px;
				width: 100%;
				height: 1px;
				background-color: $color-secondary-9;
			}

			> span.filesize-label {
				top: 20px;
				left: 0;

				@include mq(tablet) {
					position: absolute;
					margin-right: 0;
					left: calc(50% - 150px);
				}

				@include mq(desktop) {
					left: 297px;
				}
			}

			> span.js-fileSizeSumUnit {
				@include mq(tablet) {
					margin-right: calc(50% - 160px);
				}

				@include mq(desktop) {
					margin-right: 257px;
				}
			}
		}
	}

	// clone remove button (bin icon)
	.js-clone-remove,
	.mm-delete-icon {
		@include absolute($top: 2px, $left: calc(100% - 40px));
		@include main-icon-delete;
		cursor: pointer;
		width: 20px;
		height: 20px;
		z-index: 1;

		@include mq(tablet) {
			top: 8px;
		}

		&.hidden {
			display: none;
		}
	}

	.ll-row.is-error.is-check > .ll-item-container:not(.ll-item-container--center) .js-clone-remove {
		top: auto;
		bottom: 11px;
	}

	.mm-form-container {

		> .mm-fieldset {

			strong {
				&.mm-center {
					display: block;
					text-align: center;
				}
			}

			> .mm-row {

				position: relative;
				display: flex;
				flex-flow: column wrap;

				@include mq(tablet) {
					flex-direction: row;
					align-items: center;
				}

				&:first-child {

					// add padding top to first mm-row if container is not first child of comp
					@include if-parent('.mm-form-container:not(:first-child)') {
						padding-top: 30px;

						@include mq(tablet) {
							padding-top: 20px;
						}
					}
				}

				&:not(.h-space-m):not(.h-space-l) {
					margin-bottom: 30px;

					@include mq(tablet) {
						margin-bottom: 20px;
					}
				}

				&:last-child:not(.h-space-m):not(.h-space-l) {
					margin-bottom: 0;
				}

				&.h-hidden + .mm-row {

					// add padding top to first mm-row if container is not first child of comp
					@include if-parent('.mm-form-container:not(:first-child)') {
						padding-top: 30px;

						@include mq(tablet) {
							padding-top: 20px;
						}
					}
				}

				// &--center
				&--center {
					justify-content: center;
				}

				// &--center-input
				&--center-natural {
					@include mq(tablet) {
						padding: 0 30px;
					}
				}

				&--column {
					@include mq(tablet) {
						flex-flow: row nowrap;
						justify-content: space-between;
					}

					.js-clone-remove,
					.mm-delete-icon {

						top: -40px;
						left: auto;
						right: -15px;

						@include mq(tablet) {
							top: auto;
							left: auto;
							right: -20px;
							bottom: 25px;
						}
					}
				}

				&--block {
					align-items: flex-start;
				}

				&.ll-depend {
					display: none;
				}

				> .mm-row-text {
					flex: 1 0 100%;
					text-align: left;

					@include mq(tablet) {
						text-align: center;
					}

					&:not(.h-space-m):not(.h-space-l) {
						margin-bottom: 20px;

						@include mq(tablet) {
							margin-bottom: 10px;
						}
					}

					&--align-to-input {

						max-width: 300px;

						@include mq(tablet) {
							margin-left: calc(100% / 3);
							text-align: left;
						}
					}

					&--align-left {
						@include mq(tablet) {
							text-align: left;
						}
					}
				}

				.mm-error-msg {
					width: 100%;
					max-width: 300px;
					max-height: 0;
					padding: 0;
					overflow: hidden;
					opacity: 0;
					color: $color-action-alert;
					transition: max-height 1.35s ease, opacity 1.35s ease;
					order: 2;

					@include mq(tablet) {
						margin-left: calc(100% / 3);
						order: 0;
					}

					@include if-parent('.mm-row.is-check.is-error') {
						opacity: 1;
						max-height: 300px;
						padding-bottom: 10px;
					}

					@include if-parent('.mm-row.mm-row--center') {

						margin-right: calc(100% / 3);

						@include mq(tablet) {
							max-width: none;
						}
					}

					@include if-parent('.mm-row.mm-row--center.mm-row--center-natural') {

						@include mq(tablet) {
							margin-left: 50%;
							margin-right: 0;
						}
					}

					@include if-parent('.mm-row.mm-row--center.mm-row--center-big') {

						@include mq(tablet) {
							margin-left: 30%;
							margin-right: 0;
						}
					}

					// exceptions for mm-row.mm-row--column
					@include if-parent('.mm-row.mm-row--column') {
						@include mq(tablet) {
							margin-left: 0;
							padding-right: 5px;
						}
					}
				}

				.mm-col-label {

					width: 100%;
					margin-bottom: 5px;

					@include mq(tablet) {
						width: calc(100% / 3);
						margin-bottom: 0;
						padding-right: 20px;
						text-align: right;
					}

					// .mm-col-label validation
					@include if-parent('.mm-row.is-check.is-error') {
						order: 1;

						@include mq(tablet) {
							order: 0;
						}
					}

					// exception .mm-row--center
					@include if-parent('.mm-row.mm-row--center') {
						width: auto;
					}

					// exception .mm-row--center.mm-row--center-input
					@include if-parent('.mm-row.mm-row--center.mm-row--center-natural') {

						@include mq(tablet) {
							flex: 0 0 50%;
						}
					}

					@include if-parent('.mm-row.mm-row--center.mm-row--center-big') {

						@include mq(tablet) {
							flex: 0 0 25%;
							margin-left: 5%;
						}

						+ .mm-inputcontainer {
							flex: 1 0 45%;
							margin-right: 25%;
							margin-left: initial;
						}
					}

					@include if-parent('.mm-row.mm-row--block') {

						padding-top: 7px;
					}

					// exception for mm-row--column
					@include if-parent('.mm-row.mm-row--column') {
						margin-left: 0;
						width: 100%;
						text-align: left;

						@include mq(tablet) {
							padding-right: 0;
						}
					}

					&--layer {
						padding-right: 42px;
						display: flex;

						@include mq(tablet) {
							display: block;
						}

						> * {
							&:first-child {
								margin-right: 10px;

								@include mq(tablet) {
									margin: 0;
								}
							}
						}
					}

					> .mm-label {
						@include milo-bold();

						> small {
							@include milo-regular();
						}

						> span.h-optional {
							@include milo-regular();
						}
					}
				}

				> .mm-inputcontainer {

					position: relative;
					display: flex;
					flex-flow: row nowrap;
					flex-grow: 1;
					max-width: 100%;

					@include mq(tablet) {
						max-width: 300px;
						margin-left: calc(100% / 3);
					}

					// .mm-inputcontainer validation
					@include if-parent('.mm-row.is-check.is-error:not(.mm-row--column)') {
						order: 3;

						@include mq(tablet) {
							order: 0;
						}
					}

					// exception .mm-row--center
					@include if-parent('.mm-row.mm-row--center') {

						flex: 0 1 auto;

						@include mq(tablet) {
							max-width: none;
							margin-left: 0;
						}
					}

					// exception .mm-row--center.mm-row--center-input
					@include if-parent('.mm-row.mm-row--center.mm-row--center-natural') {

						@include mq(tablet) {
							flex: 1 0 auto;
						}
					}

					// exception .mm-row--center.mm-row--center-input
					@include if-parent('.mm-row.mm-row--center.mm-row--center-big') {

						@include mq(tablet) {
							flex: 1 0 40%;
							margin-right: 30%;
							margin-left: 30%;
						}
					}

					// exception .mm-row.mm-row--column
					@include if-parent('.mm-row.mm-row--column') {

						flex-flow: column nowrap;
						margin-bottom: 15px;
						order: 0;

						@include mq(tablet) {
							align-self: flex-end;
							margin-left: 0;
							padding-right: 40px;

							max-width: none;
							flex: 1 1 auto;
						}

						@include if-parent('.mm-row.mm-row--column-1-3-3') {

							@include mq(tablet) {
								flex: 1 0 33%;
							}

						}

						@include if-parent('.mm-row.mm-row--column-1-4-4') {

							@include mq(tablet) {
								flex: 1 0 25%;
							}
						}
					}

					@include if-parent('.mm-row.mm-row--block') {

						display: block;
					}

					&.is-check.is-error {
						> .mm-error-msg {
							opacity: 1;
							max-height: 300px;
							padding-bottom: 10px;
						}

						> .mm-check {
							opacity: 1;
							visibility: visible;
							@include main-icon-close-red;
						}
					}

					// validation for input-container used in row--columns
					&.is-check.is-valid {
						> .mm-check {
							opacity: 1;
							visibility: visible;
							@include main-icon-check-green;
						}

					}

					> .mm-check {

						@include absolute(-3px, $right: -42px);
						opacity: 0;
						width: 22px;
						height: 22px;
						margin: 0;
						visibility: hidden;
						transition: opacity $form-animation ease;

						@include if-parent('.mm-row.is-check.is-valid') {
							opacity: 1;
							visibility: visible;
							@include main-icon-check-green;
						}

						@include if-parent('.mm-row.is-check.is-error') {
							opacity: 1;
							visibility: visible;
							@include main-icon-close-red;

							@include if-parent('.mm-row.mm-row--column') {
								@include mq(tablet) {
									top: 0;
									bottom: 0;
								}
							}
						}

						@include if-parent('.mm-row.mm-row--column') {
							top: auto;
							bottom: -4px;

							@include mq(tablet) {
								right: 0;
							}
						}
					}

					> .a-basic-input-select {
						max-width: none;
					}

					// exception if .a-basic-input-text--date ~ .mm-check
					> .a-basic-input-text--date + .mm-check {
						left: 130px;

						@include mq(desktop) {
							left: 170px;
						}
					}

					// exception for a-basic-input-text--date
					> .a-basic-input-text--date:not(:only-of-type) + .mm-check {

						@include mq(desktop) {
							right: -50px;
						}
					}
				}

				// exception if label is a prev sibling
				> .mm-col-label + .mm-inputcontainer {

					@include mq(tablet) {
						margin-left: 0;
					}
				}

				> .mm-description {
					width: 100%;
					max-width: 300px;
					max-height: 0;
					padding: 0;
					overflow: hidden;
					opacity: 0;
					clear: both;
					line-height: normal;
					transition: opacity $form-animation ease, max-height $form-animation ease;

					@include mq(tablet) {
						margin-left: calc(100% / 3);
					}

					// required for transition
					&:before {
						content: "";
						display: block;
						margin-top: 5px;
					}

					&.is-visible,
					&.mm-description--permanent {
						opacity: 1;
						max-height: 200px;
					}

					@include if-parent('.mm-row.mm-row--center-natural') {
						@include mq(tablet) {
							margin-left: 50%;
							max-width: none;
						}
					}
				}
			}
		}
	}

	.mm-upload-container {

		max-width: 300px;

		@include mq(tablet) {
			max-width: none;
		}

		> .mm-row {

			display: flex;
			flex-flow: column nowrap;

			@include mq(tablet) {
				flex-flow: row wrap;
			}

			> .mm-error-msg {
				width: 100%;
				max-height: 0;
				padding: 0;
				overflow: hidden;
				opacity: 0;
				color: $color-action-alert;
				transition: max-height 1.35s ease, opacity 1.35s ease;

				@include mq(tablet) {
					text-align: center;
				}

				@include if-parent('.mm-row.is-check.is-error') {
					opacity: 1;
					max-height: 300px;
					padding: 0 0 10px 0;
				}
			}

			> .mm-row-content {

				display: flex;
				flex: 1 0 100%;

				&--left {

					@include mq(tablet) {
						flex: 0 1 67%;
					}

					> .mm-col-label {

						display: none;
						flex: 0 0 200px;

						@include mq(tablet) {
							display: block;
						}

						> .mm-label {
							display: inline-block;
							padding-top: 7px;
							@include milo-bold();
						}
					}
				}

				&--right {

					margin-bottom: 10px;

					@include mq(tablet) {
						flex: 1 0 33%;
						justify-content: flex-end;
						margin-bottom: 0;
					}

					// spacings for every direct child of .mm-row--right without distinct <tag> or .class selectors
					> * {
						margin: 0 5px;

						&:first-child {
							margin-left: 0;
						}

						&:last-child {
							margin-right: 0;
						}
					}
				}
			}

			> .mm-upload-button-wrap {
				flex: 1 0 auto;

				@include mq(tablet) {
					margin-left: 200px;
				}
			}
		}
	}


	// vresContentbox styles for rvv dummy
	// plain buttons
	.mm-plain-button {
		display: block;
		position: relative;
		padding: 0;
		cursor: pointer;
		text-align: left;
		text-decoration: none;

		@include mq(tablet) {
			text-align: center;
		}

		&--left {
			margin-left: 0;
		}

		// clone btn
		&--clone {
			padding-left: 30px;

			&:before {
				display: block;
				width: 22px;
				height: 22px;
				content: "";
				@include main-icon-clone;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 100;
			}

		}
	}

	.ll-row {
		padding: 0;

		&.is-clone-child {
			justify-content: flex-start !important;
			max-width: 550px !important;
		}

		&.is-check {
			.ll-item-container {
				.ll-check {
					position: absolute;
					right: 0;
					top: 28px;
					z-index: 1;
					opacity: 1;
					visibility: visible;

					@include mq(tablet) {
						right: 28%;
					}
				}
			}
		}

		// upload validation error styles
		&.is-check.is-error {
			.ll-item-container {
				.ll-error-msg {
					opacity: 1;
					max-height: 300px;
					padding: 5px 0 0 0;

					@include mq(desktop) {
						max-width: none;
					}

					> a {
						color: $color-action-alert;
					}
				}

				.ll-check {
					@include main-icon-close-red;
				}
			}
		}


		// upload validation valid styles
		&.is-check.is-valid {
			.ll-item-container {
				.ll-check {
					@include main-icon-check-green;
				}
			}
		}

		.ll-col-data {
			display: flex;
			flex-direction: column;


		}

		.ll-item-container {
			.ll-error-msg {
				transition: none;
				order: 2;
			}
		}

		.ll-col-label {
			&--right {
				text-align: right !important;
			}

			.ll-label {
				font-weight: bold;

				> span {
					font-weight: normal;
				}
			}
		}
	}

	.ll-multirow {
		margin-left: auto;
		margin-right: auto;

		.ll-row {

			&.is-check {

				.ll-item-container {
					.ll-check {
						right: 0;
					}
				}

				&:first-child {
					.ll-item-container {
						.ll-error-msg {
							padding-right: 10px;
						}
					}
				}

				&:nth-child(2) {
					.ll-item-container {
						.ll-error-msg {
							padding-left: 10px;
						}
					}
				}
			}

			> .ll-item-container {
				> .ll-col-data {
					@include if-parent('.ll-row--last') {
						flex-direction: row;
					}
				}
			}
		}
	}

	.ll-delete-clone {
		@include absolute($top: 2px, $right: 0);
		left: auto !important;
		@include main-icon-delete;
		cursor: pointer;
		display: block !important;
		width: 20px !important;
		height: 20px !important;

		@include mq(tablet) {
			top: 8px !important;
		}

		&.hidden {
			display: none;
		}
	}

	.ll-col-label {
		&--min-height {
			@include mq(tablet) {
				min-height: 55px;
			}
		}
	}

	.js-delete-select-clone {
		@include main-icon-delete();
		display: inline-block;
		top: 11px;
		position: relative;
		cursor: pointer;
	}
}