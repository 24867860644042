/* m-basic-table--borderless */
.m-basic-table--borderless {
	width: 100%;

	tbody {
		> tr {
			background: $color-primary-2;
			border: 0;

			&:first-child {
				border: 0;
			}

			&:nth-child(even) {
				background-color: $color-primary-2;
			}

			> td {
				border: 0;
				text-align: left;
				padding: 5px 30px;

				&:first-child {
					border: 0;
					text-align: left;
					width: 180px;

					@include mq(tablet) {
						width: 145px;
					}

					@include mq(desktop-xl) {
						width: 180px;
					}
				}
			}
		}
	}
}