.a-basic-input-select--my-adac-content {
	.aa-controller {
		top: -4px;
		right: 15px;
		@include main-icon-arrow-right-grey;
		background-repeat: no-repeat;
		transform: rotate(90deg);
	}

	> select {
		padding: 10px;
		height: 40px;
		margin-bottom: 6px;
		outline: none;
		background-color: $color-secondary-8;
		@include font-size(16px, 18px);
	}
}