.m-basic-toggle--av {

	.mm-head {
		padding-left: 0;
		background: none;

		@include mq('tablet') {
			padding: 15px 9px 15px 0;
		}

		&:before {
			content: " ";
			position: absolute;
			display: block;
			width: 35px;
			height: 24px;
			@include main-icon-keyboard-arrow-down;

			@include mq('tablet') {
				width: 40px;
			}
		}

		> p {
			padding-left: 70px;

			@include mq('tablet') {
				padding-left: 75px;
			}
		}

		&.mm-opened {
			background: none;

			&:before {
				@include main-icon-keyboard-arrow-down;
				transform: rotate(-180deg);
			}

			+ .mm-content {
				padding-left: 35px;

				@include mq('tablet') {
					padding-left: 40px;
				}
			}
		}
	}
}