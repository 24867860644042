/* o-basic-form */
.o-basic-dynamicbutton {

	button,
	a {
		&.m-context-btn {
			display: none
		}

		display: inline-block
	}
}