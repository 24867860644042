/* m-adac-maps */
.m-adac-maps--mgldiscountmaps {

	> .mm-switch-map {
		display: block;
		overflow: hidden;
		border-bottom: 1px solid $color-secondary-9;
		margin-bottom: 5px;
		margin-right: 0;

		@include mq(tablet) {
			display: none;
		}

		> .js-switch {
			opacity: 0.5;
			display: inline-block;
			outline-color: $color-primary-2;
			margin-left: 10px;

			&.is-active {
				opacity: 1;
			}
		}
	}

	// exception if .mm-switch-map is direct sibling of .mm-head-map
	> .mm-head-map + .mm-switch-map {
		margin-top: -35px;

		&.is-stuck {
			margin-top: 0;
		}
	}

	> .mm-maps-nav {
		> .mm-wrapper {

			@include mq(tablet) {
				display: inline-block;
				margin-bottom: 0;
			}

			> .mm-wrapper-header {

				@include mq(tablet) {
					float: left;
					line-height: 30px;
					margin-right: 10px;
				}
			}

		}
	}

	.mm-maps-mgl-wrapper {
		@include mq(tablet) {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
		}

		// @EW72 fyi (by LM):
		// no direct child selector on purpose bc element might be wrapped via custom scrollbar etc.
		.mm-result-list-wrap {
			background-color: inherit;
			border: none;
			padding: 0;
			width: 100%;
			max-width: 100%;
			display: none;

			@include mq(tablet) {
				float: left;
				max-height: none;
				display: block;
				box-sizing: border-box;
				overflow: hidden;
				border-bottom: 0;
			}

			// Namics has extra div container per h2 and ul
			> div {

				&:last-child {
					> .mm-result-list {
						margin-bottom: 0;
					}
				}

				> .mm-result-list {
					margin-bottom: 20px;
				}
			}

			.mm-result-list {
				margin: 0 0 20px 0;
				padding: 0;
				border-top: 0;

				@include mq(tablet) {
					margin: 0 37px 0 0;
					border-top: 1px solid $color-secondary-9;
				}

				&:not(:last-child) {
					margin-bottom: 20px;
				}

				> li {
					position: relative;
					padding-top: 10px;
					padding-bottom: 13px;
					border-bottom: 1px solid $color-secondary-9;

					&:last-child {
						border-bottom: 0;

						@include mq(tablet) {
							border-bottom: 1px solid $color-secondary-9;
						}
					}
				}
			}

			&.is-active {
				display: block;
			}
		}

		.mm-map-wrapper {
			flex: 1 0 100%;
			padding-bottom: 100%;
			display: none;
			width: 100%;

			@include mq(tablet) {
				flex: 1 0 50%;
				height: auto;
				max-width: 340px;
				max-height: 320px;
				padding-bottom: 0;
				display: block;
			}

			@include mq(desktop) {
				max-width: 320px;
			}

			@include mq(desktop-l) {
				max-width: 50%;
			}

			&.is-active {
				display: block;
			}

			// exception for cardamage-confirmation
			.mm-right & {
				@include mq(tablet) {
					padding-bottom: 56%;
				}
			}

			// exception for cardamage-cluster
			.l-main-content--cluster .mm-right & {
				@include mq(tablet) {
					padding-bottom: 38%;
				}
			}
		}
	}

	// exception for molecule m-adac-maps--searchresults
	&.m-adac-maps--searchresults {
		.mm-result-list-wrap > .mm-result-list {
			&:not(:last-child) {
				margin-bottom: 60px;
			}

			> li {
				padding-top: 20px;
				padding-bottom: 20px;
			}
		}

		// neccessary to set a max-height for sticky map, otherwise it won't stick correctly
		> .mm-maps-mgl-wrapper > .mm-map-wrapper {
			height: auto;
			margin-top: 0;
			padding-bottom: 0;

			@include mq(tablet) {
				height: 100%;
				max-height: 600px;
				overflow: visible;
			}
		}

		.mm-map-image {
			margin-bottom: 21px;
		}
	}
}