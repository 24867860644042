/* a-basic-link--back */
.a-basic-link--back {

	&:before {
		content: '';
		@include main-icon-arrow-left-black;
		top: -10px;
	}

	&.a-basic-link--right {
		display: inline-block;
		float: right;
		padding-right: 0;
		left: inherit;
	}

	// exception for "backToOverview"-Elem
	.l-main-content > &:not(.h-space-m):not(.h-space-l) {
		margin-bottom: 15px;
	}
}