.o-basic-form--my-adac {

	.l-outer--my-adac & {
		margin: 0 0 24px 0;
	}

	> .oo-fieldset {

		> legend {
			background-color: transparent;
			@include milo-regular(18px, 20px);
			margin: 0 0 20px 0;
			padding: 0;
		}

		> .oo-row {
			margin: 0 0 10px 0;
			padding: 0;
		}
	}
}