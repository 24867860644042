/* a-basic-input-textarea */
.a-basic-input-textarea {
	width: 100%;
	padding: 5px 15px;
	min-height: 200px;
	background: $color-secondary-8;
	border-bottom: 1px solid $color-secondary-3;

	&:disabled {

		& ~ .aa-label {
			&:after {
				.l-form-general & {
					@include main-icon-lock;
					content: '';
					position: absolute;
					right: -10px;
					top: 3px;
					opacity: 0.3;
				}
			}

			> .aa-label {
				color: $color-secondary-20;
			}
		}
	}

	.l-form-general & {
		border: 1px solid $color-formfield-border-standard;
		min-height: 208px;
		border-radius: 4px;
		background: $color-primary-2;
		padding: 10px 75px 5px 15px;

		&:hover:not(:focus) {
			border: 1px solid $color-formfield-border-hover;
		}

		&:focus {
			border: 2px solid $color-formfield-border-hover;
		}

		&:disabled {
			border-color: $color-secondary-5;
			color: $color-secondary-20;
			-webkit-text-fill-color: $color-secondary-20;
			opacity: 1;

			&:after {
				@include main-icon-lock;
				content: '';
				position: absolute;
				right: -10px;
				top: 3px;
				opacity: 0.3;
			}

			&:hover {
				border-color: $color-secondary-5;
			}

			& ~ .aa-label {
				width: calc(100% - 30px);
				height: 100%;
				color: $color-secondary-20;
			}
		}
	}

	.m-my-adac-dashboard-box-layer & {
		min-height: 110px;
	}

	.m-basic-sticky-service-contact & {
		@include mq(tablet) {
			background-color: $color-primary-2;
		}
	}

	&--maxwidth {
		max-width: 640px;
		margin: 0 auto;
	}

	.l-form-general .ll-col-data & {
		max-width: 400px;
	}

	.o-basic-form & ~ .oo-description {
		opacity: 0;
		padding: 0;
		max-height: 0;
		overflow: hidden;
		transition: all $form-animation ease;
		transition-delay: 0.1s;
	}

	.o-basic-form &.js-focus ~ .oo-description {
		opacity: 1;
		max-height: 200px;
	}

	// l-form + ll-form-general
	.l-form & ~ .ll-description {
		opacity: 0;
		padding: 0;
		max-height: 0;
		overflow: hidden;
		transition: all $form-animation ease;
		transition-delay: 0.1s;
		width: 100%;
		display: block;
		text-align: center;
	}

	.l-form-general & ~ .ll-description {
		opacity: 0;
		max-height: 0;
		overflow: hidden;
		transition: all $form-animation ease;
		transition-delay: 0.1s;
		width: 100%;
		max-width: 400px;
		display: block;
		text-align: right;
		margin-top: -6px;
		padding-right: 10px;
		color: $color-secondary-4;
	}

	.l-form-general & ~ .js-clear-button,
	.l-form & ~ .js-clear-button {
		display: none;
		position: absolute;
		cursor: pointer;
		top: 20px;
		right: 5px;
		z-index: 10;
		width: 44px;
		height: 44px;

		> span {
			display: block;
			position: relative;
			width: 26px;
			height: 26px;

			&:after {
				content: '';
				position: absolute;
				left: -11px;
				top: -11px;
				transition: right 0.4s;
				@include main-icon-cancel;
			}
		}

		&:focus {
			outline: none;

			> span {
				.is-keyboard-focus & {
					border: 2px solid $color-primary-3;
					border-radius: 50%;
				}
			}
		}
	}

	.l-form-general & ~ .js-clear-button {
		> span {
			&:after {
				left: -11px;
				top: -11px;
			}
		}
	}

	.l-form & ~ .js-clear-button {
		> span {
			&:after {
				left: 0;
				top: 0;
			}
		}
	}

	.l-form-general &.a-basic-input-textarea--clear ~ .js-clear-button,
	.l-form &.a-basic-input-textarea--clear ~ .js-clear-button {
		display: initial;
	}

	.l-form-general &.a-basic-input-textarea--clear ~ .js-clear-button {
		top: 30px;
		right: 5px;
	}

	.l-form &.a-basic-input-textarea--clear ~ .js-clear-button {
		right: 30px
	}

	.m-my-adac-dashboard-box-layer .l-form &.a-basic-input-textarea--clear ~ .js-clear-button {
		right: 5px;
	}

	.l-form-general &.a-basic-input-textarea--clear.is-floating ~ .js-clear-button {
		display: initial;
	}

	.l-form-general .ll-row.is-check.is-error > .ll-col-data > &.a-basic-input-textarea--clear ~ .js-clear-button,
	.l-form-general .ll-row.is-check.is-valid > .ll-col-data > &.a-basic-input-textarea--clear ~ .js-clear-button {
		display: initial;
		right: 30px;
	}

	.l-form-general .ll-row.is-error.is-check > .ll-col-data > & ~ .ll-check,
	.l-form-general .ll-row.is-valid.is-check > .ll-col-data > & ~ .ll-check {
		top: 21px;
	}

	.l-form .ll-row--cardamage & ~ .ll-description {
		text-align: left;
	}

	.l-form .ll-col-label + .ll-col-data & {
		max-width: 400px;

		// exception for m-basic-socialshare
		.m-basic-socialshare & {
			max-width: none;
		}
	}

	.o-basic-form .oo-col-label + .oo-col-data & {
		max-width: 400px;
	}

	.l-form &.js-focus ~ .ll-description,
	.l-form-general &.js-focus ~ .ll-description {
		opacity: 1;
		max-height: 200px;
	}

	// Exception for .m-my-adac-dashboard-box-layer
	.m-my-adac-dashboard-box-layer .l-form & ~ .ll-description {
		display: flex;
		justify-content: flex-end;
		width: 100%;

		> .js-textarea-count {
			margin-right: 5px;
		}
	}

	// Exception molecule m-basic-teaser-box--padding
	.m-basic-teaser-box--padding & {
		margin-top: 3px;
	}

	// Exception molecule m-basic-socialshare
	.m-basic-socialshare & {
		min-height: 198px;
	}

	&:focus {
		border-bottom: 2px solid $color-primary-1;
	}

	//  Error exception
	.ll-row.is-error & {
		border-bottom: 2px solid $color-action-alert;
	}

	//  Error exception .l-form-general
	.l-form-general .ll-row.is-error & {
		border: 2px solid $color-action-alert;
	}

	&.h-space-s {
		margin-bottom: 0;
	}

	// Exception when in ll-depend following to basic-radio
	.a-basic-input-radio + .ll-depend & {
		margin-left: 25px;

		+ .ll-description {
			margin-left: 25px;
		}
	}

	// exception for cardamage forms
	.l-form .ll-row--cardamage .ll-col-data > & {

		height: 50px;
		min-height: 60px;
		max-height: 100px;
		max-width: none;

		@include mq(tablet) {
			width: 540px;
			max-height: 60px;
			resize: none;
		}

		& + .ll-description {

			float: right;
			margin-right: 0;

			@include mq(tablet) {
				margin-right: -15px;
			}
		}
	}

	.l-form .ll-row--cardamage-small .ll-col-data > & {
		@include mq(tablet) {
			width: 515px;
		}
	}

	.l-form--sticky-contact .ll-col-data > & {
		height: 85px;
		min-height: 50px;
		max-height: 100px;
	}

	// exception .m-basic-calculator-content-box
	.m-basic-calculator-content-box .mm-form-container > .mm-fieldset > .mm-row > .mm-inputcontainer > & {

		min-width: 100%;

		@include mq(tablet) {
			min-width: 0;
			width: 640px;
			margin: 0 auto;
		}

		+ .ll-description {

			@include absolute($right: 0, $bottom: -10px);
			display: block;
			width: 100%;
			max-height: 0;
			padding: 0;
			overflow: hidden;
			text-align: left;
			opacity: 0;
			transform: translate(0, 100%);
			/* property name | duration | timing function | delay */
			transition: opacity $form-animation ease 0.1s, max-height $form-animation ease 0.1s;

			@include mq(tablet) {
				text-align: center;
			}
		}

		&.js-focus + .ll-description {
			max-height: 200px;
			opacity: 1;
		}
	}

	//&.is-floating:placeholder-shown,
	&.is-floating:not(:placeholder-shown),
	&.is-floating:not([placeholder=" "]),
	&.is-floating[value]:not([value=""]),
	&.is-floating:not(.is-empty),
	&.is-floating.js-clear,
	&.is-floating.a-basic-input-text-textarea--clear,
	&.is-floating:focus {
		.l-form-general & {
			padding-top: 40px;
		}
	}

	& ~ .aa-label {
		.l-form-general & {
			position: absolute;
			z-index: 1;
			top: 15px;
			transition: top 0.5s;
			left: 16px;
			@include milo-regular($font-size-16);
			font-weight: normal;
			color: $color-secondary-20;
			max-width: calc(100% - 15px);
			line-height: 16px;

			//input:not(:placeholder-shown), input:not([placeholder=" "]),
			@include if-parent('.a-basic-input-textarea.is-floating:not(:placeholder-shown), .a-basic-input-textarea.is-floating:not([placeholder=" "]), .a-basic-input-textarea.is-floating[value]:not([value=""]), .a-basic-input-textarea.if-floating:not(.is-empty), .a-basic-input-textarea.is-floating.js-clear, .a-basic-input-textarea.is-floating.a-basic-input-text-textarea--clear, .a-basic-input-textarea.is-floating:focus') {
				left: 2px;
				top: 2px;
				transition: top 0.5s;
				position: absolute;
				@include milo-regular($font-size-14);
				width: calc(100% - 20px);
				padding-left: 14px;
				padding-top: 6px;
				height: 30px;
			}
		}

		.l-form-general .ll-row.is-check.is-error & {
			@include if-parent('.a-basic-input-textarea.is-floating:placeholder-shown, .a-basic-input-textarea.is-floating[value]:not([value=""]), .a-basic-input-textarea.if-floating:not(.is-empty), .a-basic-input-textarea.is-floating.js-clear, .a-basic-input-textarea.is-floating.a-basic-input-text-textarea--clear, .a-basic-input-textarea.is-floating:focus') {
				color: $color-action-alert;
			}
		}
	}
}