/* m-ves-tab */
.m-ves-tab {
	max-width: 730px; // 30 extra for validation icons

	@include mq(print) {
		display: none;
	}

	// Exception:
	.l-form .ll-fieldset & {
		margin-left: 10px;
	}

	// exception ll-main-content-switch
	.ll-main-content-switch & {
		margin-right: 2 * $l-main-content-padding-side;

		@include mq(tablet) {
			margin-right: 2 * $l-main-content-padding-side-tablet;
		}

		@include mq(desktop) {
			margin-right: 2 * $l-main-content-padding-side-desktop;
		}
	}

	.swiper {
		@include mq(print) {
			display: none;
		}
	}

	.mm-nav {
		overflow: hidden;
		display: table;
		table-layout: fixed;
		max-width: 100%;
		margin: 0;
		padding: 0;

		@include mq(print) {
			display: none;
		}

		li {
			position: relative;
			z-index: 1;
			display: table-cell;
			border: 1px solid $color-secondary-3;
			border-left: 1px solid $color-secondary-4;

			&:first-child {
				border-left: 1px solid $color-secondary-3;
			}

			&.is-active {
				z-index: 3;
				border-bottom: 1px solid $color-primary-2;
				border-left: 1px solid $color-primary-2;

				&:first-child {
					border-left: 1px solid $color-secondary-3;

					&:before {
						content: none;
					}
				}

				&:before {
					content: "";
					position: absolute;
					left: -1px;
					top: -1px;
					width: 1px;
					height: 1px;
					background: $color-secondary-3;
				}

				&:after {
					content: "";
					position: absolute;
					bottom: -1px;
					right: -1px;
					width: 1px;
					height: 1px;
					background: $color-secondary-3;
				}

				a {
					background-color: $color-primary-2;
					cursor: default;
					position: relative;

					&:before {
						content: '';
						@include main-icon-arrow-drop-down;
						@include absolute($top: -6px, $left: -3px)
					}

					&:focus {
						background-color: $color-primary-2;
						outline: 0;
					}
				}
			}

			> a {
				display: block;
				margin-bottom: -303px;
				padding: 5px 15px 308px 30px;
				@include font-size($font-size-15);
				background-color: $color-secondary-9;
				text-align: left;
				text-decoration: none;
				vertical-align: top;
				position: relative;
				@include milo-bold();

				&:before {
					content: '';
					@include main-icon-link;
					@include absolute($top: -6px, $left: -3px)
				}

				&:focus {
					background-color: $color-primary-2;
				}
			}
		}
	}

	.mm-tabcontent {
		position: relative;
		z-index: 2;
		border-top: 1px solid $color-secondary-3;
		margin-top: -1px;
	}

	.mm-tabcontent-inside {
		width: 100%;
		padding: 20px 0 0 0;
		background: $color-primary-2;

		@include mq(print) {
			height: auto;
			margin: 0;
			overflow: visible;
			position: static;
			width: auto;
			border-bottom: 1px solid $color-secondary-3;
		}

		> .l-form {
			.ll-row {
				> .ll-col-label {
					> h3 {
						font-weight: normal;
					}
				}
			}
		}
	}

	.mm-tabcontent-printheadline {
		display: none;

		@include mq(print) {
			display: block;
		}
	}
}