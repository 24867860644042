/* a-basic-time-refresh--reworked */
.a-basic-time-refresh--reworked {
	> .aa-timerefresh {
		margin-top: 0;

		> .aa-timerefresh-content {
			border: none;
			padding-left: 0;

			> .aa-timerefresh-wrapper {
				flex: initial;
				margin-right: 50px;
				margin-bottom: 15px;

				@include mq(tablet) {
					margin-bottom: 0;
				}

				> .aa-last {
					font-weight: bold;
					margin-right: 20px;
				}

				> span {
					&:nth-child(3) {
						margin: 0 5px;
					}
				}
			}
		}
	}
}