.m-my-adac-vehicle-image--dashboard {
	> .mm-wrapper {
		> .mm-default {
			> .mm-circle-wrapper {
				> .mm-circle {
					> .mm-circle-icon {
						width: 35px;
						height: 33px;

						&:before {
							@include main-icon-add-black-big;
							transform: scale(2.2);
							top: 0;
							left: 0;
						}
					}
				}
			}
		}

		> .mm-vehicle-image {

			> .mm-img-wrapper {
				border-radius: 0;
			}
		}
	}
}