/* a-basic-input-text--date */
.a-basic-input-text--date {
	width: 130px;

	@include mq(desktop) {
		width: $form-width-s;
	}

	.m-basic-keywordfilter .l-form & {
		@include mq(desktop) {
			width: 130px;
		}
	}

	.l-form-general & {
		width: 100%;

		&:not(.a-basic-input-text--date-long) {
			max-width: 200px;
		}

		&--date-long {
			max-width: none;
		}
	}

	// exception when in .m-ves-rs-contentbox
	.m-ves-rs-contentbox & {
		max-width: none;
		width: 250px;

		@include mq(tablet) {
			max-width: 300px;
			width: auto;
		}
	}

	// exception when in .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration & {
		@include mq(tablet) {
			width: 140px;
		}
	}

	// exception .m-basic-calculator-content-box
	.m-basic-calculator-content-box & {

		@include mq(tablet) {
			width: 140px;
		}

		.is-touch & {

			@include mq(tablet) {
				width: 140px;
			}
		}

		@include if-parent('.a-basic-input-text--date.a-basic-input-text--s') {
			width: 100px;
		}
	}

	// exception .m-basic-calculator-content-box .mm-row.is-check.is-error
	.m-basic-calculator-content-box .mm-form-container > .mm-fieldset > .mm-row.is-check.is-error > .mm-inputcontainer > & {
		border-bottom: none;
	}

	> input {
		padding-right: 0;
		width: 100%;

		-webkit-appearance: none;

		& + .js-clear-button {
			display: none;
		}

		.l-form & {
			&[placeholder="MM.JJJJ"] {
				width: 75px;
			}

			&[placeholder="TT.MM.JJJJ"] {

				// exception when in .m-ves-rs-contentbox
				.m-ves-rs-contentbox & {
					width: 250px;

					@include mq(tablet) {
						width: 140px;
					}

					.is-touch & {
						width: $form-width-s;
					}
				}

				// exception when in .m-basic-calculator-content-box
				.m-basic-calculator-content-box & {
					width: calc(100% - 30px);

					@include mq(desktop) {
						width: 140px;
					}

					.is-touch & {
						width: 100%;

						@include mq(desktop) {
							width: 140px;
						}
					}

					@include if-parent('.a-basic-input-text--date.a-basic-input-text--s') {
						width: 100px;
					}
				}

				// exception for .m-basic-radius-search
				.m-basic-radius-search & {
					width: 120px;
				}
			}
		}

		// exception .m-basic-calculator-content-box .mm-row.is-check.is-error
		.m-basic-calculator-content-box .mm-form-container > .mm-fieldset > .mm-row.is-check.is-error > .mm-inputcontainer > & {
			border-bottom: 2px solid $color-action-alert;
		}
	}

	&.a-basic-input-text--col-1 {
		width: auto;

		> input {
			width: 265px;

			.l-form-general & {
				width: 100%;

				@include mq(tablet) {
					width: calc(400px - #{$form-width-s-general + $form-gutter-restyle});
				}
			}
		}

		.l-form--cardamage-map & {

			@include mq(tablet) {
				width: 240px;
			}

			> input {
				width: calc(100% - 30px);

				@include mq(desktop) {
					width: 100%;
				}

				.is-touch & {
					width: 100%;
				}
			}
		}
	}

	.is-touch .l-form & {

		width: $form-width-s;

		@include mq(desktop) {
			width: 130px;
		}
	}

	&.a-basic-input-text--my-adac-content {
		width: 130px;

		@include mq(tablet) {
			width: 100%;
		}

		> input {
			color: $color-secondary-1;

			/* Chrome, Firefox, Opera, Safari 10.1+ */
			&::placeholder {
				color: $color-secondary-3;
				/* Firefox */
				opacity: 1;
			}
		}
	}
}