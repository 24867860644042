/* m-basic-list--article */
.m-basic-list--article {

	> li {
		margin: 0 0 23px 0;
		padding: 0;
		background: 0;

		&:before {
			display: none
		}

		@include mq(print) {
			&:before {
				display: none
			}
		}

		.mm-head {
			margin: 0 0 0 0;

			// append style to every (nested!!!-)child
			> * {
				float: left;

				&:after {
					margin: 0 8px 0 8px;
					content: "|";
				}
			}

			// but not the last one
			> *:last-child {
				&:after {
					margin: 0;
					content: " ";
				}
			}
		}

		> p {
			margin: 3px 0 -11px 0;
			padding: 0 0 0 11px;
			font-weight: bold;

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-arrow-right-black;
				position: absolute;
				left: -18px;
				top: 9px;
			}

			@include mq(print) {
				background: none;

				&:before {
					width: 0;
					height: 0;
					border-top: 5px solid transparent;
					border-left: 5px solid #CC3300;
					border-bottom: 5px solid transparent;
					content: "";
					display: inline-block;
					position: absolute;
					top: 21px;
					left: 0;
				}
			}

			> a {
				text-decoration: none;
				@include milo-bold();

				.h-roboto & {
					font-family: 'Roboto Bold', sans-serif;
				}

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}