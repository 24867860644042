/* a-basic-link--sm-table-link */
.a-basic-link--sm-table-link {

	@include mq(tablet) {

		&:before {
			content: '';
			display: inline-block;
			@include main-icon-arrow-right-black;
			position: absolute;
		}
	}
}