/* m-basic-list--vesrs-premium */
.m-basic-list--vesrs-premium {

	display: flex;
	justify-content: space-around;
	flex-flow: column nowrap;
	margin-top: 20px;
	cursor: default;

	@include mq(tablet) {
		flex-flow: row nowrap;
	}

	> li {
		width: 100%;
		margin: 0;
		padding: 20px 0;
		cursor: default;
		background: none;
		opacity: 0.5;

		@include mq(tablet) {
			float: left;
			width: 25%;
			padding: 10px 0 0 20px;
			margin: 0;
		}

		// exception for .m-basic-calculator-tile
		.m-basic-calculator-tiles & {
			padding: 20px 0;

			@include mq(tablet) {
				padding: 10px 0 0 20px;
			}
		}

		&:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 1px;
			background: $color-primary-1;
			top: 0;
			left: 0;

			@include mq(tablet) {
				width: 1px;
				height: 100%;
			}
		}

		> .mm-head {

			margin-bottom: 10px;
			padding-right: 20px;
			padding-left: 10px;

			> .mm-headline-text {
				@include milo-bold($font-size-16, 18px);

				.h-roboto & {
					font-family: 'Roboto Bold', sans-serif;
				}
			}
		}

		> p {
			padding-right: 20px;
			margin-bottom: 0;
			@include font-size($font-size-14, 20px);
			text-align: left;
			padding-left: 10px;

			@include mq(tablet) {
				margin-bottom: 20px;
			}
		}

		// first-child exceptions
		&:first-child {
			padding-left: 0;

			> p {
				padding-right: 30px;
			}

			&:before {
				content: none;
			}
		}

		// last-child exceptions
		&:last-child {
			@include mq(mobile-max) {
				padding-bottom: 0;
			}

			&.active {
				&:after {
					@include mq(tablet) {
						right: 0;
					}
				}
			}

			> p {
				padding-right: 5px;
			}
		}

		&.is-active,
		&.active {

			@include if-parent('.m-basic-list--vesrs-premium:not(.is-disabled)') {
				opacity: 1;

				> .mm-head {
					max-width: calc(100% - 21px);
					background: $color-primary-1;
					padding-top: 5px;
					padding-bottom: 5px;
				}

				&:after {
					content: "";
					position: absolute;
					top: 2px;
					right: -8px;
					@include main-icon-selected-small;

					@include mq(tablet) {
						top: -15px;
						right: -6px;
						background-size: 36px auto;
						@include main-icon-selected-thin;
					}

					// exception .m-ves-rs-contentbox--grey
					.m-ves-rs-contentbox--grey & {
						@include main-icon-selected-small-grey;

						@include mq(tablet) {
							@include main-icon-selected-thin-grey;
						}
					}
				}
			}
		}
	}
}