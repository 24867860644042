/* a-basc-link--top */
.a-basic-link--top {
	&:before {
		content: '';
		display: inline-block;
		@include main-icon-arrow-top-red;
		width: 30px;
		height: 33px;
		position: absolute;
	}
}