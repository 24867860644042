/* m-basic-list--myadac-iconsmall */
.m-basic-list--myadac-iconsmall {
	li {
		margin-bottom: 0;

		&:not(:last-child):after {
			display: none;
		}

		> span {
			&:first-child {
				width: 25px;
				min-width: 25px;
				background: transparent;
			}
		}
	}
}