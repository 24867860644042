//.a-my-adac-empty-search-box
.a-my-adac-empty-search-box {
	display: none;
	padding: 30px 0 40px;
	text-align: center;
	position: relative;
	z-index: 1;

	&:before {
		top: 0;
		content: '';
		z-index: -1;
		width: 100vw;
		height: 100%;
		left: calc(50% - 50vw); // 50% - "halbes width"
		position: absolute;
		background-color: $color-primary-2;
	}

	.m-my-adac-dashboard-box--block & {
		&:before {
			display: none;
		}
	}

	> .mm-empty-search-content {

		> .mm-content-headline {
			margin-bottom: 20px;
			font-size: 25px;
		}
	}
}