/* o-layout-main-stage--align-center */
.o-layout-main-stage--align-center {

	.oo-figure {

		.oo-img {
			left: -24%;

			@include mq(tablet) {
				left: -17%
			}

			@include mq(desktop) {
				position: static;
			}

			&--col-3 {
				left: -8%;

				@include mq(tablet) {
					left: -18%;
				}

				@include mq(desktop) {
					position: relative;
					left: -8%;
				}

				@include mq(desktop-l) {
					left: 0;
				}
			}
		}
	}

	.oo-box:not(.oo-box--col-2):not(.oo-box--col-3) {
		@include mq(tablet) {
			left: 50px;
		}

		@include mq(desktop) {
			left: 205px;
		}
	}
}