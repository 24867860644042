/* m-basic-keywordfilter--button */
.m-basic-keywordfilter--button {

	@include mq(tablet) {
		width: auto;
	}

	.mm-keywordHeadline {
		padding-bottom: 5px;
	}

	.mm-searchbar {
		font-size: 0px;
		margin-bottom: 10px;

		@include mq(tablet) {
			margin-bottom: 0;
			width: 350px;
			float: left;
		}
	}

	.a-basic-btn {
		float: right;

		@include mq(tablet) {
			margin: 0 0 0 10px;
			float: left;
		}
	}
}