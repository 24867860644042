.m-basic-toggle--dashbord-toggle {

	.mm-head {
		position: relative;
		border: unset;
		border-bottom: 1px solid $color-secondary-9;
		padding-left: 0;
		padding-right: 50px;
		border-radius: 0;

		&:nth-last-child(2) {
			border: unset
		}

		&.has-icon {
			padding-left: 40px;
		}

		&:before {
			left: unset;
			top: 2px;
			right: 0;
		}

		.mm-head-icon {
			position: absolute;
			left: 0;
			top: 14px;
		}

		&.mm-opened {
			border: unset;

			& + .mm-content {
				border: unset;
				border-bottom: 1px solid $color-secondary-9;
				padding: 0 0 30px 0;
			}
		}

		&.mm-opened.has-icon {
			& + .mm-content {
				padding: 0 0 30px 40px;
			}
		}
	}
}