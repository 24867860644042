.o-layout-ves-stage--no-addons {
	> .oo-wrapper {
		> .oo-item {
			&--content {
				@include mq(desktop) {
					padding-right: 120px;
				}

				> .oo-text {
					@include mq(desktop) {
						padding-right: 0;
					}
				}
			}
		}
	}
}