/* m-basic-status-nav */
.m-basic-status-nav {
	$shadow: 2px;

	z-index: 56;
	overflow: hidden;
	margin: 0 0 20px 0;

	.mm-inner {
		position: relative;
		margin: 0 0 $shadow 0;
		@include franklin-demi($font-size-18);
		background-color: $color-secondary-6;
		box-shadow: 0 0 $shadow $color-secondary-4;
	}

	.mm-steps {
		width: 100%;
		display: table;
		position: relative;
		white-space: nowrap;
		table-layout: fixed;
		counter-reset: basic-status-nav;
	}

	.mm-indicator {
		// do not remove, jQuery.animate()!
		overflow: visible !important;
		background-color: $color-primary-1;
		@include absolute($top: 0, $left: 0, $bottom: 0);

		&:after {
			content: "";
			@include absolute($top: 0, $left: 100%);
			border-top: 20px solid transparent;
			border-bottom: 20px solid transparent;
			border-left: 20px solid $color-primary-1;
		}
	}

	.mm-step {
		width: 7%;
		height: 40px;
		font-size: 0;
		padding: 0 10px 0 10px;
		overflow: hidden;
		position: relative;
		display: table-cell;
		vertical-align: middle;
		text-overflow: ellipsis;
		text-align: center;

		@include mq(tablet) {
			width: auto;
			padding: 0;
			@include font-size($font-size-18);
		}

		&:before {
			counter-increment: basic-status-nav;
			content: counter(basic-status-nav) ". ";
			@include font-size($font-size-18);
		}

		&.is-active {
			@include font-size($font-size-18);
		}

		&.mm-step--first {
			padding-left: 10px;
		}
	}
}