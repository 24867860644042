//m-fdl-calculator--hometeaser
.m-fdl-calculator--hometeaser {
	max-width: 100%;

	@include mq(tablet) {
		max-width: 260px;
	}

	.a-basic-calculator-slider {
		margin: 0;

		.noUi-horizontal {
			margin-bottom: 10px;
		}
	}

	> .mm-calc {
		> strong {
			margin: 0 0 5px 0;
			padding: 0;
			background: transparent;
		}

		> .mm-result {
			padding: 0;

			> .mm-value-container {
				background-color: $color-primary-2;
				text-align: left;
				padding: 5px 10px;
				margin: 0;

				> p {
					margin: 0;
				}

				> span {
					font-weight: normal;
					@include font-size(12px);

					&.mm-amount {
						font-weight: bold;
						@include font-size(24px);
					}
				}

				> .a-basic-btn {
					margin-top: -10px;
					position: static;
				}
			}
		}
	}
}