/* a-basic-input-text--edit*/
.a-basic-input-text--edit {
	padding: 0 35px 0 0;

	.l-form-general & {
		padding-right: 0;
	}

	> .aa-edit-button {
		display: inline-block;
		content: '';
		position: absolute;
		right: -4px;
		top: -6px;
		width: 30px;
		height: 30px;
		@include main-icon-edit;
		background-repeat: no-repeat;

		.l-form-general & {
			top: 5px;
			right: 5px;
			background-color: $color-primary-2;
		}
	}

	// exception if the edit button exist and a description for the input is added - full width minus editWidth
	.o-basic-form-overview .oo-description & ~ .ll-description {
		// if input hasClass is-editable -> padding: 0 35 0 0; to make space for the edit icon
		width: calc(100% - 35px);
	}
}