/* m-basic-list--my-adac-check */
.m-basic-list--my-adac-check {
	li {
		position: relative;
		height: auto;
		margin: 0;
		padding: 0 0 0 40px;
		background: none;

		&:hover {
			text-decoration: none;
		}

		&:before {
			position: absolute;
			content: '';
			width: 40px !important;
			height: 40px !important;
			background-color: transparent !important;
			top: -7px !important;
			@include main-icon-check();

			@include mq(desktop) {
				top: -5px !important;
			}
		}
	}
}