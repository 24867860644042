.m-ves-rs-stickybar {
	background: $color-secondary-10;
	width: 100vw;
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 0);
	margin: 0 auto;
	z-index: 120;
	border-bottom: 2px solid $color-primary-1;
	padding: 7px 20px 2px 20px;

	@include mq(tablet) {
		padding: 19px 0 15px 0;
		border-top: 0;
		border-bottom: 0;
	}

	&.is-stuck-bottom {
		position: absolute;
		top: auto;
		bottom: -50px;
	}

	&.is-stuck-unbottom {
		position: fixed;
		top: auto;
	}

	> .mm-layer {
		background: $color-secondary-10;
		width: 100%;
		height: 0;
		@include absolute($left: 0, $right: 0);
		z-index: 10;
		padding: 0 15px;
		overflow: auto;
		display: none;
		transition: none;

		@include mq(tablet) {
			height: auto;
			transition: all 0.5s ease;
		}

		&.is-open {
			display: block;
		}

		> .mm-close-icon {
			@include absolute($right: -25px);
			cursor: pointer;
			z-index: 15;
		}

		> .mm-layer-wrap {
			max-width: 898px;
			margin: 0 auto;
			padding: 20px 35px 50px 5px;
			min-height: 235px;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: baseline;
			height: 100%;

			@include mq(tablet) {
				padding: 30px 35px 15px 5px;
				flex-direction: row;
				overflow: auto;
				height: auto;
			}

			@include mq(desktop) {
				padding: 30px 0 23px 0;
			}

			> .mm-layer-inner {
				width: 100%;

				> h3 {
					max-width: calc(100% - 40px);

					@include mq(tablet) {
						max-width: 100%;
					}
				}

				> .m-teaser-hotline {
					width: 100%;
					margin-bottom: 20px;

					@include mq(tablet) {
						width: calc(100% / 2);
						float: left;
					}

					&:last-child {
						margin-bottom: 0;
					}

					> span {
						display: block;
						@include milo-bold();
						font-weight: normal;
					}

					> a {
						display: block;
						text-decoration: none;
						margin: 5px 0;
						@include milo-bold($font-size-24);
					}
				}
			}
		}
	}

	> .mm-sticky-module {
		margin: 0 auto;
		@include font-size($font-size-17);

		@include mq(tablet) {
			@include font-size($font-size-24);
		}

		> .mm-layer-content {
			@include milo-bold();
			font-weight: normal;
			padding: 0 20px;

			> .mm-layer-content-inner {
				position: relative;
				max-width: 1075px;
				margin: 0 auto;

				> .mm-nav {
					display: flex;
					flex-wrap: wrap;
					flex-direction: row;
					justify-content: space-between;
					margin: 0;

					> .mm-nav-btn {
						position: relative;
						cursor: pointer;
						max-width: 160px;

						> figure {
							margin: 1px 0 0 0;
							max-width: 18px;
							float: left;

							@include mq(tablet) {
								margin: 1px 4px 0 0;
							}

							> img {
								width: 100%;
							}
						}

						> .mm-text {
							text-decoration: none;
							display: none;
							float: left;

							@include mq(tablet) {
								display: block;
							}
						}

						> .mm-line {
							@include absolute($top: -5px, $right: -150%, $left: -150%);
							margin: 0 auto;
							max-width: 160px;
							z-index: 11;
							display: none;

							@include mq(tablet) {
								top: -19px;
								right: -25%;
								left: -25%
							}

							@include mq(desktop) {
								right: -50%;
								left: -50%
							}

							> .mm-triangle {
								width: 0;
								height: 0;
								border-style: solid;
								border-width: 0 15px 15px 15px;
								border-color: transparent transparent $color-primary-1 transparent;
								@include absolute($top: -16px, $left: 50%);
								margin-left: -16px;
								z-index: 11;
							}
						}

						&.is-active {
							> .mm-line {
								display: block;
								border-top: 2px solid $color-primary-1;
							}
						}
					}
				}
			}
		}
	}
}