// m-mgl-sedcard
.m-mgl-sedcard {
	perspective: 1000px;
	width: 272px;
	height: 402px;
	margin: 0;
	padding: 20px 0;

	&:hover {
		& .m-card-wrap {
			transform: rotateY(-20deg);
		}

		&.hover {
			& .m-card-wrap {
				transform: rotateY(-160deg);
			}
		}
	}

	&.hover {
		& .m-card-wrap {
			transform: rotateY(-180deg);
		}
	}

	> .m-card-wrap {
		transition: 0.4s;
		transform-style: preserve-3d;
		position: relative;

		> .mm-card {
			backface-visibility: hidden;
			position: relative;
			top: 0;
			left: 0;

			> img {
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				bottom: 0;
				z-index: -1;
			}

			> .mm-content-wrapper {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				padding: 20px;

				> h3 {
					overflow: hidden;
					margin-bottom: 20px;
				}
			}

			&--front {
				z-index: 2;

				/* for firefox 31 */
				&:not(.is-ie11) {
					transform: rotateY(0deg);
				}

				&.is-ie11 + .mm-card--back {
					display: none;
					z-index: 2;
				}

				&.is-ie11-hidden {
					display: none;

					& + .mm-card--back {
						display: block;
					}
				}
			}

			&--back {
				background-color: $color-secondary-6;
				bottom: 0;
				right: 0;
				height: 362px;

				&:not(.is-ie11) {
					transform: rotateY(180deg);
				}
			}

			& .mm-turn-icon {
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 40px 40px 0;
				border-color: transparent $color-primary-1 transparent transparent;
				position: absolute;
				top: 0;
				right: 0;
				z-index: 1;

				&:before {
					content: '';
					position: absolute;
					@include main-icon-arrow-right-black;
					top: -9px;
					left: 8px;
				}
			}
		}
	}
}