/* a-basic-input-text--m */
.a-basic-input-text--m {
	width: calc(#{$form-width-general-mobile * 0.5} - #{0.5 * $form-gutter});

	@include mq(tablet) {
		width: calc(50% - #{0.5 * $form-gutter});
	}

	.l-form-general & {
		width: 100%;
		float: none;
	}

	// we are after M
	.a-basic-input-text--m + & {
		float: right;
	}

	// exception for cardamage-forms
	.l-form .ll-row--cardamage .ll-col-label ~ .ll-col-data & {

		width: calc(100% - 80px);
		max-width: 240px;

		@include mq(tablet) {
			width: 240px;
			max-width: none;
		}

		&.a-basic-input-text--expand {
			width: 100%;
			max-width: 320px;
		}
	}

	.l-form--cardamage-map .ll-row--full .ll-col-label + .ll-col-data .ll-input-spacer + & {

		width: calc(100% - 99px);
		max-width: 208px;

		@include mq(tablet) {
			width: 208px;
		}

		@include mq(desktop) {
			width: 208px;
		}
	}

	.l-form--cardamage-proof .ll-row--full .ll-col-data .ll-input-spacer + & {

		width: calc(100% - 99px);
		max-width: 208px;

		@include mq(tablet) {
			width: 208px;
		}

		@include mq(desktop) {
			width: 208px;
		}
	}

	// exception .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration .l-form .ll-item-container--full > .ll-input-container & {
		width: 210px;
	}

	// exception .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration .l-form .ll-item-container--full > .ll-col-data & {
		width: 140px;
	}

	// exception .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration .l-form .ll-item-container--full > .ll-input-container--multi & {
		width: calc(255px / 2);

		@include mq(tablet) {
			width: calc(295px / 2);
		}
	}
}