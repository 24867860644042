/* a-basic-btn--autocenter */
.a-basic-btn--my-adac-stopper {
	position: relative;
	margin: 0 0 44px 0;
	border-radius: 5px;
	padding: 7px 22px;
	@include milo-regular();
	letter-spacing: 0.5px;
	background: none;
	background-color: transparent;
	min-height: 30px;
}