// m-basic-list--teaser
.m-basic-list--no-list {
	> li {
		padding-left: 45px;
		padding-top: 5px;
		background: none;
		min-height: 28px;

		&:before {
			display: none;
		}

		@include mq(tablet) {
			padding-left: 40px;
			padding-top: 8px;
		}

		img {
			position: absolute;
			left: 5px;
			top: 0;

			@include mq(tablet) {
				left: 0;
				top: 2px;
			}

			.m-my-adac-dashboard-box-showcase & {
				@include mq(tablet) {
					top: 3px;
				}
			}
		}
	}
}