/* a-basic-link--save */
.a-basic-link--save {

	&:before {
		content: '';
		display: inline-block;
		@include main-icon-save;
		position: absolute;
		left: -20px;
		top: -13px;
	}
}