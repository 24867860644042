/* a-basic-link--add */
.a-basic-link--add {
	padding-left: 50px;
	@include milo-bold();

	&:before {
		position: absolute;
		left: -5px;
		top: -11px;
		display: block;
		content: '';
		@include main-icon-add;
		z-index: 10;
	}

	&:after {
		position: absolute;
		left: 0;
		top: -6px;
		display: block;
		background: $color-primary-1;
		content: '';
		width: 33px;
		height: 33px;
		border-radius: 50%;
	}
}