/* a-basic-input-text--daterange*/
.a-basic-input-text--daterange {
	width: 100%;

	.l-form-general & {
		&:not(.a-basic-input-text--xs):not(.a-basic-input-text--date).a-basic-input-text--ds-filter {
			input {
				padding: 0 7px;
				min-width: 75px;
				height: 40px;
			}
		}

		> .js-clear-button {
			display: none
		}
	}

	.a-basic-input-text--daterange + & {
		.l-form-general & {
			&:not(.a-basic-input-text--xs):not(.a-basic-input-text--date) {
				input {
					padding-right: 30px !important;
				}
			}
		}
	}
}