/* m-layout-header-nav */
.m-layout-header-nav {
	position: absolute;
	top: 65px;
	width: 100%;
	display: none;
	z-index: 60;
	@include milo-bold(18px);
	color: $color-secondary-1;

	@include mq(tablet) {
		position: absolute;
		top: 73px;
		bottom: 0;
		right: 0;
		padding: 0;
		border-top: 1px solid $color-primary-2;
		height: auto;
		display: block;
		overflow-y: visible;
	}

	@include mq(desktop) {
		right: auto;
		left: 0;
		width: calc(100% - 130px);
		margin: 0 0 0 130px;

		top: 82px;

		.l-outer--nav & {
			right: 0;
			left: auto;
			width: 100%;
			margin: 0;
			padding: 0;
		}
	}

	@include mq(desktop-l) {
		width: 100%;
		margin: 0;
	}

	@include mq(desktop-xl) {
		.l-outer--nav & {
			width: 100%;
		}
	}

	@include mq(print) {
		display: none;
	}

	&.is-open {
		display: block;
		overflow: hidden;
		overflow-y: auto;
	}

	> .close-bar {
		position: absolute;
		top: 0;
		left: calc(100% - 46px);
		z-index: 10;

		//adaption to hide the close btn in layer
		display: none;

		> button {
			padding: 5px 10px 0 10px;
			background: none;
			cursor: pointer;

			> img {
				height: 26px;
			}
		}
	}

	> nav {
		@include mq(tablet) {
			width: 100%;
			background: $color-primary-1;
			padding-right: 30px;
		}

		@include mq(desktop) {
			position: relative;
			padding-right: 0;
		}

		&:after {
			@include mq(desktop) {
				content: '';
				display: block;
				position: absolute;
				background: $color-primary-1;
				width: 130vw;
				top: -1px;
				left: 38%;
				transform: translate(-50%);
				height: calc(100% + 1px);
				border-top: 1px solid $color-primary-2;
				z-index: -1;
			}

			@include mq(desktop-l) {
				left: 50%;
			}
		}

		> ul {
			display: none;
			position: absolute;
			left: 0;
			width: 100%;
			padding: 1px 0 0 0;

			@include mq(tablet) {
				display: flex;
				position: relative;
				padding: 0;
				justify-content: flex-end;
				flex-flow: row nowrap;
			}

			> li {

				@include mq(tablet) {
					text-align: center;
					border-bottom: 0;

					&.mm-last {
						border-right: 0;
					}
				}

				a {
					display: block;
					margin: 0 0 1px 0;
					padding: 13px 20px;
					text-decoration: none;
					background: $color-primary-1;
					@include font-size($font-size-18);
					color: $color-secondary-1;
					outline: none;

					@include mq(tablet) {
						margin: 0;
						padding: 8px 13px;
						@include font-size($font-size-15);
					}

					@include mq(desktop) {
						padding: 12px 18px;
						@include font-size($font-size-18);
					}

					@include mq(desktop-l) {
						padding: 12px 25px;
					}

					@include if-parent('li:last-child') {
						@include mq(tablet) {
							padding-right: 0;
						}
					}

					&.is-active {
						background: $color-primary-1;
						@include milo-bold();
					}

					.l-outer--nav & {
						@include mq(desktop) {
							padding: 12px;
						}

						@include mq(desktop-l) {
							padding: 12px 25px;
						}

						@include if-parent('li:last-child') {
							@include mq(tablet) {
								padding-right: 0;
							}
						}
					}
				}

				ul {
					display: none;
					position: absolute;
					top: 0;
					left: 100%;
					width: 100%;
					min-width: 320px;
					padding: 1px 0 0 0;

					@include mq(tablet) {
						left: auto;
						top: 37px;
						width: 320px;
						margin: 0 0 0 -1px;
						padding: 0;
						height: 0;
						overflow-y: hidden;
						border: 1px solid $color-secondary-10;
					}

					@include mq(desktop) {
						top: 45px;
					}

					&.mm-first-child {
						@include mq(tablet) {
							margin: 0 0 0 0;
						}
					}

					&.mm-aside {
						@include mq(tablet) {
							left: auto;
							right: 0;
						}

						@include mq(desktop-l) {
							right: 0;
						}
					}

					&.is-active {
						height: auto;
					}

					&.is-hidden {
						height: auto;
						visibility: hidden;
					}

					@keyframes navSlideDownRefresh {
						0% {
							max-height: 0;
						}

						100% {
							max-height: 99vh;
						}
					}

					&.is-visible {
						display: block;
						height: auto;
						max-height: none;
						animation: 0.6s ease-in navSlideDownRefresh;
					}

					// custom scrollbar styles
					&.jspScrollable {

						&:focus {
							outline: none;
						}

						> .jspContainer {

							//scrollbar wrapper
							> .jspVerticalBar {
								width: 5px;
								right: 0;

								> .jspTrack {
									background: $color-secondary-5;

									> .jspDrag {
										background: $color-secondary-2;
									}
								}
							}

							> .jspHorizontalBar {
								display: none;
							}
						}
					}

					// 4th level looks different
					&.is-content-navi {

						> li:not(.mm-back) {

							> a {
								background-color: $color-primary-2;
								color: $color-secondary-1;

								&.is-active {
									@include milo-bold();
								}
							}

						}
					}

					li {

						@include mq(tablet) {
							border-top: 1px solid $color-primary-2;
						}

						&.mm-overview {
							display: block;

							@include mq(tablet) {
								border-top: none;
							}

							@include mq(desktop) {
								display: none;
							}

							+ li {
								@include mq(desktop) {
									border-top: none;
								}
							}
						}

						> a {
							display: block;
							position: relative;
							margin: 0 0 1px 0;
							padding: 13px 20px;
							background: $color-primary-1;
							font-weight: normal;
							text-decoration: none;
							color: $color-secondary-1;
							@include milo-regular($font-size-16);

							&:hover {
								background: $color-primary-2;
							}

							@include mq(tablet) {
								margin: 0;
								padding: 7px 25px 8px 25px;
								text-align: left;
							}

							@include mq(desktop) {
								@include font-size($font-size-18);
							}

							&.is-active {
								background: $color-primary-2;
							}
						}
					}
				}
			}
		}
	}

	.mm-back {
		display: block;
		margin: 0 0 1px 0;

		@include mq(tablet) {
			display: none;
		}

		& > button {
			display: block;
			width: 100%;
			padding: 12px 25px 12px 40px;
			background: url('/assets/img/icons/icon-link-back-big.svg') $color-primary-1 no-repeat 13px 10px;
			text-align: left;
			@include milo-regular();
			border: solid 3px red;
		}
	}

	.mm-loader {
		display: block;
		position: absolute;
		right: 5px;
		top: 50%;
		width: 24px;
		height: 24px;
		margin: -12px 0 0 0;
		background: url(/assets/img/icons/icon-refresh.svg) no-repeat;

		// spin animation
		animation: spin 0.8s infinite linear;

		@keyframes spin {
			0% {
				transform: rotate(0deg);
			}

			100% {
				transform: rotate(360deg);
			}
		}
	}
}