// project breakpoints
// if changed also update HELPER.breakpoints in /assets/js/S.Utils.Helper.js!

// Berechnung der Breakpoints vorab
$mobile-max-breakpoint: em(767px);
$tablet-max-breakpoint: em(1023px);
$tablet-breakpoint: em(768px);
$desktop-breakpoint: em(1025px);
$desktop-l-breakpoint: em(1220px);
$desktop-xl-breakpoint: em(1420px);
$mobile-max-height-breakpoint: em(480px);

// Definierte Map mit bereits umgerechneten Werten
$breakpoints: (
	mobile-max: "only screen and (max-width: #{$mobile-max-breakpoint})",
	// do not use this, will be deleted
	tablet-max: "only screen and (max-width: #{$tablet-max-breakpoint})",
	// do not use this, will be deleted
	tablet: ("print", "only screen and (min-width: #{$tablet-breakpoint})"),
	// Fix für "print" + Media Query
	desktop: "only screen and (min-width: #{$desktop-breakpoint})",
	desktop-l: "only screen and (min-width: #{$desktop-l-breakpoint})",
	desktop-xl: "only screen and (min-width: #{$desktop-xl-breakpoint})",
	print: "print",
	mobile-max-height: "only screen and (max-width: #{$mobile-max-breakpoint}) and (max-height: #{$mobile-max-height-breakpoint})",
	// do not use this, will be deleted
	tablet-max-height: "only screen and (max-width: #{$tablet-max-breakpoint}) and (max-height: #{$mobile-max-height-breakpoint})" // do not use this, will be deleted
);
// breakpoints for ie8 and below
$breakpoints-ie8: (
	desktop
);

// layout
$layout-header-center: 898px;
$layout-main-center: 898px;
$layout-footer-center: 898px;

// layout myAdac
$layout-my-adac-header-center: 1160px;
$layout-my-adac-main-center: 1160px;
$layout-my-adac-footer-center: 1160px;

// layout myAdacContent
$layout-my-adac-content-header-center: 1290px;
$layout-my-adac-content-main-center: 1290px;
$layout-my-adac-content-footer-center: 1290px;
$layout-my-adac-content-header-center-inner: 960px;
$layout-my-adac-content-main-center-inner: 960px;
$layout-my-adac-content-footer-center-inner: 960px;

// layout Dashboard new
$layout-my-adac-dashboard-content: 1160px;
$layout-my-adac-dashboard-landingpage: 835px;

// ** BEGIN MAIN COLOR VARS **
// color primary
$color-primary-1: #ffcc00; // yellow
$color-primary-2: #fff;
$color-primary-3: #000;

// color secondary
$color-secondary-1: #333;
$color-secondary-2: #999;
$color-secondary-3: #afafaf;
$color-secondary-4: #c5c5c5;
$color-secondary-5: #d7d7d7;
$color-secondary-6: #ededed;
$color-secondary-7: #f8f8f8;
$color-secondary-8: #f3f3f4;
$color-secondary-9: #cccccc;
$color-secondary-10: #e9e9ea;
$color-secondary-11: #0079c0;
$color-secondary-13: #8f8f8f;
$color-secondary-14: #8e8e8e;
$color-secondary-15: #cecece;
$color-secondary-16: #E5E5E5;
$color-secondary-17: #191919;
$color-secondary-18: #313131;
$color-secondary-19: #f5f5f5;
$color-secondary-20: #666666;
$color-secondary-21: rgba(0, 0, 0, 0.6);
$color-secondary-22: rgba(0, 0, 0, 0.9);
$color-secondary-23: rgba(0, 0, 0, 0.42);
$color-secondary-24: #e7bb0d;
$color-secondary-25: #F5F5F5;

$color-black-10: #00000019;
$color-black-42: #0000006B;
$color-black-60: #00000099;
$color-black-90: #000000E5;

$color-formfield-border-standard: $color-secondary-23;
$color-formfield-border-hover: $color-secondary-17;
$color-formfield-border-disabled: $color-secondary-5;

// color action
$color-action-alert: #cc3333; // red
$color-action-valid: #21A121; // green
$color-action-search: #FFF2BF; // soft yellow
$color-action-btn: #FFE06A; // hover soft yellow
$color-action-btn2: #ffb700; // hover dark yellow
$color-action-focus: $color-secondary-4;

// color rating
$color-rating-1: #009900;
$color-rating-2: #9FC938;
$color-rating-3: #FFEDA6;
$color-rating-4: #FF9900;
$color-rating-5: #cc3300;
$color-rating-6: #2E7D32;
$color-rating-7: #FFCC00;
$color-rating-8: #F18700;
$color-rating-9: #753F2A;
$color-rating-10: #95C11F;

// color pie chart
$color-pie-chart-1: #FFDD00;
$color-pie-chart-2: #009FE3;
$color-pie-chart-3: #00A13A;
$color-pie-chart-4: #702283;

// ** END MAIN COLOR VARS **

// color table compare
$color-table-compare-1: #D0D9E5;
$color-table-compare-2: #E5EAF1;
$color-table-compare-3: #EEF2F6;

// color destination detail weather table
$color-weather-table-1: $color-primary-1;
$color-weather-table-2: #ffe57f;
$color-weather-table-3: #33a3dc;
$color-weather-table-4: #93cbe7;

// color header
$color-header-spacer: #E5B700; // dark yellow
$color-header-contact-background: $color-action-alert;

// color footer:
$color-footer-background: $color-secondary-17;
$color-footer-border: $color-secondary-18;
$color-footer-white: rgba(255, 255, 255, 0.9);

// tab focus
$color-focus-tab: $color-primary-3;

// font size
$font-size-0: 0px;
$font-size-10: 10px;
$font-size-11: 11px;
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-15: 15px; // standard
$font-size-17: 17px;
$font-size-16: 16px;
$font-size-17: 17px;
$font-size-18: 18px;
$font-size-19: 19px;
$font-size-20: 20px;
$font-size-21: 21px;
$font-size-22: 22px;
$font-size-23: 23px;
$font-size-24: 24px;
$font-size-28: 28px;
$font-size-30: 30px;
$font-size-32: 32px;
$font-size-36: 36px;
$font-size-40: 40px;
$font-size-50: 50px; // header headline
$font-size-100: 100px; // movie

// grid gaps
$grid-gap-10: 10px;
$grid-gap-15: 15px;
$grid-gap-20: 20px;

// forms
$form-gutter: 10px;
$form-gutter-restyle: 15px;
$form-gutter-general: 0;
$form-width-s: 100px;
$form-width-s-general: 100px;
$form-width-s-mobile: 100px; // by changing check field combinations s + l in all solutions
$form-width-general-mobile: 278px;
$form-width-xs: 50px;
$form-animation: 1.35s;
$form-animation-label: 0.5s;

// paddings l-main-content
$l-main-content-padding-side: 15px;
$l-main-content-padding-side-tablet: 30px;
$l-main-content-padding-side-desktop: 40px;

// paddings lighbox
$l-lightbox-inner-padding-side: 30px;
$l-lightbox-inner-padding-side-tablet: 40px;
$l-lightbox-inner-padding-side-desktop: 50px;