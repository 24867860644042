/* m-basic-list--filter */
.m-basic-list--filter {

	padding: 0 0 10px 0;
	list-style-type: none;

	> li {
		display: none;
		flex-flow: row wrap;
		margin-bottom: 1px;
		padding: 0 0 2px 0;
		background: none;

		&:before {
			content: '\2013';
		}

		&.is-active {
			display: flex;
		}

		> .filter-desc {
			border-bottom: 1px solid $color-secondary-1;

			&:hover {
				cursor: pointer;
			}
		}

		> button {
			position: relative;
			margin-left: 8px;
			@include main-icon-close;
			width: 20px;
			height: 20px;
		}
	}
}