// .m-basic-header
.m-basic-header {
	// *****************
	// begin:: local vars

	// heights
	$topBarHeight: 32px;

	$mainHeaderHeight-mob: 56px;
	$mainHeaderHeight-tablet: 64px;
	$mainHeaderHeight-desk: 120px;

	// width
	$mainHeaderWidthMax-desk: 1440px;

	// spacing
	$searchbarPadding-right: 60px;

	// other
	$animDurationDefault: .3s;
	$animDurationFast: .2s;
	$animDurationSlow: .75s;

	// offset is -100px from icon center (padding x-axis + icon.width / 2)!
	$layerOffset-tablet: calc(-100px + 16px);
	$layerOffset-desktop: calc(-100px + 36px);

	// end:: local vars
	// *****************

	position: relative;
	transform: translate3d(0, 0, 0);
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
		0 1px 10px 0 rgba(0, 0, 0, 0.12),
		0 4px 5px 0 rgba(0, 0, 0, 0.14);
	z-index: 1003;

	@include mq(print) {
		position: relative !important;
		box-shadow: none !important;
	}

	@include mq(tablet) {
		z-index: 1000;
	}

	@include mq(desktop-l) {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		transform: translate3d(0, 0, 0);
		transition: none;

	}

	&.is-anim {
		animation-name: BasicHeaderSlideDown;
		animation-duration: $animDurationDefault;
		animation-timing-function: ease;

		@keyframes BasicHeaderSlideDown {
			from {
				transform: translate3d(0, -64px, 0);
			}

			to {
				transform: translate3d(0, 0, 0);
			}
		}
	}

	// breadcrumb styles
	&.has-breadcrumb:not(.is-dropshadow) {
		box-shadow: none;
	}

	// sticky class
	&.is-stuck {

		position: fixed;
		top: -56px;
		left: 0;
		width: 100%;
		transform: translate3d(0, 100%, 0);
		transition: transform $animDurationDefault ease 0s;
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
			0 1px 10px 0 rgba(0, 0, 0, 0.12),
			0 4px 5px 0 rgba(0, 0, 0, 0.14);

		@include mq(tablet) {
			top: -64px;
		}

		@include mq(desktop-l) {
			top: 0;
			transform: translate3d(0, 0, 0);
			transition: none;
			animation-name: BasicHeaderSlideUp;
			animation-duration: $animDurationSlow;
			animation-timing-function: ease;
		}

		@keyframes BasicHeaderSlideUp {
			from {
				transform: translate3d(0, 100%, 0);
			}

			to {
				transform: translate3d(0, 0, 0);
			}
		}

		&.has-breadcrumb:not(.is-dropshadow) {
			box-shadow: none;

			@include mq(desktop-l) {
				box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
					0 1px 10px 0 rgba(0, 0, 0, 0.12),
					0 4px 5px 0 rgba(0, 0, 0, 0.14);
			}
		}

		// up anim in mobile
		&.is-anim-up {
			transform: translate3d(0, -100%, 0);
			transition: transform $animDurationDefault ease 0s;
		}
	}

	// top themen
	> .mm-top-topics {
		display: none;
		min-height: $topBarHeight;
		width: 100%;
		background-color: $color-primary-3;

		@include mq(desktop-l) {
			display: block;
			position: relative;
			min-height: $topBarHeight;
			opacity: 1;
			max-width: $mainHeaderWidthMax-desk;
			margin: 0 auto;
			z-index: 110;
		}

		// hide nav animation behind this
		&:after {
			display: none;
			content: '';
			@include absolute(0, -100vw);
			width: 200vw;
			height: 100%;
			background: rgba($color-primary-3, .9);
			z-index: -1;

			@include mq(desktop-l) {
				display: block;
			}
		}

		// exception .is-stuck
		@include if-parent('.m-basic-header.is-stuck') {
			@include mq(desktop-l) {
				overflow: hidden;
				height: 0;
				opacity: 0;
				min-height: inherit;
			}
		}

		// ul
		> .mm-topics-wrap {

			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			margin: 0;
			padding: 6px 32px 6px 4px;

			> li:first-of-type {
				cursor: default;
				@include milo-bold();
				color: $color-primary-2;
			}

			// li
			> .mm-topics-list {
				padding: 0 14px;
				margin: 0 14px;
				cursor: pointer;

				&:last-child {
					margin-right: 0;
				}

				&:hover {
					> a {
						color: rgba($color-secondary-19, 1);
					}
				}

				> a {
					@extend %focus-outline-inverse;
					@include font-size($font-size-14, 20px);
					color: rgba($color-primary-2, .9);
					text-decoration: none;
				}
			}
		}
	}

	> .mm-navi-wrap {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		position: relative;
		height: $mainHeaderHeight-mob;
		padding: 12px 16px;
		background-color: $color-primary-1;
		z-index: 100;

		@include mq(tablet) {
			height: $mainHeaderHeight-tablet;
			padding: 16px 48px 16px 32px;
		}

		@include mq(desktop-l) {
			padding-bottom: 0;
			flex-wrap: wrap;
			align-items: flex-start;
			height: auto;
			min-height: $mainHeaderHeight-desk;
			max-width: $mainHeaderWidthMax-desk;
			margin: 0 auto;
		}

		// hide nav animation behind this
		&:after {
			display: none;
			content: '';
			@include absolute(0, -100vw);
			width: 200vw;
			height: 100%;
			background-color: $color-primary-1;
			z-index: -1;

			@include mq(desktop-l) {
				display: block;
			}
		}

		// exception .is-stuck
		@include if-parent('.m-basic-header.is-stuck') {
			@include mq(desktop-l) {
				flex-wrap: nowrap;
				height: $mainHeaderHeight-tablet;
				padding-right: 24px;
				min-height: inherit;
				padding-bottom: 16px;
			}
		}

		// logo
		> .mm-logo {
			margin-right: auto;
			order: 1;

			@include mq(desktop-l) {
				position: relative;
				top: 50%;
				transform: translate3d(0, -50%, 0);
				margin-right: 0;
				z-index: 5;
			}

			@include mq(print) {
				position: relative;
				background: url("/assets/img/logos/adac-logo-emailtemplate.gif") top center no-repeat;
				width: 85px;
				height: 85px;
			}

			@include mq(print) {
				&:after {
					content: 'www.adac.de' !important;
					position: absolute;
					left: 450px;
					top: 0;
				}
			}

			// exception .is-stuck
			@include if-parent('.m-basic-header.is-stuck') {
				@include mq(desktop-l) {
					flex-grow: 0;
				}
			}
		}

		// nav list
		> .mm-nav {
			@include absolute($mainHeaderHeight-mob, auto, auto, -100%);
			width: 100%;
			height: calc(100vh - 56px);
			background-color: $color-primary-2;
			transform: translateX(0);
			order: 0;
			display: none;

			@include mq(tablet) {
				top: $mainHeaderHeight-tablet;
				right: -420px;
				max-width: 420px;
			}

			@include mq(desktop-l) {
				position: static;
				width: auto;
				max-width: calc(100% - 95px);
				height: auto;
				margin: 0 auto 0 165px;
				flex: none;
				order: 4;
				background: none;
				transition: none;
				transform: none;

				display: block;
			}

			@include mq(desktop-xl) {
				margin: 0 auto 0 215px;
				max-width: calc(100% - 215px);
			}

			&:not(.is-no-anim) {
				transition: transform $animDurationDefault ease-in-out;
			}

			&.is-visible {
				display: block;
			}

			&.is-active {
				transform: translateX(-100%);

				@include mq(tablet) {
					transform: translateX(-420px);
				}

				// exception for .m-basic-header--reduced (done here due to selector length hierarchy)
				@include if-parent('.m-basic-header--reduced') {

					@include mq(desktop-l) {
						transform: translate3d(-50%, 0, 0);
					}
				}
			}

			&.is-scrollable {
				overflow-y: auto;
			}

			// exception .is-stuck
			@include if-parent('.m-basic-header.is-stuck') {
				@include mq(desktop-l) {
					margin: -6px auto 0 -28px;
					order: 2;
					flex-grow: 0;
				}
			}

			// lvl 1
			> .mm-list-wrapper {
				height: 100%;
				margin: 0;
				padding: 16px 16px 0 16px;
				display: flex;
				flex-direction: column;

				@include mq(desktop-l) {
					display: flex;
					flex-flow: row nowrap;
					padding: 0;
					overflow: hidden;
					//  left aligns nav with content
					margin: 6px 48px 0 0;
					flex-wrap: wrap;
				}

				&:not(.is-no-anim) {
					transition: margin $animDurationDefault ease-in-out;

					@include mq(desktop-l) {
						transition: none;
					}
				}

				// hide nav animation behind this
				&:after {
					display: none;
					content: '';
					@include absolute(-100%, -100vw);
					width: 200vw;
					height: 200%;
					background-color: $color-primary-1;
					z-index: -1;

					@include mq(desktop-l) {
						display: block;
					}
				}

				&.is-shifted {
					margin-left: -150px;
					margin-right: 150px;

					@include mq(tablet) {
						margin-left: 0;
						margin-right: 0;
					}
				}

				// exception .is-stuck
				@include if-parent('.m-basic-header.is-stuck') {
					@include mq(desktop-l) {
						margin: 0;
					}
				}

				> .is-top-menu {
					left: 50%;
					position: relative;
					transform: translateX(-50%);
					// counter padding
					width: calc(100% + 32px);
					flex: 1;
					display: flex;
					align-items: flex-end;

					:-ms-fullscreen,
					:root & {
						width: 100%;
					}

					@include mq(desktop-l) {
						display: none;
					}

					@include if-parent('.mm-list-wrapper.is-shifted') {
						display: none;
					}

					> .mm-topics-wrap {
						background-color: #000;
						padding: 20px 15px;
						margin: 0;

						.mm-topics-list {
							color: $color-primary-2;
							margin-bottom: 10px;

							&:first-child {
								font-size: 14px;
							}

							&:not(:first-child) {
								display: inline-block;

								> a {
									@extend %focus-outline-inverse;
									display: inline-block;
									border: 1px solid $color-secondary-20;
									padding: 8px 12px;
									white-space: nowrap;
									border-radius: 16px;
								}
							}

							&:not(:last-child) {
								margin-right: 8px;
							}

							> a {
								color: $color-primary-2;
								text-decoration: none;
							}
						}
					}

				}

				// lvl 1 // <li>
				> .mm-nav-list {
					margin: 0 4px;
					border-bottom: 1px solid $color-secondary-16;

					@include mq(desktop-l) {
						border-bottom: 0;
					}

					> a {
						&:focus {
							outline: 0;
						}

						.is-keyboard-focus &:focus {
							border: 2px solid $color-focus-tab;
							border-radius: 2px;
							padding: 10px 14px;
						}
					}

					// lvl 1 // <a>
					> .mm-list {
						display: flex;
						justify-content: space-between;
						width: 100%;
						margin: 4px 0;
						padding: 20px 0 17px 8px;
						@include milo-bold($font-size-20);
						text-decoration: none;

						@include mq(desktop-l) {
							display: inline-block;
						}

						@include mq(desktop-l) {
							position: relative;
							margin: 0;
							padding: 12px 9px;
							background: none;
							@include font-size($font-size-18, 24px);
							color: $color-secondary-21;
						}

						@include mq(desktop-xl) {
							padding: 12px 16px;

							@include if-parent('.m-basic-header.is-stuck') {
								padding: 12px 9px;
							}
						}

						// desktop hover
						&:after {
							@include mq(desktop-l) {
								content: '';
								@include absolute($left: 16px, $bottom: 0);
								width: calc(100% - 32px);
								height: 3px;
								background-color: $color-primary-3;
								transform: scaleX(0);
								transition: transform .2s ease;
							}
						}

						&:hover {
							cursor: pointer;
						}

						&.is-selected {
							color: $color-secondary-17;
						}

						// exception if parent .is-active
						@include if-parent('.mm-nav-list.is-active, .mm-nav-list.has-focus ') {
							color: $color-secondary-17;

							&:after {
								@include mq(desktop-l) {
									transform: scaleX(1);
								}
							}
						}

						// exception .is-stuck
						@include if-parent('.m-basic-header.is-stuck') {
							@include mq(desktop-l) {
								padding-bottom: 22px;
								@include font-size($font-size-16, 20px);
							}
						}

						> svg {
							@include mq(desktop-l) {
								display: none;
							}
						}
					}

					// lvl 2
					> .mm-list-wrapper {
						@include absolute(0, auto, auto, 0);
						width: 100%;
						height: calc(100vh - 56px);
						padding-left: 0;
						padding-bottom: 16px;
						background-color: $color-primary-2;
						overflow-y: auto;
						transform: translateX(100%);
						transition: transform $animDurationDefault ease-in-out;
						z-index: 1;
						display: none;

						@include mq(desktop-l) {
							display: none;
							flex-flow: row nowrap;
							top: 100%;
							left: 0;
							right: auto;
							width: 100vw;
							max-width: $mainHeaderWidthMax-desk;
							height: auto;
							transform: translate3d(0, -100px, 0);
							padding: 16px 64px 32px 64px;
							align-content: flex-start;
							opacity: 0;
							z-index: -1;
							transition: opacity $animDurationDefault linear;
							box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
								0 1px 10px 0 rgba(0, 0, 0, 0.12),
								0 4px 5px 0 rgba(0, 0, 0, 0.14);
						}

						// mobile
						&.is-active {
							transform: translateX(0);
							display: block;

							@include mq(desktop-l) {
								display: none;
							}
						}

						// desktop-l
						&.is-visible {

							@include mq(desktop-l) {
								display: flex;
							}

							&.is-active {
								@include mq(desktop-l) {
									transform: translateY(0px);
									opacity: 1;
								}
							}

							// exception .is-stuck
							@include if-parent('.m-basic-header--reduced') {
								&.is-active {
									@include mq(desktop-l) {
										transform: translateY(0px) translateX(-50%);
										left: 38%;
									}
								}
							}
						}

						&.is-no-anim {
							transition: none;
						}

						// lvl 2 // section wrap
						> .mm-list-section {

							@include mq(desktop-l) {
								width: 20%;
								margin-right: 16px;
							}

							// lvl 2 // <ul>
							> .mm-list-wrapper {
								margin: 0;
								padding: 0;

								// lvl 2 // <li>
								> .mm-nav-list {
									position: relative;
									margin-left: 16px;
									margin-right: 16px;
									border-bottom: 1px solid $color-secondary-9;
									z-index: 10;
									background-size: 16px 16px;

									@include mq(desktop-l) {
										margin: 32px 0 0 0;
										border-bottom: 0;
										background: 0;
									}

									&:first-child {
										margin-top: 0;

										// lvl 2 // section head
										@include if-parent('.mm-list-section:first-child .mm-list-wrapper') {

											display: inline-block;
											width: 100%;
											margin: 0 0 16px 0;
											padding-left: 0;
											text-decoration: none;
											border-bottom: 0;

											&:after {
												content: '';
												@include absolute(auto, 0, 0, auto);
												width: 100%;
												height: 5px;
												z-index: -1;
												// ask "loop" why
												box-shadow: 0 2px 4px -1px rgba($color-primary-3, 0.2),
													0 1px 10px 0 rgba($color-primary-3, 0.12),
													0 4px 5px 0 rgba($color-primary-3, 0.14);
											}

											// hide in desktop
											@include mq(desktop-l) {
												display: none;
											}

											.is-keyboard-focus & {
												&:after {
													@include mq(desktop-l) {
														box-shadow: none;
														border-bottom: 1px solid $color-secondary-9;
														z-index: 1;
													}
												}

												&:before {
													@include mq(desktop-l) {
														@include main-icon-arrow-right-grey-dark;
														content: '';
														position: absolute;
														z-index: 1;
														top: 11px;
														left: -15px
													}
												}

												@include mq(desktop-l) {
													display: inline-block;
												}

												> a {
													@include mq(desktop-l) {
														text-align: left;
														padding-left: 22px;
													}
												}

												> .mm-back {
													@include mq(desktop-l) {
														display: none;
													}
												}
											}
										}
									}

									&:nth-child(2) {
										@include if-parent('.mm-list-section:first-child .mm-list-wrapper') {
											@include mq(desktop-l) {
												margin-top: 0;
											}
										}
									}

									&.mm-special {

										background: none;

										& + .mm-special {
											@include mq(desktop-l) {
												margin-top: 0;
											}
										}

										&:before {
											content: '';
											@include absolute(20px, 2px, auto, auto);
											width: 16px;
											height: 16px;
											background: $color-secondary-11;
											background-size: 16px 16px;
											z-index: -1;

											@include mq(desktop-l) {
												top: 13px;
												left: 0;
											}
										}
									}

									&.mm-nav-list-img {

										margin-bottom: 0;

										@include mq(desktop-l) {
											margin-top: 0;
										}
									}

									// arrow left to travel lvl up
									> .mm-back {
										@include absolute(0, 0);
										width: 57px;
										height: 100%;
										padding: 0;

										> svg {
											position: relative;
											top: 3px;
										}
									}

									// lvl 2 // <a>
									> .mm-list {
										display: block;
										padding: 17px 40px 19px 2px;
										@include milo-bold($font-size-16);
										@include line-height(16px);
										text-decoration: none;

										@include mq(desktop-l) {
											position: relative;
											margin-bottom: 4px;
											padding: 10px 4px 10px 0;
											border-bottom: 1px solid $color-secondary-9;
											background-size: 16px 16px;
										}

										&:after {
											display: none;
											content: '';
											@include absolute(0, -5px);
											width: calc(100% + 10px);
											height: calc(100% - 3px);
											border-radius: 4px;
											background-size: 16px 16px;
											z-index: -1;
										}

										&:focus {
											outline: none;

											.is-keyboard-focus & {
												outline: 2px solid $color-focus-tab;
												border-radius: 2px;

												@include if-parent('.mm-list-section .mm-nav-list:not(.mm-special):not(.mm-nav-list-img)') {
													background-color: $color-secondary-19;
												}
											}
										}

										&:hover {
											.is-keyboard-focus & {
												outline: 2px solid $color-focus-tab !important;
											}

											@include mq(desktop-l) {
												border-radius: 4px 4px 0 0;
												background: none;
											}

											&:after {
												@include mq(desktop-l) {
													display: block;
												}
											}
										}

										&.mm-list-toggle {

											&:before {
												content: '';
												@include absolute(12px, auto, auto, 4px);
												width: 32px;
												height: 32px;
												border-radius: 4px;
												background: $color-secondary-19;

												@include mq(desktop-l) {
													display: none;
												}
											}

											&:hover {
												cursor: pointer;

												&:after {
													@include mq(desktop-l) {
														display: block;
													}
												}
											}

											// lvl 3 // toggle elem
											+ .mm-list-wrapper {
												display: none;
												height: auto;

												@include mq(desktop-l) {
													display: block;
												}
											}
										}

										&.is-open {
											border-bottom: 0;

											&:after {
												transform: rotate(-180deg);
											}
										}

										& + .mm-list-wrapper {
											> .mm-nav-list-img {
												&:first-child {
													padding-top: 8px;
												}
											}
										}

										// section head in mobile
										@include if-parent('.mm-list-section:first-child .mm-nav-list:first-child') {
											margin: 0;
											padding: 20px 24px 18px 8px;
											@include milo-bold($font-size-18, 18px);
											text-align: center;
											background: $color-primary-2;
											border: 0;

											@include mq(tablet) {
												padding-top: 24px;
												padding-bottom: 24px;
											}
										}

										// exception if only one elem is present (= 2 elems incl. the section head)
										@include if-parent('.mm-list-section .mm-nav-list:not(.mm-nav-list-img):nth-child(2):last-child') {
											padding-bottom: 20px;
											border-bottom: 0;

											@include mq(desktop-l) {
												padding-bottom: 10px;
												border-bottom: 1px solid $color-secondary-9;
											}
										}

										// special links (with blue icon)
										@include if-parent('.mm-list-section .mm-nav-list.mm-special') {
											background-image: none;

											@include mq(desktop-l) {
												border-bottom: none;
											}
										}

										// lvl 2 // <a> - teaser-img link (should look like a button in desktop-l)
										@include if-parent('.mm-list-section .mm-nav-list.mm-nav-list-img ') {
											@extend %focus-border-buttons;

											@include mq(desktop-l) {
												@include absolute(auto, 0, 15px, auto);
												width: calc(100% - 16px);
												max-width: none;
												margin: 0 8px;
												padding: 10px 22px 7px 23px;
												border-radius: 4px;
												border-bottom: none;
												@include font-size(14px);
												text-align: center;
												background: $color-primary-1;
												z-index: 30;
											}

											&:after {
												@include mq(desktop-l) {
													display: none;
												}
											}

											&:hover {
												@include mq(desktop-l) {
													background-color: $color-action-btn;
												}
											}

											& + .mm-list-wrapper {
												display: none;

												@include mq(desktop-l) {
													display: block;
												}
											}
										}

										> svg {
											position: relative;
											top: 3px;
											margin-right: 2px;
										}

										> .mm-btn-toggle {
											position: absolute;
											top: 13px;
											right: 2px;
											padding: 4px;

											&.is-closed {
												@include if-parent('.mm-list.is-open') {
													display: none;
												}

												@include mq(desktop-l) {
													display: none;
												}
											}

											&.is-open {
												display: none;

												@include if-parent('.mm-list.is-open') {
													display: inline;
												}

												@include mq(desktop-l) {
													display: none;
												}
											}
										}
									}

									// lvl 3 // <ul>
									> .mm-list-wrapper {
										padding-left: 0;
										margin: 0;
										transition: max-height $animDurationDefault ease-in-out;

										// lvl 3 // <li>
										> .mm-nav-list {
											position: relative;
											margin-bottom: 8px;
											z-index: 10;
											background-size: 16px 16px;

											@include mq(desktop-l) {
												margin-top: 2px;
												margin-bottom: 2px;
												background-position-x: 0;
												background-position-y: 6px;
											}

											&:after {
												display: none;
												content: '';
												@include absolute(0, -5px);
												width: calc(100% + 10px);
												height: 100%;
												border-radius: 4px;
												background: $color-secondary-19;
												background-size: 16px 16px;
												z-index: -1;
											}

											&:hover {
												@include mq(desktop-l) {
													border-radius: 4px;
													background: none;
												}

												&:after {
													@include mq(desktop-l) {
														display: block;
													}
												}
											}

											&:last-child {
												@include mq(desktop-l) {
													margin-bottom: 0;
												}
											}

											&.mm-nav-list-img {

												margin-top: 0;
												margin-bottom: 10px;
												background: none;

												@include mq(desktop-l) {
													display: block;
												}

												&:after {
													content: none;
												}

												&:last-child {
													margin-bottom: 0;
												}

												> .mm-list {
													@extend %focus-border-buttons;

													@include mq(desktop-l) {
														@include absolute(auto, 0, 15px, auto);
														width: calc(100% - 16px);
														max-width: none;
														margin: 0 8px;
														padding: 10px 22px 7px 23px;
														border-radius: 4px;
														border-bottom: none;
														@include milo-bold(14px);
														text-align: center;
														background: $color-primary-1;
														z-index: 30;
													}

													&:focus {
														.is-keyboard-focus & {
															background: $color-primary-1;
														}
													}

													&:after {
														@include mq(desktop-l) {
															display: none;
														}
													}

													&:hover {
														@include mq(desktop-l) {
															background-color: $color-action-btn;
														}
													}

													& + .mm-picture {
														display: none;

														@include mq(desktop-l) {
															display: block;
														}
													}
												}

												// lvl 3 // <picture>
												> .mm-picture {
													display: none;
													min-height: 150px;

													@include mq(desktop-l) {
														display: block;
													}

													> img {
														max-width: 100%;
														width: 100%;
													}
												}
											}

											// on 3rd level
											& + .mm-nav-list-img {
												margin-top: 6px;
											}

											// lvl 3 // <a>
											> .mm-list {
												display: block;
												padding: 8px 20px 13px 2px;
												@include milo-regular($font-size-14);
												@include line-height(14px);
												text-decoration: none;
												@extend %focus-outline;

												@include mq(desktop-l) {
													padding-top: 7px;
													padding-right: 0;
													padding-bottom: 7px;
												}

												&:focus {
													.is-keyboard-focus & {
														background-color: $color-secondary-19;
													}
												}

												> svg {
													position: relative;
													top: 3px;
													margin-right: 2px;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		// buttons
		> .mm-main {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			order: 3;

			@include mq(tablet) {
				flex-shrink: 0;
				z-index: 5;
			}

			@include mq(desktop-l) {
				flex: none;
				padding-top: 8px;
				z-index: 100;
				margin-left: 200px; // hack when bigger letter-spacing or font-size is set
			}

			// exception .is-stuck
			@include if-parent('.m-basic-header.is-stuck') {
				padding-top: 0;

				@include mq(desktop-l) {
					z-index: 10;
					margin-left: 0; // hack when bigger letter-spacing or font-size is set
				}
			}

			// exception .is-stuck
			@include if-parent('.m-basic-header.js-search-filled.is-stuck') {
				@include mq(desktop-l) {
					z-index: 100;
				}
			}

			// search / login / contact
			> .mm-main-btn {
				display: flex;
				flex-flow: row nowrap;
				margin-left: 26px;

				> .mm-section {

					display: block;
					margin: 0 4px;

					@include mq(tablet) {
						position: relative;
						margin: 0 10px;
					}

					@include if-parent('.m-basic-header.is-stuck') {
						@include mq(tablet) {
							margin: 0 8px;
						}
					}

					&:first-child {
						margin-left: 0;

						@include mq(desktop-l) {
							margin-right: 16px;
						}

						@include mq(desktop-xl) {
							margin-right: 20px;
						}
					}

					&:last-child {

						@include mq(tablet) {
							margin-right: 24px;
						}

						@include mq(desktop-l) {
							margin-right: 0;
						}
					}

					&:hover {
						cursor: pointer;

						> .mm-btn {
							color: rgba($color-primary-3, 0.9)
						}
					}

					> .mm-btn {
						@extend %focus-outline;
						display: inline-block;
						height: 32px;
						padding: 4px;
						text-decoration: none;

						@include mq(desktop-l) {
							color: rgba($color-primary-3, .6);
						}

						&.mm-btn-submit {
							display: inline-block;
							height: 32px;
							padding: 4px;
							text-decoration: none;
							border: 2px solid transparent;
							margin-right: -2px;
							@extend %focus-border;

							@include mq(tablet) {
								@include absolute(50%, $right: 70px);
								padding-top: 7px;
								content: '';
								transform: translate3d(0, -50%, 0);
								border-radius: 18px !important;
								width: 48px;
								height: 36px;
								z-index: 1000;
								transition: background-color .3s ease-out;
								cursor: pointer;
								outline: none;
								opacity: .6;
								background: transparent;
								right: -11px;
								top: 15px;
								margin-right: 0;

								@include if-parent('.m-basic-header.is-stuck') {
									opacity: 1;
								}

							}

							// exception if search input is filled
							@include if-parent('.m-basic-header.js-search-filled') {
								@include mq(tablet) {
									position: absolute;
									z-index: 1005;
									margin-top: 0;
									opacity: 1;
									right: -11px;
									top: 15px;
									background: $color-primary-1;
								}
							}

							> svg {
								z-index: 40;
								margin-top: 1px;
								margin-left: 2px;
							}
						}


						&.mm-btn-contact {

							> .mm-contact-img {
								position: relative;
								top: -4px;
								width: 32px;
								height: 32px;
								padding: 4px;
								border-radius: 4px;
								background-color: $color-header-contact-background;
							}

							> .mm-desc {
								top: -14px;
							}
						}

						> .mm-desc {
							display: none;
							position: relative;
							top: -6px;
							padding-left: 3px;

							@include mq(tablet) {
								display: inline;
							}

							// exception .is-stuck
							@include if-parent('.m-basic-header.is-stuck') {
								@include mq(desktop-l) {
									display: none;
								}
							}

							// exception .is-stuck
							@include if-parent('.m-basic-header--reduced') {
								@include mq(tablet) {
									display: none;
								}
							}
						}
					}

					// default layer styles
					> .mm-layer {
						display: none;
						@include absolute(-20px, 0);
						width: 100%;
						height: 100vh;
						background-color: $color-primary-2;
						transform: translateY(0);
						cursor: default;
						z-index: 40;
						opacity: 0;
						visibility: hidden;
						transition: transform $animDurationDefault ease-in-out, opacity $animDurationDefault linear;

						@include mq(tablet) {
							top: 28px;
							left: $layerOffset-tablet;
							height: auto;
							max-height: calc(100vh - 100px);
							width: 365px;
							border-radius: 4px;
							box-shadow: 0 8px 10px -5px rgba($color-primary-3, 0.2),
								0 6px 30px 5px rgba($color-primary-3, 0.12),
								0 16px 24px 2px rgba($color-primary-3, 0.14);
						}

						@include mq(desktop-l) {
							top: 25px;
							left: auto;
							right: $layerOffset-desktop;
							max-height: calc(100vh - 125px);
							width: 375px;
						}

						&:after {
							content: "";
							// countersteer layer offset.left (+ :after.width / 2)
							@include absolute(-8px, calc(-1 * #{$layerOffset-tablet} + 8px));
							width: 16px;
							height: 16px;
							display: none;
							transform: rotate(45deg);
							background-color: $color-primary-2;

							@include mq(tablet) {
								display: block;
							}

							@include mq(desktop-l) {
								left: auto;
								right: 152px;
							}

							// exception .is-stuck
							@include if-parent('.m-basic-header.is-stuck') {
								@include mq(desktop-l) {
									right: 72px;
								}
							}
						}

						// exception .is-stuck
						@include if-parent('.m-basic-header.is-stuck') {
							@include mq(desktop-l) {
								top: calc(-20px + 67px);
							}
						}

						&.is-visible {
							display: block;
							visibility: visible;
						}

						&.is-active {
							opacity: 1;
							transform: translateY(20px);
						}

						&.mm-help,
						&.mm-contact {

							@include mq(tablet) {
								left: auto;
								right: 0;
							}

							@include mq(desktop-l) {
								right: -8px;
							}

							&:after {
								left: auto;
								right: 63px;

								@include mq(desktop-l) {
									right: 73px;
								}

								// exception .is-stuck
								@include if-parent('.m-basic-header.is-stuck') {
									@include mq(desktop-l) {
										right: 16px;
									}
								}
							}

							> .mm-layer-content {

								> .mm-wrap {
									@include mq(tablet) {
										padding: 16px 0;
									}
								}
							}
						}

						&.mm-help {
							&:after {
								left: auto;

								@include mq(desktop-l) {
									right: 79px;
								}
							}
						}

						> .mm-layer-content {

							overflow-y: auto;
							max-height: calc(100vh - 60px);

							@include mq(tablet) {
								margin: 10px 0;
								max-height: calc(100vh - 145px);
							}

							> .mm-wrap {
								margin: 0 16px;
								padding: 16px 0;
								border-bottom: 1px solid $color-secondary-16;

								@include mq(tablet) {
									margin: 0 32px;
									padding: 8px 0;
								}


								&:nth-child(2) {
									padding-top: 32px;
									padding-bottom: 24px;

									@include mq(tablet) {
										padding-top: 10px;
										padding-bottom: 16px;
									}
								}

								&:last-child {
									border-bottom: none;
									margin-bottom: 10px;

									@include mq(tablet) {
										padding-bottom: 2px;
										margin-bottom: 0;
									}
								}

								&.mm-top-wrap {

									position: relative;
									height: 64px;
									margin: 0;
									padding: 20px 0;

									@include mq(tablet) {
										display: none;
									}

									> .mm-headline {
										margin: 0 auto;
										@include font-size($font-size-18, 24px);
										text-align: center;
									}

									> .mm-close {
										display: block;
										@include absolute(0, $right: 0);
										height: 100%;
										cursor: pointer;
										@include main-icon-close-dark-grey;
										top: 10px;
										right: 10px;

										@include mq(tablet) {
											display: none;
										}
									}
								}

								&.mm-login-wrap {

									padding-bottom: 14px;

									@include mq(tablet) {
										padding-bottom: 6px;
									}

									> .a-basic-link {
										padding-left: 30px;
									}

									> .mm-loggedin-text {
										margin-left: 8px;
										padding-left: 16px;
										border-left: 2px solid $color-primary-1;
										@include milo-bold($font-size-18, 24px);

										@include mq(tablet) {
											margin-left: 0;
											border-width: 4px;
											@include font-size($font-size-20, 28px);
										}
									}
								}

								> .mm-headline {
									margin-bottom: 16px;
									@include milo-bold($font-size-16, 20px);
								}
							}
						}
					}
				}
			}

			// menu
			> .mm-main-nav {

				border-left: 1px solid $color-header-spacer;

				@include mq(desktop-l) {
					display: none;
				}

				> .mm-menu {
					display: block;
					width: 34px;
					height: 100%;
					margin-left: 12px;
					padding: 2px 4px 4px 4px;
					text-decoration: none;
					position: relative;

					@include mq(tablet) {
						width: auto;
						margin-left: 20px;
					}

					@include mq(desktop-l) {
						top: -5px;
					}

					&:hover {
						cursor: pointer;
					}

					> .mm-menu-img {
						display: block;
						margin: 0 auto;
					}

					> .mm-menu-icon-wrapper {

						position: relative;
						top: -5px;
						display: block;
						height: 12px;
						margin-top: 4px;

						@include mq(tablet) {
							display: inline-block;
							width: 24px;
							margin-top: 8px;
							margin-right: 5px;
							top: 0;
						}

						> .mm-menu-bar {
							display: block;
							width: 20px;
							height: 2px;
							position: absolute;
							left: 3px;
							background-color: $color-primary-3;
							transition: transform $animDurationDefault ease-in-out, opacity $animDurationDefault linear;

							&--top {
								top: 0;
								transform-origin: top left;
							}

							&--mid {
								top: 5px;
							}

							&--bottom {
								top: 10px;
								transform-origin: bottom left;
							}
						}
					}

					> .mm-menu-text {
						display: block;
						position: absolute;
						bottom: -3px;
						@include font-size(10px);
						text-align: center;
						text-transform: uppercase;

						@include mq(tablet) {
							display: inline-block;
							position: static;
							text-transform: none;
							@include font-size(16px);
						}
					}

					// burger icon animation
					&.is-open {
						> .mm-menu-icon-wrapper {
							> .mm-menu-bar {
								&--top {
									transform: translateX(4px) rotate(45deg) scaleX(0.9);
								}

								&--mid {
									opacity: 0;
									transform: scaleX(0.9);
								}

								&--bottom {
									transform: translate3d(4px, 2px, 0) rotate(-45deg) scaleX(0.9);
								}
							}
						}

					}
				}
			}
		}

		// search field
		> .mm-search {
			display: none;
			@include absolute(0, 0);
			width: 100%;
			height: 100%;
			padding: 4px $searchbarPadding-right 4px 8px;
			background-color: $color-primary-2;
			order: 2;
			z-index: 40;

			@include mq(tablet) {
				position: relative;
				display: block;
				top: -7px;
				left: auto;
				right: -40px;
				width: auto;
				height: 44px;
				padding: 0;
				flex: 1 1 170px;
				z-index: 0;
				background-color: $color-primary-1;
				box-shadow: none;
			}

			@include mq(desktop-l) {
				max-width: 597px;
				top: 1px;
				margin-left: 134px;
				margin-right: -200px; // hack when bigger letter-spacing or font-size is set
				right: -45px;
				order: 2;
				flex: 1 1 600px;
				z-index: 5;
				background-color: transparent;
			}

			@include mq(desktop-xl) {
				max-width: 745px;
				margin-left: 190px;
			}

			// exception .m-basic-header--reduced for desktop-l
			@include if-parent('.m-basic-header.m-basic-header--reduced') {
				@include mq(desktop-l) {
					top: -7px;
				}
			}

			// exception .is-stuck
			@include if-parent('.m-basic-header.is-stuck') {
				@include mq(desktop-l) {
					display: none;
					@include absolute($top: 0, $left: auto);
					width: 800px;
					height: 64px;
					right: 140px;
					padding: 8px 0;
					flex: none;
					margin-right: 0; // hack when bigger letter-spacing or font-size is set
				}
			}

			// exception .m-basic-header--reduced.is-stuck
			@include if-parent('.m-basic-header.m-basic-header--reduced.is-stuck') {
				@include mq(desktop-l) {
					right: 275px;
				}
			}

			&.is-open {
				display: block;

				@include mq(desktop-l) {
					z-index: 20;
				}

				// exception .is-stuck
				@include if-parent('.m-basic-header.is-stuck') {
					display: block;
				}

				// search icon
				&:before {
					content: '';
					@include main-icon-search;
					position: absolute;
					right: 72px;
					top: 6px;
					z-index: 1001;
					opacity: 0.6;
					display: none;

					// exception if search input is filled
					@include if-parent('.m-basic-header.is-stuck') {
						display: block;

						@include mq(tablet) {
							display: none;
						}

					}
				}

				// button changes position while layer is open
				+ .mm-main {
					.mm-btn.mm-btn-submit {
						position: absolute;
						z-index: 40;
						right: 81px;

						@include mq(tablet) {
							right: -11px;
						}

						@include if-parent('.m-basic-header.is-stuck') {
							display: none;
						}

						@include if-parent('.m-basic-header.js-search-filled') {
							display: block;
							transform: translate3d(0, -50%, 0);
							border-radius: 18px !important;
							width: 48px;
							height: 36px;
							transition: background-color .3s ease-out;
							cursor: pointer;
							outline: none;
							background: $color-primary-1;
							opacity: 1;
							top: 28px;
							right: 72px;
							padding-top: 6px;
							padding-right: 5px;

							@include mq(tablet) {
								top: 16px;
								right: -11.5px;
							}
						}

						@include if-parent('.m-basic-header.is-stuck.js-search-filled') {
							@include mq(tablet) {
								right: 0;
							}
						}
					}
				}
			}

			> .mm-close {
				position: absolute;
				top: 6px;
				right: 15px;
				width: $searchbarPadding-right;
				height: 100%;
				cursor: pointer;
				z-index: 120;
				@include main-icon-close-dark-grey;

				@include mq(tablet) {
					display: none;
				}

				@include if-parent('.m-basic-header.is-stuck') {
					@include mq(desktop-l) {
						display: block;
						top: 11px;
					}
				}

				@include mq(desktop-l) {
					right: 10px;
				}
			}

			> .mm-submit {
				display: none;
				@include absolute(50%, $right: 68px);
				width: 54px;
				height: 44px;
				transform: translate3d(0, -50%, 0);
				cursor: pointer;
				z-index: 150;

				@include if-parent('.m-basic-header.js-search-filled') {
					display: block;

					@include mq(tablet) {
						right: -4px;
					}
				}
			}
		}

		.mm-nav,
		.mm-search,
		.mm-main,
		.m-layout-header-logo {
			@include mq(print) {
				display: none !important;
			}
		}
	}
}

// soft layer
.mm-header-dark-layer {
	visibility: hidden;
	opacity: 0;
	z-index: 950;
	transition: opacity .3s linear;

	@include mq(desktop-l) {
		// wait for the removal if user is hovering another nav item right after
		transition-delay: 150ms;
	}

	&.is-active {
		opacity: 0.3;
		transition-delay: 0s;
	}

	&.is-visible {
		visibility: visible;
	}
}