/* o-basic-form-overview */
.o-basic-form-overview {
	.oo-fieldset {
		&:not(:last-child) {
			margin-bottom: 30px;
		}

		@include mq(tablet) {
			&:not(:last-child) {
				margin-bottom: 50px;
			}
		}

		@include mq(print) {
			margin-top: 13px;
			page-break-inside: avoid;
		}

		> legend {
			display: block;
			width: 100%;
			margin: 0 0 20px 0;
			padding: 0;
			position: relative;

			@include milo-slab-bold($font-size-18, 21px);

			@include mq(tablet) {
				font-size: 22px;
				margin: 0 0 25px 0;
			}

			@include mq(desktop) {
				margin: 0 0 30px 0;
			}

			> h2 {
				@include milo-slab-bold($font-size-18, 21px);
				padding: 0;

				@include mq(tablet) {
					font-size: 22px;
				}
			}

			&.h-h3 {
				max-width: 700px;
				margin: 0 0 10px 0;
				padding: 0 0 6px 0;
				font-weight: bolder;
				@include milo-bold($font-size-16, 21px);

				@include mq(tablet) {
					@include font-size($font-size-20, 24px);
				}
			}

			.oo-edit {
				display: inline-block;
				position: relative;
				width: 30px;
				height: 25px;
				padding: 0 0 0 5px;
				top: 5px;
				right: 7px;
				cursor: pointer;
				margin-left: 15px;

				&::before {
					content: '';
					@include main-icon-edit;
					position: absolute;
					top: -10px;
					right: -10px;
				}

				> img {
					display: none;
					position: absolute;
					left: 10px;
					top: 0;
				}

				// exception when in ves rs legal protection confirmation
				.l-main-content--vesrslegalprotectionconfirmation & {
					display: none;
				}
			}

			@include mq(print) {
				margin-bottom: 6px;
			}
		}
	}

	.oo-row {
		max-width: 100%;
		padding: 0 10px 12px 10px;

		@include if-parent('.o-basic-form-overview') {
			padding-left: 0;
			// col-label: 240 & col-data: 410px
			max-width: 650px;
		}

		@include if-parent('.o-basic-form-overview--narrow') {
			max-width: none;
			display: flex;
			padding-right: 0;

			@include mq(tablet) {
				padding-right: 10px;
			}
		}

		@include if-parent('.oo-row--narrow-break') {
			max-width: none;
			display: block;

			@include mq(tablet) {
				display: flex;
			}
		}

		@include mq(tablet) {
			padding-bottom: 0;
		}

		@include mq(desktop) {
			padding-right: 0;
		}

		@include mq(print) {
			padding-bottom: 0;
			page-break-inside: avoid;
		}

		&--distance {
			padding-bottom: 16px;
		}

		&.is-error .oo-error-msg {
			opacity: 1;
			max-height: 300px;
			padding: 5px 0 0 0;
		}

		// exception for premiums
		> .l-main-cols {
			flex-direction: column;

			@include mq(tablet) {
				flex-direction: row;
			}

			> .ll-main-cols--first {
				margin: 0 15px 15px 0;
			}
		}

		.oo-error-msg {
			opacity: 0;
			padding: 0;
			max-height: 0;
			overflow: hidden;
			color: $color-action-alert;
			transition: all $form-animation ease;
		}

		.oo-col-label {
			margin: 0;
			font-weight: bold;

			@include mq(tablet) {
				margin: 0 0 8px 0;
				font-weight: normal;
				float: left;
				width: 240px;
				padding: 5px 10px 0 0;
			}

			@include mq(print) {
				font-weight: normal;
				float: left;
				width: 240px;
				padding: 5px 10px 0 0;
			}

			@include if-parent('.o-basic-form-overview--narrow') {

				flex: 0 1 30%;
				align-self: center;
				width: auto;
				max-width: 250px;
				padding-right: 10px;

				@include mq(tablet) {
					flex: 1 0 33%;
				}
			}

			@include if-parent('.oo-row--narrow-break') {
				float: none;
				display: inline-block;
				width: 100%;

				@include mq(tablet) {
					width: 220px;
				}

				@include mq(desktop-xl) {
					width: 295px;
				}
			}

			.oo-label {
				@include milo-bold();

				@include mq(tablet) {
					@include milo-regular();
				}

				> span {
					font-weight: normal;
				}
			}
		}

		.oo-col-label + .oo-col-data {
			@include mq(tablet) {
				float: left;
				width: calc(100% - 240px);
				max-width: 410px;
				padding-top: 5px;
				margin-bottom: 8px;
			}

			@include mq(print) {
				float: left;
				width: calc(100% - 240px);
				max-width: 410px;
				padding-top: 3px;
			}

			@include if-parent('.oo-row--full') {
				@include mq(tablet) {
					max-width: 410px;
				}

				@include mq(print) {
					max-width: 410px;
				}
			}

			@include if-parent('.oo-row--narrow') {
				flex: 1 0 75%;
				text-align: right;

				@include mq(tablet) {
					flex: 1 0 auto;
					text-align: left;
				}
			}

			@include if-parent('.oo-row--narrow-break') {
				width: 100%;
				float: none;
			}

			@include if-parent('.o-basic-form-overview--narrow') {
				flex: 0 1 280px;
				width: auto;

				@include mq(tablet) {
					padding-right: 20px;
					flex: 0 1 100%;
				}
			}
		}

		.oo-description {
			clear: both;
			padding-top: 1px;

			@include mq(tablet) {
				padding-top: 3px;
			}

			&:first-child {
				@include mq(tablet) {
					padding-top: 0;
				}
			}

			&--full {
				@include mq(tablet) {
					max-width: 700px;
				}
			}

			> .oo-flex-box {

				display: flex;
				flex-flow: row nowrap;
				max-width: 250px;

				> span {
					margin: 0 10px;

					@include mq(tablet) {
						margin: 0 8px;
					}

					@include mq(desktop) {
						margin: 0 10px;
					}

					&:first-of-type {
						margin-left: 0;
					}

					&:last-of-type {
						margin-right: 0;
					}

					&.oo-date {
						margin-left: auto;
						margin-right: 4px;
					}

					&.oo-time {
						margin-left: 0;
						width: 38px;
						text-align: right;

						@include mq(desktop) {
							margin-left: 4px;
						}
					}
				}
			}

			.oo-digits {
				@extend %display-as-digits;
			}

			> ul {
				&:not(.m-basic-list) {
					list-style-type: disc;
				}
			}
		}
	}
}