/* mm-my-adac-toggle-element--border*/
.mm-my-adac-toggle-element--border {

	> .mm-wrapper {
		border-bottom: 1px solid $color-secondary-9;

		> .mm-toggle-headline-wrapper {
			> .mm-toggle-headline {
				color: $color-secondary-3;
				letter-spacing: 0;
				border-top: 1px solid $color-secondary-9;
				@include font-size($font-size-15);
			}
		}

		> .mm-toggle-content {
			padding-bottom: 10px;

			> .ll-row {
				> .ll-col-data {
					width: 100%;
				}
			}

		}
	}
}