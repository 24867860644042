.m-layout-footer {
	position: relative;
	z-index: 100;
	padding: 25px 15px 20px 15px;
	background: $color-primary-1;
	text-align: center;

	@include mq(desktop) {
		display: flex;
		justify-content: center;
	}

	.l-footer.is-conversion & {
		padding: 10px 0;
	}

	> .m-left {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 20px;

		@include mq(desktop) {
			margin-right: 40px;
			margin-bottom: 0;
		}

		@include mq(desktop-xl) {
			margin-right: 130px;
		}

		> .m-left-text {
			margin-bottom: 7px;
			margin-right: 15px;
			padding-top: 4px;
			white-space: nowrap;
		}

		> .m-left-list {
			display: flex;
			margin: 0;
			padding: 0;

			> li {
				margin-right: 15px;
			}
		}

		.l-footer.is-conversion & {
			display: none;
		}
	}

	> .m-right {

		> .m-right-list {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin: 0;
			padding: 0;

			> li {
				margin-right: 30px;
				margin-bottom: 4px;

				&:last-child {
					margin-right: 0;
				}

				@include mq(desktop) {
					padding-top: 4px;
				}

				> a {
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.l-footer.is-conversion & {
			display: none;
		}
	}

	> .m-conversion {

		> a {
			text-decoration: none;

			&:first-child {
				margin-right: 20px;
			}
		}
	}
}