/* a-basic-link--inline-rte */
.a-basic-link--inline-rte {
	display: inline-block;
	margin-bottom: 20px;
	margin-right: 20px;

	&:before {
		@include main-icon-arrow-right-grey;
	}

	@include mq(tablet) {
		margin-bottom: 0;
	}

	&:last-child {
		margin-right: 0;
	}

	&.h-space-s {
		margin-bottom: 20px;

		@include mq(tablet) {
			margin-bottom: 0;
		}
	}
}