/* o-basic-banderole */
.o-basic-banderole {
	position: relative;
	padding: 15px 0 30px 0;

	// exception for m-layout-main-nav-left
	.l-outer--nav .l-main .ll-main-full & {
		background: $color-secondary-9;

		@include mq(desktop) {
			margin-left: 176px;
			width: calc(82%);
			float: right;
			padding-right: 20px;
		}

		@include mq(desktop-xl) {
			margin-left: 0;
			width: auto;
			float: none;
			padding-right: 0;
		}
	}

	// Exception Position
	.l-main-content + & {
		padding-bottom: 0;
	}

	.ll-main-center & {
		@include mq(desktop-xl) {
			margin-left: -30px;
			margin-right: -30px;
		}
	}

	.l-main-content--cluster & {
		max-width: 100%;

		@include mq(desktop-xl) {
			margin-left: 0;
		}
	}

	// Exception Navi
	@include mq(desktop) {
		.l-outer--nav .ll-main-center > & {
			float: right;
			width: 82%;
		}
	}

	@include mq(desktop-xl) {
		.l-outer--nav .ll-main-center & {
			float: none;
			width: auto;
		}
	}

	&.h-space-s {
		margin-bottom: 0;
	}

	&.is-center {
		.oo-headline {
			max-width: 898px;
			margin: 0 auto;

			@include mq(tablet) {
				padding-left: 50px;
			}

			@include mq(desktop) {
				padding-left: 40px;
			}

			&--inner {
				padding: 12px 20px;
			}
		}
	}

	.oo-headline {
		display: block;
		padding: 0 0 20px 10px;
		@include milo-slab-bold($font-size-18, 24px);


		@include mq(tablet) {
			@include font-size($font-size-28, 34px);
		}

		@include mq(print) {
			padding: 0 0 20px 0;
		}

		&--inner {
			padding: 12px 20px;
			background: $color-primary-2;
			@include font-size(32px);
		}

		// Exception .ll-main-center
		.ll-main-center > & {
			padding: 21px 0 0 20px;
			@include font-size($font-size-18);

			@include mq(tablet) {
				padding-left: 0;
				@include font-size($font-size-28);
			}
		}

		// Exception .ll-main-center and .l-main-content, extra case > div > & for extra namics wrap container
		.ll-main-center > .l-main-content > &,
		.ll-main-center > .l-main-content > div > & {
			padding-bottom: 0;

			&--inner {
				padding: 12px 20px;
			}
		}


		@include mq(tablet) {
			padding-left: 20px;
		}

		@include mq(desktop) {
			margin: 0 auto;
		}
	}

	.swiper-slide {
		.is-keyboard-focus &:focus-visible {
			outline: none;
		}
	}

	// no direct sibling selector on purpose cause comp. might be wrapped in BE/CMS
	.ll-main-center > .l-main-content &--mgldiscount {
		.oo-headline {
			padding-bottom: 20px;
		}

		.swiper {
			background: $color-primary-2;
		}

		.swiper-slide {
			.is-keyboard-focus &:focus-visible {
				outline: none;
				border: 5px solid red;
			}
		}
	}
}