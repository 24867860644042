/* m-basic-teaser-across */
.m-basic-teaser-across {

	&.h-space-s {
		margin-bottom: 0;
	}

	&.mm-first {

		// exception o-basic-banderole
		.o-basic-banderole & {
			padding-left: 15px;
			padding-right: 7px;
		}

		// exception .o-basic-banderole--white
		.o-basic-banderole--white & {
			padding-left: 0;

			@include mq(desktop-xl) {
				padding-left: 15px;
			}
		}

		// Exception o-basic-banderole --product, --highlight, --premium
		.o-basic-banderole--product &,
		.o-basic-banderole--highlight &,
		.o-basic-banderole--premium & {
			padding-right: 7px;
			padding-left: 0;
		}

		// exception .o-basic-banderole--mgldiscount
		.o-basic-banderole--mgldiscount & {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&.mm-last {

		// exception o-basic-banderole
		.o-basic-banderole & {
			padding-left: 7px;
			padding-right: 15px;
		}

		// Exception o-basic-banderole --product, --highlight, --premium
		.o-basic-banderole--product &,
		.o-basic-banderole--highlight &,
		.o-basic-banderole--premium & {
			padding-left: 7px;
			padding-right: 0;
		}

		// exception .o-basic-banderole--mgldiscount
		.o-basic-banderole--mgldiscount & {
			padding-left: 0;
			padding-right: 0;

			// exception if basic-stopper is set on last slide
			&.mm-stopper {
				padding-right: 7px;
			}
		}
	}

	.mm-headline {
		display: inline-block;
		padding: 0 0 16px 0;
		@include milo-slab-bold($font-size-24, 21px);
		color: $color-primary-2;
		font-weight: normal;

		.h-roboto & {
			font-family: 'Roboto Bold', sans-serif;
		}

		@include mq(tablet) {
			padding: 0 0 18px 0;
		}

		// exception o-basic-banderole
		.o-basic-banderole & {
			border: 0;
			clip: rect(0 0 0 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
		}
	}

	> article {
		width: 289px;
		position: relative;
		background: $color-primary-2;

		&.mm-article-filter {
			height: 251px;
			overflow: hidden;
			padding-bottom: 10px;
			background: $color-secondary-8;
		}

		.mm-article-content {
			padding-bottom: 20px;
			@include line-height(18px);
			background: $color-secondary-8;

			&--filter {
				height: auto;
			}

			.mm-header {
				padding: 7px 10px 0 10px;
				font-weight: bold;
				margin: 0;

				a {
					@include milo-bold($font-size-16);
					font-weight: normal;

					.h-roboto & {
						font-family: 'Roboto Bold', sans-serif;
					}
				}

				.mm-attention {
					@include absolute($top: -18px, $right: 0);
					padding: 0 3px 1px 3px;
					color: $color-primary-3;
					font-weight: normal;
					@include font-size($font-size-11);

					// exception for o-basic-banderole-product
					.o-basic-banderole--product & {
						position: absolute;
						top: -18px;
						text-transform: uppercase;
						color: $color-secondary-1;
						right: 0;
						background: 0;
						box-shadow: none;
						@include milo-regular($font-size-12);

						.h-roboto & {
							font-family: 'Roboto', sans-serif;
						}
					}
				}
			}

			.mm-copy {
				margin-bottom: 6px;
				padding: 0 10px;

				p {
					margin: 0 0 5px 0;
				}
			}
		}

		> figure {
			position: relative;
			margin: 0;
			height: 120px;
			background: $color-secondary-5 url(/assets/img/bg/banderole-preview.gif) center no-repeat;

			// Exception when in swiper
			.is-init & {
				background: $color-secondary-5 url(/assets/img/bg/banderole-preview.gif) center no-repeat;
			}

			.o-basic-banderole--mgldiscount & {

				max-height: 218px;
				height: auto;

				img {
					height: auto;
				}
			}

			> a {
				display: block;

				> img {
					display: block;
					width: 100%;

					// Exception when in swiper
					.is-init & {
						visibility: hidden;
					}

					@include mq(tablet) {
						max-width: 100%;
						height: 120px;
						width: 289px;
					}
				}
			}

			> .swiper-lazy-preloader {
				border: none;
			}
		}

		.mm-footer {
			border-top: 1px solid $color-secondary-9;
			padding: 6px 10px 10px 10px;
			background: $color-secondary-8;


			// Exception BasicBanderole -- product +  -- highlight
			.o-basic-banderole--product &,
			.o-basic-banderole--highlight &,
			.o-basic-banderole--premium & {
				border-color: $color-secondary-3;
			}

			// exception m-basic-teaser-across filter-template with custom scroll
			&--filter {
				border: none;
				padding-top: 0;
				overflow-y: auto;

				// custom scrollbar styles
				> .jspScrollable {

					&:focus {
						outline: none;
					}

					> .jspContainer {

						//scrollbar wrapper
						> .jspVerticalBar {
							width: 5px;
							right: 10px;

							> .jspTrack {
								background: $color-secondary-5;

								> .jspDrag {
									background: $color-secondary-2;
								}
							}
						}

						> .jspHorizontalBar {
							display: none;
						}
					}
				}
			}
		}

		> hr {
			margin: 0 0 3px 0;
		}
	}

	a {
		text-decoration: none;
	}

	// Exceptions
	// molecule m-basic-text
	.m-basic-text & {
		width: calc(100% + #{$l-main-content-padding-side} + #{$l-main-content-padding-side});
		background: $color-secondary-3;
		margin-left: -$l-main-content-padding-side;
		margin-bottom: 22px;
		padding: $l-main-content-padding-side 15px $l-main-content-padding-side $l-main-content-padding-side;
		padding-top: 14px;

		@include mq(tablet) {
			width: 344px;
			margin-left: -$l-main-content-padding-side-tablet;
			padding-left: $l-main-content-padding-side-tablet;
			margin-bottom: 4.728132%;
		}

		@include mq(desktop) {
			margin-left: -$l-main-content-padding-side-desktop;
			padding-left: $l-main-content-padding-side-desktop;
		}
	}

	.m-basic-text .mm-aside--right & {
		@include mq(tablet) {
			margin: 0;
			margin-left: 20px;
			margin-bottom: 4.728132%;
			padding-right: 30px;
		}

		@include mq(desktop) {
			margin-right: -$l-main-content-padding-side-desktop;
			padding-right: $l-main-content-padding-side-desktop;
			padding-left: 15px;
			margin-left: 40px;
		}
	}

	// ***
	// start:: EXCEPTIONS o-basic-banderole
	// ***

	// Exception when used in Banderole o-basic-banderole
	.o-basic-banderole & {
		padding: 25px 8px 0 8px;
	}

	.o-basic-banderole--mgldiscountfull & {
		background: transparent;
	}

	// Exception when used in Banderole o-basic-banderole--premium
	.o-basic-banderole--premium & {
		padding: 20px 8px;
	}

	// Exception when used in Banderole o-basic-banderole--product
	.o-basic-banderole--product & {
		padding: 20px 8px;
	}

	.o-basic-banderole--mgldiscount & {
		padding: 0; // swiper calculated the width incorrect, changed it to spaceBetween
	}

	.o-basic-banderole--product .is-init &,
	.o-basic-banderole--highlight .is-init &,
	.o-basic-banderole--premium .is-init & {
		padding: 0;
		box-sizing: content-box;
		border-left: 8px solid $color-secondary-3;
		border-right: 8px solid $color-secondary-3;
	}

	// ***
	// end:: EXCEPTIONS o-basic-banderole
	// ***
}