.m-basic-chart-diagram {
	> .mm-chart {
		display: flex;
		justify-content: space-between;

		> .mm-chart-block {
			width: 31%;
			text-align: center;
			padding: 15px;
			@include milo-bold();

			@include if-parent('.mm-chart--top') {
				background: $color-secondary-11;
			}

			@include if-parent('.mm-chart--center') {
				background: transparentize($color-secondary-11, 0.5);
			}

			@include if-parent('.mm-chart--bottom') {
				background: #E5E5E5;
			}

			&.is-empty {
				background: transparent;
			}

			&.is-vertical-center {
				display: flex;
				align-items: center;

				@include if-parent('.mm-chart--center') {
					background: $color-secondary-11;

					@include mq('tablet') {
						background: transparentize($color-secondary-11, 0.5);
						padding: 15px 0 0;
						border-right: 15px solid $color-secondary-11;
						border-left: 15px solid $color-secondary-11;
						border-bottom: 15px solid $color-secondary-11;
					}
				}
			}

			> .mm-chart-headline {
				text-align: center;

				@include mq('tablet') {
					margin-bottom: 10px;
				}

				> a {
					text-decoration: none;
					font-size: $font-size-16;
					padding: 0 0 0 12px;
					position: relative;

					@include mq('tablet') {
						font-size: $font-size-18;
						padding: 0 0 0 15px;
					}

					&:before {
						content: '';
						@include main-icon-arrow-right-black;
						display: inline-block;
						position: absolute;
						left: -18px;
						top: -13px;

						@include mq(tablet) {
							top: -11px;
						}
					}
				}
			}

			> .mm-chart-part {
				display: none;

				@include mq('tablet') {
					display: block;
				}
			}
		}
	}

	> .mm-chart-text {
		> .mm-text {
			padding: 30px 0 0 0;

			> .mm-chart-headline {
				margin-bottom: 5px;
				margin-left: 10px;
				@include milo-bold();

				> a {
					text-decoration: none;
					font-size: $font-size-18;
					padding: 0 0 0 15px;
					position: relative;

					&:before {
						content: '';
						@include main-icon-arrow-right-black;
						display: inline-block;
						position: absolute;
						left: -18px;
						top: -13px;
					}

				}
			}
		}
	}
}