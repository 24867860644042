// .m-ves-rs-contentbox--contact
.m-ves-rs-contentbox--contact {

	padding-top: 40px;
	padding-bottom: 0;
	display: flex;
	flex-flow: row wrap;

	@include mq(tablet) {
		justify-content: flex-start;
		flex-flow: row nowrap;
	}

	> .mm-contact-button {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin: 6px 10px 40px 2px;

		@include mq(tablet) {
			display: block;
			width: calc(100% - 420px);
			margin-bottom: 0;
		}
	}

	> .mm-contact {
		display: flex;
		flex-flow: row nowrap;
		margin-left: 15px;

		@include mq(tablet) {
			justify-content: flex-end;
		}

		> .mm-contact-icon {
			padding-right: 10px;
			height: 50px;
			width: 50px;
		}

		> .mm-contact-content {

			display: flex;
			flex-flow: column nowrap;

			@include mq(tablet) {
				width: calc(420px - 50px);
			}

			> .mm-contact-head {
				line-height: 18px;
				padding-bottom: 5px;
			}

			> .mm-contact-text {

				display: flex;
				flex-flow: row wrap;
				align-items: baseline;

				> .mm-contact-tel {
					padding-right: 10px;
					font-family: 'Milo Offc W02 Bold', sans-serif !important;
					@include font-size($font-size-24);
					font-weight: normal;
					max-width: none;
					text-decoration: none;
				}
			}
		}
	}
}