.m-basic-socialwall {
	> .mm-column-wrapper {
		column-gap: 15px;
		margin-bottom: 20px;

		@include mq(tablet) {
			column-count: 2;
			margin-bottom: 40px;
		}

		@include mq(desktop-xl) {
			column-count: 3;
		}

		> .mm-social-item {
			border: 1px solid $color-secondary-5;
			border-radius: 8px;
			margin-bottom: 15px;
			display: inline-block;
			width: 100%;
			max-width: 100%;

			> .mm-item-head {
				padding: 25px 15px;
				display: flex;
				align-items: center;
				column-gap: 10px;

				> .mm-icon {
					width: 24px;
					height: 24px;
					position: relative;

					&:after {
						content: '';
						position: absolute;
						left: -10px;
						top: -13px;
					}

					&--instagram {
						&:after {
							@include main-icon-instagram();
						}
					}

					&--facebook {
						&:after {
							@include main-icon-facebook();
						}
					}

					&--twitter {
						&:after {
							@include main-icon-twitter();
						}
					}

					&--blank {
						width: 0;
						margin-left: -10px;

						&:after {
							display: none;
						}
					}

					&--youtube {
						&:after {
							@include main-icon-youtube();
							top: -14px;
						}
					}

					&--tiktok {
						&:after {
							@include social-media-icon-tiktok();
							transform: scale(0.7);
							top: -14px;
						}
					}

					&--linkedin {
						&:after {
							@include social-media-icon-linkedin();
							transform: scale(0.7);
						}
					}
				}

				> .mm-name {
					color: $color-secondary-14;
				}
			}

			> .mm-media {
				picture {
					img {
						max-width: 100%;
						height: auto;
					}
				}

				video {
					max-width: 100%;
					height: auto;
				}
			}

			> .mm-item-content {
				padding: 25px 15px;

				> div {
					&:not(:last-child) {
						margin-bottom: 15px;
					}
				}

				> .mm-date {
					color: $color-secondary-14;
				}

				> .mm-text {
					overflow-wrap: anywhere;
				}

				> .mm-link {
					position: relative;
					padding-left: 30px;

					&:after {
						content: '';
						position: absolute;
						left: -15px;
						top: -13px;
					}

					&--arrow {
						padding-left: 20px;

						&:after {
							@include main-icon-arrow-right-grey-dark();
							top: -11px;
						}
					}

					a {
						text-decoration: none;
					}
				}

				> .mm-actions {
					display: flex;
					justify-content: right;

					> * {
						width: 35px;
						height: 35px;
						position: relative;
						cursor: pointer;

						&:after {
							content: '';
							position: absolute;
							left: -5px;
							top: -5px;
						}

						&.mm-like {
							&:after {
								@include main-icon-heart();
							}
						}

						&.mm-share {
							&:after {
								@include main-icon-share-grey();
							}
						}

						&.mm-comment {
							&:after {
								@include main-icon-comment();
							}
						}
					}
				}
			}
		}
	}

	> .mm-pager {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		column-gap: 20px;

		@include mq(tablet) {
			justify-content: center;
		}

		> .mm-pages {
			display: flex;
			column-gap: 15px;

			> .mm-page {
				text-decoration: none;

				&.is-active {
					@include milo-bold();
				}

				&:not(.mm-divider) {
					cursor: pointer;
				}
			}
		}

		> .mm-next,
		> .mm-prev {
			@include main-icon-arrow-right-grey2();
			cursor: pointer;
		}

		> .mm-prev {
			transform: rotate(180deg);
		}
	}
}