/* m-basic-list--document */
.m-basic-list--document {
	margin-left: auto;
	margin-right: auto;
	max-width: none;

	li {
		&:before {
			display: none;
		}
	}

	&.m-basic-list--link {
		li {
			background: none;

			&:before {
				display: none;
			}
		}
	}

	p {
		border-bottom: 1px solid $color-secondary-9;
		padding: 3px 0;

		&.mm-flex {
			display: flex;
			align-items: center;
		}

		> span {
			margin-right: 20px;

			@include mq(tablet) {
				margin-right: 40px;
			}

			@include mq(desktop) {
				margin-right: 60px;
			}
		}
	}
}