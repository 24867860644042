/* .m-basic-hint--background */
.m-basic-hint--background {
	background: $color-secondary-8;
	border: none;
	position: relative;
	padding: 13px 20px 13px 40px;
	border-radius: 8px;

	@include mq(tablet) {
		padding: 23px 83px;
	}

	&.m-basic-hint--padding-l {
		padding: 13px 25px;

		@include mq(tablet) {
			padding: 30px 83px 40px 83px;
		}
	}

	> img {
		top: 15px;
		left: 10px;

		@include mq(tablet) {
			top: 25px;
			left: 45px;
		}
	}

	> .mm-phone {
		margin-bottom: 8px;

		> span {
			display: inline-block;
			margin-bottom: 2px;

			&:last-child {
				margin-bottom: 0;
			}

			> a {
				@include milo-bold($font-size-20);
				text-decoration: none;

				.h-roboto & {
					font-family: 'Roboto Bold', sans-serif;
				}

			}
		}
	}

	&.m-basic-hint--big {
		padding: 13px 20px 13px 50px;

		@include mq(tablet) {
			padding: 23px 83px;
		}

		> img {
			top: 7px;
			left: 7px;

			@include mq(tablet) {
				top: 25px;
				left: 35px;
			}
		}

		// Exception .m-basic-hint--error
		&.m-basic-hint--error {
			max-width: none;


			@include mq(tablet) {
				padding: 25px 80px 30px 80px;
			}

			> img {
				@include mq(tablet) {
					left: 37px;
				}

			}
		}

		// Exception for o-basic-cluster-box
		.o-basic-cluster-box & {

			width: calc(100% - 60px);
			padding: 15px 0 10px 40px;
			margin: 0 30px;

			@include mq(tablet) {
				width: 100%;
				padding-top: 10px;
			}

			> img {
				top: 9px;
				left: 0;
			}

		}

	}
}