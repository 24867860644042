/* .a-basic-btn--call */
.a-basic-btn--call {
	padding: 10px 22px 7px 42px;

	&:before {
		content: '';
		display: inline-block;
		@include main-icon-phone;
		position: absolute;
		left: 0;
		top: -4px;
	}
}