/* m-basic-footnote */
.m-basic-footnote {
	margin: 0;
	padding: 0;
	@include font-size($font-size-12, $font-size-15);
	list-style: none;

	& > li:not(#{&}:last-child) {
		padding-bottom: 10px;
	}

}