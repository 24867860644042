/* m-basic-table--ruf-weather */
.m-basic-table--ruf-weather {

	tr {

		> th,
		> td {
			padding: 15px;

			> img {
				max-height: 60px;
			}
		}
	}
}