/* m-adac-maps--sticky */
.m-adac-maps--sticky {

	> .mm-head-map {

		&.is-fixed {
			position: fixed;
			top: 0;
			padding-top: 5px;
			background-color: $color-primary-2;
			z-index: 105;
			padding-bottom: 0;

			@include mq(tablet) {
				width: 100%;
				padding-top: 15px;
				padding-bottom: 10px;
				z-index: 100;
			}

			// hide contents behind the elem
			&:before {
				content: '';
				position: absolute;
				top: -500px;
				left: 0;
				right: auto;
				height: 500px;
				width: 100%;
				background: $color-primary-2;
				z-index: -1;
			}
		}
	}

	> .mm-switch-map {

		&.is-stuck {
			padding-top: 5px;
			background-color: $color-primary-2;
			z-index: 100;
			overflow: visible;

			// hide contents behind the elem
			&:before {
				content: '';
				position: absolute;
				top: calc(-500px + 30px);
				left: -100px;
				right: auto;
				height: 500px;
				width: calc(100vw + 100px);
				background: $color-primary-2;
				z-index: -1;
			}
		}
	}

	> .mm-maps-mgl-wrapper {

		> .mm-result-list-wrap {
			margin-bottom: 0;
		}

		> .mm-map-wrapper {

			@include mq(tablet) {
				padding-bottom: 50px;
			}

			&.is-stuck {
				height: auto;
				right: 0;
				padding-top: 70px;
			}
		}
	}
}