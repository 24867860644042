/* a-basic-input-text--s */
.a-basic-input-text--s {
	width: $form-width-s;

	// we are after L
	.a-basic-input-text--l + & {
		float: right;
	}

	@include mq(tablet) {
		width: $form-width-s;
	}

	.l-form-general &:not(.a-basic-input-text--right-border) {
		width: 100%;
		float: none;
	}

	> input {
		.l-form-general & {
			padding-right: 15px !important;

			@include mq(tablet) {
				padding-right: 35px;
			}

			&:disabled ~ .aa-col-label {
				&:after {
					display: none;
				}
			}
		}

		& + .js-clear-button {
			display: none;
		}


		&[placeholder="MM.JJJJ"] {
			width: 90px;

			@include mq(tablet) {
				width: 70px;
			}
		}

		&[placeholder="TT.MM.JJJJ"] {
			width: 130px;

			@include mq(tablet) {
				width: 100px;
			}
		}
	}

	// exception for cardamage-forms
	.l-form .ll-row--cardamage .ll-col-label ~ .ll-col-data & {
		width: 70px;
	}

	// exception .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration .mm-upload-container > .mm-row > .mm-row-content &,
	.m-ves-rs-contentbox--damagedeclaration .mm-tile-container > .mm-row > .mm-row-content & {
		width: 100px;
	}

	.m-ves-rs-contentbox--damagedeclaration .l-form .ll-item-container--full > .ll-input-container & {
		width: 85px;
	}
}