/* m-layout-header-logo */
.m-layout-header-logo {
	position: absolute;
	padding: 0;
	top: 18px;
	left: 20px;
	z-index: 30;

	@include mq(tablet) {
		left: 25px;
		top: 25px;
	}

	@include mq(desktop-xl) {
		left: -165px;
	}

	@include mq(print) {
		top: 12px;
		left: 0;
	}

	// Exception .l-outer--nav
	.l-outer--nav & {

		@include mq(desktop) {
			left: 25px;
			top: 25px;
			right: auto;
		}

		@include mq(desktop-l) {
			left: calc(30% - (100vw / 3));
			right: auto;
			top: 25px;
		}

		@include mq(desktop-xl) {
			right: auto;
			left: -165px;
		}

	}

	// Exception m-basic-header
	.m-basic-header > .mm-navi-wrap > .mm-logo > & {
		position: relative;
		top: 0;
		left: 0;

		@include mq(desktop-l) {
			position: absolute;
			top: 19px;
		}

		// exception .is-stuck
		@include if-parent('.m-basic-header.is-stuck') {

			@include mq(desktop-l) {
				position: relative;
				top: 0;
				transform: none;
			}
		}

		// exception .m-basic-header--reduced
		@include if-parent('.m-basic-header--reduced') {
			@include mq(desktop-l) {
				position: relative;
				top: 0;
				transform: none;
			}
		}
	}

	> a {
		display: block;
		@extend %focus-outline;

		// Exception m-basic-header
		.m-basic-header & {
			@include mq(desktop-l) {
				max-height: 50px;
			}
		}

		> img {
			max-width: 60px;

			@include mq(tablet) {
				max-width: 66px;
			}

			@include mq(desktop) {
				max-width: 90px;
			}

			// Exception m-basic-header
			.m-basic-header & {

				display: block;
				height: 100%;

				@include mq(tablet) {
					max-width: 81px;
				}

				@include mq(desktop-l) {
					height: 50px;
					max-width: none;
					transform: scale(1.0);
					transform-origin: 0 100%;
					transition: transform .3s ease-in-out 0s;
				}

				// exception .is-stuck
				@include if-parent('.m-basic-header.is-stuck') {
					@include mq(desktop-l) {
						transform: scale(0.6);
						transform-origin: 0 50%;
					}
				}

				// exception .m-basic-header--reduced
				@include if-parent('.m-basic-header--reduced') {
					@include mq(desktop-l) {
						max-width: 85px;
					}
				}
			}
		}
	}

}