/* o-layout-main-stage--align-left */
.o-layout-main-stage--align-left {

	.oo-figure {

		.oo-img {
			left: 0;

			@include mq(tablet) {
				left: 0;
			}

			@include mq(desktop) {
				position: static;
			}

			&--col-3 {
				left: 0;

				@include mq(tablet) {
					left: 0;
				}

				@include mq(desktop) {
					position: relative;
					left: 0;
				}

				@include mq(desktop-l) {
					left: 0;
				}
			}

			&.is-fixed-height {

				@include mq(tablet) {
					position: relative;
					left: 0;
				}

				@include mq(desktop) {
					left: 50%;
					transform: translate(-50%);
					width: auto;
					height: 100%;
				}
			}
		}

		&--overlay {

			.oo-img {
				@include mq(tablet) {
					transform: none;
				}
			}
		}
	}

	> .swiper > .swiper-wrapper > .swiper-slide .oo-slide-content-wrapper--overlay {

		@include mq(desktop) {
			left: auto;
			right: 50%;
			transform: translate(50%, 0);
		}

		> .oo-slide-head {

			@include mq(tablet) {
				left: auto;
				right: 30px;
			}

			@include mq(desktop) {
				min-width: calc(700px - 20%);
				max-width: calc(700px - 20%);
			}

			@include mq(desktop-l) {
				margin-left: auto;
				margin-right: auto;
				padding-right: 0;
				min-width: calc(800px - 20%);
				max-width: calc(800px - 20%);
			}
		}

		> .oo-box {
			@include mq(desktop) {
				padding-right: 20px;
			}
		}
	}

	.oo-slide-content-wrapper--overlay + .oo-badge {

		@include mq(tablet) {
			left: auto;
			right: 30px;
		}

		@include mq(desktop) {
			top: 155px;
			right: calc(50% - 415px);
			transform: translate(50%, 0);
		}

		@include mq(desktop-l) {
			right: calc(50% - 480px);
		}
	}
}