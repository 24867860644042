/* a-basic-top */
.a-basic-top {
	background: $color-primary-2;
	position: fixed;
	right: 15px;
	bottom: 110px;
	width: 48px;
	height: 48px;
	cursor: pointer;
	outline: none;
	border: none;
	text-align: center;
	z-index: 1500;
	display: none;
	transition: opacity 1s;
	box-shadow: 0 0 7px 2px $color-secondary-4;
	border-radius: 50%;

	@include mq(tablet) {
		bottom: 240px;
	}

	@include mq(print) {
		display: none !important;
	}

	&.is-active {
		display: block;
	}

	&:hover {

		> span {
			&.mm-def {
				display: none;
			}

			&.mm-hover {
				display: block;
			}
		}
	}

	&:focus {
		outline: none;

		.is-keyboard-focus & {
			outline: none;
			border-radius: 50%;
			box-shadow: 0 0 0 2px $color-primary-3, 0 0 0 5px $color-primary-2;
		}
	}

	> span {
		&.mm-def {
			display: flex;
			justify-content: center;

			> img {
				transform: rotate(90deg);
				width: 22px;
				height: 22px;
				margin-top: -2px;
			}
		}

		&.mm-hover {
			@include milo-slab-bold(11px);
			display: none;
		}
	}
}