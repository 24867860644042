/* a-basic-input-select--colorpick  */
.a-basic-input-select--colorpick {

	// only for new styling
	.l-form-general & {
		border: 1px solid $color-formfield-border-standard;
		border-radius: 4px;

		&:hover {
			border: 1px solid $color-formfield-border-hover;
		}
	}

	> select {
		display: none;
	}

	> .aa-btn-color-selection {
		@include milo-regular($font-size-16);
		display: block;
		width: 100%;
		height: 40px;
		position: relative;
		padding: 9px 0 0 15px;
		background: $color-secondary-8;
		border-bottom: 1px solid $color-secondary-9;
		text-decoration: none;
		cursor: pointer;

		.h-roboto & {
			font-family: 'Roboto', sans-serif;
		}

		.l-form-general & {
			height: 53px;
			border-radius: 4px;
			padding-top: 16px;
			margin-bottom: -1.8px;
			background: $color-primary-2;
			border-bottom: none;

			@include if-parent('.a-basic-input-select--colorpick.is-floating') {
				padding-top: 25px;
				transition: top 0.5s;
			}

			&:after {
				top: 5px;
			}

			&.is-opened {
				border-radius: 4px 4px 0 0;
			}

			&.has-color {
				padding-left: 40px;
			}

			& ~ .aa-col-label {
				@include if-parent('.a-basic-input-select--colorpick.is-floating') {
					top: 3px;
				}


				> .aa-label {
					@include if-parent('.a-basic-input-select--colorpick.is-floating') {
						color: $color-secondary-20;
						@include milo-regular($font-size-14);
					}

					.l-form-general .ll-row.is-check.is-error & {
						color: $color-action-alert;
					}
				}
			}
		}

		.l-form-general .is-floating & {
			padding-top: 25px;
		}

		&.is-opened {
			&:after {
				@include main-icon-arrow-drop-up;
				background-repeat: no-repeat;
			}
		}

		&.has-color {
			padding-left: 35px;
		}

		&:after {
			content: " ";
			display: block;
			height: 28px;
			width: 28px;
			position: absolute;
			top: 0;
			right: 1px;
			@include main-icon-arrow-drop-down;
			background-repeat: no-repeat;
		}

		> .aa-colorbox {
			display: block;
			height: 20px;
			width: 20px;
			position: absolute;
			top: 10px;
			left: 10px;
			border: 1px solid $color-secondary-5;

			.l-form-general & {
				top: 17px;
				left: 14px;

				@include if-parent('.a-basic-input-select--colorpick.is-floating') {
					top: 25px;
				}
			}
		}
	}

	> .aa-color-options {

		display: none;
		width: 100%;
		max-width: 100%;
		height: 0;
		max-height: 210px;
		padding: 0;
		position: absolute;
		visibility: hidden;
		overflow: hidden;
		background: $color-secondary-7;
		border: 1px solid $color-secondary-5;
		border-top: 0;
		z-index: 51;

		.l-form-general & {
			right: -1px;
			min-width: 305px;
			border: 1px solid black;
			border-radius: 0 0 4px 4px;
			background: $color-primary-2;
			border-top: 1px solid $color-secondary-5;

			@include mq(tablet) {
				min-width: 400px;
			}

			&:not(.jspScrollable) {
				border-top: none;

				ul.aa-color-options-list {
					> li {
						&:first-child {
							border-top: 1px solid $color-secondary-5;
							margin: 0 10px;
							padding-left: 25px;

							&.no-color {
								padding-left: 0;
							}

							> .aa-colorbox {
								left: 0;
							}

							&.active {
								margin: 0;
								padding-left: 35px;

								&.no-color {
									padding-left: 10px;
								}

								> .aa-colorbox {
									left: 10px;
								}
							}
						}
					}
				}
			}
		}

		ul {
			li {

				&:hover,
				.is-keyboard-focus &:focus {
					outline: none;
					background-color: $color-secondary-8;
				}
			}
		}

		&.is-opened {
			visibility: visible;
			height: auto;
		}

		// custom scrollbar styles
		&.jspScrollable {

			&:focus {
				outline: none;
			}

			> .jspContainer {
				.l-form-general & {
					width: 100% !important;
				}

				//scrollable content wrapper
				> .jspPane {
					.l-form-general & {
						width: 100% !important;
					}
				}

				//scrollbar wrapper
				> .jspVerticalBar {
					width: 5px;
					right: 10px;

					.l-form-general & {
						background: transparent;
						padding: 10px 0;
						right: 0;
					}

					> .jspArrow {
						background: $color-secondary-7;
						height: 15px;
						cursor: default;

						.l-form-general & {
							height: 0;
						}
					}

					> .jspTrack {
						background: $color-secondary-5;

						.l-form-general & {
							height: 100% !important;
						}

						> .jspDrag {
							background: $color-secondary-2;
							border-bottom: 1px solid $color-primary-2;
						}
					}
				}

				> .jspHorizontalBar {
					display: none;
				}
			}
		}

		ul.aa-color-options-list {

			padding: 0 17px 0 0;
			margin: 0;

			.l-form-general & {
				padding: 0;
			}

			> li {
				position: relative;
				height: 40px;
				padding: 11px 0 0 35px;
				border-top: 1px solid $color-secondary-5;
				@include milo-regular($font-size-16);
				cursor: pointer;

				.h-roboto & {
					font-family: 'Roboto', sans-serif;
				}

				.l-form-general & {
					border-top: none;
					padding: 11px 0 0 40px;

					&.active {
						background: $color-primary-1;
						font-weight: bold;
					}

					&.no-color {
						padding-left: 14px;
					}

					> .aa-colorbox {
						left: 14px;
					}
				}

				&:first-child {
					border-top: 0;
				}

				&.active {
					background: $color-secondary-5;
				}

				&.no-color {
					padding-left: 10px;
				}

				> .aa-colorbox {
					display: block;
					height: 20px;
					width: 20px;
					position: absolute;
					top: 10px;
					left: 10px;
					border: 1px solid $color-secondary-5;
				}
			}
		}
	}


	&.a-basic-input-select--my-adac-content {
		margin-bottom: 6px;

		> .aa-btn-color-selection {
			padding: 10px;
			height: 40px;
			outline: none;
			background-color: $color-secondary-8;
			@include font-size(16px, 18px);

			&.has-color {
				padding-left: 40px;
			}

			&:after {
				@include main-icon-arrow-right-grey;
				top: 50%;
				right: 15px;
				background-repeat: no-repeat;
				transform: rotate(90deg);
				translate: 0 -50%;
			}

			&.is-opened {
				&:after {
					transform: rotate(-90deg);
				}
			}

			> .aa-colorbox {
				top: 8px;
				height: 25px;
				width: 25px;
				border-radius: 50%;
				border: 0;

				&--is-border {
					border: 1px solid $color-secondary-15;
				}
			}
		}

		> .aa-color-options {

			ul.aa-color-options-list {

				> li {
					position: relative;
					height: 40px;
					padding: 10px 0 0 40px;
					border-top: 1px solid $color-secondary-15;
					@include font-size(16px, 18px);

					&:first-child {
						border-top: 0;
					}

					&.active {
						background: $color-secondary-5;
					}

					&.no-color {
						padding-left: 10px;
					}

					> .aa-colorbox {
						top: 8px;
						height: 25px;
						width: 25px;
						border-radius: 50%;
						border: 0;

						&--is-border {
							border: 1px solid $color-secondary-15;
						}
					}


				}
			}
		}
	}

	> .aa-col-label {
		.l-form-general & {
			position: absolute;
			z-index: 1;
			top: 16px;
			left: 15px;
			transition: top 0.5s;
		}

		> .aa-label {
			.l-form-general & {
				@include milo-regular($font-size-16);
				font-weight: normal;
				color: initial
			}
		}
	}
}