.a-basic-calculator-slider {
	overflow: hidden;

	//expexption m-fdl-calculator
	.m-fdl-calculator & {
		margin: 0 20px;
	}

	.m-fdl-calculator--clusterbox & {
		margin: 0 0;
	}

	input {
		width: 100%;
		padding: 4px 15px;
		margin: 5px 0 0 0;
		border-bottom: 1px solid $color-secondary-3;

		//expexption m-fdl-calculator
		.m-fdl-calculator & {
			padding-top: 6px;
			padding-bottom: 6px;
			margin-top: 0px;
			margin-bottom: 4px;
		}

		.m-fdl-calculator--clusterbox & {
			height: 36px;
			margin: 5px 0 0 0;
		}
	}

	.aa-slider {
		margin: 10px 0 15px 0;
		border: none;
		background: $color-secondary-9;

		@include mq(tablet) {
			margin-bottom: 10px;
		}
	}

	.aa-io {
		position: relative;
		color: #333;
		width: 100%;
	}

	.aa-result {
		background: $color-secondary-7;

		.m-fdl-calculator--clusterbox & {
			background: $color-primary-2;
		}

		.m-basic-teaser-home & {
			background-color: $color-primary-2;
		}
	}

	/* Functional styling;
	 * These styles are required for noUiSlider to function.
	 * You don't need to change these rules to apply your design.
	 */
	.noUi-target,
	.noUi-target * {
		touch-action: none;
		box-sizing: border-box;
	}

	.noUi-target {
		position: relative;
		direction: ltr;
	}

	.noUi-stacking .noUi-handle {
		/* This class is applied to the lower origin when
	   its values is > 50%. */
		z-index: 10;
	}

	.noUi-state-tap .noUi-origin {
		transition: left 0.3s, top 0.3s;
	}

	.noUi-state-drag * {
		cursor: inherit !important;
	}

	/* Painting and performance;
	 * Browsers can paint handles in their own layer.
	 */
	.noUi-base {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 1;
		/* Fix 401 */
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	/* Slider size and handle placement;
	 */
	.noUi-horizontal {
		height: 18px;

		//expexption m-fdl-calculator
		.m-fdl-calculator & {
			height: 15px;
			margin-bottom: 16px;
		}

	}

	/* Styling;
	 */
	.noUi-background {
		padding: 0 0 0 29px;

		@include mq(print) {
			background: none;
			box-shadow: inset 0 0 0 1000px $color-secondary-2;
		}
	}

	.noUi-connect {
		background: $color-secondary-11;
		box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
		transition: background 450ms;
	}

	.noUi-origin {
		position: absolute;
		right: 0;
		top: 0;
		left: 0;
		bottom: 0;
		height: 14px !important;
		background: $color-secondary-2;
		max-width: calc(100% - 30px);

		@include mq(print) {
			background: none;
			box-shadow: inset 0 0 0 1000px $color-secondary-4;
		}
	}

	.noUi-target.noUi-connect {
		box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45), 0 3px 6px -5px #BBB;
	}

	/* Handles and cursors;
	 */
	.noUi-draggable {
		cursor: w-resize;
	}

	.noUi-vertical .noUi-draggable {
		cursor: n-resize;
	}

	.noUi-horizontal .noUi-handle {
		top: -15px;
		z-index: 1;
		width: 30px;
		height: 30px;
		background: $color-primary-1;
		transform: translate(13px, 6px);
		border: none;
		box-shadow: none;
		cursor: default;

		&:before,
		&:after {
			background: $color-primary-3;
			top: 10px;
			height: 8px;
		}

		&:before {
			left: 12px;
		}

		@include mq(print) {
			background: none;
			box-shadow: inset 0 0 0 1000px $color-primary-1;
		}
	}

	/* Disabled state;
	 */
	[disabled].noUi-connect,
	[disabled] .noUi-connect {
		background: #B8B8B8;
	}

	[disabled].noUi-origin,
	[disabled] .noUi-handle {
		cursor: not-allowed;
	}
}