/* l-main-content */
.l-main-content {
	position: relative;

	padding: 0 20px 1px 20px;
	background: $color-primary-2;

	@include mq(tablet) {
		padding-top: 20px;
		padding-right: 0;
		padding-left: 0;
		min-height: 500px;
	}

	@include mq(desktop) {
		padding-top: 0;
		padding-right: $l-main-content-padding-side-desktop;
		padding-left: $l-main-content-padding-side-desktop;
	}

	@include mq(desktop-l) {
		padding-right: 0;
		padding-left: 0;
	}

	@include mq(print) {
		padding-top: 39px;
		padding-left: 0;
		padding-right: 0;
	}

	.l-main-content {
		min-height: auto;
		padding-left: 0;
		padding-right: 0;
	}

	// exception if l-main-content is sibling to itself
	& ~ .l-main-content {
		padding-top: 15px;

		@include mq(tablet) {
			min-height: inherit;
		}
	}

	& + &--searchresult {
		min-height: 0;
		margin: calc(18% + 10px) 0 0 0;

		// exception nav
		.l-outer--nav & {
			margin: calc(25% + 10px) 0 0 0;
		}

		@include mq(tablet) {
			.l-outer--nav & {
				margin: calc(18% + 10px) 0 0 0;
			}
		}

		@include mq(desktop) {
			.l-outer--nav & {
				margin: calc(15% + 8.5px) 0 0 0;
			}
		}

		@include mq(desktop-xl) {
			.l-outer--nav & {
				margin: calc(18% + 10px) 0 0 0;
			}
		}

		.ll-main-triangle {
			left: 0;
			width: 100%;
			position: relative;
			top: -64px;

			@include mq(tablet) {
				top: -84px;
			}

			@include mq(desktop) {
				top: -59px;
			}

			@include mq(desktop-l) {
				top: -65px;
			}

			@include mq(desktop-xl) {
				top: -45px;
			}

			> div {
				@include absolute($left: 0, $bottom: 0);
				width: 100%;
				height: 0;
				padding-left: 50%;
				padding-top: 18%;
				overflow: hidden;

				.l-outer--nav & {
					padding-top: 25%;

					@include mq(tablet) {
						padding-top: 18%;
					}
				}

				> div {
					width: 0;
					height: 0;
					margin-left: -1400px;
					margin-top: -500px;
					border-left: 1400px solid transparent;
					border-right: 1400px solid transparent;
					border-top: 500px solid $color-secondary-10;

				}
			}
		}
	}

	&--cluster {
		margin: 0 10px;
		padding: 15px 0 0 0;
		background: 0;
		display: flex;
		flex-direction: column;
		min-height: 0;

		@include mq(tablet) {
			margin: 0;
			padding: 0;
			flex-flow: row wrap;
		}

		@include mq(print) {
			margin-left: 0;
			margin-right: 0;
		}

		.o-basic-banderole {
			padding-top: 0;
			padding-bottom: 20px;

			.swiper {
				margin: 2px -10px 0 0;
				//destroy every banderole on a cluster page
				background: $color-secondary-8;

				@include mq(tablet) {
					margin-right: 0;
				}

				> .oo-headline--inner {
					background: none;
				}
			}

			&--clusterbox {
				.swiper {
					margin: 2px 0 0;
					padding: 0 0 10px 0;
				}
			}

			.m-basic-teaser-across {
				background: none;
				padding-top: 0;
				padding-bottom: 0;

				&.js-init {
					padding: 0;
				}
			}
		}
	}

	&--home {
		background: transparent;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin: 0;
		padding: 0 10px;
	}

	&--hometeaser {
		background: transparent;
		min-height: 340px;
		padding: 0;
	}

	&--breadcrumb {
		min-height: auto;
		padding-top: 0;
	}

	// exception for distance to basicformfootersticky
	&--stickyfooter {
		padding-bottom: 100px;
		position: static;

		@include mq(tablet) {
			padding-bottom: 50px;
		}
	}

	// exception for distance to ves rs sticky
	&--upsidedown {
		padding-bottom: 100px;
	}

	// sitecore fix
	> .scWebEditInput {
		display: block;
	}

	// exception m-layout-main-nav-left as prev sibling
	.m-layout-main-nav-left ~ & {

		padding-top: 50px;

		@include mq(tablet) {
			padding-top: 80px;
		}

		@include mq(desktop) {
			padding-top: 20px;
		}

		// exception for .m-basic-header
		.m-basic-header ~ .l-main > .ll-main-center > & {
			padding-top: 70px;

			@include mq(tablet) {
				padding-top: 80px;
			}

			@include mq(desktop) {
				padding-top: 0px;
			}
		}
	}

	strong ~ .l-form {
		@include mq(tablet) {
			padding-left: 10px;
		}
	}

	// exception for l-outer--nav
	.l-outer--nav & {

		@include mq(desktop) {
			float: right;
			width: calc(82% - 35px);
			max-width: $layout-header-center;
			padding-right: $l-main-content-padding-side-desktop;
		}

		@include mq(desktop-xl) {
			float: none;
			width: auto;
			padding-right: 0;
		}
	}

	// exception for myAdac
	.l-outer--my-adac &,
	.l-outer--my-adac-content & {
		background: none;
		padding: 0;

		@include mq(desktop-l) {
			padding: 10px 0 0 0;
		}

		> .m-bank-data-tank {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			position: relative;
			z-index: 1;
			padding: 30px 0 10px;
			align-items: center;
			margin-bottom: 20px;

			&:before {
				top: 0;
				content: '';
				z-index: -1;
				width: 100vw;
				height: 100%;
				left: calc(50% - 50vw); // 50% - "halbes width"
				position: absolute;
				background-color: $color-secondary-8;
			}

			@include mq(tablet) {
				padding: 30px 0;
			}

			> .mm-tank-col {
				&--1 {
					width: 100%;
					margin-bottom: 15px;

					@include mq(tablet) {
						width: 30%;
						margin-bottom: 0;
					}

					> .m-basic-image {
						max-width: 357px;
						margin: 0 auto;
						width: auto;

						@include mq(tablet) {
							max-width: 100%;
						}
					}
				}

				&--2 {
					width: 100%;

					@include mq(tablet) {
						width: 65%;
					}
				}
			}
		}
	}

	// exception for myAdac contenten sites
	// outer width: 1280px - content width: 960px
	.l-outer--my-adac-content & {
		max-width: $layout-my-adac-content-header-center-inner;
		background: none;
		margin: 0 auto;
		padding: 15px 0 0 0;

		@include mq(tablet) {
			padding: 30px 0 0 0;
		}
	}

	.l-outer--my-adac-dashboard-content & {
		padding-top: 15px;

		@include mq(tablet) {
			padding-top: 30px;
		}
	}

	// exception for full width container with gray background color
	.ll-main--gray & {
		max-width: $layout-main-center;
		margin: 0 auto;
		padding: 20px;
		background-color: $color-secondary-8;
		min-height: auto;

		@include mq(tablet) {
			padding: 40px;
		}

		@include mq(desktop-l) {
			padding: 40px 0;
		}
	}

	.ll-main-full + .ll-main-center & {
		min-height: 0;
		padding-bottom: 15px;
		padding-top: 60px;

		@include mq(tablet) {
			padding-bottom: 20px;
		}

		@include mq(desktop) {
			padding-bottom: 30px;
		}
	}

	> .ll-main-content-clusterbox {
		margin: 0 10px;
		padding: 15px 0 0 0;
		background: 0;
		display: flex;
		flex-direction: column;
		min-height: 0;

		@include mq(tablet) {
			margin: 0;
			padding: 0;
			flex-flow: row wrap;
		}

		@include mq(print) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.ll-dashboard {
		flex-basis: 100%;

		@include mq(tablet) {
			display: flex;
			flex-wrap: wrap;
			margin-left: -10px;
		}

		&-left {
			width: 100%;

			@include mq(tablet) {
				display: flex;
				flex-wrap: wrap;
			}

			@include mq(desktop) {
				display: block;
				width: calc(100% / 3);
			}
		}

		&-right {
			width: 100%;

			@include mq(tablet) {
				display: flex;
				flex-wrap: wrap;
			}

			@include mq(desktop) {
				width: calc(100% / 3 * 2);
			}
		}

		&-double {
			width: 100%;

			@include mq(tablet) {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}

			> .m-basic-teaser-dashboard {
				border: none;
			}
		}
	}

	> .ll-rating {
		display: flex;
		flex-direction: column;
		margin-bottom: 30px;

		@include mq(desktop) {
			display: flex;
			justify-content: space-between;
			flex-direction: row;

			.ll-main-center .m-layout-main-nav-left ~ & {
				padding-top: 30px;
			}
		}

		> .ll-rating-left {
			order: 1;

			@include mq(tablet) {
				display: block;
			}

			@include mq(desktop) {
				order: 2;
				width: calc(33% - 5px);
			}
		}

		> .ll-rating-right {
			order: 2;

			@include mq(tablet) {
				display: block;
			}

			@include mq(desktop) {
				order: 1;
				width: calc(66%);
			}
		}
	}

	.ll-main-content-border {
		padding: 20px;
		border: 1px solid $color-secondary-4;
		border-radius: 10px;

		@include mq(tablet) {
			padding: 40px;
		}

		@include if-parent('.l-main-content.h-print-only, .l-main-content.h-print-showcase') {
			max-width: 550px;
		}
	}

	// js switch boxes
	.ll-main-content-switch {

		&--aside {
			position: relative;
			overflow: hidden;
			margin: 0 0 20px 0;

			&.is-aside {

				&:before {
					content: none;
					position: absolute;
					top: 0;
					left: 0;
					width: 10px;
					height: 100%;
					z-index: 10;
					box-shadow: 0 0 10px #333;

					@include mq(tablet) {
						width: $l-main-content-padding-side-tablet;
					}

					@include mq(desktop) {
						width: $l-main-content-padding-side-desktop;
					}
				}
			}

			&.is-aside-last {
				margin-right: 0;

				&:after {
					content: none;
				}
			}

			&:after {
				content: none;
				position: absolute;
				top: 0;
				right: 0;
				width: $l-main-content-padding-side;
				height: 100%;
				z-index: 10;
				box-shadow: 0 0 10px #333;

				@include mq(tablet) {
					width: $l-main-content-padding-side-tablet;
				}

				@include mq(desktop) {
					width: $l-main-content-padding-side-desktop;
				}
			}

			.ll-main-content-switchwrap {
				position: relative;
				left: 0;
				top: 0;
				width: 200%;
				height: 100%;
			}

			.ll-main-content-switchbox {
				position: relative;
				width: 50%;
				float: left;
				overflow: hidden;

				&:first-child {
					padding-left: 0;
				}

				&.ll-main-content-switchbox--active {
					height: auto;
				}

			}
		}

		.ll-main-content-switchbox {
			&--hidden {
				@extend %h-visuallyhidden;
				height: auto;
			}
		}
	}

	// exception for highlight
	.l-main-inner-content {
		&--highlight {
			margin-bottom: 40px;
			position: relative;
			padding: 15px 20px 5px;
			background: $color-primary-1;
			margin-left: -20px;
			margin-right: -20px;
			margin-top: -15px;

			@include mq(tablet) {
				margin-left: 0;
				margin-right: 0;
				padding: 15px 0 5px;
			}

			@include mq(desktop) {
				padding: 55px 0 30px;
			}

			:before {
				background: $color-primary-1;
				content: '';
				position: absolute;
				width: 100vw;
				height: 100%;
				top: 0;
				transform: translate(-50%);
				left: 50%;
				z-index: -1;
			}
		}
	}

	.ll-header-wrapper {
		@include mq(tablet) {
			display: flex;
			align-items: center;
		}

		.m-basic-text {
			@include mq(tablet) {
				margin-bottom: 0;
				margin-right: 5px;
			}
		}

		.m-basic-rating {
			@include mq(tablet) {
				margin-bottom: 0;
			}
		}
	}

	> .ll-main-triangle-bottom {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		width: 200%;
		height: 65px;
		margin-bottom: 60px;

		&.h-space-s {
			margin-top: -15px;

		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			height: 0;
			width: 100vw;
			border-top: 65px solid $color-secondary-8;
			border-left: 384px solid transparent;
			border-right: 384px solid transparent;

			@include mq(tablet) {
				width: 100%;
				border-left: 898px solid transparent;
				border-right: 898px solid transparent;
			}
		}

	}

	.ll-main-shadowbox {
		width: 100%;
		height: auto;
		border-radius: 15px;
		box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.20);
		padding: 12px 20px 30px;

		@include mq(tablet) {
			padding: 23px 83px;
		}
	}
}