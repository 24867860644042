/* m-basic-table--simple */
.m-basic-table--simple {
	width: 100%;

	tr {

		background: $color-primary-2;

		&:nth-child(odd) {
			background: $color-primary-2;
		}

		&:nth-child(even) {
			background: $color-primary-2;
		}

		td {
			border-right: 0;
			// was right
			text-align: left;
			padding: 5px 0px;
			padding-right: 30px;


			@include mq(tablet) {
				padding-left: 10px;

			}

			&:first-child {
				border-left: 0;
				text-align: left;
				width: 50%;
			}

			&:last-child {
				position: relative;
				padding-right: 10px;

				@include mq(tablet) {
					padding-left: 0;
				}
			}
		}
	}
}

// Exception Lightbox
.l-lightbox .m-basic-table--simple {
	padding-right: 30px;

	@include mq(tablet) {
		padding-right: 0px;
	}
}