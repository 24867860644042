/* m-vehicle-test-criterion */
.m-vehicle-test-criterion {
	position: relative;
	margin-bottom: 50px;

	> .m-nav {
		margin: 0 auto;
		padding: 0;
		min-height: 305px;
		width: 100%;
		max-width: 100%;
		position: relative;

		@include mq(tablet) {
			max-width: 768px;
		}

		@include mq(desktop) {
			margin: 0;
			width: 150px;
		}

		> li {
			background: $color-table-compare-3;
			height: auto;
			max-width: 100%;

			@include mq(tablet) {
				max-width: 768px;
			}

			@include mq(desktop) {
				width: 150px;
			}

			&:first-child {
				background: transparent;

				@include mq(desktop) {
					background: $color-secondary-3;
				}

				> .a-basic-btn {
					background: $color-secondary-3;
					box-shadow: inset 0 0 0 1000px $color-secondary-3;
					@include milo-bold($font-size-15);

					> .m-triangle {

						@include mq(desktop) {
							border-color: transparent $color-secondary-3 transparent transparent;
							border-top-width: 15px;
							border-right-width: 30.5px;
							border-bottom-width: 15px;
							border-left-width: 0;
						}
					}
				}

				> .m-tabcontent {

					.m-tabcontent-inside {
						background: transparent;

						@include mq(desktop) {
							background: $color-secondary-3;
							box-shadow: inset 0 0 0 1000px $color-secondary-3;
						}

						> .m-decision {
							background: $color-secondary-3;
							box-shadow: inset 0 0 0 1000px $color-secondary-3;

							> .m-basic-text {

								> .mm-content {

									> p {
										border-top: 1px solid $color-secondary-1;

										&:last-child {
											border-bottom: 1px solid $color-secondary-1;
										}
									}
								}
							}
						}
					}
				}
			}

			&:nth-child(2) {
				background: transparent;

				@include mq(desktop) {
					background: $color-secondary-9;
					box-shadow: inset 0 0 0 1000px $color-secondary-9;
				}

				> .a-basic-btn {
					background: $color-secondary-9;
					box-shadow: inset 0 0 0 1000px $color-secondary-9;
					@include milo-bold($font-size-15);

					> .m-triangle {

						@include mq(desktop) {
							border-color: transparent $color-secondary-9 transparent transparent;
							border-top-width: 60px;
							border-right-width: 30.5px;
							border-bottom-width: 60px;
							border-left-width: 0;
						}
					}
				}

				> .m-tabcontent {

					> .m-tabcontent-inside {
						background: transparent;

						@include mq(desktop) {
							background: $color-secondary-9;
							box-shadow: inset 0 0 0 1000px $color-secondary-9;
						}

						> .m-decision {
							background: $color-secondary-9;
							box-shadow: inset 0 0 0 1000px $color-secondary-9;

							> .m-basic-text {

								> .mm-content {

									> p {
										border-top: 1px solid $color-secondary-3;

										&:last-child {
											border-bottom: 1px solid $color-secondary-3;
										}
									}
								}
							}
						}
					}
				}
			}

			&:last-child {
				background: transparent;

				@include mq(desktop) {
					background: $color-secondary-10;
					box-shadow: inset 0 0 0 1000px $color-secondary-10;
				}

				> .a-basic-btn {
					background: $color-secondary-10;
					box-shadow: inset 0 0 0 1000px $color-secondary-10;

					> .m-triangle {

						@include mq(desktop) {
							border-color: transparent $color-secondary-10 transparent transparent;
							border-top-width: 75px;
							border-right-width: 30.5px;
							border-bottom-width: 75px;
							border-left-width: 0;
						}
					}
				}

				> .m-tabcontent {

					> .m-tabcontent-inside {
						background: transparent;

						@include mq(desktop) {
							background: $color-secondary-10;
						}

						> .m-decision {
							background: $color-secondary-10;
							box-shadow: inset 0 0 0 1000px $color-secondary-10;

							> .m-basic-text {

								> .mm-content {

									> p {
										border-top: 1px solid $color-secondary-9;

										&:last-child {
											border-bottom: 1px solid $color-secondary-9;
										}
									}
								}
							}
						}
					}
				}
			}

			&.is-active {

				.a-basic-btn {
					border-bottom: 1px solid $color-primary-2;
					min-height: 80px;

					@include mq(desktop) {
						border-bottom: none;
						margin-bottom: 0;
						min-height: 0;
					}

					&:before,
					&:after {
						display: block;

						@include mq(desktop) {
							display: none;
						}
					}

					> .m-triangle {
						display: none;

						@include mq(desktop) {
							display: block;
						}
					}
				}
			}

			> .a-basic-btn {
				background: none;
				outline: none;
				border: none;
				text-decoration: underline;
				padding: 0;
				font-weight: bold;
				font-family: arial, sans-serif;
				@include font-size($font-size-14);
				text-align: center;
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				max-width: 100%;
				position: relative;
				box-sizing: border-box;

				@include mq(tablet) {
					max-width: 100%;
				}

				&:before,
				&:after {
					height: 50px;
					bottom: 0;
					width: 50%;
					z-index: 1;
					content: "";
					position: absolute;
					pointer-events: none;
					background: linear-gradient(to left top, white 50%, transparent 50%);
					display: none;
				}

				&:after {
					right: 0;
				}

				&:before {
					left: 0;
					transform: scale(-1, 1);
				}

				> .m-triangle {
					width: 100%;
					height: 100%;
					overflow: hidden;
					display: none;

					&:after {
						content: "";
						display: block;
						width: 0;
						height: 0;
						margin: 0 auto;
					}

					@include mq(desktop) {
						width: 0;
						height: 0;
						border-style: solid;
						border-color: transparent $color-table-compare-3 transparent transparent;
						@include absolute($top: 0, $left: 150px);

						&:after {
							margin: 0;
							border: none;
						}
					}
				}
			}

			> .m-tabcontent {

				@include mq(desktop) {
					border-top: 1px solid $color-primary-2;
				}

				> .m-tabcontent-inside {
					height: 100%;
					width: 100%;

					@include mq(desktop) {
						@include absolute($left: 181px, $top: 0);
						width: 385px;
						max-width: 385px;
						max-height: 305px;
					}

					> .m-decision {
						padding: 20px;

						> h3 {
							margin-bottom: 5px;
						}

						> .m-basic-text {

							> .mm-content {

								> p {
									padding: 10px 0;
									margin: 0;
									position: relative;

									> span {
										font-weight: bold;
										@include absolute($right: 0);
									}
								}
							}
						}
					}

					> .m-info {
						font-weight: bold;
						background: $color-primary-2;
						padding-top: 15px;

						@include mq(desktop) {
							padding-top: 0;
							width: 180px;
							@include absolute($left: 410px, $top: 0);
						}

						> .m-basic-text {

							> .mm-content {
								margin-bottom: 30px;
							}
						}
					}
				}
			}
		}
	}
}