/* o-basic-banderole--award */
.o-basic-banderole--award {
	padding: 15px 0 30px 0;
	margin: 0;

	@include mq(tablet) {
		padding: 7px 0 30px 0;
	}

	.ll-main-center & {
		@include mq(desktop-xl) {
			margin-left: -20px;
			margin-right: -20px;
		}
	}

	.l-main-content--cluster & {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}

	.oo-headline {
		color: $color-primary-3;
		@include milo-slab-bold($font-size-24, 28px);
		margin-bottom: 20px !important;

		.h-roboto & {
			font-family: 'Roboto Bold', sans-serif;
		}

		@include mq(tablet) {
			@include font-size($font-size-28);
		}

		@include mq(desktop-xl) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	> .swiper {
		width: 100% !important;
		margin: 0 auto !important;
		padding: 0 !important;
		background: $color-primary-2;

		.ll-main-full & {
			background: transparent;
		}

		@include mq(desktop-xl) {
			padding-left: 20px !important;
			padding-right: 20px !important;
			padding-bottom: 1px !important;
		}

		.l-main-content--cluster & {
			background: $color-primary-2;
			padding-left: 0 !important;
			padding-right: 0 !important;
		}


		> .swiper-wrapper {

			> .swiper-slide {
				border: 1px solid $color-secondary-9;
				border-radius: 8px;
				padding: 40px 30px 20px 30px;
				text-align: center;
				height: auto;
				margin: 0;

				.ll-main-full & {
					background: $color-primary-2;
				}

				.is-keyboard-focus &:focus-visible {
					border: 2px solid $color-primary-3;
				}

				img {
					height: 120px;
					width: auto;
					max-height: 120px;
					max-width: 100%;
					margin-bottom: 15px;
				}

				.oo-award-text {
					font-size: 14px;
					padding: 0 25px;
				}
			}
		}

		.swiper-pagination {
			position: relative !important;
			margin-top: 15px;
		}
	}

	.mm-btn-box {
		padding: 0 20px;
		display: flex;
		justify-content: flex-end;
		margin-top: 25px;

		.l-main-content--cluster & {
			padding-left: 0;
			padding-right: 0;
		}
	}
}


.oo-basic-banderole--layer-img.js-img-big {

	// Exception when in lightbox
	.ll-lightbox-inner & {
		width: 100%;
		height: auto;
	}
}

.oo-basic-banderole--caption {
	text-align: right;
	height: 25px;
	overflow: hidden;

	.m-basic-text {
		padding-right: 0;
		padding-top: 7px;
		font-size: 78%;
	}
}