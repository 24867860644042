/* m-basic movie */
.m-basic-movie {
	$movie-color: $color-primary-1 !default;
	$movie-volume: 50px !default;
	$movie-timeline: 3px !default;
	$movie-timeline-scale: 2 !default;
	$movie-timeline-handle: 9px !default;
	$movie-timeline-handle-border: 2px !default;
	$movie-timeline-transition: all 0.25s ease !default;

	font-size: 0;
	cursor: pointer;
	outline: none;
	overflow: hidden;
	position: relative;
	height: 100%;
	-webkit-tap-highlight-color: transparent;

	// Exception gallery
	.m-basic-gallery > .mm-big & {
		height: auto;
	}

	.m-basic-gallery > .mm-big .has-youtube-video & {
		padding-bottom: 56.25%;
	}

	.m-basic-gallery .swiper--thumbs &:not(.has-cloudinary-video),
	.m-basic-gallery .swiper--prev &:not(.has-cloudinary-video) {
		display: flex; // center image 3d movies
		align-items: center; // center image 3d movies
	}

	// Exception m-basic-newsbox
	.m-basic-newsbox & {
		width: 100%;
		max-width: 100%;
		margin: 0;
		padding: 0 0 16px 0;
		z-index: 2;

		@include mq(tablet) {
			float: left;
			width: 290px;
			margin-top: 4px;
		}

		// Exception for newsbox inside of m-ves-tab
		// fix the heights to prevent document.height changes when content is lazyloaded
		.m-ves-tab & {

			// Movies are in 16:9 format ( = 1.777 : 1 ) and scale in mobile
			$mainContentPadding: 40px;
			$mobileHeight: calc((100vw - #{$mainContentPadding}) / 1.777);

			min-height: $mobileHeight;
			max-height: $mobileHeight;
			height: $mobileHeight;

			@include mq(tablet) {
				min-height: 162px;
				max-height: 162px;
				height: 162px;
			}
		}
	}

	&,
	&::before,
	&::after {
		box-sizing: border-box;
	}

	&.is-controls {
		cursor: default;

		&.is-draggable {
			// fallback
			cursor: move;
			cursor: grab;
		}

		&.is-dragging {
			cursor: grabbing;
		}

		&.is-poster {
			cursor: pointer;
		}

		&.is-error {
			// fallback
			cursor: default;
			cursor: no-drop;
		}
	}

	&.is-unsupported {
		// fallback
		cursor: default;
		cursor: no-drop;

		&::before {
			content: "";
			display: block;
			padding-top: (9 / 16 * 100%);
		}
	}

	// native fullscreen
	&:fullscreen {
		width: 100%;
		height: 100%;
	}

	// fallback fullscreen (iOS)
	&.is-fake-fullscreen {
		z-index: 99999;
		@include fixed(0, 0, 0, 0);
	}

	:focus,
	&:focus {
		outline: none;
	}

	> .mm-poster-container {
		display: block;
		position: relative;

		&:hover {
			&::before {
				border: 2px solid $color-primary-3;
			}
		}

		&::before {
			content: '';
			background-color: $color-primary-1;
			background-size: 100%;
			width: 80px;
			height: 80px;
			border-radius: 50px;
			cursor: pointer;
			border: 0;
			outline: 0;
			display: block;
			position: absolute;
			z-index: 10;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			.m-basic-gallery .mm-thumbnail-wrapper &,
			.m-basic-gallery .swiper--thumbs &,
			.m-basic-gallery .swiper--prev & {

				@include mq(desktop) {
					width: 50px;
					height: 50px;
				}
			}

			&:hover {
				background-color: $color-primary-1;
			}

			&.is-hover {
				background-color: $color-primary-1;
			}

			&:focus {
				outline: none;
			}

			&.is-fade-out {
				background-color: rgba(0, 0, 0, 0.7);
				transition: opacity 750ms ease-out;
			}

			&.is-z-index {
				z-index: 93;
			}
		}

		&::after {
			@include main-icon-360;
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 0;
			z-index: 20;
			display: block;

			.m-basic-gallery .mm-thumbnail-wrapper &,
			.m-basic-gallery .swiper--thumbs & {
				@include main-icon-360-30;
				width: 52px;
				height: 52px;
			}

			.m-basic-gallery .swiper--prev & {

				@include mq(desktop) {
					@include main-icon-360-30;
					width: 52px;
					height: 52px;
				}
			}
		}

		> .mm-poster-img {
			width: 100%;
			height: auto;
			filter: brightness(70%);
		}
	}

	> .mm-movie-container {

		&.is-init + .mm-poster-container {
			display: none;
		}
	}

	> .mm-youtube-container {
		position: relative;
		// Default for 1600x900 videos 16:9 ratio
		padding-bottom: 56.25%;
		padding-top: 0;
		height: 0;
		overflow: hidden;
		background: $color-primary-3;

		> iframe {
			position: absolute;
			border: 0;
			top: 0;
			left: 0;
			z-index: 95;
			width: 100%;
			height: 100%;
		}

		> picture {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 97;
			width: 100%;
			height: 100%;
			cursor: pointer;
			filter: brightness(70%);

			.m-basic-gallery:not(.is-enlarged) & {
				position: relative;
			}

			.swiper-slide.swiper-slide-thumb-active & {
				width: calc(100% - 8px);
				height: calc(100% - 8px);
				top: 4px;
				left: 4px;
			}

			&.is-fade-out {
				opacity: 0;
				transition: opacity 750ms ease-out;
			}

			&.is-z-index {
				z-index: 93;
			}

			> img {
				position: absolute;
				display: block;
				width: 100%;
				height: auto;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				.m-basic-gallery & {
					position: relative;
					transform: none;
					top: 0;
					left: 0;
				}
			}
		}

		> button {
			background-color: $color-primary-1;
			background-size: 100%;
			width: 80px;
			height: 80px;
			border-radius: 50px;
			cursor: pointer;
			border: 0;
			outline: 0;
			display: block;
			position: absolute;
			z-index: 99;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			@include mq('print') {
				display: none;
			}

			.m-basic-gallery .mm-thumbnail-wrapper &,
			.m-basic-gallery .swiper--thumbs &,
			.m-basic-gallery .swiper--prev & {
				@include mq(desktop) {
					width: 50px;
					height: 50px;
				}
			}

			&:hover {
				background-color: $color-primary-1;
			}

			&.is-hover {
				background-color: $color-primary-1;
			}

			&:focus {
				outline: none;
			}

			.is-keyboard-focus &:focus {
				border: 4px solid $color-primary-2 !important;
				outline: 4px solid $color-primary-3 !important;
			}

			&.is-fade-out {
				background-color: rgba(0, 0, 0, 0.7);
				transition: opacity 750ms ease-out;
			}

			&.is-z-index {
				z-index: 93;
			}

			@include if-parent('.mm-youtube-container:hover') {
				border: 2px solid $color-primary-3;
			}

			> span {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 0;
				display: block;
				@include main-icon-play-arrow-black-36;

				.m-basic-gallery .swiper--thumbs &,
				.m-basic-gallery .swiper--prev & {
					@include main-icon-play-arrow-black;
					width: 40px;
					height: 40px;
				}

				.m-basic-gallery--layer .swiper--prev & {

					@include mq(desktop) {
						@include main-icon-play-arrow-black;
						width: 40px;
						height: 40px;
					}
				}
			}
		}
	}

	// default image (cloudinary)
	> .mm-default {
		> img {
			max-width: 100%;
		}
	}

	> .mm-cloudinary-container {
		> .cld-video-player {
			> .vjs-poster {
				filter: brightness(70%);

				@include mq('print') {
					width: 100%;
					height: auto;
					position: relative;
				}
			}

			> .vjs-big-play-button {

				@include mq('print') {
					display: none;
				}

				&::before {
					content: '';
					padding-bottom: 0;
					background-color: $color-primary-1;
					background-size: 100%;
					width: 80px;
					height: 80px;
					border-radius: 50px;
					cursor: pointer;
					border: 0;
					outline: 0;
					display: block;
					position: absolute;
					z-index: 10;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);

					.m-basic-gallery .mm-thumbnail-wrapper &,
					.m-basic-gallery .swiper--thumbs &,
					.m-basic-gallery .swiper--prev & {

						@include mq(tablet) {
							width: 50px;
							height: 50px;
						}
					}

					&:hover {
						background-color: $color-primary-1;
					}

					&.is-hover {
						background-color: $color-primary-1;
					}

					&:focus {
						outline: none;
					}

					&.is-fade-out {
						background-color: rgba(0, 0, 0, 0.7);
						transition: opacity 750ms ease-out;
					}

					&.is-z-index {
						z-index: 93;
					}

					@include if-parent('.mm-cloudinary-container:hover') {
						border: 2px solid $color-primary-3;
					}
				}

				.is-keyboard-focus &:focus {
					&:before {
						border: 4px solid $color-primary-2 !important;
						outline: 4px solid $color-primary-3 !important;
					}
				}

				&::after {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					font-size: 0;
					z-index: 20;
					display: block;
					@include main-icon-play-arrow-black-36;
					content: '';

					.m-basic-gallery .mm-thumbnail-wrapper &,
					.m-basic-gallery .swiper--thumbs & {
						@include main-icon-play-arrow-black;
						width: 40px;
						height: 40px;
					}

					.m-basic-gallery .swiper--prev & {

						@include mq(desktop) {
							@include main-icon-play-arrow-black;
							width: 40px;
							height: 40px;
						}
					}
				}
			}
		}

		video {
			display: none;
		}

		&--loaded {
			video {
				display: block;
			}
		}
	}

	:not(.m-basic-gallery) & {

		> .mm-youtube-container {
			padding-bottom: 56.25%;
			width: 100%;

			> iframe {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
			}
		}
	}

	> .m-loading {
		z-index: 1;
		pointer-events: none;
		@include autoalpha(0);
		color: $movie-color;
		@include font-size($font-size-50);
		transition: all 0.5s ease;
		@include absolute(50%, 50%);
		transform: translate(-50%, -90%);

		@include mq(tablet) {
			@include font-size(100px);
			transform: translate(-50%, -70%);
		}

		@include if-parent('.m-basic-movie.is-loading') {
			@include autoalpha(1);
		}

		svg {
			width: 50px;
			height: 50px;
			vertical-align: top;

			@include mq(tablet) {
				width: 100px;
				height: 100px;
			}

			// animation
			animation: movie-loading 1s infinite steps(2);

			@keyframes movie-loading {
				0% {
					transform: rotate(0deg);
				}

				100% {
					transform: rotate(360deg);
				}
			}
		}
	}

	> .m-play {
		z-index: 1;
		color: white;
		cursor: pointer;
		@include autoalpha(0);
		@include font-size($font-size-50);
		transition: all 0.5s ease;
		@include absolute(50%, 50%);
		transform: translate(-50%, -50%);
		text-shadow: 0 0 15px transparentize(black, 0.5);

		@include mq(tablet) {
			@include absolute(50%, 50%);
			@include font-size($font-size-100);
			transform: translate(-50%, -50%);

			// Exception gallery
			.m-basic-gallery .swiper--prev & {
				display: none;
			}
		}

		@include if-parent('.m-basic-movie:hover') {
			text-shadow: none;
			color: $movie-color;
		}

		@include if-parent('.m-basic-movie.is-poster, .m-basic-movie.is-paused') {
			@include autoalpha(1);
		}

		// hide play button while loading or dragging 3d view
		@include if-parent('.m-basic-movie.is-loading, .m-basic-movie.is-dragging') {
			transition-delay: 1s;
			@include autoalpha(0);
		}

		@include if-parent('.m-basic-movie.is-loading') {
			transition-delay: 0s;
		}

		// hide immediately on error
		@include if-parent('.m-basic-movie.is-error') {
			display: none;
		}

		// move to top
		@include if-parent('.m-basic-movie.is-paused.is-controls:not(.is-poster)') {
			transform: translate(-50%, -85%);

			@include mq(tablet) {
				transform: translate(-50%, -50%);
			}
		}

		svg {
			fill: white;
			width: 50px;
			height: 50px;
			vertical-align: top;
			transition: all 0.25s ease;
			filter: drop-shadow(0 0 15px transparentize(black, 0.5));

			@include if-parent('.m-basic-movie:hover') {
				filter: none;
				fill: $movie-color;
			}

			@include mq(tablet) {
				width: 100px;
				height: 100px;
			}
		}

		> .fa-play {
			position: relative;

			&::before {
				display: none;

				@include if-parent('.m-basic-movie.is-loading') {
					display: block;
				}
			}
		}
	}

	> .mm-youtube-link-container {
		display: block;
		position: relative;
		overflow: hidden;

		&:hover {
			&::before {
				border: 2px solid $color-primary-3;
			}
		}

		&::before {
			background-color: $color-primary-1;
			background-size: 100%;
			width: 80px;
			height: 80px;
			border-radius: 50px;
			cursor: pointer;
			border: 0;
			outline: 0;
			display: block;
			position: absolute;
			z-index: 10;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			content: '';

			.m-basic-gallery .mm-thumbnail-wrapper &,
			.m-basic-gallery .swiper--thumbs &,
			.m-basic-gallery .swiper--prev & {

				@include mq(desktop) {
					width: 50px;
					height: 50px;
				}

				@include mq(print) {
					width: 50px;
					height: 50px;
				}
			}

			&:hover {
				background-color: $color-primary-1;
			}

			&.is-hover {
				background-color: $color-primary-1;
			}

			&:focus {
				outline: none;
			}

			&.is-fade-out {
				background-color: rgba(0, 0, 0, 0.7);
				transition: opacity 750ms ease-out;
			}

			&.is-z-index {
				z-index: 93;
			}
		}

		&::after {
			@include main-icon-play-arrow-black-36;
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 0;
			z-index: 20;
			display: block;

			.m-basic-gallery .mm-thumbnail-wrapper &,
			.m-basic-gallery .swiper--thumbs & {
				@include main-icon-play-arrow-black;
				width: 40px;
				height: 40px;
			}

			.m-basic-gallery .swiper--prev & {

				@include mq(desktop) {
					@include main-icon-play-arrow-black;
					width: 40px;
					height: 40px;
				}
			}
		}

		.is-keyboard-focus &:focus {
			&:before {
				border: 4px solid $color-primary-2;
				outline: 4px solid $color-primary-3;
			}
		}

		> img {
			width: 100%;
			filter: brightness(70%);

			&.mm-default {
				box-shadow: inset 1000px 1000px 1000px rgba(0, 0, 0, 0.3);
			}
		}
	}

	> .mm-link-description {
		background: $color-primary-2;
		margin-top: 7px;

		// exception gallery
		.m-basic-gallery & {
			padding: 4px 10px;
			background: $color-secondary-5;
			display: block;
			position: absolute;
			bottom: 0;
			text-align: left;
			z-index: 10;

			@include mq(tablet) {
				padding: 7px 10px;
			}
		}

		.m-basic-gallery .swiper--thumbs &,
		.m-basic-gallery .swiper--prev &,
		.m-basic-gallery--layer .mm-prev & {
			@include mq(tablet) {
				display: none;
			}
		}

		> p {
			@include font-size(12px);
			@include line-height(15px);
			margin: 0;

			@include mq(tablet) {
				@include font-size(16px);
				@include line-height(21px);
			}

			// exception gallery
			.m-basic-gallery & {
				@include line-height(14px);

				@include mq(tablet) {
					@include line-height(21px);
				}
			}
		}
	}

	> .m-spherical {
		opacity: 0;
		padding: 10px;
		pointer-events: none;
		color: $movie-color;
		transition: all 0.25s ease;
		@include absolute(70px, 10px);

		&:focus {
			@include if-parent('.m-basic-movie.is-keyboard-focus') {
				opacity: 1;
			}
		}

		// TODO: remove this once SVG
		> i {
			padding: 5px;
			overflow: hidden;
			background: white;
			border-radius: 50%;
			border: 2px solid $movie-color;

			&::before {
				font-size: xx-large;
			}
		}
	}

	> .m-controls {
		color: white;
		cursor: default;
		@include autoalpha(0);
		transition: all 0.5s ease;
		transform: translateY(100%);
		padding: 10px 20px 10px 20px;
		@include absolute(null, 0, 0, 0);
		background: linear-gradient(to top, #{transparentize(black, 0.5)} 0%, #{transparentize(black, 0.85)} 70%, transparent 100%);

		// Exception gallery
		.m-basic-gallery .swiper:not(.swiper--big) & {
			padding-left: 40px;

			@include mq(tablet) {
				padding-left: 50px;
				padding-right: 50px;
			}
		}

		// if controls are visible, show em
		@include if-parent('.m-basic-movie.is-controls') {
			@include if-parent('.m-basic-movie.is-paused') {
				@include autoalpha(1);
				transform: translateY(0);
			}

			@include mq(desktop) {
				@include autoalpha(1);
				transform: translateY(0);
			}
		}

		// hide controls while dragging 3d view or on error
		@include if-parent('.m-basic-movie.is-dragging, .m-basic-movie.is-error') {
			pointer-events: none;
			transition-delay: 1s;
			@include autoalpha(0);
			transform: translateY(100%);
		}

		> .m-timeline {
			padding: 10px;
			margin: 0 -10px;
			cursor: pointer;
			line-height: $movie-timeline-scale * $movie-timeline-handle + 2 * $movie-timeline-handle-border;

			> .m-time {
				font-size: small;
				display: table-cell;
				vertical-align: middle;
				text-shadow: 0 0 2px transparentize(black, 0.5);

				&--progress {
					padding-right: 10px;
				}

				&--total {
					padding-left: 10px;
				}

				+ .m-slider {
					width: 100%;
					display: table-cell;
				}
			}

			> .m-slider {
				overflow: hidden;
				position: relative;
				white-space: nowrap;
				vertical-align: middle;
				transition: $movie-timeline-transition;
				padding-left: ($movie-timeline-scale * $movie-timeline-handle + 2 * $movie-timeline-handle-border);

				@include if-parent('.m-basic-movie.is-timeline-dragging') {
					transition: none;

					// for time tooltip
					overflow: visible;
				}

				@include mq(desktop) {
					padding-left: ($movie-timeline-handle + 2 * $movie-timeline-handle-border);

					@include if-parent('.m-timeline:hover, .m-basic-movie.is-timeline-dragging, .m-basic-movie.is-keyboard-focus .m-timeline:focus') {
						padding-left: ($movie-timeline-scale * $movie-timeline-handle + 2 * $movie-timeline-handle-border);
					}
				}

				> .m-line {
					display: inline-block;
					vertical-align: middle;
					height: $movie-timeline;
					transition: $movie-timeline-transition;

					@include if-parent('.m-basic-movie.is-timeline-dragging') {
						transition: none;
					}

					&--progress {
						background: $movie-color;
						margin-left: -($movie-timeline-scale * $movie-timeline-handle + 2 * $movie-timeline-handle-border);

						@include mq(desktop) {
							margin-left: -($movie-timeline-handle + 2 * $movie-timeline-handle-border);

							// huge handle
							@include if-parent('.m-timeline:hover, .m-basic-movie.is-timeline-dragging, .m-basic-movie.is-keyboard-focus .m-timeline:focus') {
								margin-left: -($movie-timeline-scale * $movie-timeline-handle + 2 * $movie-timeline-handle-border);
							}
						}
					}

					&--remaining {
						overflow: hidden;
						position: relative;
						background: transparentize(white, 0.8);

						> .m-buffer {
							@include absolute(0, 0, 0);
							background: transparentize(white, 0.5);
							transition: $movie-timeline-transition;
							margin-left: -($movie-timeline-scale * $movie-timeline-handle + 2 * $movie-timeline-handle-border);
							padding-left: ($movie-timeline-scale * $movie-timeline-handle + 2 * $movie-timeline-handle-border);

							@include if-parent('.m-basic-movie.is-timeline-dragging') {
								transition: none;
							}

							@include mq(desktop) {
								margin-left: -($movie-timeline-handle + 2 * $movie-timeline-handle-border);
								padding-left: ($movie-timeline-handle + 2 * $movie-timeline-handle-border);

								// huge handle
								@include if-parent('.m-timeline:hover, .m-basic-movie.is-timeline-dragging, .m-basic-movie.is-keyboard-focus .m-timeline:focus') {
									margin-left: -($movie-timeline-scale * $movie-timeline-handle + 2 * $movie-timeline-handle-border);
									padding-left: ($movie-timeline-scale * $movie-timeline-handle + 2 * $movie-timeline-handle-border);
								}
							}
						}
					}
				}

				> .m-handle {
					border-radius: 50px;
					position: relative;
					display: inline-block;
					vertical-align: middle;
					width: $movie-timeline-scale * $movie-timeline-handle;
					height: $movie-timeline-scale * $movie-timeline-handle;

					transition: $movie-timeline-transition;
					border: $movie-timeline-handle-border solid white;

					@include if-parent('.m-basic-movie.is-timeline-dragging') {
						transition: none;
						border-color: $movie-color;
					}

					// huge handle
					@include if-parent('.m-timeline:hover, .m-basic-movie.is-timeline-dragging, .m-basic-movie.is-keyboard-focus .m-timeline:focus') {
						width: $movie-timeline-scale * $movie-timeline-handle;
						height: $movie-timeline-scale * $movie-timeline-handle;
					}

					@include mq(desktop) {
						width: $movie-timeline-handle;
						height: $movie-timeline-handle;
					}

					// handle time tooltip
					&::before {
						display: none;
						color: white;
						padding: 0 5px;
						font-size: small;
						position: absolute;
						border-radius: 5px;
						margin-bottom: 20px;
						content: attr(data-time);
						transform: translateX(-50%);
						background: transparentize(black, 0.5);
						@include absolute($bottom: 100%, $left: 50%);

						@include if-parent('.m-basic-movie.is-timeline-dragging') {
							display: block;
						}
					}
				}
			}
		}

		> .m-left {
			float: left;
			margin-left: -10px;
		}

		> .m-right {
			float: right;
			margin-right: -10px;
		}

		> .m-left,
		> .m-right {
			> .m-button {
				padding: 10px;
				color: inherit;
				cursor: pointer;
				font-size: 100%;
				appearance: none;
				user-select: none;
				border: medium none;
				text-align: inherit;
				font-family: inherit;
				line-height: inherit;
				display: inline-block;
				vertical-align: middle;
				transition: all 0.25s ease;
				background-color: transparent;
				filter: drop-shadow(0 0 2px transparentize(black, 0.5));

				&::-moz-focus-inner {
					border: none;
				}

				@include if-parent('.m-button:hover, .m-basic-movie.is-keyboard-focus .m-button:focus') {
					filter: none;
					color: $movie-color;
				}

				// TODO: remove this once replaced with SVG
				> i {
					font-size: 0;

					&::before {
						font-size: medium;
						vertical-align: middle;
					}
				}

				> svg {
					fill: white;
					width: 20px;
					height: 20px;
					transition: all 0.25s ease;

					@include if-parent('.m-button:hover, .m-basic-movie.is-keyboard-focus .m-button:focus') {
						fill: $movie-color;
					}

					@include mq(tablet) {
						width: 30px;
						height: 30px;
					}
				}

				&--play {
					display: none;
					left: -1px;
					width: 39px;
					height: 39px;
					position: relative;

					&::before {
						@include main-icon-play-arrow-white-36;
						content: "";
						position: absolute;
						top: -10px;
						left: -9px;

						@include if-parent('.m-basic-movie.is-playing') {
							@include main-icon-pause;
						}
					}

					&:hover {
						&::before {
							@include main-icon-play-arrow-highlight;

							@include if-parent('.m-basic-movie.is-playing') {
								@include main-icon-pause-highlight;
							}
						}
					}

					@include mq(desktop) {
						display: inline;
					}

					.m-play,
					> .fa-play,
					.m-pause,
					> .fa-pause {
						display: none;
					}
				}

				&--volume {
					position: relative;
					top: 1px;
					width: 39px;
					height: 39px;

					&::before {
						@include main-icon-volume-up;
						content: "";
						position: absolute;
						top: -10px;
						left: -10px;

						@include if-parent('.m-basic-movie.is-muted') {
							@include main-icon-volume-mute;
						}
					}

					&:hover {
						&::before {
							@include main-icon-volume-up-highlight;

							@include if-parent('.m-basic-movie.is-muted') {
								@include main-icon-volume-mute-highlight;
							}
						}
					}

					.m-on,
					> .fa-volume-up,
					.m-off,
					> .fa-volume-off {
						display: none;
					}
				}

				&--quality {
					@include autoalpha(0.5);

					@include if-parent('.m-basic-movie.is-hd') {
						@include autoalpha(1);
					}

					.m-menu {
						display: none;
					}

					.m-hd {
						display: none;
						font-size: small;
					}

					// it is a toggle because only 2 sources available
					&.is-toggle {
						@include autoalpha(0.5);

						@include if-parent('.m-basic-movie.is-hd') {
							@include autoalpha(1);
						}

						.m-menu {
							display: none;
						}

						.m-hd {
							display: inline-block;
						}
					}

				}

				&--orientation {
					position: relative;
					width: 39px;
					height: 39px;


					@include mq(tablet) {
						margin: 0 0 0 10px;
					}

					&::before {
						@include main-icon-gps-fixed;
						content: '';
						position: absolute;
						top: -10px;
						left: -10px;
					}

					&:hover {
						&::before {
							@include main-icon-gps-fixed-highlight;
						}
					}

					// on smartphone/tablet this is a toggle when orientationsensor is available
					@include if-parent('.m-basic-movie.has-orientation') {
						@include autoalpha(0.5);

						@include if-parent('.m-basic-movie.is-orientation') {
							@include autoalpha(1);
						}

						&::before {
							@include main-icon-mobile-move;
						}

						&:hover {
							&::before {
								@include main-icon-mobile-move-highlight;
							}
						}

						.m-center {
							display: none;
						}

						.m-orientation {
							display: inline-block;
						}
					}

					.m-orientation {
						display: none;
					}

					> .fa-crosshairs {
						display: none;
					}
				}

				&--stereo {
					@include autoalpha(0.5);
					position: relative;
					width: 39px;
					height: 39px;

					@include mq(tablet) {
						margin: 0 0 0 10px;
					}

					&::before {
						@include main-icon-glasses;
						content: "";
						position: absolute;
						top: -10px;
						left: -10px;
					}

					&:hover {
						&::before {
							@include main-icon-glasses-highlight;
						}
					}

					@include if-parent('.m-basic-movie.is-stereo') {
						@include autoalpha(1);
					}

					> .fa-columns {
						display: none;
					}
				}

				&--fullscreen {
					position: relative;
					width: 39px;
					height: 39px;

					@include mq(tablet) {
						margin: 0 0 0 10px;
					}

					&::before {
						@include main-icon-fullscreen;
						content: '';
						position: absolute;
						top: -10px;
						left: -10px;

						@include if-parent('.m-basic-movie.is-fullscreen') {
							@include main-icon-fullscreen-ext;
						}
					}

					&:hover {
						&::before {
							@include main-icon-fullscreen-highlight;

							@include if-parent('.m-basic-movie.is-fullscreen') {
								@include main-icon-fullscreen-ext-highlight;
							}
						}
					}

					.m-expand,
					> .fa-expand,
					.m-compress,
					> .fa-compress {
						display: none;
					}
				}
			}

			> .m-volume {
				display: inline-block;
				max-width: 0;
				overflow: hidden;
				position: relative;
				vertical-align: middle;
				transition: all 0.5s ease;

				@include mq(desktop) {
					display: inline;
				}

				@include if-parent('.m-basic-movie.is-volume') {
					max-width: ($movie-volume + 2*10px);
				}

				@include if-parent('.m-basic-movie.is-dragging, .m-basic-movie.is-timeline-dragging') {
					max-width: 0;
				}

				> .m-slider {
					cursor: pointer;
					position: relative;
					display: inline-block;
					box-sizing: content-box;
					width: $movie-volume;
					padding: (4 * $movie-timeline) 10px;

					> .m-line {
						width: 100%;
						position: relative;
						display: inline-block;
						transition: all 0.5s ease;
						height: $movie-timeline;
						background: $movie-color;

						@include if-parent('.m-basic-movie.is-muted') {
							width: 0 !important;
						}

						@include if-parent('.m-basic-movie.is-volume-dragging') {
							transition: none;
						}

						&::before {
							z-index: -1;
							content: "";
							height: 100%;
							position: absolute;
							display: inline-block;
							width: $movie-volume;
							background: transparentize(white, 0.8);
						}

						&::after {
							content: "";
							display: block;
							background: white;
							position: absolute;
							top: -$movie-timeline;
							width: $movie-timeline;
							height: (3 * $movie-timeline);
							right: (-0.5 * $movie-timeline);
						}
					}
				}
			}

			> .m-quality {
				display: none;
				margin: 0;
				padding: 5px 0;
				list-style: none;
				font-size: medium;
				text-align: right;
				border-radius: 5px;
				@include autoalpha(0);
				transition: all 0.5s ease;
				transform: translateX(150%);
				background: transparentize(black, 0.5);
				@include absolute($bottom: 100%, $right: 20px);

				@include mq(desktop) {
					display: inline;
				}

				@include if-parent('.m-basic-movie.is-quality-popup') {
					transform: none;
					@include autoalpha(1);
				}

				> li {
					cursor: pointer;
					padding: 5px 20px;

					&:hover {
						color: $movie-color;
					}

					&[aria-checked="true"] {
						color: $movie-color;
						background: transparentize(black, 0.5);
					}

					> i {
						min-width: 20px;
						text-align: center;
						padding-left: 10px;
					}
				}
			}

			> .m-time {
				padding: 10px;
				font-size: small;
				user-select: none;
				display: inline-block;
				vertical-align: middle;
				text-shadow: 0 0 2px transparentize(black, 0.5);
			}
		}
	}

	> .m-poster {
		cursor: pointer;
		@include autoalpha(0);
		transition: all 0.5s ease;
		@include absolute(0, 0, 0, 0);
		background: black center center;
		background-size: cover;

		@include if-parent('.m-basic-movie.is-poster, .m-basic-movie.is-error') {
			@include autoalpha(1);
		}
	}

	> .m-error {
		z-index: 1;
		@include absolute(0, 0, 0, 0);
		background: transparentize(black, 0.5);

		> .m-message {
			color: white;
			width: 280px;
			height: 50px;
			margin: auto;
			max-width: 100%;
			max-height: 100%;
			font-size: large;
			text-align: center;
			@include absolute(0, 0, 0, 0);
			text-shadow: 0 0 20px black, 0 0 20px black, 0 0 20px black, 0 0 20px black;
		}
	}
}

.m-basic-movie-notice {
	color: #8a6d3b;
	position: relative;
	border-radius: 5px;
	margin-bottom: 20px;
	background: #fcf8e3;
	padding: 10px 30px 10px 10px;
	border: 1px solid darken(adjust-hue(#fcf8e3, -10%), 5%);

	> .m-close {
		padding: 10px;
		cursor: pointer;
		@include absolute($top: 0, $right: 0);
	}
}

.m-basic-movie-error {
	position: relative;
	background: transparentize(black, 0.5);

	// fallback
	cursor: default;
	cursor: no-drop;

	&::before {
		content: "";
		display: block;
		padding-top: (9 / 16 * 100%);
	}

	> .m-message {
		color: white;
		margin: auto;
		width: 280px;
		height: 50px;
		max-width: 100%;
		max-height: 100%;
		font-size: large;
		text-align: center;
		@include absolute(0, 0, 0, 0);
		text-shadow: 0 0 20px black, 0 0 20px black, 0 0 20px black, 0 0 20px black;
	}
}