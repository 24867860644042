/* a-basic-btn--toggle */
.a-basic-btn--toggle {
	display: block;
	margin: 10px auto 0 auto;
	padding: 0 0 0 40px;
	background: none;
	outline: none;
	@include milo-regular(16px);
	position: relative;

	&:before {
		content: '';
		position: absolute;
		display: inline-block;
		@include main-icon-dashboard;
		width: 44px;
		height: 44px;
		top: -4px;
		left: -10px;
	}

	.l-outer--my-adac-dashboard & {
		&:before {
			@include main-icon-dashboard-grey;
			top: -6px;
		}
	}

	@include mq(desktop) {
		position: absolute;
		width: auto;
		margin-top: 0;
		top: 8px;
		right: 0;
	}

	@include mq(desktop-l) {
		top: 18px;
	}

	&:focus {
		color: $color-secondary-1;
		background-color: transparent;
	}

	&:hover {
		background-color: transparent;
	}
}