/* .autocomplete-suggestion */
.autocomplete-suggestions {
	top: 30px;
	left: 0;
	width: 100%;
	max-height: 210px;
	background: $color-primary-2;
	overflow: auto;
	border: 0;
	border-bottom: 1px solid $color-secondary-4;
	box-shadow: none;
	z-index: 1200;

	strong {
		font-weight: normal;
	}

	@include if-parent('.autocomplete-suggestions.is-shown') {
		max-height: inherit;
	}

	&--l {
		max-height: 400px;
	}

	&.js-suggestion--long {
		width: auto !important;
		border-top: 1px solid $color-secondary-4;
		max-width: calc(100% - 40px);

		@include mq(tablet) {
			max-width: 750px;
		}
	}

	// exception for search 2.0 / autocomplete
	.mm-searchbar--navi &,
	.mm-searchbar--content & {
		width: 100%;
		height: auto;
		background: $color-primary-2;
		overflow: initial;
		z-index: 115;
		border: none;
		max-height: inherit;
	}

	.autocomplete-suggestion--puzzlegallery:not(.mm-result-suggestion) {
		height: auto;
		line-height: normal;
		padding-top: 5px;
		padding-bottom: 5px;
		white-space: unset;

		> .aa-resultlist {
			display: inline-block;
			width: 80%;
		}

		> p {
			display: inline-block;
			width: 80%;
			margin-bottom: 0;
		}

		> img {
			float: left;
			max-width: 40px;
			margin-top: 5px;
			margin-right: 5px;
			width: 17%;
		}
	}

	.autocomplete-suggestion--rufdestination:not(.mm-result-suggestion) {
		line-height: normal;
		padding: 12px 17px 5px 10px;
		white-space: nowrap;

		> .aa-resultlist {

			> .aa-matched-string {
				@include milo-bold();

				.h-roboto & {
					font-family: 'Roboto Bold', sans-serif;
				}
			}
		}

		> img {
			float: left;
			margin: -3px 10px 0 0;
			width: 22px;
		}
	}

	.autocomplete-suggestion:not(.mm-result-suggestion) {
		white-space: nowrap;
		overflow: hidden;
		height: 40px;
		background-color: $color-secondary-7;
		width: 100%;
		border: 1px solid $color-secondary-4;
		border-bottom: none;
		line-height: 43px;
		padding: 2px 7px;

		@include if-parent ('.autocomplete-suggestions.js-suggestion--long') {
			height: auto;
			overflow: visible;
			white-space: normal;
			line-height: 20px;
		}

		&:first-of-type {
			border-top: none;
		}

		&:hover {
			cursor: pointer;
		}

		&.selected {
			background-color: $color-secondary-4;
		}

		&.has-button {
			padding-top: 15px;
			padding-left: 15px;
			height: 60px;

			&:hover {
				cursor: default;
				background-color: $color-secondary-7;
			}
		}

		> img {
			display: inline-block;
		}
	}
}