.m-ves-calculator-short--rrv {

	.mm-calc {
		> strong {
			display: block;
			width: 95%;
			font-weight: bold;
			margin-bottom: 10px;
			padding: 10px 10px 11px 10px;
			background: $color-secondary-6;

			@include mq(print) {
				background: none;
				box-shadow: inset 0 0 0 1000px $color-secondary-6;
			}
		}

		.l-form,
		.ll-fieldset {
			margin: 0;
		}

		.ll-row {
			margin: 0 0 10px 10px;
			padding: 0;
		}

		.ll-depend {
			margin-top: 10px;

			.a-basic-input-radio {
				margin: 5px 15px 5px 0;
			}
		}
	}

	.mm-triangle {
		display: block;
		opacity: 1;
		width: 100%;
		height: 0;
		padding-left: 50%;
		padding-top: 45px;
		overflow: hidden;
		position: relative;
		z-index: 10;
		left: 0;
		top: 3px;
		margin-bottom: -18px;

		@include mq(tablet) {
			transform: rotate(-90deg);
			margin-bottom: -52px;
			left: -50%;
			top: 165px;
			padding-top: 52px;
		}

		div.mm-top {
			width: 0;
			height: 0;
			margin-left: -1400px;
			margin-top: -502px;
			border-left: 1400px solid transparent;
			border-right: 1400px solid transparent;
			border-top: 500px solid $color-primary-1;
		}

		div.mm-bottom {
			width: 0;
			height: 0;
			margin-left: -1400px;
			margin-top: -500px;
			border-left: 1400px solid transparent;
			border-right: 1400px solid transparent;
			border-top: 500px solid $color-primary-2;

			@include mq(tablet) {
				margin-top: -508px;
			}
		}
	}

	.mm-result {
		position: relative;
		background: $color-secondary-6;
		padding: 30px 20px 15px 20px;

		@include mq(tablet) {
			padding: 20px 30px 15px 30px;
		}

		@include mq(desktop) {
			padding: 16px 30px 15px 30px;
		}

		@include mq(print) {
			background: none;
			box-shadow: inset 0 0 0 1000px $color-secondary-6;
		}

		h2,
		h3 {
			@include mq(tablet) {
				padding-bottom: 16px;
				text-align: left;
			}
		}

		h2 {
			padding-bottom: 20px;
			padding-top: 15px;
			text-align: center;

			@include mq(tablet) {
				padding-bottom: 16px;
				padding-top: 0;
				text-align: left;
			}
		}

		.a-basic-stopper + h2 {
			margin: 30px 0 0 0;

			@include mq(tablet) {
				margin: 0;
			}
		}

		.mm-infotext {
			display: block;
			width: 220px;
			margin-bottom: 15px;

			@include mq(tablet) {
				margin-bottom: 13px;
			}

			@include mq(desktop) {
				margin-bottom: 7px;
			}

			small {
				color: $color-secondary-2;
			}
		}

		.mm-value-container {
			background: $color-primary-2;
			padding: 5px 0;
			position: relative;
			text-align: center;
			margin: 0 0 15px 0;

			@include mq(tablet) {
				padding: 5px 0;
				margin: 0 0 188px 0;
			}

			> span {
				@include font-size(16px);
				font-weight: bold;

				&.mm-amount,
				&.mm-unit {
					@include font-size(43px);
				}

				&.mm-amount {
					margin-right: 5px;
				}
			}
		}

		.mm-creditdifference-container {
			margin: 0 0 20px 0;

			.mm-difference {
				font-weight: bold;

				& + .mm-difference {
					.mm-label {
						@include mq(desktop) {
							margin-top: 11px;
						}
					}

					.mm-result {
						@include mq(desktop) {
							top: 1px;
						}
					}
				}

				.mm-label {
					display: block;
					width: 100%;
					margin: 10px 0 4px 0;
					text-align: center;

					@include mq(tablet) {
						width: 44%;
						height: 40px;
						float: left;
						padding: 0;
						margin-top: 11px;
						text-align: left;
					}

					@include mq(desktop) {
						width: 60%;
						margin-top: 13px;
						margin-bottom: 0px;
					}
				}

				.mm-result {
					display: block;
					position: relative;
					width: 130px;
					margin: 0 auto 6px auto;
					padding: 3px 20px;
					background: $color-primary-2;
					text-align: center;

					@include mq(tablet) {
						position: relative;
						top: 14px;
						width: 42%;
						height: 43px;
						float: right;
						padding: 18px 10px 3px 10px;
						text-align: left;
					}

					@include mq(desktop) {
						top: 3px;
						width: 35%;
						height: 41px;
						padding-top: 10px;
						padding-left: 20px;
						padding-bottom: 4px;
					}

					em {
						font-style: normal;
					}
				}
			}
		}
	}
}