.a-my-adac-digital-card {
	width: 100%;
	position: relative;
	border-radius: 26px;
	overflow: hidden;

	&--small {
		max-width: 365px;

		@include mq(tablet) {
			width: 365px;
		}
	}

	.m-my-adac-dashboard-box--block & {
		max-width: 365px;
		margin-left: auto;
		margin-right: auto;
	}

	.l-outer--my-adac & {
		margin: 0 0 24px 0;
	}

	> .mm-image-wrapper {

		> img {
			width: 100%;
			display: block;

			@include mq(print) {
				max-width: 50%;
				margin: 0 auto;
			}

		}
	}


	> .mm-wrapper {
		position: absolute;
		left: 24px;
		top: 155px;

		@include mq(desktop) {
			left: 26px;
			top: 170px;
		}

		.m-my-adac-dashboard-box--block & {
			top: 190px;
			left: 30px;
		}

		//.a-my-adac-digital-card--black & {
		@include if-parent('.a-my-adac-digital-card--black') {
			color: $color-primary-2;
		}

		@include if-parent('.a-my-adac-digital-card.a-my-adac-digital-card--small') {
			bottom: 20px;
			top: initial;

			@include mq(desktop) {
				bottom: 20px;
				top: initial;
			}
		}

		> .mm-member-name {
			margin: 0;
			height: 152px;
			max-width: 180px;
			@include milo-regular(23px, 27px);

			@include mq(desktop) {
				height: 167px;
				max-width: 200px;
				@include milo-regular(25px, 29px);
			}

			.m-my-adac-dashboard-box--block & {
				height: 180px;
			}

			@include if-parent('.a-my-adac-digital-card.a-my-adac-digital-card--small') {
				@include milo-regular(18px, 27px);
				height: auto;

				@include mq(desktop) {
					@include milo-regular(20px, 29px);
				}
			}
		}

		> .mm-data-wrapper {
			display: flex;
			flex-flow: row nowrap;

			> .mm-data {
				display: flex;
				flex-flow: column nowrap;
				justify-content: flex-start;
				margin: 0 10px 0 0;

				// exception when digital card is a toggle-element
				.a-my-adac-list .mm-my-adac-toggle & {
					margin-top: 16px
				}

				> .mm-headline {
					@include milo-regular(8px, 11px);
					margin: 0;

					@include mq(desktop) {
						@include milo-regular(10px, 14px);
					}
				}

				> .mm-data-member {
					@include milo-bold(16px, 20px);
					margin: 0;

					@include mq(desktop) {
						@include milo-bold(18px, 22px);
					}

					> span {
						@include milo-regular(16px, 20px);

						@include mq(desktop) {
							@include milo-regular(18px, 22px);
						}
					}
				}
			}
		}
	}

	> .mm-code-wrapper {
		display: flex;
		bottom: 0;
		flex-direction: row;
		justify-content: space-between;
		position: relative;
		padding: 0 25px;
		background: $color-primary-2;
		width: 100%;

		> .mm-qr-code {
			max-width: 80px;
			height: 80px;
			padding: 22px 0 28px 0;
			box-sizing: content-box;

			@include mq(desktop) {
				max-width: 90px;
				height: 90px;
			}

			> img {
				width: 117px;
			}
		}

		> .mm-ean-code {
			width: 135px;
			height: 80px;
			position: relative;
			padding: 22px 0 28px 0;
			box-sizing: content-box;

			@include mq(desktop) {
				width: 145px;
				height: 90px;
			}

			> img {
				max-width: 135px;
				height: 90px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
}