/* m-layout-header-skiplinks */
.m-layout-header-skiplinks {

	position: absolute;
	top: 0;
	left: 200px;
	z-index: 30;

	> ul > li > a {
		display: block;
		padding: 7px 15px;
		background: $color-secondary-6;
		text-decoration: none;
	}

}