.m-basic-toggle--accordion {
	.mm-head {
		@include mq(print) {
			box-shadow: inset 0 0 0 1000px $color-secondary-5;
		}

		&:before {
			@include mq(print) {
				content: "";
				width: 0;
				height: 0;
				border-top: 4px solid transparent;
				border-left: 6px solid $color-primary-3;
				border-bottom: 4px solid transparent;
				display: block;
				position: absolute;
				margin-top: 6px;
				margin-left: -14px;
			}

		}

		&.mm-opened {

			&:before {
				@include mq(print) {
					content: "";
					width: 0;
					height: 0;
					border-left: 4px solid transparent;
					border-right: 4px solid transparent;
					border-top: 6px solid $color-primary-3;
					display: block;
					position: absolute;
					margin-top: 6px;
					margin-left: -14px;
				}

			}
		}
	}
}