/* m-basic-search */
.m-basic-search {

	&[data-mod="navi"]:not(.is-open) {
		display: none;

		@include mq(tablet) {
			display: block;
		}

		// for brs-header hide it first, on icon click the magic happens
		.o-layout-header-metanav--brs & {
			display: none;
		}

		// exception m-basic-header
		.m-basic-header & {
			display: block;
			height: 100%;
			padding: 5px 8px;
			border: 1px solid $color-secondary-16;
			border-radius: 22px;

			@include mq(tablet) {
				padding: 12px 24px;
				background-color: $color-primary-2;
			}

			@include mq(desktop-l) {
				padding: 11px 24px;
			}

			// exception .is-stuck
			@include if-parent('.m-basic-header.is-stuck') {

				@include mq(desktop-l) {
					padding-top: 14px;
					padding-bottom: 14px;
				}
			}

			// exception .m-basic-header--reduced
			@include if-parent('.m-basic-header--reduced') {

				@include mq(desktop-l) {
					padding-top: 14px;
					padding-bottom: 14px;
				}
			}

			// unused bc looks shitty
			@keyframes borderRadAnim {
				0% {
					border-radius: 22px;
				}

				100% {
					border-radius: 8px 8px 0 0;
				}
			}
		}

		.m-basic-header.js-search-focus & {
			border: 2px solid $color-secondary-22;
		}

		@include mq('print') {
			display: none;
		}
	}

	@include mq('print') {
		display: none;
	}

	> .mm-searchbar {

		@include mq(print) {
			display: none;
		}

		&.mm-searchbar--content {
			margin-bottom: 40px;
		}

		&.mm-searchbar--navi {
			padding: 25px 20px 20px 20px;
			border-top: 1px solid $color-primary-2;
			width: 100%;
			max-height: 78px;
			background: $color-primary-1;
			opacity: 1;

			@include mq(tablet) {
				padding: 0;
				border: none;
				width: 250px;
				height: 30px;
				opacity: 0;
				position: absolute;
				top: 16px;
				display: block;
				overflow: initial;
				z-index: 90;
				transition: opacity 1s;
				background: transparent;

				// for brs-header
				.o-layout-header-metanav--brs & {

					@include if-parent('.mm-searchbar.is-open') {
						opacity: 1;
					}
				}
			}

			@include mq(desktop) {
				width: 370px;
				top: 26px;

				.l-outer--nav & {
					margin-left: 18%;
				}

				// exception .m-basic-header
				.l-outer--nav .m-basic-header & {
					margin-left: 0;
				}
			}

			@include mq(desktop-xl) {

				.l-outer--nav & {
					margin-left: 0;
				}
			}

			@include if-parent('.m-basic-search.is-open') {
				display: block;

				@include mq(tablet) {
					height: 30px;
				}
			}

			// exception m-basic-header
			.m-basic-header & {
				width: 100%;
				max-height: 100%;
				padding: 0;
				border: none;
				background: none;

				@include mq(tablet) {
					position: static;
					opacity: 1;
					height: 20px;
				}
			}
		}

		// for brs-header
		.o-layout-header-metanav--brs & {
			display: none;
		}

		> .mm-search-form {

			@include if-parent('.m-basic-search[data-mod="navi"]') {

				@include mq(tablet) {
					background: transparent;
					position: absolute;
					// so the icon is on the same spot, as the original ones
					right: -4px;
					width: 100%;
				}

				// exception m-basic-header
				.m-basic-header & {

					@include mq(tablet) {
						position: static;
						right: auto;
					}
				}
			}

			@include if-parent('.m-basic-search[data-mod="content"]') {
				position: relative;
				max-width: 610px;
				width: 100%;
			}

			> .mm-search {
				display: flex;
				flex-flow: column nowrap;

				> .mm-search-input-wrapper {
					display: flex;
					flex-flow: row nowrap;

					@include if-parent('.m-basic-search[data-mod="navi"]') {
						position: relative;
						width: 100%;
						height: 30px;
						padding: 0 10px 0 15px;
						background-color: $color-primary-2;
						z-index: 105;

						@include mq(tablet) {
							background-color: transparent;
						}

						@include if-parent('.mm-search-form.is-focused') {
							background-color: $color-primary-2;

							// exception m-basic-header
							.m-basic-header & {
								background-color: unset;
							}
						}

						// exception m-basic-header
						.m-basic-header & {
							padding-top: 6px;
							padding-left: 16px;

							@include mq(tablet) {
								padding: 0;
								height: 20px;
								z-index: 110;
							}
						}
					}

					> .mm-search-input {
						position: relative;
						display: flex;
						flex-flow: row nowrap;
						width: 100%;

						@include mq(tablet) {

							@include if-parent('.m-basic-search[data-mod="navi"] .mm-search-form.is-focused') {
								border-bottom: none;
							}

							@include if-parent('.m-basic-search[data-mod="navi"]') {
								border-bottom: 1px solid $color-secondary-1;
							}
						}


						@include if-parent('.m-basic-search[data-mod="content"] .mm-search-form.is-focused') {
							// blacklayer has z-index: 101
							z-index: 105;
						}

						// exception m-basic-header
						.m-basic-header & {

							@include mq(tablet) {
								border-bottom: 0;
							}
						}

						&.is-general-styling {
							align-items: center;
							column-gap: 20px;
						}

						input[type="search"] {
							position: relative;
							@include font-size($font-size-17);
							display: block;
							width: 100%;
							height: 100%;
							-webkit-appearance: none;
							border-radius: 0;

							@include mq(tablet) {
								background: transparent;
								width: 300px;
								@include font-size(15px);
							}

							@include mq(desktop) {
								width: 360px;
							}

							&:focus::placeholder {

								// exception m-basic-header
								.m-basic-header & {
									color: transparent;
								}
							}

							&::placeholder {

								@include mq(tablet) {
									color: $color-secondary-1;
								}

								// exception m-basic-header
								.m-basic-header & {
									color: rgba($color-primary-3, 0.6);
									overflow: hidden;
									text-overflow: ellipsis !important;
									white-space: nowrap;
								}
							}

							@include if-parent('.m-basic-search[data-mod="navi"]') {
								padding: 4px 50px 0 5px;

								// exception m-basic-header
								.m-basic-header & {
									padding: 0 50px 0 0;
									color: rgba($color-primary-3, 0.9);
									caret-color: $color-primary-3;
									@include font-size($font-size-18, 24px);
									overflow: hidden;
									text-overflow: ellipsis !important;
									white-space: nowrap;

									@include mq(tablet) {
										width: 100%;
										padding-right: 20px;
										@include font-size($font-size-16, 20px);
									}
								}
							}

							@include if-parent('.m-basic-search[data-mod="content"]') {
								flex: 0 1 100%;
								flex-basis: 100%;
								margin-right: 10px;
								background: $color-secondary-8;
								padding: 5px 45px 5px 15px;
							}
						}

						> .mm-search-cancel {
							cursor: initial;
							display: block;
							height: 35px;
							opacity: 0;
							transition: opacity .2s ease-out;

							@include if-parent('.m-basic-search[data-mod="content"]') {
								@include main-icon-cancel;
								@include absolute(-3px, auto, auto, 90px);
							}

							@include if-parent('.m-basic-search[data-mod="navi"]') {
								width: 20px;
								height: 20px;
								@include absolute(1px, auto, auto, 50px);
								border-radius: 18px !important;

								@include mq(tablet) {
									@include absolute(1px, auto, auto, 35px);
								}

								@include mq(desktop-l) {
									@include absolute(1px, auto, auto, 45px);
								}

								@include mq(desktop-xl) {
									@include absolute(5px, auto, auto, 32px);
									margin-right: 13px;
									margin-top: -5px;
								}

								.m-basic-header.is-stuck & {
									@include mq(desktop-l) {
										right: 65px;
									}

									@include mq(desktop-xl) {
										right: 32px;
									}
								}

								// exception m-basic-header
								.m-basic-header--reduced & {
									@include mq(desktop-l) {
										top: -2px;
									}

									@include mq(desktop-xl) {
										top: 2px;
									}
								}

								> svg {
									position: absolute;
									background-color: $color-secondary-2;
									border-radius: 15px;
									width: 20px;
									height: 20px;
									padding: 1.5px;
									right: 0;
									bottom: 0;
								}
							}

							&.is-shown {
								cursor: pointer;
								opacity: 1;
								transition: opacity .2s ease-in;
							}
						}

						> .mm-searchbtn {
							display: table;
							width: fit-content;
							white-space: nowrap;
							height: 36px;
							padding: 10px 22px 8px 23px;
							text-decoration: none;
							cursor: pointer;
							text-align: left;
							font-weight: 300;
							border-radius: 4px;
							outline: none;
							background: $color-primary-1;
							@include milo-bold(14px);
						}

						> .mm-icon-search {
							@include main-icon-search;
							height: 35px;
							position: absolute;
							top: 1px;
							right: -10px;
							padding: 4px 3px 0 0;
							text-indent: -10000px;

							@include mq(tablet) {
								@include absolute(0, auto, auto, 0);
							}
						}
					}

					> .mm-icon-search {
						display: flex;
						padding: 2px 0 0 0;
					}
				}

				> .mm-search-result {
					width: 100%;
					height: auto;
					z-index: 105;
					display: block;
					visibility: visible;

					@include if-parent('.mm-search-form:not(.is-focused)') {
						// debug
						visibility: hidden;
					}

					@include if-parent('.m-basic-search[data-mod="navi"]') {
						border-top: 2px solid $color-primary-1;
						position: relative;
						top: auto;
						left: auto;
						height: auto;

						@include mq(desktop-l) {
							position: absolute;
							left: 0;
							top: 30px;
						}

						@include if-parent('.mm-search-form.is-focused') {
							// a good value to see enough if the mobile keyboard is out
							// to force the scrolling to
							height: 350px;
							// to guarantee that the user can scroll to the end of it
							padding: 0 0 150px 0;
							overflow-y: scroll;

							@include mq(tablet) {
								overflow-y: auto;
								height: auto;
								padding: 0;
							}

							// exception m-basic-header
							.m-basic-header & {
								// did not understand the 150px buffer, but it might be another case for the new header
								// (or my stupidness)
								padding-bottom: 30px;

								@include mq(tablet) {
									padding-bottom: 0;
								}
							}
						}

						&.is-landscape {

							@include if-parent('.mm-search-form.is-focused') {
								// to guarantee that the user can scroll to the end pf it
								padding: 0 0 300px 0;

								@include mq(tablet) {
									padding: 0;
								}
							}
						}

						&.mm-has-advert {

							@include mq(desktop-l) {
								max-width: 100%;
								width: auto;
							}
						}

						// exception m-basic-header
						.m-basic-header & {

							@include absolute(56px, 0);
							border-top: 1px solid $color-secondary-16;
							overflow: visible;

							@include mq(tablet) {
								top: 0;
								padding-top: 44px;
								border: none;
								z-index: -1;
								border-radius: 22px;
								box-shadow:
									0 11px 15px -7px rgba(0, 0, 0, 0.2),
									0 9px 46px 8px rgba(0, 0, 0, 0.12),
									0 24px 38px 3px rgba(0, 0, 0, 0.14);
							}

							@include mq(desktop) {
								padding-top: 43px;
							}

							// exception .is-stuck
							@include if-parent('.m-basic-header.is-stuck') {

								@include mq(desktop-l) {
									visibility: visible;
									top: 8px;
									padding-top: 47px;
								}
							}

							// exception mod --reduced
							@include if-parent('.m-basic-header--reduced') {
								@include mq(desktop-l) {
									visibility: hidden;
								}

								@include if-parent('.mm-search-form.is-focused') {
									@include mq(desktop-l) {
										visibility: visible;
									}
								}
							}

							@include if-parent('.m-basic-header.js-suggestion-visible') {

								@include mq(tablet) {
									border-radius: 22px;
									background-color: $color-primary-2;
									border: none;
									border-top: none;
									z-index: 105;
								}
							}
						}
					}

					@include if-parent('.m-basic-search[data-mod="content"]') {
						max-width: 100%;
						position: absolute;
						top: 40px;
						left: 0;

						@include mq(tablet) {
							max-width: 520px;
						}

						@include if-parent('.mm-search-form.is-focused') {
							border-top: 2px solid transparent;
						}
					}

					> .autocomplete-suggestions {
						position: static !important;
						width: 100%;
						height: auto;
						background-color: $color-primary-2;
						border-left: 1px solid $color-secondary-16 !important;
						border-right: 1px solid $color-secondary-16 !important;

						@include if-parent('.m-basic-search[data-mod="content"] .autocomplete-suggestions.is-shown') {
							border: 1px solid $color-secondary-2;
						}

						// exception m-basic-header
						.m-basic-header & {
							padding: 0 8px;
							min-width: 375px;

							@include mq(tablet) {
								border-radius: 4px;
								border-top-left-radius: 0;
							}

							@include mq(desktop) {
								border-radius: 22px;
								border-top-left-radius: 0;
								border-top-right-radius: 0;
								border-bottom: none;
								border-right: none;
								border: none;
							}
						}

						> .autocomplete-no-suggestion {
							padding: 5px 15px;
							font-weight: bolder;

							@include font-size($font-size-17, 21px);

							@include mq(tablet) {
								@include font-size($font-size-20, 22px);
								padding: 5px 15px;
							}
						}

						> .mm-content-headline {
							padding: 15px 15px 5px 15px;
							border-bottom: 1px solid $color-secondary-2;
							@include milo-bold($font-size-16, 21px);

							@include mq(tablet) {
								@include font-size($font-size-18, 24px);
							}

							// exception m-basic-header
							.m-basic-header & {
								padding: 16px 8px;
								@include font-size($font-size-20, 24px);

								@include mq(tablet) {
									padding-top: 24px;
									border-color: $color-secondary-16;
								}

								@include mq(desktop) {
									border-top: 1px solid $color-secondary-16;
								}
							}
						}

						> .mm-content-wrapper {
							display: flex;
							flex-flow: row nowrap;

							> .mm-content {
								flex: 0 1 100%;
								height: 100%;

								@include if-parent('.m-basic-search[data-mod="content"]') {
									max-width: 100%;
								}

								@include if-parent('.m-basic-search[data-mod="navi"]') {
									max-width: 100%;

									& + .mm-content-advertising {

										// test this again
										@include mq(tablet) {
											max-width: 370px;
										}

										@include mq(desktop-l) {
											flex: 0 1 55%;
											max-width: 100%;
										}
									}
								}

								> .mm-result-suggestion {
									display: flex;
									flex-flow: row nowrap;
									border-bottom: 1px solid $color-secondary-2;

									// is set while hovering!
									&.mm-result-selected {
										background: $color-secondary-8;

										// exception m-basic-header
										.m-basic-header & {
											background: $color-secondary-19;
											cursor: pointer;
										}
									}

									// exception m-basic-header
									.m-basic-header & {
										padding: 0;
										border-color: $color-secondary-16;
									}

									b {
										color: $color-primary-3;

										// exception m-basic-header
										.m-basic-header & {
											color: rgba($color-primary-3, .9);
										}
									}

									> .mm-link-wrapper {
										display: flex;
										flex-flow: row nowrap;
										text-decoration: none;
										max-width: 100%;

										@include if-parent('.mm-result-suggestion.mm-old-results') {
											max-width: calc(100% - 30px);
										}

										> .mm-link {
											display: inline-block;
											padding: 10px 0 10px 15px;
											white-space: nowrap;
											text-overflow: ellipsis;
											overflow: hidden;
											text-decoration: none;

											&:only-child {
												padding: 10px 10px 10px 15px;
											}

											@include if-parent('.mm-result-suggestion.mm-old-results') {

												&:only-child {
													padding: 10px 5px 10px 15px;
												}
											}

											// exception m-basic-header
											.m-basic-header & {
												padding: 14px 8px;
												color: rgba($color-primary-3, .6);
											}

											&:visited {
												color: $color-primary-3;
												text-underline: none;
											}

											.mm-segment {
												color: $color-secondary-20;

												&--inner {
													color: $color-secondary-17;
												}
											}
										}

										> .mm-result-count {
											padding: 10px 15px 10px 0;

											@include if-parent('.mm-result-suggestion.mm-old-results') {
												padding: 10px 5px 10px 0;
											}
										}
									}

									> .mm-old-result-cancel {
										@include main-icon-cancel-black;
										display: block;
										min-width: 20px;
										height: 35px;
										margin: auto 5px auto 0;
										cursor: pointer;

										@include mq(desktop) {
											opacity: 0;
										}

										@include if-parent('.mm-result-suggestion.mm-result-selected, .mm-result-suggestion:hover') {

											@include mq(tablet) {
												opacity: 1;
											}
										}

										&.is-hidden {
											display: none;
										}
									}
								}

								> .mm-button-wrapper {
									display: block;
									padding: 15px;

									> .mm-button {
										display: table;
										padding: 5px;
										max-width: 340px;
										position: relative;

										// exception m-basic-header
										.m-basic-header & {
											margin: 0 auto;
											padding: 0;
											border-radius: 4px;
											background-image: none;
										}

										> .mm-show-more {
											min-height: 30px;
											padding: 4px 5px 4px 23px;
											text-decoration: none;
											cursor: pointer;
											text-align: left;
											font-weight: 300;
											outline: none;
											@include milo-regular(16px);
											white-space: nowrap;
											text-overflow: ellipsis;
											overflow: hidden;

											// exception m-basic-header
											.m-basic-header & {
												display: block;
												padding: 10px 24px;
												@include milo-bold($font-size-14, 16px);

												&:before {
													content: '';
													display: inline-block;
													@include main-icon-arrow-right-grey;
													position: absolute;
													left: -8px;
													top: -4px;
												}
											}

											&:before {
												content: '';
												display: inline-block;
												@include main-icon-arrow-right-grey;
												position: absolute;
												left: -8px;
												top: -7px;
											}
										}

										> .mm-all-result-count {

											padding: 4px 22px 4px 0;
										}
									}
								}
							}

							> .mm-content-advertising {
								display: none;

								@include mq(desktop-l) {
									flex: 0 1 45%;
									max-width: 320px;
									height: auto;
									display: block;
								}

								&:empty {
									display: none;
								}

								> .mm-ad-suggestion {
									display: flex;
									flex-flow: column nowrap;
								}
							}
						}
					}
				}
			}
		}
	}
}