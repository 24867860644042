/* m-basic-list--teaser-extended */
.m-basic-list-wrapper {

	background: $color-secondary-8;
	padding: 40px 10px;

	//exception
	&.m-basic-list--no-bg {
		background: transparent;
		padding: initial;
	}

	@include mq(tablet) {
		padding: 40px 0;
	}

	> .m-basic-list--teaser-extended {
		display: flex;
		justify-content: flex-start;
		max-width: 100%;
		flex-direction: column;

		@include mq(tablet) {
			flex-flow: row wrap;
			justify-content: center;
		}

		> li {
			position: relative;
			padding: 1px 0 5px 40px;
			margin: 0 0 20px 0;
			background: 0;
			@include milo-bold();

			@include mq(tablet) {
				padding: 0 10px;
				min-width: 147px;
				max-width: calc(100% / 3);
				word-wrap: break-word;
				text-align: center;
				flex: 0 0 auto;
			}

			@include mq(desktop) {
				max-width: 147px;
			}

			&:before {
				background: none;
				display: none;
			}

			//teaser-extended-notbold

			// exception teaser-extended-notbold
			@include if-parent('.m-basic-list--teaser-extended.m-basic-list--teaser-extended-notbold') {
				@include milo-regular();
			}

			// exception landingpage-logged-out
			@include if-parent('.m-basic-list--teaser-extended.m-basic-list--teaser-extended-higher-width') {
				@include mq(desktop) {
					max-width: 25%;
				}
			}

			//exception
			@include if-parent('.m-basic-list--teaser-extended.m-basic-list--teaser-extended-numbers-by-side') {
				padding-left: 40px;
				padding-right: 20px;

				@include mq(tablet) {
					text-align: left;
				}

				@include mq(desktop) {
					max-width: calc(100% / 3);
				}

				//exception if only one number each row is needed
				@include if-parent('.m-basic-list--teaser-extended.m-basic-list--teaser-extended-one-number-each-row') {
					margin-bottom: 10px;
					min-width: 100%;
					max-width: 100%;

					@include mq(tablet) {
						margin-bottom: 20px;
					}
				}
			}

			> .mm-img-content {
				position: static;
				margin-bottom: 5px;

				@include mq(tablet) {
					position: relative;
				}

				//exception
				@include if-parent('.m-basic-list--teaser-extended.m-basic-list--teaser-extended-numbers-by-side') {
					@include mq(tablet) {
						position: absolute;
						left: 0;
					}

					//exception if only one number each row is needed
					@include if-parent('.m-basic-list--teaser-extended.m-basic-list--teaser-extended-one-number-each-row') {
						top: -5px;
					}
				}

				> img {
					display: inline;
					@include absolute($top: 0, $left: 0);
					margin: 0;

					@include mq(tablet) {
						position: static;
					}
				}

				> .mm-index {

					@include absolute($top: 5px, $left: 9px);
					@include milo-slab-bold(18px);

					font-variant-numeric: tabular-nums;

					@include mq(tablet) {
						@include absolute($top: calc(50% - 2px), $left: 50%);
						transform: translate(-50%, -50%);
					}

					//exception
					@include if-parent('.m-basic-list--teaser-extended.m-basic-list--teaser-extended-numbers-by-side') {
						top: 4px;
						left: 9px;
						transform: none;
					}
				}
			}

			> ul {
				> li {
					@include milo-regular();

					&:first-child {
						margin-top: 15px;
					}
				}
			}
		}
	}
}