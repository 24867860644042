// fonts
@mixin arial($font-size: null, $line-height: null) {
	font-family: "Calibri", "Arial", sans-serif;

	@if $font-size {
		@include font-size($font-size, $line-height);
	}
}

@mixin franklin-book($font-size: null, $line-height: null) {
	font-family: "franklin-book", Arial, sans-serif;

	@if $font-size {
		@include font-size($font-size, $line-height);
	}
}

@mixin franklin-demi($font-size: null, $line-height: null) {
	font-family: "franklin-demi", Arial, sans-serif;

	@if $font-size {
		@include font-size($font-size, $line-height);
	}
}




// MiloSlabforADACW01-Bold
@mixin milo-slab-bold($font-size: null, $line-height: null) {
	font-family: "MiloSlabforADACW04-Bold", Arial, sans-serif;
	font-feature-settings: 'lnum' 1;

	@if $font-size {
		@include font-size($font-size, $line-height);
	}
}

// Milo ADAC W04 Bold
@mixin milo-bold($font-size: null, $line-height: null) {
	font-family: "Milo ADAC W04 Bold", Arial, sans-serif;
	font-weight: 500;
	font-feature-settings: 'lnum' 1;

	@if $font-size {
		@include font-size($font-size, $line-height);
	}
}

// MiloforADACW04-Regular
@mixin milo-regular($font-size: null, $line-height: null) {
	font-family: "Milo ADAC W04 Regular", Arial, sans-serif;
	font-feature-settings: 'lnum' 1;

	@if $font-size {
		@include font-size($font-size, $line-height);
	}
}


@mixin placeholder {

	&::-webkit-input-placeholder {
		@content;
		color: $color-secondary-3;
	}

	&:-moz-placeholder {
		@content;
		color: $color-secondary-3;
	}

	&::-moz-placeholder {
		@content;
		color: $color-secondary-3;
	}

	&:-ms-input-placeholder {
		@content;
		color: $color-secondary-3;
	}
}