.o-basic-cluster-box {
	width: 100%;
	margin-top: 0;
	margin-left: auto;
	margin-right: auto;
	background: $color-secondary-8;

	@include mq(tablet) {
		max-width: none;
		min-height: 336px;
	}

	@include mq(print) {
		margin: 0;
		border-top: 20px solid $color-primary-2;
		page-break-inside: avoid;

		&:first-child {
			border-top: 0;
		}
	}

	&:not(.h-space-m):not(.h-space-l) {
		margin-bottom: 20px;
	}

	.oo-header {
		padding: 20px 30px 22px 30px;

		@include mq(print) {
			padding-left: 0;
		}

		> h2 {
			max-width: none;
		}

	}

	.oo-headline {
		padding: 0;

		@include mq(tablet) {
			max-height: none;
			min-height: 0;
			overflow: hidden;
		}
	}

	.oo-figure {
		margin: 0;

		@include mq(tablet) {
			max-height: 336px;
			max-width: inherit;
			overflow: hidden;
			text-align: center;
		}

		> img,
		> picture img {
			position: relative;
			width: 100%;
			height: auto;

			@include mq(tablet) {
				left: 50%;
				margin: 0 0 0 -100%;
				width: auto;
				max-width: 463px;
			}

			@include mq(print) {
				left: 0;
				margin: 0;
				height: 100%;
				width: auto;
			}
		}
	}

	.oo-main {
		position: relative;
		width: 100%;
		display: block;
		z-index: 0;
		max-height: inherit;
		overflow: inherit;

		@include mq(tablet) {
			display: flex;
			justify-content: flex-start;
		}

		@include mq(print) {
			min-height: 200px;
		}

		&--edit {
			overflow-x: scroll;
			overflow-y: hidden;
			display: flex;
			flex-direction: row;
		}

		> section {

			&.oo-img-box {
				margin-right: 0;

				@include mq(tablet) {
					width: 50%;
				}

				@include mq(print) {
					width: 279px;
				}

				@include if-parent('.oo-main.oo-main--edit') {
					margin-right: 0;
				}
			}

			&.oo-content-box {
				width: 100%;
				height: 100%;
				position: relative;
				padding: 10px 15px 10px 15px;

				@include mq(tablet) {
					width: 50%;
					padding-top: 17px;
					padding-left: 25px;
				}

				@include mq(print) {
					@include absolute($top: 0, $right: 0);
					padding: 0 0 0 25px;
					width: 320px;
				}

				@include if-parent('.oo-main.oo-main--edit') {
					position: static;
					flex: 0 0 auto;
				}

				.oo-header {
					padding: 0 0 8px;
					max-width: 85%;

					@include mq(tablet) {
						padding: 0 0 18px;
					}

					@include mq(print) {
						padding-left: 0;
					}

					> h2 {
						max-width: none;
					}
				}

				> .oo-stopper {
					@include mq(tablet) {
						max-width: 198px;
					}

					@include mq(desktop) {
						max-width: 210px;
					}
				}

				> .mm-table {
					> tbody {
						> tr {
							> td {
								vertical-align: top;

								&:first-of-type {
									padding-right: 15px;
								}
							}
						}
					}
				}

				> .mm-appstore {
					display: flex;
					flex-flow: row nowrap;

					> a {
						flex: 0 1 140px;

						> img {
							max-width: 135px;
						}
					}
				}

				> .oo-text--label {
					margin-bottom: 5px;
				}

				> .oo-phone {
					@include milo-bold();

					.h-roboto & {
						font-family: 'Roboto Bold', sans-serif;
					}

					> a {
						text-decoration: none;
					}
				}

				> .a-basic-input-text {
					width: 100%;
					max-width: 340px;

					@include mq(tablet) {
						max-width: 308px;
					}

					&.h-space-s {
						margin-bottom: 5px;
					}
				}

				> .a-basic-input-select {
					width: 100%;
					max-width: 340px;

					@include mq(tablet) {
						max-width: 308px;
					}

					&.h-space-m {
						margin-bottom: 17px;
					}
				}
			}
		}
	}

	.oo-subheadline {
		display: block;
	}

	.oo-calc {
		.oo-result {
			width: 100%;
			height: 55px;
			padding: 4px 8px;
			background: #F4F4F4;
			margin: 0 0 5px 0;

			> p {
				font-weight: bold;
				margin-bottom: 0;
			}

			.oo-amount,
			.oo-unit {
				@include font-size($font-size-24);
				line-height: 1.125;
				font-weight: bold;
				margin-bottom: 0;
			}

			@include mq(tablet) {
				width: 150px;
				margin: 0;
				@include absolute($bottom: 15px, $left: 15px);
			}
		}
	}

	.ll-main-center--mgldiscount & {
		margin-bottom: 40px;

		@include mq(tablet) {
			margin-bottom: 60px;
		}
	}
}