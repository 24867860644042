/* a-basc-link--before-icon */
.a-basic-link--before-icon {
	background: none;
	display: flex;
	align-items: center;
	padding-left: 0;
	max-width: fit-content;

	&:before {
		content: none;
	}

	.m-adac-maps--searchresults & {
		padding-left: 0;
	}

	> .aa-icon-wrapper {
		display: block;
		margin-right: 5px;
		width: 25px;
		height: 21px;

		> img {
			display: block;
			position: relative;
			left: -12px;
			top: -11px;
			width: 44px;
			height: 44px;
		}
	}
}