/* a-basic-input-radio--white */
.a-basic-input-radio--white {

	> input {
		@extend %h-visuallyhidden;

		& ~ div {
			margin: 0;

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-radio-button-unchecked-white;
				width: 35px;
				height: 32px;
				background-repeat: no-repeat;
			}
		}

		&:checked ~ div {

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-radio-button-checked-white;
				width: 35px;
				height: 32px;
				background-repeat: no-repeat;
			}
		}

		// error state
		.l-form .is-error & ~ div {

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-radio-button-unchecked-red-white;
				width: 35px;
				height: 32px;
				background-repeat: no-repeat;
			}
		}

		.l-form .is-error &:checked ~ div {

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-radio-button-checked-red;
				width: 35px;
				height: 32px;
				background-repeat: no-repeat;
			}
		}

		.o-basic-form .is-error & ~ div {

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-radio-button-unchecked-red-white;
				width: 35px;
				height: 32px;
				background-repeat: no-repeat;
			}
		}

		.o-basic-form .is-error &:checked ~ div {

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-radio-button-checked-red-white;
				width: 35px;
				height: 32px;
				background-repeat: no-repeat;
			}
		}
	}
}