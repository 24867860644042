/* a-basic-input-text--copy */
.a-basic-input-text--copy {

	.l-form-general & input:disabled,
	.l-form-general & input {
		border-color: $color-secondary-14;
		background-color: $color-secondary-19;
		padding: 5px 40px 0 15px !important;
	}

	.aa-col-label {
		&:after {
			display: none;
		}
	}

	.js-clear-button {
		display: none;
	}

	& ~ .ll-check {
		display: none;
	}
}