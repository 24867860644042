/* .m-basic-hint--single-line */
.m-basic-hint--single-line {
	> img {
		position: absolute;
		top: 18px;
		left: 2px;

		@include mq(tablet) {
			top: 14px;
		}
	}
}