.m-vehicles-brands {

	> .h-h2 {
		margin-bottom: 16px;

		@include mq(tablet) {
			margin-bottom: 78px;
		}
	}

	> .swiper {
		> .swiper-wrapper {
			> .swiper-slide {
				width: 110px !important;
				padding: 1px;

				@include mq(tablet) {
					width: 160px !important;
				}

				> .mm-img {
					position: relative;
					width: 100px;
					height: 94px;
					margin-bottom: 20px;
					border-left: 3px solid transparent;
					border-right: 3px solid transparent;
					outline: 1px solid $color-secondary-4;


					@include mq(tablet) {
						height: 100px;
						width: 150px;
					}

					&:last-of-type {
						margin-bottom: 0;
					}

					&::before,
					&::after {
						content: "";
						position: absolute;
						background-color: transparent;
						height: 4px;
						width: 100%;
						display: block;
						z-index: 1;
					}

					&::before {
						top: 0;
					}

					&::after {
						bottom: 0;
					}

					&:hover,
					&.is-active {
						position: relative;
						border-left: 3px solid $color-primary-1;
						border-right: 3px solid $color-primary-1;
						cursor: pointer;
						outline: 1px solid $color-primary-1;

						&::before,
						&::after {
							content: "";
							position: absolute;
							background-color: $color-primary-1;
							height: 4px;
							width: 100%;
							display: block;
							z-index: 1;
						}

						&::before {
							top: 0;
						}

						&::after {
							bottom: 0;
						}

						> img {
							opacity: 1;
							border-color: transparent;
						}
					}

					> img {
						display: block;
						max-width: 100%;
						max-height: 100%;
						padding: 6px;

						@include absolute(50%, 50%);
						transform: translate(-50%, -50%);
					}
				}
			}
		}
	}
}