/* .a-basic-btn--package */
.a-basic-btn--package {
	@include milo-regular(16px);
	border: 1px solid $color-secondary-9;
	font-weight: lighter;
	margin-right: 5px;
	background: transparent;
	padding-left: 40px;
	padding-top: 5px;
	padding-bottom: 5px;
	min-height: 0;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: -6px;
		@include main-icon-check;
	}

	&:hover {
		background-color: $color-secondary-25;
		border: 1px solid $color-secondary-22;
	}

	&:focus,
	&:active {
		background-color: $color-secondary-25;
		border: 2px solid $color-secondary-22 !important;

		.is-keyboard-focus & {
			border: 2px solid $color-focus-tab;
			background-color: $color-secondary-25;
		}
	}

	&-no-icon {
		@include milo-regular(16px);
		border: 1px solid $color-secondary-9;
		background: transparent;
		font-weight: lighter;
		padding-top: 5px;
		padding-bottom: 5px;
		min-height: 0;

		.m-my-adac-dashboard-box .mm-box &,
		.m-my-adac-dashboard-box-showcase .mm-box & {
			cursor: initial;
		}
	}
}