/* a-basic-link--center */
.a-basic-link--info-layer {
	pointer-events: none;

	&:hover {
		text-decoration: none;
	}

	> a {
		text-decoration: none;
		pointer-events: auto;

		&:hover {
			text-decoration: underline;
		}
	}

	> .m-info-layer {
		margin-left: 10px;
		pointer-events: auto;
	}
}