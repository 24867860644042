#MapContainer {
	@include absolute($top: 0, $right: 0, $left: 0, $bottom: 0);
	background: $color-secondary-3;
	min-width: 280px;
	min-height: 200px;
	width: 100%;
	height: 100%;
}

.m-adac-maps .mm-map-wrapper .leaflet-pane.leaflet-overlay-pane img,
.m-adac-maps .mm-map-wrapper .leaflet-map-pane .leaflet-overlay-pane img {
	width: auto;
}