.m-basic-toggle--arrow-right {

	.mm-head {
		padding: 15px 43px 15px 9px;

		&:before {
			content: " ";
			position: absolute;
			right: -15px;
			left: unset;
			display: block;
			width: 24px;
			height: 24px;
			@include main-icon-arrow-right-grey;
			transform: rotate(90deg);
		}

		&.mm-opened {
			&:before {
				@include main-icon-arrow-right-grey;
				transform: rotate(270deg);
			}
		}
	}
}