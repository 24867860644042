/* .m-basic-hint--icon-only */
.m-basic-hint--icon-only {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 0;

	> img {
		position: relative;
		top: 0;
		left: 0;
	}
}