/* a-basic-stopper */
.a-basic-stopper {
	display: block;
	position: absolute;
	right: -10px;
	z-index: 30;
	top: 0;

	@include mq(tablet) {
		top: 10px;
	}

	@include mq(print) {
		page-break-inside: avoid;
	}

	@include if-parent('.a-basic-stopper--mgl-teaser') {
		right: -5px;

		// exception for m-basic-teaser-wrapper--product-advanced
		.m-basic-teaser-wrapper--product-advanced & {
			right: -10px;
		}

		// exception for m-basic-teaser-wrapper--product-disabled
		.m-basic-teaser-wrapper--product-disabled & {
			right: 0;
			z-index: 1;
		}
	}

	// exception for o-layout-main-stage
	.o-layout-main-stage & {
		right: 10px;
		margin-top: 10px;

		@include mq(tablet) {
			margin-top: initial;
			right: -10px;
		}
	}

	.l-form-general .m-basic-calculator-content-box-newform & {
		top: 20px;
		right: -45px;

		@include mq(tablet) {
			top: 25px;
			right: -45px;
		}
	}

	.a-basic-btn & {
		top: -50px;
		right: -70px;
	}

	.m-basic-calculator-content-box & {
		right: -45px;
		top: 20px;

		@include mq(tablet) {
			top: 25px;
		}
	}

	// sitecore fix
	.scLooseFrameZone {
		overflow-y: inherit;
	}

	.m-basic-image--full & {
		right: 10px;

		@include mq(tablet) {
			right: -10px;
		}

		@include mq(print) {
			right: 41px;
		}
	}

	.m-basic-image > figure > .mm-stopper-wrapper > & {
		top: 10px;
		bottom: auto;
	}

	// Exception for m-basic-image stopper-wrapper--bottom
	.m-basic-image > figure > .mm-stopper-wrapper--bottom > & {
		top: auto;
		bottom: 25px
	}


	// exception in m-basic-newsbox
	.m-basic-newsbox & {
		top: 20px;
		right: 0;
	}

	// exception in m-basic-newsbox
	.m-basic-teaser-product & {
		top: 10px;
		right: -5px;
	}

	.m-basic-teaser-product--banderole & {
		top: -209px !important;
	}

	.m-basic-newsbox--img & {
		right: -10px;

		@include mq(tablet) {
			top: 20px;
			right: 0;
		}
	}

	.m-basic-table--break & {
		margin-right: 0;

		@include mq(tablet) {
			margin-right: -68px;
		}
	}

	.m-basic-table--compare & {
		top: -50px;
		margin-right: -65px;

		@include mq(tablet) {
			margin-right: -90px;
		}
	}

	// exception fdl-calculator
	.m-fdl-calculator & {
		@include absolute($top: 10px, $right: -10px);

		@include mq(tablet) {
			top: 20px;
		}
	}

	.o-basic-cluster-box & {
		@include absolute($top: 28px, $right: -10px);

		@include mq(print) {
			@include absolute($top: 10px, $right: 0px);
		}
	}

	// exception m-basic-table
	.m-basic-table & {
		margin-right: -45px;
	}

	.m-basic-teaser-flex & {
		top: 20px;

		@include mq('tablet') {
			top: 10px;
		}
	}

	.aa-inner-content {
		vertical-align: middle;
		margin-left: 15px;
		margin-top: 0;
		position: relative;
		min-width: 70px;
		display: block;
		width: auto;
		height: auto;
		z-index: 10;
		background: $color-primary-2;
		border: 2px solid $color-secondary-1;
		border-top-left-radius: 12px;
		border-bottom-right-radius: 12px;
		border-bottom-left-radius: 12px;
		padding: 10px 8px 6px 10px;
		text-align: left;

		@include mq(tablet) {
			border-bottom-left-radius: 0;
			border-top-right-radius: 12px;
		}

		@include if-parent('.a-basic-stopper--mgl-teaser') {
			border-top-left-radius: 8px;
			border-top-right-radius: 0;
			border-bottom-right-radius: 8px;
			min-width: 50px;
			padding: 5px 10px;
			margin-top: 15px;

			@include mq(tablet) {
				border-top-right-radius: 8px;
			}
		}

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			width: 0;
			height: 0;
			border-style: solid;
		}

		&::before {
			border-width: 14px 13px 0 0;
			border-color: $color-secondary-1 transparent transparent transparent;
			top: -14px;
			right: -2px;
			transform: rotate(180deg);

			@include mq(tablet) {
				left: -2px;
				top: auto;
				bottom: -15px;
				right: initial;
				transform: initial;
			}
		}

		&:after {
			border-width: 9px 9px 0 0;
			border-color: $color-primary-2 transparent transparent transparent;
			right: 0;
			top: -8px;
			transform: rotate(180deg);

			@include mq(tablet) {
				left: 0;
				top: auto;
				bottom: -9px;
				right: initial;
				transform: initial;
			}
		}

		// Exception .o-layout-main-stage--align-left
		.o-layout-main-stage--align-left .oo-slide-content-wrapper > .oo-box > & {
			margin-left: 0;
			margin-right: 15px;

			@include mq(tablet) {
				margin-left: 15px;
				margin-right: 0;
			}
		}

		b,
		strong {
			@include milo-bold();

			.h-roboto & {
				font-family: 'Roboto Bold', sans-serif;
			}
		}

		@include mq(print) {
			-webkit-print-color-adjust: exact;
			box-shadow: inset 0 0 0 1000px white;
			right: 5px;
		}

		span {
			overflow: hidden;
			max-width: 100px;

			@include mq(print) {
				color: $color-primary-3;
			}
		}

		.aa-headline,
		.aa-content,
		.aa-thirdline {
			display: block;
			line-height: 1;
			margin: 0;
			padding: 0;
			@include font-size($font-size-12);

		}

		.aa-headline {
			max-height: 100%;
			margin: 0;
			color: $color-secondary-1;
			@include milo-regular($font-size-13);

			.h-roboto & {
				font-family: 'Roboto', sans-serif;
			}

			@include mq(print) {
				color: $color-primary-3;
			}

			&:empty {
				display: block;
				margin-top: -5px;
			}

			&.is-hidden {
				display: none;
			}

			.aa-highlight {
				@include milo-bold($font-size-20);

				.h-roboto & {
					font-family: 'Roboto Bold', sans-serif;
				}
			}
		}

		.aa-content {
			max-height: 100%;
			margin: 0;
			color: $color-secondary-1;
			@include milo-bold($font-size-20);

			.h-roboto & {
				font-family: 'Roboto Bold', sans-serif;
			}

			@include if-parent('.a-basic-stopper--mgl-teaser') {
				@include milo-bold(16px);

				@include mq(tablet) {
					@include milo-bold(18px);
				}
			}

			@include mq(print) {
				padding-left: 2px;
				color: $color-primary-3;
			}

			.aa-highlight {
				@include milo-bold($font-size-22);

				.h-roboto & {
					font-family: 'Roboto Bold', sans-serif;
				}
			}

			.aa-content-small {
				@include font-size($font-size-12);
			}

			.aa-content-smaller {
				@include milo-bold($font-size-16, 16px);
				margin: 0 0 5px 0;
			}

			.aa-content-thin {
				@include milo-regular($font-size-12);

				.h-roboto & {
					font-family: 'Roboto', sans-serif;
				}
			}
		}

		.aa-thirdline {
			max-height: 12px;
			margin: 0;
			padding-left: 0;
			text-align: left;
			@include milo-regular();
			color: $color-secondary-1;

			.h-roboto & {
				font-family: 'Roboto', sans-serif;
			}

			.oo-box--advantage-tourset-stage & {
				max-height: inherit;
			}

			.a-basic-btn & {
				max-height: none;
			}
		}

		.print-img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 54px;
			z-index: -1;
		}
	}

	&.h-space-s {
		margin-bottom: 0;
	}

	// ********************************************
	// *** Begin:: Exception in LayoutMainStage ***
	// ********************************************
	.o-layout-main-stage .swiper .swiper-wrapper .swiper-slide & {
		@include mq(tablet) {
			top: 30px;
		}
	}

	// exception in o-layer-main-stage--legalprotection
	.o-layout-main-stage--legalprotection .swiper .swiper-wrapper .swiper-slide .oo-box & {
		right: 20px;

		@include mq(tablet) {
			top: 20px;
			right: -10px;
		}
	}

	// exception in o-layer-main-stage .oo-box--teaser-center
	.o-layout-main-stage .swiper .swiper-wrapper .swiper-slide .oo-box--teaser-center & {
		top: -50px;
		right: 10px;

		@include mq(tablet) {
			right: -10px;
			top: 20px;
		}
	}

	// exception .oo-slide-content-wrapper (default || "--align-left" stage mod)
	.o-layout-main-stage .swiper .swiper-wrapper .swiper-slide .oo-slide-content-wrapper > .oo-box > & {
		top: 30px;
		left: auto;
		right: 0;

		@include mq(tablet) {
			top: 30px;
			left: 30px;
			right: auto;
		}

		@include mq(desktop) {
			left: auto;
			right: -10px;
		}
	}

	// exception .oo-slide-content-wrapper ("--align-right" stage mod)
	.o-layout-main-stage--align-right .swiper .swiper-wrapper .swiper-slide > .oo-slide-content-wrapper > .oo-box > & {
		left: 0;
		right: auto;

		@include mq(tablet) {
			top: 30px;
			left: auto;
			right: 30px;
		}

		@include mq(desktop) {
			right: -10px;
		}
	}

	// exception .oo-slide-content-wrapper--fade-overlay (default)
	.o-layout-main-stage .swiper .swiper-wrapper .swiper-slide > .oo-slide-content-wrapper.oo-slide-content-wrapper--fade-overlay > .oo-box > & {
		@include mq(desktop) {
			top: 15px;
			right: 30px;
			transform: translate(100%, 0);
		}
	}

	// exception .oo-slide-content-wrapper--fade-overlay (oo-box--blank)
	.o-layout-main-stage .swiper .swiper-wrapper .swiper-slide > .oo-slide-content-wrapper.oo-slide-content-wrapper--fade-overlay > .oo-box--blank > & {
		@include mq(desktop) {
			top: 35px;
			right: 15px;
		}
	}

	// exception .oo-slide-content-wrapper--overlay (default)
	.o-layout-main-stage .swiper .swiper-wrapper .swiper-slide > .oo-slide-content-wrapper.oo-slide-content-wrapper--overlay > .oo-box > & {
		top: 15px;
		left: 0;
		right: auto;

		@include mq(tablet) {
			top: 30px;
			left: auto;
			right: 30px;
		}

		@include mq(desktop) {
			top: 15px;
			left: auto;
			right: 15px;
			transform: translate(100%, 0);
		}
	}

	// exception .oo-slide-content-wrapper--overlay (no copy text)
	.o-layout-main-stage .swiper .swiper-wrapper .swiper-slide > .oo-slide-content-wrapper.oo-slide-content-wrapper--overlay > .oo-box > .a-basic-btn:first-child + & {

		@include mq(desktop) {
			position: static;
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;
			transform: none;
			display: inline-block;
			margin-left: 25px;
		}

	}

	// exception .oo-slide-content-wrapper--overlay ("--align-left" stage mod)
	.o-layout-main-stage--align-left .swiper .swiper-wrapper .swiper-slide > .oo-slide-content-wrapper.oo-slide-content-wrapper--overlay > .oo-box > & {
		top: 15px;
		left: auto;
		right: 0;

		@include mq(tablet) {
			top: 30px;
			left: 20px;
			right: auto;
		}

		@include mq(desktop) {
			top: 15px;
			left: auto;
			right: 25px;
			transform: translate(100%, 0);
		}
	}

	// exception .oo-slide-content-wrapper--overlay ("--align-right" stage mod)
	.o-layout-main-stage--align-right .swiper .swiper-wrapper .swiper-slide > .oo-slide-content-wrapper.oo-slide-content-wrapper--overlay > .oo-box > & {

		left: 5px;

		@include mq(tablet) {
			top: 30px;
			left: auto;
			right: 30px;
		}

		@include mq(desktop) {
			top: 15px;
			transform: translate(100%, 0);
		}
	}

	// exception .oo-slide-content-wrapper--overlay ("--align-right" stage mod && oo-box--blank)
	.o-layout-main-stage--align-right .swiper .swiper-wrapper .swiper-slide > .oo-slide-content-wrapper.oo-slide-content-wrapper--overlay > .oo-box--blank > & {
		@include mq(desktop) {
			right: 15px;
		}
	}

	// exception .oo-box--advantage-tourset-stage ("--advantage-tourset-stage" stage mod)
	.o-layout-main-stage .swiper .swiper-wrapper .swiper-slide > .oo-slide-content-wrapper > .oo-box--advantage-tourset-stage & {
		right: 20px;
		top: 30px;
		left: auto;

		@include mq(desktop) {
			right: -10px;
		}
	}

	// ******************************************
	// *** END:: Exception in LayoutMainStage ***
	// ******************************************

	// exception .m-basic-teaser-across
	.m-basic-teaser-across > article & {
		top: 130px;
		right: -5px;

		+ .mm-article-content .mm-header {
			width: calc(100% - 100px);
		}

		+ .mm-article-content .mm-copy {
			width: calc(100% - 100px);
		}
	}

	// exception .o-basic-banderole--mgldiscount inside of .m-basic-teaser-across
	.o-basic-banderole--mgldiscount .m-basic-teaser-across article & {
		top: auto;
		margin-top: 10px;
	}
}