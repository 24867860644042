/* m-basic-rating */
.m-basic-rating--ekomi {
	position: relative;
	min-height: 160px;
	background: $color-secondary-8;
	padding: 20px;

	> .mm-valid {

		> .mm-rating-content {
			display: flex;
			flex-direction: column;

			@include mq(tablet) {
				flex-direction: row;
			}

			> .mm-star-container {
				margin: 0 10px 10px 0;

				@include mq(tablet) {
					flex-grow: 1;
				}

				> .mm-label {
					font-weight: bolder;
				}

				> div {
					white-space: nowrap;

					&:not(.mm-label) {
						margin-left: -10px;
					}

					> .mm-star--ekomi {
						height: 26px;
						width: 26px;
						display: inline-block;

						&:before {
							position: absolute;
							content: '';
							margin-top: -10px;
							@include main-icon-star-00;
						}

						&-10 {
							&:before {
								@include main-icon-star-10;
							}
						}

						&-20 {
							&:before {
								@include main-icon-star-20;
							}
						}

						&-30 {
							&:before {
								@include main-icon-star-30;
							}
						}

						&-40 {
							&:before {
								@include main-icon-star-40;
							}
						}

						&-50 {
							&:before {
								@include main-icon-star-50;
							}
						}

						&-60 {
							&:before {
								@include main-icon-star-60;
							}
						}

						&-70 {
							&:before {
								@include main-icon-star-70;
							}
						}

						&-80 {
							&:before {
								@include main-icon-star-80;
							}
						}

						&-90 {
							&:before {
								@include main-icon-star-90;
							}
						}

						&-full {
							&:before {
								@include main-icon-star-full-black;
							}
						}
					}
				}
			}

			> .mm-quota {
				font-weight: bold;
				margin-bottom: 15px;
				margin-right: 10px;
				white-space: nowrap;

				@include mq(tablet) {
					margin-bottom: 0;
					flex-grow: 1;
				}

				> .mm-recommendation {
					margin-top: -4px;
					display: inline-block;
					@include font-size($font-size-20);


					@include mq(tablet) {
						@include font-size($font-size-28);
					}
				}

				> .mm-reviews {
					padding-left: 5px;
					font-weight: normal;
				}
			}

			> .mm-ekomi-logo {
				margin: 5px 0 10px 0;

				@include mq(tablet) {
					flex-grow: 1;
					position: relative;
					top: -10px;
					margin: 0;
				}
			}
		}
	}

	> .mm-fail {
		background-color: $color-action-alert;
		color: $color-primary-2;
		line-height: 1.125rem;
		margin: 8px 0 20px;
		min-height: 50px;
		padding: 12px 10px 6px 40px;
		position: relative;

		&:before {
			@include main-icon-error-symbol-white;
			content: '';
			display: block;
			left: 0;
			position: absolute;
			top: 0;
		}

		> p {
			margin: 0;
		}
	}

	> .mm-loader {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, 0.8);

		@include if-parent('.m-basic-rating--ekomi.is-loaded') {
			display: none;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 48px;
			height: 48px;
			margin: -40px 0 0 -34px;
			@include main-icon-refresh-48px;
			animation: rotate 1s infinite linear;

			@keyframes rotate {
				0% {
					transform: rotate(0deg);
				}

				100% {
					transform: rotate(360deg);
				}
			}
		}
	}
}