.a-basic-input-text--my-adac {

	> input {
		height: 34px;
		outline: none;
		border-top: none;
		border-right: none;
		border-left: none;
		border-bottom: 1px solid $color-secondary-9;
		@include font-size(18px, 22px);

		&:focus {
			border-top: none;
			border-right: none;
			border-left: none;
			border-bottom: 1px solid $color-secondary-9;

			@include placeholder {
				color: $color-secondary-8;
			}
		}
	}
}