/* a-basic-icon */
.a-basic-icon {

	&.h-space-s {
		margin-bottom: 0;
	}

	&.h-space-m,
	&.h-space-l {
		display: inline-block;
	}

	> img {
		position: relative;
		top: 8px;

		//exception l-form-general, l-form
		.l-form .ll-aside-head .ll-aside-edit &,
		.l-form-general .ll-aside-head .ll-aside-edit & {
			top: 2px;
		}
	}

	// exception when in ves-rs-sticky
	.mm-layer-contribution & {
		display: block;
		width: 100%;
		height: 100%;
	}

	// Exception for .a-basic-input-checkbox
	.a-basic-input-checkbox label & {
		position: relative;
		display: inline-block;
		width: 24px;

		> img {
			position: absolute;
			top: -16px;
		}
	}

	.m-basic-form-footer-sticky & {
		padding-left: 28px;
		position: relative;

		> img {
			position: absolute;
			left: 0;
			top: -4px;
		}
	}

	// exception for .m-basic-text
	.m-basic-text & {
		> img {
			position: relative;
			top: 8px;
		}
	}

}