// m-basic-teaser-product--banderole
.m-basic-teaser-product--banderole {
	margin: 0;
	padding: 0;
	border: none;

	> .mm-wrap {
		margin: 0;

		> .mm-box {
			margin: 0 15px 0 0;

			> .mm-main {
				background: none;

				> .mm-top {
					height: inherit;
					border: none;

					> header {

						> h2 {
							padding: 0 20px 0 0;

							> a {
								margin: 0;
								padding: 0;
								@include font-size($font-size-15);
								line-height: 1;
								text-decoration: none;
							}
						}
					}
				}
			}
		}
	}
}