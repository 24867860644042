/* a-basic-input-select--m */
.a-basic-input-select--m {

	width: calc(50% - #{0.5 * $form-gutter});

	// we are after M
	.a-basic-input-select--m + & {
		float: right;
	}

	// exception .m-basic-calculator-content-box .mm-row if a-basic-input-select--m is only input
	// to align with date input in next row
	.m-basic-calculator-content-box .mm-form-container > .mm-fieldset > .mm-row > .mm-inputcontainer > &:only-of-type {

		width: 120px;

		@include mq(tablet) {
			width: 140px;
		}

		.is-touch & {
			width: 100px;

			@include mq(tablet) {
				width: 140px;
			}
		}
	}
}