.o-layout-ves-stage--image-addons {
	> .oo-wrapper {
		> .oo-item {
			&--content {
				@include mq(desktop) {
					position: static;
					padding-right: 120px;
				}

				> .oo-text {
					@include mq(desktop) {
						padding-right: 0;
					}
				}

				> .oo-stopper {
					@include mq(desktop) {
						right: calc(50% + 50px);
						transform: none;
						top: 20px;
					}

					@include if-parent('.o-layout-ves-stage--image-addons.o-layout-ves-stage--image-right') {
						@include mq(desktop) {
							transform: none;
							right: 50px;
						}
					}
				}

				> .oo-siegel {
					@include if-parent('.o-layout-ves-stage--image-addons:not(.o-layout-ves-stage--image-right)') {
						@include mq(desktop) {
							transform: translateX(-40px);
							right: 50%;
						}
					}
				}
			}
		}
	}
}