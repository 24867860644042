/* m-basic-text */
.m-basic-text {
	@include mq(tablet) {

		// Exception
		.o-basic-form .oo-row & {
			padding-right: 0;
		}

		// Exception layerli
		.l-lightbox & {
			padding-right: 50px;

			> .mm-content {
				&.h-space-none {
					margin-bottom: 0;
				}

				> p {
					@include milo-regular($font-size-16);

					.h-roboto & {
						font-family: 'Roboto', sans-serif;
					}
				}
			}
		}
	}

	&.h-space-s {
		margin-bottom: 0;

		// exception for cardamage-map
		.l-form--cardamage-map .ll-ll-row & {
			margin-bottom: 12px;
		}

		// exception .autocomplete-suggestion as no result
		.autocomplete-suggestion.no-result > & {
			margin-bottom: 15px;
		}

		// exception m-basic-hint--flex-row
		.m-basic-hint--flex-row & {
			margin-bottom: 15px;
		}
	}

	&.h-space-m {
		margin-bottom: 20px;
	}

	&.h-space-l {
		margin-bottom: 40px;
	}

	&.h-space-xl {
		margin-bottom: 60px;
	}

	//exception l-form-general
	.l-form-general .ll-col-data & {
		&:not(:first-child) {
			padding-top: 8px;
		}
	}


	// exception when in aside
	.a-basic-btn.h-space-s + & {
		margin-top: 7px;

		// exception for myAdac
		@include if-parent('.m-basic-text--my-adac') {
			margin: 0 0 12px 0;
		}
	}

	// exception when in aside
	.ll-aside-content & h1 {
		padding-bottom: 0;
	}

	// exception when used instead of an input field in col-data
	.oo-col-label + .oo-col-data &,
	.ll-col-label + .ll-col-data & {
		padding-top: 8px;
	}

	// exception when in checkbox which is in a fieldset
	.l-form .ll-fieldset .a-basic-input-checkbox & {
		margin-left: 0;
	}

	// exceptionf
	.l-form .ll-fieldset .l-main-cols &,
	.l-form .ll-fieldset .ll-description & {
		margin-left: 0;
	}

	// exception ll-main-content-switch
	.ll-main-content-switch & {
		margin-right: $l-main-content-padding-side;

		@include mq(tablet) {
			margin-right: $l-main-content-padding-side-tablet;
		}

		@include mq(desktop) {
			margin-right: $l-main-content-padding-side-desktop;
		}
	}

	// exception molecule m-basic-hint
	.m-basic-hint & {
		padding-top: 4px;

		.ll-depend.is-open & {
			display: flex;
		}

		+ .a-basic-link {
			margin-top: 10px;
		}
	}

	// exception .ll-main-chatbot
	.ll-main-chatbot.is-activated & {
		display: none;
	}

	// exception molecule m-basic-hint
	.m-basic-hint--no-text-padding & {
		padding-top: 0;
	}

	// exception molecule m-basic-hint
	.m-basic-hin--big & {
		padding-top: 0;
	}

	// exception molecule m-basic-hint
	.m-basic-hint & .mm-content:first-child {
		padding-top: 0;
	}

	.m-basic-hint & .mm-content:only-child {
		margin-bottom: 0;
	}

	// exception molecule m-basic-hint--address
	.m-basic-hint--address & .mm-content {
		> p {
			> a {
				text-decoration: none;
			}
		}
	}

	// Exception: m-basic-teaser-product
	.m-basic-teaser-product & {
		@include line-height($font-size-18);
	}

	// exception for hr.yellow
	hr.yellow {
		margin: 6px 0 14px 0;
	}

	// exception m-adac-maps .mm-country-informations-head
	.m-adac-maps .mm-country-informations-head & {
		padding-right: 50px;
	}

	// Exception: m-basic-cookie
	.m-basic-cookie > .m-cookie-center > & {

		> a {
			@include milo-regular($font-size-14);

			.h-roboto & {
				font-family: 'Roboto', sans-serif;
			}
		}
	}

	> .mm-stopper-wrapper {
		padding-right: 80px;
		position: relative;
	}

	> p {
		max-width: 700px;

		// no need for an max-width, all content is in limited wrapper
		.l-outer--my-adac-content &,
		.m-basic-teaser-box & {
			max-width: 100%;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.m-basic-sticky-service-contact & {
			margin-bottom: 10px;
		}

		.m-basic-hint--padding & {
			&:first-child {
				padding-top: 6px;
			}
		}
	}

	.mm-dependent-content {
		display: none;

		&--ios {
			.l-outer & {
				display: none;
			}

			.l-outer.is-ios & {
				display: inline;

				@include mq(desktop) {
					display: none;
				}
			}
		}

		&--android {
			.l-outer & {
				display: none;
			}

			.l-outer.is-touch:not(.is-ios) & {
				display: inline;

				@include mq(desktop) {
					display: none;
				}
			}
		}

		&--desktop {
			.l-outer.is-touch & {
				display: none;

				@include mq(desktop) {
					display: inline;
				}
			}

			.l-outer & {
				display: inline;
			}
		}
	}

	.mm-content {
		max-width: 700px;
		margin: 0 0 50px 0;

		&--m {
			max-width: 760px;
		}

		&--l {
			max-width: 100%;

			> p {
				max-width: none !important;
			}

			> h3 {
				max-width: none;
			}
		}

		&--half {
			@include mq(tablet) {
				width: calc(50% - 40px);
			}
		}

		&--flex-row {
			display: flex;
			flex-flow: column nowrap;

			@include mq(tablet) {
				flex-flow: row nowrap;
			}
		}

		&.h-space-none {
			margin-bottom: 0;
		}

		&.h-space-s {
			margin-bottom: 15px;
		}

		&.h-space-m {
			margin-bottom: 30px;
		}

		&.h-space-l {
			margin-bottom: 60px;
		}

		// myAdac Dashboard - for RTE-Richtext content
		.l-outer--my-adac & {
			margin: 0 0 24px 0;
		}

		// exception for .ll-main-content-border
		.l-form-general .ll-main-content-border & {
			margin-bottom: 0;
		}

		// exception for .ll-main-content-border width two .mm-content's
		.l-form-general .ll-main-content-border .mm-content & {
			padding-top: 5px;
		}

		.m-basic-teaser-box & {
			max-width: 100%;
		}

		// exception basicTeaserWrapper
		.mm-teaser-box & {
			margin: 0;
		}

		.m-basic-hint--btnbox & {
			margin-top: 15px;
		}

		// exception molecule m-basic-teaser-dashboard
		.m-basic-teaser-dashboard & {

			margin-bottom: 20px;

			> p {
				> a {

					&:not(:hover) {
						text-decoration: none;
					}

				}
			}
		}

		// Exception for m-newsletter-category
		.mm-newsletter-theme & {
			margin-bottom: 0;
			display: none;

			@include mq(tablet) {
				display: block;
			}

			&.is-open {
				display: block;
			}
		}

		// exception ves rs sticky bar
		.mm-layer-inner & {
			margin-bottom: 8px;
		}

		// exception organism o-basic-form
		.o-basic-form & {
			margin-bottom: 17px;
		}

		// exception molecule m-basic-hint
		.m-basic-hint &:last-child {
			margin-bottom: 0;
		}

		// exception molecule m-basic-hint
		.m-basic-hint & {

			> span.h-h3:not(:only-child) {
				float: left;
				padding-bottom: 0;
				padding-right: 10px;
			}

		}

		> ol {

			.h-print-only .l-form-general .ll-main-content-border &,
			.h-print-showcase .l-form-general .ll-main-content-border & {
				margin-bottom: 0
			}
		}

		> .mm-cnt-headline {

			@include if-parent('.mm-content--half') {
				text-align: right;
			}
		}

		> .mm-cnt-headline-note {
			margin-bottom: 5px;
		}

		.mm-cnt-headline-left-small {
			font-size: $font-size-16;
		}

		.mm-cnt-headline-right {
			float: right;

			@include if-parent('.mm-content--half') {
				float: initial;
			}

			.mm-cnt-headline-right-small {
				font-size: $font-size-16;
			}
		}

		// exception molecule m-ves-rs-contentbox
		.m-ves-rs-contentbox & {

			margin: 25px 0 0 0;

			@include mq(mobile-max) {
				margin-bottom: 25px;
			}

			& + .mm-content {
				margin-bottom: 50px;
			}
		}

		// exception molecule m-adac-maps modifier --mgldiscountmaps
		.m-adac-maps--mgldiscountmaps .mm-result-list-wrap & {
			margin-bottom: 0;
		}

		p {
			max-width: 700px;
			margin: 0 0 20px 0;

			.m-basic-teaser-box & {
				max-width: 100%;
			}

			.m-adac-maps & {
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;

				@include mq(tablet) {
					flex-flow: column nowrap;
				}

				@include mq(desktop) {
					flex-flow: row nowrap;
				}
			}

			&:last-child {
				margin-bottom: 0;

				.m-adac-maps--mgldiscountmaps .mm-result-list-wrap & {
					margin-bottom: 10px;
				}
			}

			@include if-parent('.mm-content.mm-content--color-light') {
				color: $color-secondary-21;
			}

			// exception if wrapping is not allowed
			&.has-no-wrap {
				.m-adac-maps & {
					flex-wrap: nowrap;

					@include mq(tablet) {
						flex-direction: row;
					}
				}
			}

			> .mm-prod-name {
				@include milo-bold();
				display: block;
				margin-bottom: 5px;
			}

			> a {

				// exception for a-basic-btn
				&:not(.a-basic-btn):not(.a-basic-link) {
					text-decoration: underline;
				}
			}

			> img {
				.m-adac-maps & {
					width: 161px;
					height: auto;
					margin: 6px 20px 7px 0;
				}
			}

			> span {

				// exception molecule m-adac-maps modifier --mgldiscountmaps
				.m-adac-maps--mgldiscountmaps .mm-result-list-wrap & {
					@include mq(desktop) {
						max-width: calc(100% - 181px);
						overflow: hidden;
					}
				}

				.m-adac-maps--mgldiscountmaps .mm-result-list-wrap .l-main-cols & {
					@include mq(desktop) {
						max-width: 100%;
					}
				}
			}
		}

		.mm-multiple-buttons {
			display: flex;
			flex-wrap: wrap;
			column-gap: 20px;
		}

		> ul {
			margin: 0 0 20px 0;
			overflow: hidden;

			&:not(.m-basic-list) {
				padding: 0;
			}

			// exception molecule m-basic-hint
			.m-basic-hint & {
				padding-top: 4px;

				@include mq(tablet) {
					padding-top: 8px;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}

			// exception basicTeaserWrapper
			.mm-teaser-box & {
				margin: 0 0 12px 0;
			}

			// exception molecule m-basic-footnote in m-basic-hint
			&.m-basic-footnote {
				padding: 0;

				> li {
					max-width: 700px;
					background: 0;
					padding-left: 10px;

					&.mm-hint {
						position: absolute;
						padding: 0;
						background: 0;
						// pages-fdl-examples-carcredit
						display: none;
					}
				}
			}

			> li {
				position: relative;
				max-width: 700px;
				margin: 0 0 8px 0;
				@include line-height(18px);

				@include if-parent('.m-basic-text .mm-content ul:not(.m-basic-list)') {
					padding: 0 0 0 20px;

					&:before {
						content: '';
						@include main-icon-bullet;
						position: absolute;
						top: -14px;
						left: -9px;
					}
				}

				.m-basic-teaser-box & {
					max-width: 100%;
				}

				@include mq(print) {
					padding-left: 10px;

					&:before {
						content: "●";
						font-size: 10px;
						position: absolute;
						left: 1px;
						top: -1px;
					}
				}

				// exception m-basic-list--yellow
				@include if-parent('ul.m-basic-list--yellow') {
					margin: 0 0 10px 0;

				}

				// exception molecule m-basic-hint
				.m-basic-hint & {
					left: -10px;

					@include mq(tablet) {
						left: 0;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}

				&.mm-last {
					margin-bottom: 0;
				}

				> a {
					text-decoration: underline;
				}

				> ul {
					padding: 12px 0 0 12px;

					> li {
						position: relative;
						margin: 0 0 8px 0;
						padding: 0 0 0 11px;

						&:before {
							content: '';
							@include main-icon-bullet;
							position: absolute;
							top: -14px;
							left: -18px;
						}

						@include mq(print) {
							background: none;

							&:before {
								content: "●";
								font-size: 10px;
								position: absolute;
								left: 1px;
								top: -1px;
							}
						}

						> a {
							text-decoration: underline;
						}
					}
				}
			}
		}

		> h3 {
			@include milo-slab-bold();
		}
	}

	.mm-flex {
		@include mq(tablet) {
			display: flex;
			flex: 0 0 auto;
		}

		&--row-reverse {
			@include mq(tablet) {
				flex-direction: row-reverse;
				display: flex;
			}

			h4 {
				@include milo-bold($font-size-16);
				margin-bottom: 14px;

				.h-roboto & {
					font-family: 'Roboto Bold', sans-serif;
				}

				@include mq(tablet) {
					margin-bottom: 4px;
				}
			}
		}

		&--reverse {
			flex-direction: column-reverse;
			display: flex;

			@include mq(tablet) {
				flex-direction: row-reverse;
			}

			h4 {
				@include milo-bold($font-size-16);
				margin-bottom: 14px;

				.h-roboto & {
					font-family: 'Roboto Bold', sans-serif;
				}

				@include mq(tablet) {
					margin-bottom: 4px;
				}
			}
		}

		.h-print-only .ll-main-content-border &,
		.h-print-showcase .ll-main-content-border & {
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		// second stage
		.h-print-only .ll-main-content-border .m-basic-text &,
		.h-print-showcase .ll-main-content-border .m-basic-text & {
			flex-direction: row;
			display: flex;
			align-items: center;

			@include mq(tablet) {
				align-items: flex-start;
			}
		}

		// exception .ll-main-chatbot
		.ll-main-chatbot & {
			@include mq(tablet) {
				display: flex;
				align-items: center;
			}
		}

		.mm-aside {
			position: relative;
			padding-top: 4px;

			@include mq(tablet) {
				padding: 4px 0 0 0;
				margin: 0 20px 0 0;
				flex: none;
				max-width: 50%;

				@include if-parent(".mm-flex--row-reverse") {
					margin-right: 0;
					margin-left: 20px;
				}
			}

			@include mq(desktop) {
				margin: 0 40px 0 0;

				@include if-parent(".mm-flex--reverse") {
					margin-right: 0;
					margin-left: 40px;
				}
			}

			.h-print-only .ll-main-content-border &,
			.h-print-showcase .ll-main-content-border & {
				display: flex;
				justify-content: center;
				margin-right: 0;

				@include mq(tablet) {
					display: inherit;
				}
			}

			// second stage
			.h-print-only .ll-main-content-border .m-basic-text &,
			.h-print-showcase .ll-main-content-border .m-basic-text & {
				min-width: 40%;
				width: auto;
			}

			> figure {
				margin: 0 0 12px 0;
				position: relative;

				.h-print-only .ll-main-content-border &,
				.h-print-showcase .ll-main-content-border & {
					max-width: 50%;

					@include mq(tablet) {
						max-width: initial;
					}
				}

				.mm-captionright {
					text-align: right;
				}

				> a {
					position: relative;
					display: block;

					.mm-zoom {
						position: absolute;
						bottom: 0;
						right: 0;
						width: 30px;
						height: 30px;
						background-color: rgba(255, 255, 255, .7);
						text-align: center;
						padding: 2px 0 0 0;
						z-index: 1;

						&:after {
							content: '';
							@include main-icon-zoom-in;
							position: absolute;
							left: -7px;
							top: -8px;
						}

						> img {
							display: none;
						}
					}
				}

				> figcaption {
					padding: 4px 0 0;
					@include font-size($font-size-13);
				}


				.mm-branding {
					position: absolute;
					top: 10px;
					right: 9px;
					width: 150px;
					height: auto;

					&--white {
						background-color: $color-primary-2;
					}

					> img {
						display: block;
						max-width: 100%;
					}
				}

				.mm-ratio-container {
					position: relative;

					&:not(.is-lazyloaded) {
						background: $color-secondary-6;

						&::before {
							animation: swiper-preloader-spin 1s steps(12, end) infinite;
							display: block;
							z-index: 100;
							position: absolute;
							top: calc(50% - 34px);
							left: calc(50% - 34px);
							content: '';
							opacity: 1;
							transition: opacity 300ms;
							@include main-icon-refresh-48px;
						}
					}

					> picture {
						display: block;

						> img {
							&:not([src="#"]) {
								height: auto;
								display: block;
								min-width: 100%;
								max-width: 100%;
							}
						}
					}
				}
			}
		}

		.mm-content {
			max-width: inherit;

			&--half {
				@include mq(tablet) {
					width: calc(50% - 40px);
				}

				@include if-parent('.m-basic-text.m-basic-text--right .mm-flex') {
					width: calc(50% - 40px);
				}
			}

			.h-print-only .ll-main-content-border .m-basic-text &,
			.h-print-showcase .ll-main-content-border .m-basic-text & {
				min-width: 40%;
				width: auto;
			}

			> p {
				max-width: inherit;
			}
		}
	}

	> .mm-aside {
		@include mq(tablet) {
			float: left;
			padding-top: 5px;
			margin-right: 20px;
			max-width: 50%;
		}

		@include mq(desktop) {
			margin-right: 40px;
		}

		+ .mm-content {
			max-width: inherit;

			> p {
				max-width: inherit;
			}
		}
	}

	.mm-aside--right {
		@include mq(tablet) {
			float: right;
			margin: 0;
			margin-right: -30px;
		}

		@include mq(desktop) {
			margin-right: 0;
		}

		+ .mm-content {
			@include mq(tablet) {
				width: inherit;
				float: none;
			}
		}
	}

	// Exception
	.m-basic-hint--address & {
		@include mq(desktop) {
			float: left;
			width: 65%;
			margin: 0 5% 0 0;
		}
	}

	// exception organsim o-basic-form
	.o-basic-form & {
		padding-right: 30px;

		@include mq(tablet) {
			padding-right: 0;
		}

		// exception in l-lightbox
		.l-lightbox & {
			padding-right: 0;
		}
	}

	// ##### exceptions #####
	// molecule m-basic-hint
	.m-basic-hint & > h2,
	.m-basic-hint & > h3,
	.m-basic-hint & > h4 {
		padding: 0 0 3px 0;
		@include milo-slab-bold($font-size-21, 24px);
		font-weight: normal;

		.h-roboto & {
			font-family: 'Roboto Bold', sans-serif;
		}

		@include mq(tablet) {
			padding-bottom: 4px;
			padding-top: 3px;
			@include font-size($font-size-24, 27px);
		}

		> a {
			text-decoration: none;
			@include milo-slab-bold($font-size-21, 24px);

			@include mq(tablet) {
				@include font-size($font-size-24, 27px);
			}

			.h-roboto & {
				font-family: 'Roboto Bold', sans-serif;
			}
		}
	}

	.m-basic-hint & > h5 {
		margin: 0;
		font-size: 20px;
	}

	.m-basic-hint--big & > h2,
	.m-basic-hint--big & > h3 {
		@include mq(tablet) {
			padding-top: 3px;
		}
	}

	.m-basic-hint--background & > h2,
	.m-basic-hint--background & > h3 {
		@include milo-slab-bold();

		.h-roboto & {
			font-family: 'Roboto Bold', sans-serif;
		}
	}

	.m-basic-hint--center & > h2,
	.m-basic-hint--center & > h3 {
		margin-bottom: 10px;
	}

	.m-basic-newsbox + .m-basic-hint & > h4 {
		@include milo-regular($font-size-17, 20px);

		.h-roboto & {
			font-family: 'Roboto Bold', sans-serif;
		}

		@include mq(tablet) {
			padding-top: 5px;
			@include font-size($font-size-20, 23px);
		}
	}


	// lightbox-layer
	.l-lightbox .mfp-content > div:first-child &:first-child > h2 {
		&:first-child {
			// Selects the first h2 in the first div if a h2 is set out of components.
			@include font-size($font-size-24, 29px);
			padding-bottom: 28px;

			@include mq(tablet) {
				@include font-size($font-size-36, 40px);
			}
		}
	}

	// lightbox-layer
	.l-lightbox .mfp-content > div:first-child &--my-adac:first-child > h2 {
		&:first-child {
			@include milo-slab-bold(18px, 22px);

			.h-roboto & {
				font-family: 'Roboto Bold', sans-serif;
			}

			@include mq(tablet) {
				@include milo-slab-bold(24px, 32px);

				.h-roboto & {
					font-family: 'Roboto Bold', sans-serif;
				}
			}
		}
	}

	// exception basicrating-ekomi-ves
	.m-basic-rating--ekomi-ves .mm-headline & {
		> h2 {
			@include font-size($font-size-22);

			@include mq (tablet) {
				@include font-size($font-size-28);
			}
		}
	}

	.l-lightbox & .mm-content {
		margin-bottom: 21px;
	}

	// exception when align right needed
	.l-lightbox .ll-main-cols--right & {
		padding-right: 40px;
	}

	// exception allow bottom space inside of lightbox
	.ll-lightbox-inner > & {
		&.h-space-s {
			margin-bottom: 15px;
		}
	}

	// exception when inside m-ves-rs-contentbox
	.m-ves-rs-contentbox & {
		@include mq(tablet) {
			margin-top: 10px;
		}
	}

	// exception when in ves-rs-sticky
	.mm-layer-details-inner & {
		margin-bottom: 10px;

		> h3 {
			padding-bottom: 0;
		}

		> .mm-shift {
			margin-left: 10px;

			> .mm-total-price {
				float: right;
				@include milo-bold();
				font-weight: normal;
				margin-right: 10px;

				.h-roboto & {
					font-family: 'Roboto Bold', sans-serif;
				}
			}
		}

		small {
			@include font-size($font-size-13);
		}
	}

	// exception if .m-basic-hint--background has a headline
	.m-basic-hint--background:not(.m-basic-hint--big) & {
		@include mq (tablet) {

			> h2,
			h3,
			h4 {
				margin-top: -7px;
			}
		}
	}

	// exception if .m-basic-hint has a headline
	.m-basic-hint:not(.m-basic-hint--big):not(.m-basic-hint--background):not(.m-basic-hint--padding):not(.m-basic-hint--outline) & {
		@include mq (tablet) {

			> h2,
			h3,
			h4 {
				margin-top: -5px;
			}
		}
	}

	// exception when in .m-basic-hint-background
	.m-basic-hint--background & {
		> h2 {
			@include milo-slab-bold($font-size-20);
			margin-bottom: 9px;

			.h-roboto & {
				font-family: 'Roboto Bold', sans-serif;
			}

			@include mq(tablet) {
				@include milo-slab-bold($font-size-30);

				.h-roboto & {
					font-family: 'Roboto Bold', sans-serif;
				}
			}
		}

		> .mm-content,
		> .mm-content:last-child {
			margin-bottom: 6px;
		}

		> .mm-content {
			max-width: 100%;
		}
	}

	// exception ves rs stickybar
	.mm-layer & {
		> h3 {
			max-width: calc(100% - 40px);

			@include mq(tablet) {
				max-width: 100%;
			}
		}
	}

	// exception for .l-form--cardamage-map ll-aside
	.l-form--cardamage-map .ll-aside &,
	.l-form-general--cardamage-map .ll-aside & {
		> a {
			&[href^="tel:"] {

				@include milo-bold($font-size-18, 23px);
				text-decoration: none;

				.h-roboto & {
					font-family: 'Roboto Bold', sans-serif;
				}

				@include mq(tablet) {
					@include font-size($font-size-22, 26px);
				}
			}
		}
	}

	// exception for inspection services
	&--inspection-tester-text > .mm-content {
		margin-bottom: 25px;
	}

	// exception .o-layout-main-stage--teaser
	.o-layout-main-stage--teaser .oo-box--teaser-center > .oo-button-box & {
		&.h-space-s {
			margin-bottom: 10px;

			@include mq(tablet) {
				margin-bottom: 0;
			}
		}
	}

	// exception for lightbox layer footnote
	&--footnote {

		> .mm-content {
			max-width: 100%;

			> p {
				margin-bottom: 10px;
				line-height: 90%;
				max-width: 100%;

				&.mm-icon {
					margin-left: 25px;
					position: relative;

					&--advantage {
						&:before {
							@include absolute($left: -35px);
							content: "";
							@include main-icon-check;
							padding: 0;
							top: -16px;
							transform: scale(0.7);
						}
					}

					&--disadvantage {
						&:before {
							@include absolute($left: -20px);
							content: "–";
							top: -2px;
						}
					}
				}
			}
		}
	}

	> h1,
	> h2,
	> h3 {
		> .mm-number {
			@include milo-bold(12px);
			display: inline-block;
			width: 20px;
			height: 20px;
			text-align: center;
			background: $color-primary-1;
			border-radius: 50%;
			padding-top: 2px;
			margin-left: 5px;

			> span {
				display: block;
				line-height: 27px;
				position: relative;
				top: -6px;
			}
		}
	}

	> h1 {
		width: 100%;

		@include mq(tablet) {
			max-width: 710px;
		}

		&:only-child {
			margin-bottom: 0;
		}

		&.mm-full {
			max-width: none;
		}

		&.mm-left {
			text-align: left;
		}

		> .oo-box-headline-registered-sign {
			@include font-size($font-size-16, 20px);

			@include mq(tablet) {
				@include font-size($font-size-24, 44px);
			}

			@include mq(desktop) {
				@include font-size(26px, 48px);
			}
		}

		// exception basicrating if it stands next to headline
		.a-basic-rating + & {
			$ratingWidth: calc(100px + 10px);

			@include mq(tablet) {
				max-width: calc(100% - #{$ratingWidth});
			}
		}
	}

	// exception for m-vehicles-results
	.m-vehicles-results > .mm-results-row > .mm-image-col > & {
		display: inline-block;
		width: 50%;
	}

	// exception for m-basic-hint--boldtext
	.m-basic-hint--boldtext & {
		@include milo-slab-bold();
		@include font-size(19px);

		> span {
			@include milo-regular();
			@include font-size(14px);
		}
	}

	&--chart-headline {
		> h3 {
			margin: 0 auto;
			text-align: center;
		}

		.l-main-cols & {
			> h3 {
				padding: 4.5px 0 0 0;

				@include mq(tablet) {
					padding: 3px 0 0 0;
				}
			}
		}
	}

	small {
		a {
			@include font-size($font-size-13);
		}

		&.m-grey {
			color: $color-secondary-13;
		}
	}

	span {
		&.mm-grey {
			color: $color-secondary-13;
		}
	}

	table {
		@extend %basic-table-paddings;
	}
}