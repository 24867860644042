/* a-basic-link--my-adac */
.a-basic-link--my-adac-icon-before {
	position: relative;
	width: 100%;
	margin: 0;
	padding: 0 25px 5px 48px;
	background: none;
	text-decoration: none;
	display: block;
	outline: none;
	@include font-size(16px, 20px);

	.a-basic-btn--inline + & {
		width: auto;
		margin-left: 40px
	}

	&:before {
		position: absolute;
		content: '';
		display: block;
		@include main-icon-arrow-right-grey2;
		top: -2px;
		left: -8px;

		.m-basic-text & {
			top: -10px;

			@include mq(desktop) {
				top: -7px;
			}
		}
	}

	&:after {
		content: none;
		display: block;
	}

	&:hover {
		text-decoration: none;
	}

	&:visited {
		color: $color-secondary-1;
	}

	.m-basic-text .mm-content & {
		left: -6px;
	}

	.m-basic-hint--my-adac & {
		left: 0;
	}

	> .aa-link-icon-wrapper {
		position: absolute;
		left: 0;
		top: 12px;
		width: 24px;
		height: 24px;
		background-size: 100%;
		max-height: 45px;
	}

	&.a-basic-link--my-adac-icon-before-content {
		left: -8px;
	}
}