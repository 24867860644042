.m-basic-pie-chart {

	> .mm-pie-chart-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		max-width: 290px;
		margin: 0 auto;

		@include mq(tablet) {
			max-width: none;
		}

		@include if-parent('.m-basic-pie-chart--headline') {
			display: block;
			max-width: none;
		}

		@include if-parent('.m-basic-pie-chart--headline-double') {
			display: block;
			max-width: none;
		}

		> .mm-pie {
			display: inline-block;
			margin-bottom: 40px;

			@include if-parent('.m-basic-pie-chart--headline') {
				display: flex;
				justify-content: space-between;
				width: 100%;
				max-width: 555px;
			}

			@include if-parent('.m-basic-pie-chart--headline-double') {
				display: flex;
				justify-content: space-between;
				width: 100%;
				max-width: 555px;
				margin-bottom: 20px;
			}

			&:nth-child(odd) {
				margin-right: 30px;
			}

			@include mq(tablet) {
				margin-right: 0;

				&:not(:last-child) {
					margin-right: 30px;
				}
			}

			> .mm-headline {
				display: none;

				@include if-parent('.m-basic-pie-chart--headline') {
					display: flex;
					align-items: center;
				}

				@include if-parent('.m-basic-pie-chart--headline-double') {
					display: flex;
					align-items: center;
				}

				> .mm-headline-wrapper {
					> h3 {
						font-size: $font-size-18;

						@include mq('tablet') {
							font-size: $font-size-24;
						}
					}
				}

				> .mm-icon {
					margin-right: 20px;
					width: 65px;
					height: 65px;
					flex-shrink: 0;
					background-color: $color-secondary-18; //fallback if no color is chosen
					border-radius: 8px;

					@include if-parent('.mm-pie--yellow') {
						background-color: $color-pie-chart-1;
					}

					@include if-parent('.mm-pie--blue') {
						background-color: $color-pie-chart-2;
					}

					@include if-parent('.mm-pie--green') {
						background-color: $color-pie-chart-3;
					}

					@include if-parent('.mm-pie--lilac') {
						background-color: $color-pie-chart-4;
					}

					@include if-parent('.m-basic-pie-chart--headline-double .mm-pie:not(:first-child)') {
						background-color: transparent;
					}

					> img {
						display: block;
						margin: 50% auto 0 auto;
						transform: translate(0, -50%);
					}
				}
			}

			> .mm-pie-chart {

				> .mm-chart {
					position: relative;
					margin-left: 10px;

					@include if-parent('.m-basic-pie-chart--headline') {
						max-width: 65px;
						max-height: 65px;
					}

					@include if-parent('.m-basic-pie-chart--headline-double') {
						max-width: 65px;
						max-height: 65px;
					}

					.mm-percent {
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						font-weight: bold;
						font-size: 29px;
						white-space: nowrap;
						max-width: 120px;

						@include if-parent('.m-basic-pie-chart--headline') {
							font-size: 15px;
						}

						@include if-parent('.m-basic-pie-chart--headline-double') {
							font-size: 15px;
						}
					}

					> svg {
						display: block;
						margin: 0 auto;

						@include if-parent('.m-basic-pie-chart--headline') {
							max-width: 65px;
							max-height: 65px;
						}

						@include if-parent('.m-basic-pie-chart--headline-double') {
							max-width: 65px;
							max-height: 65px;
						}

						path {
							fill: $color-secondary-9;

							&.is-filled {
								fill: $color-secondary-18; //fallback if no color is chosen

								@include if-parent('.mm-pie--yellow') {
									fill: $color-pie-chart-1;
								}

								@include if-parent('.mm-pie--blue') {
									fill: $color-pie-chart-2;
								}

								@include if-parent('.mm-pie--green') {
									fill: $color-pie-chart-3;
								}

								@include if-parent('.mm-pie--lilac') {
									fill: $color-pie-chart-4;
								}
							}
						}
					}
				}

				.mm-textline {
					text-align: center;
					font-weight: bold;
					margin-top: 5px;
					max-width: 120px;
					margin-left: 10px;
					word-break: break-word;

					@include if-parent('.m-basic-pie-chart--headline') {
						display: none;
					}

					@include if-parent('.m-basic-pie-chart--headline-double') {
						display: none;
					}
				}
			}
		}
	}
}