/* a-basic-input-text--plain */
.a-basic-input-text--plain {

	&.is-disabled {
		display: none;

		//exception for modifiers input: s,m,l,xl,etc.
		.l-form .ll-col-label + .ll-col-data &,
		.l-form-general .ll-col-label + .ll-col-data & {
			max-width: none;
		}

		input {
			border: none;
			padding-left: 0;
			background: transparent;

			html & {
				cursor: text;
			}

			&:focus {
				border: none;
			}

			&::-webkit-input-placeholder {
				color: $color-primary-3;
			}

			&:-moz-placeholder {
				/* Firefox 18- */
				color: $color-primary-3;
			}

			&::-moz-placeholder {
				/* Firefox 19+ */
				color: $color-primary-3;
			}

			&:-ms-input-placeholder {
				color: $color-secondary-3;
			}
		}
	}
}