.o-mgl-selector {

	> form {
		max-width: 100%;

		@include mq(desktop) {
			max-width: 557px;
		}

		> .oo-selector {
			padding: 11px 16px 0 8px;
			background: $light-grey;

			@include if-parent('.oo-selector.oo-selector--sophisticated') {
				display: flex;
				flex-direction: column;

				@include mq(tablet) {
					flex-direction: row;
					flex-grow: 1;
					justify-content: space-between;
				}
			}

			> label {
				float: left;
				padding: 0 0 13px 0;
				margin-right: 34px;
				cursor: pointer;
				@include milo-bold($font-size-16);

				@include if-parent('.oo-selector.oo-selector--sophisticated') {
					margin-right: 5px;
				}

				&.oo-last {
					margin-right: 0;
				}

				input[type="radio"] {
					display: none;

					& + span {
						display: inline-block;
						padding: 2px 0 0 31px;
						position: relative;

						&::before {
							content: '';
							@include main-icon-radio-button-unchecked;
							position: absolute;
							top: -10px;
							left: -11px;
						}

						> .oo-sophisticated-info {
							display: block;

							@include mq(tablet) {
								display: inline-block;
							}

							@include mq(desktop) {
								display: block;
							}
						}
					}

					&:checked {

						& + span {
							&::before {
								@include main-icon-radio-button-checked;
							}
						}
					}
				}

				> span {
					> span {
						white-space: pre;
					}
				}
			}
		}

		> .oo-content {
			margin-bottom: 27px;

			> div {
				display: none;

				> .oo-membership-content {
					background: $color-secondary-6;
					border: 1px solid $color-secondary-5;
					padding: 0;
					border-bottom: 3px double $color-primary-3;
					margin-bottom: 0;

					> li {
						border-bottom: 1px solid $color-secondary-5;
						padding: 10px 10px 5px;
						position: relative;

						&:last-of-type {
							border-bottom: none;
						}

						@include if-parent('.oo-membership-content.oo-membership-content--radio') {
							display: flex;
							justify-content: space-between;
						}

						> .oo-list-content {
							@include milo-bold();
							@include font-size(18px);
							margin: auto 0;

							> .a-basic-input-radio {
								> div {
									background-position: -2px 0;

									> .ll-depend {
										padding-top: 5px;

										> div {
											> .a-basic-input-radio {
												width: 100%;

												> div {
													background-position: -2px 0;

													> label {
														font-weight: normal;
													}
												}
											}
										}
									}

									> label {
										> .h-smartphone-only {
											font-weight: normal;
											white-space: pre;
										}
									}
								}
							}

							> .a-basic-input-checkbox {
								position: static;
								min-height: 30px;

								@include if-parent('.oo-list-content.oo-list-content--select') {
									padding-right: 50px;
								}

								> div {
									background-position: -2px 0;

									> label {
										@include if-parent('.oo-list-content.oo-list-content--select') {

											@include mq(tablet) {
												position: relative;
											}
										}

										> .h-smartphone-only {
											font-weight: normal;
											white-space: pre;
										}
									}

									> .oo-depend-select {
										position: absolute;
										right: 10px;
										top: 10px;
										margin-top: 0;
										vertical-align: top;

										@include mq(tablet) {
											padding-left: 15px;
											display: inline-block;
											position: relative;
											top: -2px;
										}

										> .a-basic-input-select {
											display: none;
											margin-top: 0;

											@include if-parent('li.is-active') {
												display: inline-block;
											}
										}
									}
								}
							}
						}

						> .oo-list-price {
							@include milo-bold();
							@include font-size(22px);
							opacity: 0.5;
							align-self: flex-start;
							flex-shrink: 0;

							@include if-parent('li.is-active') {
								opacity: 1;
							}
						}
					}
				}

				> div {
					> .oo-description-wrapper {
						display: none;

						&.is-active {
							display: block;
						}
					}
				}

				&.js-mgl-selector-active {
					display: block;
				}
			}
		}

		> .oo-operator {
			margin-bottom: 45px;

			.a-basic-btn {
				margin-bottom: 15px;
			}

			.a-basic-btn + .a-basic-btn {
				float: right;
				margin-left: 10px;
			}
		}
	}


	.oo-contribution {
		padding: 0 0 15px 0;
		margin-bottom: 43px;
		border-bottom: 1px solid $color-primary-3;

		li {
			position: relative;

			.oo-first {
				padding-right: 130px;

				@include mq(tablet) {
					padding-right: 160px;
				}

				display: block;
				width: 100%;
			}

			.oo-first + span {
				position: absolute;
				right: 8px;
				top: 0;

				@include mq(tablet) {
					right: 23px;
				}
			}
		}

		.oo-contribution-total {
			padding-top: 13px;
			padding-bottom: 9px;
			@include milo-bold();

			@include mq(tablet) {
				padding-bottom: 0;
			}

			.oo-first {
				@include font-size($font-size-18, $font-size-19);
				min-height: 36px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				text-align: right;
			}

			.oo-first + span {
				padding-top: 13px;
				@include font-size($font-size-36, $font-size-36);
			}
		}

		.oo-contribution-reduced {

			.oo-first {
				padding-right: 128px;

				@include mq(tablet) {
					padding-right: 158px;
				}

				min-height: 21px;
				@include font-size($font-size-12);
				display: flex;
				align-items: center;
				justify-content: flex-end;
				text-align: right;
			}

			.oo-first + span {
				@include font-size($font-size-15);
			}
		}
	}

	.oo-content-above {
		padding: 25px 20px 9px;
		margin: 0 0 43px;
		position: relative;
		background: $color-secondary-6;
		display: block;
		border: 1px solid $color-secondary-5;
	}

	.oo-description {
		padding: 25px 20px 9px;
		margin: 0 0 17px;
		position: relative;
		background: $color-secondary-6;

		ul {
			padding: 3px 0 0 8px;
		}

		&:first-of-type {
			.oo-arrow {
				width: 0;
				height: 0;
				border-left: 127px solid transparent;
				border-right: 127px solid transparent;
				border-top: 48px solid $color-primary-1;
				position: absolute;
				top: -33px;
				left: 50%;
				margin-left: -127px;
			}
		}

		&:not(:only-of-type) {
			margin-bottom: 0;
			border-bottom: 1px solid $color-primary-3;

			&:last-of-type {
				border-bottom: none;
			}
		}
	}

	// TODO: rebuild this properly to our norm
	.a-basic-input-checkbox .ll-depend {
		padding-top: 10px;
		margin-bottom: 0;
	}
}