.a-basic-rating {
	display: inline-block;
	margin-right: 15px;

	.h-inline-block-left + & {
		position: relative;
		top: -4px;
	}

	> .aa-inner-wrap {
		padding: 1px 1px 1px 23px;
		border: 1px solid $color-primary-2;
		box-shadow: inset 0 0 0 1000px $color-rating-1;
		display: inline-block;
		min-width: 52px;
		text-align: center;

		> .aa-inner {
			padding: 0 4px;
			background: $color-primary-2;
			@include milo-bold();

			.h-roboto & {
				font-family: 'Roboto Bold', sans-serif;
			}
		}

		@include if-parent('.a-basic-rating.a-basic-rating--2') {
			background: $color-rating-2;
			box-shadow: inset 0 0 0 1000px $color-rating-2;
		}

		@include if-parent('.a-basic-rating.a-basic-rating--3') {
			background: $color-rating-3;
			box-shadow: inset 0 0 0 1000px $color-rating-3;
		}

		@include if-parent('.a-basic-rating.a-basic-rating--4') {
			background: $color-rating-4;
			box-shadow: inset 0 0 0 1000px $color-rating-4;
		}

		@include if-parent('.a-basic-rating.a-basic-rating--5') {
			background: $color-rating-5;
			box-shadow: inset 0 0 0 1000px $color-rating-5;
		}

		@include if-parent('.a-basic-rating.a-basic-rating--6') {
			background: $color-rating-6;
			box-shadow: inset 0 0 0 1000px $color-rating-6;
		}

		@include if-parent('.a-basic-rating.a-basic-rating--7') {
			background: $color-rating-7;
			box-shadow: inset 0 0 0 1000px $color-rating-7;
		}

		@include if-parent('.a-basic-rating.a-basic-rating--8') {
			background: $color-rating-8;
			box-shadow: inset 0 0 0 1000px $color-rating-8;
		}

		@include if-parent('.a-basic-rating.a-basic-rating--9') {
			background: $color-rating-9;
			box-shadow: inset 0 0 0 1000px $color-rating-9;
		}
	}

	// Exception
	.m-basic-table &.h-space-s {
		margin-bottom: 0;
	}

	&--even-width {
		> .aa-inner-wrap {
			> .aa-inner {
				padding: 0;
				text-align: center;
				width: 26px;
			}
		}
	}

	&--mobile-list-mode {
		display: block;

		@include mq(tablet) {
			display: inline-block;
		}
	}

	&--first {
		margin-right: 15px;

		&.a-basic-rating--last {
			margin-right: 0;
		}
	}
}