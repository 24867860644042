.m-basic-toggle-tab-box {
	border-top: 1px solid $color-secondary-9;

	@include mq(tablet) {
		border-top: 0;
	}

	.l-form--tet &,
	.l-form-general--tet & {
		max-width: 563px;
	}

	&--fullwidth {

		.l-form--tet &,
		.l-form-general--tet & {
			max-width: 100%;
		}
	}

	> .mm-tab-desk-header {
		width: 370px;
		margin: 0 auto 40px;

		@include mq(tablet) {
			width: 100%;
			background: $color-primary-2;
			overflow: visible;
			z-index: 45;
			top: 0;
			box-shadow:
				0 2px 4px -1px rgba(0, 0, 0, 0.2),
				0 1px 10px 0 rgba(0, 0, 0, 0.12),
				0 4px 5px 0 rgba(0, 0, 0, 0.14);
			border-radius: 5px 5px 0 0;
		}

		.l-form--tet &,
		.l-form-general--tet & {
			height: 0;
			visibility: hidden;
			max-width: 100%;
			width: auto;
			margin: 0;

			@include mq(tablet) {
				height: auto;
				visibility: visible;
				margin: 0 0 40px;
			}
		}

		> .mm-desk-header-list {
			display: flex;
			padding-bottom: 15px;
			height: 0;
			visibility: hidden;

			@include mq(tablet) {
				height: auto;
				visibility: visible;
				padding-bottom: 0;
			}

			&--2er {
				@include mq(tablet) {
					margin-left: -20px;
					margin-right: -20px;
				}
			}

			&.is-hidden {
				display: none;
			}

			&.swiper-wrapper {
				overflow: visible;
			}

			> .mm-tab-toggle-headline {
				padding: 10px 10px 15px;
				@include font-size($font-size-18);
				cursor: pointer;
				width: 33.33%;
				text-align: center;
				border-bottom: 2px solid $color-secondary-4;
				text-decoration: none;
				position: relative;

				@include mq(tablet) {
					padding: 12px 0;
					align-items: center;
					@include milo-slab-bold($font-size-15, 20px);
					border-bottom: 2px solid transparent;
				}

				.l-form--tet &,
				.l-form-general--tet & {
					width: auto;
					flex-grow: 1;
					display: flex;
					justify-content: center;
				}

				&:focus {
					border-radius: 5px 5px 0 0;
					outline: 2px solid #000;
				}

				&:hover {
					@include mq(tablet) {
						background-color: $color-secondary-19;
						border-radius: 5px 5px 0 0;
						border-bottom: 2px solid $color-primary-3;
						color: $color-secondary-17;
					}

					> .mm-tab-icon {
						position: absolute;
						left: calc(50% - 46px/2);
						bottom: 0;

						&:before {
							@include mq(tablet) {
								content: " ";
								clear: both;
								position: absolute;
								width: 0;
								height: 0;
								padding: 0;
								left: 7px;
								border-left: 14px solid transparent;
								border-right: 14px solid transparent;
								border-top: 17px solid $color-primary-3;
								display: table;
							}
						}
					}
				}

				&.is-current {
					border-bottom: 2px solid $color-primary-1;

					@include mq(tablet) {
						border-bottom: 2px solid $color-primary-3;
						background-color: $color-primary-1;
						border-radius: 5px 5px 0 0;
						color: $color-secondary-17;
					}

					> .mm-tab-icon {
						position: absolute;
						left: calc(50% - 46px/2);
						bottom: 0;

						&:before {
							@include mq(tablet) {
								content: " ";
								clear: both;
								position: absolute;
								width: 0;
								height: 0;
								padding: 0;
								left: 7px;
								border-left: 14px solid transparent;
								border-right: 14px solid transparent;
								border-top: 17px solid $color-primary-3;
								display: table;
							}
						}
					}
				}

				> .mm-tab-close {
					height: 24px;
					position: relative;
					margin-left: 15px;
					width: 48px;
					overflow: hidden;
					display: block;

					@include mq(tablet) {
						position: absolute;
						right: 0;
					}

					&:after {
						content: '';
						position: absolute;
						left: 2px;
						top: -10px;
						@include main-icon-cancel-grey;
					}

					img {
						display: none;
					}
				}

				> .mm-t-index {
					padding-left: 5px;
				}
			}
		}

	}

	> .mm-formular {
		> .mm-toggle-tab-container {
			border-bottom: 1px solid $color-secondary-9;

			@include mq(tablet) {
				border-bottom: 0;
			}

			&:only-child {

				.mm-tab-close {
					display: none;
				}
			}

			> .mm-toggle-tab-headline {
				@include milo-bold($font-size-16);
				padding: 10px;
				position: relative;
				display: flex;
				justify-content: flex-start;

				&:after {
					position: absolute;
					content: '';
					display: block;
					@include main-icon-arrow-right-grey;
					transform: rotate(90deg);
					top: -2px;
					left: auto;
					right: -2px;
				}


				@include mq(tablet) {
					display: none;
				}

				&.is-current {
					&:after {
						transform: rotate(-90deg);
					}
				}

				&.is-stndt {
					padding-left: 40px;
					@include milo-regular($font-size-16);

					&:after {
						position: absolute;
						content: '';
						display: block;
						@include main-icon-keyboard-arrow-down;
						transform: rotate(0deg);
						top: -2px;
						left: 2px;
						right: auto;
					}

					&.is-current {
						&:after {
							transform: rotate(-180deg);
						}
					}
				}

				> .mm-tab-close {
					height: 24px;
					position: absolute;
					right: 15px;
					top: 10px;
					margin-left: 15px;
					width: 48px;
					overflow: hidden;
					display: block;

					&:after {
						content: '';
						position: absolute;
						left: 2px;
						top: -10px;
						@include main-icon-cancel;
					}

					img {
						display: none;
					}
				}

				> .mm-t-index {
					padding-left: 5px;
				}
			}


			> .mm-toggle-tab-content {
				display: none;
				margin-top: 25px;

				@include mq(tablet) {
					margin-top: 0;
				}

				&.is-current {
					display: block;
				}

				.a-basic-btn {
					margin-left: auto;
					margin-right: auto;
				}
			}
		}

		> .a-basic-btn {
			margin-top: 30px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}