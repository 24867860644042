.m-basic-form-footer-sticky--multiple {
	> .mm-content {
		> .mm-inner-content {
			@include mq(tablet) {
				display: grid;
				grid-template-columns: 1fr fit-content(250px);
				grid-auto-rows: auto;
				gap: 0 30px;
				grid-template-areas:
					"header badge"
					"content badge";
			}

			> .mm-inner-header {
				grid-area: header;
				max-width: 550px;
				display: flex;
				justify-content: space-between;
				padding-bottom: 10px;
				border-bottom: 2px solid $color-secondary-9;

				@include mq(tablet) {
					padding-bottom: 18px;
				}

				> .mm-edit {
					position: relative;
					padding-left: 40px;
					padding-top: 4px;
					cursor: pointer;
					display: flex;
					align-items: center;

					&:after {
						@include main-icon-edit;
						content: '';
						position: absolute;
						left: 0;
					}

					a {
						text-decoration: none;
					}
				}
			}

			> .mm-inner-section {
				grid-area: content;
				max-width: 550px;
				margin-bottom: 30px;

				@include mq(tablet) {
					margin-bottom: 60px;
				}

				> .mm-inner-wrapper {
					padding: 10px 0;
					border-bottom: 1px solid $color-secondary-9;

					@include mq(tablet) {
						padding: 18px 0;
					}

					&--border-bold {
						border-bottom: 2px solid $color-secondary-9;
					}

					> .mm-title {
						display: flex;
						justify-content: space-between;
						margin-bottom: 20px;

						> .mm-price {
							@include milo-bold(16px);
							white-space: nowrap;

							@include mq(tablet) {
								@include milo-bold(20px);
							}
						}
					}

					ul {
						width: 100%;

						li {
							position: relative;
							padding: 0 0 2px 28px;
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;

							&.is-justify-content-left {
								justify-content: left;
							}

							&:after {
								content: '';
								position: absolute;
								left: -12px;
								top: -13px;
								@include main-icon-check;
							}

							> span {
								&:first-child {
									max-width: 350px;
								}

								&:last-child {
									padding-left: 10px;
								}
							}

							&:not(:last-child) {
								margin-bottom: 10px;
							}

							&:before {
								background: none;
								display: none !important;
							}

							&:hover {
								text-decoration: none;
							}
						}
					}
				}
			}
		}
	}
}