/* a-basic-input-select--newdesign  */
.a-basic-input-select--newdesign {

	border: 1px solid $color-formfield-border-standard;
	border-radius: 4px;

	&:hover {
		border: 1px solid $color-formfield-border-hover;
	}

	// exception .m-basic-radius-search
	.m-basic-radius-search > .l-form-general .ll-col-label + .ll-col-data & {
		overflow: initial;
	}

	&.is-locked {
		border-color: $color-secondary-5;
		color: $color-secondary-20;
	}

	// row has error class
	.ll-row.is-error & {
		height: 57px;
		border: 2px solid $color-action-alert;
	}

	> select {
		display: none;
	}

	> .aa-btn-selection {
		display: block;
		width: 100%;
		position: relative;
		padding: 16px 0 0 15px;
		@include milo-regular($font-size-16);
		text-decoration: none;
		cursor: pointer;
		height: 53px;
		border-radius: 4px;
		margin-bottom: -1.8px;
		background: $color-primary-2;

		.h-roboto & {
			font-family: 'Roboto', sans-serif;
		}

		.is-floating & {
			padding: 28px 0 0 15px;
		}

		@include if-parent('.a-basic-input-select--newdesign.is-floating') {
			padding-top: 25px;
			transition: top 0.5s;
		}

		&:after {
			top: 5px;
			content: " ";
			display: block;
			height: 28px;
			width: 28px;
			position: absolute;
			right: 1px;
			@include main-icon-arrow-drop-down;
			background-repeat: no-repeat;

			.ll-row.is-valid > .ll-col-data > &,
			.ll-row.is-error > .ll-col-data > &,
			.ll-row.is-valid > &,
			.ll-row.is-error > & {
				right: 31px;
			}
		}

		@include if-parent('.a-basic-input-select--newdesign.is-locked') {
			cursor: initial;
		}

		&.is-opened {
			border-radius: 4px 4px 0 0;

			&:after {
				@include main-icon-arrow-drop-up;
				background-repeat: no-repeat;
			}
		}

		& ~ .aa-col-label {
			@include if-parent('.a-basic-input-select--newdesign.is-floating') {
				top: 3px;
			}


			> .aa-label {
				@include if-parent('.a-basic-input-select--newdesign.is-floating') {
					color: $color-secondary-20;
					@include milo-regular($font-size-14);
				}

				.l-form-general .ll-row.is-check.is-error & {
					color: $color-action-alert;
				}
			}
		}
	}

	> .aa-options {

		display: none;
		width: 100%;
		max-width: 100%;
		height: 0;
		max-height: 210px;
		padding: 0;
		position: absolute;
		visibility: hidden;
		overflow: hidden;
		z-index: 51;
		right: -1px;
		min-width: calc(100% + 2px);
		border: 1px solid black;
		border-radius: 0 0 4px 4px;
		background: $color-primary-2;
		border-top: 1px solid $color-secondary-5;

		@include mq(tablet) {
			right: -1px;
		}

		&:not(.jspScrollable) {
			border-top: none;

			ul.aa-options-list {
				> li {
					&:first-child {
						border-top: 1px solid $color-secondary-5;
					}
				}
			}
		}

		ul {
			li {

				&.is-active {
					background: $color-primary-1;
					font-weight: bold;
				}

				&.is-disabled {
					pointer-events: none;
					color: $color-secondary-3;
				}

				&:hover,
				.is-keyboard-focus &:focus {
					outline: none;
					background-color: $color-secondary-8;
				}

				a {
					text-decoration: none;
					pointer-events: none;
				}
			}
		}

		&.is-opened {
			visibility: visible;
			height: auto;
		}

		// custom scrollbar styles
		&.jspScrollable {

			&:focus {
				outline: none;
			}

			> .jspContainer {
				width: 100% !important;

				//scrollable content wrapper
				> .jspPane {
					width: 100% !important;
				}

				//scrollbar wrapper
				> .jspVerticalBar {
					width: 5px;
					background: transparent;
					padding: 10px 0;
					right: 0;

					> .jspArrow {
						background: $color-secondary-7;
						cursor: default;
						height: 0;
					}

					> .jspTrack {
						background: $color-secondary-5;
						height: 100% !important;

						> .jspDrag {
							background: $color-secondary-2;
							border-bottom: 1px solid $color-primary-2;
						}
					}
				}

				> .jspHorizontalBar {
					display: none;
				}
			}
		}

		ul.aa-options-list {
			margin: 0;
			padding: 0;

			> li {
				position: relative;
				height: 40px;
				padding: 11px 0 0 15px;
				border-top: none;
				@include milo-regular($font-size-16);
				cursor: pointer;

				.h-roboto & {
					font-family: 'Roboto', sans-serif;
				}

				&:first-child {
					border-top: 0;
				}
			}
		}
	}

	> .aa-col-label {
		position: absolute;
		z-index: 1;
		top: 16px;
		left: 15px;
		transition: top 0.5s;

		> .aa-label {
			@include milo-regular($font-size-16);
			font-weight: normal;
			color: initial
		}
	}
}