/* .m-basic-hint--document */
.m-basic-hint--document {

	border: 1px solid $color-secondary-9;
	border-radius: 8px;
	padding: 20px 20px 20px 65px;

	@include mq(desktop) {
		padding: 40px 40px 40px 85px;
	}

	> img {
		top: 23px;
		left: 20px;
		max-width: 24px;

		@include mq(desktop) {
			top: 43px;
			left: 30px;
		}
	}

	.a-basic-btn {
		margin-right: 20px;

		@include mq(tablet) {
			margin-right: 0;
		}
	}
}