/* m-my-adac-dashboard-box-intr*/
.m-my-adac-dashboard-box-intro {
	position: relative;
	margin: 0 0 40px 0;
	padding: 40px 10px;

	@include mq(tablet) {
		margin: 0 0 60px 0;
		padding: 40px;
	}

	&:before {
		top: 0;
		content: '';
		z-index: 0;
		width: 100vw;
		height: 100%;
		left: calc(50% - 50vw); // 50% - "halbes width"
		position: absolute;
		background-color: $color-secondary-10;
	}

	> .mm-wrapper {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		position: relative;
		z-index: 1;

		> .mm-button-wrapper {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;

			@include mq(tablet) {
				flex-flow: row nowrap;
			}


			> button {
				width: 155px;
				display: table;
				outline: none;
				white-space: nowrap;
				margin: 0 15px 20px 15px;
				text-align: center;

				@include mq(tablet) {
					max-width: initial;
					margin: 0 15px;
				}

				&:last-child {
					margin: 0 15px;
				}
			}
		}
	}
}