/* m-adac-maps--cardamage */
.m-adac-maps--cardamage {

	> .mm-switch-map {
		display: none;
	}

	.mm-maps-mgl-wrapper {
		display: flex;
		flex-flow: column nowrap;

		@include mq(tablet) {
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
		}

		> .mm-wrapper-head {
			flex: 0 0 100%;
			order: 1;

			@include mq(tablet) {
				order: 0;
			}
		}

		> .mm-wrapper-subhead {
			flex: 0 0 100%;
			order: 2;

			@include mq(tablet) {
				order: 0;
			}
		}

		// @EW72 fyi (by LM):
		// no direct child selector on purpose bc element might be wrapped via custom scrollbar etc.
		.mm-result-list-wrap {
			display: none;
			flex: 1 1 100%;
			order: 3;
			width: 100%;
			max-width: 100%;
			padding: 0;
			border: none;
			background-color: inherit;

			@include mq(tablet) {
				display: block;
				max-height: none;
				flex: 0 1 50%;
				order: 0;
				float: left;
				overflow: hidden;
				box-sizing: border-box;
			}

			> .mm-result-list {
				margin: 0 0 10px 0;
				padding: 0;
				border-top: 0;

				@include mq(tablet) {
					margin: 0 37px 0 0;
					border-top: 1px solid $color-secondary-9;
				}

				&:not(:last-child) {
					margin-bottom: 10px;
				}

				> li {
					position: relative;
					padding-top: 10px;
					padding-bottom: 13px;
					padding-left: 23px;
					border-bottom: 1px solid $color-secondary-9;

					&.is-highlight {
						padding-left: 20px;
						border-left: 3px solid $color-primary-1;
					}

					&:hover {
						background-color: $color-secondary-8;
						cursor: pointer;
					}
				}
			}

			&.is-active {
				display: block;
			}
		}

		.mm-map-wrapper {
			display: block;
			flex: 1 1 100%;
			order: 1;
			width: 100%;
			padding-bottom: 15px;

			@include mq(tablet) {
				flex: 1 0 50%;
				order: 0;
				max-width: 50%;
				padding-bottom: 0;
			}

			// is-iOS Fix
			.is-ios & {
				margin-bottom: 15px;

				@include mq(tablet) {
					margin-bottom: 0;
				}
			}

			&.is-active {
				display: block;
			}

			> picture {

				// exception if e.g. a a-basic-btn is following the img
				&:not(:last-child) {
					> img {
						margin-bottom: 10px;
					}
				}

				> img {
					max-width: 100%;

					@include mq(tablet) {
						width: auto;
					}
				}
			}
		}
	}

	> .mm-maps-nav {

		&:not(.h-space-m) {
			margin-bottom: 5px;

			@include mq(tablet) {
				margin-bottom: 10px;
			}
		}
	}
}