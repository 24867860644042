/* m-basic-keywordfilter--lexicon */
.m-basic-keywordfilter--lexicon {

	position: relative;
	width: auto;

	> .mm-btnWrap {
		text-align: right;
		display: block;
		margin-bottom: 10px;

		@include mq(tablet) {
			position: absolute;
			right: 0;
			top: 10px;
		}

		.a-basic-link.a-basic-link--top {
			display: inline-block;
		}
	}

	> .mm-searchbar {
		max-width: 480px;
		margin-bottom: 5px;

		.js-clear-button {
			right: 40px !important;
			bottom: 8px !important;
			height: 26px;

			.is-keyboard-focus &:focus {
				outline: 2px solid $color-primary-3;
			}

			span {
				right: -2px;
			}
		}
	}

	> .mm-searchinfo {
		height: 25px;

		> .mm-result {
			display: none;
		}

		> .mm-noresult {
			display: none;
		}
	}

	> .mm-lexicon-result-wrap {
		position: relative;
		overflow: hidden;
		margin-right: - $l-main-content-padding-side;
		padding-right: $l-main-content-padding-side + 35px;
		transition: height 1s ease;

		@include mq(tablet) {
			margin-right: - $l-main-content-padding-side-tablet;
			padding-right: $l-main-content-padding-side-tablet + 70px;
		}

		@include mq(desktop) {
			margin-right: - $l-main-content-padding-side-desktop;
			padding-right: $l-main-content-padding-side-desktop + 60px;
		}

		> .mm-lexicon-result {
			position: relative;
			transition: margin 1s ease-in-out;

			> .mm-lexicon-category {

				> .mm-lexicon-categoryhead {
					padding: 7px 10px;
					background: $color-secondary-6;
					font-weight: bold;

					@include if-parent('.m-basic-keywordfilter--lexicon.m-basic-keywordfilter--lexicon-my-adac') {

						background: white;
						border-bottom: 1px solid $color-secondary-14;
						// less line height, too much distance to the border
						@include milo-slab-bold(38px, 26px);
						margin: 0 0 20px 0;

						@include mq(tablet) {
							margin: 0 0 40px 0;
						}
					}
				}
			}
		}

		> .mm-lexicon-letterbox {
			position: absolute;
			top: 0;
			right: 0;
			width: 39px;
			height: 100%;

			@include if-parent('.m-basic-keywordfilter--lexicon.m-basic-keywordfilter--lexicon-my-adac') {
				display: flex;
			}

			> .mm-lexicon-letterbox-sticky {
				max-height: 80vh;
				transition: all .3s ease;

				@include mq(desktop) {
					max-height: 100vh;
				}

				> .swiper {
					max-height: 80vh;

					@include mq(desktop) {
						max-height: calc(100vh - 106px);
					}

					> .swiper-wrapper {

						> .swiper-slide {

							@include if-parent('.m-basic-keywordfilter--lexicon.m-basic-keywordfilter--lexicon-my-adac') {
								width: 30px;
								height: 30px;
							}

							margin-bottom: 2px;

							> .mm-lexicon-letter {
								height: 40px;
								padding: 4px 0 0 0;
								@include franklin-demi($font-size-24, 29px);
								background: $color-secondary-4;
								text-align: center;
								border: 2px solid transparent;
								cursor: pointer;

								@include if-parent('.m-basic-keywordfilter--lexicon.m-basic-keywordfilter--lexicon-my-adac') {
									cursor: pointer;
									@include milo-regular(18px, 28px);
									background: $color-secondary-10;
									height: 100%;
									max-height: 30px;
									padding: 0;
								}

								@include if-parent('.swiper-slide.is-active') {
									background: $color-primary-1;
									cursor: default;
								}

								&:focus-within {
									outline: none;

									.is-keyboard-focus & {
										border: 2px solid #000;
									}
								}

								&:hover {
									background: $color-secondary-5;

									@include if-parent('.m-basic-keywordfilter--lexicon.m-basic-keywordfilter--lexicon-my-adac') {
										background: $color-secondary-10;
									}

									@include if-parent('.swiper-slide.is-active') {
										background: $color-primary-1;
									}
								}

								// exception for myAdac - if there is no Text for this letter its getting grey
								&.is-empty {
									color: $color-secondary-3;
									cursor: default;
								}
							}
						}
					}

					> .mm-lexicon-prev,
					.mm-lexicon-next {
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
						cursor: pointer;
						z-index: 10;
						background-color: rgba(255, 255, 255, 0.9);
						border-radius: 50%;
						border: 2px solid $color-primary-3;
						width: 35px;
						height: 35px;

						@include if-parent('.m-basic-keywordfilter--lexicon.m-basic-keywordfilter--lexicon-my-adac') {
							left: 15px;
							overflow: hidden;
							width: 24px;
							height: 24px;

							@include mq(tablet) {
								width: 30px;
								height: 30px;
							}
						}

						&:hover {
							background-color: rgba(255, 255, 255, 0.7);
						}

						&:focus-visible {
							outline: none;

							.is-keyboard-focus & {
								outline: 2px solid $color-primary-3;
							}
						}
					}

					> .mm-lexicon-prev {
						top: 2px;

						&.swiper-button-disabled {
							display: none;
						}

						> .mm-button-inner {
							@include main-icon-arrow-down;
							position: relative;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}

					> .mm-lexicon-next {
						bottom: 2px;

						&.swiper-button-disabled {
							display: none;
						}

						> .mm-button-inner {
							@include main-icon-arrow-up;
							position: relative;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}
				}
			}
		}
	}
}