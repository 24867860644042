/* m-adac-maps--searchresult-with-filter */
.m-adac-maps--searchresult-with-filter {

	> .mm-maps-mgl-wrapper {
		> .mm-map-wrapper {
			margin-top: 52px;

			&.is-stuck {
				margin-top: 40px;
				padding-bottom: 30px;
			}
		}

		> .mm-result-list-wrap {
			> .mm-headline {
				margin-top: 14px;
				padding-bottom: 14px;
				border-bottom: 1px solid $color-secondary-9;

				@include mq(tablet) {
					margin: 0;
					padding-right: 37px;
					padding-bottom: 0;
					border-bottom: 0;
				}
			}
		}
	}

	> .mm-switch-map {
		border-bottom: 0;

		&.is-stuck {
			border-bottom: 1px solid $color-secondary-9;
		}

		.js-switch {
			opacity: 0.5;

			&.is-active {
				opacity: 1;
			}
		}
	}
}