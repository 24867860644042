/* .m-basic-hint--my-adac-content */
.m-basic-hint--my-adac-content {
	padding-left: 45px;

	> img {
		left: 0;
		top: 18px;

		@include mq(desktop) {
			top: 17px;
		}
	}
}