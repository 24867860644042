/* m-basic-newsbox  */
.m-basic-newsbox {

	position: relative;
	padding: 20px 0 3px 0;
	border: 1px solid $color-secondary-5;
	border-right: none;
	border-left: none;
	clear: both;

	@include mq(tablet) {
		padding-top: 16px;
	}

	& + .m-basic-newsbox {
		border-top: 0;
	}

	&.h-space-s {

		// Exception .m-ves-tab
		.m-ves-tab & {
			margin-bottom: 0;
		}
	}

	// Exception:
	.mm-lexicon-category & + .m-basic-newsbox {
		padding-top: 0;
	}

	// Exception Basic-Cluster-Box
	.o-basic-cluster-box & {
		padding-top: 12px;
	}

	// exception for myAdac Glossar/Lexicon
	.m-basic-keywordfilter--lexicon-my-adac & {
		display: flex;
		justify-content: center;
		margin: 0 0 20px 0;
		padding: 0 10px;

		// if there is no h-space-s, do the same
		@include mq(tablet) {
			margin: 0 0 40px 0;
		}

		&.h-space-s {
			margin: 0 0 20px 0;

			@include mq(tablet) {
				margin: 0 0 40px 0;
			}
		}
	}

	.mm-tabcontent-important & {
		&:last-child {
			border-bottom: 0;
		}
	}

	> .mm-content-link {
		position: absolute;
		height: calc(100% - 30px);
		left: 0;
		width: 100%;
		z-index: 1;
	}

	> .mm-content {
		@include mq(tablet) {
			max-width: 700px;
			float: left;
		}

		// Exception Basic-Cluster-Box
		.o-basic-cluster-box & {
			max-width: 85%;
		}

		> .mm-meta {
			margin: 0 0 5px 0;

			// Exception Basic-Cluster-Box
			.o-basic-cluster-box & {
				margin-bottom: 0;
			}

			// Exception .m-ves-tab
			.m-ves-tab & {
				display: flex;
				flex-flow: row nowrap;
				white-space: nowrap;
			}

			.mm-meta-item {
				display: inline;

				// adds pipe on end if not the only or last elem
				&:not(:only-child):not(:last-child):after {
					margin: 0 8px;
					content: "|";

					// Exception Basic-Cluster-Box
					.o-basic-cluster-box & {
						margin-left: -0.3em;
						margin-right: 0;
						content: ",";
					}

					// Exception .m-ves-tab
					.m-ves-tab & {
						content: none;
					}
				}

				> address {
					display: inline;
				}

				// sitecore fix
				.scWebEditInput {
					display: inline;
				}


				&:first-child {

					// Exception .m-ves-tab
					.m-ves-tab & {
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				// adds pipe on begin if not the only or first elem
				&:not(:only-child):not(:first-child):before {

					// Exception .m-ves-tab
					.m-ves-tab & {
						margin: 0 6px 0 8px;
						content: "|";
					}
				}
			}
		}

		h2,
		> h3,
		h2 > a,
		h3 > a {

			$lineHeight: 24px;
			$lineHeightTablet: 26px;
			$maxLines: 2;

			padding: 0;
			font-weight: normal;
			@include milo-slab-bold($font-size-21, $lineHeight);

			.h-roboto & {
				font-family: 'Roboto Bold', sans-serif;
			}

			@include mq(tablet) {
				padding-bottom: 10px;
				vertical-align: top;
				@include font-size($font-size-24);
				@include line-height($lineHeightTablet);
			}

			+ .mm-subheadline {
				@include mq(tablet) {
					margin-top: -10px;
				}
			}

			> a {
				text-decoration: none;
				z-index: 2;
			}

			// Exception .m-ves-tab
			.m-ves-tab & {
				position: relative;
				display: inline-block;
				width: auto;
				max-height: calc(#{$maxLines} * #{$lineHeight});
				padding: 0 5px 0 0;
				overflow: hidden;

				@include mq(tablet) {
					max-height: calc(#{$maxLines} * #{$lineHeightTablet});
				}

				@at-root #{selector-unify(&, a)} {
					max-height: none;
				}
			}
		}

		> h2,
		> h3 {
			@include if-parent('.m-basic-newsbox.is-global-searchresult') {
				@include milo-bold($font-size-23, 26px);
			}

			> a {
				@include if-parent('.m-basic-newsbox.is-global-searchresult') {
					@include milo-bold($font-size-23, 26px);
				}
			}
		}

		> a {
			@include if-parent('.m-basic-newsbox.is-global-searchresult') {
				@include milo-bold($font-size-23, 26px);
			}
		}

		> .mm-subheadline {
			display: block;
			padding: 0 0 10px 0;

			// Exception Basic-Cluster-Box
			.o-basic-cluster-box & {
				padding-bottom: 5px;
			}
		}

		> p {

			$lineHeight: 21px;
			$maxLines: 2;

			&:not(:last-child) {
				margin: 0;
			}

			/// Exception .m-ves-tab
			.m-ves-tab & {
				position: relative;
				width: auto;
				max-height: calc(#{$maxLines} * #{$lineHeight});
				overflow: hidden;
				margin-bottom: 5px;

				&:last-child {
					margin-bottom: 0;
				}

				& + footer {
					margin-bottom: 10px;
					text-overflow: ellipsis;
					width: 100%;
					white-space: nowrap;
					overflow: hidden;
				}
			}
		}
	}

	.a-basic-stopper + .mm-content {
		margin-right: 100px;
	}

	.mm-ratio-container {
		position: relative;

		&:not(.is-lazyloaded) {
			background: $color-secondary-6;

			&::before {
				animation: swiper-preloader-spin 1s steps(12, end) infinite;
				display: block;
				z-index: 100;
				position: absolute;
				top: calc(50% - 34px);
				left: calc(50% - 34px);
				content: '';
				opacity: 1;
				transition: opacity 300ms;
				@include main-icon-refresh-48px;
			}

			&:after {
				content: '';
				display: block;
				height: 0;
				width: 100%;
				/* 16:9 = 56.25% = calc(9 / 16 * 100%) */
				padding-bottom: 52.109181%;

				@include mq(desktop) {
					width: 403px;
				}
			}

			> * {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
}

.js-basickeywordfilter-result {
	clear: both;

	& + .js-basickeywordfilter-result {
		.m-basic-newsbox {
			border-top: 0;
		}
	}
}