.o-layout-main-stage--wide {
	max-width: 100%;

	> .swiper {
		> .swiper-wrapper {
			> .swiper-slide {

				> .oo-box {
					bottom: unset;
					background: transparent;
					padding: 0 15px;
					margin: 0;
					height: unset;

					@include mq(tablet) {
						top: 60px;
						padding: 15px 0 15px 15px;
						margin: -30px 10px 0 10px;
					}

					@include mq(desktop) {
						left: 355px;
						width: 480px;
					}

					.oo-box-head {
						max-width: 100%;
						line-height: 28px;
						@include milo-bold($font-size-21, 50px);

						@include mq(tablet) {
							@include font-size($font-size-36);
						}

						@include mq(desktop) {
							line-height: 50px;
						}
					}

					.oo-box-text {
						height: unset;
						max-width: unset;

						@include mq(tablet) {
							max-width: 400px;
						}
					}
				}
			}
		}
	}
}