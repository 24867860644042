/* m-basic-list */
.m-basic-list {

	max-width: 700px;
	padding: 0;

	// exception
	.m-basic-text p:last-of-type + & {
		padding-top: 15px;
	}

	@include mq(print) {
		page-break-inside: avoid;
	}

	&.h-space-s {
		margin-bottom: 0;
	}

	&--centered {
		margin-left: auto;
		margin-right: auto;
	}

	// exception
	br + & {
		padding-top: 12px;
	}

	br + br & {
		padding-top: 0;
	}

	.m-basic-text.h-space-s + & {
		padding-top: 12px;

		// exception for myAdac
		@include if-parent('.m-basic-list--my-adac') {
			padding: 0;
		}
	}

	// exception
	.o-basic-cluster-box .a-basic-btn + & {
		padding-top: 7px;
	}

	// exception layoutMainStage
	.o-layout-main-stage .oo-box-col-3 & {
		margin-top: 2px;
	}

	// exception when used instead of an input field in col-data
	.oo-col-label + .oo-col-data &,
	.ll-col-label + .ll-col-data & {
		padding-top: 7px;
	}

	// exception radio + checkbox
	.a-basic-input-radio &,
	.a-basic-input-checkbox & {

		> li {
			padding-left: 14px;
			background-position: -9px -3px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	// exception .m-basic-calculator-content-box if children of mm-tile
	.m-basic-calculator-content-box .mm-tile > .mm-tile-content > & {
		padding-top: 10px;
		overflow: hidden;

		@include mq(tablet) {
			padding-top: 20px;
		}
	}

	// exception .m-basic-calculator-content-box if children of mm-tile and sibling of a disabled input
	.m-basic-calculator-content-box .mm-tile-container .mm-tile > .mm-tile-content > input:disabled ~ & {
		color: $color-secondary-2;
	}

	.m-basic-toggle--directory & {
		max-width: initial;

		@include mq(tablet) {
			column-count: 2;
			column-gap: 5%;
			margin-bottom: 10px;
		}
	}

	// exceptions for ordered lists
	@at-root {
		ol#{&} {
			padding-left: 28px;
			list-style-type: decimal;

			> li {
				padding-left: 0;
				background: none;

				&:before {
					background: none;
				}

				// for subitems inside lists
				> ol {
					padding: 12px 0 0 28px;
					list-style-type: decimal;

					> li {
						margin-bottom: 8px;
					}
				}
			}
		}
	}

	> li {
		position: relative;
		margin: 0 0 8px 0;
		padding: 0 0 0 20px;
		@include line-height(18px);
		text-align: left;

		&:before {
			content: '';
			display: inline-block;
			@include main-icon-bullet;
			position: absolute;
			left: -9px;
			top: -13px;

			.mm-map-wrapper & {
				content: none;
			}

			.a-basic-input-checkbox & {
				left: -13px;
			}
		}

		// Exception: .m-basic-toggle--directory
		.m-basic-toggle--directory & {
			margin-bottom: 10px;
		}

		// Exception: BasicTable
		.m-basic-table & {
			margin-bottom: 1px;
		}

		.m-basic-table--compare & {
			@include mq(tablet) {
				overflow: hidden;
				text-overflow: ellipsis;
			}

			@include mq(desktop) {
				overflow: visible;
				text-overflow: clip;
			}
		}

		// exception for .m-basic-calculator-tile
		.m-basic-calculator-tiles & {
			padding-left: 30px;
		}

		// exception for m-vehicles-results
		.m-vehicles-results > .mm-infolink-col > & {
			margin-bottom: 0;
			text-align: right;
		}

		&.mm-last {
			margin-bottom: 0;
		}

		> a {
			text-decoration: underline;

			// exception for myAdac - for PDF´s (Namics CMS)
			@include if-parent('.m-basic-list.m-basic-list--my-adac-link') {
				text-decoration: none;

				&:hover {
					text-decoration: none;
				}

				&:visited {
					color: $color-secondary-1;
				}
			}
		}

		> ul:not(.m-basic-list--yellow) {
			padding: 12px 0 0 12px;

			> li {
				position: relative;
				margin: 0 0 8px 0;
				padding: 0 0 0 11px;

				&:before {
					content: '';
					display: inline-block;
					@include main-icon-bullet;
					position: absolute;
					left: -18px;
					top: -14px;
				}
			}
		}
	}

	// exception for .m-basic-list with yellow dots
	&--yellowdots > li {
		&::before {
			content: '';
			display: inline-block;
			@include main-icon-bullet-yellow-6;
			position: absolute;
			top: -7px;
			left: -5px;
		}
	}

	// exception when in ves-rs-sticky
	.mm-layer-details-inner & {
		padding-top: 4px !important;

		&.h-space-s {
			margin-bottom: 0;

			@include mq(tablet) {
				margin-bottom: 10px;
			}
		}
	}

	// exception
	.mm-flex--row-reverse .mm-content & {
		padding-left: 10px;
	}

	.oo-description & {

		> ul {
			padding: 0;
		}
	}

	.l-outer--my-adac-content & {

		li {
			position: relative;
			height: auto;
			margin: 0;
			padding: 8px 0 8px 40px;
			background: none;
			@include font-size(16px, 20px);

			&:hover {
				text-decoration: none;
			}

			&:before {
				position: absolute;
				width: 8px;
				height: 8px;
				top: 14px;
				left: 0;
				content: '';
				background-color: $color-secondary-17;
				border-radius: 50%;

				@include if-parent('.m-basic-list--link') {
					display: none;
				}
			}
		}
	}

	.l-outer--my-adac &:not(.m-basic-list--no-my-adac):not(.m-basic-list--link) {
		margin: 0 0 24px 0;

		li {
			position: relative;
			height: auto;
			margin: 0;
			padding: 8px 0 8px 40px;
			background: none;
			@include font-size(16px, 20px);

			&:hover {
				text-decoration: none;
			}

			&:before {
				position: absolute;
				width: 8px;
				height: 8px;
				top: 14px;
				left: 0;
				content: '';
				background-color: $color-secondary-17;
				border-radius: 50%;
			}
		}
	}

	.l-outer--my-adac &.m-basic-list--no-my-adac {
		li {
			margin-bottom: 15px;
		}
	}

	.oo-col-label + .oo-col-data & {
		.o-basic-form-overview & {
			padding-top: 2px;
		}
	}

	// exception for pins below map at pages-ruf-examples-inspection-services
	.m-adac-maps--searchresults .mm-map-wrapper > & {
		display: flex;
		flex-wrap: wrap;

		> .mm-icon {
			width: 100%;
			padding: 0;
			margin: 0;
			background: none;
			list-style: none;
			text-align: right;
			display: inline-flex;
			justify-content: flex-end;
			align-items: center;
			@include milo-regular($font-size-15);

			svg {
				width: 36px;
				height: 36px;
				margin-left: 7px;
			}
		}
	}
}