/* mm-my-adac-toggle-element--bold*/
.mm-my-adac-toggle-element--bold {

	> .mm-wrapper {

		> .mm-toggle-headline-wrapper {
			@include if-parent('.mm-my-adac-toggle-element--bold.is-active') {
				border-bottom: 1px solid $color-secondary-9;
			}

			> .mm-toggle-headline {
				padding-left: 0;
				padding-right: 0;

				&:after {
					right: -10px;
				}

				> button {
					@include milo-bold();
				}
			}
		}

		> .mm-toggle-content {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}