/* a-basic-btn--edit */
.a-basic-btn--edit {
	position: relative;
	padding: 10px 22px 7px 42px;

	&.a-basic-btn--my-adac {
		padding-left: 48px;
	}

	&:before {
		@include main-icon-edit();
		content: '';
		position: absolute;
		left: 12px;
		top: 9px;
		width: 30px;
		height: 30px;
		transform: scale(0.8);

		.m-my-adac-dashboard-box--block &,
		.m-my-adac-dashboard-box-showcase &,
		.m-my-adac-dashboard-box & {
			left: 5px;
			top: 0;
			width: 40px;
			height: 40px;
		}
	}
}