/* a-basic-input-radio--col-1-2-3 */
.a-basic-input-radio--col-1-2-3 {
	width: 100%;
	margin-right: 10px;

	@include mq(tablet) {
		margin-right: $form-gutter;
		width: calc(100% / 2 - #{$form-gutter});
	}

	@include mq(desktop) {
		margin-right: $form-gutter;
		width: calc(100% / 3 - 11px);
	}
}