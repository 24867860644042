// m-ves-rs-contentbox
.m-ves-rs-contentbox {

	position: relative;
	padding: 34px 5px;
	background: none;

	@include mq(tablet) {
		padding: 50px 0;
	}

	//style box as fullwidth
	&:before,
	&:after {
		content: "";
		position: absolute;
		top: 0;
		width: 50%;
		height: 100%;
		background: $color-primary-2;
	}

	&:before {
		left: -50%;
	}

	&:after {
		right: -50%;
	}

	.is-hidden,
	&.is-hidden {
		display: none !important;
	}

	.mm-subheadline {

		> h2,
		h3,
		p {
			text-align: center;
		}

		> p {
			margin-bottom: 25px;

			@include mq(desktop-l) {
				max-width: 720px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	> h2,
	h3,
	p {
		max-width: none;
		text-align: center;
	}

	> p {
		@include mq(tablet) {
			max-width: 610px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	> .mm-tile-container {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		margin: 5px -10px 5px -10px;

		@include mq(tablet) {
			margin: 5px 0 0 0;
			padding: 0;
		}

		& + .mm-tile-container {
			@include mq(tablet) {
				margin-top: 40px;
			}
		}

		//exception if immediately followed by m-basic-text
		.m-basic-text + & {
			@include mq(tablet) {
				margin-bottom: 50px;
			}
		}

		&.hidden {
			display: none;
		}

		&.h-space-s {
			margin-bottom: 10px;
		}

		&.h-space-m {
			margin-bottom: 30px;
		}

		&.h-space-l {
			margin-bottom: 60px;
		}

		> .mm-subheadline {
			width: 100%;
			margin: 0 10px 5px 10px;

			@include mq(tablet) {
				margin: 0 0 5px 0;
			}
		}

		> .mm-tile {
			position: relative;
			margin: 0 15px 40px 15px;

			@include mq(tablet) {
				margin: 0 20px;
			}

			&--radio {
				> label {
					> img {
						width: 100%;
						height: 100%;
						pointer-events: none;
						user-select: none;
					}
				}
			}

			&--checkbox {
				> input {

					// show other text if cbx is checked
					&:checked + label {
						> .mm-text-container {

							> .mm-tile-desc-info {

								&--default {
									display: none;
								}

								&--active {
									display: block;
								}
							}
						}
					}
				}

				> label {
					position: relative;

					> img {
						width: 100%;
						height: 100%;
						pointer-events: none;
						user-select: none;
					}

					// initial icon
					&:after {
						@include main-icon-choose-small;

						@include mq(tablet) {
							@include main-icon-choose-thin;
						}
					}
				}
			}

			&-empty {
				display: block;
				width: 130px;
				height: 0;
				margin-top: 0;
				margin-bottom: 0;

				@include mq(tablet) {
					display: none;
				}
			}

			> input {

				// style tile label if input is checked
				&:checked + label {
					border-color: $color-primary-1;
					background: $color-primary-1;

					&:after {
						@include main-icon-selected-small;

						@include mq(tablet) {
							@include main-icon-selected-thin;
						}
					}
				}

				&:disabled {
					pointer-events: none;
				}

				&:disabled + label {
					cursor: default;
				}

				// style tile label if input is not checked, but disabled
				&:not(:checked):disabled + label {
					opacity: 0.3;

					&:after {
						display: none;
					}

					> .mm-text-container {
						> .mm-tile-desc-info {
							visibility: hidden;
						}
					}
				}
			}

			> label {
				display: block;
				height: 130px;
				width: 130px;
				background: $color-primary-2;
				border: 2px solid $color-secondary-1;
				cursor: pointer;
				border-radius: 8px;
				position: relative;

				@include if-parent('.mm-tile.mm-tile--reduced-xs') {
					height: auto !important;
					min-height: 0;
				}

				@include if-parent('.mm-tile.mm-tile--reduced') {
					height: 100%;
					width: 150px;
					min-height: 65px;
				}

				&:after {
					content: "";
					position: absolute;
					top: -26px;
					right: -26px;

					@include mq(tablet) {
						top: -30px;
						right: -30px;
					}
				}

				img {
					&.img-on-top {
						position: relative;
						top: -22px;
					}
				}

				> .mm-text-container {
					position: absolute;
					width: 100%;
					display: flex;
					flex-flow: column nowrap;
					left: 50%;
					transform: translate(-50%, 0);
					padding: 0 5px;
					bottom: 12px;
					text-align: center;

					@include if-parent('.mm-tile.mm-tile--reduced') {
						justify-content: center;
						height: 100%;
						transform: none;
						left: 0;
						top: 0;
						position: relative;
						padding: 10px;
					}

					> .mm-tile-desc-head {
						@include milo-bold($font-size-16, 18px);
						letter-spacing: -0.07em;

						&--small {
							font-size: 15px;
						}
					}

					> .mm-tile-desc-info {
						@include font-size($font-size-12);

						&--active {
							display: none;
						}
					}
				}
			}
		}

		&--primeBox {

			> .mm-tile {
				width: 100%;
				margin: 5px 20px 40px 10px;
				max-width: 580px;

				@include mq(tablet) {
					width: 640px;
					margin: 0;
					max-width: none;
				}

				&:last-child {
					margin-bottom: 0;
				}

				> input {

					// show other text if cbx is checked
					&:checked + label {
						> .mm-text-container {

							> .mm-tile-desc-info {
								padding: 5px 0 5px 33px;

								@include mq('tablet') {
									padding: 5px 0 5px 50px;
								}

								&--active {
									display: inline-block;
								}
							}
						}
					}
				}

				> label {
					position: relative;
					width: 100%;
					height: 50px;

					> .mm-text-container {
						bottom: calc(50% - 14px);
						display: block;

						> .mm-tile-desc-info {
							position: relative;
							@include milo-bold($font-size-16, 18px);
							padding: 5px 0 5px 33px;

							&:before {
								content: '';
								position: absolute;
								top: -11px;
								left: -10px;
								@include main-icon-star;
							}

							@include mq('tablet') {
								padding: 5px 0 5px 50px;
							}

							&--default {
								display: inline-block;
							}
						}
					}
				}
			}
		}
	}

	> .mm-component {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		margin-top: 40px;
	}

	.mm-wrapper--inline {
		text-align: center;

		h2 {
			display: inline;
		}
	}
}