/* a-basic-input-radio--col-1-4-5 */
.a-basic-input-radio--col-1-4-5 {
	width: calc(100% - #{$form-gutter});
	margin-right: 20px;

	@include mq(tablet) {
		width: calc(100% / 4);
		white-space: nowrap;
	}

	@include mq(desktop) {
		width: calc(100% / 5);
		margin-right: 30px;
	}
}