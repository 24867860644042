/* a-basic-icon-pdf */
.a-basic-icon-pdf {
	position: relative;
	display: inline-block;
	padding: 0 0 0 25px;
	min-height: 16px;

	.aa-pdf-icon-wrapper {
		position: absolute;
		left: -12px;
		top: -13px;
		@include main-icon-pdf-grey2;
	}

	// exception myAdac
	.l-outer--my-adac-content &,
	.l-outer--my-adac & {
		padding: 0;

		.aa-pdf-icon-wrapper {
			@include main-icon-pdf-grey;
		}
	}

	// exception for h2 and h3
	h2 &,
	h3 & {
		padding-left: 27px;
	}

	> svg {
		position: absolute;
		top: 0;
		left: -2px;

		@include mq(print) {
			top: -2px;
		}

		.m-basic-list--link .a-basic-link & {
			top: -3px;
		}

		.l-outer--my-adac-content &,
		.l-outer--my-adac & {
			display: none;
		}

		// exception for h2 and h3
		h2 &,
		h3 & {
			top: 0;
			left: 1px;
		}
	}

	a > & {
		text-decoration: underline;

		// exception myAdac
		.l-outer--my-adac & {
			text-decoration: none;
		}

		// exception for h2 and h3
		h2 &,
		h3 & {
			text-decoration: none;
		}
	}


	a:hover > & {
		text-decoration: underline;

		// exception myAdac
		.l-outer--my-adac-content &,
		.l-outer--my-adac & {
			text-decoration: none;
		}

		// exception for h2 and h3
		h2 &,
		h3 & {
			text-decoration: none;
		}
	}

	/* ########## exceptions ########## */
	p > a > & {
		text-decoration: underline;

		// exception myAdac
		.l-outer--my-adac-content,
		.l-outer--my-adac & {
			text-decoration: none;
		}

		// exception for h2 and h3
		h2 &,
		h3 & {
			text-decoration: none;
		}
	}

	// atom a-basic-link
	.a-basic-link & {
		text-decoration: none;
	}

	.a-basic-link:hover & {
		text-decoration: underline;
	}

	// molecule m-basic-list
	.m-basic-list:not(.m-basic-list--link) li > a > & {
		text-decoration: underline;
	}

	.m-basic-list--link li > a > & {
		text-decoration: none;
	}

	.m-basic-list--link li > a:hover > & {
		text-decoration: underline;

		// exception myAdac
		.l-outer--my-adac-content,
		.l-outer--my-adac & {
			text-decoration: none;
		}
	}

	// molecule m-basic-text
	.m-basic-text .mm-content > ul > li > a > & {
		text-decoration: underline;
	}

	.m-basic-text .mm-content > ul > li > ul > li > a > & {
		text-decoration: underline;
	}

	&.h-space-s {
		margin-bottom: 0;
	}
}