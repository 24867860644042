/* m-basic-keywordfilter--mobilelayer */
.m-basic-keywordfilter--mobilelayer {
	width: 100%;

	@include mq(tablet) {
		padding: 25px 25px 0 25px;
		border: 1px solid $color-secondary-9;
		border-radius: 10px;
	}

	> .mm-mobile-btn-wrapper {
		display: flex;
		justify-content: flex-end;

		> .mm-mobile-btn {
			position: relative;
			padding: 4px 12px 4px 32px;
			border: 1px solid $color-secondary-9;
			border-radius: 15px;
			cursor: pointer;

			@include mq(tablet) {
				display: none;
			}

			&:before {
				display: block;
				content: '';
				position: absolute;
				left: -2px;
				top: -4px;
				@include main-icon-filter;
			}
		}
	}

	> .mm-mobile-layer {
		display: none;

		@include mq(tablet) {
			display: block;
		}

		&.is-visible {
			display: block;
			padding-bottom: 40px;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $color-primary-2;
			z-index: 1000;
			overflow: auto;

			@include mq(tablet) {
				position: static;
				overflow: inherit;
			}
		}

		> .mm-mobile-layer-head {
			min-height: 62px;
			padding: 18px 60px 18px 20px;
			border-bottom: 1px solid $color-secondary-9;
			@include milo-bold(18px);

			@include mq(tablet) {
				min-height: inherit;
				margin-bottom: 17px;
				padding: 0;
				border: none;
				@include milo-bold(19px);
			}

			&:empty {
				margin-bottom: 0;
			}
		}

		> .mm-mobile-layer-content {
			padding: 20px 20px 80px 20px;

			@include mq(tablet) {
				padding: 0;
			}

			&:before {
				display: block;
				content: '';
				position: fixed;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 80px;
				background: $color-primary-2;
				z-index: 1000;
				box-shadow: 0px 10px 20px $color-primary-3;

				@include mq(tablet) {
					display: none;
				}
			}

			> .mm-flex {
				max-width: 400px;

				@include mq(tablet) {
					display: flex;
					max-width: inherit;
				}

				> .mm-item {
					margin-bottom: 25px;

					@include mq(tablet) {
						margin-right: 20px;
						margin-bottom: 0;
					}

					&:first-child {
						margin-bottom: 19px;
					}

					&:last-child {
						margin-right: 0;
					}

					&--search {
						@include mq(tablet) {
							width: 260px;
						}

						@include mq(desktop-xl) {
							width: 300px;
						}
					}

					&--km {
						@include mq(tablet) {
							width: 100px;
						}
					}

					&--separator {
						margin-bottom: 22px;
						border-bottom: 1px solid $color-secondary-9;

						@include mq(tablet) {
							width: 1px;
							height: 68px;
							border-right: 1px solid $color-secondary-9;
						}
					}

					&--filter {
						@include mq(tablet) {
							width: 220px;
						}
					}

					&--btn {
						@include mq(tablet) {
							padding-top: 31px;
						}

						> .a-basic-btn {
							position: fixed;
							bottom: 20px;
							width: calc(100% - 40px);
							z-index: 2000;

							@include mq(tablet) {
								position: static;
								width: 100%;
							}
						}
					}

					&--no-padding {
						@include mq(tablet) {
							padding-top: 0;
						}
					}

					> .mm-label {
						margin-bottom: 9px;
						@include milo-bold();
					}
				}
			}

			> .a-basic-btn {
				position: fixed;
				bottom: 20px;
				width: calc(100% - 40px);
				z-index: 2000;

				@include mq(tablet) {
					display: none;
				}
			}

			> .mm-filterstatusbar {
				display: none;
				padding-top: 7px;
				padding-bottom: 5px;


				&.is-visible {
					display: block;

					@include mq(tablet) {
						display: flex;
						flex-wrap: wrap;
					}
				}

				> div {
					margin-right: 20px;

					&.mm-label {
						margin-bottom: 20px;
					}

					&.mm-filterbox {

						@include mq(tablet) {
							display: flex;
							flex-wrap: wrap;
						}

						> .mm-filter-item {
							display: table;
							position: relative;
							top: -7px;
							margin-right: 10px;
							margin-bottom: 10px;
							padding: 6px 35px 5px 10px;
							border: 1px solid $color-secondary-9;
							border-radius: 5px;
							@include milo-bold(16px);

							&:last-child {
								margin-right: 0;
							}

							> span {
								display: flex;
								align-items: center;
								flex-direction: row-reverse;

								img {
									margin-right: 5px;
								}
							}

							> .mm-filter-delete {
								display: block;
								content: '';
								position: absolute;
								right: -4px;
								top: -5px;
								@include main-icon-close-grey;
								cursor: pointer;
							}
						}
					}

					&.mm-reset {
						position: relative;
						top: -1px;
						margin-bottom: 22px;
						padding-left: 30px;
						cursor: pointer;
						@include font-size(17px);

						&:before {
							display: block;
							content: '';
							position: absolute;
							left: -10px;
							top: -11px;
							@include main-icon-cancel;
						}
					}
				}
			}
		}

		> .mm-close {
			position: absolute;
			top: 10px;
			right: 16px;
			@include main-icon-close-grey;
			cursor: pointer;

			@include mq(tablet) {
				display: none;
			}
		}
	}
}