/* a-basc-link--icon */
.a-basic-link--icon {
	background-position-y: 1px;

	&:after {
		height: 32px;
	}

	&:before {
		top: -8px;
	}

	> .aa-link-icon-wrapper {
		display: inline-block;

		> img,
		svg {
			vertical-align: middle;
			margin-left: 10px;

			&:first-child {
				margin-left: 0;

				@include mq(tablet) {
					margin-left: 10px;
				}

				// exception .m-basic-header
				.m-basic-header > .mm-navi-wrap > .mm-main > .mm-main-btn > .mm-section & {
					@include mq(tablet) {
						margin-left: 0;
					}

				}
			}

			&.aa-bg-yellow {
				background: $color-primary-1;
			}

			&.aa-align-top {
				margin-bottom: 20px;
			}

			// exception .m-basic-header
			.m-basic-header > .mm-navi-wrap > .mm-main > .mm-main-btn > .mm-section & {
				padding: 12px;

				@include mq(tablet) {
					padding: 8px;
				}
			}
		}

		> svg {
			width: 48px;
			height: 48px;

			// exception .m-basic-header
			.m-basic-header > .mm-navi-wrap > .mm-main > .mm-main-btn > .mm-section & {
				padding: 12px;
			}
		}

		// exception .m-basic-header
		.m-basic-header > .mm-navi-wrap > .mm-main > .mm-main-btn > .mm-section & {
			@include absolute(50%, 5px);
			transform: translate(0, -50%);
			border-radius: 50%;
			overflow: hidden;
		}
	}
}