.o-form-membership {
	.alert {
		padding: 8px 15px;
		border: 1px solid;
		color: #fff;
		margin-bottom: 5px;

		&.success-message {
			background-color: #41a457;
			border-color: #3a8f4d
		}

		&.error-message {
			background-color: #a44141;
			border-color: #8f3a3a
		}
	}
}