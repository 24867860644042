/* m-basic-image */

.m-basic-image {
	position: relative;

	@include mq(print) {
		page-break-before: avoid;
		page-break-inside: avoid;
	}

	&.h-space-s {
		margin-bottom: 0;
	}

	&.h-space-m {
		margin-bottom: 20px;
	}

	&.h-space-l {
		margin-bottom: 40px;
	}

	> .mm-headline {
		padding: 0 20px;
		color: $color-secondary-1;
		width: 100%;
		display: block;
		margin: 0 0 8px 0;

		@include mq(tablet) {
			margin-bottom: 0;
			padding: 0;
			@include absolute(25px, 29px);
			color: $color-primary-2;
			width: 50%;
			overflow: hidden;
		}

		@include mq(desktop) {
			max-height: 75px;
			overflow: hidden;
			@include absolute(35px, 38px);
			width: 50%;
			height: 100%;
		}

		&--dark {
			color: $color-secondary-1;
		}

		> h1 {
			margin: 0;
			padding: 0;
			@include font-size(24px, 29px);

			@include mq(tablet) {
				@include font-size(35px, 35px)
			}
		}
	}

	> .mm-logo {
		position: absolute;
		right: 0;
		top: -60px;

		@include mq('tablet') {
			top: -90px;
		}
	}


	> figure {
		margin: 0;
		width: 100%;
		padding: 0 0 10px 0;
		position: relative;

		@include mq(desktop) {
			padding-bottom: 21px;
		}

		.m-basic-teaser-wrapper--product &,
		.m-basic-teaser-wrapper--product-details & {
			padding: 0 0 10px 0;

			@include mq(tablet) {
				padding: 0 0 5px 0;
			}
		}

		.m-basic-teaser-wrapper--product-additional & {
			padding: 0 0 10px 0;

			@include mq(tablet) {
				padding: 0 0 20px 0;
			}
		}

		// exception for m-my-adac-info-box--is-split
		.m-my-adac-info-box .mm-info-box-wrapper-img & {
			padding: 0;
		}

		// exception for m-vehicles-results
		.m-vehicles-results > .mm-models > .mm-result-row > .mm-image-col > & {
			padding-bottom: 0;
		}

		> img:not(.h-img-fluid-smartphone-only),
		> .mm-stopper-wrapper img:not(.h-img-fluid-smartphone-only) {
			width: 100%;
			height: auto;
		}

		> .mm-stopper-wrapper {
			position: relative;

			&::before {
				.m-basic-movie & {
					content: '';
					position: absolute;
					z-index: 5;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 0.3;
					background-color: $color-primary-3;
				}
			}

			> .mm-ratio-container {
				position: relative;

				&:not(.is-lazyloaded) {
					background: $color-secondary-6;

					&::before {
						animation: swiper-preloader-spin 1s steps(12, end) infinite;
						display: block;
						z-index: 100;
						position: absolute;
						top: calc(50% - 34px);
						left: calc(50% - 34px);
						content: '';
						opacity: 1;
						transition: opacity 300ms;
						@include main-icon-refresh-48px;

						// not for youtube link movies
						.mm-youtube-link-container & {
							display: none;
						}
					}

					&::after {
						content: '';
						display: block;
						height: 0;
						width: 100%;
						/* 16:9 = 56.25% = calc(9 / 16 * 100%) */
						padding-bottom: 41.322314%;

						@include mq(desktop) {
							width: 403px;
						}

						// not for youtube link movies
						.mm-youtube-link-container & {
							display: none;
						}
					}

					> * {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}

				> picture {
					max-width: 100%;
					width: 100%;
					display: block;

					@include mq(tablet) {
						width: auto;
					}

					> img {
						max-width: 100%;
						width: 100%;
						display: block;

						.m-basic-table-toggle & {
							width: auto;
						}
					}
				}
			}
		}

		// exception m-basic-movie
		.m-basic-movie & {
			padding-bottom: 0;
		}

		.mm-ratio-container {
			position: relative;

			&:not(.is-lazyloaded) {
				background: $color-secondary-6;

				&::before {
					animation: swiper-preloader-spin 1s steps(12, end) infinite;
					display: block;
					z-index: 100;
					position: absolute;
					top: calc(50% - 17px);
					left: calc(50% - 17px);
					content: '';
					opacity: 1;
					transition: opacity 300ms;
					width: 34px;
					height: 34px;
					@include main-icon-refresh-48px;
				}

				&:after {
					content: '';
					display: block;
					height: 0;
					width: 100%;
					/* 16:9 = 56.25% = calc(9 / 16 * 100%) */
					padding-bottom: 52.109181%;

					@include mq(desktop) {
						width: 403px;
					}
				}

				> * {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}

			> img {
				max-width: 100%;
			}
		}

		> .mm-figurecaption {
			padding: 7px 20px 0 0;
			width: 100%;
			font-size: 78%;

			@include mq(tablet) {
				padding-right: 30px;
			}

			@include mq(desktop) {
				padding-right: 40px;
			}

			&--right {
				text-align: right;
				padding-right: 0;
				padding-left: 20px;

				@include mq(tablet) {
					padding-left: 30px;
				}

				@include mq(desktop) {
					padding-left: 40px;
				}
			}

		}

	}

	// exception for m-basic-table--tests
	.m-basic-table--tests > tbody td.mm-product-cell & {
		display: inline-block;
		width: 120px;
		height: 90px;
		top: 1px;
		margin-right: 10px;

		> figure {
			padding: 0;

			> .mm-stopper-wrapper {
				border: 1px solid $color-secondary-5;
			}
		}
	}

	.m-basic-table-toggle & {
		max-height: 50px;
		width: 60px;

		@include mq('tablet') {
			width: 80px;
		}

		> figure {
			padding: 0;
		}
	}

	// exception for m-vehicles-results
	.m-vehicles-results > .mm-models > .mm-result-row > .mm-image-col > & {
		max-width: 120px;
	}
}