.m-basic-teaser-box {
	position: relative;
	padding: 20px;
	border: 1px solid $color-secondary-9;
	border-radius: 8px;

	> .mm-attention {
		position: absolute;
		top: -18px;
		text-transform: uppercase;
		color: $color-secondary-1;
		right: 0;
		@include font-size($font-size-12);
	}

	> .mm-flex-content {
		@include mq('tablet') {
			display: flex;
		}

		> .mm-img {
			margin-bottom: 10px;

			@include mq('tablet') {
				margin-bottom: 0;
				margin-right: 20px;
				max-width: 340px;
			}

			figure {
				margin: 0;

				img {
					width: 100%;
					max-width: 100%;

					@include mq('tablet') {
						width: auto;
						max-width: none;
					}
				}
			}
		}
	}
}