.m-ves-rs-list {
	visibility: hidden;
	display: none;

	@include mq(tablet) {
		visibility: visible;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: center;
	}

	> .mm-variant-teaser {
		@include mq(tablet) {
			text-align: center;
			max-width: 150px;
			margin-right: 62px;
		}

		&:last-child {
			margin-right: 0;
		}

		img {
			@include mq(tablet) {
				margin: 0 auto 5px auto;
				display: block;
			}
		}
	}

	&.h-space-s {
		margin-bottom: 5px;
	}

	&.h-space-m {
		margin-bottom: 15px;
	}

	&.h-space-l {
		margin-bottom: 44px;
	}
}