/* a-basic-input-radio--my-adac */
.a-basic-input-radio--my-adac {

	.l-outer--my-adac & {
		margin: 0 -8px;
	}

	.l-outer--my-adac-content & {
		float: none;
		margin-right: 0;
	}

	// Exception ll-col-data
	.l-form .ll-col-label + .ll-col-data & {
		max-width: 370px;
	}

	.l-form-general--my-adac .ll-col-data &:not(.a-basic-input-radio--haptik) {
		width: 100%;
	}

	> input {
		@include milo-regular(16px, 20px);
	}

	.ll-row--sep & {

		@include mq(tablet) {
			float: left;
			margin-right: 50px;
		}
	}
}