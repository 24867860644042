/* a-basic-btn--full-textcenter*/
.a-basic-btn--full-textcenter {
	width: 100%;
	text-align: center;
	justify-content: center;
	max-width: inherit;

	@include mq(tablet) {
		width: fit-content;
		max-width: 340px;
		justify-content: initial;
	}
}