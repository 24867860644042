.o-layout-main-stage--search {

	> .swiper {

		> .swiper-wrapper {

			> .swiper-slide {

				> .oo-box {
					bottom: auto;
					padding: 10px;
					margin: 0;
					height: auto;

					@include mq(tablet) {
						top: 50%;
						padding: 25px 40px 10px;
						margin: 0;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 688px;
					}

					@include mq(desktop) {
						width: 845px;
					}

					.oo-box-head {
						max-width: 100%;
						line-height: 28px;
						@include milo-slab-bold();

						@include mq(desktop) {
							line-height: 50px;
						}

						@include mq(tablet) {
							@include font-size($font-size-36);
						}
					}

					.oo-box-text {
						height: auto;
						max-width: none;
					}

					.l-form,
					.l-form-general {
						display: flex;
						justify-content: flex-start;

						.ll-fieldset {
							margin-bottom: 10px;

							> .ll-row {
								max-width: 100%;
							}

							> .ll-row > .ll-col-data {
								width: 100%;
								float: none
							}

							> .ll-row > .ll-col-label {
								float: none;
								width: auto;
							}

							&:nth-child(1) {
								flex-grow: 1;
							}
						}
					}
				}

			}
		}
	}

	// exception if input-autocomplete sets a noresult class -> prevents cutting off overlay
	&.is-noresult {
		overflow: visible;
	}

	// exception if searchbox is placed inside of wide stage area
	&.o-layout-main-stage--wide {
		margin-bottom: 10px;

		@include mq(tablet) {
			margin-bottom: 18px;
		}
	}
}