.m-basic-toggle--my-adac {

	.mm-head {
		padding-left: 0;
		background: none;

		&:before {
			content: " ";
			position: absolute;
			display: block;
			width: 24px;
			height: 24px;
			@include main-icon-arrow-right-grey;
			transform: rotate(90deg);
		}

		> p {
			@include milo-regular($font-size-18);
			padding-left: 75px;
		}

		&.mm-opened {
			background: none;

			&:before {
				@include main-icon-arrow-right-grey;
				transform: rotate(-90deg);
			}

			+ .mm-content {
				padding-left: 35px;
			}
		}
	}
}