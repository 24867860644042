.m-basic-range-slide--single-selection {

	padding: 0 20px 0 10px;

	> .mm-label {
		margin-bottom: 35px;
	}

	.noUi-base {

		&:before {
			height: 26px;
			width: calc(100% + 28px);
			left: -14px;
			top: -12px;
		}

		.noUi-origin {
			background-color: transparent;

			> .noUi-handle {
				position: absolute;
				width: 34px;
				height: 34px;
				transform: translate(5px, -5px);

				&:after {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 10px;
					height: 10px;
					background: $color-primary-3;
					border-radius: 50%;
					display: block;
				}
			}
		}
	}

	.noUi-pips-horizontal {

		.noUi-marker-horizontal {
			width: 26px;
			height: 26px;
			margin-left: -12.5px;
			border: 2px solid $color-primary-3;
			background-color: $color-primary-2;
			top: -6px;

			.is-keyboard-focus &:focus-visible,
			.is-keyboard-focus &:focus {
				outline: none;

				&:after {
					content: '';
					position: absolute;
					top: -5px;
					left: -5px;
					width: 32px;
					height: 32px;
					border: 2px solid $color-primary-3;
					border-radius: 50%;
				}
			}

			&.is-active {
				.is-keyboard-focus &:focus {
					width: 44px;
					height: 44px;
					top: -15px;
					transform: translateX(-9px);
					border: 2px solid $color-primary-3;

					&:after {
						display: none;
					}
				}
			}
		}

		.noUi-value-horizontal {
			top: -10px;
			@include milo-bold(16px);
			transform-origin: 300% 600%;
			transition: transform .2s ease-in;

			&.is-active {
				transform: scale(1.2);
			}
		}
	}
}