/* m-my-adac-dashboard-box-showcase--block */
.m-my-adac-dashboard-box--block {
	position: relative;

	> .mm-wrapper {
		> .mm-dashbox-theme-wrapper {
			> .mm-dashbox-theme {
				> .mm-dashbox-theme-option-layer {
					margin-bottom: 15px;
					z-index: 10;
					order: 2;
					flex: 0 0 auto;

					> .mm-theme-option {
						display: flex;
						justify-content: right;
						position: relative;

						// Firefox needs this, else it only prints blank/content/blank sites
						@include mq(print) {
							display: block;
						}

						> .mm-option-wrapper {
							display: flex;
							cursor: pointer;
							min-height: 44px;

							// Firefox needs this, else it only prints blank/content/blank sites
							@include mq(print) {
								display: block;
							}

							> .mm-icon {
								@include main-icon-list-grey2;
								top: -11px;
								left: 20px;
								position: relative;

								@include if-parent('.mm-theme-option.is-open') {
									display: none;
								}
							}

							> .mm-text {
								margin: 0;
								padding: 0 0 0 25px;
							}
						}
					}
				}

				> .mm-box-wrapper {
					> .mm-box {
						position: relative;

						.mm-box-headline {
							position: relative;
							padding-top: 22px;
							padding-bottom: 31px;
							border-bottom: 1px solid $color-secondary-9;
							cursor: pointer;
							padding-right: 50px;
							display: flex;
							align-items: center;
							column-gap: 40px;

							@include mq(desktop) {
								padding: 23px 45px 16px 30px;
							}

							&:not(.js-headline-toggle) {
								cursor: default;
							}

							h2 {
								max-width: 700px;
								margin: 0;
								padding: 0;
								font-weight: bolder;
								@include milo-bold($font-size-20, 21px);

								@include mq(tablet) {
									@include font-size($font-size-24, 24px);
								}
							}

							> .mm-headline-toggle {
								@include main-icon-arrow-right-black;
								position: absolute;
								right: 0;
								top: calc(50% - 5px);
								width: 44px;
								height: 44px;
								transform: translateY(-50%) rotate(90deg);
								transition: all 0.3s;

								@include mq(tablet) {
									top: calc(50% + 2px);
								}

								@include if-parent('.mm-box-headline.is-open') {
									transform: translateY(-50%) rotate(270deg);
								}

								@include if-parent('.mm-box-headline:not(.js-headline-toggle)') {
									display: none;
								}
							}
						}

						.mm-content-wrapper {
							position: relative;
							padding-top: 30px;
							margin-bottom: 60px;

							@include mq(desktop) {
								margin-bottom: 90px;
							}

							> .mm-edit {
								display: block;
								@include main-icon-edit;
								position: absolute;
								right: 0;
								top: 10px;
								width: 44px;
								height: 44px;

								@include mq(tablet) {
									top: 28px;
								}

								& + .mm-content {
									padding-top: 17px;

									@include mq(tablet) {
										padding: 0;
									}
								}
							}

							> .mm-content {
								&:not(.mm-breaker) {
									@include mq(desktop) {
										padding: 0 30px;
									}
								}

								&--flex {
									@include mq(tablet) {
										display: flex;
										column-gap: 20px;
									}
								}

								&--flex-reverse {
									display: flex;
									row-gap: 20px;
									flex-direction: column;

									@include mq(tablet) {
										column-gap: 20px;
										row-gap: 0;
										flex-direction: row-reverse;
									}
								}

								.mm-content-headline {
									h5 {
										@include milo-regular(16px);
										margin-bottom: 10px;
									}
								}

								> .mm-timerefresh {
									position: relative;
									line-height: 18px;

									@include mq(desktop) {
										margin-top: 14px;
									}

									> .mm-timerefresh-content {
										border-top: 1px solid $color-secondary-3;
										border-bottom: 1px solid $color-secondary-3;
										position: relative;
										padding: 10px 5px;
										margin-bottom: 30px;

										display: flex;
										flex-wrap: wrap;
										align-items: center;


										> .mm-timerefresh-wrapper {
											flex: 1;

											> .mm-timestamp {
												white-space: nowrap;
												color: $color-secondary-3;
											}
										}

										> .mm-refresh-icon {
											width: 30px;
											height: 30px;
											background-color: $color-primary-1;
											cursor: pointer;

											> img {
												margin: 3px 0 0 3px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}