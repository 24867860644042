/* a-basic-btn--inline */
.a-basic-btn--inline {
	display: inline-block;
	margin-right: 18px;

	// clear margin for last <a> elem
	&:last-of-type {
		margin-right: 0;
	}

	& + .a-basic-link {
		margin-left: 15px;
		display: inline-block;
	}

	.m-basic-table &.h-space-s {
		margin-bottom: 15px;
	}

	.m-basic-hint--center & {
		& + .a-basic-link {
			margin-top: 15px;

			@include mq('tablet') {
				margin-top: 0;
			}
		}
	}

	.m-basic-hint--btnbox & {
		& + .a-basic-link {
			margin-top: 15px;

			@include mq('tablet') {
				margin-top: 0;
			}
		}
	}
}