/* m-ves-rs-sticky */
.m-ves-rs-sticky {
	background: $color-secondary-10;
	width: 100vw;
	position: fixed;
	bottom: 0;
	left: 0;
	margin: 0 auto;
	z-index: 51;
	padding: 19px 0 5px 0;
	border-top: 2px solid $color-primary-1;
	display: none;

	@include mq(tablet) {
		left: 50%;
		transform: translate3d(-50%, 0, 0);
		padding: 19px 0 15px 0;
	}

	&.is-visible {
		display: block;
	}

	&.is-stuck-bottom {
		position: absolute;
		bottom: -150px;

		&.is-social-sharing {
			bottom: calc(-171px - 75px);
		}
	}

	&--interfaceerror {
		padding: 10px 0;
	}

	> .mm-layer {
		border-top: 2px solid $color-primary-2;
	}

	> .mm-layer-contact {
		background: $color-secondary-8;
		width: 100%;
		height: calc(100vh - 220px);
		@include absolute();
		z-index: 10;
		padding: 0 15px;
		overflow: auto;
		display: none;
		transition: all 0.5s ease;

		@include mq(tablet) {
			height: auto;
		}

		&.is-open {
			display: block;
		}

		> .mm-layer-contact-inner {
			max-width: 898px;
			margin: 0 auto;
			padding: 20px 5px 50px 5px;
			height: 310px;
			position: relative;

			@include mq(tablet) {
				padding: 30px 5px 15px 5px;
				height: 420px;
			}

			@include mq(desktop) {
				padding: 30px 0 23px 0;
			}

			> .mm-close-icon {
				cursor: pointer;
				z-index: 15;
				@include absolute($right: -40px, $top: 0);

				@include mq(tablet) {
					right: -45px;
				}

				@include mq(desktop) {
					right: -45px;
				}

				@include mq(desktop-l) {
					@include absolute($right: -166px);
					align-self: auto;
				}

				&::before {
					content: '';
					@include main-icon-close;
					position: absolute;
					top: -8px;
					right: -10px;
				}

				> img {
					display: none;
				}
			}

			> .m-ves-tab {
				position: relative;
				max-width: 100%;

				@include mq(tablet) {
					left: -30px;
				}

				> .m-nav {
					padding: 0;
					display: flex;
					flex-direction: column;
					align-items: baseline;

					@include mq(tablet) {
						flex-direction: row;
					}

					> .m-nav-btn {
						width: 100%;

						@include mq(tablet) {
							border-bottom: none;
							width: auto;
						}

						&.is-active {
							@include mq(tablet) {
								border-bottom: 2px solid $color-primary-1;
							}

							> .mm-nav-link {
								@include mq(tablet) {
									> .mm-triangle {
										display: block;
									}
								}
							}

							> .m-tabcontent {
								border-bottom: 1px solid $color-secondary-3;
								padding: 20px 0;
								float: left;

								@include mq(tablet) {
									border-bottom: none;
									float: none;
								}
							}
						}

						> .mm-nav-link {
							@include milo-bold();
							font-weight: normal;
							text-decoration: none;
							display: block;
							position: relative;
							padding: 10px 30px 10px 25px;
							border-bottom: 1px solid $color-secondary-3;

							@include mq(tablet) {
								padding: 10px 30px 7px 52px;
								border-bottom: none;
							}

							&:focus {
								outline: none;
							}

							img {
								display: none;
								@include absolute($left: 0, $top: 11px);
								max-height: 18px;

								@include mq(tablet) {
									top: 11px;
									left: 27px;
								}
							}

							.mm-triangle {
								width: 0;
								height: 0;
								padding: 0;
								border-left: 16px solid transparent;
								border-right: 16px solid transparent;
								border-top: 13px solid $color-primary-1;
								display: none;
								@include absolute($bottom: -15px, $left: 50%);
								margin-left: -16px;
							}

							&::before {
								content: '';
								position: absolute;
								top: -2px;
								left: -10px;
								transform: scale(0.8);

								@include mq(tablet) {
									left: 13px;
								}
							}

							&--phone {
								&::before {
									@include main-icon-phone;
								}
							}

							&--contact {
								&::before {
									@include main-icon-email;
								}
							}

							&--location {
								&::before {
									@include main-icon-adac-location;
									transform: scale(1);
									top: 1px;
									left: -10px;

									@include mq(tablet) {
										left: 17px;
									}
								}
							}
						}

						> .m-tabcontent {
							width: 100%;

							@include mq(tablet) {
								@include absolute($left: 30px, $top: 55px);
							}

							> .m-tabcontent-inside {

								> .m-teaser-hotline {
									width: 100%;
									margin-bottom: 20px;

									@include mq(tablet) {
										width: calc(100% / 2);
										float: left;
									}

									&:last-child {
										margin-bottom: 0;
									}

									> span {
										display: block;
										@include milo-bold();
										font-weight: normal;
									}

									> a {
										display: block;
										text-decoration: none;
										margin: 5px 0;
										@include milo-bold($font-size-24);
									}
								}
							}
						}

						&--3 {
							padding-bottom: 25px;

							@include mq(tablet) {
								padding-bottom: 0;
							}
						}
					}
				}
			}
		}
	}

	> .mm-layer-details {
		background: $color-secondary-8;
		width: 100%;
		height: calc(100vh - 220px);
		@include absolute();
		z-index: 12;
		padding: 0 15px;
		overflow: auto;
		margin-bottom: -2px;
		border-bottom: 2px solid $color-primary-1;
		display: none;

		@include mq(tablet) {
			padding: 0 15px;
			height: auto;
		}

		&.is-open {
			display: block;
		}

		> .mm-layer-details-inner {
			max-width: 898px;
			margin: 0 auto;
			padding: 30px 5px 15px 5px;
			position: relative;

			@include mq(desktop) {
				padding: 30px 0 23px 0;
			}

			> .mm-close-icon {
				cursor: pointer;
				z-index: 15;
				@include absolute($right: -40px, $top: 0);

				@include mq(tablet) {
					right: -45px;
				}

				@include mq(desktop) {
					right: -45px;
				}

				@include mq(desktop-l) {
					@include absolute($right: -166px);
					align-self: auto;
				}

				&::before {
					content: '';
					@include main-icon-close;
					position: absolute;
					top: -8px;
					right: -10px;
				}

				> img {
					display: none;
				}
			}

			> .mm-icon-link {
				cursor: pointer;
				z-index: 15;
				@include absolute($right: 26px, $top: 17px);

				@include mq(desktop-l) {
					@include absolute($right: -99px);
					align-self: auto;
				}

				> span {
					position: relative;

					&::before {
						content: '';
						@include main-icon-edit;
						position: absolute;
						top: 5px;
						right: -5px;
					}

					> img {
						display: none;
					}
				}
			}


			hr {
				margin: 15px 0;
				border-color: $color-secondary-1;
			}
		}
	}

	> .mm-sticky-module {
		margin: 0 auto;
		@include font-size($font-size-16);

		@include mq(tablet) {
			@include font-size($font-size-24);
		}

		> .mm-layer-btn {
			@include absolute($top: -33px);
			width: 100%;

			> .mm-layer-btn-inner {
				max-width: 898px;
				margin: 0 auto;
				padding: 0 15px;

				@include mq(tablet) {
					padding: 0 20px;
				}

				@include mq(desktop) {
					margin: 0 auto;
					padding: 0;
				}

				> .mm-btn-contact {
					background: $color-secondary-10;
					width: 195px;
					height: 33px;
					text-align: center;
					cursor: pointer;
					@include milo-bold($font-size-18);
					padding: 4px 10px 5px 20px;
					position: relative;
					z-index: 11;
					border-top: 2px solid $color-primary-1;
					border-right: 2px solid $color-primary-1;
					border-left: 2px solid $color-primary-1;

					&:before {
						content: "";
						@include main-icon-expand-less;
						@include absolute($top: -6px, $left: -5px);
					}

					&.is-opened {
						background-color: $color-primary-1;
						border-color: $color-primary-2;
						transform: translate(0, -2px);
						padding-top: 6px;

						&:before {
							@include main-icon-expand-more;
							top: -3px;
						}
					}
				}
			}
		}

		> .mm-layer-content {
			@include milo-bold();
			font-weight: normal;
			padding: 0 20px;

			> .mm-layer-content-inner {
				position: relative;
				max-width: 898px;
				margin: 0 auto;
				display: flex;
				flex-wrap: nowrap;
				flex-direction: column;

				@include mq(tablet) {
					flex-wrap: wrap;
					padding: 0;
				}

				@include mq(desktop-l) {
					flex-direction: row;
					align-items: center;
				}

				&--error {
					@include milo-regular($font-size-16, 18px);
					max-width: 887px;
					margin: 0 auto;
					padding: 12px 0 10px;
					color: $color-primary-2;
					background-color: $color-action-alert;

					&:before {
						content: '';
						@include main-icon-error-symbol-white;
						@include absolute($top: -2px, $left: 0);
					}

					@include mq(desktop) {
						width: 887px;
						height: 40px;
					}

					> .mm-interface-error-text {
						display: block;
						padding: 0 10px 0 40px;
						font-weight: normal;
						@include font-size($font-size-14);

					}

				}

				> .mm-layer-contribution {
					margin-right: 0;
					order: 1;
					position: relative;
					display: flex;
					flex-direction: column;
					line-height: 15px;
					margin-bottom: 7px;

					@include mq(tablet) {
						min-height: 76px;
						max-width: 425px;
					}

					@include mq(desktop) {
						margin-right: 30px;
					}

					@include mq(desktop-l) {
						margin-right: 80px;
					}

					> .mm-contribution {
						position: relative;
						margin-bottom: 5px;
						order: 1;

						@include mq(tablet) {
							margin-top: 15px;
						}

						> .mm-totalamount {
							@include font-size($font-size-24);
							float: right;

							@include mq(tablet) {
								@include font-size($font-size-32);
								margin-left: 10px;
							}

							> .mm-interval {
								@include milo-regular($font-size-15);
							}
						}
					}

					> .mm-membership {
						order: 2;
						@include milo-regular($font-size-13);

						@include mq(desktop) {
							margin-left: 77px;
						}

						> .mm-totalamount {
							@include milo-bold($font-size-13);
							float: right;

							> .mm-interval {
								@include milo-regular($font-size-13);
							}
						}
					}

					> .mm-icon-link {
						order: 3;
						margin-top: 15px;
						width: 24px;
						height: 24px;

						@include mq(tablet) {
							margin-top: 0;
							@include absolute($bottom: auto, $left: auto, $top: 12px, $right: -33px);
						}
					}
				}

				> .mm-layer-links {
					order: 3;
					align-self: flex-end;
					display: flex;
					justify-content: flex-end;
					align-items: baseline;

					@include mq(tablet) {
						margin-top: 15px;
					}

					@include mq(desktop-l) {
						position: absolute;
						top: -12px;
						right: 160px;
					}
				}
			}
		}
	}
}