/* m-basic-anchornav */
.m-basic-anchornav {
	position: relative;

	@include mq(tablet) {
		padding-top: 78px;
	}

	> .mm-anchorlist {

		@include mq(tablet) {
			display: block;
			position: absolute;
			width: 100%;
			background: $color-primary-2;
			margin: 0 0 42px;
			z-index: 45;
			top: 0;
			box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
				0 1px 10px 0 rgba(0, 0, 0, 0.12),
				0 4px 5px 0 rgba(0, 0, 0, 0.14);
			border-radius: 5px 5px 0 0;
		}

		@include mq(print) {
			border: none;
			display: none;
		}

		&.is-stuck {
			z-index: 100;
		}

		> ul {
			margin: 0 -20px 30px;
			padding: 0;

			@include mq(tablet) {
				margin: 0;
				overflow: hidden;
				display: flex;
				flex: 0 0 auto;
				flex-direction: row;
			}

			&:after {
				content: '';
				width: 100%;
				left: 0;
				right: 0;
				height: 16px;
				top: 36px;
				background: $color-primary-2;
				position: absolute;
				z-index: -1;

				@include mq(tablet) {
					content: initial;
				}
			}

			.is-keyboard-focus &:focus-visible {
				outline: none;
			}

			> .mm-anchor {
				background: $color-primary-2;
				border-bottom: 1px solid $color-secondary-9;

				@include mq(tablet) {
					padding: 0 12px 500px 12px;
					margin-bottom: -500px;
					height: 100%;
					@include font-size(14px, 18px);
					background: none;
				}

				&:first-child {
					border-top: 1px solid $color-secondary-9;

					@include mq(tablet) {
						border-top: 0 none transparent;
						border-left: 0 none transparent;
					}
				}

				&:hover {
					@include mq(tablet) {
						background: $color-secondary-19;
						border-radius: 5px;
					}

					&:before {
						content: initial;

						@include mq(tablet) {
							content: " ";
							clear: both;
							position: absolute;
							width: 0;
							height: 0;
							padding: 0;
							border-left: 14px solid transparent;
							border-right: 14px solid transparent;
							border-top: 17px solid $color-primary-3;
							bottom: -16px;
							left: var(--indicatorHoveredLeft);
							display: table;
							z-index: -1;
						}
					}
				}


				&.is-shown {
					@include mq(tablet) {
						background: $color-primary-1;
						border-radius: 5px;
					}
				}

				> a {
					position: relative;
					padding: 11px 35px 9px 19px;
					display: block;
					text-decoration: none;
					overflow: hidden;
					@include milo-bold();
					@extend %focus-border;

					@include mq(tablet) {
						border: 2px solid transparent;
						@include milo-slab-bold($font-size-15, 20px);
						font-weight: lighter;
						margin: 0 -12px -1px -12px;
						padding: 10px 15px 10px 15px;
						color: $color-secondary-20;
					}

					&.is-focused {
						@include if-parent('.m-basic-anchornav:focus-within') {
							.is-keyboard-focus & {
								border: 2px solid $color-primary-3;
								border-radius: 5px 5px 0 0;
							}
						}
					}

					&:focus {
						outline: 0 none transparent;

						.is-keyboard-focus & {
							border-radius: 5px 5px 0 0;
						}

						&:not(.is-focused) {
							border-color: transparent !important;
						}
					}

					&:hover {
						@include mq(tablet) {
							border-bottom: 2px solid black;
							color: $color-secondary-17;
						}
					}

					&:before {
						content: '';
						position: absolute;
						@include main-icon-link-big;
						top: -2px;
						right: 0;

						@include mq(tablet) {
							display: none;
						}
					}

					.h-roboto & {
						font-family: 'Roboto Bold', sans-serif;

						@include mq(tablet) {
							font-family: 'Roboto Bold', sans-serif;
						}
					}

					@include if-parent('.mm-anchor.is-shown') {
						@include mq(tablet) {
							border-bottom: 2px solid black;
							color: $color-secondary-17;
						}
					}

					> svg {
						display: none;
					}

					> .mm-arrow {
						display: none;
					}
				}
			}

			> .mm-indicator {
				display: none;
				transition: left 0.3s linear;

				@include mq(tablet) {
					width: 0;
					height: 0;
					padding: 0;
					border-left: 14px solid transparent;
					border-right: 14px solid transparent;
					border-top: 17px solid $color-primary-3;
					bottom: -16px;
					position: absolute;
					left: 15px;
					display: block;
				}

				@include mq(print) {
					display: none;
				}
			}
		}
	}

	> .mm-contentwrapper {

		> .mm-content {
			opacity: 0;
			padding: 0 20px 15px;
			left: 100vw;
			top: 0;
			width: 100%;
			background: $color-primary-2;
			position: fixed;
			overflow-x: auto;
			height: 100%;
			transition: left 400ms ease-in-out;

			@include mq(tablet) {
				padding: 20px 0 30px 0;
				position: relative;
				opacity: 1;
				left: auto;
				width: 100%;
				height: auto;
				overflow: visible;
			}

			&:focus,
			&:focus-within,
			&:focus-visible {
				outline: none;

				&:after {
					.is-keyboard-focus & {
						content: '';
						position: absolute;
						top: 0;
						left: -15px;
						width: calc(100% + 30px);
						height: 100%;
						border: 1px solid $color-secondary-16;
						border-radius: 8px;
						z-index: 3;
					}
				}

				.is-keyboard-focus & {
					outline: none;
				}

				> *:nth-child(2) {
					position: relative;

					&:after {
						.is-keyboard-focus & {
							content: '';
							position: absolute;
							top: 0;
							left: -14px;
							width: 4px;
							height: 100%;
							background-color: $color-primary-1;
							z-index: 3;
						}
					}
				}
			}


			&.mm-zoom-table-full {
				@include mq(tablet) {
					overflow: visible;
				}
			}

			&.is-open {
				padding-top: 65px;
				opacity: 1;
				z-index: 1500;
				left: 0;

				@include mq(tablet) {
					padding-top: 0;
					opacity: 0;
					z-index: -1;
					left: 100vw;
				}
			}

			.mm-sticky-anchor {
				background: $color-primary-2;
				left: 20px;
				top: 0;
				padding: 20px 0 10px;
				margin-bottom: 10px;
				width: 100vw;
				display: none;
				z-index: 100;

				@include mq('tablet') {
					display: inline-block;
					padding: 0;
				}

				&.is-fixed {
					position: fixed;

					@include mq('tablet') {
						position: static;
					}
				}

				.mm-anchorpoint {
					overflow: hidden;
					position: relative;
					cursor: pointer;
					text-decoration: none;
					padding: 0 22px 0 23px;
					margin: 0;
					display: inline-block;
					border: 2px solid $color-primary-3;

					&:before {
						content: '';
						display: inline-block;
						@include main-icon-arrow-left-black;
						position: absolute;
						left: -10px;
						top: -11px;

						@include mq(tablet) {
							content: none;
						}
					}

					@include mq(tablet) {
						position: absolute;
						height: 0;
						width: 0;
						padding: 0;
						border: none;
						display: block;
					}

					> svg {
						position: absolute;
						left: 0;
						top: -1px;
						display: none;
					}

					> .mm-arrow {
						display: none;
					}
				}
			}
		}
	}
}