// m-ds-filter
.m-ds-filter {
	&:not(.is-visible-mobile) {
		display: none;
		position: fixed;
		top: 0;
		background: $color-primary-2;
		z-index: 1005;
		left: 0;
		max-height: 100vh;
		overflow-y: auto;
		height: 100vh;
		max-width: 100vw;
		padding: 20px 0;

		@include mq(tablet) {
			overflow: hidden;
			display: block;
			position: relative;
			z-index: 1;
			max-height: none;
			height: auto;
			padding: 0;
		}
	}

	> .mm-mobile {
		display: flex;
		justify-content: space-between;
		padding: 0 20px;

		@include mq(desktop) {
			display: none;
		}

		@include if-parent('.m-ds-filter.is-visible-mobile') {
			display: none;
		}

		> .js-close {
			@include main-icon-close;
			position: absolute;
			top: 8px;
			right: 10px;
			cursor: pointer;
		}
	}

	> .mm-wrapper {
		margin-bottom: 40px;
		padding: 15px;
		border: 1px solid $color-secondary-16;
		border-radius: 10px;

		@include mq(tablet) {
			padding: 30px;
			margin-bottom: 60px;
			display: flex;
			column-gap: 30px;
		}

		@include if-parent('.m-ds-filter:not(.is-visible-mobile)') {
			border-radius: 0;
			border-left: transparent;
			border-right: transparent;

			@include mq(tablet) {
				border: 1px solid $color-secondary-16;
				border-radius: 10px;
			}
		}

		> .mm-filter {
			flex: 1;
			position: relative;

			> h2 {
				@include mq(desktop) {
					@include font-size($font-size-30);
				}
			}

			.mm-sub-headline {
				margin-bottom: 5px;

				@include mq(tablet) {
					margin-bottom: 10px;
				}
			}

			.mm-filter-row {
				display: flex;
				flex-wrap: wrap;
				column-gap: 10px;
				row-gap: 10px;
				margin-bottom: 30px;

				> .mm-filter-item {
					@extend %input-chips;
				}
			}

			.ll-check:has(+ .mm-filter-row) {
				transform: translateX(100%);
				top: -10px;

				@include mq(desktop) {
					transform: translateX(30px);
				}
			}

			> .mm-location-short-wrapper {
				max-width: 400px;

				@include mq(tablet) {
					max-width: 300px;
				}
			}

			> .mm-location-long-wrapper {
				display: flex;
				column-gap: 10px;
				row-gap: 30px;
				flex-direction: column;
				margin-bottom: 20px;
				max-width: 400px;

				@include mq(tablet) {
					max-width: none;
					flex-direction: row;
				}

				> .mm-location-item {
					flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
				}
			}

			> .mm-additional-headline {
				position: relative;
				cursor: pointer;
				display: inline-block;
				padding-left: 30px;

				> span {
					@include main-icon-arrow-down;
					display: inline-block;
					position: absolute;
					left: -10px;
					top: -11px;
					transform: rotate(180deg);
					transition: all 0.3s;

					@include if-parent('.mm-additional-headline.is-open') {
						transform: rotate(0deg);
					}
				}
			}

			> .mm-additional-content {

				> .mm-additional-wrapper {
					display: flex;
					flex-wrap: wrap;
					flex-direction: column;
					column-gap: 20px;
					row-gap: 30px;
					margin-top: 30px;

					@include mq(tablet) {
						flex-direction: row;
					}

					> .mm-additional-item {
						width: 100%;
						display: flex;
						flex-direction: column;
						justify-content: space-between;

						@include mq(tablet) {
							max-width: calc(50% - 10px);
						}

						&--full {
							@include mq(tablet) {
								max-width: none;
							}
						}

						.mm-chips-wrapper {
							display: flex;
							column-gap: 10px;
							flex-wrap: wrap;
							row-gap: 15px;

							> .mm-chips {
								@extend %input-chips;

								> input.is-checkbox {
									&:checked + label {
										&:after {
											left: -13px;
											top: -13px;
											@include main-icon-check-box;
										}
									}
								}
							}
						}
					}
				}
			}

			> .mm-delete-filter {
				position: relative;
				cursor: pointer;
				display: block;
				padding-left: 30px;
				margin-top: 20px;

				@include mq(tablet) {
					position: absolute;
					margin-top: 0;
					right: 0;
					top: 0;
				}

				> span {
					@include main-icon-cancel;
					display: inline-block;
					position: absolute;
					left: -10px;
					top: -11px;
				}
			}

			> .mm-button-wrapper {
				margin-top: 30px;
				display: flex;
				justify-content: space-between;
			}
		}

		> .mm-image {
			display: none;

			@include mq(desktop) {
				display: block;
				max-width: 395px;
			}
		}
	}

	.mm-error {
		margin-bottom: 5px;
		color: $color-action-alert;
	}
}