/* l-outer */
.l-outer {
	position: relative;

	@include mq(print) {
		max-width: 600px;
		margin: 0 auto;
		overflow-x: hidden;
	}

	&--my-adac {
		&:not(.l-outer--my-adac-dashboard) {
			background-color: $color-secondary-8;
		}
	}

	&--my-adac-content {
		background-color: $color-secondary-8;
	}
}