// m-mgl-product-aside
.m-mgl-product-aside {
	> figure {
		margin: 0;
		padding: 0 0 10px;
		border-bottom: 1px solid $color-secondary-5;

		> img {
			width: 100%;
		}
	}

	> .m-productname {
		@include font-size(18px);
		padding: 15px 0 13px;
	}

	> .m-price {
		font-weight: bold;
		@include font-size(18px);
		padding: 15px 0 13px;
		border-top: 1px solid $color-secondary-5;
	}
}