/* o-basic-banderole--sedcard */
.o-basic-banderole--sedcard {
	.ll-main-center & {
		margin-left: 0;
		margin-right: 0;
	}

	> .swiper {

		> .swiper-wrapper {

			> .swiper-slide {
				background: none;
			}
		}
	}
}