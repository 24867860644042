/* l-main */
.l-main {

	min-height: 600px;
	padding: 65px 0 50px 0;
	width: 100%;
	position: relative;
	left: 0;
	transition: transform .3s ease-in-out;

	@include mq(tablet) {
		padding-top: 110px;
	}

	@include mq(desktop) {
		padding-top: 128px;
	}

	@include mq(print) {
		padding-top: 0;
		padding-bottom: 0;
	}

	&.js-reduced-header {

		@include mq(tablet) {
			padding-top: 73px;
		}

		@include mq(desktop) {
			padding-top: 82px;
		}

		@include mq(print) {
			padding-top: 0;
		}
	}

	&.js-nav-opened {
		transform: translateX(-150px);

		@include mq(tablet) {
			transform: translateX(0);
		}
	}

	// exception if m-basic-header is prev sibling
	.m-basic-header ~ & {
		padding-top: 40px;

		@include mq(desktop) {
			padding-top: 60px;
		}

		@include mq(desktop-l) {
			padding-top: 216px;
		}

		@include mq(print) {
			padding-top: 0 !important;
		}

		@include mq(print) {
			padding-top: 0 !important;
		}
	}

	// exception if m-basic-header is prev sibling and breadcrumb is present
	.m-basic-header + .m-basic-breadcrumb ~ & {
		padding-top: 40px;

		// ~ header-height + breadcr.-height
		@include mq(desktop-l) {
			padding-top: 264px;
		}
	}

	// exception if m-basic-header--reduced is prev sibling
	.m-basic-header--reduced ~ & {

		@include mq(desktop-l) {
			padding-top: 128px;
		}
	}

	// exception if m-basic-header--reduced is prev sibling and breadcrumb is present
	.m-basic-header--reduced + .m-basic-breadcrumb ~ & {
		padding-top: 56px;

		@include mq(tablet) {
			padding-top: 64px;
		}

		@include mq(desktop) {
			padding-top: 160px;
		}
	}

	> .ll-main-language {
		max-width: 898px;
		width: 100%;
		position: absolute;
		top: 20px;
		left: 50%;
		transform: translate(-50%, 0);
		z-index: 100;
		padding: 0 20px 0 0;

		@include mq(tablet) {
			top: 30px;
		}

		@include mq(desktop) {
			top: 50px;
		}

		.m-basic-header--reduced ~ & {

			@include mq(desktop) {
				top: 35px;
			}

			@include mq(desktop-l) {
				top: 100px;
			}
		}

		&--full {
			max-width: 100%;
			right: 0;
			left: auto;
			transform: translate(0, 0);

			@include mq(desktop-xl) {
				right: 10%;
			}
		}
	}

	.ll-main-center {
		position: relative;

		@include mq(tablet) {
			padding: 0 30px;
			max-width: $layout-main-center;
			margin: 0 auto;
		}

		@include mq(desktop) {
			padding: 0;

			&--cluster {
				max-width: 927px;
			}

			.l-outer--nav & {
				max-width: 1180px;
				margin: 0;
				padding: 0;
			}
		}

		@include mq(desktop-l) {
			margin: 0 auto;
			padding: 0;

			.l-outer--nav & {
				margin: 0 auto;
			}
		}

		@include mq(desktop-xl) {
			.l-outer--nav & {
				max-width: $layout-header-center;
				padding: 0;
			}
		}

		@include mq(print) {
			padding: 0;
		}

		// exception for myAdac Dashboard Boxes
		.l-outer--my-adac & {
			max-width: ($layout-my-adac-main-center + 60px);
			margin: 0 auto;
			padding: 0 10px;

			@include mq(tablet) {
				padding: 0 30px;
			}
		}

		// exception for myAdac contenten sites
		.l-outer--my-adac-content & {
			max-width: ($layout-my-adac-content-main-center + 60px);
			margin: 0 auto;
			padding: 0 20px;
			background: transparent;

			@include mq(tablet) {
				padding: 0 30px;
			}
		}

		// exception for myAdac Dashboad new
		.l-outer--my-adac-dashboard-landingpage & {
			max-width: $layout-my-adac-dashboard-landingpage;
		}

		.l-outer--my-adac-dashboard-content & {
			max-width: $layout-my-adac-dashboard-content;
		}

		&--flex {
			@include mq(desktop) {
				display: flex;
			}

			> .l-main-content {
				flex: 1;
			}
		}

		&--home {
			max-width: 1260px;

			@include mq(desktop-xl) {
				left: 0;
			}
		}

		// exception for distance to m-basic-form-footer-sticky
		&.is-social-sharing {
			padding-bottom: 95px;

			@include mq(tablet) {
				padding-bottom: 100px;
			}
		}

		// exception for distance to m-ves-rs-sticky
		&--vesrsgap {
			padding-bottom: 85px;

			@include mq(tablet) {
				padding-bottom: 60px;
			}

			&.is-social-sharing {
				padding-bottom: 125px;

				@include mq(tablet) {
					padding-bottom: 100px;
				}
			}
		}

		// exception for distance to ves rs sticky
		&--vesrsgap-bottom {
			padding-bottom: 120px;

			@include mq(tablet) {
				padding-bottom: 60px;
			}
		}

		&--news {
			.l-main-content {
				.js-basickeywordfilter-result {
					.m-basic-newsbox {
						.mm-content {
							h3 {
								padding-bottom: 0;
							}
						}
					}
				}
			}
		}

		// schmuck headline styles
		> .ll-schmuck-head {
			padding: 0 0 0 10px;
			@include font-size($font-size-28);
			@include line-height($font-size-32);
			text-align: initial;

			@include mq(tablet) {
				padding: 30px 0 15px 0;
			}

			@include mq(desktop) {
				padding: 40px 0 30px 0;
				text-align: right;
				@include font-size(48px);
				@include line-height(56px);
			}
		}
	}

	// exception ll-main-center is sibling of ll-main-language
	.ll-main-language + .ll-main-center {
		padding-top: 10px;

		@include mq(tablet) {
			padding-top: 0;
		}
	}

	.ll-main-full {
		position: relative;
		background: $color-secondary-9;
	}

	// exception for myAdac content sites
	.l-outer--my-adac-content & {
		background: $color-primary-2;
	}

	.ll-main--gray {
		background: $color-secondary-8;
	}
}