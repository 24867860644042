.m-basic-compare-list {

	&--background {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			left: -50%;
			top: 0;
			width: 200%;
			height: 100%;
			background-color: $color-secondary-8;
		}
	}

	> .mm-compare-list {
		position: relative;
		z-index: 1;

		@include mq('tablet') {
			display: flex;
			justify-content: space-between;
		}

		> .mm-compare {

			@include mq('tablet') {
				width: calc(50% - 15px);
			}

			&:last-child {
				@include if-parent('.m-basic-compare-list.m-basic-compare-list--background') {
					padding: 20px 0;

					@include mq('tablet') {
						padding: 0;
					}
				}
			}

			> .mm-compare-img {
				display: block;
				margin: 0 auto 15px;
				text-align: center;
				position: relative;
				width: 100%;
				height: 50px;

				&:before {
					content: '';
					position: absolute;
					top: -10px;
					left: 50%;
					transform: translateX(-50%);

					@include if-parent('.mm-compare.mm-compare--pro') {
						@include main-icon-pro;
					}

					@include if-parent('.mm-compare.mm-compare--contra') {
						@include main-icon-contra;
					}
				}

				> img {
					display: none;
				}
			}

			> .h-h3 {
				@include if-parent('.m-basic-compare-list.m-basic-compare-list--background') {
					margin-bottom: 20px;
				}
			}

			> ul {
				margin: 0;
				padding: 0;

				> li {
					margin-bottom: 20px;
				}
			}
		}
	}
}