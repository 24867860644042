/* a-basic-input-checkbox--rating */
.a-basic-input-checkbox--rating {

	// show as list if mobile
	&--1,
	&--2,
	&--3,
	&--4,
	&--5 {
		display: block;
		float: none;

		@include mq(tablet) {
			display: inline-block;
		}

	}

	//green --1
	&--1 {
		> input {
			@extend %h-visuallyhidden;

			& ~ div {

				&:before {
					content: '';
					display: inline-block;
					@include main-icon-check-box-outline-green;
					background-repeat: no-repeat;
				}
			}

			// if focus
			&:focus ~ div {

				// if is mouseover
				@include if-parent('.a-basic-input-checkbox--rating--1.js-clicked') {

					&:before {
						content: '';
						display: inline-block;
						@include main-icon-check-box-outline-green;
						background-repeat: no-repeat;
					}
				}
			}

			&:checked {

				// if focus && checked
				&:focus ~ div {

					&:before {
						content: '';
						display: inline-block;
						@include main-icon-check-box-green;
						background-repeat: no-repeat;
					}
				}

				// if focus by click && checked
				@include if-parent('.a-basic-input-checkbox--rating--1.js-clicked') {
					& ~ div {

						&:before {
							content: '';
							display: inline-block;
							@include main-icon-check-box-green;
							background-repeat: no-repeat;
						}
					}
				}
			}
		}
	}

	//light-green --2
	&--2 {
		> input {
			@extend %h-visuallyhidden;

			& ~ div {

				&:before {
					content: '';
					display: inline-block;
					@include main-icon-check-box-outline-light-green;
					background-repeat: no-repeat;
				}
			}

			// if focus
			&:focus ~ div {

				// if is mouseover
				@include if-parent('.a-basic-input-checkbox--rating--2.js-clicked') {

					&:before {
						content: '';
						display: inline-block;
						@include main-icon-check-box-outline-light-green;
						background-repeat: no-repeat;
					}
				}
			}

			&:checked {

				& ~ div {

					&:before {
						content: '';
						display: inline-block;
						@include main-icon-check-box-light-green;
						background-repeat: no-repeat;
					}
				}

				// if focus && checked
				&:focus ~ div {

					&:before {
						content: '';
						display: inline-block;
						@include main-icon-check-box-light-green;
						background-repeat: no-repeat;
					}
				}

				// if focus by click && checked
				@include if-parent('.a-basic-input-checkbox--rating--2.js-clicked') {
					& ~ div {

						&:before {
							content: '';
							display: inline-block;
							@include main-icon-check-box-light-green;
							background-repeat: no-repeat;
						}
					}
				}
			}
		}
	}

	// yellow --3
	&--3 {
		> input {
			@extend %h-visuallyhidden;

			& ~ div {

				&:before {
					content: '';
					display: inline-block;
					@include main-icon-check-box-outline-focus;
					background-repeat: no-repeat;
				}
			}

			// if focus
			&:focus ~ div {

				// if is mouseover
				@include if-parent('.a-basic-input-checkbox--rating--3.js-clicked') {

					&:before {
						content: '';
						display: inline-block;
						@include main-icon-check-box-outline-focus;
						background-repeat: no-repeat;
					}
				}
			}

			&:checked {

				& ~ div {

					&:before {
						content: '';
						display: inline-block;
						@include main-icon-check-box-green-focus;
						background-repeat: no-repeat;
					}
				}

				// if focus && checked
				&:focus ~ div {

					&:before {
						content: '';
						display: inline-block;
						@include main-icon-check-box-green-focus;
						background-repeat: no-repeat;
					}
				}

				// if focus by click && checked
				@include if-parent('.a-basic-input-checkbox--rating--3.js-clicked') {
					& ~ div {

						&:before {
							content: '';
							display: inline-block;
							@include main-icon-check-box-green-focus;
							background-repeat: no-repeat;
						}
					}
				}
			}
		}
	}
}