// m-basic-list--teaser-center
.m-basic-list--teaser-center {

	$marginTeaser: 30px;
	$widthTeaserTab: 202px;
	$widthTeaserDesk: 255px;

	margin: 0 auto;
	max-width: none;
	padding: 0;

	@include mq(tablet) {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
	}

	> .mm-variant-teaser {
		padding: 5px 0 5px 40px;
		position: relative;
		background: 0;

		@include mq(tablet) {
			padding: 0;
			text-align: center;
			width: calc(#{$widthTeaserTab} - #{$marginTeaser});
			margin: 0 $marginTeaser;
		}

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}

		&:not(:first-child):not(:last-child) {
			@include mq(tablet) {
				width: calc(#{$widthTeaserTab} - #{2 * $marginTeaser});
			}
		}

		&:before {
			content: none;
			background: none;
			display: none;
		}

		.l-outer--my-adac-content & {
			padding: 5px 0 5px 40px;
			position: relative;
			background: 0;

			@include mq(tablet) {
				padding: 0;
				text-align: center;
				width: calc(#{$widthTeaserTab} - #{$marginTeaser});
				margin: 0 $marginTeaser;
			}
		}

		img {
			left: 0;
			top: 0;
			position: absolute;

			@include mq(tablet) {
				position: static;
				left: auto;
				top: auto;
				margin: 0 auto 5px auto;
				display: block;
			}
		}

		> .mm-headline {
			@include milo-bold($font-size-16);
			font-weight: normal;
			margin: 7px 0 10px 0;

			@include mq(desktop) {
				min-height: 42px;
			}
		}
	}

	&.h-space-s {
		margin-bottom: 5px;
	}

	&.h-space-m {
		margin-bottom: 15px;
	}

	&.h-space-l {
		margin-bottom: 44px;
	}
}