.m-basic-toggle--highlight {
	border: none;

	.mm-head {
		border: none;
		position: relative;
		padding: 10px 9px 10px 60px;

		&:not(:nth-last-child(2)) {
			margin-bottom: 20px;
		}

		&:before {
			content: '';
			position: absolute;
			top: -1px;
			left: -2px;
			@include main-icon-arrow-up;
			z-index: 1;
		}

		> p {

			&:before {
				width: 40px;
				height: 40px;
				content: '';
				background-color: $color-primary-1;
				display: inline-block;
				border-radius: 4px;
				position: absolute;
				left: 0;
				top: 0;
			}
		}

		&.mm-opened {
			border-bottom: none;
			margin-bottom: 0;

			&:before {
				@include main-icon-arrow-down;
				width: 40px;
				height: 40px;
				top: 0;
				left: 3px;
			}
		}

		&.mm-opened + .mm-content {
			padding: 17px 10px 2px 60px;

			&:not(:last-child) {
				margin-bottom: 5px;
			}
		}
	}
}