/* o-basic-banderole--white */
.o-basic-banderole--white {

	> .swiper {
		margin-top: 0;
		padding-top: 0;

		> .swiper-wrapper {

			> .swiper-slide {

				> .m-basic-teaser-across {
					background: $color-primary-2;
					padding-top: 12px;

					@include mq(tablet) {
						padding-top: 22px;
					}

					&.mm-first {
						padding-left: 0;

						@include mq(desktop-xl) {
							padding-left: 15px;
						}
					}

					> article {

						> .mm-article-content {
							background: $color-primary-2;
						}

						> .mm-footer {
							background: $color-primary-2;
						}
					}
				}
			}
		}
	}
}