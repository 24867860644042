/* m-basic-list--flex */
.m-basic-list--flex {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	max-width: 898px;

	@include mq(tablet) {
		flex-direction: row;
	}

	> li {
		flex: 0 0 100%;

		@include mq(tablet) {
			flex: 0 0 50%;
		}
	}
}