/* .a-basic-btn--outline-highlight */
.a-basic-btn--outline-highlight {
	// compensate vert.offset due to outline usage
	$vert-offset: 2px;
	background-color: transparent;
	min-height: 32px;

	&:hover {
		background-color: $color-secondary-25;
	}

	&:focus,
	&:active {
		background-color: $color-secondary-25;
		border: 2px solid $color-secondary-22 !important;

		.is-keyboard-focus & {
			border: 2px solid $color-focus-tab;
			background-color: $color-secondary-25;
		}
	}
}