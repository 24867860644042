// .m-basic-list--compare
.m-basic-list--compare {
	visibility: visible;
	display: block;
	max-width: none;
	padding: 0;

	> .swiper {
		padding-left: 30px;

		@include mq(tablet) {
			padding-left: 0;
		}

		> .swiper-wrapper {
			justify-content: flex-start;
			align-items: stretch;

			@include mq(tablet) {
				min-width: 898px;
			}

			> .swiper-slide {
				width: 136px;
				height: auto;
				background: $color-secondary-8;

				@include mq(tablet) {
					width: 163px;
				}

				> .mm-variant-teaser {
					text-align: left;
					width: 136px;
					position: relative;

					@include mq(tablet) {
						width: 163px;
					}


					> .mm-option-list {
						background: $color-secondary-10;
						margin: 0;
						padding: 7px;

						> li {
							padding: 5px 0 5px 32px;
							vertical-align: middle;
							position: relative;

							&:before {
								content: "";
								@include main-icon-selected-transparent;
								@include absolute($top: -13px, $left: -15px);
								transform: scale(0.7);
							}

							&.mm-option-disabled {
								color: $color-secondary-3;

								&:before {
									background: none;
								}
							}

							&:last-child {
								padding-bottom: 10px;
								border-bottom: 1px solid $color-secondary-9;
							}
						}
					}

					> .mm-monthlyprice {
						text-align: center;
						@include milo-bold($font-size-16, 21px);
						padding: 10px 0;
						background: $color-secondary-10;
						border-bottom: 6px solid $color-primary-2;
					}

					hr.yellow {
						margin: 13px -11px 14px 0
					}

					> .mm-option-service {
						padding: 15px 10px 10px 40px;
						position: relative;

						&:before {
							content: '';
							display: block;
							@include main-choose-mobile;
							position: absolute;
							height: 38px;
							width: 34px;
							top: -18px;
							left: calc(50% - 14px);
						}

						> .mm-icon-additional {
							position: absolute;
							left: 10px;
						}

						> .m-basic-info-layer {
							.mm-infolayer-icon {
								bottom: -32px;
								right: 85px;

								@include mq(tablet) {
									right: 114px;
								}
							}
						}
					}
				}

				&:last-child {
					> .mm-variant-teaser {
						hr.yellow {
							margin-right: 0;
						}
					}
				}
			}
		}

		.swiper-button-next,
		.swiper-button-prev {
			&:focus-within {
				outline: none;

				.is-keyboard-focus & {
					outline: 2px solid #000;
				}
			}
		}
	}
}