.a-basic-rating--big {

	> .aa-inner-wrap {
		padding-left: 40px;
		min-width: 85px;

		> .aa-inner {
			padding: 0 6px;
			@include font-size(22px);
		}
	}
}