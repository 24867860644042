.a-basic-calculator-slider--doublehandle {
	width: 100%;
	max-width: 400px;

	.is-hidden {
		display: none;
	}

	> .aa-label {
		display: inline-block;
	}

	> .aa-row {
		> .aa-label {
			display: inline-block;
			padding: 3px 0;
			margin: 5px 10px 0 10px;

			&-begin {
				margin-left: 0;
			}

			&.is-hidden {
				display: none;
			}
		}
	}

	.aa-result {
		display: inline-block;
		width: 144px;

		@include mq(tablet) {
			width: 130px;
		}
	}

	.aa-slider {
		width: 100%;
		max-width: 334px;

		@include mq(tablet) {
			max-width: 307px;
		}
	}

	.noUi-connects > .noUi-connect {
		background: $color-secondary-2;
	}

	.noUi-origin {
		height: 18px !important;
		background: transparent;
	}
}