/* a-basic-input-textarea--white */
.a-basic-input-textarea--white {

	background: $color-primary-2;

	.l-form-general &.a-basic-input-textarea--clear ~ .js-clear-button {
		display: initial;
		right: 35px;
		top: 30px;
	}
}