/* m-my-adac-content-hint-big */
.m-my-adac-content-hint--big {

	> .mm-wrapper {

		@include mq(tablet) {
			//left: calc(50% - 50vw); // 50% - "halbes width"
		}

		@include mq(tablet) {
			margin: 0 -215px;
			max-width: 960px;
		}

		> .mm-content {
			max-width: 760px;
			width: 100%;
			margin: 0 auto;
		}
	}
}