// m-basic-link-cloud
.m-basic-link-cloud {
	margin-bottom: 40px;

	@include mq(desktop) {
		margin-bottom: 60px;
	}

	> .mm-wrapper {
		display: flex;
		flex-wrap: wrap;
		column-gap: 20px;
		row-gap: 5px;
		max-width: 770px;

		> .mm-wrapper-item {
			position: relative;

			&:not(:last-child) {
				&:after {
					content: '';
					width: 6px;
					height: 6px;
					border-radius: 50%;
					background-color: $color-primary-3;
					display: block;
					position: absolute;
					top: calc(50% - 3px);
					right: -14px;
				}
			}
		}
	}
}