.o-fdl-credit-card-application {
	.oo-switch {
		max-width: 580px;
		margin-bottom: 25px;

		@include mq(tablet) {
			margin-bottom: 35px;
		}

		@include mq(desktop) {
			margin-bottom: 35px;
		}

		label {
			padding: 0;
			background: $color-primary-2;
			position: relative;
			float: left;
			width: 100%;
			cursor: pointer;

			@include mq(tablet) {
				width: 50%;
				max-width: 300px;
			}
		}

		input[type="radio"] {
			display: none;
		}

		figure {
			margin: 0;
			padding-left: 30px;

			img {
				width: 239px;
				padding: 0 0 5px;
				opacity: 0.75;
				transition: opacity 0.3s;

				&:hover {
					opacity: 1;
				}
			}
		}
	}

	.oo-switch-inner {

		.oo-select {
			width: 21px;
			height: 21px;
			padding: 0;
			display: inline-block;
			@include absolute($top: 15px, $left: 0);

			&::before {
				content: '';
				@include main-icon-radio-button-unchecked;
				position: absolute;
				top: -11px;
				right: -12px;
			}
		}
	}

	input[type="radio"]:checked ~ figure img {
		opacity: 1;
	}

	input[type="radio"]:checked ~ .oo-switch-inner * {
		opacity: 1;
	}

	input[type="radio"]:checked ~ .oo-switch-inner .oo-select {
		&::before {
			@include main-icon-radio-button-checked;
		}
	}

	&:hover {

		img {
			opacity: 1;
		}

		span {
			opacity: 1;
		}
	}

	.oo-content {
		margin-bottom: 50px;

		.oo-content-inner {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			position: relative;
			max-width: 580px;

			.a-basic-input-checkbox {
				min-width: 0;

				@include mq(tablet) {
					max-width: none;
				}

				&.js-checkbox-deal {
					margin-top: 9px;
					padding-right: 95px;

					@include mq(tablet) {
						margin-top: 17px;
						padding-right: 0;
					}
				}

				&.h-space-s {
					margin-bottom: 3px;

					@include mq(tablet) {
						margin-bottom: 10px;
					}
				}

				.mm-line-through {
					text-decoration: line-through;
					white-space: nowrap;
				}
			}

			.m-basic-text {
				margin-left: 25px;

				@include mq(tablet) {
					margin-top: -8px;
				}
			}
		}
	}

	.m-basic-text {
		margin-left: 0;

		.mm-content {
			margin: 0 0 5px 0;

			@include mq(tablet) {
				margin: 0 0 10px 0;
			}

			.mm-line-through {
				text-decoration: line-through;
			}
		}
	}

	.a-basic-stopper {
		top: 4px;
		right: -10px;

		@include mq(tablet) {
			top: 10px;
			right: 0;
		}
	}

	.oo-content-result {
		border-top: 3px double $color-secondary-1;
		border-bottom: 1px solid $color-secondary-1;
		margin: 30px 0;
		padding: 15px 0 15px 10px;
		position: relative;
		max-width: 580px;

		span {
			font-weight: bolder;
			@include font-size(16px);
			display: block;

			@include mq(tablet) {
				@include font-size(20px);
			}

			&.oo-total {
				@include absolute($right: 10px, $top: 6px);
				text-align: right;
				@include font-size(24px);

				@include mq(tablet) {
					top: 1px;
					@include font-size(34px);
				}
			}
		}
	}
}