/* m-basic-progress-nav */
.m-basic-progress-nav-form--piechart {
	> .mm-nav-wrapper {
		padding: 10px;

		@include mq(desktop) {
			padding: 20px 20px 30px 20px;
		}

		> li {

			position: static;
			align-items: flex-start;

			@include mq(desktop) {
				position: relative;
				align-items: center;
			}

			&:last-child {
				min-width: 0;
			}

			&.mm-mobile {
				> .mm-step {
					padding-top: 9px;
					text-align: center;
				}

				.mm-segment {
					display: block;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					width: 1px;
					height: 1px;
					background-color: red;

					&.is-active {
						> span {
							border-color: transparent transparent $color-primary-1 transparent;
						}
					}

					> span {
						display: block;
						width: 0;
						height: 0;
						transform: rotate(0deg);
						border-style: solid;
						border-width: 0 100px 200px 100px;
						border-color: transparent transparent $color-secondary-16 transparent;
						position: absolute;
						right: 0;
						bottom: 0;
					}
				}
			}

			.mm-digit {
				display: none;

				@include mq(desktop) {
					display: block;
				}
			}

			a {
				position: static;
				width: 0;

				@include mq(desktop) {
					position: relative;
					width: auto;
				}
			}

			.mm-text {
				position: static;

				@include mq(desktop) {
					position: relative;
				}

				> .mm-text-inner {
					left: 65px;
					top: 15px;
					transform: translateX(0);

					@include mq(desktop) {
						left: 0;
						top: 0;
					}

					> .mm-mobile-text {
						font-weight: bold;
					}
				}
			}

			> .mm-line {
				display: none;

				@include mq(desktop) {
					display: block;
				}
			}
		}
	}
}