/* m-basic-table--break */
.m-basic-table--hide-head {
	margin-bottom: 0;
	margin-top: -11px;

	@include mq(tablet) {
		margin-top: 0;
	}

	thead {
		display: none;
	}
}