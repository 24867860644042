/* o-layout-main-stage--align-right */
.o-layout-main-stage--align-right {

	.oo-figure {

		.oo-img {
			left: -48%;

			@include mq(tablet) {
				left: -33%
			}

			@include mq(desktop) {
				position: static;
			}

			&--col-3 {
				left: -15%;

				@include mq(tablet) {
					left: -36%;
				}

				@include mq(desktop) {
					position: relative;
					left: -16%;
				}

				@include mq(desktop-l) {
					left: 0;
				}
			}

			&.is-fixed-height {

				@include mq(tablet) {
					position: relative;
					left: -48%;
				}

				@include mq(desktop) {
					left: 50%;
					transform: translate(-50%);
					width: auto;
					height: 100%;
				}
			}
		}

		&--overlay {
			.oo-img {
				@include mq(tablet) {
					transform: none;
					width: 148%;
				}
			}
		}
	}

	.oo-slide-content-wrapper {

		> .oo-slide-head {

			@include mq(tablet) {
				left: auto;
				min-width: 0;
				// neue Breite damit das Motiv nicht überdeckt wird (optimierung)
				right: 350px;
				width: calc(100% - 560px);
			}

			@include mq(desktop) {
				left: 30px;
				right: auto;
				min-width: 620px;
				max-width: 620px;
			}

		}

		&--small {

			@include mq(desktop) {
				left: 58%;
			}

			@include mq(desktop-l) {
				left: 50%;
			}

			> .oo-slide-head {

				@include mq(tablet) {
					max-width: calc(100% - 560px);
					right: 270px
				}

				@include mq(desktop) {
					min-width: calc(800px - 42%);
					max-width: calc(800px - 42%);
				}

				@include mq(desktop-l) {
					min-width: 620px;
					max-width: 620px;
				}
			}

			&.oo-slide-content-wrapper--fade-overlay {
				> .oo-slide-head {
					@include mq(desktop) {
						min-width: calc(700px - 51%);
						max-width: calc(700px - 51%);
					}

					@include mq(desktop-l) {
						min-width: 620px;
						max-width: 620px;
					}
				}
			}
		}

		&--overlay {

			@include mq(desktop) {
				right: auto;
				left: 50%;
				transform: translate(-50%, 0);
			}

			@include mq(desktop-l) {
				left: calc(50% - 65px);
			}

			> .oo-slide-head {

				@include mq(desktop) {
					min-width: calc(700px - 51%);
					max-width: calc(700px - 51%);
				}

				@include mq(desktop-l) {
					margin-left: auto;
					margin-right: auto;
					padding-right: 0;
					min-width: calc(800px - 45%);
					max-width: calc(800px - 45%);
				}
			}

			> .oo-box {
				@include mq(desktop) {
					padding-right: 20px;
				}
			}
		}
	}

	.oo-badge {

		@include mq(tablet) {
			left: 35px;
			right: auto;
		}

		@include mq(desktop) {
			left: auto;
			right: calc(50% + 420px);
			transform: translate(50%, 0);
		}

		&--big {
			@include mq(desktop) {
				right: calc(44% + 390px);
				transform: translate(44%, 0);
			}

			@include mq(desktop-l) {
				right: calc(50% + 430px);
				transform: translate(50%, 0);
			}
		}
	}

	.oo-slide-content-wrapper--fade-overlay + .oo-badge {
		@include mq(tablet) {
			top: 40px;
			left: 30px;
			right: auto;
			transform: none;
		}

		@include mq(desktop) {
			bottom: auto;
			left: auto;
			right: calc(50% + 410px);
			transform: translate(50%, 0%);
		}

		&--big {
			@include mq(desktop) {
				right: calc(50% + 320px);
			}

			@include mq(desktop-l) {
				right: calc(50% + 410px);
			}
		}
	}

	.oo-slide-content-wrapper--overlay + .oo-badge {

		@include mq(tablet) {
			top: 40px;
			left: 30px;
			right: auto;
			transform: none;
		}

		@include mq(desktop) {
			top: 55px;
			left: calc(50% - 545px);
			right: auto;
			transform: translate(50%, 0);
		}

		@include mq(desktop-l) {
			left: calc(50% - 615px);
		}
	}
}