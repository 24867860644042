/* a-basic-link--contact */
.a-basic-link--contact {
	display: inline-block;
	height: auto;
	padding: 10px 0 10px 50px;
	@include milo-bold();
	text-decoration: none;

	&:before {
		content: '';
		display: inline-block;
		@include main-icon-contact-phone-circle;
		position: relative;
		height: 39px;
		top: 13px;
	}

	&:after {
		right: 0;
		top: -4px;
		height: calc(100% + 8px);
		max-height: 54px;

		@include mq(desktop) {
			height: 100%;
		}
	}

	> .aa-link-icon-wrapper {
		max-height: 48px;
	}

	> .aa-text-wrapper {

		display: inline-block;

		.aa-text {
			display: block;
			max-width: 200px;
			@include milo-bold($font-size-16, 20px);
			color: rgba($color-primary-3, .9);

			@include if-parent('.a-basic-link--contact.is-full-length') {
				max-width: none;
			}

			@at-root #{selector-unify(&, small)} {
				@include milo-regular($font-size-14, 20px);

				&:nth-child(3):last-child {
					color: rgba($color-primary-3, .6);
				}
			}

			&.aa-big {
				@include font-size($font-size-20, 24px);

				@include mq('tablet') {
					@include font-size($font-size-24, 28px);
				}
			}
		}
	}

	.m-basic-text .a-basic-btn + & {
		margin-top: 15px;

		@include mq('tablet') {
			margin-top: 0;
			margin-left: 20px;
		}
	}

	// exception .m-basic-header
	.m-basic-header > .mm-navi-wrap > .mm-main > .mm-main-btn > .mm-section & {
		display: inline-block;
		position: relative;
		height: auto;
		margin-bottom: 16px;
		padding: 3px 0 4px 70px;
		background: none;

		@include mq('tablet') {
			padding-left: 70px;
		}

		&:hover {
			max-height: unset;
		}

		&:last-child {
			margin-bottom: 0;

			&:after {
				top: -4px;
				height: calc(100% + 8px);
				right: 0;
				background-color: transparent;
				max-height: 54px;

				@include mq(desktop) {
					height: 100%;
				}
			}
		}
	}
}