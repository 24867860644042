/* a-basic-link--my-adac-back */
.a-basic-link--my-adac-back {
	position: relative;
	width: 100%;
	padding-left: 40px;
	text-decoration: none;
	display: block;
	outline: none;
	@include font-size(14px, 20px);
	background: none;

	.ll-main-center > & {
		display: inline-block;
		width: auto;
	}

	&.a-basic-link--right {
		display: inline-block;
		float: right;
		padding-right: 0 !important;
		left: inherit !important;
	}

	&:before {
		position: absolute;
		content: '';
		display: inline-block;
		@include main-icon-arrow-right-grey;
		left: 0;
		transform: rotate(-180deg);
		width: 30px;
		height: 29px;

		.l-outer--my-adac-dashboard & {
			@include main-icon-arrow-right-grey2;
		}

		.l-outer--my-adac-content & {
			@include main-icon-arrow-right-grey-dark;
		}

		// exception for theme-option-layer
		.mm-dashbox-theme-option-layer & {
			transform: translate(0, -50%) rotate(0deg);
		}

		@include mq(print) {
			background: none;
			width: 0;
			height: 0;
			border-top: 5px solid transparent;
			border-bottom: 5px solid transparent;
			border-left: 5px solid #CC3300;
			content: "";
			display: inline-block;
			position: relative;
			top: -1px;
			left: -2px;
			z-index: 100000000000;
			transform: none;
		}

	}

	&:after {
		content: none;
	}

	.l-outer--my-adac &,
	.l-outer--my-adac-dashboard-content & {
		margin: 0 0 24px 0;
		position: relative;
		left: 10px;

		@include mq(tablet) {
			left: -11px;
		}

		@include mq(desktop) {
			left: 10px;
		}

		@include mq(desktop-l) {
			position: relative;
			left: -11px;
		}
	}

	// exception if you are in layer
	.mm-layer-content & {
		margin: 0 0 12px 0;
	}

	.ll-lightbox-inner.my-adac & {
		@include mq(tablet) {
			width: auto;
			order: 1;
		}
	}
}