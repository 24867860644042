/* a-basic-input-select--l */
.a-basic-input-select--l {

	width: calc(100% - #{$form-width-s + $form-gutter});

	.m-basic-calculator-content-box & {
		width: calc(100% - #{$form-width-s + $form-gutter});
	}

	// we are after S
	.a-basic-input-text--s + & {
		float: right;
	}
}