.oo-basic-border-wrapper {
	display: block;
	width: 100%;
	border: 1px solid $color-secondary-16;
	border-radius: 8px;
	padding: 20px 30px;

	@include mq(tablet) {
		padding: 30px;
	}

	> .oo-content-wrapper {
		> .oo-toggle-head {
			position: relative;
			padding-right: 45px;
			cursor: pointer;

			@include milo-slab-bold($font-size-18, 24px);

			@include mq(tablet) {
				@include font-size($font-size-28, 34px);
			}

			@include mq(print) {
				@include milo-slab-bold($font-size-18, 24px);
			}

			&:after {
				@include main-icon-arrow-down-grey2();
				content: '';
				position: absolute;
				right: -15px;
				top: -5px;
				transform: rotate(0deg);
				transition: transform 0.5s;
			}

			&.is-open {
				&:after {
					transform: rotate(180deg);
				}
			}
		}

		> .oo-toggle-content {
			display: none;
			padding-top: 20px;

			@include mq(tablet) {
				padding-top: 30px;
			}

			&.is-initial-open {
				display: block;
			}
		}
	}
}