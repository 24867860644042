/* .a-basic-btn--logout */
.a-basic-btn--logout {
	padding: 10px 22px 7px 42px;

	&:before {
		content: '';
		display: inline-block;
		@include header-icon-logout;
		position: absolute;
		left: 0;
		top: -4px;
	}
}