/* .m-my-adac-formular-template */
.m-my-adac-formular-template {

	> .mm-wrapper {

		.mm-content-button-wrapper {
			display: flex;
			justify-content: center;
		}
	}

	.ll-row {
		.ll-row:not(.nopadding) {
			padding-right: 0;

			@include mq(tablet) {
				padding-left: 45px;
			}

			&.is-small-padding {
				padding-left: 15px;
			}
		}
	}

	.ll-depend {
		.ll-row:not(.nopadding) {
			padding-left: 0;
		}
	}
}