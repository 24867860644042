/* m-basic-progress-nav */
.m-basic-progress-nav {
	position: relative;
	min-height: 70px;

	&.h-space-m {
		margin-bottom: 40px;
	}

	@include mq(print) {
		min-height: 25px;
	}

	&.is-hidden {
		display: none;
	}

	ul {
		display: flex;
		flex-direction: row;
		align-content: stretch;
		padding: 0;
		margin: 0;
		width: 100%;

		@include mq(tablet) {
			width: 100%;
		}

		li {
			position: relative;
			display: table-cell;
			text-align: center;
			min-height: 100%;
			padding: 8px 0 4px 0;
			flex-grow: 1;
			vertical-align: top;
			width: 500px;
			max-width: calc(100vw - 55px);

			@include mq(tablet) {
				text-align: center;
				padding: 8px 0 4px 0;
				max-width: none;
			}

			&:first-child {
				padding-left: 0;

				.mm-progressnav-container {

					&:before {
						content: "";
						margin: 0;
						left: 0;
					}
				}

				.mm-text {
					text-align: left;
					margin: 0;
					left: 0;

					.mm-text-inner {
						&:before {
							content: "1.";
						}
					}
				}

				&.mm-active {
					padding-left: 0;

					.mm-progressnav-container {
						background: $color-secondary-9;
						height: 1px;
						border: none !important;
						top: auto !important;

						&:before {
							content: "";
							border: 5px solid $color-primary-1;
							width: 20px;
							height: 20px;
							@include absolute($top: -10px, $right: auto, $left: 0);
							transform: none;
						}
					}

					.mm-text {
						left: 0;
					}
				}
			}

			&:nth-child(2) {
				.mm-text {
					.mm-text-inner {
						&:before {
							content: "2.";
						}
					}
				}
			}

			&:nth-child(3) {
				.mm-text {
					.mm-text-inner {
						&:before {
							content: "3.";
						}
					}
				}
			}

			&:nth-child(4) {
				.mm-text {
					.mm-text-inner {
						&:before {
							content: "4.";
						}
					}
				}
			}

			&:nth-child(5) {
				.mm-text {
					.mm-text-inner {
						&:before {
							content: "5.";
						}
					}
				}
			}

			&:nth-child(6) {
				.mm-text {
					.mm-text-inner {
						&:before {
							content: "6.";
						}
					}
				}
			}

			&:last-child {
				width: 0;

				.mm-progressnav-container {

					&:before {
						margin: 0;
						right: 0 !important;
						left: auto;
					}
				}

				.mm-text {
					text-align: right;
					margin: 0;
					right: 0;
					left: auto;

					.mm-text-inner {
						right: 0;
						left: auto;
					}
				}

				.mm-digit {
					left: auto;
				}

				&.mm-active {
					min-width: 20px;
					max-width: 20px;

					.mm-text {
						right: 0;
						left: auto;
					}
				}
			}

			&.mm-active {
				padding-left: 0;

				+ li {
					padding-left: 22px;
				}

				~ li {
					padding-left: 0;
					flex-grow: 2;

					.mm-progressnav-container {
						background: $color-secondary-9;

						&:before {
							background: $color-primary-2;
							border: 2px solid $color-secondary-9;
						}
					}

					.mm-text {
						color: $color-secondary-9;
						right: 0;
					}

					.mm-digit {
						color: $color-secondary-9;
					}
				}

				.mm-text {
					display: inline-block;
					@include line-height(18px);
					color: $color-secondary-1;
					top: 30px;
					right: 0;
					left: -100%;
				}

				.mm-digit {
					display: block;
					left: 5px;

					@include mq(tablet) {
						left: 0;
					}
				}

				.mm-progressnav-container {
					background: $color-secondary-9;
					border: none !important;
					top: auto !important;

					&:first-child {
						border-left: 1px solid $color-secondary-3;
						border-top: 1px solid $color-secondary-3;
					}

					/* bottom Arrow at beginning of Element while not the first arrow */
					.mm-tri-b {
						content: " ";
						position: absolute;
						height: 100%;
						width: 18px;
						top: 0;
						left: -1px;
						background: url(/assets/img/sprites/arrow-end-white.gif);
						background-repeat: no-repeat;
						z-index: 4;
					}

					/* First Arrow at end of Element while not the first arrow */
					.mm-tri-e {
						position: absolute;
						top: 0;
						right: -17px;
						width: 17px;
						background: url(/assets/img/sprites/arrow-head-white.gif);
						background-repeat: no-repeat;
						background-size: 100% 100%, auto;
						background-position: center center;
						z-index: 6;
					}

					&:before {
						content: "";
						position: absolute;
						background: $color-primary-2;
						width: 20px;
						height: 20px;
						@include absolute($top: -10px, $right: auto, $left: 0);
						transform: none;
					}

					&:after {
						content: "";
						top: -4px;
						left: -1px;
						width: calc(100% - 1px);
						height: 5px;
						background: $color-primary-2;
						position: static;
						transform: none;
					}
				}

				.mm-bg {
					background: $color-primary-2;
				}
			}

			.mm-text {
				z-index: 10;
				@include absolute($top: 30px, $left: -50%);
				vertical-align: top;
				@include milo-slab-bold($font-size-16, 18px);
				max-width: 145px;
				margin: 0 auto;
				color: $color-secondary-1;
				@include line-height(18px);

				@include mq(tablet) {
					width: 155px;
					left: -100%;
					right: 0;
					max-width: 155px;
				}

				@include mq(desktop) {
					width: 170px;
					max-width: 170px;
				}

				// overrides global tablet+ style (see markup class)
				@include mq(print) {
					display: none !important;
				}

				// Exception if raw html digits are set (.has-markup-digit)
				// Hint: "!important" on purpose to get a cleaner code. Otherwise we had to write >10 exceptions cause the digit logic isn't dynamic
				.mm-text-inner {
					position: relative;
					left: 15px;

					&:before {
						@include absolute($left: -15px);

						@include if-parent('.m-basic-progress-nav.has-markup-digit') {
							display: none !important;
						}
					}
				}
			}

			.mm-digit {
				z-index: 10;
				@include absolute($top: 30px, $right: 0, $left: 0);
				vertical-align: top;
				@include milo-slab-bold($font-size-16, 18px);
				text-align: left;

				@include mq(tablet) {
					text-align: center;
				}

				// overrides global smartphone only style
				@include mq(print) {
					display: block !important;
					text-align: left;
				}
			}

			.mm-progressnav-container {
				width: 100%;
				position: relative;
				background: $color-primary-1;
				height: 1px;

				@include mq(print) {
					border-top: 1px solid $color-primary-1;
				}

				&:before {
					content: "";
					@include absolute($top: -5px, $left: 0);
					background: $color-primary-1;
					border: 5px solid $color-primary-1;
					width: 10px;
					height: 10px;
					margin: 0 auto;
				}

				.mm-tri-b {
					display: none;
				}

				/* bottom Arrow at end of Element while not the first arrow */
				.mm-tri-e {
					display: none;
				}
			}
		}

		&.is-big {

			li {
				height: 51px;
				padding-left: 38px;

				.mm-progressnav-container {
					height: 51px;


					/* bottom Arrow at end of Element while not the first arrow */
					.mm-tri-e {
						right: -28px;
						top: 0;
						background: url(/assets/img/sprites/arrow-head-twolines-grey.gif) no-repeat;
						width: 28px;
						height: 51px;
						z-index: 4;
					}
				}

				&.mm-active {
					padding-left: 32px;

					+ li {
						padding-left: 33px;
					}

					.mm-progressnav-container {

						/* bottom Arrow at beginning of Element while not the first arrow */
						.mm-tri-b {
							height: 51px;
							width: 27px;
							background: url(/assets/img/sprites/arrow-end-twolines-white-refresh.gif) no-repeat;
						}

						/* First Arrow at end of Element while not the first arrow */
						.mm-tri-e {
							right: -27px;
							width: 27px;
							background: url(/assets/img/sprites/arrow-head-twolines-white.gif) no-repeat;
						}
					}

					// LÖSCHEN????
					// Only the first Child, which is active, shouldn't have the skewed edge
					&:first-child {
						padding-left: 10px;

						.mm-progressnav-container {
							border-left: 1px solid $color-secondary-3;
							border-top: 1px solid $color-secondary-3;
							top: 1px;

							.mm-tri-b {
								top: -5px;
								left: -1px;
								background: $color-primary-2;
								width: calc(100% - 3px);
								height: 5px;
								border-left: 1px solid $color-secondary-3;
								border-top: 1px solid $color-secondary-3;
								z-index: 5;

								&:before {
									content: "";
									position: absolute;
									top: -1px;
									right: -4px;
									width: 21px;
									height: 5px;
									transform: skew(45deg);
									background: $color-secondary-3;
									z-index: 3;
								}

								&:after {
									content: "";
									position: absolute;
									top: 0;
									right: -3px;
									width: 21px;
									height: 6px;
									transform: skew(45deg);
									background: $color-primary-2;
									z-index: 4;
								}

							}

							&:after {
								content: none;
							}

							&:before {
								content: none;
							}
						}
					}
				}
			}
		}
	}

	&--extended {
		ul {

			li {
				.mm-text {
					transform: translate(-50%);
					margin: 0;
					right: 50%;
					left: 0;

					@include mq(tablet) {
						transform: none;
						margin: 0 auto;
						right: 0;
						left: -100%;
					}

					.mm-text-inner {
						display: inline-block;
						left: auto;
						@include font-size($font-size-14);

						@include mq(tablet) {
							display: inline;
							left: 15px;
							@include font-size($font-size-16);
						}

						&:before {
							display: none;

							@include mq(tablet) {
								display: block;
								top: 0;
							}
						}

						a {
							@include absolute($top: -35px, $left: 0);
							width: 100%;
							height: calc(100% + 35px);
							min-height: 10px;
							z-index: 20;

							@include mq(tablet) {
								left: -15px;
								width: calc(100% + 15px);
							}

							&:focus {
								outline: none;
							}
						}
					}
				}

				// default digit styles
				.mm-digit {
					top: 0;
					left: -5px;
					right: auto;
					width: 20px;
					max-width: 20px;
					overflow: hidden;
					text-align: center;
				}

				.mm-progressnav-container {

					&:before {
						top: -10px;
						left: -5px;
						width: 20px;
						height: 20px;

						@include mq(tablet) {
							top: -5px;
							left: 5px;
							width: 10px;
							height: 10px;
						}
					}
				}

				&.mm-active {

					.mm-text {
						left: 0;
						right: 50%;
						transform: translate(-50%);

						@include mq(tablet) {
							left: -100%;
							right: 0;
							transform: none;
						}
					}

					.mm-digit {
						left: -5px;
					}

					.mm-progressnav-container {

						&:before {
							left: -5px;
							background: $color-primary-1;

							@include mq(tablet) {
								left: 0;
								background: $color-primary-2;
							}

						}
					}

					~ li {

						.mm-text {

							left: 5px;
							margin: 0;

							@include mq(tablet) {
								left: -100%;
								margin: 0 auto;
								right: 0;
							}

							.mm-text-inner {

								left: auto;

								@include mq(tablet) {
									left: 15px;
								}

								&:before {
									display: none;

									@include mq(tablet) {
										display: block;
									}

								}
							}
						}
					}

				}

				&:first-child {

					.mm-text {
						transform: none;
					}

					.mm-digit {
						top: 0;
						left: 0;
						max-width: 20px;
						text-align: center;
					}

					&.mm-active {
						.mm-digit {
							left: 0;
						}
					}

				}

				&:nth-child(2):not(:first-child):not(:last-child) {

					.mm-text {
						width: 100%;

						@include mq(tablet) {
							width: initial;
						}
					}
				}

				&:last-child {

					min-width: 15px;
					max-width: 15px;

					&.mm-active {
						min-width: 15px;
						max-width: 15px;
					}


					.mm-digit {
						top: 0;
						right: 0;
						max-width: 20px;
						text-align: center;
					}

					.mm-text {

						transform: none;
						left: auto !important;

						@include mq(tablet) {
							right: 0 !important;
						}

						.mm-text-inner {

							@include mq(tablet) {
								right: 0 !important;
								left: auto !important;
							}
						}
					}
				}
			}
		}

		// exception for .has-markup-digit
		// pls dont blame me for not writing this in real exceptions but this whole layout logic is a huge brainf***
		&.has-markup-digit {

			> ul {

				> li {

					&:first-child {
						> .mm-text {
							> .mm-text-inner {
								left: 0;

								> .mm-digit {
									left: 0;
									transform: none;
								}
							}
						}
					}

					&:last-child {
						> .mm-text {
							> .mm-text-inner {
								> .mm-digit {
									left: auto;
									right: 8px;
									transform: none;
								}
							}
						}
					}

					&.mm-active {
						~ li {

							&:last-child {
								> .mm-text {
									> .mm-text-inner {
										> .mm-digit {
											left: auto;
											right: 0;
											transform: none;
										}
									}
								}
							}

							> .mm-text {
								> .mm-text-inner {
									> .mm-digit {
										left: 50%;
										transform: translateX(-50%);
										right: auto;
									}
								}
							}
						}
					}

					> .mm-text {

						right: 0;

						@include mq(tablet) {
							width: 170px;
							max-width: 170px;
						}

						> .mm-text-inner {

							left: 8px;

							> .mm-digit {
								top: -32px;
								left: calc(50% - 3px);
								transform: translateX(-50%);

								@include mq(tablet) {
									position: static;
									display: inline;
								}

								> .mm-digit-dot {
									margin-left: -3px;
								}
							}
						}
					}
				}
			}
		}
	}

	// exception prev sibling h1
	h1 + & {
		margin-top: 20px;
	}
}