///* a-basic-btn--autocenter */
.a-basic-btn--my-adac {
	border-radius: 5px;

	letter-spacing: 0.5px;
	min-height: 36px;
	background: none;

	.l-outer--my-adac & {
		margin: 0 0 24px 0;
	}


	.ll-lightbox-inner.my-adac & {
		margin-bottom: 15px;

		@include mq(tablet) {
			display: inline-block;
			margin-bottom: 0;
			order: 2;
		}
	}

	// exception for myAdacDashboardBoxIntro
	.m-my-adac-dashboard-box-intro & {
		margin: 0 15px;
	}

	// if both modfiers - my-adac and grey - are set
	// the --my-adac overides --grey
	&.a-basic-btn--grey {
		background: $color-secondary-9;
		background-color: $color-secondary-9;
		color: $color-secondary-1;

		&:hover {
			background-color: $color-secondary-9;
			color: $color-secondary-1;
		}

		&:focus {
			background-color: $color-secondary-9;
		}

	}

	// if the button exists in an info-box don´t need margin
	.mm-info-box-wrapper & {
		margin: 0;

		// Exception for the my-adac data-protection-hint in dashboard-box
		.mm-data-protection-hint & {
			margin: 0 auto;
		}
	}

	// Exception for buttons in hints under dashboard boxes
	.mm-info-box-wrapper .mm-button-wrapper & {
		outline: none;
		white-space: nowrap;
		margin: 0 20px 20px 0;
		text-align: center;

		@include mq(tablet) {
			max-width: initial;
			margin: 0 30px 0 0;
		}

		&:last-child {
			margin: 0 15px 0 0;
		}
	}
}

// basic buttons from RTE (Richtext)
.l-outer--my-adac .m-basic-text.mm-content .mm-content .a-basic-btn {
	@extend .a-basic-btn--my-adac;
}