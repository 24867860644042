$mgl-animation: 0.7s;

.o-mgl-advisor {
	> .oo-headline {
		max-width: 800px;
		font-weight: lighter;

		@include mq(desktop) {
			text-align: center;
		}
	}

	> .oo-reception-wrapper {
		max-width: 765px;

		@include mq(desktop) {
			margin-top: 65px;
		}

		&.is-hidden {
			display: none;
		}

		> .oo-tom-image {
			> img {
				margin-left: 125px;
				display: block;
				max-height: 180px;

				@include mq(desktop) {
					max-height: none;
				}
			}
		}

		> .oo-tom-text-wrapper {
			padding: 30px 20px 25px 20px;
			border: 3px solid $color-secondary-10;
			border-radius: 10px;
			position: relative;

			@include mq(desktop) {
				padding: 90px 90px 45px 90px;
			}

			&:before {
				content: '';
				position: absolute;
				top: -35px;
				left: 16px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 0 35px 32px;
				border-color: transparent transparent $color-secondary-10 transparent;
			}

			&:after {
				content: '';
				position: absolute;
				top: -25px;
				left: 20px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 0 25px 25px;
				border-color: transparent transparent $color-primary-2 transparent;
			}

			> .oo-tom-text {
				p {
					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			> .oo-button-wrapper {
				display: flex;
				justify-content: flex-end;
				margin-top: 20px;

				@include mq(desktop) {
					margin-top: 40px;
				}
			}
		}
	}

	> .oo-advisor-wrapper {
		max-width: 570px;
		margin: 65px auto 0 auto;
		transition: all $form-animation;
		opacity: 1;
		pointer-events: auto;
		max-height: none;

		&.is-hidden {
			opacity: 0;
			max-height: 0;
			pointer-events: none;
		}

		> .oo-question-wrapper {
			> .oo-questions {
				margin-bottom: 25px;
				opacity: 1;
				transition: opacity $mgl-animation;
				max-height: 500px;

				&.is-hidden {
					opacity: 0;
					max-height: 0;
					margin-bottom: 0;
					pointer-events: none;
				}

				> .oo-question {
					display: flex;
					align-items: flex-start;

					> .oo-image {
						> img {
							max-width: 60px;

							@include mq(desktop) {
								max-width: 75px;
							}
						}

						> span {
							display: block;
							text-align: center;
							font-size: 14px;
						}
					}

					> .oo-text {
						position: relative;
						border: 2px solid $color-secondary-9;
						width: calc(100% - 20px);
						margin-left: 20px;
						border-radius: 8px;
						padding: 25px 20px;
						font-weight: bold;
						margin-bottom: 20px;

						@include mq(desktop) {
							font-size: 18px;
						}

						&:before {
							content: '';
							position: absolute;
							top: 20px;
							left: -15px;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 0 0 15px 15px;
							border-color: transparent transparent $color-secondary-9 transparent;
						}

						&:after {
							content: '';
							position: absolute;
							top: 23px;
							left: -11px;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 0 0 10px 11px;
							border-color: transparent transparent $color-primary-2 transparent;
						}

						@include if-parent('.oo-questions.oo-questions--end') {
							font-weight: normal
						}

						> span {
							position: absolute;
							right: 15px;
							top: 10px;
							font-size: 14px;
							font-weight: normal;
						}

						.oo-thanks {
							padding: 0;
							margin: 0;

							@include mq(desktop) {
								font-size: 18px;
							}

							&.is-hidden {
								display: none;
							}
						}
					}
				}

				> .oo-selection {
					max-width: calc(100% - 100px);
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-end;
					margin-right: 0;
					margin-left: auto;
					margin-bottom: 5px;

					> .oo-answer-item {
						display: block;
						font-weight: bold;
						padding: 5px 20px;
						border: 2px solid $color-primary-3;
						border-radius: 8px;
						margin-bottom: 15px;
						cursor: pointer;

						&.is-active,
						&:focus,
						&:hover {
							background-color: $color-primary-1;
							border-color: $color-primary-1;
							outline: none;
						}

						&:not(:first-child) {
							margin-left: 15px;
						}
					}
				}

				> .oo-answer-wrapper {
					margin-bottom: 20px;

					> .oo-answer {
						max-width: calc(100% - 100px);
						background-color: $color-secondary-10;
						position: relative;
						border-radius: 8px;
						padding: 25px;
						margin-right: 0;
						margin-left: auto;
						transition: opacity $mgl-animation;
						max-height: 500px;

						@include mq(desktop) {
							font-size: 18px;
						}

						&.is-hidden {
							opacity: 0;
							max-height: 0;
							margin-bottom: 0 !important;
							padding: 0;
						}

						&.is-dechosen {
							display: none;
						}

						&:before {
							content: '';
							position: absolute;
							top: 20px;
							right: -15px;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 0 15px 15px 0;
							border-color: transparent transparent $color-secondary-10 transparent;
						}
					}
				}
			}
		}

		> .oo-result-wrapper {
			> .oo-result {
				display: flex;
				align-items: flex-start;
				transition: opacity $mgl-animation;
				max-height: 500px;
				opacity: 1;

				&.is-hidden {
					opacity: 0;
					max-height: 0;
				}

				> .oo-image {
					> img {
						max-width: 60px;

						@include mq(desktop) {
							max-width: 75px;
						}
					}

					> span {
						display: block;
						text-align: center;
						font-size: 14px;
					}
				}

				> .oo-text {
					position: relative;
					border: 2px solid $color-secondary-9;
					width: calc(100% - 20px);
					margin-left: 20px;
					border-radius: 8px;
					padding: 20px;
					margin-bottom: 20px;

					@include mq(desktop) {
						font-size: 18px;
					}

					&:before {
						content: '';
						position: absolute;
						top: 20px;
						left: -15px;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 0 15px 15px;
						border-color: transparent transparent $color-secondary-9 transparent;
					}

					&:after {
						content: '';
						position: absolute;
						top: 23px;
						left: -11px;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 0 10px 11px;
						border-color: transparent transparent $color-primary-2 transparent;
					}

					> .oo-button-wrapper {
						margin-top: 20px;

						@include mq(desktop) {
							display: flex;
							justify-content: space-between;
						}

						> * {
							&:not(:last-child) {
								margin-bottom: 20px;

								@include mq(desktop) {
									margin-bottom: 0;
									margin-right: 30px;
								}
							}

							&:first-child {
								@include mq(desktop) {
									width: 290px;
								}
							}

							&:last-child:not(:only-child) {
								@include mq(desktop) {
									max-width: 150px;
								}
							}
						}
					}
				}
			}
		}
	}
}