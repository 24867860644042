/* o-basic-banderole--overview */
.o-basic-banderole--overview {
	.l-main-content & {
		padding-bottom: 0;
	}

	> .swiper {
		.l-main-content & {
			background: $color-primary-2;
			padding-bottom: 0;
		}

		.swiper-wrapper {

			.swiper-slide {

				&:nth-child(odd) {
					background-color: $color-secondary-10;

					> .oo-header-box {
						background-color: $color-secondary-10;
					}
				}

				&:first-child {
					border-left: 1px solid $color-secondary-3;
				}

				.m-basic-teaser-across {

					article {
						border-bottom: 0;

						figure {
							border-bottom: 1px solid $color-secondary-3;
						}

						.mm-price {
							padding: 12px;
						}

						.mm-article-content {
							border-top: none;
							border-bottom: 1px solid $color-secondary-3;
						}
					}
				}
			}
		}
	}
}