.a-basic-input-text--my-adac-content {

	> input {
		padding: 10px 35px 10px 10px;
		height: 40px;
		outline: none;
		background-color: $color-secondary-8;
		@include font-size(16px, 18px);

		&:focus {
			@include placeholder {
				color: $color-secondary-8;
			}
		}
	}
}