/* m-basic-list--disadvantage */
.m-basic-list--disadvantage {

	padding: 0 0 10px 8px;

	@include mq(print) {
		padding-left: 0;
	}

	// Exception table
	.m-basic-table & {
		padding-bottom: 0;
		padding-left: 0;
	}

	> li {
		margin-bottom: 10px;
		padding: 0 0 2px 28px;

		&:before {
			@include main-icon-close-black;
			left: -10px;
			top: -12px;

			@include mq(print) {
				@include main-icon-close-red;
			}
		}

		@include mq(print) {
			background: none;
		}

		// exception
		.m-basic-table table tr:nth-child(even) td & {
			@include mq(print) {
				box-shadow: inset 0 0 0 1000px $color-secondary-6;
			}
		}
	}

	/* m-basic-list--disadvantage--transparent */
	&--transparent {

		li {
			margin: 0 0 10px -3px;
			padding: 0 0 2px 24px;
			color: transparent;

			&:before {
				@include main-icon-close-red;
				top: -14px;
				left: -10px;
			}
		}
	}
}