/* a-basic-input-select--rating */
.a-basic-input-select--rating {
	width: 150px;
	margin-left: 15px;

	> select {
		border: none;
		font-weight: bold;
		background: $color-secondary-6;

		&:focus {
			border: 0;
			text-shadow: none;
		}
	}

	.aa-controller {
		background-color: $color-secondary-6;
	}
}