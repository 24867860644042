.swiper {
	padding: 0;
	cursor: move;

	@include mq(print) {
		margin-left: -40px;
	}

	// Exception js-basic-table-wrap
	.is-page-editor & {
		cursor: text;
	}

	// exception BasicBanderole
	.o-basic-banderole & {
		margin-top: 5px;
		padding: 10px 30px 10px 20px;

		@include mq(tablet) {
			margin-top: 12px;
			padding: 10px 0;
		}

		// check if content should be centered
		&.is-checking {
			float: left;
			width: auto;
			padding: 0;
		}
	}

	.o-basic-banderole.is-center & {
		margin-left: auto;
		margin-right: auto;
		padding-left: 0;
		padding-right: 0;
		cursor: initial;
	}

	.o-basic-banderole--award &,
	.o-basic-banderole--product &,
	.o-basic-banderole--highlight &,
	.o-basic-banderole--premium & {
		position: static;
	}

	.o-basic-banderole--award.is-center &,
	.o-basic-banderole--product.is-center &,
	.o-basic-banderole--highlight.is-center &,
	.o-basic-banderole--premium.is-center & {
		@include mq(print) {
			padding: 0;
		}
	}

	//exception for o-basic-banderole--sedcard
	.o-basic-banderole--sedcard & {
		cursor: pointer;
	}

	// exception .ll-main-center .l-main-content .o-basic-banderole
	.ll-main-center .l-main-content .o-basic-banderole & {
		margin: 2px -15px 0 -15px;

		@include mq(tablet) {
			margin: 2px -30px 0 -30px;
		}

		@include mq(tablet) {
			margin: 0 auto;
		}
	}

	// exception LayoutMainStage
	.o-layout-main-stage & {
		overflow: visible;
		position: static;
		cursor: default;

		@include mq(tablet) {
			overflow: hidden;
		}
	}

	// Exception basicKeywordfilter lexicon
	.m-basic-keywordfilter & {
		height: 100%;
	}

	// Exception gallery
	.m-basic-gallery & {
		height: 184px;
		padding: 10px 0;

		@include mq(tablet) {
			height: 355px;
		}
	}

	&.swiper-no-swiping {
		cursor: default !important;

	}


	.swiper-wrapper {

		// Exception o-basic-banderole
		.o-basic-banderole--award & {
			height: auto;
		}

		// Exception m-basic-keywordfilter lexicon
		.m-basic-keywordfilter--lexicon & {
			display: block;
		}
	}


	// edit original swiper class
	.swiper-slide {

		// Exception o-basic-banderole
		.o-basic-banderole:not(.o-basic-banderole--award) & {
			width: auto !important;
			//don't switch to 100%
			height: auto;
			float: left;
		}

		.o-basic-banderole--product & {
			background: $color-secondary-8;
		}

		.o-basic-banderole--award & {
			margin-right: 50px;
		}

		// Exception m-basic-keywordfilter lexicon
		.m-basic-keywordfilter--lexicon & {
			height: 40px;
		}

		// Exception js-basic-table-wrap
		.is-page-editor & {
			z-index: -1;
		}

		&:last-child {
			margin: 0 !important;
		}

		&.is-init {
			overflow: hidden;
			background: $color-secondary-5;

			// Exception o-basic-banderole--award
			.o-basic-banderole--award & {

				@include mq('tablet') {
					width: calc(50% - 10px) !important;
				}

				@include mq('desktop') {
					width: calc(100% / 3 - 40px / 3) !important;
				}

				background: url(/assets/img/bg/banderole-preview.gif) no-repeat;

				> a,
				> a > img {
					visibility: hidden;
				}
			}

			// Exception o-basic-banderole
			.o-basic-banderole & {
				margin-left: 15px;
			}
		}

		// edit original swiper class
		.swiper-lazy-preloader {
			border: none;
			display: none;

			// adopted from basicgallery.scss
			animation: none;

			// adopted from basicgallery.scss
			@include if-parent('.swiper-slide.swiper-slide-visible') {
				display: block;
				animation: swiper-preloader-spin 1s steps(12, end) infinite;
			}

			// exception LayoutMainStage
			.o-layout-main-stage & {
				top: 27%;

				@include mq(tablet) {
					top: 22%;
				}
			}

			&::after {
				animation: swiper-preloader-spin 1s steps(12, end) infinite;
				display: block;
				z-index: 100;
				position: absolute;
				top: calc(50% - 34px);
				left: calc(50% - 34px);
				content: '';
				opacity: 1;
				transition: opacity 300ms;
				@include main-icon-refresh-48px;
			}
		}
	}

	// exception BasicTeaserascross
	.m-basic-teaser-across .swiper-lazy-preloader {
		display: block;
	}

	.swiper-button-prev,
	.swiper-button-next {
		background-color: rgba(255, 255, 255, 0.9);
		border: 2px solid $color-primary-3;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		background-image: none;

		@include if-parent('--arrow-top') {
			top: 110px;
		}

		.is-keyboard-focus & {
			display: none;
		}

		.m-basic-breadcrumb & {
			border: none;
			border-radius: initial;
		}

		.o-layout-main-stage & {
			top: 110px;

			@include mq(tablet) {
				top: 50%;
			}
		}

		@include mq(print) {
			display: none;
		}

		&::after {
			content: "";
			position: absolute;
			width: 40px;
			height: 40px;
			left: -4px;
			top: -4px;
			transform: scale(1.5);
		}

		&.swiper-button-disabled {
			display: none;
		}

		&:focus-visible {
			outline: none;

			.is-keyboard-focus & {
				outline: 2px solid $color-primary-3;
			}
		}
	}

	.swiper-button-prev {
		left: 14px;

		&::after {
			@include main-icon-arrow-left-black;
		}
	}

	.swiper-button-next {
		right: 14px;

		&::after {
			@include main-icon-arrow-right-black;
		}
	}

	// table swiper
	&--table {
		padding: 0 1px 10px 0;
	}

	// exception VesRsList
	.m-ves-rs-list & {
		padding: 10px 0;
	}
}

// used for sticky tables
.swiper-container-wrap {
	position: relative;
	margin: 0 -15px;

	@include mq(tablet) {
		margin-left: 0;
		margin-right: 0;
	}

	// Exception
	.l-form .ll-fieldset > & {
		margin-left: 10px;
	}

	.js-basic-table-wrap > & {
		margin: 0;
		border: 1px solid $color-secondary-16;
		border-radius: 10px;

		@include mq(tablet) {
			padding: 0 30px;
		}
	}

	.js-basic-table-wrap.is-full > & {
		margin-left: 20px;
	}

	// Exception :: ll-main-full
	.ll-main-full > .js-basic-table-wrap > & {
		margin: -10px 0;
	}

	&.h-space-m {
		margin-bottom: 30px;

		// Exception :: ll-main-full
		.ll-main-full > .js-basic-table-wrap > & {
			margin-bottom: -10px;
		}
	}

	&.h-space-l {
		margin-bottom: 50px;

		// Exception :: ll-main-full
		.ll-main-full > .js-basic-table-wrap > & {
			margin-bottom: 0;
		}
	}

	.swiper-container-sticky {
		position: absolute;
		z-index: 10;
		overflow: hidden;
		top: 0;
		border-right: 1px solid $color-secondary-5;
		height: 100%;

		@include if-parent('.swiper-container-wrap.has-stopper') {
			padding-top: 24px;

			// Exception in ll-main-full--table
			.ll-main-full--table & {
				padding-top: 34px;
			}
		}
	}

	.swiper--table {
		@include if-parent('.swiper-container-wrap.has-stopper') {
			z-index: 10;
			padding-bottom: 44px;

			&:before {
				content: ' ';
				display: block;
				width: 1px;
				height: 34px;
				position: absolute;
				top: 0;
				left: 0;
				background: $color-primary-2;

				.ll-main-full--table & {
					top: 10px;
				}
			}
		}

		// Exception in js-basic-table-wrap
		@include if-parent('.swiper-container-wrap.h-space-s') {
			margin-bottom: -10px;
		}
	}

	// zoom function btn
	> .js-table-zoom {
		display: none;

		@include mq(tablet) {
			display: block;
			position: absolute;
			top: -39px;
			right: 4px;
			width: 30px;
			height: 30px;
			z-index: 11;
			@include main-icon-zoom-in;
			cursor: pointer;
		}

		&--stopper {
			top: 40px;
		}
	}

	.swiper-container-table {
		position: absolute;
		overflow: hidden;

		@include if-parent('.swiper-container-wrap.has-stopper') {
			padding-top: 24px;

			// Exception in ll-main-full--table
			.ll-main-full--table & {
				padding-top: 34px;

			}
		}
	}
}

.swiper-pagination {
	width: 100%;
	top: 0;

	// if we don't write a more specific CSS statement, it will be overridden by the plugin CSS
	&.swiper-pagination-horizontal {
		bottom: auto;
	}

	.swiper-pagination-bullet {
		width: 12px;
		height: 12px;
		background-color: $color-secondary-4;
		margin: 0 2px !important;

		&:only-child {
			display: none;
		}

		.ll-main-full & {
			background-color: $color-primary-2;
			opacity: 1;
		}

		&.swiper-pagination-bullet-active {
			background-color: $color-primary-1;

			.is-keyboard-focus & {
				background-color: $color-primary-3;
			}
		}
	}
}