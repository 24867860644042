.m-basic-sticky-service-contact-restyle {
	position: fixed;
	right: 15px;
	bottom: 20px;
	z-index: 1500;

	@include mq(tablet) {
		bottom: 170px;
	}

	&.has-basic-form-footer-sticky {
		bottom: 270px;

		@include mq(tablet) {
			bottom: 180px;
		}
	}

	&--animated:focus {
		.is-keyboard-focus & {
			outline: none;
			box-shadow: 0 0 0 2px $color-primary-3, 0 0 0 5px $color-primary-2;
			border-radius: 50%;
		}
	}

	> .mm-trigger {
		height: 48px;
		width: 48px;
		background: $color-primary-1;
		cursor: pointer;
		border-radius: 50%;
		box-shadow: 0 0 7px 2px $color-secondary-4;
		color: $color-secondary-1;
		text-align: center;

		@include if-parent('.m-basic-sticky-service-contact-restyle.is-open') {
			display: none;
		}

		@include if-parent('.m-basic-sticky-service-contact-restyle.m-basic-sticky-service-contact-restyle--animated') {
			animation: bounce 4s 1s 1;
		}

		&:hover {
			> span {
				&.mm-def {
					display: none;
				}

				&.mm-hover {
					display: block;
				}
			}
		}

		&:focus-visible {
			.is-keyboard-focus & {
				outline: none;
				box-shadow: 0 0 0 2px $color-primary-3, 0 0 0 5px $color-primary-2;
			}
		}

		> span {
			&.mm-def {
				position: relative;
				width: 48px;
				height: 48px;
				overflow: hidden;
				display: block;

				img {
					padding-top: 3px;
					padding-left: 0.5px;
				}
			}

			&.mm-hover {
				@include milo-slab-bold(11px);
				line-height: 48px;
				display: none;
			}
		}
	}

	> .mm-content-layer {
		height: 100vh;
		width: 100vw;
		max-height: 100vh;
		position: fixed;
		background: $color-primary-2;
		left: 0;
		top: 0;
		padding: 10px 15px 0;
		border: 1px solid $color-primary-2;
		overflow-y: auto;
		overflow-x: hidden;
		display: none;

		@include mq(tablet) {
			max-width: 450px;
			max-height: 585px;
			right: 15px;
			top: 15px;
			left: auto;
			padding: 10px 10px 30px;
			position: fixed;
			border-radius: 8px;
			box-shadow: 0 0 7px 2px $color-secondary-4;
		}

		@include mq(desktop) {
			max-height: 785px;
		}

		> .mm-navigation {
			position: relative;

			> .mm-close {
				@extend %focus-outline;
				@include main-icon-close;
				cursor: pointer;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 1;
				width: 41px;
				height: 41px;
			}

			> .mm-headline {
				@include milo-bold($font-size-24);
				padding: 35px 0 20px 0;
				text-align: center;
			}

			> ul {
				> li {
					@include milo-bold($font-size-18);
					cursor: pointer;
					border-top: 1px solid $color-secondary-9;
					padding: 17px 0 17px 45px;
					position: relative;
					@extend %focus-outline;

					&:focus {
						.is-keyboard-focus & {
							position: relative;
							z-index: 1;
						}
					}

					> .mm-img-wrapper {
						position: absolute;
						left: 0;
						top: 0;
						width: 35px;
						height: 55px;

						> img {
							position: relative;
							max-width: 100%;
							left: 0;
							top: 50%;
							transform: translateY(-50%);

							@include if-parent('.mm-img-wrapper--indent') {
								left: 5px;
							}
						}
					}

					&:last-child {
						border-bottom: 1px solid $color-secondary-9;
					}

					&:after {
						content: '';
						position: absolute;
						@include main-icon-arrow-2-right;
						right: 0;
						top: 0;
						height: 50px;
						width: 30px;
					}
				}
			}
		}

		> .mm-content-wrapper {
			.mm-content {
				position: absolute;
				left: 100%;
				top: 0;
				height: 100%;
				width: 100%;
				background: $color-primary-2;
				overflow: hidden;
				display: none;

				.mm-scrollpane {
					top: 0;
					height: 100%;
				}

				.mm-pad-wrapper {
					padding: 40px 20px;
					height: 100%;
				}

				.mm-header {
					position: relative;
					border-bottom: 1px solid $color-secondary-9;
					padding: 0 0 20px 0;
					margin-bottom: 15px;
					background-color: $color-primary-2;

					@include if-parent('.mm-content--full') {
						padding: 0;
						margin: 0;
						border: none;
					}

					> .mm-close-content {
						@extend %focus-outline;
						cursor: pointer;
						position: absolute;
						@include main-icon-arrow-2-left;
						left: -2px;
						top: -30px;
						width: 41px;
						height: 41px;
						display: inline-block;

						@include if-parent('.mm-content--full') {
							left: auto;
							right: 45px;
							top: 17px;
						}
					}

					> .mm-headline-wrapper {
						@include milo-bold($font-size-24);
						text-align: center;
						padding: 0 20px;

						@include if-parent('.mm-content--double-hl') {
							display: flex;
							justify-content: center;
							align-items: flex-start;
							margin: 0 20px;
						}

						> span {
							@include if-parent('.mm-content--double-hl') {
								display: block;
								flex: 1;
								text-align: left;
							}
						}

						> img {
							position: relative;
							top: 9px;

							@include if-parent('.mm-content--double-hl') {
								top: 3px;
							}
						}
					}
				}

				.mm-wrapper {
					padding: 0 10px;
				}

				.mm-contact-hint {
					margin-bottom: 20px;

					&:last-child {
						margin-bottom: 0;
					}

					> .mm-hint-label {
						@include milo-bold();
					}

					> .mm-hint-number {
						@include milo-bold($font-size-20);
						text-decoration: none;
						display: block;

						@include mq(tablet) {
							font-size: $font-size-24
						}
					}

				}

				.ll-col-data--multi {
					width: 100%;
				}

				iframe {
					width: 100%;
					height: 550px;
					margin-top: -70px;
					border: 0;

					@include mq(desktop) {
						height: 664px;
					}
				}
			}
		}
	}

	.jspContainer {

		//scrollbar wrapper
		> .jspVerticalBar {
			width: 5px;
			right: 0;

			> .jspTrack {
				background: $color-secondary-5;

				> .jspDrag {
					background: $color-secondary-2;
				}
			}
		}

		> .jspHorizontalBar {
			display: none;
		}
	}

	@keyframes bounce {

		0%,
		5%,
		13.25%,
		20%,
		100% {
			animation-timing-function: cubic-bezier(.215, .61, .355, 1);
			transform: translateZ(0);
		}

		10%,
		10.75% {
			animation-timing-function: cubic-bezier(.755, .05, .855, .06);
			transform: translate3d(0, -30px, 0);
		}

		17.5% {
			animation-timing-function: cubic-bezier(.755, .05, .855, .06);
			transform: translate3d(0, -15px, 0);
		}

		22.5% {
			transform: translate3d(0, -4px, 0);
		}

		22.5% {
			transform: translate3d(0, 0, 0);
		}
	}
}