.m-my-adac-info-box {


	// exception for myAdac site´s
	.l-outer--my-adac & {
		// -8px - for each content there is a mm-content(box) with padding: 0 24px
		// here we need 16px padding
		margin: 0 -8px 24px -8px;
	}

	// Exception for the my-adac data-protection-hint in dashboard-box
	.mm-data-protection-hint & {
		margin: 0;
	}

	// Exception for the my-adac data-protection-hint in dashboard-box
	.mm-text-hint & {
		margin: 0 auto;
		max-width: 960px;
	}

	> .mm-info-box-wrapper {
		display: block;
		position: relative;
		background-color: $color-primary-1;
		padding: 24px;
		border-radius: 10px;

		&--is-splited {
			display: flex;
			flex-direction: column;

			@include mq(tablet) {
				flex-direction: row;
			}
		}

		// exception for myAdac content site´s
		.l-outer--my-adac-content & {
			max-width: 380px;
			margin: 0 auto;
		}

		// Exception for the my-adac data-protection-hint in dashboard-box
		.mm-data-protection-hint & {
			text-align: center;
			margin: 0 auto;
		}

		// Exception for the my-adac data-protection-hint in dashboard-box
		.mm-text-hint & {
			background-color: transparent;
		}

		// Exception for the my-adac small info-boxes
		@include if-parent('.m-my-adac-info-box.m-my-adac-info-box--small') {
			padding: 16px 24px;
		}

		@include if-parent('.m-my-adac-info-box--stopper-outside') {
			position: static;
		}

		@include if-parent('.m-my-adac-info-box--outer-tile') {
			padding: 24px 0;

			@include mq(desktop) {
				padding: 24px 30px;
			}
		}

		> .mm-info-box-wrapper-img {
			display: flex;
			justify-content: center;
			margin-bottom: 16px;

			@include mq(tablet) {
				width: 25%;
				max-width: 25%;
				margin-right: 24px;
				margin-bottom: auto;
				margin-top: auto;
			}
		}

		> .mm-info-box-wrapper-content {
			height: 100%;
			margin-top: auto;
			margin-bottom: auto;

			@include mq(tablet) {
				width: 75%;
				max-width: 75%;
			}
		}

		.a-basic-link:not(.a-basic-link--my-adac-promotion) {
			position: relative;
			margin: 16px 0 0 auto;
			bottom: 0;
			right: 0;

			&:before {
				height: 29px;
				margin-right: -13px;
				position: absolute;
				top: -1px;
				left: -30px;
			}

			// display none when in .m-my-adac-dashboard-box
			.mm-box & {
				display: none;
			}
		}

		.mm-appstore {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;

			> a {
				flex: 0 1 50%;

				> img {
					max-width: 120px;
				}
			}
		}

		.a-basic-stopper--my-adac {

			~ .mm-info-box-text {
				max-width: 75%;
				min-height: 72px;

				@include mq(tablet) {
					max-width: 80%;
				}

				@include mq(desktop) {
					max-width: 82%;
				}

				&:last-child {
					min-height: auto;
					margin: 0;
				}
			}

			~ .mm-info-box-subtext {
				max-width: 75%;

				@include mq(tablet) {
					max-width: 80%;
				}

				@include mq(desktop) {
					max-width: 82%;
				}

				+ .mm-info-box-text {
					min-height: 48px;
				}
			}
		}

		.mm-info-box-headline {
			margin-bottom: 16px;
			padding: 0;
			@include milo-bold(16px, 20px);
		}

		.mm-info-box-text {
			margin-bottom: 16px;
			@include milo-bold(20px, 24px);

			// Exception for the my-adac data-protection-hint in dashboard-box
			.mm-data-protection-hint & {
				@include milo-bold(16px, 20px);
				max-width: 100%;
			}

			// Exception for the my-adac data-protection-hint in dashboard-box
			.mm-text-hint & {
				@include milo-regular(16px, 20px);
				max-width: 100%;
			}

			// Exception for the my-adac small info-boxes
			@include if-parent('.m-my-adac-info-box.m-my-adac-info-box--small') {
				@include milo-bold(16px, 20px);

				// Exception for the my-adac data-protection-hint in dashboard-box
				.mm-text-hint & {
					@include milo-regular(16px, 20px);
				}
			}

			// Exception for the my-adac small info-boxes
			@include if-parent('.m-my-adac-info-box.m-my-adac-info-box--center') {
				text-align: center;
			}

			&:only-child {

				// Exception for mm-data-protection-hint
				.mm-data-protection-hint & {
					margin: 5px 0;
				}
			}
		}

		.mm-info-box-subtext {
			margin-bottom: 16px;
			padding: 0;
			@include milo-regular(16px, 18px);
			font-weight: normal;
			font-style: normal;
		}

		.mm-button-wrapper {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;

			&--row {

				@include mq(tablet) {
					flex-flow: row nowrap;
				}
			}
		}
	}
}