/* m-compare-product-sticky */
.m-compare-product-sticky {
	@include fixed($bottom: 0, $left: 0, $right: 0);
	width: 100%;
	z-index: 90;
	max-width: 898px;
	margin: 0 auto;


	@include mq(print) {
		display: none;
	}

	@include mq(desktop) {
		padding: 0;
		width: 100%;
	}

	&.is-stuck-bottom {
		position: absolute;
		bottom: -80px;
	}

	&.is-stuck-unbottom {
		position: fixed;
		bottom: 0;
	}

	> .mm-compare-slide {
		display: block;
		width: 100%;
		min-height: 43px;
		padding: 10px 9px 10px 24px;
		cursor: pointer;
		@include milo-bold($font-size-16);
		border: 1px solid $color-secondary-3;
		background-color: $color-primary-1;

		&:before {
			content: '';
			display: inline-block;
			@include main-icon-link;
			position: absolute;
			top: -1px;
			left: 0;
		}

		&.mm-opened {

			&:before {
				@include main-icon-arrow-drop-down;
				position: absolute;
				top: -1px;
				left: 0;
			}
		}

		.m-basic-text {
			padding-left: 10px;

			.mm-content {
				margin: 0;
			}
		}
	}

	> .mm-compare-container {
		background: $color-primary-2;
		float: left;
		width: 100%;
		padding: 20px 20px 0 20px;
		border-right: 1px solid $color-secondary-3;
		border-bottom: 1px solid $color-secondary-3;
		border-left: 1px solid $color-secondary-3;

		> .swiper {

			> .swiper-wrapper {
				padding: 0;

				> .swiper-slide {
					position: relative;
					width: 150px;

					> .mm-icon-cancel {
						@include main-icon-cancel-black;
						margin: 0;
						cursor: pointer;
						@include absolute($right: 20px, $top: 0);
						z-index: 10;
						right: 18px;
						position: absolute;
						width: 31px;
						height: 32px;
					}

					> .mm-figure-product {
						width: 100px;
						height: 65px;
						margin: 0;
						position: relative;
						background: $color-secondary-6;
						overflow: hidden;

						> .mm-product {
							width: 100%;
							height: auto;
						}
					}

					> .mm-description {
						padding: 0;
						@include arial($font-size-15);
						line-height: 1.2;
						font-weight: normal;
						max-width: 105px;
						text-decoration: underline;
					}
				}

				> .m-basic-text--hidden {
					display: none;
					visibility: hidden;
				}
			}
		}

		> .js-lightbox--trigger {
			display: none;
			visibility: hidden;
			opacity: 0;
		}

		> .a-basic-btn {
			margin-top: 20px;
			margin-right: 20px;
		}

		&--hidden {
			display: none;
		}
	}

	.l-outer--nav & {
		padding-right: 0;

		@include mq(desktop) {
			padding-right: 40px;
		}

		@include mq(desktop-l) {
			padding-right: 40px;
		}

		@include mq(desktop-xl) {
			padding-right: 0;
		}
	}
}