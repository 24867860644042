/* m-basic-rating */
.m-basic-rating--user {
	background: transparent;
	padding: 0;

	.mm-result-list & {
		display: inline-block
	}

	> .mm-rating-content {
		display: flex;
		flex-direction: column;

		@include mq(tablet) {
			flex-direction: row;
		}

		> .mm-star-container {
			margin: 0px 10px 1px -10px;
			display: flex;
			flex-flow: row wrap;
			max-height: 35px;

			> .mm-label {
				font-weight: bolder;
				@include milo-bold($font-size-28, 27px);
				display: inline-block;
				margin-right: 25px;
			}

			.m-basic-table & {
				margin: 0 0 -9px -10px;
			}

			@include mq(desktop) {
				flex-flow: row nowrap;
			}

			> div {
				display: flex;
				flex-flow: row nowrap;

				> .mm-star {

					.m-adac-maps & {
						display: none;
					}

					.m-basic-rating.is-clickable & {
						cursor: pointer;
					}

					&:before {
						content: '';
						@include main-icon-star-empty-black;
						position: absolute;
					}

					&--full {

						.m-adac-maps & {
							display: inline-block;
							width: 18px;
							height: 18px;
						}

						&:before {
							@include main-icon-star-fill-black;
						}
					}

					&--half {

						.m-adac-maps & {
							display: none;
						}

						&:before {
							@include main-icon-star-half-black;
						}
					}

					&:first-child {

						&:before {
							@include main-icon-star-fill-black;
						}
					}

					&.is-clickable {
						cursor: pointer;
					}

				}

				> .mm-circle {
					width: 33px;
					height: 28px;
					display: inline-block;
					position: relative;
					margin-top: -3px;

					&:before {
						content: '';
						position: absolute;
						@include main-icon-circle-empty;
						top: -6px;
					}

					&--full {
						&:before {
							@include main-icon-circle-full;
						}
					}

					&--half {
						&:before {
							@include main-icon-circle-half;
						}
					}

					&:first-child {
						&:before {
							@include main-icon-circle-full;
						}
					}
				}
			}

			> .mm-rating-sum {
				max-width: 100%;
				display: block;
				margin: 2px 0 0 17px;
				color: $color-primary-3;
				font-weight: bold;
			}
		}
	}

	&.m-basic-rating--green {

		> .mm-rating-content {

			> .mm-star-container {

				> div {

					> .mm-star {
						&:before {
							@include main-icon-star-grey-empty;
						}

						&--full {
							&:before {
								@include main-icon-star-green-full;
							}
						}

						&:first-child {
							&:before {
								@include main-icon-star-green-full;
							}
						}
					}
				}
			}
		}
	}

	&.m-basic-rating--blue {

		> .mm-rating-content {

			> .mm-star-container {

				> div {

					> .mm-star {
						&:before {
							@include main-icon-star-grey-empty;
						}

						&--full {
							&:before {
								@include main-icon-star-green-full;
							}
						}

						&:first-child {
							&:before {
								@include main-icon-star-green-full;
							}
						}
					}
				}
			}
		}
	}
}