.m-basic-toggle--link {
	border-bottom: 1px solid $color-primary-2;

	// Exception: m-basic-keywordfilter
	.m-basic-keywordfilter & {
		clear: both;
	}

	// Exception: basic-calculator-tiles
	.m-basic-calculator-tiles & {
		border: none;
	}

	.mm-head {
		position: relative;
		left: -2px;
		padding-left: 20px;
		background-color: transparent;
		border-top: 1px solid $color-primary-2;

		&:before {
			top: -2px;
			left: -13px;

			// Exception: m-basic-calculator-tile
			.m-basic-calculator-tiles & {
				margin-top: 0.8px;
			}
		}

		&.mm-opened {
			background-color: transparent;
			border-bottom: 1px solid $color-primary-2;

			// Exception: m-basic-calculator-tile
			.m-basic-calculator-tiles & {
				border-bottom: initial;
			}

			+ .mm-content {
				padding: 0;
			}
		}

		// Exception: m-basic-calculator-tile
		.m-basic-calculator-tiles & {
			border: none;
			padding: 11px 75px 11px 30px;
		}

		> p {

			// Exception: m-basic-calculator-tile
			.m-basic-calculator-tiles & {
				margin-left: 12px;
			}
		}
	}

	.mm-content {

		@include mq(tablet) {
			padding: 0;
		}

		&--bg {
			background: $color-secondary-6;
		}

		// Exception: m-basic-calculator-tile
		.m-basic-calculator-tiles & {
			margin-left: 40px;
		}
	}
}