/* o-layout-main-stage */
.o-layout-ves-stage {
	position: relative;
	z-index: 10;
	background-color: $color-secondary-10;

	// Exception m-basic-header
	.m-basic-header ~ .l-main > & {
		margin-top: -40px;

		@include mq(tablet) {
			margin-top: -42px;
			margin-bottom: 50px;
		}

		@include mq(desktop) {
			margin-top: -64px;
		}
	}

	// Exception m-basic-header if m-basic-breadcrumb is present
	.m-basic-header + .m-basic-breadcrumb ~ .l-main > & {
		margin-top: -40px;

		@include mq(desktop) {
			margin-top: -60px;
		}
	}

	> .oo-wrapper {
		position: relative;
		overflow: hidden;
		z-index: 10;
		max-width: 1440px;
		margin: 0 auto;

		@include mq(desktop) {
			display: flex;
		}

		> .oo-item {
			@include mq(desktop) {
				flex: 1 1 auto;
				width: 50%;
			}

			&--image {

				@include mq(desktop) {
					overflow: hidden;
				}

				> .oo-figure {
					margin: 0;
					height: 100%;

					> picture {
						display: block;
						height: 100%;

						> img {
							display: block;
							max-width: 100%;
							width: 100%;
							height: auto;

							@include mq(desktop) {
								max-width: none;
								width: auto;
								height: 100%;
								position: relative;
								left: 50%;
								transform: translateX(-50%);
							}
						}
					}
				}
			}

			&--content {
				padding: 20px;
				position: relative;
				min-height: 120px;
				z-index: 1;

				@include mq(desktop) {
					padding: 40px 145px 20px 40px;
				}

				> .oo-text {
					@include mq(desktop) {
						padding-right: 125px;
					}

					> .oo-button-wrapper {
						margin-top: 15px;
						margin-bottom: -15px;
						display: flex;
						flex-wrap: wrap;
						align-items: center;

						> * {
							margin-bottom: 15px;

							&:not(:last-child) {
								margin-right: 20px;

								@include mq(desktop) {
									margin-right: 40px;
								}
							}
						}
					}
				}

				> .oo-stopper {
					position: absolute;
					top: -20px;
					right: 20px;
					transform: translateY(-100%);
					width: 100%;
					pointer-events: none;

					@include mq(desktop) {
						top: calc(100% - 50px);
						right: calc(50% + 25px);
						transform: translate(-50%, -100%);
					}

					@include mq(desktop-l) {
						top: 30px;
						right: 40px;
						transform: none;
					}
				}

				> .oo-siegel {
					margin-top: 20px;

					@include mq(desktop) {
						position: absolute;
						bottom: 40px;
						right: 40px;
						margin-top: 0;
					}

					> img {
						display: block;
					}
				}
			}

			> .oo-headline {
				margin: 0;
				padding: 0 0 14px 0;
				@include milo-slab-bold($font-size-22, 29px);

				@include mq(tablet) {
					padding: 0 0 17px 0;
					@include font-size($font-size-36, 43px);
				}

				@include mq(desktop) {
					@include font-size($font-size-40, 48px);
				}

				@include mq(print) {
					@include milo-slab-bold($font-size-24, 31px);
				}
			}
		}
	}
}