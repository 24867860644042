/* m-ves-vehicle-select */
.m-ves-vehicle-select {

	ul {
		margin: 0 0 0 -10px;
		padding: 10px 0 0 0;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;

		.mm-caritem {
			margin: 0 0 10px 10px;
			width: 135px;
			height: 114px;

			@include mq(tablet) {
				width: 198px;
				height: 142px;
			}

			&.is-active {
				> a {
					border: 5px solid $color-primary-1;
					border-top: 0;

					&:hover {
						.mm-header {
							background: $color-primary-1;
						}
					}
				}
			}

			> a {
				background: $color-secondary-6;
				display: block;
				height: 114px;

				@include mq(tablet) {
					height: 142px;
				}

				&:hover {
					.mm-header {
						background: $color-action-btn;
					}
				}

				.mm-header {
					display: block;
					@include milo-regular($font-size-16);
					height: 34px;
					text-align: center;
					background: $color-primary-1;

					.mm-title {
						display: inline-block;
						padding: 0 0 0 18px;
						@include line-height(34px);
						max-width: calc(100% - 20px);
						overflow: hidden;
						text-overflow: ellipsis;
						position: relative;

						&:before {
							content: '';
							@include main-icon-arrow-right-black;
							@include absolute($top: -5px, $left: -15px)
						}
					}
				}

				> img {
					margin: 0 auto;
					display: block;
					max-height: 68px;
					max-width: calc(100% - 20px);
					padding-top: 10px;

					@include mq(tablet) {
						padding-top: 20px;
						max-height: inherit;
					}
				}
			}
		}
	}
}