.o-layout-main-stage--regionalclubs {

	> .swiper {

		> .swiper-wrapper {

			> .swiper-slide {

				> .oo-box {
					bottom: auto;
					padding: 10px;
					margin: 0;
					height: auto;

					@include mq(tablet) {
						top: 75px;
						padding: 25px 40px 10px;
						margin: 0;
						left: 50%;
						transform: translate(-50%, 0);
						width: 688px;
					}

					@include mq(desktop) {
						width: 845px;
					}

					.oo-box-head {
						max-width: 100%;
						line-height: 28px;
						@include milo-slab-bold();

						@include mq(desktop) {
							line-height: 50px;
						}

						@include mq(tablet) {
							@include font-size($font-size-36);
						}
					}

					.oo-box-text {
						height: unset;
						max-width: unset;
					}

					.l-form {
						display: flex;
						justify-content: flex-start;

						.ll-fieldset {
							float: left;
							margin-right: 10px;
							margin-bottom: 10px;

							> .ll-row > .ll-col-data {
								width: 100%;
							}

							&:nth-child(1) {
								width: 75%;
							}

							&:nth-child(2) {
								width: 30%;
							}
						}
					}
				}

			}
		}
	}
}