/* o-basic-form-footer */
.o-basic-form-footer {

	&.h-space-s {
		margin-bottom: 0;
	}

	// Expeption: l-main-cols--line
	.l-main-cols--line > .ll-main-cols-m > & {
		@include mq(tablet) {
			margin-right: 20px;
		}
	}

	// Exception ll-main-content-switch
	.ll-main-content-switchbox > & {
		margin-right: $l-main-content-padding-side * 2;

		@include mq(tablet) {
			margin-right: 0;
		}

		@include mq(desktop) {
			margin-right: 0;
		}
	}


	.oo-right {
		p {
			position: relative;
			margin: 0;
			padding-left: 27px;
		}

		.a-basic-icon-verified {
			position: absolute;
			left: 0;
			top: 1px;
		}
	}

	.l-main-content--login & {
		margin-top: 30px;

		@include mq(tablet) {
			margin-top: 55px;
		}
	}
}