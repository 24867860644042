@import "../../../../assets/svg-sprite/scss/weather-sprite.scss";

.m-ruf-weather-chart {
	width: 100%;
	max-height: 460px;
	min-height: 400px;

	> .mm-wrapper {

		> .mm-chart-box {
			max-width: 100%;

			> .highcharts-container {

				> .highcharts-axis.highcharts-xaxis {

					> text {
						@include milo-regular(14px);

						@include mq(tablet) {
							@include milo-regular(16px);
						}
					}

					&.mm-label-wrapper {

						&--rain-percantage {
							top: 295px !important;
							right: -12px !important;
							left: auto !important;
							width: 40px;
							height: 40px;

							> .highcharts-axis-title {
								position: static !important;

								> .m-icon {
									> img {
										@include mq(tablet) {
											max-width: 40px;
										}

										@include if-parent('.m-icon--umbrella') {
											@include weather-umbrella-dim;
										}
									}
								}
							}
						}

						&--snow-amount {
							top: 338px !important;
							right: -10px !important;
							left: auto !important;
							width: 40px;
							height: 40px;

							&.is-hidden {
								display: none;
							}

							> .highcharts-axis-title {
								position: static !important;

								> .m-icon {
									> img {
										@include mq(tablet) {
											max-width: 40px;
										}

										@include if-parent('.m-icon--snow') {
											width: 40px;
											height: auto;
										}
									}
								}
							}
						}
					}
				}

				> .highcharts-axis-labels {

					&.mm-label-wrapper {
						display: flex !important;
						flex-flow: row nowrap;
						justify-content: space-between;
						left: 50px !important;
						right: 30px !important;

						@include mq(tablet) {
							right: 20px !important;
						}

						&.is-hidden {
							display: none !important;
						}

						&--main {

							@include if-parent('.m-ruf-weather-chart--second') {
								top: 60px !important;

								@include mq(tablet) {
									top: 50px !important;
								}
							}
						}

						&--rain-percantage {
							top: 300px !important;
						}

						&--rain-amount {
							top: 315px !important;
						}

						&--snow-amount {
							top: 350px !important;
						}
					}


					> span {
						@include milo-regular(14px);
						white-space: nowrap;
						text-align: center;
						position: static !important;
						min-width: 70px;
						width: auto !important;
						z-index: 0;

						@include mq(tablet) {
							min-width: 60px;
							padding: 0;
							@include milo-regular(16px);
						}

						> .mm-sub-label,
						> .mm-main-label {
							@include milo-regular(13px);

							@include mq(tablet) {
								@include milo-regular(13px);
							}

							@include if-parent('.highcharts-axis-labels.mm-label-wrapper--main') {
								@include milo-regular(14px);

								@include mq(tablet) {
									@include milo-regular(16px);
								}
							}
						}
					}

					// time or date
					.m-timeline {
						@include milo-regular(16px);
					}
				}

				// svg
				> .highcharts-root {

					> .highcharts-credits {
						@include milo-regular(16px);
						font-size: 18px !important;
					}

					// red label tooltip, disables it
					> .highcharts-label.highcharts-tooltip {
						display: none;
					}

					// labels 12°C on top of the lines
					> .highcharts-data-labels {

						> .highcharts-data-label {

							> text {
								@include milo-regular(14px);
								font-size: 14px !important;

								@include mq(tablet) {
									@include milo-regular(16px);
									font-size: 16px !important;
								}
							}
						}
					}

					> .mm-weather-icon {
						max-width: 20px;

						@include mq(tablet) {
							max-width: 40px;
						}
					}

					> .highcharts-axis-labels {
						display: flex !important;
						flex-flow: row nowrap;
						justify-content: space-between;

						left: 60px !important;
						right: 20px !important;
						@include milo-regular(14px);

						@include mq(tablet) {
							@include milo-regular(16px);
						}

						@include if-parent('.m-ruf-weather-chart--second') {
							top: 40px !important;
						}

						> text {
							position: static !important;
							width: auto !important;
							@include milo-regular(14px);
							color: $color-secondary-1;
							white-space: nowrap;
							text-align: center;
							z-index: 0;

							@include mq(tablet) {
								transform: rotate(0) translateX(0px) !important;
								@include milo-regular(16px);
							}

							&:empty {
								display: none;
							}

							> tspan {
								@include milo-regular(14px);
								color: $color-secondary-1;

								@include mq(tablet) {
									@include milo-regular(16px);
								}
							}
						}

						// time or date
						.m-timeline {
							@include milo-regular(16px);
						}
					}
				}

				// tooltip
				> .highcharts-tooltip {
					border: 1px solid $color-secondary-3;
					display: block;
					background: white !important;
					padding: 10px;
					z-index: 1;

					@include mq(tablet) {
						padding: 15px 20px;
						width: 300px;
					}

					> span {
						position: static !important;
						background: white !important;
						margin: 0;
						height: auto;
						display: block;
						padding: 0;

						> .mm-tooltip-wrapper {

							> .mm-headline {
								display: block;
								@include milo-bold(14px);
								max-width: calc(100% - 36px);
								padding: 0;
								margin: 0 0 5px 0;

								@include mq(tablet) {
									@include milo-bold(16px);
									margin: 0 0 10px 0;
								}
							}

							> .mm-weather-icon {
								position: absolute;
								top: 8px;
								right: 8px;
								width: 60px;
								height: 60px;

								> img {
									max-width: 100%;
								}
							}

							> ul {
								padding: 0 0 0 15px;
								margin: 0;

								> .mm-tooltip {
									padding: 0 20px 0 8px;
									margin: 0 0 2px 0;
									list-style: none;
									position: relative;
									@include milo-regular(14px);

									@include mq(tablet) {
										margin: 0 0 10px 0;
										@include milo-regular(16px);
									}

									@include if-parent('.m-ruf-weather-chart--second') {
										margin: 0;
									}

									&.is-hidden {
										display: none;
										border: 2px solid red;
									}

									&.mm-sub-tooltip {
										margin: 0 0 10px 0;
									}

									&:before {
										content: '•';
										position: absolute;
										left: -5px;
										top: 0;
										font-size: 16px;
										vertical-align: middle;

										@include mq(tablet) {
											font-size: 20px;
										}

										@include if-parent('.mm-tooltip.mm-sub-tooltip') {
											display: none;
										}
									}
								}
							}
						}
					}
				}

				> .highcharts-title {

					> .m-weather-headline {
						@include milo-slab-bold(18px);
						margin: 0 0 20px 0;

						@include mq(tablet) {
							@include milo-slab-bold(32px);
						}
					}
				}
			}
		}

		> .mm-chart-credit {
			display: flex;
			justify-content: flex-end;

			> .mm-chart-credit-link {
				display: flex;
				align-items: center;
				@include milo-regular(16px);
				text-decoration: none;
				color: $color-secondary-1;

				&:hover {
					color: $color-secondary-1;
				}

				&:visited {
					color: $color-secondary-1;
				}

				> .mm-chart-credit-icon {
					max-width: 30px;
					margin-left: 10px;
				}
			}
		}
	}
}