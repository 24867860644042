/* o-basic-form-footer--data */
.o-basic-form-footer--data {
	padding-top: 17px;
	border-top: 1px solid $color-secondary-5;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex: 1 1 auto;
	flex-wrap: wrap;

	@include mq(tablet) {
		flex-wrap: nowrap;
	}

	.oo-left {
		align-self: flex-start;
		width: 100%;

		+ .oo-right {
			flex: 0 0 auto;
			padding-right: 25px;
			width: 100%;

			@include mq(tablet) {
				width: auto;
			}

			> p {
				text-align: right;

				> a {
					text-align: right;
				}

				> .a-basic-icon-verified {
					left: inherit;
					right: -25px;
				}
			}
		}

		.m-basic-list {
			margin: 0;
		}
	}
}