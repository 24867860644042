/* l-header */
.l-header {
	position: fixed;
	transition: top 0.5s;
	z-index: 110;
	background: $color-primary-1;
	width: 100%;

	@include mq(desktop) {
		top: 0;
		width: 100%;
	}

	&.header-input-is-focused {
		box-shadow: none;
	}

	@include mq(print) {
		border-bottom: 1px solid #B0B0B0;
		position: relative;
	}


	&.headroom--unpinned {
		top: -83px;

		@include mq(tablet) {
			top: -222px;
		}
	}

	&.headroom--pinned {
		top: 0;
	}

	&.stay-visible {
		top: 0;
	}

	.ll-header-center {
		min-height: 65px;
		position: relative;

		@include mq(tablet) {
			height: 73px;
			z-index: 115;
		}

		@include mq(desktop) {
			position: relative;
			height: 82px;
			margin: 0 40px;

			.l-outer--nav & {
				margin: 0 40px;
			}
		}

		@include mq(desktop-l) {
			max-width: 898px;
			margin: 0 auto;

			.l-outer--nav & {
				max-width: 927px;
				margin: 0 40px 0 auto;
			}
		}

		@include mq(desktop-xl) {
			.l-outer--nav & {
				max-width: $layout-header-center;
				margin: 0 auto;
			}
		}

		@include mq(print) {
			min-height: 75px;
		}

	}
}