/* a-basc-link--table */
.a-basic-link--table {
	padding: 10px 22px 7px 23px;
	background-color: $color-primary-1;
	background-image: none;
	display: table;
	text-decoration: none;
	border-radius: 4px;
	@include milo-bold($font-size-14);

	&:hover {
		text-decoration: none;
	}

	&:before {
		content: none;
	}

	// Exception
	.m-basic-table.h-space-s + & {
		position: relative;
		top: -15px;
	}
}