/* m-basic-newsbox--disabled */
.m-basic-newsbox--disabled {

	&:before {
		content: ' ';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $color-primary-2;
		opacity: 0.8;
	}

}