/* a-basic-icon--redminus */
.a-basic-icon--redminus {

	display: inline-block;
	padding-left: 32px;
	max-width: 700px;

	// Exception for usage in tables
	@at-root {
		.m-basic-table {

			> thead,
			> tbody {
				> tr {
					> td {

						> .a-basic-icon--redminus {

							position: relative;

							&:before {
								left: 0px;
							}

							&.h-space-s {
								margin-bottom: 0;
							}
						}
					}
				}
			}

		}
	}

	// reset to default-value
	&.h-space-s {
		margin-bottom: 15px;
	}

	&:before {
		position: absolute;
		display: block;
		left: 20px;
		width: 21px;
		height: 21px;
		content: '\2013';
		font-size: 15px;
		line-height: 20px;
		padding-top: 2px;
		color: #ffffff;
		text-align: center;

		background-color: $color-action-alert;

		@include mq(print) {
			box-shadow: inset 0 0 0 1000px $color-action-alert;
		}

		@include mq(tablet) {
			left: 2px;
		}
	}
}