/* m-basic-text--center */
.m-basic-text--center {
	text-align: center;

	> .mm-content {
		max-width: 100%;

		> p {
			max-width: 100%;
		}
	}

	h3 {
		max-width: none;
	}
}