/* l-form-general */
.l-form-general {

	&:not(.l-form-general--cardamage-map):not(.l-form-general--background):not(.l-form-general--no-width):not(.l-form-general--dark):not(.l-form-general--my-adac):not(.l-form-general--full) {
		max-width: 870px;

		.m-basic-calculator-content-box-newform & {
			max-width: 285px;

			@include mq(tablet) {
				max-width: initial;
			}
		}

		.m-basic-calculator-tiles--classic & {
			max-width: none;
		}

		.o-mgl-selector & {
			max-width: 100%;

			@include mq(desktop) {
				max-width: 557px;
			}
		}
	}

	//exception radius-search mobile wrapped
	.m-basic-radius-search--mobile-wrap & {
		width: calc(100% - 5px);
	}

	&--centered {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.mm-row.mm-row--center & {
		@include mq(tablet) {
			min-width: 575px;
		}
	}

	&--right-space {
		padding-right: 20px;
	}

	&--background {
		background: $color-secondary-8;
		border-radius: 10px;
		padding: 20px 15px 20px 15px;

		@include mq(tablet) {
			padding: 20px 40px 30px 30px;
		}

		> form {
			padding: 20px 25px 0 25px;
			margin-bottom: -20px;

			@include mq(tablet) {
				padding: 20px 0 0 10px;
			}
		}
	}

	&--dark {
		background: $color-secondary-10;
		padding: 10px;

		@include mq(tablet) {
			padding: 20px;
		}
	}

	&--my-adac {
		max-width: 370px;
		margin: 0 auto;

		.m-my-adac-formular-template & {
			max-width: 400px;
			margin-right: auto;
			margin-left: auto;
		}
	}

	.ll-fieldset {

		&:not(.h-space-s):not(.h-space-m):not(.h-space-l):not(.h-space-none):not(.h-space-resp-tablet):not(.h-space-resp-tablet) {
			margin-bottom: 20px;

			// exception .m-basic-radius-search
			.m-basic-radius-search & {
				margin-bottom: 0;
			}
		}

		&.h-space-none {
			margin-bottom: 0;
		}

		&:first-of-type {
			.m-basic-calculator-content-box-newform .mm-row.mm-row--center .m-basic-radius-search & {
				flex: 1 1 40%;
			}

		}

		&:last-of-type {
			.m-basic-calculator-content-box-newform .mm-row.mm-row--center .m-basic-radius-search & {
				flex: 0 0 125px;

				@include mq(tablet) {
					flex: 0 1 30%;
				}
			}
		}

		&.resp-tablet {
			.l-form-general--my-adac & {
				margin-bottom: 30px;

				@include mq(tablet) {
					margin-bottom: 60px;
				}
			}
		}

		@include if-parent('.l-form-general--dark') {
			margin: 0;
		}

		.l-form-general--my-adac & {
			margin-right: 30px;

			@include mq(tablet) {
				margin-right: 0;
			}
		}

		> .ll-row {
			.m-basic-calculator-content-box-newform & {
				display: flex;
				justify-content: center;
				max-width: calc(100% - 30px);

				@include mq(tablet) {
					min-width: 100%;
				}

				&--flex-row {
					flex-direction: column;
					align-items: center;
				}
			}

			.m-basic-calculator-content-box-newform .l-form-general--small-content & {
				max-width: 400px;
				margin: 0 auto 15px auto;
				justify-content: flex-start;

				@include mq(tablet) {
					padding: 0 0 0 60px;
					margin: initial;
					max-width: 620px;
				}
			}

			> .ll-col-label {
				> .ll-label {
					@include if-parent('.ll-fieldset.js-fieldset-edit .ll-row.ll-row--data-only') {
						color: initial;
					}
				}

				& + .ll-col-data {
					.l-form-general--my-adac & {
						width: 100%;
					}
				}
			}

			.ll-col-copy {
				margin-bottom: 15px;
			}

			> .ll-col-data {

				// bug related with swinging textarea, caused by display: flex in .ll-row
				// site: /pages-adac-examples-cardamage-contact-finish - checkbox dependecies
				> .mm-radio-related-dependencies {
					width: 100%;
					max-width: 400px;
				}

				> .js-plain-content.is-data-only {
					@include if-parent('.ll-fieldset.js-fieldset-edit .ll-row.ll-row--data-only') {
						position: absolute;
						top: 6px;
						margin-top: 0;
						margin-left: 0;
					}
				}
			}

			> .ll-item-container {
				> .ll-col-label {

					// exception when in m-basic-calculator-content-box
					.m-basic-calculator-content-box-newform & {
						@include mq(tablet) {
							max-width: fit-content;
						}
					}
				}

				> .ll-col-data {

					// exception when in m-basic-calculator-content-box
					.m-basic-calculator-content-box-newform & {
						@include mq(tablet) {
							max-width: fit-content;
							min-width: 400px;
							margin-right: auto;
							margin-left: auto;
							padding-right: 0;
						}
					}
				}
			}
		}

		> legend {
			display: block;
			width: 100%;
			position: relative;
			margin: 0;
			padding: 0 0 10px 0;
			@include milo-slab-bold($font-size-18, 24px);
			font-weight: normal;

			@include mq(tablet) {
				padding: 0 0 18px 0;
				@include font-size($font-size-28, 34px);
			}

			@include mq(print) {
				@include milo-slab-bold($font-size-18, 24px);
			}

			@include if-parent('.l-form-general--dark') {
				margin: 0;
				padding: 0 10px 6px 0;
			}

			> h2 {
				padding: 0;
				@include milo-slab-bold($font-size-18, 24px);

				@include mq(tablet) {
					@include font-size($font-size-28, 34px);
				}

				@include mq(print) {
					@include milo-slab-bold($font-size-18, 24px);
				}
			}

			.ll-edit {
				position: relative;
				top: 3px;
				left: 7px;
				cursor: pointer;
			}

			.ll-delete {
				cursor: pointer;
				font-weight: normal;
				@include milo-regular($font-size-15, 24px);
				padding: 0 0 0 25px;
				display: inline-block;
				margin-left: 8px;
				position: absolute;
				margin-top: 7px;

				&:after {
					content: '';
					position: absolute;
					@include main-icon-arrow-right-black;
					left: 0;
					top: -0.5px;
					width: 30px;
					height: 30px;
					background-repeat: no-repeat;
				}
			}

			> .ll-optional {
				font-weight: normal;
			}
		}

		.ll-delete-clone-icon {
			position: relative;
			width: 25px;
			height: 25px;
			display: inline-block;
			cursor: pointer;

			&:after {
				content: '';
				position: absolute;
				@include main-icon-cancel;
				left: -10px;
				top: -2px;
				width: 35px;
				height: 35px;
			}

			&--delete {
				&:after {
					top: -4px;
					@include main-icon-delete;
				}
			}
		}


		.ll-edit-clone-icon {
			position: relative;
			width: 25px;
			height: 25px;
			display: inline-block;
			cursor: pointer;

			&:after {
				content: '';
				position: absolute;
				@include main-icon-edit;
				left: 1px;
				top: -3px;
				width: 32px;
				height: 25px;
			}
		}
	}

	.ll-multirow {

		max-width: 400px;
		display: flex;
		column-gap: $form-gutter-restyle;
		row-gap: 15px;
		padding-right: 45px;

		@include mq(tablet) {

			padding-right: 0;
		}

		// exception when in vesrs-contentbox
		.m-ves-rs-contentbox & {
			margin-left: auto;
			margin-right: auto;
		}

		&--search {
			align-items: flex-start;
			flex-direction: column;

			@include mq(tablet) {
				align-items: center;
				flex-direction: row;
			}

			> .ll-row {
				width: 305px;

				@include mq(tablet) {
					width: initial;
				}
			}
		}


		&--first {
			> * {
				&:first-child {
					flex: 1;
					margin-right: 15px;
				}
			}
		}

		&--full {
			max-width: none;
			display: block;

			@include mq(desktop) {
				display: flex;
				justify-content: space-between;
			}

			> * {
				&:last-child {
					margin-left: 0;
				}

				&:first-child {
					margin-right: 0;
				}
			}
		}

		&:not(:last-child) {
			.ll-row {

				&:not(.h-space-none):not(.h-space-s):not(.h-space-m):not(.h-space-l):not(.ll-row--data-only):not(.seperator-no):not(.ll-row--radio-margin-s) {
					margin-bottom: 28px;
				}
			}
		}

		.ll-row {
			max-width: calc(100% - 45px);
			padding-right: 0;

			@include mq(tablet) {
				max-width: 400px;
			}


			// exception when in vesrs-contentbox
			.m-ves-rs-contentbox &.is-floating {
				display: flex;
				justify-content: flex-start;

				@include if-parent('.ll-row:first-child()') {
					justify-content: flex-end;
				}
			}

			// exception when in vesrs-contentbox & label not floating
			.m-ves-rs-contentbox &:not(.is-floating) {
				display: flex;
				min-width: 50%;

				@include if-parent('.ll-row:first-child()') {
					min-width: calc(50% - 100px);
					margin-right: 15px;
				}
			}

			&.is-size-xs {
				width: 25%;
			}

			&.is-size-s {
				width: calc(100% / 3);
			}

			&.is-size-m {
				width: 50%;
			}

			&.is-size-l {
				width: calc(100% / 1.5);
			}

			&.is-size-xl {
				width: 75%;
			}

			&--radio-margin-s {
				margin-bottom: 12px;
			}

			.ll-col-label:not(.ll-col-label--above) {
				position: absolute;
				min-width: 400px;

				@include if-parent('.ll-multirow.ll-multirow--search .ll-row.ll-row--icon') {
					margin-left: 30px;
				}

				@include if-parent('.ll-row:first-child') {
					+ .ll-col-data {
						padding-top: 22px;

						.ll-check {
							top: 20px;
						}
					}
				}

				.m-ves-rs-contentbox & {
					@include if-parent('.ll-row:nth-child(2)') {
						left: 15px;
					}
				}
			}

			.ll-col-data {
				width: initial;

				.m-ves-rs-contentbox & {
					@include if-parent('.ll-row:nth-child(2)') {
						width: calc(100% - 15px);

						@include mq(tablet) {
							width: 100%;
						}
					}
				}

				.ll-check {
					@include if-parent('.ll-multirow .ll-row:not(.is-floating):nth-child(2):not(.is-floating)') {
						margin-top: 31px;
					}
				}
			}
		}

		& + .ll-error-msg {
			opacity: 0;
			padding: 0;
			max-height: 0;
			overflow: hidden;
			color: $color-action-alert;
			transition: all $form-animation ease;
			transform: translateY(-28px);
			@include milo-regular($font-size-12, 12px);

			> a {
				color: $color-action-alert;
			}
		}

		&.has-error + .ll-error-msg {
			opacity: 1;
			max-height: 300px;
			padding: 5px 0;
		}

	}

	.ll-row {

		max-width: 400px;
		position: relative;
		padding: 0 45px 0 0;

		@include mq(tablet) {
			padding: initial;
		}

		.m-basic-radius-search--locksmith & {
			padding: 0;
			margin-right: 0;
		}

		&:not(.h-space-none):not(.h-space-s):not(.h-space-m):not(.h-space-l):not(.ll-row--data-only):not(.seperator-no):not(.ll-row--radio-margin-s):not(:last-child) {
			margin-bottom: 28px;

			.m-basic-radius-search & {
				margin-bottom: 0;
			}
		}

		@include if-parent('.l-form-general--calculation') {
			max-width: none;
		}

		@include if-parent('.l-form-general.js-fine') {
			max-width: none;
		}


		&:first-child {
			@include mq(tablet) {
				padding: initial;
			}
		}

		// if ll-row is cloned
		&--clone {

			//single clonelement not to be deleted
			&.is-fixed-only {
				> * {
					&:only-child {
						.ll-delete {
							display: none;
						}
					}
				}
			}

			legend {
				.ll-delete {
					margin-top: 2px;
					padding: 0 0 0 20px;
					margin-left: 15px;

					@include mq(tablet) {
						margin-top: 7px;
						margin-left: 8px;
					}
				}

				h2 {
					margin-bottom: 0;
				}
			}
		}

		&--full {
			max-width: 730px; // 30px extra for validation icons

			// exception when in l-form-general--mgl-standard
			@include if-parent('.l-form-general--mgl-standard') {
				@include mq(tablet) {
					max-width: initial;
				}
			}
		}

		&--required {
			margin-bottom: 5px;
			text-align: right;
			@include milo-regular($font-size-14);
		}

		&--ds {
			margin-bottom: 0 !important;
			max-width: none;
		}

		// ll-row with --flex prop
		&--flex {
			display: flex;
			align-items: center;

			&-end {
				justify-content: flex-end;
			}

			&-center {
				justify-content: center;
			}

			:first-child {
				margin-right: 15px;
			}
		}

		// ll-row with --button(-right) prop
		&--button {
			width: 100%;
			max-width: none;
			padding: 0;
			display: flex;
			align-items: center;
			margin: 30px 0 15px 0;

			&-right {
				justify-content: flex-end;
			}
		}

		&--data-only {
			&:not(.h-space-s):not(.h-space-m):not(.h-space-l):not(.h-space-resp-tablet):not(.h-space-resp-tablet):not(.h-space-none) {
				margin-bottom: 15px;
			}

			+ .ll-row:not(.ll-row--data-only) {
				margin-top: 20px;
			}
		}

		// ll-row with --button(-right) prop
		&--location {
			max-width: 100%;
			padding: 0;

			@include mq(tablet) {
				display: flex;
				align-items: center;
				max-width: 100% !important;
				flex-flow: row nowrap;
			}

			&.ll-row--location-desc {
				@include mq(tablet) {
					align-items: flex-start;
				}

				> * {
					flex: 0;
				}
			}

			.ll-discount-wrapper {
				display: flex;
				flex-flow: column nowrap;
				margin-bottom: 10px;

				@include mq(tablet) {
					flex-flow: row nowrap;
					margin-bottom: 0;
					margin-right: 20px;
				}

				> label {
					padding: 4px 10px 0 0;

					@include mq(tablet) {
						padding-left: 10px;
					}
				}
			}
		}

		&--cardamage {
			display: flex;
			flex-flow: column nowrap;
			width: 100%;
			max-width: 640px;

			@include mq(tablet) {
				padding-right: 0;
			}

			&.ll-row--cardamage-small {
				max-width: 400px;
			}

			// error validation
			&.is-check {
				padding-right: 30px;
				max-width: none;
			}

			&.h-space-s {
				margin-bottom: 15px;
			}

			&.h-space-m {
				margin-bottom: 30px;
			}

			&.h-space-l {
				margin-bottom: 60px;
			}

			&-full {
				max-width: none;
			}

			> .ll-error-msg {
				width: 100%;
			}

			> .ll-col-label {

				width: 100%;
				padding: 5px 0;

				& ~ .ll-col-data {
					max-width: 640px;
					width: 100%;

					@include mq(tablet) {
						margin-right: 15px;
						width: auto;
						max-width: none;
					}

					&.is-last {
						margin-right: 0;
					}
				}
			}
		}

		&--hidden {
			display: none;
		}

		&.is-error {
			margin-bottom: 10px;

			@include mq(desktop) {
				margin-bottom: 18px;
			}

			.m-basic-calculator-tiles--classic & {
				margin-bottom: 0;
			}

			.ll-error-msg {
				opacity: 1;
				max-height: 300px;
				padding: 5px 0;

				> a {
					color: $color-action-alert;
				}
			}
		}

		&.seperator-s {
			margin-bottom: 10px;
		}

		&.seperator-m {
			margin-bottom: 10px;

			@include mq(desktop) {
				margin-bottom: 25px;
			}
		}

		&.seperator-l {
			margin-bottom: 10px;

			@include mq(desktop) {
				margin-bottom: 35px;
			}
		}

		&.seperator-xl {
			margin-bottom: 10px;

			@include mq(desktop) {
				margin-bottom: 50px;
			}
		}

		&.seperator-no {
			margin-bottom: 0;
		}

		&--dependent {
			margin-bottom: 10px;
		}

		&.ll-row.is-error.is-sub-error-shown .ll-error-msg {
			display: block;
		}

		// exception when in vesrs-contentbox
		.m-ves-rs-contentbox &:not(.is-floating) {
			max-width: 400px;
			display: flex;
			justify-content: center;
			margin-left: auto;
			margin-right: auto;
			flex-direction: column;
		}

		.m-ves-rs-contentbox &.is-floating {
			max-width: 400px;

			@include mq(tablet) {
				display: flex;
				justify-content: center;
				width: initial;
				max-width: initial;
			}
		}

		// for the cloned cases inside m-basic-calculator-tiles--classic
		.m-basic-calculator-tiles--classic.m-basic-calculator-tiles--clone-element & {
			padding-right: 0;

			max-width: initial;
		}

		.ll-error-msg {
			opacity: 0;
			padding: 0;
			max-height: 0;
			overflow: hidden;
			color: $color-action-alert;
			transition: all $form-animation ease;
		}

		h3 {
			&.is-form-aligned {
				text-align: left;
				padding-bottom: 12px;
			}

		}

		> .ll-col-label {

			@include if-parent('.ll-row.ll-row--icon') {
				margin-left: 30px;
			}

			@include if-parent('.ll-row.ll-row--location') {
				left: 45px;
				top: 18px;
			}

			@include if-parent('.ll-row.ll-row--data-only') {
				position: initial;
				top: 0;
				left: 0;
				float: left;
				width: 45%;
				padding: 8px 0 0 0;
				margin-right: 5%;
			}

			@include if-parent('.ll-row.ll-row--cardamage') {
				width: 100%;
				padding: 5px 0;
			}

			&--above {
				.m-basic-calculator-tiles--classic & {
					margin-bottom: 10px;
				}
			}

			// exception when in vesrs-contentbox
			.m-ves-rs-contentbox & {
				left: 250px;
			}

			// exception when in vesrs-contentbox
			.m-ves-rs-contentbox .ll-multirow & {
				left: 0;
			}

			+ .ll-col-data {
				input {
					padding-top: 0;
				}
			}

			.ll-label {

				@include if-parent('.ll-row.ll-row--data-only .ll-col-label') {
					font-weight: bold;
				}

				@include if-parent('.ll-row.is-error') {
					color: $color-action-alert;
				}

				@include if-parent('.ll-row .ll-col-label .ll-label.ll-label--infolayer') {
					position: relative;
					padding: 0 5px;
					left: -5px;
				}

				@include if-parent('.ll-row.is-error') {
					color: $color-action-alert;
				}

				@include if-parent('.ll-col-label') {
					font-weight: bold;
					top: 0 !important;
				}

				&:hover {
					cursor: text;
				}

				> span:not(.ll-bold) {
					@include if-parent('.ll-row.ll-row--data-only .ll-col-label') {
						@include milo-regular($font-size-16);
						font-weight: normal;
					}
				}
			}
		}

		// ll-row can be nested in ll-row therefor if-parent would lead to false positive/negative
		&.is-error.is-check {
			> .ll-col-data {
				> .ll-check {
					opacity: 1;
					visibility: visible;
					@include main-icon-error-symbol-red;
				}
			}
		}

		&.is-valid.is-check {
			> .ll-col-data {
				> .ll-check {
					opacity: 1;
					visibility: visible;
					@include main-icon-check-green;
				}
			}
		}

		&.is-error.is-check--icon,
		&.is-valid.is-check--icon {
			> .ll-col-data {
				> .ll-check {
					right: 44px;
					position: relative;
				}
			}
		}

		&.is-check--icon {
			padding: 0;

			> .ll-col-data {
				> *:not(.a-basic-input-text--date) {
					max-width: calc(100% - 45px);

					@include mq(tablet) {
						max-width: 400px;
					}
				}
			}
		}

		> .ll-col-data {
			display: flex;
			flex-wrap: wrap;
			position: relative;

			@include mq(tablet) {
				max-width: initial;
			}

			.ll-row:not(.ll-row--full) & {
				max-width: 305px;

				@include mq(tablet) {
					max-width: 400px;
				}
			}

			@include if-parent('.ll-row.ll-row--data-only') {
				width: 50%;
				float: left;
				display: block;
				position: initial;
			}

			@include if-parent('.ll-row.ll-row--cardamage') {
				@include mq(tablet) {
					margin-right: 15px;
				}
			}

			@include if-parent('.ll-row.ll-row--cardamage-small') {
				margin-right: 0;
			}

			@include if-parent('.ll-row.ll-row--data-only') {
				@include mq(tablet) {
					width: 50%;
				}
			}

			@include if-parent('.ll-row.ll-row--data-only') {
				@include mq(tablet) {
					width: 50%;
				}
			}

			@include if-parent('.ll-row.ll-row--center') {
				justify-content: center;
			}

			@include if-parent('.ll-row.ll-row--padding') {
				padding-right: 40px;
			}

			&--baseline {
				align-items: baseline;
			}

			&--center {
				align-items: center;
			}

			&--no-flex {
				display: initial;
			}

			> .ll-error-msg {
				display: none;
				color: $color-action-alert;
				order: 2;
				width: 100%;
				padding: 5px 15px 5px 0;
				@include milo-regular($font-size-12, 12px);

				@include if-parent('.ll-row.is-error') {
					display: block;
				}

				@include if-parent('.ll-row.ll-row--icon') {
					padding: 5px 15px 5px 30px;
				}
			}

			> .ll-date-condition {
				width: 100%;
				order: 3;
				margin-top: 5px;

				@include if-parent('.ll-row.is-error') {
					margin-top: 15px;
				}
			}

			> .m-basic-calculator-tiles--classic {
				+ .ll-check {
					display: none;
				}
			}

			> .a-basic-input-radio {
				+ .ll-check {
					top: -15px;
					transform: translateX(100%);
				}
			}

			> .a-basic-input-checkbox {
				+ .ll-check {
					top: -15px;
				}
			}

			> .a-basic-input-radio--haptik {
				+ .ll-check {
					top: -8px;
				}
			}

			.ll-check {
				position: absolute;
				right: 2px;
				top: -2px;
				z-index: 1;
				opacity: 0;
				width: 22px;
				height: 22px;
				margin: 9px 0 0 0;
				visibility: hidden;

				@include if-parent('.ll-row.ll-row--ds') {
					margin: 0;
				}
			}


			// slide in description:
			.ll-description {
				clear: both;
				line-height: normal;

				// required for transition
				&:before {
					content: "";
					display: block;
					margin-top: 5px;
				}
			}

			> .js-plain-content.is-data-only {
				margin-top: 40px;
				margin-left: 15px;

				@include mq(tablet) {
					margin-top: 5px;
					margin-left: 240px;
				}
			}

			div + .ll-description {

				&:not(.ll-description--show-initial) {
					opacity: 0;
					max-height: 0;
					overflow: hidden;
					transition: all $form-animation-label ease;
					max-width: 400px;

					@include if-parent('.ll-row.is-focused') {
						opacity: 1;
						max-height: 260px;
					}
				}
			}

			div.js-focus + .ll-description {
				&:not(.ll-description--show-initial) {
					opacity: 1;
					max-height: 260px;
					padding-top: 5px;
				}
			}

			> .ll-iframe-basic-input-text {
				iframe {
					width: 400px;
					height: 55px;
				}
			}

			> p {
				@include if-parent('.ll-row--data-only') {
					padding-top: 8px;
				}
			}

			> strong {
				margin-right: 20px;
			}

			> .ll-data-text {
				@include if-parent('.ll-row--data-only') {
					position: absolute;
					padding-top: 8px;
				}
			}
		}

		.ll-depend {
			width: 100%;

			&--margin-top {
				margin-top: 30px;
			}

			> .ll-col-data {
				width: 305px;

				@include mq(tablet) {
					width: 400px;
				}
			}
		}

		.ll-row {
			padding-right: 0;
		}

		.ll-button-trigger {
			&--plus {
				position: relative;
				padding-left: 40px;
				cursor: pointer;

				&:before {
					display: block;
					width: 22px;
					height: 22px;
					content: "";
					@include main-icon-clone;
					position: absolute;
					top: -13px;
					left: -13px;
				}
			}
		}

		.ll-clone-content {
			&.is-hidden-headline {
				.ll-clone-headline {
					display: none;
				}
			}
		}

		.ll-clone-wrapper {
			&:not(:first-child) {
				.ll-info-only-once {
					display: none;
				}
			}
		}
	}

	.ll-aside {
		background: $color-primary-2;
		display: none;
		z-index: 55;
		border: 1px solid $color-secondary-5;
		margin-top: 0;
		transition: transform 1.5s;

		@include mq(tablet) {
			display: block;
			width: 305px;
		}

		@include mq(desktop) {
			display: block;
			width: 305px;
			margin-right: -40px;
		}

		&-inline-on-init {
			display: block;
			position: relative;
			width: 100%;
			min-height: 100px;
			margin: 0 0 20px 0;

			@include mq(desktop) {
				position: static;
				display: block;
				width: 305px;
			}
		}

		&.is-open {
			left: auto;
			right: 0;
			top: 33.5%;
			position: fixed;
			width: 305px;
			z-index: 1005;

			@include mq(desktop) {
				right: auto;
				top: auto;
			}
		}

		&.is-animating-right {
			transform: translateX(calc(100vw));
		}

		.ll-aside-head {
			padding: 1px 37px 2px 10px;
			position: relative;
			background: $color-primary-1;
			@include milo-slab-bold($font-size-24);
			border-bottom: 1px solid $color-secondary-6;

			.h-roboto & {
				font-family: 'Roboto Bold', sans-serif;
			}

			.ll-aside-edit {
				position: absolute;
				top: 5px;
				right: 7px;
				cursor: pointer;
			}

			&--small {
				min-height: 37px;
				@include font-size(16px, 20px);
				font-family: "Calibri", "Arial", sans-serif;
				padding-top: 8px;
				padding-bottom: 8px;
			}
		}

		.ll-aside-content {
			padding: 8px 10px 2px;
			background: $color-primary-2;
			position: relative;

			h2 {
				font-family: "Calibri", "Arial", sans-serif;
				padding-bottom: 7px;
				@include milo-bold($font-size-24);
				@include line-height($font-size-24);
			}

			// exception
			.m-basic-hint + .ll-price-wrapper .ll-contribution {
				padding-top: 0;
				border-top: 0;
			}

			.ll-price-wrapper {
				@include if-parent('.l-form-general .ll-aside.ll-aside-inline-on-init:not(.is-open)') {
					@include mq(tablet) {
						position: absolute;
						top: 4px;
						right: 10px;
						text-align: right;
					}

					@include mq(desktop) {
						position: static;
						text-align: left;
					}
				}

				> .ll-contribution {

					@include mq(tablet) {
						@include font-size(23px);
						border: none;
						padding: 0;
					}

					@include mq(desktop) {
						padding: 10px 0 7px 0;
						border-top: 1px solid $color-secondary-5;
					}

					.ll-rate {
						@include mq(tablet) {
							@include font-size(23px);
						}

						@include mq(desktop) {
							@include font-size($font-size-28);
						}
					}
				}
			}

			> .l-main-cols {
				> .ll-main-cols-s {
					text-align: right;
				}
			}

			.ll-contribution {
				padding: 10px 0 7px 0;
				@include font-size($font-size-22);
				font-weight: bold;
				border-top: 1px solid $color-secondary-5;

				@include mq(tablet) {
					@include font-size($font-size-28);
				}

				// styling for Unfallschutz aside
				> .l-main-cols.l-main-cols--us {
					align-items: center;

					> .ll-main-cols-m {

						> .js-rate-method,
						&.js-rate-value {
							@include font-size(24px);
							line-height: 30px;

							&-initial {
								@include font-size(24px);
								line-height: 30px;
							}
						}

						&:last-of-type {
							text-align: right;
						}
					}
				}

				> #membership_price_standard,
				> #membership_price_plus {
					@include font-size(16px);
					font-weight: normal;
				}
			}

			.ll-action-footer {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.ll-rate {
				@include font-size($font-size-22);
				font-weight: bold;

				@include mq(tablet) {
					@include font-size($font-size-28);
				}
			}

			// exception for AKS Incoming
			> .ll-row {
				padding: 0;

				> .ll-row--flex {
					&:not(.h-none) {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						padding-right: 10px;
					}

					&:first-child {
						margin-bottom: 0;
					}

					> p {
						margin-bottom: 0;
					}

					.js-kv-price {
						font-weight: bold;
					}

					.js-hv-price {
						font-weight: bold;
					}
				}
			}
		}

		.ll-aside-bar--mobile {
			visibility: hidden;
			height: 0;

			&.is-stuck {
				@include mq(mobile-max) {
					visibility: visible;
					height: auto;
					z-index: 99;
				}
			}
		}
	}


	.ll-button-wrapper {
		display: flex;
		flex-flow: row nowrap;
		margin-bottom: 7px;

		&--form-bottom {
			display: block;

			@include mq(tablet) {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				align-items: flex-start;
			}
		}

		&--form-width {
			max-width: 400px;
		}

		&--form-break-mobile {
			flex-direction: column;
			row-gap: 20px;

			@include mq(tablet) {
				flex-direction: row;
			}
		}

		&--form-left {
			column-gap: 20px;
		}

		&--form-bottom-reverse {
			@include mq(tablet) {
				flex-flow: row-reverse nowrap;
			}
		}

		&.seperator-m {
			margin-bottom: 20px;

			@include mq(tablet) {
				margin-bottom: 30px;
			}

			@include mq(desktop) {
				margin-bottom: 45px;
			}
		}

		&.seperator-l {
			margin-bottom: 30px;

			@include mq(tablet) {
				margin-bottom: 50px;
			}

			@include mq(desktop) {
				margin-bottom: 95px;
			}
		}

		&.seperator-top-l {
			margin-top: 20px;

			@include mq(tablet) {
				margin-top: 40px;
			}

			@include mq(desktop) {
				margin-top: 80px;
			}
		}

		> .ll-button-group {
			display: block;

			@include mq(tablet) {
				display: flex;
				align-items: baseline;
				column-gap: 20px;
				row-gap: 20px;
			}
		}
	}
}

//exception  m-basic-calculator-tiles--classic
.m-basic-calculator-tiles--classic .l-form-general.mm-content-row--centered-natural {

	.ll-row {
		left: 50%;
		transform: translateX(-50%);
	}

	.ll-col-data {
		display: inline-block;
		left: 50%;
		transform: translateX(-50%);
		min-width: 290px;

		> * {
			max-width: none;
		}

	}

	.is-check--icon {
		> .ll-col-data {
			> .ll-check {
				left: 100%;
				position: relative;
			}
		}
	}
}

.m-basic-calculator-tiles--classic .l-form-general.mm-content-row--centered-full .ll-col-label--above {
	text-align: center;
}