/* ==========================================================================
   helper: DO NOT CHANGE!  updated: 2015-08-27 (PeDo)
   ========================================================================== */

/*
 * Hide visually and from screen readers:
 */

.h-hidden {
	display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

.h-visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.h-visuallyhidden-reset {
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.h-visuallyhidden.h-focusable:active,
.h-visuallyhidden.h-focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */

.h-invisible {
	visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.cf:before,
.cf:after {
	content: " ";
	/* 1 */
	display: table;
	/* 2 */
	clear: both;
}

.cf:after {
	clear: both;
}

/* Schriftartklasse für Webview Pannenhilfe*/

.h-roboto {
	font-family: 'Roboto', sans-serif;
}


/* set light grey background global around element with full width */

.h-background {
	background: $color-secondary-8;

	&:before,
	&:after {
		background: $color-secondary-8;
	}
}

.h-background-full {
	background: $color-secondary-8;
	position: relative;

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 100vw;
		height: 100%;
		background: $color-secondary-8;
	}

	> * {
		z-index: 1;
	}
}

/* add style to anchor scroll targets */
:target:not(#footer) {

	$mainHeaderHeight-mob: 56px;
	$mainHeaderHeight-tablet: 64px;
	$mainHeaderHeight-desk: 120px;

	&:before {
		content: "";
		display: block;
		height: $mainHeaderHeight-mob;
		/* fixed header height*/
		margin-top: -1 * $mainHeaderHeight-mob;
		z-index: -10;

		@include mq(tablet) {
			height: $mainHeaderHeight-tablet;
			/* fixed header height*/
			margin-top: -1 * $mainHeaderHeight-tablet;
		}
	}
}