/* m-basic-list--my-adac-advantage */
.m-basic-list--my-adac-advantage {

	li {
		position: relative;
		padding: 0 0 2px 28px;

		&:before {
			content: '';
			display: inline-block;
			@include main-icon-check-green;
			width: 31px;
			height: 30px;
			position: absolute;
			left: 0;
			top: -11px;
		}

		&:hover {
			text-decoration: none;
		}

		.l-outer--my-adac-content & {
			padding: 0 0 2px 28px;

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-check-green;
				width: 31px;
				height: 30px;
				position: absolute;
				left: 0;
				top: -11px;
			}
		}
	}
}