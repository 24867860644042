.m-basic-cookie {

	position: fixed;
	left: 0;
	bottom: -2px;
	width: 100vw;
	max-height: 0;
	margin-left: calc(50% - 50vw);
	margin-right: calc(50% - 50vw);
	overflow: hidden;
	@include milo-regular($font-size-14);
	background: $color-secondary-8;
	z-index: 300;
	transition: max-height 1s;
	border: 1px solid $color-primary-2;

	@include mq(tablet) {
		max-width: 500px;
	}

	&.is-sliding-down {
		.headroom--top & {
			max-height: 200px;
		}

		// exception .m-basic-header // mobile && tablet
		.m-basic-header:first-of-type:not(.is-dropshadow) ~ & {
			max-height: 200px;

			@include mq(desktop-l) {
				max-height: 0;
			}
		}

		// exception .m-basic-header // > desktop-l
		.m-basic-header:first-of-type:not(.is-stuck) ~ & {

			@include mq(desktop-l) {
				max-height: 200px;
			}
		}

		// exception .m-basic-header--reduced
		.m-basic-header--reduced:first-of-type:not(.is-dropshadow) ~ & {
			@include mq(desktop-l) {
				max-height: 200px;
			}
		}
	}

	// used to hide elem without concerning later scroll events overriding js inline-styles
	// e.g. .m-ves-rs-stickybar
	&.is-hidden {
		max-height: 0 !important;
	}

	> .m-cookie-center {
		padding: 15px 20px;
		max-width: $layout-main-center;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}

	@include mq('print') {
		display: none;
	}
}