/* .a-basic-btn--outline */
.a-basic-btn--outline {

	// compensate vert.offset due to outline usage
	$vert-offset: 2px;

	border: $vert-offset solid rgba(0, 0, 0, 0.42);
	background-color: transparent;

	&:hover {
		background-color: $color-secondary-25;
		border: $vert-offset solid $color-secondary-22 !important;

	}

	&:focus,
	&:active {
		background-color: $color-secondary-25;
		border: $vert-offset solid $color-secondary-22 !important;

		.is-keyboard-focus & {
			background-color: $color-secondary-25 !important;
		}
	}

	// exception .m-ves-rs-contentbox .mm-upload-container .mm-row.is-error
	.m-ves-rs-contentbox .mm-upload-container .mm-row &,
	.m-ves-rs-contentbox .mm-tile-container--col-1-5 .mm-row & {
		@include if-parent('.mm-row.is-check.is-error') {

			&:not(.is-disabled):not(.is-no-error) {
				border: 2px solid $color-action-alert;
			}
		}
	}

	// exception when in .m-ves-rs-contentbox .mm-upload-container .mm-row-content.is-error
	.m-ves-rs-contentbox .mm-upload-container .mm-row-content &,
	.m-ves-rs-contentbox .mm-tile-container--col-1-5 .mm-row-content & {

		// row has error class
		@include if-parent('.mm-row-content.is-check.is-error') {

			&:not(.is-disabled):not(.is-no-error) {
				border: 2px solid $color-action-alert;
			}
		}
	}
}