/* a-basic-btn--refresh */
.a-basic-btn.a-basic-btn--refresh {
	padding: 10px 10px 7px 35px;
	position: relative;

	&:before {
		@include main-icon-refresh-grey();
		content: '';
		position: absolute;
		height: 100%;
		left: -5px;
		top: 0;
	}
}