/* a-basic-btn--right */
.a-basic-btn--right {
	float: right !important;
	margin-left: 20px;

	// exception .o-layout-main-stage
	.o-layout-main-stage .swiper .swiper-wrapper .swiper-slide .oo-slide-content-wrapper > .oo-box > & {
		// even importanter ;)
		float: none !important;
		margin-left: auto;
	}

	.l-form-general & {
		margin-left: auto;
	}

	// exception .l-form--emailoffer, basic-hint-document
	.l-form--emailoffer > .ll-fieldset > .ll-row > &,
	.m-basic-hint--document & {

		float: none !important;
		margin-left: 0;

		@include mq(tablet) {
			float: right !important;
			margin-left: 20px;
		}
	}

	// exception .ll-main-cols-flex
	.ll-main-cols-flex > & {
		margin-left: 0;
	}
}