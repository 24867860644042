.o-layout-main-stage--teaser {
	position: relative;

	@include mq(tablet) {
		height: 450px;
	}

	> .swiper {
		> .swiper-wrapper {
			> .swiper-slide {

				> .oo-figure {
					overflow: visible;
					max-width: 100%;

					@include mq(tablet) {
						height: 450px;
						max-height: 450px;
					}

					.oo-img {
						@include mq(tablet) {
							width: 167%;
							left: -67%;
						}

						@include mq(desktop) {
							width: auto;
							min-height: 450px;
							left: auto;
						}

						@include mq(desktop-l) {
							width: auto;
							min-width: 100%;
							min-height: 450px;
							left: auto;
						}
					}
				}

				.oo-box {
					bottom: auto;
					padding: 0 10px;
					margin: 0;
					height: auto;

					@include mq(tablet) {
						top: 75px;
						padding: 15px 40px 10px;
						margin: 0;
						left: 50%;
						transform: translate(-50%, 0);
						width: 688px;
					}

					@include mq(desktop) {
						width: 845px;
					}

					&--teaser-center {
						padding-top: 12px;

						@include mq(tablet) {
							top: auto;
							bottom: 45px;
							padding-top: 20px;
						}
					}

					.oo-box-head {
						max-width: 100%;
						line-height: 28px;
						@include milo-slab-bold();

						@include mq(tablet) {
							@include font-size($font-size-40, 40px);
						}

						@include mq(desktop) {
							line-height: 50px;
						}
					}

					.oo-box-subhead {
						&.h-h3 {
							display: inline-block;

							@include mq(tablet) {
								margin-bottom: 12px;
							}
						}
					}

					header + .oo-box-subhead.h-h3 {

						padding-top: 10px;

						@include mq(tablet) {
							padding-top: 0;
						}
					}

					.oo-box-text {
						height: auto;
						max-width: 100%;
					}
				}

			}
		}
	}
}