/* m-basic-progress-nav */
.m-basic-progress-nav-form--bar {
	> .mm-nav-wrapper {
		padding: 20px 20px 5px 10px;

		@include mq(desktop) {
			padding: 20px 20px 30px 20px;
		}

		> li {
			position: static;
			margin-right: 0;
			align-items: flex-start;

			@include mq(desktop) {
				position: relative;
				margin-right: 15px;
				align-items: center;
			}

			&:last-child {
				min-width: 0;
			}

			&.mm-active,
			&.mm-active ~ li {
				flex: 1;

				> .mm-line {
					background-color: $color-secondary-16;
					display: block;
				}

				&:last-child {
					> .mm-line {
						@include mq(desktop) {
							display: none;
						}
					}
				}
			}

			&.mm-mobile {
				top: -11px;
			}

			.mm-digit {
				display: none;

				@include mq(desktop) {
					display: block;
				}
			}

			.mm-text {
				position: static;

				@include mq(desktop) {
					position: relative;
				}

				> .mm-text-inner {
					left: 58px;
					margin-top: -5px;
					transform: translateX(0);

					@include mq(desktop) {
						left: 0;
					}

					> .mm-mobile-text {
						font-size: 13px;
					}
				}
			}

			a {
				position: static;
				width: 0;

				@include mq(desktop) {
					position: relative;
					width: auto;
				}
			}

			> .mm-line {
				height: 8px;
				background-color: $color-primary-1;
				margin-left: 0;

				@include mq(desktop) {
					height: 1px;
					margin-left: 10px;
					background-color: $color-secondary-16;
				}
			}
		}
	}
}