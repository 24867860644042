/* m-basic-dashboard-list*/
.m-my-adac-dashboard-list {
	padding: 0;

	.l-outer--my-adac & {
		margin: 0 0 24px 0;
	}

	> .mm-wrapper {
		width: 100%;

		> h2 {

			// exception .m-ves-rs-contentbox--damagedeclaration
			.m-ves-rs-contentbox--damagedeclaration > & {
				padding-bottom: 36px;

				@include mq(print) {
					padding-bottom: 15px;
				}
			}

		}

		> .mm-list-wrapper {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			margin: 0 auto;

			&.is-hidden {
				visibility: hidden;

				// exception .m-ves-rs-contentbox--damagedeclaration
				.m-ves-rs-contentbox--damagedeclaration > & {
					display: none;
				}
			}

			> .mm-left-list-wrapper {
				text-align: right;
				border-right: 1px solid $color-secondary-3;
				width: 40%;

				// exception .m-ves-rs-contentbox--damagedeclaration
				.m-ves-rs-contentbox--damagedeclaration > & {
					width: 50%;

					@include mq(tablet) {
						width: 50%;
					}

					@include mq(print) {
						border-color: $color-secondary-2;
					}
				}

				.l-outer--my-adac-content & {
					width: 40%;

					@include mq(tablet) {
						width: 50%;
					}
				}

				> .mm-list-point-headline {
					display: inline-block;
					margin: 0 10px 0 0;
					padding: 8px 0;
					color: $color-secondary-1;
					text-decoration: none;
					@include font-size(16px);

					@include if-parent('.m-my-adac-dashboard-list.m-my-adac-dashboard-list--is-light') {
						color: $color-secondary-4;
					}

					@include if-parent('.mm-list-wrapper.is-highlighted') {
						@include milo-bold(16px);
					}

					@include if-parent('.mm-left-list-wrapper.mm-has-info-icon') {
						margin: 0 20px 0 0;
						position: relative;
					}

					// exception .m-ves-rs-contentbox--damagedeclaration
					.m-ves-rs-contentbox--damagedeclaration > & {
						display: block;

						@include mq(print) {
							color: $color-secondary-2;
						}

						&.is-hidden {
							display: none;
						}

						&:not(:only-child) {
							margin-bottom: 0;
							padding-bottom: 0;

							&:not(:first-child) {
								padding-top: 5px;
							}
						}

					}

					> .mm-info-icon {
						display: inline-block;
						position: absolute;
						width: 20px;
						height: 100%;
						top: 0;
						right: -22px;

						&.is-clickable {
							cursor: pointer;
						}

						&:after {
							display: block;
							content: '';
							position: absolute;
							top: 0;
							left: -12px;
							@include main-icon-info;
							transform: scale(0.6);

							.l-outer--my-adac-dashboard & {
								@include main-icon-info;
							}
						}
					}
				}
			}

			> .mm-right-list-wrapper {
				text-align: left;
				width: 60%;
				display: flex;
				flex-flow: column nowrap;

				&.mm-has-info-icon {
					cursor: pointer;
					position: relative;
				}

				// exception .m-ves-rs-contentbox--damagedeclaration
				.m-ves-rs-contentbox--damagedeclaration > & {
					width: 50%;

					@include mq(tablet) {
						width: 50%;
					}

					.is-uppercase {
						text-transform: uppercase;
					}
				}

				.l-outer--my-adac-content & {
					width: 60%;

					@include mq(tablet) {
						width: 50%;
					}
				}

				> .mm-list-point-text {
					display: inline-block;
					padding: 0 0 5px 0;
					margin: 0 0 0 10px;
					@include font-size(16px);
					text-decoration: none;
					color: $color-secondary-1;

					@include if-parent('.mm-right-list-wrapper.mm-has-info-icon') {
						margin: 0 25px 0 10px;
						position: relative;
						max-width: calc(100% - 45px);
					}

					& + .mm-list-point-text--link {
						margin: -8px 0 0 8px;
						padding: 2px 0 8px 0;
						line-height: 18px;

						&.mm-element-space-s {
							margin: -8px 0 10px 8px;
						}

						&.mm-element-space-m {
							margin: -8px 0 15px 8px;
						}

						&.mm-element-space-xl {
							margin: -8px 0 20px 8px;
						}

						> .a-basic-link {
							&:before {
								transform: scale(.8);
							}
						}
					}

					&:first-child:not(:only-child) {
						padding: 8px 0;

						@include mq(tablet) {
							padding: 8px 0 5px 0;
						}
					}

					&:last-child:not(:only-child) {
						padding: 2px 0 8px 0;
					}

					&:only-child {
						padding: 8px 0;
					}

					// if value from list/bullet point is empty, dont take space
					&.is-empty {
						margin: 0 0 0 50px;
					}

					&.mm-element-space-s {
						margin: 0 0 10px 10px;
					}

					&.mm-element-space-m {
						margin: 0 0 15px 10px;
					}

					&.mm-element-space-xl {
						margin: 0 0 20px 10px;
					}

					@include if-parent('.mm-list-wrapper.is-highlighted') {
						@include milo-bold(16px);
					}

					// exception .m-ves-rs-contentbox--damagedeclaration
					.m-ves-rs-contentbox--damagedeclaration > & {
						display: block;

						&:not(:only-child) {
							margin-bottom: 0;
							padding-bottom: 3px;
						}

						ul {
							padding-left: 5px;
							margin-bottom: 0;

							> li {
								text-indent: -5px;

								@include mq(desktop) {
									max-width: 500px;
								}
							}
						}
					}
				}

				> .mm-info-icon {
					padding-left: 10px;

					&.is-clickable {
						cursor: pointer;
					}

					&:after {
						display: block;
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						@include main-icon-info;
						transform: scale(0.6);

						.l-outer--my-adac-dashboard & {
							@include main-icon-info;
						}
					}
				}
			}
		}
	}
}