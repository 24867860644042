/* m-basic-image--my-adac*/
.m-basic-image--my-adac {
	max-width: 290px;
	margin: 0 auto;
	padding-top: 0;

	@include mq(tablet) {
		max-width: 320px;
	}

	&.h-space-s {
		margin-bottom: initial;
	}

	> figure {
		margin: 0;
		padding: 0;

		> .mm-stopper-wrapper {

			> .mm-ratio-container {
				display: flex;
				justify-content: center;

				> picture {
					width: auto;
					max-width: 100%;
					display: inline-block;

					img {
						width: 100%;

						&.mm-image--ios {
							.l-outer & {
								display: none;
							}

							.l-outer.is-ios & {
								display: inline;

								@include mq(tablet) {
									display: none;
								}
							}
						}

						&.mm-image--android {
							.l-outer & {
								display: none;
							}

							.l-outer.is-touch:not(.is-ios) & {
								display: inline;

								@include mq(tablet) {
									display: none;
								}
							}
						}

						&.mm-image--fallback {
							.l-outer.is-touch & {
								display: none;

								@include mq(tablet) {
									display: block;
								}
							}
						}
					}
				}
			}
		}
	}
}