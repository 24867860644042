// m-basic-list--space
.m-basic-list--space {

	.m-adac-cardamage.m-adac-cardamage--rework .mm-item.is-visible & {
		margin-bottom: 12px;

		@include mq(tablet) {
			margin-bottom: 15px;
		}
	}

	> li {
		@include mq(tablet) {
			margin-bottom: 5px;
		}

		&:first-child {
			@include mq(tablet) {
				margin-top: 10px;
			}
		}

		.m-adac-cardamage.m-adac-cardamage--rework .mm-item.is-visible & {
			padding-left: 35px;
		}

		&:before {
			.m-adac-cardamage.m-adac-cardamage--rework .mm-item.is-visible & {
				left: 7px;
				top: 9px;
			}
		}
	}
}