/* a-basic-input-select--white */
.a-basic-input-select--white {

	// settings
	$bg: $color-primary-2;

	> select {
		background: $bg;

		> option {
			background: $bg;
		}
	}
}