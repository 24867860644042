.m-my-adac-info-box--left {
	> .mm-info-box-wrapper {
		padding: 0;

		.mm-appstore {
			justify-content: flex-start;
			column-gap: 10px;

			> .js-android,
			> .js-apple {
				flex: 0;
			}
		}
	}
}