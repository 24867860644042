/* o-basic-form-footer--right */
.o-basic-form-footer--right {

	> .oo-right {
		float: right;
		padding-right: 25px;

		> p {
			> .a-basic-icon-verified {
				left: inherit;
				right: -25px;
			}
		}
	}
}