/* m-basic-newsbox  */
.m-basic-newsbox--my-adac {
	// this css is in the basicnewsbox.scss as exception

	> .mm-content {

		@include mq(tablet) {
			max-width: 760px;
			float: none;
		}

		> h3 {
			@include milo-slab-bold(18px, 22px);
			text-align: center;
			margin: 0 0 10px 0;

			@include mq(tablet) {
				@include milo-slab-bold(28px, 32px);
				margin: 0 0 15px 0;
			}
		}

		> p {
			@include milo-regular(16px, 20px);
			margin: 0 0 10px 0;

			@include mq(tablet) {
				margin: 0 0 15px 0;
			}
		}
	}
}