.m-basic-toggle--infoboxlist {

	display: none;
	height: auto;
	background-color: $color-secondary-4;
	padding: 0 8px 8px;

	@include mq(tablet) {
		display: block;
		height: 100%;
		overflow: hidden;
	}

	.mm-head {

		position: relative;
		padding: 15px 0 0 0;
		border: none;
		background: none;
		cursor: initial;

		&:after {

			@include mq(tablet) {
				content: '';
				display: inline-block;
				@include main-icon-link-big;
				position: absolute;
				right: -6px;
				top: -3px;
			}
		}

		@include mq(tablet) {
			padding: 11px 36px 11px 10px;
			border-top: 1px solid $color-primary-2;
			cursor: pointer;

			&:not(:first-child) {
				border-top: 1px solid $color-primary-2;
			}
		}

		@include mq(print) {
			box-shadow: inset 0 0 0 1000px $color-secondary-4;
		}

		&:before {
			content: none;

			@include mq(print) {
				content: "";
				width: 0;
				height: 0;
				border-top: 4px solid transparent;
				border-left: 6px solid $color-primary-3;
				border-bottom: 4px solid transparent;
				display: block;
				position: absolute;
				margin-top: 6px;
				margin-left: -14px;
			}
		}

		&.mm-opened {

			background: none;
			padding: 10px 10px 10px 26px;
			border-top: none;

			&:after {
				@include mq(tablet) {
					content: none;
				}
			}

			@include mq(tablet) {
				border-bottom: 1px solid $color-primary-2;
			}

			&:before {
				@include mq(tablet) {
					content: '';
					@include main-icon-link-back-big;
					top: -3px;
				}

				@include mq(print) {
					content: "";
					width: 0;
					height: 0;
					border-left: 4px solid transparent;
					border-right: 4px solid transparent;
					border-top: 6px solid $color-secondary-4;
					display: block;
					position: absolute;
					margin-top: 6px;
					margin-left: -14px;
				}
			}

			+ .mm-content {
				padding: 0 10px;
			}
		}

		&.js-active {
			border-top: none;
		}

		> p {
			line-height: 1.2em;
		}
	}

	.mm-content {
		background: none;
		height: auto;
		padding: 0;

		@include mq(tablet) {
			height: 0;
			background: $color-secondary-4;
			overflow: auto;
			padding: 0 10px;
		}

		> :first-child {
			padding-top: 0;
		}

		.m-basic-text {
			padding-top: 5px;

			@include mq(tablet) {
				padding: 15px 35px 25px 26px;
			}
		}
	}


	@at-root .mobile-toggle {
		display: block;
		padding: 0 10px 0 12px;
		margin-bottom: 6px;
		position: relative;
		cursor: pointer;

		@include mq(tablet) {
			display: none;
		}

		&:after {
			content: '';
			position: absolute;
			top: -12px;
			left: -20px;
			@include main-icon-link;
		}

		&.mm-opened {
			@include main-icon-arrow-drop-down;
		}
	}
}