/* l-main */
.l-form {
	&:not(.h-space-s):not(.h-space-m):not(.h-space-l):not(.h-space-resp):not(.h-space-resp-tablet) {
		margin: 0 0 20px 0;
	}

	@include mq(tablet) {
		margin-right: 0;
	}

	@include mq(print) {
		margin-right: 0;
	}

	&--background {
		background: $color-secondary-8;
		border-radius: 10px;
		padding: 20px 15px 20px 15px;

		@include mq(tablet) {
			padding: 20px 40px 20px 30px;
		}

		> form {
			padding: 20px 25px 0 25px;
			margin-bottom: -20px;

			@include mq(tablet) {
				padding: 20px 0 0 10px;
			}
		}
	}

	// exeption for mm-input-field
	.m-layout-main-nav-left .mm-input-field & {
		background-color: $color-primary-1;
		padding: 10px 0;
		margin: 1px 0 0 0;
	}

	// exeption for m-layout-main-nav-left
	.m-layout-main-nav-left & {
		margin: 10px 0;
	}

	// Exception: When in basic-keywordfilter
	.m-basic-keywordfilter--radiobuttons & {
		margin-bottom: 0;
	}

	// Exception: ll-row in ll-main-cols-l
	& fieldset .ll-main-cols-l .ll-row {
		max-width: inherit;
	}

	// Expeption: l-main-cols--line
	.l-main-cols--line > & {
		padding-right: 20px;
	}

	// Exception: m-basic-toggle--link
	.m-basic-toggle--link & {
		margin-bottom: 0;
		background: $color-secondary-6;
	}

	// exception .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration &:not(.h-space-s):not(.h-space-m):not(.h-space-l) {
		margin-bottom: 0;
	}

	// exception for m-basic-calculator-content-box--my-boat
	> .m-basic-calculator-content-box--my-boat > .ll-fieldset > .ll-row > .ll-col-label {
		@include milo-bold();

		@include mq(tablet) {
			@include milo-regular();
		}
	}

	.ll-fieldset {

		&:not(.h-space-s):not(.h-space-m):not(.h-space-l):not(.h-space-resp-tablet):not(.h-space-resp-tablet) {
			margin-bottom: 20px;

			// exception .m-basic-radius-search
			.m-basic-radius-search & {
				margin-bottom: 0;
			}
		}

		// exception .m-ves-rs-contentbox--damagedeclaration
		.m-ves-rs-contentbox--damagedeclaration > & {
			margin-bottom: 0;
		}

		@include if-parent('.l-form--sticky-contact') {
			&:not(.h-space-s):not(.h-space-m):not(.h-space-l):not(.h-space-resp-tablet):not(.h-space-resp-tablet) {
				margin-bottom: 0;
			}
		}

		// Exception ll-row
		.ll-main-cols .ll-row {
			margin-left: 0;
		}

		> legend {
			display: block;
			width: 100%;
			position: relative;
			margin: 0;
			padding: 0 0 10px 0;
			@include milo-slab-bold($font-size-18, 24px);
			font-weight: normal;

			@include mq(tablet) {
				padding: 0 0 18px 0;
				@include font-size($font-size-28, 34px);
			}

			@include mq(print) {
				@include milo-slab-bold($font-size-18, 24px);
			}

			.ll-edit {
				position: relative;
				top: -5px;
				left: 7px;
				cursor: pointer;
			}

			.ll-delete {
				cursor: pointer;
				font-weight: normal;
				@include milo-regular($font-size-15, 24px);
				padding: 0 0 0 20px;
				display: inline-block;
				margin-left: 8px;
				background: url(/assets/img/icons/icon-arrow-right-black.svg) no-repeat left top;
			}

			> .ll-optional {
				font-weight: normal;
			}
		}

		.ll-row {

			@include if-parent('.l-form--sticky-contact') {
				margin-bottom: 0;
			}

			@include mq(tablet) {

				@include if-parent('.l-form--sticky-contact') {
					margin-bottom: 0;
				}

			}

			&:not(.h-space-s):not(.h-space-m):not(.h-space-l) {
				margin: 0 0 15px 0;

				@include mq(tablet) {
					margin: 0 0 19px 0;
				}
			}

			&--upload {
				margin: 40px 0;
			}

			// Exception in checkbox label
			.a-basic-input-checkbox .ll-row {
				margin-left: 0;
			}

			&--multilabels {

				@include mq(tablet) {
					margin-top: 23px;
				}
			}

			// exception for cardamage forms
			&--cardamage {
				margin-left: 0;

				// exception for cardamage forms with aside elements
				@include if-parent('.l-form.l-form--cardamage-map') {
					margin-left: 10px;
				}
			}

			&--sep {
				.ll-col-label {
					display: block;
					overflow: hidden;
					width: 100%;

					.ll-label--l {
						display: block;
						float: right;
						max-width: 400px;
						width: calc(100% - 110px);
					}

					.ll-label--s {
						display: block;
						float: left;
						width: 100px;
					}
				}

				.ll-col-data {
					width: 100%;
					max-width: 300px;

					.a-basic-input-text--s {
						float: left;
					}

					.a-basic-input-text--l {
						width: calc(100% - 110px);
						float: right;
					}

					@include mq(desktop) {
						width: calc(100% - 100px);
					}

					//Exception
					.m-basic-sticky-service-contact & {
						@include mq(desktop) {
							width: 100%;
						}
					}
				}
			}

			&--sep-reverse {
				.ll-col-label {
					display: block;
					overflow: hidden;
					width: 100%;

					.ll-label--l {
						display: block;
						float: left;
						max-width: 400px;
						width: calc(100% - 110px);
					}

					.ll-label--s {
						display: block;
						float: right;
						width: 100px;
					}
				}

				.ll-col-data {
					width: 100%;
					max-width: 300px;

					> p {
						padding-top: 8px;
					}

					.a-basic-input-text--s {
						float: right;
					}

					.a-basic-input-select--s {
						float: right;
					}

					.a-basic-input-text--l {
						width: calc(100% - 110px);
						float: left;
					}

					@include mq(desktop) {
						width: calc(100% - 100px);
					}

					//Exception
					.m-basic-sticky-service-contact & {
						@include mq(desktop) {
							width: 100%;
						}
					}
				}
			}

			&--space-between {
				display: flex;
				justify-content: space-between;
			}

			> .ll-inline {
				display: flex;

				.m-basic-range-slide & {
					display: flex;

					@include mq('tablet') {
						display: none;
					}
				}


				> * {
					margin-right: 10px;
				}

				> .ll-inline-labeltext {
					padding-top: 5px;

					&-stars {
						padding-top: 2px;
					}
				}

				> .ll-inline-element {
					min-width: 96px;
				}
			}
		}

		.ll-delete-clone-icon {
			position: relative;
			width: 25px;
			height: 25px;
			display: inline-block;
			cursor: pointer;

			&:after {
				content: '';
				position: absolute;
				@include main-icon-cancel;
				left: -10px;
				top: -2px;
				width: 35px;
				height: 35px;
			}

			&--delete {
				&:after {
					top: -4px;
					@include main-icon-delete;
				}
			}
		}
	}

	> .l-main-cols {
		.ll-row {

			// exception .m-basic-radius-search
			.m-basic-radius-search & {
				padding: 0;

				@include mq(tablet) {
					padding: 0 30px 0 0;
				}
			}
		}
	}

	.ll-left {
		float: left;
		margin: 0 20px 0 0;
	}

	.ll-row {
		position: relative;
		padding: 0 57px 0 0;
		max-width: 335px;

		@include mq(tablet) {
			max-width: 547px;
			padding: 0 30px 0 0;
		}

		@include mq(print) {
			max-width: 547px;
			padding: 0 30px 0 0;
		}

		&--thin-padding {
			padding: 0 10px 0 0;
		}

		&--no-padding {
			padding: 0;
		}

		&:not(.h-space-s):not(.h-space-m):not(.h-space-l):not(.ll-row--button) {
			margin: 0 0 15px 0;
		}

		// exception for my adac
		.l-outer--my-adac-content & {
			width: calc(100% - 30px);

			@include mq(tablet) {
				width: 100%;
			}
		}

		@include if-parent('.l-form--sticky-contact') {
			width: calc(100% - 10px);
			padding: 0;
		}

		&--hidden {
			display: none;
		}

		&--clone {
			padding-right: 0;

			&.is-fixed-only {
				> * {
					&:only-child {
						.ll-delete {
							display: none;
						}
					}
				}
			}

			legend {
				.ll-delete {
					margin-top: 2px;
					padding: 0 0 0 20px;
					margin-left: 15px;

					@include mq(tablet) {
						margin-top: 7px;
						margin-left: 8px;
					}
				}

				h2 {
					margin-bottom: 0;
				}
			}
		}

		&--button {
			width: 100%;
			max-width: none;
			padding: 0;
			display: flex;
			align-items: center;
			margin-top: 30px;
			margin-left: 0;
			margin-right: 0;

			&-right {
				justify-content: flex-end;
			}
		}

		// ll-row with --full width
		&--full {
			max-width: 730px; // 30px extra for validation icons

			.ll-col-label {
				width: 230px;

				@include if-parent('.l-form:not(.l-form--labelontop)') {
					& + .ll-col-data {
						width: calc(100% - 230px);
						max-width: none;
					}
				}
			}
		}

		// exception for ll-rows in a o-basic-form-overview
		&--overview {

			@include mq(tablet) {
				// col-label: 240 & col-data: 410px
				max-width: 650px;
			}
		}

		// ll-row with --flex prop
		&--flex {
			display: flex;
			align-items: center;

			&-end {
				justify-content: flex-end;
			}

			&-center {
				justify-content: center;
			}

			:first-child {
				margin-right: 15px;
			}
		}

		// exception for m-basic-keywordfilter
		.m-basic-keywordfilter &--full {
			max-width: none;
		}

		// exception for cardamage forms
		&--cardamage {

			display: flex;
			flex-flow: column nowrap;

			width: 100%;
			max-width: 640px;
			padding-right: 0;

			&.ll-row--cardamage-small {
				max-width: 547px;
				padding-right: 30px;
			}

			// error validation
			&.is-check {
				padding-right: 30px;
				max-width: none;
			}

			&.h-space-s {
				margin-bottom: 15px;
			}

			&.h-space-m {
				margin-bottom: 30px;
			}

			&.h-space-l {
				margin-bottom: 60px;
			}

			&-full {
				max-width: none;
			}

			> .ll-error-msg,
			> .mm-error-msg {
				width: 100%;
			}

			> .ll-col-label {

				width: 100%;
				padding: 5px 0;

				& ~ .ll-col-data {

					max-width: 640px;
					width: 100%;

					@include mq(tablet) {
						margin-right: 15px;
						width: auto;
						max-width: none;
					}

					&.is-last {
						margin-right: 0;
					}
				}
			}
		}

		&--mgl-discount {
			display: flex;
			flex-flow: column wrap;


			@include mq(tablet) {
				flex-flow: row nowrap;
				border-top: 10px solid transparent;
			}

			.ll-discount-wrapper {
				display: flex;
				flex-flow: column nowrap;
				margin-bottom: 10px;

				@include mq(tablet) {
					flex-flow: row nowrap;
					margin-bottom: 0;
					margin-right: 20px;
				}

				> label {
					padding: 4px 10px 0 0;

					@include mq(tablet) {
						padding-left: 10px;
					}
				}
			}
		}

		&--location {
			@include mq(tablet) {
				display: flex;
				flex-flow: column wrap;
				max-width: 100% !important;
			}

			@include mq(tablet) {
				flex-flow: row nowrap;
			}

			.ll-discount-wrapper {
				display: flex;
				flex-flow: column nowrap;
				margin-bottom: 10px;

				@include mq(tablet) {
					flex-flow: row nowrap;
					margin-bottom: 0;
					margin-right: 20px;
				}

				> label {
					padding: 4px 10px 0 0;

					@include mq(tablet) {
						padding-left: 10px;
					}
				}
			}
		}

		// Exception m-basic-toggle--link
		.m-basic-toggle--link & {
			width: 100%;
			max-width: inherit;
			padding-right: 0;
		}

		.mm-dashbox-theme & {
			padding-right: 0;
			border-bottom: 1px solid $color-secondary-16;
			padding-bottom: 10px;
			max-width: 100%;
		}

		.m-my-adac-dashboard-box-layer & {
			border: none;
			padding-bottom: 0;
		}

		//Formular clone blocks, always have data attribute data-clone-heritage
		[data-clone-heritage] {
			transition: opacity 0.2s ease 0.2s;
			max-height: 0;
			opacity: 0;
			overflow: hidden;
			padding-top: 20px;

			&.is-visible {
				max-height: 999999px;
				opacity: 1;
			}
		}

		h3 {
			&.is-form-aligned {
				text-align: left;
				padding-bottom: 12px;
			}

		}

		.ll-check,
		.mm-check {
			position: absolute;
			right: 32px;
			opacity: 0;
			width: 22px;
			height: 22px;
			margin: 6px 0 0 0;
			visibility: hidden;
			transition: opacity $form-animation ease;

			@include mq(tablet) {
				margin: 8px 0 0 0;
				right: 5px;
			}

			.l-outer--my-adac-content & {
				margin: 6px 0 0 0;
				right: -30px;
			}

			@include if-parent('.l-form--sticky-contact') {
				right: -30px;
			}
		}

		// for delete icon
		&.is-valid.is-check .ll-switch-icon,
		&.is-error.is-check .ll-switch-icon {
			right: -20px;
			margin: 8px 0 0 0;
		}

		// for delete icon
		&.is-valid.is-check .ll-delete,
		&.is-error.is-check .ll-delete {
			right: -20px;
			margin: 8px 0 0 0;
		}

		&.is-error.is-check .ll-check,
		&.is-error.is-check .mm-check {
			opacity: 1;
			visibility: visible;
			background: url(/assets/img/icons/icon-close-red.svg) no-repeat;
		}

		&.is-valid.is-check .ll-check,
		&.is-valid.is-check .mm-check {
			opacity: 1;
			visibility: visible;
			background: url(/assets/img/icons/icon-check-green.svg) no-repeat;
		}

		// new modifier class '.ll-check--infoIcon' needs to be set, if module basicInfoLayer is given
		&.is-valid.is-check .ll-check--infoIcon,
		&.is-error.is-check .ll-check--infoIcon {
			right: 5px;

			@include mq(tablet) {
				margin: 8px -25px 0px 0px;
			}
		}

		.ll-error-msg,
		.mm-error-msg {
			opacity: 0;
			padding: 0;
			max-height: 0;
			overflow: hidden;
			color: $color-action-alert;
			transition: all $form-animation ease;
		}

		&.is-error {

			.ll-error-msg,
			.mm-error-msg {
				opacity: 1;
				max-height: 300px;
				padding: 5px 0;

				> a {
					color: $color-action-alert;
				}
			}
		}

		.ll-iframe-basic-input-text {
			display: inline-block;
			width: 100%;
			position: relative;
			overflow: hidden;
			transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

			&:before {
				content: '';
				@include main-icon-cancel;
				position: absolute;
				left: 100%;
				top: 2px;
				width: 0;
				height: 100%;
				z-index: 2;
				transition: all .6s ease-in-out;
			}

			> iframe {
				height: 36px;
				width: 343px;

				@include if-parent ('.ll-row.is-error') {
					border-color: transparent;
					border-bottom: 2px solid $color-action-alert;
				}
			}
		}

		.ll-button-trigger {
			&--plus {
				position: relative;
				padding-left: 40px;
				cursor: pointer;

				&:before {
					display: block;
					width: 22px;
					height: 22px;
					content: "";
					@include main-icon-clone;
					position: absolute;
					top: -13px;
					left: -13px;
				}
			}
		}
	}

	.ll-row-hidden {
		&.hidden {
			display: none;
		}
	}

	.ll-clone-wrapper {
		&:not(:first-child) {
			.ll-info-only-once {
				display: none;
			}
		}
	}

	&--labelontop .l-main-cols .ll-col-label .ll-row {
		margin-bottom: 0;
	}

	// exception ll-main-content-switch
	& .ll-main-content-switch .ll-row {
		margin-right: $l-main-content-padding-side;

		@include mq(tablet) {
			margin-right: $l-main-content-padding-side-tablet;
		}

		@include mq(desktop) {
			margin-right: $l-main-content-padding-side-desktop;
		}
	}

	.ll-depend {
		display: none;
		clear: both;

		&--visible {
			display: block;
		}

		&--my-adac {
			margin-top: 15px;
		}

		> div {
			&.m-full {
				width: 100%;
			}
		}
	}

	.ll-depend-change {
		display: none;
		clear: both;

		&.is-visible {
			display: block;
		}

		&--my-adac {
			margin-top: 15px;
		}
	}

	// Exception
	.a-basic-input-checkbox--legend > div > .ll-depend {
		margin-left: -35px;
	}

	// exception when ll-depend is in a row
	.ll-row.ll-depend {
		margin-left: 0;
	}

	.ll-row.ll-depend.ll-depend--radio {
		margin-left: 25px;
	}

	.ll-row.ll-depend.ll-depend--radio-other {
		margin-left: 35px;
	}


	// Exception when ll-depend is in a-basic-input-radio
	.a-basic-input-radio .ll-depend {
		margin-top: 0;
	}

	.a-basic-input-radio .ll-depend--my-adac {
		margin-left: -45px;
		margin-top: 15px;

		@include if-parent('.a-basic-input-radio--my-adac') {
			margin-left: 0;
		}
	}

	.ll-col-label {
		margin: 0 0 10px 0;

		@include mq(tablet) {
			margin: 0;
			float: left;
			width: 240px;
			padding: 8px 10px 0 0;
		}

		@include mq(print) {
			margin: 0;
			float: left;
			width: 210px;
			padding: 5px 10px 0 0;
		}

		&.is-empty {
			width: 25px;
		}

		.mm-dashbox-theme & {
			margin: 0;
			float: left;
			padding: 15px 10px 0 0;
			width: auto;
		}

		// exception for m-basic-socialshare
		.m-basic-socialshare & {
			width: 100%;
		}

		& + .ll-col-data {

			// exception myAdac forms
			@include if-parent('.l-form--my-adac') {
				width: 100%;
				max-width: calc(100% - 30px);
			}

			@include mq(tablet) {
				float: left;
				width: calc(100% - 240px);
				max-width: 307px;

				// Expeption: l-main-cols--line
				.l-main-cols--line & {
					width: 100%;
				}

				// Expeption: m-basic-sticky-service-contact-restyle
				.m-basic-sticky-service-contact-restyle & {
					width: 100%;
					max-width: inherit;
				}
			}

			> p {
				padding-top: 8px;
			}

			.mm-dashbox-theme & {
				float: left;
				width: auto;
			}

			// exception myAdac forms
			@include if-parent('.l-form--my-adac') {
				width: 100%;
				max-width: 100%;

				@include mq(tablet) {
					max-width: 100%;
					width: auto;

				}
			}

			// exception New sticky Contact Module right
			@include if-parent('.l-form--sticky-contact') {
				@include mq(tablet) {
					width: 100%;
				}
			}

			.o-layout-main-stage--search & {
				max-width: none;
			}

			// exception for m-basic-socialshare
			.m-basic-socialshare & {

				@include mq(tablet) {
					float: left;
					width: 100%;
					max-width: none;
				}
			}

			// exception for m-basic-keywordfilter
			.m-basic-keywordfilter--button & {
				width: 100%;
				max-width: 100%;
			}
		}

		.ll-label {
			@include milo-bold($font-size-16);
			position: relative;

			&--infolayer {
				display: inline;
				position: relative;
			}
		}

		.ll-label > span:not(.ll-bold) {
			@include milo-regular($font-size-16);
			font-weight: normal;
		}

		.ll-label > span.ll-label-smartbold {
			@include milo-bold();
			font-weight: normal;

			@include mq(tablet) {
				@include milo-regular();
			}
		}

		// Exeption l-lightbox
		.l-lightbox & {

			&:not(.ll-col-label--short) {
				width: 100%;
				padding: 0 0 5px 0;
			}
		}

		&.is-disabled {
			.ll-label {
				color: $color-secondary-9;
			}
		}

		.m-basic-socialshare & label {
			@include milo-bold();
		}
	}

	.ll-col-data {
		@include mq(print) {
			float: left;
			width: calc(100% - 170px);
		}

		&--only-data {
			@include mq(tablet) {
				margin: 0 0 0 170px;
			}

			@include mq(print) {
				margin: 0 0 0 170px;
			}
		}

		&--grid {
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;

			> .ll-error-msg,
			> .mm-error-msg {
				width: 100%;
			}
		}

		&--align-right {
			justify-content: flex-end;
		}


		.ll-delete-clone-icon {
			position: relative;
			width: 25px;
			height: 25px;
			display: inline-block;
			cursor: pointer;

			&:after {
				content: '';
				position: absolute;
				top: 7px;
				left: 0;
				width: 100%;
				height: 100%;
				background: url(/assets/img/icons/icon-cancel.svg) no-repeat;
			}
		}

		> .js-plain-content {
			display: inline-block;
			padding-top: 5px;
		}

		> .ll-input-spacer {
			display: inline-block;
			width: 19px;
			height: 30px;
			position: relative;

			> span {
				position: absolute;
				top: 5px;
				left: 8px;
			}

			&--my-adac-content {
				width: 10px;
				height: 40px;

				> span {
					left: 0;
					width: 10px;
					top: 10px;
					font-weight: bold;
					text-align: center;
				}
			}
		}

		> .ll-delete {
			position: absolute;
			right: 5px;
			width: 25px;
			height: 25px;
			margin: 5px 0 0 0;
			transition: all $form-animation ease;
			background: url(/assets/img/icons/icon-delete.svg) no-repeat;
			cursor: pointer;
		}

		> .ll-switch-icon {
			position: absolute;
			right: 5px;
			width: 25px;
			height: 25px;
			margin: 5px 0 0 0;
			transition: all $form-animation ease;
			background: url('/assets/img/icons/tourset/icon-tourset-vert.svg') no-repeat center center;
			cursor: pointer;
		}

		> .ll-row-content-wrapper {
			width: 100%;
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;

			&--tablet-switch {
				display: flex;
				flex-flow: column nowrap;
				justify-content: flex-start;

				@include mq(tablet) {
					flex-flow: row nowrap;
				}
			}
		}

		// only text in col-data
		.ll-data-text {
			display: block;
			padding: 5px 0;

			&--big {
				padding-top: 2px;
				padding-bottom: 10px;
				@include font-size($font-size-18);
				font-weight: bold;
			}
		}

		// label and input fields together in a row
		&.ll-col-data-flex {
			> .ll-inline {
				display: flex;
				align-items: center;

				> * {
					margin: 0 5px;

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}

	.ll-description {
		clear: both;
		padding-top: 5px;
		line-height: normal;

		// required for transition
		&:before {
			content: "";
			display: block;
			margin-top: 5px;
		}
	}

	// Exception basicInputText
	.a-basic-input-text + .ll-description {

		&:not(.ll-description--show-initial) {
			opacity: 0;
			padding: 0;
			max-height: 0;
			overflow: hidden;
			transition: all $form-animation ease;
		}
	}

	.a-basic-input-text.js-focus + .ll-description {
		&:not(.ll-description--show-initial) {
			opacity: 1;
			max-height: 260px;
		}
	}

	// Exception: vesNumberPlate
	.m-ves-number-plate + .ll-description {
		@include mq(tablet) {
			padding-top: 0;
		}
	}

	.ll-info {
		clear: both;
		padding-top: 5px;
		line-height: normal;
		margin-bottom: 45px;

		&:before {
			content: "";
			display: block;
			margin-top: 5px;
		}
	}

	// Exception basicInputText
	.a-basic-input-text + .ll-info {
		padding: 0;
		overflow: hidden;
	}

	// text behind input elem
	.ll-data-input-text {
		display: inline-block;
		height: 30px;
		padding: 5px 0;

		&--left {
			float: left;
			margin-right: 10px;
		}
	}

	.ll-aside-open {
		width: 40px;
		height: 40px;
		position: fixed;
		right: 0;
		top: 33.5%;
		background: $color-action-alert;
		padding: 5px;
		cursor: pointer;
		z-index: 45;

		@include mq(desktop) {
			display: none;
			right: auto;
		}

		span {
			display: block;
			padding: 3px 0 0 2px;
			width: 31px;
			height: 31px;

			&.ll-premium {
				border: none;
				position: absolute;
				top: -2px;
				left: -2px;
			}
		}
	}

	.ll-aside {
		background: $color-primary-2;
		display: none;
		z-index: 55;
		border: 1px solid $color-secondary-5;
		margin-top: 0;
		transition: transform 1.5s;

		@include mq(tablet) {
			display: block;
			width: 305px;
		}

		@include mq(desktop) {
			display: block;
			width: 305px;
			margin-right: -40px;
		}

		&-inline-on-init {
			display: block;
			position: relative;
			width: 100%;
			min-height: 100px;
			margin: 0 0 20px 0;

			@include mq(desktop) {
				position: static;
				display: block;
				width: 305px;
			}
		}

		&.is-open {
			left: auto;
			right: 0;
			top: 33.5%;
			position: fixed;
			width: 305px;
			z-index: 1005;

			@include mq(desktop) {
				right: auto;
				top: auto;
			}
		}

		&.is-animating-right {
			transform: translateX(calc(100vw));
		}

		.ll-aside-head {
			padding: 1px 37px 2px 10px;
			position: relative;
			background: $color-primary-1;
			@include milo-slab-bold($font-size-24);
			border-bottom: 1px solid $color-secondary-6;

			.h-roboto & {
				font-family: 'Roboto Bold', sans-serif;
			}

			.ll-aside-edit {
				position: absolute;
				top: 5px;
				right: 7px;
				cursor: pointer;
			}

			&--small {
				min-height: 37px;
				@include font-size(16px, 20px);
				font-family: "Calibri", "Arial", sans-serif;
				padding-top: 8px;
				padding-bottom: 8px;
			}
		}

		.ll-aside-content {
			padding: 8px 10px 2px;
			background: $color-primary-2;
			position: relative;

			h2 {
				font-family: "Calibri", "Arial", sans-serif;
				padding-bottom: 7px;
				@include milo-bold($font-size-24);
				@include line-height($font-size-24);
			}

			// exception
			.m-basic-hint + .ll-price-wrapper .ll-contribution {
				padding-top: 0;
				border-top: 0;
			}

			.ll-price-wrapper {
				@include if-parent('.l-form .ll-aside.ll-aside-inline-on-init:not(.is-open)') {
					@include mq(tablet) {
						position: absolute;
						top: 4px;
						right: 10px;
						text-align: right;
					}

					@include mq(desktop) {
						position: static;
						text-align: left;
					}
				}

				> .ll-contribution {

					@include mq(tablet) {
						@include font-size(23px);
						border: none;
						padding: 0;
					}

					@include mq(desktop) {
						padding: 10px 0 7px 0;
						border-top: 1px solid $color-secondary-5;
					}

					.ll-rate {
						@include mq(tablet) {
							@include font-size(23px);
						}

						@include mq(desktop) {
							@include font-size($font-size-28);
						}
					}
				}
			}

			> .l-main-cols {
				> .ll-main-cols-s {
					text-align: right;
				}
			}

			.ll-contribution {
				padding: 10px 0 7px 0;
				@include font-size($font-size-22);
				font-weight: bold;
				border-top: 1px solid $color-secondary-5;

				@include mq(tablet) {
					@include font-size($font-size-28);
				}

				// styling for Unfallschutz aside
				> .l-main-cols.l-main-cols--us {
					align-items: center;

					> .ll-main-cols-m {

						> .js-rate-method,
						&.js-rate-value {
							@include font-size(24px);
							line-height: 30px;

							&-initial {
								@include font-size(24px);
								line-height: 30px;
							}
						}

						&:last-of-type {
							text-align: right;
						}
					}
				}

				> #membership_price_standard,
				> #membership_price_plus {
					@include font-size(16px);
					font-weight: normal;
				}
			}

			.ll-action-footer {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.ll-rate {
				@include font-size($font-size-22);
				font-weight: bold;

				@include mq(tablet) {
					@include font-size($font-size-28);
				}
			}

			// exception for AKS Incoming
			> .ll-row {
				padding: 0;

				> .ll-row--flex {
					&:not(.h-none) {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						padding-right: 10px;
					}

					&:first-child {
						margin-bottom: 0;
					}

					> p {
						margin-bottom: 0;
					}

					.js-kv-price {
						font-weight: bold;
					}

					.js-hv-price {
						font-weight: bold;
					}
				}
			}
		}

		.ll-aside-bar--mobile {
			visibility: hidden;
			height: 0;

			&.is-stuck {
				@include mq(mobile-max) {
					visibility: visible;
					height: auto;
					z-index: 99;
				}
			}
		}
	}

	.ll-calendar-periodtext {
		display: block;
		float: left;
		padding: 5px 10px 0 0;
		font-weight: bold;
		margin-left: 30px;

		.l-outer:not(.is-touch) & {
			margin-left: 0;
		}

		@include mq(desktop-l) {
			margin-left: 0;
		}

	}

	.js-result-valid {
		margin: 8px 0 0 0;
		padding: 11px 0 0 0;
		border-top: 1px solid $color-secondary-5;
	}

	&#calculator_us .js-result-valid {
		margin: 0;
		border-top: none;
	}

	.ll-result-error {
		position: relative;
		margin: 8px 0 20px 0;
		padding: 12px 10px 9px 40px;
		@include line-height(18px);
		background-color: $color-action-alert;
		color: $color-primary-2;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 10px;
			left: 10px;
			width: 24px;
			height: 24px;
			background: url('/assets/img/icons/icon-error-symbol-white.svg');
		}

		> p {
			margin-bottom: 0;
		}
	}


	/* ##### Modifier ##### */

	&.l-form--cardamage-proof {
		.ll-check {
			right: -25px;
		}
	}

	&--dark {
		background: $color-secondary-10;
		padding: 10px;

		@include mq(tablet) {
			padding: 20px;
		}

		.ll-fieldset {
			margin: 0;

			> legend {
				margin: 0;
				padding: 0 10px 6px 0;
			}

			.is-check.is-error.ll-row {
				margin-right: 0;
			}
		}
	}

	&--selection {

		.ll-fieldset {
			margin: 0;

			> legend {
				margin-bottom: 5px;
				background: transparent;
			}

			.ll-row {
				max-width: 100%;
				margin: 0 20px 15px 10px;

				@include mq(tablet) {
					margin: 0 20px 15px 20px;
				}
			}
		}
	}

	&--calculation {

		.ll-fieldset {
			margin: 0;

			.ll-row {
				max-width: 100%;
				margin-left: 0;
			}
		}
	}

	&--offense {

		.ll-fieldset {
			margin: 0;

			.ll-row {
				max-width: 100%;
				margin-left: 0;
				margin-bottom: 0;

				.ll-col-data {
					max-width: none;
				}
			}
		}
	}

	&--country-selection {

		.ll-fieldset {

			h4 {
				margin-bottom: 10px;
			}

			.ll-row {
				margin-left: 0;
				padding-right: 0;
			}
		}
	}

	&--labelontop {

		.mm-content--bg & {
			margin: 0;
			padding: 10px 20px 0 20px;

			.ll-row {
				margin: 0;
			}
		}

		.ll-row {

			// ##### exceptions #####
			// l-lightbox
			.l-lightbox & {
				margin-bottom: 11px;
			}

			&.ll-row--full {
				.ll-col-data {
					max-width: none;
				}
			}
		}

		.ll-col-data {
			float: none !important;
			width: 100%;

			&--only-data {
				margin-left: 0;
			}
		}

		.ll-col-label {
			float: none !important;
			width: 100%;
			margin-bottom: 5px;
			padding-right: 0;

			+ .ll-col-data {
				width: 100%;
			}

			// ##### exceptions #####
			// l-lightbox
			.l-lightbox & {
				padding-bottom: 0;
			}
		}
	}

	&--my-adac {
		max-width: 370px;
		margin: 0 auto;

		> .ll-fieldset {
			margin-right: 30px;

			@include mq(tablet) {
				margin-right: 0;
			}

			&.resp-tablet {
				margin-bottom: 30px;

				@include mq(tablet) {
					margin-bottom: 60px;
				}
			}

			> .ll-row {

				> .ll-col-data {
					width: 100%;
				}
			}
		}
	}

	&--my-adac-wide {
		max-width: 765px;
		margin-right: auto;
		margin-left: auto;
	}

	&--lightbox {
		padding-left: 30px;

		.ll-col-data {
			width: 100% !important;
		}
	}

	&--my-adac-content {
		max-width: 650px;
		margin: 0 auto;

		> .ll-fieldset {
			margin-right: 30px;

			@include mq(tablet) {
				margin-right: 0;
			}

			&.resp-tablet {
				margin-bottom: 30px;

				@include mq(tablet) {
					margin-bottom: 60px;
				}
			}

			.ll-row {
				max-width: 100%;

				> .ll-col-data {
					width: 100%;

					> .a-basic-input-radio--my-adac {

						.ll-depend {
							margin-top: 25px;
						}
					}
				}
			}
		}
	}

	// Exception ll-main-content-switch
	& .ll-main-content-switch .ll-fieldset > legend {
		position: relative;
		width: calc(100% + 15px);
		margin-left: -($l-main-content-padding-side);
		padding-left: ($l-main-content-padding-side + 10);

		@include mq(tablet) {
			width: calc(100% + 45px);
			margin-left: -($l-main-content-padding-side-tablet + 15);
			padding-left: ($l-main-content-padding-side-tablet + 25);
		}

		@include mq(desktop) {
			width: calc(100% + 55px);
			margin-left: -($l-main-content-padding-side-desktop + 15);
			padding-left: ($l-main-content-padding-side-desktop + 25);
		}
	}

	& .ll-main-content-switchbox:first-child .ll-fieldset > legend {
		width: 100%;
		margin-left: 0;
		padding-left: 10px;
	}

	// exception when in ves-rs-sticky + ves-rs-stickybar
	.m-tabcontent-inside--rscontact .mm-invisible-content &,
	.mm-layer-inner .mm-invisible-content & {

		.l-main-cols {

			.ll-main-cols-m {
				width: 95%;

				@include mq(tablet) {
					width: 50%;
				}

				@include mq(desktop) {
					width: 100%;
				}

				.ll-row {
					padding: 0;
					margin: 0;

					.ll-col-label {
						margin-bottom: 2px;
						width: 100%;

						@include mq(tablet) {
							max-width: 325px;
						}

						@include mq(desktop) {
							max-width: 409px;
						}

						.l-main-cols {
							padding-right: 0;
						}
					}

					.ll-col-data {
						width: 100%;

						@include mq(tablet) {
							max-width: 325px;
						}

						@include mq(desktop) {
							max-width: 409px;
						}

						.l-main-cols {
							padding-right: 0;
						}

						.a-basic-input-text {
							max-width: 409px;
						}

						.a-basic-input-textarea {
							height: 160px;
							max-width: 409px;
							width: 100%;
						}

						.ll-check {
							right: -25px;

							@include mq(tablet) {
								right: 0;
							}
						}
					}
				}
			}
		}
	}

	// exception when in ves-rs-sticky + ves-rs-stickybar
	.m-tabcontent-inside--rslocation &,
	.mm-layer--location & {
		max-width: 845px;
		margin-top: 15px;

		.ll-fieldset {
			margin: 0;
			float: none;
			width: 100%;

			@include mq(tablet) {
				width: calc(100% / 2);
				float: left;
			}

			> .ll-row {
				margin: 0;

				> .ll-col-label {
					display: block;
					float: none;
					width: 100%;

					@include mq(tablet) {
						display: inline;
						float: left;
						width: auto;
					}
				}

				> .ll-col-data {
					width: 100%;
				}
			}
		}
	}

	// exception when in vesrs-contentbox
	.m-ves-rs-contentbox & {

		margin: 0 0 0 0;

		@include mq(tablet) {
			margin: 15px 0 0 0;
		}

		> .ll-fieldset {

			> .ll-multirow-container {
				display: flex;
				flex-flow: column nowrap;
				justify-content: flex-start;

				@include mq(tablet) {
					justify-content: center;
					flex-flow: row wrap;
				}

				@include mq(desktop) {
					padding: 0;
				}

				> .ll-row {
					margin-bottom: 0;

					@include mq(tablet) {
						margin-bottom: 15px;
					}

					&--first {

						@include mq(tablet) {
							margin-right: 20px;
						}

						// exception .m-ves-rs-contentbox--damagedeclaration
						@include if-parent('.m-ves-rs-contentbox.m-ves-rs-contentbox--damagedeclaration') {
							margin-bottom: 15px;
							max-width: 400px;

							@include mq(tablet) {
								margin-bottom: 0;
							}

							// fix for calendar validation
							.ll-check {
								opacity: 1;
								height: 22px;
								visibility: visible;

								@include mq(desktop) {
									right: -65px !important;
								}
							}
						}
					}

					&--last {
						@include mq(tablet) {
							margin-left: 20px;
						}
					}

					> .ll-item-container {

						@include mq(tablet) {
							padding-right: 50px;
						}

						// exception .m-ves-rs-contentbox--damagedeclaration
						@include if-parent('.m-ves-rs-contentbox.m-ves-rs-contentbox--damagedeclaration') {
							@include mq(tablet) {
								padding-right: 50px;
							}

							> .ll-col-data {

								> .ll-error-msg,
								> .mm-error-msg {
									@include mq(tablet) {
										width: 140px;
									}

								}
							}
						}

						> .ll-col-data {

							> .ll-check {
								@include mq(tablet) {
									right: -30px;
								}
							}

							> .ll-error-msg,
							> .mm-error-msg {
								width: 140px;
							}

							> .a-basic-input-text--calendar {
								> .aa-calendar {

									@include mq(desktop) {
										right: -35px;
									}
								}
							}
						}

					}
				}
			}

			.ll-row {
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				margin: 0 0 23px 0;
				max-width: none;

				@include mq(tablet) {
					flex-flow: row wrap;
					margin-bottom: 15px;
					justify-content: space-around;
				}

				&--last:not(.h-space-default) {
					margin-bottom: 0;
				}

				// exception to reduce spacing - ll-row works just as container for various toggle contents
				&--nospacing {
					padding: 0;
					margin: 0;
				}

				&.h-space-none {
					margin-bottom: 0;
				}

				&.is-min-height:not(.hidden) {
					margin-bottom: 45px;
				}

				&.hidden {
					display: none;
				}

				> .ll-item-container {
					display: flex;
					justify-content: center;
					flex-flow: column nowrap;
					width: 100%;

					@include mq(tablet) {
						flex-flow: row nowrap;
						width: auto;
						max-width: none;
						justify-content: center;
						padding-right: 50px;
					}

					&.h-space-m {
						margin-bottom: 20px;

						@include mq(tablet) {
							margin-bottom: 0;
						}
					}

					// exception .m-ves-rs-contentbox--damagedeclaration
					@include if-parent('.ll-row.ll-row--nospacing') {
						@include mq(tablet) {
							padding-right: 0;
						}
					}

					// exception .m-ves-rs-contentbox--damagedeclaration
					@include if-parent('.m-ves-rs-contentbox.m-ves-rs-contentbox--damagedeclaration') {
						padding-right: 0;

						&.is-desk-left-aligned {

							position: relative;

							@include mq(tablet) {
								min-width: 305px;
								max-width: 305px;
								width: 305px;
								justify-content: flex-start;
							}

							> .ll-col-data {

								&--upload {
									@include mq(tablet) {
										width: 215px;
									}
								}

								> .ll-error-msg,
								> .mm-error-msg {
									width: 85%;

									@include mq(desktop) {
										width: auto;
									}
								}


							}

						}

						> .ll-col-data {

							position: relative;
							max-width: 305px;
							width: auto;

							> .ll-check {

								right: 0;

								@include mq(tablet) {
									right: -30px;
								}
							}

							// input format info
							p.ll-inputformatinfo {
								margin: 5px 0;
								@include font-size($font-size-14);
							}

							h3 {

								position: relative;
								@include milo-slab-bold();

								&.is-form-aligned {
									text-align: left;
									padding-bottom: 12px;
								}

							}
						}

						> .ll-col-label + .ll-col-data {

							@include mq(tablet) {
								padding-left: 40px;
							}
						}

						> .ll-col-label {
							padding-right: 0;
							width: auto;
							text-align: left;

							@include mq(tablet) {
								text-align: right;
								width: 35%;
								padding-top: 6px;
							}

							> .m-basic-info-layer {

								display: inline-block;

								.mm-infolayer-icon {
									position: relative;
									top: 4px;
									bottom: auto;
									left: auto;
									right: 3px;
									margin: 0;
								}

							}
						}
					}

					&--full {

						@include mq(tablet) {
							flex-flow: row wrap;
							max-width: none;
							width: 100%;
							justify-content: flex-start;
							margin-left: 45px;
							padding-right: 0;
						}

						// exception .m-ves-rs-contentbox--damagedeclaration
						@include if-parent('.m-ves-rs-contentbox.m-ves-rs-contentbox--damagedeclaration') {
							margin-left: 0;
						}

						> .ll-col-label {
							@include mq(tablet) {
								width: 50%;
								padding-right: 50px;
							}

							@include mq(desktop-l) {
								max-width: 400px;
							}
						}

						> .ll-input-container {

							width: 100%;

							@include mq(tablet) {
								max-width: 375px;
								width: 50%;
							}

							// exception .m-ves-rs-contentbox--damagedeclaration
							@include if-parent('.m-ves-rs-contentbox.m-ves-rs-contentbox--damagedeclaration') {
								display: flex;
								flex-flow: row wrap;
								position: relative;
								max-width: 300px;

								@include mq(tablet) {
									padding-left: 40px;
									max-width: 345px;
								}

								> .ll-error-msg,
								> .mm-error-msg {
									max-width: 305px;
									width: 305px;
								}

								> .ll-check,
								> .mm-check {
									right: 0;

								}

								&--multi {

									> .ll-check,
									> .mm-check {
										right: -5px;

										@include mq(tablet) {
											right: -30px;
										}

									}

									> .ll-col-data {
										margin-right: 5px;

										&:last-child {
											margin-left: 5px;
											margin-right: 0;
										}
									}
								}
							}


							> .ll-col-data {
								position: relative;
								left: 50%;
								transform: translate(-30px);
								max-width: calc(100vw - 50%);
								margin-bottom: 10px;
								padding-right: 35px;

								@include mq(tablet) {
									left: auto;
									transform: none;
									margin-left: 0;
									min-width: auto;
									max-width: none;
									padding-right: 0;
								}

								// exception .m-ves-rs-contentbox--damagedeclaration
								@include if-parent('.m-ves-rs-contentbox.m-ves-rs-contentbox--damagedeclaration') {
									margin-bottom: 0;
									left: 0;
									max-width: 100%;
									transform: none;

									&:last-child:not(.ll-col-data--multi) {
										width: calc(85% - 95px);
										margin-left: 10px;

										@include mq(tablet) {
											width: auto;
										}
									}
								}

								> .ll-error-msg,
								> .mm-error-msg {

									transform: translate(-40%);

									@include mq(tablet) {
										transform: none;
										max-width: 180px;
									}

									// exception .m-ves-rs-contentbox--damagedeclaration
									@include if-parent('.m-ves-rs-contentbox.m-ves-rs-contentbox--damagedeclaration') {
										display: none;
									}
								}

								> .ll-check,
								> .mm-check {
									top: 0;
									right: 42px;

									@include mq(tablet) {
										top: auto;
										right: 5px;
									}
								}

								p.ll-inputinfo {
									max-width: 305px;
									margin: 5px 0;
									text-align: left;
									@include font-size($font-size-14, 18px);
								}
							}
						}

						> .ll-col-data {

							// exception .m-ves-rs-contentbox--damagedeclaration
							@include if-parent('.m-ves-rs-contentbox.m-ves-rs-contentbox--damagedeclaration') {
								max-width: 305px;
								width: 100%;

								@include mq(tablet) {
									max-width: 345px;
								}

								// center elem if its the only child
								&:only-child {
									margin: 0 auto;
								}
							}
						}
					}

					> .ll-col-label {

						width: 100%;

						@include mq(tablet) {
							text-align: left;
							padding-left: 5px;
							padding-right: 10px;
							width: auto;
						}

						@include mq(desktop) {
							padding-left: 0;
						}

						&--center {
							text-align: center;

							@include mq(tablet) {
								text-align: left;
							}

						}
					}

					> .ll-col-data {
						width: 100%;
						display: flex;
						flex-flow: column nowrap;

						@include mq(tablet) {
							max-width: 180px;
							min-width: 140px;
						}

						> .ll-error-msg,
						> .mm-error-msg {

							max-width: calc(100vw - 100px);

							@include mq(tablet) {
								max-width: none;
								padding-right: 0;
							}

							// exception .m-ves-rs-contentbox--damagedeclaration
							@include if-parent('.m-ves-rs-contentbox.m-ves-rs-contentbox--damagedeclaration') {
								max-width: calc(100% - 30px);

								@include mq(tablet) {
									max-width: none;
								}
							}
						}

						> .ll-input-container {

							// exception .m-ves-rs-contentbox--damagedeclaration
							@include if-parent('.m-ves-rs-contentbox.m-ves-rs-contentbox--damagedeclaration') {
								padding-top: 55px;

								> .ll-error-msg,
								> .mm-error-msg {
									opacity: 1;
									max-height: none;
								}
							}

						}
					}
				}
			}
		}
	}



	// exception when in m-basic-calculator-tiles-classic
	.m-basic-calculator-tiles--classic & {
		.ll-row {
			max-width: none;

			.ll-col-label {
				@include mq(tablet) {
					width: 50%;
					text-align: right;
					padding-right: 20px;
				}
			}

			.ll-col-data {
				position: relative;

				@include mq(tablet) {
					width: 50%;
					margin-right: 0;
					margin-left: auto;
				}
			}
		}
	}

	// exception when in m-basic-calculator-content-box
	.m-basic-calculator-content-box & {
		&:not(.h-space-s):not(.h-space-m):not(.h-space-l) {
			margin: 0 0 0 0;

			@include mq(tablet) {
				margin: 15px 0 0 0;
			}
		}

		.ll-multirow-container {
			&.ll-multirow-33 {
				position: relative;

				@include mq(tablet) {
					display: flex;
					justify-content: flex-start !important;
				}

				@include mq(desktop) {
					justify-content: center !important;
				}

				> .ll-row {
					max-width: 400px;
					justify-content: flex-start !important;

					&--last {
						margin-left: 0 !important;
					}
				}

				> * {

					.ll-item-container {
						padding: 0 !important;
						display: block;
						margin: 0;

						@include mq(tablet) {
							position: relative;
							max-width: 215px !important;
						}
					}

					.ll-col-label {
						text-align: left !important;
						width: 100% !important;
					}

					.ll-col-data {
						padding: 0 !important;
						max-width: none !important;
						width: 100% !important;

						> .ll-check,
						> .mm-check {
							@include mq(tablet) {
								right: -25px !important;
							}

							@include mq(desktop) {
								right: 30px;
							}
						}
					}
				}

				.ll-delete-clone {
					position: absolute;
					height: 25px;
					top: 32px;
					right: -20px;
					background: url('/assets/img/icons/icon-cancel.svg') no-repeat center center transparent;
					width: 25px;
					cursor: pointer;
					z-index: 50;
				}
			}
		}


		> .ll-fieldset {
			&:not(.h-space-s):not(.h-space-m):not(.h-space-l):not(.h-space-resp-tablet):not(.h-space-resp-tablet) {
				margin-bottom: 0;
			}

			> .ll-multirow-container {
				display: flex;
				flex-flow: column nowrap;
				justify-content: flex-start;

				@include mq(tablet) {
					justify-content: center;
					flex-flow: row wrap;
				}

				@include mq(desktop) {
					padding: 0;
				}

				> .ll-row {
					margin-bottom: 0;

					@include mq(tablet) {
						margin-bottom: 15px;
						justify-content: flex-end;
						width: 100%;
						left: -25%;
					}

					@include mq(desktop) {
						justify-content: center;
						width: auto;
						left: auto;
					}

					&--last {
						@include mq(tablet) {
							margin-left: 20px;
						}
					}

					> .ll-item-container {

						@include mq(tablet) {
							padding-right: 50px;
						}

						> .ll-col-data {

							padding-right: 35px;

							@include mq(tablet) {
								padding-right: 0;
							}

							> .ll-check,
							> .mm-check {
								@include mq(tablet) {
									right: -30px;
								}

								@include mq(desktop) {
									right: 30px;
								}
							}

							> .a-basic-input-text--calendar {
								> .aa-calendar {
									@include mq(desktop) {
										right: -35px;
									}
								}
							}
						}

					}
				}
			}

			.ll-row {
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				max-width: none;

				@include mq(tablet) {
					flex-flow: row wrap;
					justify-content: space-around;
				}

				&:not(.h-space-s):not(.h-space-m):not(.h-space-l):not(.h-space-resp-tablet):not(.h-space-resp-tablet) {
					margin-bottom: 23px;

					@include mq(tablet) {
						margin-bottom: 15px;
					}
				}

				&--last:not(.h-space-default) {
					margin-bottom: 0;
				}

				&.h-space-none {
					margin-bottom: 0;
				}

				&.is-min-height:not(.hidden) {
					min-height: 30px;
				}

				&.hidden {
					display: none;
				}

				// exception for tourset 580px centered
				// if the new formstyling is online
				// delete me, and merge me with legend-css above 15.10.19
				@include if-parent('.l-form.l-form--small-content') {
					max-width: 400px;
					margin: 0 auto 15px auto;
					justify-content: flex-start;

					@include mq(tablet) {
						padding: 0 0 0 60px;
						max-width: 620px;
					}
				}

				// set form left
				@include if-parent('.l-form.l-form--calc-left-content') {
					justify-content: flex-start;
					max-width: 400px;

					@include mq(tablet) {
						max-width: 547px;
					}
				}

				> .ll-form-text {
					margin-bottom: 15px;
				}

				> .ll-item-container {

					display: flex;
					justify-content: center;
					flex-flow: column nowrap;
					width: 100%;

					@include mq(tablet) {
						flex-flow: row nowrap;
						width: auto;
						max-width: none;
						justify-content: center;
						padding-right: 50px;
					}

					// exception for tourset 580px centered
					// if the new formstyling is online
					// delete me, and merge me with legend-css above 15.10.19
					@include if-parent('.l-form.l-form--small-content') {
						justify-content: flex-start;
						width: 100%;

						@include mq(tablet) {
							padding-right: 40px;
						}
					}

					// set form left
					@include if-parent('.l-form.l-form--calc-left-content') {
						justify-content: flex-start;
						width: 100%;

						@include mq(tablet) {
							padding-right: 40px;
						}
					}

					&.h-space-m {
						margin-bottom: 20px;
					}

					> .ll-col-label {
						width: 100%;

						@include mq(tablet) {
							text-align: left;
							padding: 6px 30px 0 5px;
							width: auto;
							flex-shrink: 0;
							flex-grow: 1;
						}

						@include mq(desktop) {
							padding-left: 0;
						}

						&--center {
							text-align: center;

							@include mq(tablet) {
								text-align: left;
							}

						}

						// exception for tourset 580px centered
						// if the new formstyling is online
						// delete me, and merge me with legend-css above 15.10.19
						@include if-parent('.l-form.l-form--small-content') {
							flex: 1 0 100%;

							@include mq(tablet) {
								flex: 1 0 240px;
							}
						}

						// set form left
						@include if-parent('.l-form.l-form--calc-left-content') {
							flex: 1 0 100%;

							@include mq(tablet) {
								flex: 1 0 240px;
							}
						}

						> .ll-label {
							position: relative;
						}
					}

					> .ll-col-data {
						width: 100%;
						display: flex;
						flex-flow: column nowrap;
						padding-right: 35px;

						@include mq(tablet) {
							max-width: 150px;
							min-width: 150px;
							padding-right: 0;
						}

						// exception for tourset 540px centered
						@include if-parent('.l-form.l-form--small-content') {
							display: block;
							width: 100%;

							// exception for tourset 580px centered
							// if the new formstyling is online
							// delete me, and merge me with legend-css above 15.10.19
							@include mq(tablet) {
								max-width: 100%;
								min-width: initial;
							}
						}

						// set form left
						@include if-parent('.l-form.l-form--calc-left-content') {
							display: block;
							width: 100%;

							@include mq(tablet) {
								max-width: 100%;
								min-width: initial;
							}
						}

						> .ll-error-msg,
						> .mm-error-msg {
							max-width: calc(100vw - 100px);

							@include mq(tablet) {
								max-width: calc(100% - 35px);
								padding-right: 0;
							}
						}
					}


					&--center {
						width: 100%;
						justify-content: flex-start;

						@include mq('tablet') {
							padding-right: 0;
						}

						> .ll-col-label {
							@include mq('tablet') {
								width: 50%;
							}
						}

						> .ll-col-data {
							position: relative;

							@include mq('tablet') {
								max-width: 210px;
							}

							@include mq('desktop') {
								max-width: 100%;
							}

							> .ll-generations-msg {
								position: absolute;
								left: 0;
								bottom: -8px;
								display: none;

								@include mq(tablet) {
									left: 180px;
									bottom: 22px;
								}

								&.is-visible {
									display: block;
								}

								@include if-parent('.ll-row.is-check.is-valid') {
									@include mq(tablet) {
										left: 220px;
									}

									@include mq(desktop) {
										left: 180px;
									}
								}
							}
						}
					}

					> .ll-input-container {
						display: flex;
						flex-flow: column nowrap;
						margin-right: auto;

						@include mq(tablet) {
							flex-flow: row nowrap;
							margin-right: 0;
						}

						> .ll-col-data {
							width: 100%;
							max-width: 400px;
							margin-right: 10px;

							@include mq(tablet) {
								width: auto;
								max-width: calc(50% - 5px);
							}

							&:last-child {
								margin-right: 0;
							}
						}
					}
				}
			}
		}
	}

	// exception ves rs sticky module
	&--emailoffer {

		$label-width-tablet: 220px;

		.ll-fieldset {
			.ll-row {
				padding-left: 0;
				margin-left: 0 !important;

				> .ll-col-label {
					width: 100%;

					@include mq(tablet) {
						width: $label-width-tablet;
					}

					label {
						strong {
							@include milo-bold($font-size-16);
						}
					}
				}

				> .ll-col-data {

					@include mq(tablet) {
						width: calc(100% - #{$label-width-tablet});
						max-width: none;
					}

					// no label set (e.g. checkboxes)
					&:only-child {

						@include mq(tablet) {
							margin-left: $label-width-tablet;
						}
					}

					.a-basic-input-text:not(.a-basic-input-text--s):not(.a-basic-input-text--l) {
						width: 95%;

						@include mq(tablet) {
							width: 100%;
						}
					}
				}

				.ll-check,
				.mm-check {
					right: -15px;

					@include mq(tablet) {
						right: -30px;
					}
				}
			}
		}
	}


	.l-main-content--login & {
		padding-right: 24px;

		@include mq(tablet) {
			padding-right: 0;
		}

		.ll-fieldset {
			margin: 0 0 50px 0;

			.ll-check {
				right: -25px;
			}
		}
	}

	.o-basic-cluster-box--form & {
		.ll-col-label {
			display: block;
			overflow: hidden;
			width: 100%;
			max-width: 300px;

			@include mq(desktop) {
				width: calc(100% - 100px);
			}

			.ll-label--l {
				display: block;
				float: left;
				max-width: 400px;
				margin-right: 10px;
				width: calc(100% - 100px);
			}

			.ll-label--s {
				display: block;
				float: left;
			}
		}

		.ll-col-data {
			width: 100%;
			max-width: 300px;

			.a-basic-input-text--l {
				width: calc(100% - 110px);
			}

			@include mq(desktop) {
				width: calc(100% - 100px);
			}

		}

		.a-basic-input-text--left {
			float: left;
			margin-right: 10px;
		}

		.a-basic-input-select--left {
			margin-right: 0;
		}
	}

	&--clone {

		.mm-remove {
			@include absolute();
			background: url('/assets/img/icons/icon-cancel-black.svg') no-repeat center center transparent;
			cursor: pointer;
			display: block;
			width: 20px;
			height: 20px;
		}

		.js-clone-tour {
			display: block;
			position: relative;

			.mm-remove {
				top: 1px;
				right: 0;
				bottom: 0;
			}

			&.is-cloned {
				margin-top: 30px;
			}
		}

		.js-clone-input {
			display: block;
			position: relative;

			.mm-remove {
				top: auto;
				right: -30px;
				bottom: 11px;
			}
		}

		.js-clone-button {
			display: block;
			cursor: pointer;
			position: relative;
			left: 30px;
			max-width: 300px;
			outline: none;
			padding: 0;
			text-decoration: none;
			text-align: left;

			&:before {
				@include milo-bold($font-size-18, 14px);
				@include absolute($top: 0, $left: -30px);
				display: inline-block;
				content: "+";
				border: 2px solid $color-secondary-1;
				width: 20px;
				height: 20px;
				text-align: center;
			}
		}

		.a-basic-input-text {
			margin: 0 0 5px 0;

			&--period {
				width: 140px;

				input {
					width: 100px;

					+ .mm-travel-period {
						float: right;
						margin-top: 4px;
					}
				}
			}
		}

		.a-basic-input-text--calendar {
			margin: 0 0 10px 0;
			float: none;
		}

		.a-basic-input-textarea {
			margin: 0 0 5px 0;
		}

		.a-basic-input-select {

			&.h-space-s {
				margin-bottom: 5px;
			}
		}
	}

	&--newsletter {

		.ll-row,
		.ll-row--full {
			max-width: 604px;

			.ll-col-label + .ll-col-data {

				.a-basic-input-text {
					max-width: 85%;

					@include mq(tablet) {
						max-width: 275px;
					}
				}
			}
		}
	}

	&--sticky-contact.h-space-s {
		margin-bottom: 0;
	}

	&.form-juristisch-kontakt {
		max-width: 547px;

		.ll-row {
			max-width: none;
		}
	}
}

// exception for ll-depends outside of l-form
.ll-depend {
	display: none;
	clear: both;

	&--visible {
		display: block;
	}
}