/* a-basic-input-checkbox--legend */
.a-basic-input-checkbox--legend {
	> input {

		+ label + div {
			background-color: #ededed;
			background-position: 8px 4px;
			position: relative;
			padding: 6px 10px 6px 35px;

			> label {
				margin-left: 5px;
			}

			> .ll-depend {
				background: $color-primary-2;
				margin: 6px -10px -21px -35px;
				padding-top: 10px;
			}
		}
	}

	> label {
		z-index: 10;
	}
}