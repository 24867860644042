/* a-basc-link--english */
.a-basic-link--english {
	padding-left: 36px;

	&:before {
		content: '';
		display: inline-block;
		@include main-icon-english;
		top: -13px;
		left: -10px;
	}

	&:after {
		position: relative;
	}

	// in stage with swiper
	.swiper & {
		position: absolute;
		right: 15px;
		top: 15px;
		width: 48px;
		height: 20px;
		z-index: 10;
		padding-right: 4px;

		@include mq(tablet) {
			right: 10%;
			top: 5%;
		}

		@include mq(desktop) {
			right: 25px;
			top: 25px;
		}

		@include mq(desktop-l) {
			right: 120px;
			top: 40px;
		}
	}

	// in stage within a swiper-container and in a oo-box
	.swiper .oo-box & {
		width: auto;
		position: static;

		// code changes and the new sprite implementation lead to this issue - quick solution - not pretty good
		display: flex;
		flex-flow: row nowrap;
		padding-left: 0;

		@include mq(desktop-l) {
			right: auto;
		}

		// code changes and the new sprite implementation lead to this issue - quick solution - not pretty good
		&::before {
			position: relative;
		}
	}

	//exception for new language flag
	.ll-main-language--white-background & {
		width: 48px;
		height: 20px;
		z-index: 10;
		@include main-icon-english;
		padding-right: 4px;
	}

	.o-layout-main-stage--cluster & {
		background-color: transparent !important;
	}

	@include mq('print') {
		display: none;
	}
}