/* a-basic-input-select--ds-filter */
.a-basic-input-select--ds-filter {
	.l-form-general & {
		height: 36px;
		border-radius: 18px;

		@include mq(tablet) {
			height: 40px;
			border-radius: 5px;
		}

		> select {
			padding: 0 40px 0 15px;
			min-height: 36px;
			border-radius: 18px;

			@include mq(tablet) {
				min-height: 40px;
				border-radius: 5px;
			}

			&:focus {
				padding: 0 39px 0 14px;
			}

			> option {
				background-color: $color-primary-2;

				&:disabled {
					display: none;
				}
			}
		}

		.aa-controller {
			top: -3px;

			@include mq(tablet) {
				top: -1px;
			}
		}
	}
}