/* m-basic-list--advantage */
.m-basic-list--advantage {

	padding: 0 0 10px 8px;

	@include mq(print) {
		padding-left: 0;
	}

	// Exception table + driver safety
	.m-ds-result &,
	.m-basic-table & {
		padding-bottom: 0;
		padding-left: 0;
	}

	// exception .m-basic-calculator-content-box if children of mm-tile and sibling of a disabled input
	.m-basic-calculator-content-box .mm-tile-container .mm-text-container > & {
		padding-left: 0;
	}

	// exception .m-basic-calculator-tiles if inside tile
	.m-basic-calculator-tiles .mm-tile-label & {
		padding: 15px 0 0;
	}

	> li {
		margin-bottom: 10px;
		padding: 0 0 2px 28px;

		&:before {
			content: '';
			display: inline-block;
			@include main-icon-check;
			position: absolute;
			left: -12px;
		}

		@include mq(print) {
			background: none;
		}

		> span {
			&:last-child {
				@include mq(tablet) {
					white-space: nowrap;
				}
			}
		}

		// exception
		.m-basic-table table tr:nth-child(even) td & {
			@include mq(print) {
				box-shadow: inset 0 0 0 1000px $color-secondary-6;
			}
		}

		// exception .m-basic-calculator-content-box if children of mm-tile and sibling of a disabled input
		.m-basic-calculator-content-box .mm-tile-container .mm-tile > input:disabled ~ & {

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-check-light-grey;
				width: 31px;
				height: 30px;
				position: absolute;
				left: -13px;
			}
		}
	}

	// exception
	.mm-flex--row-reverse .mm-content & {
		margin-bottom: 0;
	}

	.m-basic-table--compare & {
		> li {

			&:before {
				content: '';
				display: inline-block;
				@include main-icon-check-green;
				position: absolute;
				left: -12px;
				top: -15px;
			}
		}
	}

	.l-main-content--dashboardteaser & {
		padding-bottom: 0;

		@include mq(desktop) {
			padding-bottom: 10px;
		}
	}
}