/* a-basic-icon--printlayer */
.a-basic-icon--printlayer {
	position: absolute;
	top: 8px;
	right: 50px;
	cursor: pointer;

	@include mq(tablet) {
		top: 16px;
		right: 70px;
	}

	@include mq(print) {
		display: none;
	}
}