/* m-basic-list--social */
.m-basic-list--social {
	li {
		height: 30px;
		margin: 0 0 10px 0;
		padding: 0;
		background: 0;

		&:before {
			background: none;
		}

		> a {
			display: block;
			padding: 6px 0 6px 40px;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		&:first-child > a {
			background: url(/dev/img/social/facebook.png) no-repeat;
		}

		&:nth-child(2) > a {
			background: url(/dev/img/social/twitter.png) no-repeat;
		}

		&:last-child > a {
			background: url(/dev/img/social/youtube.png) no-repeat;
			margin: 0;
		}
	}
}