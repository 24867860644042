/* a-basic-input-select--s */
.a-basic-input-select--s {

	width: $form-width-s;

	.o-layout-main-stage--search & {
		width: 120px;

		@include mq(tablet) {
			width: 180px;
		}
	}

	// exception .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration .mm-upload-container > .mm-row > .mm-row-content &,
	.m-ves-rs-contentbox--damagedeclaration .mm-tile-container > .mm-row > .mm-row-content & {
		width: 100px;
	}
}