// .m-basic-info-layer
.m-basic-info-layer-tab {
	display: inline;

	> .mm-infolayer-icon {
		position: relative;
		padding-left: 30px;
		text-decoration: none;
		display: inline-block;
		background: none;
		bottom: 0;
		right: 0;
		margin-top: 10px;
		width: auto;
		height: 18px;
		cursor: pointer;

		&:after {
			content: '';
			position: absolute;
			left: -10px;
			top: -10px;
			@include main-icon-info;
		}

		&.active {
			&:after {
				@include main-icon-info-yellow;
			}
		}

		.is-keyboard-focus &:focus {
			outline: none;
			border: none;

			&:before {
				content: '';
				position: absolute;
				left: -3px;
				top: -3px;
				border: 2px solid $color-primary-3;
				width: 26px;
				height: 26px;
				border-radius: 50%;

			}
		}
	}

	> .mm-infolayer-content {
		display: none;
	}
}