.m-basic-toggle--flex {
	border-bottom: 1px solid #fff;

	> .mm-head {
		&.mm-opened + .mm-content {
			padding-bottom: 10px;
		}
	}

	> .mm-content {
		display: inline-flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
		flex: 0 0 20%;

		> .a-basic-link {
			width: calc(100% / 2);
			margin-bottom: 3px;
			margin-right: 30px;

			@include mq(tablet) {
				width: calc(100% / 5);
			}
		}
	}
}