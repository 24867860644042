/* .m-my-adac-membership-content*/
.m-my-adac-membership-content {

	> .mm-wrapper {
		max-width: 650px;
		margin: 0 auto;

		@include if-parent('.m-my-adac-membership-content.m-my-adac-membership-content--wide') {
			max-width: 700px;
		}

		@include if-parent('.m-my-adac-membership-content.m-my-adac-membership-content--align-left') {
			margin: 0;
		}

		> .mm-content-smaller-width {
			max-width: 370px;
			margin: 0 auto;
		}

		// dont add child-selector here
		.mm-content-button-wrapper {
			display: flex;
			justify-content: center;
		}
	}
}