/* a-basic-btn--inline */
.a-basic-btn--mobile-hidden {
	display: none;

	.m-basic-keywordfilter--mobilelayer & {
		display: inline-block;
		position: fixed;
		bottom: 20px;
		width: calc(100% - 40px);
		z-index: 2500;
		max-width: none;
		text-align: center;

		@include mq('tablet') {
			position: static;
			width: auto;
		}
	}

	@include mq('tablet') {
		display: inline-block;
	}
}