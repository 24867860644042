/* .a-basic-btn--outline-white */
.a-basic-btn--outline-white {
	background-color: $color-primary-2;
	$vert-offset: 2px;
	border: $vert-offset solid rgba(0, 0, 0, 0.42);

	&:focus,
	&:active {
		background-color: $color-primary-2;
		border: $vert-offset solid $color-secondary-22 !important;
	}
}