.m-basic-table-restyle {
	.mm-dashbox-theme & {
		max-height: 340px;
		overflow-y: auto;
	}

	> .mm-table {
		width: 100%;

		thead {
			> tr {
				border-bottom: 1px solid $color-secondary-17;

				.m-my-adac-dashboard-box-showcase .mm-content & {
					border-bottom: none;
				}

				> th {
					padding: 20px 5px;
					text-align: left;
					vertical-align: middle;

					@include mq('tablet') {
						padding: 20px 25px;
					}

					&.is-delete {
						width: 20px;
					}

					> .mm-sort-flex {
						display: flex;
						align-items: center;

						> .js-sort {
							transform: rotate(90deg);
							width: 8px;
							height: 16px;
							display: block;
							cursor: pointer;
							margin-left: 10px;
							position: relative;

							&:after {
								content: '';
								position: absolute;
								left: -18px;
								top: -14px;
								@include main-icon-arrow-right-black;
							}

							&--active {
								transform: rotate(-90deg);
							}
						}
					}
				}
			}
		}

		tbody {
			tr {
				border-bottom: 1px solid $color-secondary-16;

				&.mm-result {
					@include milo-bold();
				}

				&.is-filter {

					&.is-filter-hidden {
						display: none;
					}
				}

				> td {
					padding: 20px 5px;
					vertical-align: top;

					@include mq('tablet') {
						padding: 20px 25px;
						min-width: 140px;
					}

					.mm-dashbox-theme & {
						padding: 10px 5px;

						@include mq('tablet') {
							padding: 10px 5px;
							min-width: 0;
						}

						&:last-child {
							width: 85px;
						}
					}

					&.is-delete {
						width: 20px;

						@include mq('tablet') {
							width: 20px;
							min-width: 0;
						}
					}

					> .mm-flex-box {
						display: flex;
						flex-direction: column;

						@include mq('tablet') {
							flex-direction: row;
							align-items: center;
						}

						.mm-dashbox-theme & {
							@include mq('tablet') {
								flex-direction: column;
								align-items: flex-start;
							}
						}

						> span {
							margin-right: 15px;
						}

						> .mm-digits {
							@extend %display-as-digits;
						}
					}
				}
			}
		}
	}

	> .mm-row-btn {
		border-top: 1px solid $color-secondary-16;
		text-align: center;
		padding: 20px 10px;

		&--borderless {
			border: none;
			margin-top: 20px;

			> button {
				display: inline;
			}
		}
	}
}