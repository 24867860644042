.a-my-adac-breaker {
	width: 100%;

	.l-outer--my-adac & {
		margin: 0 0 24px 0;
	}

	&--grey {
		border-bottom: 1px solid $color-secondary-9;
	}

	&--black {
		border-bottom: 1px solid $color-primary-3;
	}

	&.h-space-my-adac-s {
		margin: 0 0 14px 0;
	}

	// exception for promotion boxes
	.mm-box--promotion & {
		margin: 0;
	}
}