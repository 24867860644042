/* .m-layout-footer-dark */
.m-layout-footer-dark {
	width: 100%;
	height: auto;
	max-width: 1440px;
	background: $color-footer-background;
	margin: 0 auto;
	padding: 24px;

	> .mm-wrapper {
		display: flex;
		flex-flow: column nowrap;
		height: auto;
		max-width: 580px;
		margin: 0 auto;

		@include mq(desktop) {
			max-width: 100%;
		}

		> .mm-footer-link-list {
			display: flex;
			flex-flow: column nowrap;
			justify-content: space-between;
			align-content: stretch;
			width: 100%;
			padding: 0;
			margin-bottom: 4px;

			@include mq(desktop) {
				flex-flow: row nowrap;
			}

			> .mm-footer-list {
				flex: 1 1 auto;

				@include mq(desktop) {
					margin-right: 8px;
				}

				&:last-child {
					flex: 1 1 auto;

					@include mq(tablet) {
						flex: 0 1 20%;
					}

					@include mq(desktop) {
						margin-right: 0;
					}
				}

				> .mm-footer-list-headline {
					color: $color-primary-1;
					cursor: pointer;
					max-width: 100%;
					padding: 16px;
					margin: 0;
					position: relative;
					@include milo-bold(12px, 16px);
					letter-spacing: 1.85px;

					@include mq(desktop) {
						padding: 16px;
						cursor: default;
					}

					&:focus-visible,
					&:focus {
						outline: none;
					}

					.is-keyboard-focus &:focus-visible,
					.is-keyboard-focus &:focus {
						&:before {
							content: '';
							position: absolute;
							top: 10px;
							right: 16px;
							width: 33px;
							height: 31px;
							border: 2px solid $color-primary-2;
							border-radius: 4px;

							@include mq(desktop) {
								display: none;
							}
						}
					}

					&:after {
						@include absolute($top: 15px, $right: 16px);
						content: '';
						display: inline-block;
						@include main-icon-arrow-yellow;
						width: 33px;
						height: 31px;

						@include if-parent('.mm-footer-list.is-active') {
							transform: rotate(-180deg);
							top: 8px;
						}

						@include mq(desktop) {
							display: none;
						}
					}
				}

				> .mm-footer-link-wrapper {
					display: block;
					overflow: hidden;
					max-height: 0;
					transition: max-height 0.3s ease-out;

					@include if-parent('.mm-footer-list.is-active') {
						transition: max-height 0.3s ease-out;
						margin: 0 0 16px 0;
					}

					@include mq(desktop) {
						overflow: visible;
						max-height: 100%;
					}

					> .mm-footer-link-normal {
						padding: 0;
						margin: 0;

						&:not(:last-child) {
							margin-bottom: 24px;
						}

						> .mm-footer-link {
							padding: 8px 16px;

							&:hover {
								border-radius: 4px;
								background-color: $color-secondary-18;
							}

							> .mm-link {
								@extend %focus-outline-inverse;
								@include milo-bold(16px, 20px);
								text-decoration: none;
								color: $color-footer-white;

								&:hover {
									text-decoration: none;
								}

								&:visited {
									outline: none;
									text-decoration: none;
									color: $color-primary-2;
								}

								&:active {
									outline: none;
									text-decoration: none;
									color: $color-primary-2;
								}

								&--social {
									display: flex;
									flex-flow: row nowrap;
									align-items: center;
									position: relative;
								}

								> .mm-social-icon {
									width: 24px;
									height: 24px;
									margin: 0 8px 0 0;
									position: relative;

									&:before {
										content: '';
										position: absolute;
										top: -10px;
										left: -10px;
									}

									&--community {
										&:before {
											@include social-media-icon-community;
										}
									}

									&--facebook {
										&:before {
											@include social-media-icon-facebook;
										}
									}

									&--wordpress {
										&:before {
											@include social-media-icon-wordpress;
										}
									}

									&--youtube {
										&:before {
											@include social-media-icon-youtube;
										}
									}

									&--twitter {
										&:before {
											@include social-media-icon-twitter;
										}
									}

									&--instagram {
										&:before {
											@include social-media-icon-instagram;
										}
									}

									&--pinterest {
										&:before {
											@include social-media-icon-pinterest-white;
										}
									}
								}

								> .mm-social-name {
									flex: 0 0 calc(100% - 32px);
									// need for calc
									flex-basis: calc(100% - 32px);
								}
							}
						}
					}

					> .mm-footer-link-overall {
						padding: 0;
						margin: 0;

						@include mq(desktop) {
							margin: 0;
						}

						> .mm-footer-link {
							padding: 8px 16px;

							&:hover {
								border-radius: 4px;
								background-color: rgba($color-primary-2, .1);
							}

							> .mm-link {
								@extend %focus-outline-inverse;
								@include milo-bold(16px, 20px);
								text-decoration: none;
								color: $color-footer-white;

								&:hover {
									text-decoration: none;
								}

								&:visited {
									outline: none;
									text-decoration: none;
									color: $color-primary-2;
								}

								&:active {
									outline: none;
									text-decoration: none;
									color: $color-primary-2;
								}
							}
						}
					}
				}

				> .mm-footer-advertising {
					display: flex;
					flex-flow: row nowrap;
					overflow: hidden;
					max-height: 0;
					margin-top: -8px;

					@include mq(desktop) {
						flex-flow: column nowrap;
						max-height: 100%;
					}

					&:hover {
						border-radius: 4px;
						background-color: $color-secondary-18;
					}

					> .mm-img-wrapper {
						display: flex;
						justify-content: flex-start;
						flex: 0 1 100%;
						margin-right: 4px;
						padding: 8px 16px;
						transition: max-height 0.3s ease-out;

						@include if-parent('.mm-footer-list.is-active .mm-footer-advertising') {
							padding: 5px 16px;
						}

						@include mq(desktop) {
							margin-right: 0;
							flex: 0 1 100%;
						}

						> .mm-link {
							@extend %focus-outline-inverse;
							text-decoration: none;
							border: 2px solid transparent;
							margin-left: -8px;
							display: flex;

							@include mq(tablet) {
								flex-direction: column;
							}

							&:hover {
								border-radius: 4px;
							}

							&:visited {
								outline: none;
							}

							&:active {
								outline: none;
							}

							> img {
								max-width: 100%;
								height: fit-content;
								width: 35%;

								@include mq(tablet) {
									width: initial;
								}
							}

							> .mm-text-wrapper {
								display: flex;
								flex-direction: column;
								justify-content: center;
								color: $color-footer-white;
								@extend %focus-border-inverse;
								padding-left: 7px;

								@include mq(tablet) {
									max-width: 136px;
									display: initial;
								}

								&:visited {
									color: $color-primary-2;
								}

								&:active {
									color: $color-primary-2;
								}
							}
						}
					}
				}
			}
		}

		> .mm-footer-menu-wrapper {
			display: flex;
			flex-flow: column nowrap;
			border-top: 1px solid $color-footer-border;

			@include mq(desktop) {
				flex-flow: row nowrap;
				height: auto;
				padding: 16px 0;
			}

			&:only-child {
				border-top: none;
			}

			> .mm-footer-brand {
				flex: 1 1 100%;
				display: flex;
				flex-flow: column nowrap;
				align-items: center;
				padding: 32px;

				@include mq(desktop) {
					flex: 0 0 180px;
					flex-flow: row nowrap;
					padding: 0;
					border-top: none;
				}

				> .mm-footer-brand-text {
					color: $color-primary-2;

					> .mm-text {
						margin: 0;
						// loop design
						opacity: 0.9;
					}
				}

				> .mm-footer-brand-logo {
					margin: 0 0 16px 0;

					@include mq(desktop) {
						margin: 0 20px 0 0;
						height: 45px;
					}

					> img {
						max-width: 65px;

						@include mq(desktop) {
							max-width: 45px;
						}
					}
				}
			}

			> .mm-footer-menu {
				flex: 1 1 100%;
				border-top: 1px solid $color-footer-border;

				@include mq(desktop) {
					flex: 0 0 calc(100% - 180px);
					// flex-basis is needed for calc
					flex-basis: calc(100% - 180px);
					border-top: none;
				}

				> .mm-footer-menu-links {
					display: flex;
					flex-flow: row wrap;
					justify-content: space-between;
					align-items: center;
					height: 100%;
					padding: 12px 0 0 0;
					margin: 0;

					@include mq(desktop) {
						justify-content: flex-end;
						flex-wrap: nowrap;
					}

					> .mm-footer-link {
						padding: 16px;

						@include mq(desktop) {
							padding: 8px 16px;
							margin: 0 24px;
						}

						&:hover {
							border-radius: 4px;
							background-color: rgba($color-primary-2, .1);
						}

						&:first-child {
							margin-left: 0;
						}

						&:last-child {
							margin-right: 0;
						}

						> .mm-link {
							color: $color-footer-white;
							text-decoration: none;
							@include milo-bold(16px, 20px);
							@extend %focus-outline-inverse;
							border: 2px solid transparent;

							&:hover {
								text-decoration: none;
							}

							&:visited {
								color: $color-primary-2;
							}

							&:active {
								color: $color-primary-2;
							}
						}
					}
				}
			}
		}
	}
}