/* o-layout-header-metanav--brs */
.o-layout-header-metanav--brs {

	.oo-list {

		> li {
			&.oo-login {
				@include mq(tablet) {
					padding-right: 12px;
				}
			}

			&:nth-child(3) {
				@include mq(tablet) {
					padding-right: 12px;
				}
			}

			&:nth-child(5) {
				@include mq(tablet) {
					display: block;
				}
			}
		}
	}

	.oo-loggedin {
		@include if-parent('.o-layout-header-metanav--brs.o-layout-header-metanav--loggedin.has-loggedin-layer-visible') {
			display: block !important;

			@include mq(tablet) {
				right: -2px;
				padding: 60px 15px 24px 15px;
			}
		}


		@include if-parent('.o-layout-header-metanav--brs.o-layout-header-metanav--loggedin.has-navi-opened') {
			@include mq(tablet) {
				padding: 20px 15px 44px 15px;
			}

			@include mq(desktop) {
				padding: 30px 15px 51px 15px;
			}
		}
	}
}