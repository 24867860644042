/* a-basic-input-select--col-1-2 */
.a-basic-input-select--col-1-2 {

	display: inline-block;
	width: calc(100% - #{$form-gutter});
	margin-top: 10px;

	@include mq(tablet) {
		margin-top: 0;
		margin-right: $form-gutter;
		width: calc(50% - #{$form-gutter});
	}

	.l-form-general & {
		margin-top: 0;
	}

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-right: 0;
	}

	> .aa-controller {
		top: auto;
		bottom: -3px;
	}

	.l-form--cardamage-map & {

		max-width: 150px;
		width: 140px;
		margin-right: calc(100% - 140px);

		@include mq(tablet) {
			width: calc(50% - #{$form-gutter});
			margin-right: $form-gutter;
		}
	}
}