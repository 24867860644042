/* a-basic-btn--upload */
.a-basic-btn--upload {

	position: relative;

	&:before {
		content: '';
		@include absolute($top: -4px, $right: -48px);
		@include damage-declaration-icon-camera;

		@include mq(desktop) {
			display: none;
			content: none;
		}
	}

	&.is-active {
		&:before {
			@include absolute($top: 6px, $right: -48px);
		}
	}
}