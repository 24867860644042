// animated bell for Dashboard notifications
@keyframes a0_t {
	0% {
		transform: translate(76.3px, -10.8px) rotate(0deg) translate(-15.2px, 148.6px);
	}

	2.5% {
		transform: translate(76.3px, -10.8px) rotate(0deg) translate(-15.2px, 148.6px);
		animation-timing-function: cubic-bezier(.4, 0, .6, 1);
	}

	13.35% {
		transform: translate(76.3px, -10.8px) rotate(20deg) translate(-15.2px, 148.6px);
		animation-timing-function: cubic-bezier(.4, 0, .6, 1);
	}

	22.5% {
		transform: translate(76.3px, -10.8px) rotate(-15deg) translate(-15.2px, 148.6px);
		animation-timing-function: cubic-bezier(.4, 0, .6, 1);
	}

	30% {
		transform: translate(76.3px, -10.8px) rotate(7deg) translate(-15.2px, 148.6px);
		animation-timing-function: cubic-bezier(.4, 0, .6, 1);
	}

	35% {
		transform: translate(76.3px, -10.8px) rotate(-4deg) translate(-15.2px, 148.6px);
		animation-timing-function: cubic-bezier(.4, 0, .6, 1);
	}

	40.85% {
		transform: translate(76.3px, -10.8px) rotate(3deg) translate(-15.2px, 148.6px);
		animation-timing-function: cubic-bezier(.4, 0, .6, 1);
	}

	46.65% {
		transform: translate(76.3px, -10.8px) rotate(-2deg) translate(-15.2px, 148.6px);
		animation-timing-function: cubic-bezier(.4, 0, .6, 1);
	}

	51.65% {
		transform: translate(76.3px, -10.8px) rotate(2deg) translate(-15.2px, 148.6px);
		animation-timing-function: cubic-bezier(.4, 0, .6, 1);
	}

	62.5% {
		transform: translate(76.3px, -10.8px) rotate(0deg) translate(-15.2px, 148.6px);
	}

	100% {
		transform: translate(76.3px, -10.8px) rotate(0deg) translate(-15.2px, 148.6px);
	}
}

@keyframes a1_t {
	0% {
		transform: translate(76.8px, -10.1px) rotate(0deg) translate(-1px, 88px);
		animation-timing-function: cubic-bezier(.4, 0, .6, 1);
	}

	10.85% {
		transform: translate(76.8px, -10.1px) rotate(20deg) translate(-1px, 88px);
		animation-timing-function: cubic-bezier(.4, 0, .6, 1);
	}

	20% {
		transform: translate(76.8px, -10.1px) rotate(-15deg) translate(-1px, 88px);
		animation-timing-function: cubic-bezier(.4, 0, .6, 1);
	}

	27.5% {
		transform: translate(76.8px, -10.1px) rotate(7deg) translate(-1px, 88px);
		animation-timing-function: cubic-bezier(.4, 0, .6, 1);
	}

	32.5% {
		transform: translate(76.8px, -10.1px) rotate(-4deg) translate(-1px, 88px);
		animation-timing-function: cubic-bezier(.4, 0, .6, 1);
	}

	38.35% {
		transform: translate(76.8px, -10.1px) rotate(3deg) translate(-1px, 88px);
		animation-timing-function: cubic-bezier(.4, 0, .6, 1);
	}

	44.15% {
		transform: translate(76.8px, -10.1px) rotate(-2deg) translate(-1px, 88px);
		animation-timing-function: cubic-bezier(.4, 0, .6, 1);
	}

	49.15% {
		transform: translate(76.8px, -10.1px) rotate(2deg) translate(-1px, 88px);
		animation-timing-function: cubic-bezier(.4, 0, .6, 1);
	}

	60% {
		transform: translate(76.8px, -10.1px) rotate(0deg) translate(-1px, 88px);
	}

	100% {
		transform: translate(76.8px, -10.1px) rotate(0deg) translate(-1px, 88px);
	}
}