/* m-basic-big-link */
.m-basic-big-link {
	position: relative;

	> .mm-content {
		position: relative;
		display: flex;
		padding: 16px;
		align-items: center;
		border: 1px solid $color-secondary-16;
		border-radius: 8px;
		background-color: $color-primary-2;
		text-decoration: none;

		&:hover {
			background-color: $color-secondary-6;

			@include if-parent('.m-basic-big-link.m-basic-big-link--btn') {
				background: transparent;
			}
		}

		@include mq(tablet) {
			padding: 24px 16px 24px 24px;
		}

		@include if-parent('.m-basic-big-link.m-basic-big-link--btn') {
			align-content: center;
			flex-wrap: wrap;

			@include mq(tablet) {
				flex-wrap: initial;
			}
		}

		@include if-parent('.m-basic-big-link:not(.m-basic-big-link--btn)') {
			&::before {
				content: '';
				display: inline-block;
				@include main-icon-arrow-right-grey;
				position: absolute;
				right: 0;
			}
		}

		> .mm-icon {
			margin-right: 15px;
			width: 48px;
			height: 48px;
			background-color: $color-primary-1;
			border-radius: 50%;
			overflow: hidden;
			flex: 0 0 48px;

			@include if-parent('.m-basic-big-link.m-basic-big-link--btn') {
				align-self: flex-start;

				@include mq(tablet) {
					align-self: initial;
				}
			}

			&--no-background {
				background-color: initial;
			}

			> img {
				left: 50%;
				top: 50%;
				position: relative;
				transform: translate(-50%, -50%);
			}
		}

		> .mm-info {
			display: flex;
			flex-direction: column;
			margin-right: 15px;
			width: calc(100% - 63px);

			@include if-parent('.m-basic-big-link.m-basic-big-link--btn') {
				margin-right: 0;
			}

			> .mm-notice {
				color: $color-secondary-21;
				line-height: 1.2;
				margin-top: 5px;
				margin-bottom: 8px;

				@include mq(tablet) {
					margin-top: 12px;
					margin-bottom: 0;
				}

				@include if-parent('.m-basic-big-link.m-basic-big-link--btn') {
					margin-bottom: 0;
				}
			}

			> .mm-headline {
				@include milo-bold();
				padding-bottom: 0;
				margin-bottom: 0;
				line-height: 1.2;
				margin-top: 8px;

				@include mq(tablet) {
					margin-top: 0;
				}

				@include if-parent('.m-basic-big-link.m-basic-big-link--btn') {
					margin-top: 0;
				}
			}
		}

		.mm-button {
			text-decoration: none;
			width: fit-content;
			margin-top: 16px;
			margin-left: 63px;

			@include mq(tablet) {
				margin-top: 0;
				min-width: fit-content;
			}

			&--icon {
				position: relative;
				padding-left: 52px;
			}

			> img {
				position: absolute;
				bottom: 6px;
				left: 20px;
			}
		}
	}
}