/* a-basic-stopper--small */
.a-basic-stopper--small {

	.aa-inner-content {
		margin-left: 5px;
		min-width: 40px;

		.aa-content {
			font-weight: normal;
			@include font-size(14px);
		}
	}
}