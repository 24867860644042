// m-basic-skip-links
.m-basic-skip-links {
	display: block;
	position: relative;
	z-index: 1100;
	background: $color-primary-2;
	opacity: 0;
	pointer-events: none;
	height: 0;
	overflow: hidden;

	&:has(a:focus) {
		opacity: 1;
		pointer-events: all;
		height: auto;
		padding: 5px 20px;
	}

	> ul {
		display: flex;
		column-gap: 30px;
		margin: 0;
		padding: 0;
	}
}