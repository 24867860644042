// .m-basic-radius-search
.m-basic-radius-search {

	.ll-row.ll-row--ds & {
		width: 100%;
	}

	.l-main-content--background & {
		margin-left: -5px;
	}

	> .l-form,
	.l-form-general {

		> .l-main-cols {

			display: flex;
			justify-content: flex-end;
			flex-flow: row wrap;
			align-items: flex-end;

			@include mq(tablet) {
				justify-content: flex-start;
				flex-flow: row nowrap;
			}

			&--left {
				justify-content: flex-start;
			}

			> .ll-fieldset {

				margin: 0 10px 0 0;

				> .ll-row {
					max-width: 100%;
					margin-bottom: 0;

					> .ll-col-label {
						float: none;
						width: auto;
						margin-bottom: 5px;

						@include if-parent('.l-form-general') {
							margin-bottom: 0;
						}
					}

					> .ll-col-data {
						width: 100%;
						max-width: none;
						float: none
					}
				}

				&:first-of-type {
					flex: 1 1 50%;

					> .ll-row {
						> .ll-col-label {
							padding-left: 30px;

							@include if-parent('.l-form-general') {
								padding-left: 2px;
							}
						}
					}
				}

				&:last-of-type {
					flex: 0 0 110px;
					margin-right: 0;

					@include mq(tablet) {
						flex: 0 1 20%;
					}

					&:first-of-type {
						flex: 0 1 50%;
					}
				}
			}
		}
	}

	> .l-form-general {
		> .l-main-cols {
			> .ll-fieldset {
				&:first-of-type {
					flex: 1 1 40%;

					@include mq(tablet) {
						flex: 1 1 50%;
					}
				}

				&:last-of-type {
					flex: 0 0 125px;

					@include mq(tablet) {
						flex: 0 1 20%;
					}
				}
			}

			> .a-basic-btn {
				margin-right: 19px;
				margin-top: 15px;

				@include mq(tablet) {
					margin-right: 0;
					margin-top: 0;
				}
			}
		}
	}
}