/* m-basic-table */
.m-basic-table {
	position: relative;
	width: 100%;
	min-width: 0;

	.swiper-container-wrap & {
		&:after {
			display: none;
		}
	}

	.mm-toggle-row-content & {
		&:after {
			border-radius: 0;
		}
	}

	@include mq(print) {
		page-break-inside: avoid;
	}

	&.h-space-s,
	&.h-space-m,
	&.h-space-l {
		.ll-main-full & {
			margin-bottom: 0;
		}
	}

	&.special-space {
		margin-bottom: 15px;
	}

	&.is-cloned-header {
		position: relative;
		margin: 0 0 -1px 0;
		z-index: 40;

		&.is-sticky-header {
			min-width: 0;
		}
	}

	&.is-cloned {
		position: relative;
		margin: 0 0 -1px 0;
		z-index: 40;
	}

	&.js-weather-clone {
		> tbody {

			> tr {

				> td {
					padding: 3px;

					&:first-child {
						padding: 10px;
					}

					@include mq(tablet) {
						padding: 10px;
					}

					> img {
						max-height: 45px;
					}
				}
			}
		}
	}

	& .js-sort {
		display: none;
		cursor: pointer;

		@include mq(tablet) {
			@include main-icon-sort-down;
			display: inline-block;
			position: absolute;
			top: 4px;
			margin-left: -6px;

			@include if-parent('.m-basic-table--compare-result') {
				right: initial;
			}
		}

		&--active {
			@include main-icon-sort-up;
		}
	}

	// sticky mode
	&.is-orig {
		position: relative;
	}

	// switch map (m-adac-maps--sticky) helper
	&.is-hidden {
		display: none;
	}

	a {
		position: relative;
		z-index: 1;
	}

	// general styles for thead and tbody
	//> thead > tr,
	> tbody > tr {
		position: relative;

		&:not(:last-child) {
			border-bottom: 1px solid $color-secondary-16;
		}

		// exception --compare
		@include if-parent('--compare') {
			&:nth-last-child(3) {
				border-bottom: none;
			}
		}

		@include if-parent('--striped') {
			border: none;
		}

		&:nth-child(odd):not(.mm-subheading) {
			&.js-white {
				background-color: transparent;

				> td {
					background-color: transparent;
				}
			}

			td {
				@include if-parent('--striped') {
					background-color: $color-secondary-8;

					@include mq(print) {
						box-shadow: inset 0 0 0 1000px $color-secondary-6;
					}
				}
			}
		}

		&:nth-child(even):not(.mm-subheading) {
			td {
				@include if-parent('--striped') {
					background: $color-primary-2;
				}
			}

		}

		&:last-child {
			> td {
				&:first-child {
					border-bottom-left-radius: 10px;
				}

				&:last-child {
					border-bottom-right-radius: 10px;
				}
			}
		}

		@include mq(print) {
			page-break-before: avoid;
			page-break-after: avoid;
			page-break-inside: avoid;
		}

		> td {
			padding: 15px;
			text-align: left;
			vertical-align: top;
			position: relative;
			hyphens: auto;

			@include mq(tablet) {
				padding: 15px 30px;
				min-width: 140px;
			}

			@include mq(print) {
				page-break-before: avoid;
				page-break-after: avoid;
				page-break-inside: avoid;
			}

			&.phone-hide {
				display: none;

				@include mq(tablet) {
					display: table-cell;
				}
			}

			&:first-child {
				@include mq(tablet) {
					padding-left: 0;
				}

				@include if-parent('--striped') {
					@include mq(tablet) {
						padding-left: 30px;
					}
				}
			}

			&:last-child {
				@include mq(tablet) {
					padding-right: 0;
				}

				@include if-parent('--striped') {
					@include mq(tablet) {
						padding-right: 40px;
					}
				}
			}

			&.mm-tac {
				text-align: center;
			}

			&.mm-vam {
				vertical-align: middle;
			}

			&.mm-vab {
				vertical-align: bottom;
			}

			&.mm-bg-light {
				background: $color-secondary-8;
			}

			&.mm-bg-dark {
				background: $color-secondary-10;
			}

			> .mm-stopper-wrap {
				position: relative;
				margin: 0 0 7px 0;

				@include mq(tablet) {
					margin-bottom: 0;
				}
			}

			.mm-flex-content {
				display: flex;

				> * {
					&:not(:last-child) {
						display: block;
						margin-right: 10px;
					}
				}
			}

			.mm-extra {
				font-weight: normal;
				display: block;
			}

			p:last-child,
			ul:last-child {
				margin-bottom: 0;

				li:last-child {
					margin-bottom: 0;
				}
			}

			// need for sticky mode
			.m-basic-table-wrap & {
				position: relative;
			}

			.mm-phone-strong {
				@include milo-bold();

				.h-roboto & {
					font-family: 'Roboto Bold', sans-serif;
				}
			}

			.mm-phone-small {
				@include font-size($font-size-12);

				@include mq(tablet) {
					@include font-size($font-size-15);
				}
			}

			.mm-tablet-strong {
				@include mq(tablet) {
					@include milo-bold();

					.h-roboto & {
						font-family: 'Roboto Bold', sans-serif;
					}
				}
			}

			.mm-tablet-big {
				@include font-size($font-size-14);

				@include mq(tablet) {
					@include font-size($font-size-18);
				}
			}

			// display output as digits
			.mm-digits {
				@extend %display-as-digits;
			}

			> img {
				float: left;
				margin-right: 10px;

				@include if-parent('.m-basic-table--compare') {
					float: initial;
					margin-right: initial;

					@include mq(tablet) {
						float: left;
						margin-right: 10px;
					}
				}

				&.mm-fixed-width {

					&--120 {
						min-width: 120px;
						max-width: 120px;
					}
				}
			}
		}

		.mm-nobreak {
			@include mq(tablet) {
				white-space: nowrap;
			}
		}

		.mm-normal {
			font-weight: normal;
		}

		&.mm-subheading {

			&--strong {
				> td {
					@include milo-bold();

					.h-roboto & {
						font-family: 'Roboto Bold', sans-serif;
					}
				}
			}

			&.phone-hide {
				display: none;

				@include mq(tablet) {
					display: table-row;
				}
			}

			&.phone-only {
				display: table-cell;

				@include mq(tablet) {
					display: none;
				}
			}

			a.js-lightbox {
				text-decoration: none;
			}

			@include mq(tablet) {
				a.js-lightbox {
					text-decoration: underline;
				}
			}

			> td {
				@include mq(tablet) {
					border-top: 0;
				}

				.js-lightbox {
					@include milo-bold();

					.h-roboto & {
						font-family: 'Roboto Bold', sans-serif;
					}
				}
			}
		}

		&.mm-sum {
			background: $color-primary-2;
			border-bottom: 1px solid $color-secondary-3;
			border-top: 3px double $color-secondary-3;

			td {
				border-left: 0 !important;
				border-right: 0;
			}
		}

		&.is-highlight {
			background-color: $color-primary-1;
			border-bottom-color: $color-secondary-3;

			> td {
				border-top: 1px solid $color-secondary-3;
				border-bottom: none;
			}
		}
	}

	// thead only styles
	> thead {
		border-bottom: 1px solid $color-secondary-16;

		@include if-parent('.m-basic-table.is-cloned-header') {
			background: $color-primary-2;
		}

		> tr {

			> th {
				position: relative;
				height: 45px;
				background: transparent;
				@include milo-bold();
				padding: 15px;
				text-align: left;
				min-width: 100px;

				@include mq(tablet) {
					padding: 15px 30px;
					min-width: 140px;
				}

				&:first-child {
					@include mq(tablet) {
						padding-left: 0;
					}

					@include if-parent('--striped') {
						@include mq(tablet) {
							padding-left: 30px;
						}
					}
				}

				.h-roboto & {
					font-family: 'Roboto Bold', sans-serif;
				}

				@include mq(print) {
					box-shadow: inset 0 0 0 1000px $color-primary-1;
				}

				&.is-clickable {
					cursor: pointer;
					position: relative;
					z-index: 10;
					white-space: nowrap;
				}

				> .mm-subhead {
					display: block;
					@include milo-regular();

					.h-roboto & {
						font-family: 'Roboto', sans-serif;
					}
				}
			}
		}
	}

	> tfoot {
		> tr {
			> td {
				padding-top: 8px;
				font-size: 13px;
			}
		}
	}

	// exception for m-basic-table--ruf-weather
	&--ruf-weather {
		width: 100%;

		> thead {
			> tr {
				> th {
					min-width: 0;
					padding: 15px 10px;

					&:first-child {
						@include mq(tablet) {
							padding-left: 20px;
						}
					}

					&:last-child {
						@include mq(tablet) {
							padding-right: 20px;
						}
					}

					> img {
						max-height: 45px;
					}
				}
			}
		}

		> tbody {
			> tr {
				background-color: transparent;

				&:not(:last-child) {
					border-bottom: 1px solid $color-secondary-16;
				}

				> td {
					min-width: 0;
					padding: 15px 10px;

					&:first-child {
						@include mq(tablet) {
							padding-left: 20px;
						}
					}

					&:last-child {
						@include mq(tablet) {
							padding-right: 20px;
						}
					}

					> img {
						max-height: 60px;
					}
				}

				.mm-toggle-row-content {
					> table {
						> tbody {
							> tr {
								background-color: transparent;

								> td {
									width: 50%;

									> img {
										width: 55px;
										height: auto;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// exception for m-basic-table--ruf-climate
	&--ruf-climate {

		> thead {
			> tr {
				> th {
					min-width: 0;
					padding: 15px 10px;
				}
			}
		}

		> tbody {

			> tr {

				> td {
					min-width: 0;
					padding: 15px 10px;
					padding-bottom: 5px;
				}
			}
		}
	}

	// exception for component basictablerating
	&--rating {

		> thead {
			> tr {
				> th {
					min-width: 0;
					padding: 15px 20px;
				}
			}
		}

		> tbody {

			> tr {

				> td {
					min-width: 0;
					padding: 15px 20px;

					&:nth-child(2) {
						min-width: 10px !important;
					}

					&:first-child {

						> .m-image-text-wrapper {
							display: flex;
							justify-content: flex-start;
							flex-flow: row nowrap;

							> .m-basic-text {
								margin-left: 10px;

								> h4 {
									padding: 0;
									word-break: break-all;
								}

								> .mm-content {
									margin-bottom: 0;
								}
							}
						}
					}

					.mm-location-distance {
						margin-bottom: 5px;
					}

					.mm-location-icon {
						display: block;
						@include main-icon-adac-location-22px;
						width: 20px;
						height: 20px;
						margin-bottom: 2px;
						cursor: pointer;
					}

					> .cf.m-basic-text {

						> .mm-content {
							font-weight: 300;
						}
					}

					> .mm-toggle-row-content.cf {
						width: 100%;
					}
				}
			}
		}
	}

	// exception for component basicteaserdashboard
	.m-basic-teaser-dashboard & {
		img {
			vertical-align: top;

			~ .a-basic-link {
				margin-left: 10px;
			}
		}
	}

	// Exception :: in sticky wrap
	.swiper-container-sticky & {
		margin-bottom: 0;
	}

	// Exception :: ll-fieldset
	.l-form .ll-fieldset & {
		@include mq(tablet) {
			margin-left: 0;
			min-width: 100%;
			width: 100%;
		}
	}

	// Exception :: ll-main-full
	.ll-main-full > & {
		margin: 0;
	}

	// exception for mm-tablesrc
	&.has-src {
		margin-bottom: 10px;
	}

	& + .mm-tablesrc {
		@include font-size($font-size-12 );
		text-align: right;

		> img {
			margin-left: 10px;
			vertical-align: middle;
		}
	}

	// exception for lightbox
	&--lightbox {

		> thead {

			> tr {

				> th {
					width: 50%;
				}
			}
		}

		> tbody {

			> tr {

				> td {

					&:last-child {
						text-align: center;
					}
				}

				&.is-odd,
				&:nth-child(odd) {
					background-color: $color-primary-2;
				}

				&.is-even,
				&:nth-child(even) {
					background-color: $color-secondary-10 !important;
				}
			}
		}
	}

	// exception for m-basic-table--vertical-center
	&--vertical-center {

		> thead,
		> tbody {
			> tr {

				> th,
				> td {
					@include mq(tablet) {
						vertical-align: middle;
					}
				}
			}
		}
	}
}

.js-basic-table-wrap {
	position: relative;
	width: 100%;
	margin: -41px 0 0 0;
	padding-top: 41px;

	.js-main-border.is-nopadding & {
		border-right: none;
		padding-left: 0;
		padding-right: 0;
	}

	&.is-loading {
		position: relative;
		min-height: 200px;

		&:before {
			display: block;
			@include main-icon-refresh-180px;
			content: "";
			position: absolute;
			top: calc(50% - 100px);
			left: calc(50% - 100px);
			z-index: 100;
			animation: rotate 1s infinite linear;

			@keyframes rotate {
				0% {
					transform: rotate(0deg);
				}

				100% {
					transform: rotate(360deg);
				}
			}
		}

		table,
		.js-table-zoom {
			opacity: 0;
		}
	}

	&.is-full {
		width: calc(100vw - 17px);
		left: calc(50% - 50vw);
		z-index: 90;

		@include if-parent('.js-basic-table-wrap.mm-has-side-navi') {

			@include mq(desktop) {
				width: calc(100vw - 10%);
				// if sidenavi <- 18% | l-main-content: width calc(82% - 35px) 35px/2 - 12.5px -> 12px
				left: calc(50% - (18% - 12px));
				transform: translate(-50%);
			}

			@include mq(desktop-l) {
				width: calc(100vw - 50px);
				left: calc(50% - 121px);
			}

			@include mq(desktop-xl) {
				left: 50%;
			}
		}

		&.has-stopper {
			padding-top: 40px;
		}
	}

	table {
		opacity: 1;
		transition: opacity 1s;
	}

	// zoom function btn
	> .js-table-zoom {
		display: none;

		@include mq(tablet) {
			@include main-icon-zoom-out;
			display: block;
			position: absolute;
			top: 3px;
			right: 10px;
			z-index: 60;
			cursor: pointer;

			@include if-parent('.js-basic-table-wrap.has-stopper') {
				top: 34px;
			}
		}

		&.is-sticky {
			position: fixed;
		}
	}

	> .mm-tablesrc {
		@include font-size($font-size-12);
		text-align: right;

		> img {
			margin-left: 10px;
			vertical-align: middle;
		}
	}

	.swiper-button-next {
		top: 100px !important;

		&.is_stuck {
			right: 30px;

			@include mq(tablet) {
				right: 50px;
			}

			@include mq(desktop) {
				right: calc(50% - 380px);
			}

			@include mq(desktop-l) {
				right: calc(50% - 420px);
			}

			@include if-parent('.js-basic-table-wrap.is-full') {
				right: 10px;
			}
		}
	}

	.swiper-button-prev {
		top: 100px !important;
		display: block !important;

		&.swiper-button-disabled {
			opacity: 0;
		}

		&.is_stuck {
			left: 160px;

			@include mq(tablet) {
				left: 210px;
			}

			@include mq(desktop) {
				left: calc(50% - 239px);
			}

			@include mq(desktop-l) {
				left: calc(50% - 265px);
			}

			@include if-parent('.js-basic-table-wrap.is-full') {
				left: 180px;
			}
		}
	}
}

.js-basic-table-header-clone-wrap {
	position: relative;
	overflow: hidden;
	z-index: 50;
	margin-top: -10px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;

	@include mq(print) {
		opacity: 0 !important;
		visibility: hidden !important;
	}

	.is-cloned-header {
		> thead {
			.a-basic-stopper {
				display: none;
			}
		}
	}
}

.js-main-border {
	border: 1px solid $color-secondary-16;
	border-radius: 10px;

	@include mq(tablet) {
		padding: 0 30px;
	}

	.swiper-container-table & {
		padding: 0;
		border: none;
	}

	&.is-full {
		padding: 0;
		border: none;
	}

	.js-basic-table-wrap {

		> .is-full {
			border: 1px solid $color-secondary-16;
		}
	}

	&.is-nopadding {
		padding: 0;
	}
}