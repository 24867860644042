/* m-my-adac-dashboard-box--plates*/
.m-my-adac-dashboard-box--plates {
	> .mm-wrapper {
		> .mm-dashbox-theme-wrapper {
			> .mm-dashbox-theme {
				> .mm-box-wrapper {
					> .mm-box {
						.mm-box-headline {
							min-height: 80px;
							padding-top: 0;
							padding-bottom: 0;

							@include mq(desktop) {
								padding-top: 0;
								padding-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}