.m-basic-layer-survey--contact {
	width: calc(100% - 40px);
	max-width: 400px;

	> .mm-content {
		border-radius: 8px;
		padding: 21px 20px 10px 21px;

		@include mq(tablet) {
			padding: 21px 40px;
		}

		> article {
			margin-top: 30px;

			&::after {
				display: none;
			}

			section {
				margin: 0;
			}
		}
	}
}