.m-basic-toggle--compare {
	display: block;
	visibility: visible;

	@include mq(tablet) {
		display: none;
		visibility: hidden;
	}

	// Exception: m-basic-keywordfilter
	.m-basic-keywordfilter & {
		clear: both;
	}

	.mm-head {
		position: relative;
		left: -2px;
		padding-left: 20px;
		background-color: transparent;
		background-position: -3px 8px;

		&.mm-opened {
			background-color: transparent;
			background-position: -3px 8px;
		}
	}

	.mm-content {
		.a-basic-input-text {
			padding-top: 0;
			width: calc(100% - 130px);
		}

		&--bg {
			background: $color-secondary-6;
		}
	}
}