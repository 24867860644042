@import "variables.scss";
@import "globals/mixins.scss";
@import "partials/mixins.scss";
@import "partials/config.scss";
@import "partials/placeholders.scss";
// svg sprite style sheet

    // svg src
    @mixin weather-svgSprite {
        background: url("/assets/img/svg-sprite/weather-sprite.svg") no-repeat;
    }

    // common name
    @mixin weather-sprite-icons {
        @include weather-svgSprite;
    }

    // svg-sprite icon mixin
    @mixin weather-cloudy {
        @include weather-sprite-icons;
        @include weather-cloudy-dim;
        background-position: 0 0;
    }

    // icon dimensions
    @mixin weather-cloudy-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-cloudy-night {
        @include weather-sprite-icons;
        @include weather-cloudy-night-dim;
        background-position: 15.113350125944585% 0;
    }

    // icon dimensions
    @mixin weather-cloudy-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-fog {
        @include weather-sprite-icons;
        @include weather-fog-dim;
        background-position: 0 16.666666666666668%;
    }

    // icon dimensions
    @mixin weather-fog-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-fog-night {
        @include weather-sprite-icons;
        @include weather-fog-night-dim;
        background-position: 15.113350125944585% 16.666666666666668%;
    }

    // icon dimensions
    @mixin weather-fog-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-freezingrain {
        @include weather-sprite-icons;
        @include weather-freezingrain-dim;
        background-position: 30.22670025188917% 0;
    }

    // icon dimensions
    @mixin weather-freezingrain-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-freezingrain-night {
        @include weather-sprite-icons;
        @include weather-freezingrain-night-dim;
        background-position: 30.22670025188917% 16.666666666666668%;
    }

    // icon dimensions
    @mixin weather-freezingrain-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-overcast {
        @include weather-sprite-icons;
        @include weather-overcast-dim;
        background-position: 0 33.333333333333336%;
    }

    // icon dimensions
    @mixin weather-overcast-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-overcast-night {
        @include weather-sprite-icons;
        @include weather-overcast-night-dim;
        background-position: 15.113350125944585% 33.333333333333336%;
    }

    // icon dimensions
    @mixin weather-overcast-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-partlycloudy {
        @include weather-sprite-icons;
        @include weather-partlycloudy-dim;
        background-position: 30.22670025188917% 33.333333333333336%;
    }

    // icon dimensions
    @mixin weather-partlycloudy-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-partlycloudy-night {
        @include weather-sprite-icons;
        @include weather-partlycloudy-night-dim;
        background-position: 45.340050377833755% 0;
    }

    // icon dimensions
    @mixin weather-partlycloudy-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-rain {
        @include weather-sprite-icons;
        @include weather-rain-dim;
        background-position: 45.340050377833755% 16.666666666666668%;
    }

    // icon dimensions
    @mixin weather-rain-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-rain-night {
        @include weather-sprite-icons;
        @include weather-rain-night-dim;
        background-position: 45.340050377833755% 33.333333333333336%;
    }

    // icon dimensions
    @mixin weather-rain-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-rain_light {
        @include weather-sprite-icons;
        @include weather-rain_light-dim;
        background-position: 0 50%;
    }

    // icon dimensions
    @mixin weather-rain_light-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-raindrizzle {
        @include weather-sprite-icons;
        @include weather-raindrizzle-dim;
        background-position: 15.113350125944585% 50%;
    }

    // icon dimensions
    @mixin weather-raindrizzle-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-raindrizzle-night {
        @include weather-sprite-icons;
        @include weather-raindrizzle-night-dim;
        background-position: 30.22670025188917% 50%;
    }

    // icon dimensions
    @mixin weather-raindrizzle-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-rainheavy {
        @include weather-sprite-icons;
        @include weather-rainheavy-dim;
        background-position: 45.340050377833755% 50%;
    }

    // icon dimensions
    @mixin weather-rainheavy-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-rainheavy-night {
        @include weather-sprite-icons;
        @include weather-rainheavy-night-dim;
        background-position: 60.45340050377834% 0;
    }

    // icon dimensions
    @mixin weather-rainheavy-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-severethunderstorm {
        @include weather-sprite-icons;
        @include weather-severethunderstorm-dim;
        background-position: 60.45340050377834% 16.666666666666668%;
    }

    // icon dimensions
    @mixin weather-severethunderstorm-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-showers {
        @include weather-sprite-icons;
        @include weather-showers-dim;
        background-position: 60.45340050377834% 33.333333333333336%;
    }

    // icon dimensions
    @mixin weather-showers-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-showers-night {
        @include weather-sprite-icons;
        @include weather-showers-night-dim;
        background-position: 60.45340050377834% 50%;
    }

    // icon dimensions
    @mixin weather-showers-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-showers_light {
        @include weather-sprite-icons;
        @include weather-showers_light-dim;
        background-position: 0 66.66666666666667%;
    }

    // icon dimensions
    @mixin weather-showers_light-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-showersheavy {
        @include weather-sprite-icons;
        @include weather-showersheavy-dim;
        background-position: 15.113350125944585% 66.66666666666667%;
    }

    // icon dimensions
    @mixin weather-showersheavy-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-showersheavy-night {
        @include weather-sprite-icons;
        @include weather-showersheavy-night-dim;
        background-position: 30.22670025188917% 66.66666666666667%;
    }

    // icon dimensions
    @mixin weather-showersheavy-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snow {
        @include weather-sprite-icons;
        @include weather-snow-dim;
        background-position: 45.340050377833755% 66.66666666666667%;
    }

    // icon dimensions
    @mixin weather-snow-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowflake {
        @include weather-sprite-icons;
        @include weather-snowflake-dim;
        background-position: 14.423076923076923% 94.24083769633508%;
    }

    // icon dimensions
    @mixin weather-snowflake-dim {
        width: 41px;
        height: 38px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowheavy {
        @include weather-sprite-icons;
        @include weather-snowheavy-dim;
        background-position: 60.45340050377834% 66.66666666666667%;
    }

    // icon dimensions
    @mixin weather-snowheavy-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowrain {
        @include weather-sprite-icons;
        @include weather-snowrain-dim;
        background-position: 75.56675062972292% 0;
    }

    // icon dimensions
    @mixin weather-snowrain-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowrain-night {
        @include weather-sprite-icons;
        @include weather-snowrain-night-dim;
        background-position: 75.56675062972292% 16.666666666666668%;
    }

    // icon dimensions
    @mixin weather-snowrain-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowrainheavy {
        @include weather-sprite-icons;
        @include weather-snowrainheavy-dim;
        background-position: 75.56675062972292% 33.333333333333336%;
    }

    // icon dimensions
    @mixin weather-snowrainheavy-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowrainheavy-night {
        @include weather-sprite-icons;
        @include weather-snowrainheavy-night-dim;
        background-position: 75.56675062972292% 50%;
    }

    // icon dimensions
    @mixin weather-snowrainheavy-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowrainshowers {
        @include weather-sprite-icons;
        @include weather-snowrainshowers-dim;
        background-position: 75.56675062972292% 66.66666666666667%;
    }

    // icon dimensions
    @mixin weather-snowrainshowers-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowrainshowers-night {
        @include weather-sprite-icons;
        @include weather-snowrainshowers-night-dim;
        background-position: 0 83.33333333333333%;
    }

    // icon dimensions
    @mixin weather-snowrainshowers-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowrainshowers_light {
        @include weather-sprite-icons;
        @include weather-snowrainshowers_light-dim;
        background-position: 15.113350125944585% 83.33333333333333%;
    }

    // icon dimensions
    @mixin weather-snowrainshowers_light-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowshower-night {
        @include weather-sprite-icons;
        @include weather-snowshower-night-dim;
        background-position: 30.22670025188917% 83.33333333333333%;
    }

    // icon dimensions
    @mixin weather-snowshower-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowshower_light {
        @include weather-sprite-icons;
        @include weather-snowshower_light-dim;
        background-position: 45.340050377833755% 83.33333333333333%;
    }

    // icon dimensions
    @mixin weather-snowshower_light-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowshowerheavy {
        @include weather-sprite-icons;
        @include weather-snowshowerheavy-dim;
        background-position: 60.45340050377834% 83.33333333333333%;
    }

    // icon dimensions
    @mixin weather-snowshowerheavy-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowshowerheavy-night {
        @include weather-sprite-icons;
        @include weather-snowshowerheavy-night-dim;
        background-position: 75.56675062972292% 83.33333333333333%;
    }

    // icon dimensions
    @mixin weather-snowshowerheavy-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowshowers {
        @include weather-sprite-icons;
        @include weather-snowshowers-dim;
        background-position: 90.68010075566751% 0;
    }

    // icon dimensions
    @mixin weather-snowshowers-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-snowshowers_light {
        @include weather-sprite-icons;
        @include weather-snowshowers_light-dim;
        background-position: 90.68010075566751% 16.666666666666668%;
    }

    // icon dimensions
    @mixin weather-snowshowers_light-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-sunshine {
        @include weather-sprite-icons;
        @include weather-sunshine-dim;
        background-position: 90.68010075566751% 33.333333333333336%;
    }

    // icon dimensions
    @mixin weather-sunshine-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-sunshine-night {
        @include weather-sprite-icons;
        @include weather-sunshine-night-dim;
        background-position: 90.68010075566751% 50%;
    }

    // icon dimensions
    @mixin weather-sunshine-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-thunderstorm {
        @include weather-sprite-icons;
        @include weather-thunderstorm-dim;
        background-position: 90.68010075566751% 66.66666666666667%;
    }

    // icon dimensions
    @mixin weather-thunderstorm-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-thunderstorm-night {
        @include weather-sprite-icons;
        @include weather-thunderstorm-night-dim;
        background-position: 90.68010075566751% 83.33333333333333%;
    }

    // icon dimensions
    @mixin weather-thunderstorm-night-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin weather-umbrella {
        @include weather-sprite-icons;
        @include weather-umbrella-dim;
        background-position: 100% 0;
    }

    // icon dimensions
    @mixin weather-umbrella-dim {
        width: 37px;
        height: 39px;
    }


    // svg-sprite icon mixin
    @mixin weather-wind {
        @include weather-sprite-icons;
        @include weather-wind-dim;
        background-position: 0 100%;
    }

    // icon dimensions
    @mixin weather-wind-dim {
        width: 60px;
        height: 60px;
    }


// svg sprite style sheet

    // svg src
    @mixin tourset-svgSprite {
        background: url("/assets/img/svg-sprite/tourset-sprite.svg") no-repeat;
    }

    // common name
    @mixin tourset-sprite-icons {
        @include tourset-svgSprite;
    }

    // svg-sprite icon mixin
    @mixin tourset-icon-auto-renew {
        @include tourset-sprite-icons;
        @include tourset-icon-auto-renew-dim;
        background-position: 86.33093525179856% 44.61942257217848%;
    }

    // icon dimensions
    @mixin tourset-icon-auto-renew-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-australien-neuseeland {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-australien-neuseeland-dim;
        background-position: 0 0;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-australien-neuseeland-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-bootsurlaub {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-bootsurlaub-dim;
        background-position: 25% 0;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-bootsurlaub-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-camping-gespann {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-camping-gespann-dim;
        background-position: 0 25%;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-camping-gespann-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-direkte-route {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-direkte-route-dim;
        background-position: 25% 25%;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-direkte-route-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-europa {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-europa-dim;
        background-position: 50% 0;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-europa-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-flugzeug {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-flugzeug-dim;
        background-position: 50% 25%;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-flugzeug-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-gespann-trailer {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-gespann-trailer-dim;
        background-position: 0 50%;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-gespann-trailer-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-haustiere {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-haustiere-dim;
        background-position: 25% 50%;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-haustiere-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-kreuzfahrt {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-kreuzfahrt-dim;
        background-position: 50% 50%;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-kreuzfahrt-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-maps-gespann {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-maps-gespann-dim;
        background-position: 0 75%;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-maps-gespann-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-maps-motorrad {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-maps-motorrad-dim;
        background-position: 25% 75%;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-maps-motorrad-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-maps-pkw {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-maps-pkw-dim;
        background-position: 50% 75%;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-maps-pkw-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-maps-wohnmobil {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-maps-wohnmobil-dim;
        background-position: 75% 0;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-maps-wohnmobil-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-motorrad-urlaub {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-motorrad-urlaub-dim;
        background-position: 75% 25%;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-motorrad-urlaub-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-nordamerika {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-nordamerika-dim;
        background-position: 75% 50%;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-nordamerika-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-pkw {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-pkw-dim;
        background-position: 75% 75%;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-pkw-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-routenempfehlung {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-routenempfehlung-dim;
        background-position: 0 100%;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-routenempfehlung-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-rundreise {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-rundreise-dim;
        background-position: 25% 100%;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-rundreise-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-tourset {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-tourset-dim;
        background-position: 50% 100%;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-tourset-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-urlaub-mit-kindern {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-urlaub-mit-kindern-dim;
        background-position: 75% 100%;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-urlaub-mit-kindern-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-vert {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-vert-dim;
        background-position: 94.24460431654676% 44.61942257217848%;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-vert-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-wohnmobil-unter-35t {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-wohnmobil-unter-35t-dim;
        background-position: 100% 0;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-wohnmobil-unter-35t-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin tourset-icon-tourset-wohnmobil-vollintegriert {
        @include tourset-sprite-icons;
        @include tourset-icon-tourset-wohnmobil-vollintegriert-dim;
        background-position: 100% 25%;
    }

    // icon dimensions
    @mixin tourset-icon-tourset-wohnmobil-vollintegriert-dim {
        width: 120px;
        height: 85px;
    }


// svg sprite style sheet

    // svg src
    @mixin social-media-svgSprite {
        background: url("/assets/img/svg-sprite/social-media-sprite.svg") no-repeat;
    }

    // common name
    @mixin social-media-sprite-icons {
        @include social-media-svgSprite;
    }

    // svg-sprite icon mixin
    @mixin social-media-icon-community {
        @include social-media-sprite-icons;
        @include social-media-icon-community-dim;
        background-position: 0 53.191489361702125%;
    }

    // icon dimensions
    @mixin social-media-icon-community-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin social-media-icon-facebook {
        @include social-media-sprite-icons;
        @include social-media-icon-facebook-dim;
        background-position: 44% 53.191489361702125%;
    }

    // icon dimensions
    @mixin social-media-icon-facebook-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin social-media-icon-instagram {
        @include social-media-sprite-icons;
        @include social-media-icon-instagram-dim;
        background-position: 100% 0;
    }

    // icon dimensions
    @mixin social-media-icon-instagram-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin social-media-icon-linkedin {
        @include social-media-sprite-icons;
        @include social-media-icon-linkedin-dim;
        background-position: 0 0;
    }

    // icon dimensions
    @mixin social-media-icon-linkedin-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin social-media-icon-pinterest-white {
        @include social-media-sprite-icons;
        @include social-media-icon-pinterest-white-dim;
        background-position: 100% 46.808510638297875%;
    }

    // icon dimensions
    @mixin social-media-icon-pinterest-white-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin social-media-icon-tiktok {
        @include social-media-sprite-icons;
        @include social-media-icon-tiktok-dim;
        background-position: 53.191489361702125% 0;
    }

    // icon dimensions
    @mixin social-media-icon-tiktok-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin social-media-icon-twitter {
        @include social-media-sprite-icons;
        @include social-media-icon-twitter-dim;
        background-position: 0 100%;
    }

    // icon dimensions
    @mixin social-media-icon-twitter-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin social-media-icon-wordpress {
        @include social-media-sprite-icons;
        @include social-media-icon-wordpress-dim;
        background-position: 44% 100%;
    }

    // icon dimensions
    @mixin social-media-icon-wordpress-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin social-media-icon-youtube {
        @include social-media-sprite-icons;
        @include social-media-icon-youtube-dim;
        background-position: 88% 100%;
    }

    // icon dimensions
    @mixin social-media-icon-youtube-dim {
        width: 44px;
        height: 44px;
    }


// svg sprite style sheet

    // svg src
    @mixin rs-svgSprite {
        background: url("/assets/img/svg-sprite/rs-sprite.svg") no-repeat;
    }

    // common name
    @mixin rs-sprite-icons {
        @include rs-svgSprite;
    }

    // svg-sprite icon mixin
    @mixin rs-icon-berufsrechtsschutz {
        @include rs-sprite-icons;
        @include rs-icon-berufsrechtsschutz-dim;
        background-position: 0 0;
    }

    // icon dimensions
    @mixin rs-icon-berufsrechtsschutz-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin rs-icon-privatrechtsschutz {
        @include rs-sprite-icons;
        @include rs-icon-privatrechtsschutz-dim;
        background-position: 100% 0;
    }

    // icon dimensions
    @mixin rs-icon-privatrechtsschutz-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin rs-icon-verkehrsrechtsschutz {
        @include rs-sprite-icons;
        @include rs-icon-verkehrsrechtsschutz-dim;
        background-position: 0 100%;
    }

    // icon dimensions
    @mixin rs-icon-verkehrsrechtsschutz-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin rs-icon-wohnrechtsschutz {
        @include rs-sprite-icons;
        @include rs-icon-wohnrechtsschutz-dim;
        background-position: 100% 100%;
    }

    // icon dimensions
    @mixin rs-icon-wohnrechtsschutz-dim {
        width: 150px;
        height: 150px;
    }


// svg sprite style sheet

    // svg src
    @mixin reise-svgSprite {
        background: url("/assets/img/svg-sprite/reise-sprite.svg") no-repeat;
    }

    // common name
    @mixin reise-sprite-icons {
        @include reise-svgSprite;
    }

    // svg-sprite icon mixin
    @mixin reise-aktivitaeten {
        @include reise-sprite-icons;
        @include reise-aktivitaeten-dim;
        background-position: 0 0;
    }

    // icon dimensions
    @mixin reise-aktivitaeten-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin reise-architektur {
        @include reise-sprite-icons;
        @include reise-architektur-dim;
        background-position: 50% 0;
    }

    // icon dimensions
    @mixin reise-architektur-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin reise-essen {
        @include reise-sprite-icons;
        @include reise-essen-dim;
        background-position: 0 100%;
    }

    // icon dimensions
    @mixin reise-essen-dim {
        width: 36px;
        height: 41px;
    }


    // svg-sprite icon mixin
    @mixin reise-landschaft {
        @include reise-sprite-icons;
        @include reise-landschaft-dim;
        background-position: 0 51.76470588235294%;
    }

    // icon dimensions
    @mixin reise-landschaft-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin reise-museen {
        @include reise-sprite-icons;
        @include reise-museen-dim;
        background-position: 50% 51.76470588235294%;
    }

    // icon dimensions
    @mixin reise-museen-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin reise-shopping {
        @include reise-sprite-icons;
        @include reise-shopping-dim;
        background-position: 100% 0;
    }

    // icon dimensions
    @mixin reise-shopping-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin reise-stadtbild {
        @include reise-sprite-icons;
        @include reise-stadtbild-dim;
        background-position: 100% 51.76470588235294%;
    }

    // icon dimensions
    @mixin reise-stadtbild-dim {
        width: 44px;
        height: 44px;
    }


// svg sprite style sheet

    // svg src
    @mixin my-adac-svgSprite {
        background: url("/assets/img/svg-sprite/my-adac-sprite.svg") no-repeat;
    }

    // common name
    @mixin my-adac-sprite-icons {
        @include my-adac-svgSprite;
    }

    // svg-sprite icon mixin
    @mixin my-adac-grundpreis {
        @include my-adac-sprite-icons;
        @include my-adac-grundpreis-dim;
        background-position: 35.714285714285715% 68.88888888888889%;
    }

    // icon dimensions
    @mixin my-adac-grundpreis-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-hubraum {
        @include my-adac-sprite-icons;
        @include my-adac-hubraum-dim;
        background-position: 46.19047619047619% 68.88888888888889%;
    }

    // icon dimensions
    @mixin my-adac-hubraum-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-add-circle {
        @include my-adac-sprite-icons;
        @include my-adac-icon-add-circle-dim;
        background-position: 56.666666666666664% 68.88888888888889%;
    }

    // icon dimensions
    @mixin my-adac-icon-add-circle-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-apply-dark {
        @include my-adac-sprite-icons;
        @include my-adac-icon-apply-dark-dim;
        background-position: 57.7319587628866% 45.04504504504504%;
    }

    // icon dimensions
    @mixin my-adac-icon-apply-dark-dim {
        width: 76px;
        height: 71px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-backspace {
        @include my-adac-sprite-icons;
        @include my-adac-icon-backspace-dim;
        background-position: 16.904761904761905% 83.33333333333333%;
    }

    // icon dimensions
    @mixin my-adac-icon-backspace-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-backspace-grey {
        @include my-adac-sprite-icons;
        @include my-adac-icon-backspace-grey-dim;
        background-position: 27.38095238095238% 83.33333333333333%;
    }

    // icon dimensions
    @mixin my-adac-icon-backspace-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-calculate {
        @include my-adac-sprite-icons;
        @include my-adac-icon-calculate-dim;
        background-position: 37.857142857142854% 83.33333333333333%;
    }

    // icon dimensions
    @mixin my-adac-icon-calculate-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-calculate-grey {
        @include my-adac-sprite-icons;
        @include my-adac-icon-calculate-grey-dim;
        background-position: 48.333333333333336% 83.33333333333333%;
    }

    // icon dimensions
    @mixin my-adac-icon-calculate-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-cancle {
        @include my-adac-sprite-icons;
        @include my-adac-icon-cancle-dim;
        background-position: 58.80952380952381% 83.33333333333333%;
    }

    // icon dimensions
    @mixin my-adac-icon-cancle-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-cancle-grey {
        @include my-adac-sprite-icons;
        @include my-adac-icon-cancle-grey-dim;
        background-position: 69.28571428571429% 83.33333333333333%;
    }

    // icon dimensions
    @mixin my-adac-icon-cancle-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-check-box-inactive {
        @include my-adac-sprite-icons;
        @include my-adac-icon-check-box-inactive-dim;
        background-position: 89.52380952380952% 0;
    }

    // icon dimensions
    @mixin my-adac-icon-check-box-inactive-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-check-box-myadac {
        @include my-adac-sprite-icons;
        @include my-adac-icon-check-box-myadac-dim;
        background-position: 89.52380952380952% 12.222222222222221%;
    }

    // icon dimensions
    @mixin my-adac-icon-check-box-myadac-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-check-box-outline {
        @include my-adac-sprite-icons;
        @include my-adac-icon-check-box-outline-dim;
        background-position: 89.52380952380952% 24.444444444444443%;
    }

    // icon dimensions
    @mixin my-adac-icon-check-box-outline-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-close {
        @include my-adac-sprite-icons;
        @include my-adac-icon-close-dim;
        background-position: 97.67441860465117% 82.70270270270271%;
    }

    // icon dimensions
    @mixin my-adac-icon-close-dim {
        width: 34px;
        height: 34px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-correct {
        @include my-adac-sprite-icons;
        @include my-adac-icon-correct-dim;
        background-position: 89.52380952380952% 36.666666666666664%;
    }

    // icon dimensions
    @mixin my-adac-icon-correct-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-delete {
        @include my-adac-sprite-icons;
        @include my-adac-icon-delete-dim;
        background-position: 89.52380952380952% 48.888888888888886%;
    }

    // icon dimensions
    @mixin my-adac-icon-delete-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-delete-forever {
        @include my-adac-sprite-icons;
        @include my-adac-icon-delete-forever-dim;
        background-position: 89.52380952380952% 61.111111111111114%;
    }

    // icon dimensions
    @mixin my-adac-icon-delete-forever-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-delete-grey {
        @include my-adac-sprite-icons;
        @include my-adac-icon-delete-grey-dim;
        background-position: 89.52380952380952% 73.33333333333333%;
    }

    // icon dimensions
    @mixin my-adac-icon-delete-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-delete-grey2 {
        @include my-adac-sprite-icons;
        @include my-adac-icon-delete-grey2-dim;
        background-position: 89.52380952380952% 85.55555555555556%;
    }

    // icon dimensions
    @mixin my-adac-icon-delete-grey2-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-edit {
        @include my-adac-sprite-icons;
        @include my-adac-icon-edit-dim;
        background-position: 0 100%;
    }

    // icon dimensions
    @mixin my-adac-icon-edit-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-edit-grey {
        @include my-adac-sprite-icons;
        @include my-adac-icon-edit-grey-dim;
        background-position: 10.476190476190476% 100%;
    }

    // icon dimensions
    @mixin my-adac-icon-edit-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-email {
        @include my-adac-sprite-icons;
        @include my-adac-icon-email-dim;
        background-position: 0 0;
    }

    // icon dimensions
    @mixin my-adac-icon-email-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-identification-code {
        @include my-adac-sprite-icons;
        @include my-adac-icon-identification-code-dim;
        background-position: 77.31958762886597% 0;
    }

    // icon dimensions
    @mixin my-adac-icon-identification-code-dim {
        width: 76px;
        height: 71px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-identification-post {
        @include my-adac-sprite-icons;
        @include my-adac-icon-identification-post-dim;
        background-position: 76.33587786259542% 61.91860465116279%;
    }

    // icon dimensions
    @mixin my-adac-icon-identification-post-dim {
        width: 71px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-identificationcode-online {
        @include my-adac-sprite-icons;
        @include my-adac-icon-identificationcode-online-dim;
        background-position: 77.31958762886597% 21.32132132132132%;
    }

    // icon dimensions
    @mixin my-adac-icon-identificationcode-online-dim {
        width: 76px;
        height: 71px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-incorrect {
        @include my-adac-sprite-icons;
        @include my-adac-icon-incorrect-dim;
        background-position: 20.952380952380953% 100%;
    }

    // icon dimensions
    @mixin my-adac-icon-incorrect-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-lightbulb {
        @include my-adac-sprite-icons;
        @include my-adac-icon-lightbulb-dim;
        background-position: 38.46153846153846% 49.01960784313726%;
    }

    // icon dimensions
    @mixin my-adac-icon-lightbulb-dim {
        width: 74px;
        height: 98px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-lightbulb_small {
        @include my-adac-sprite-icons;
        @include my-adac-icon-lightbulb_small-dim;
        background-position: 97.9020979020979% 72.92817679558011%;
    }

    // icon dimensions
    @mixin my-adac-icon-lightbulb_small-dim {
        width: 35px;
        height: 42px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-location {
        @include my-adac-sprite-icons;
        @include my-adac-icon-location-dim;
        background-position: 47.77070063694268% 0;
    }

    // icon dimensions
    @mixin my-adac-icon-location-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-post-dark {
        @include my-adac-sprite-icons;
        @include my-adac-icon-post-dark-dim;
        background-position: 0 87.20930232558139%;
    }

    // icon dimensions
    @mixin my-adac-icon-post-dark-dim {
        width: 71px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-tab-myadac {
        @include my-adac-sprite-icons;
        @include my-adac-icon-tab-myadac-dim;
        background-position: 31.428571428571427% 96.7741935483871%;
    }

    // icon dimensions
    @mixin my-adac-icon-tab-myadac-dim {
        width: 44px;
        height: 32px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-verification-dark {
        @include my-adac-sprite-icons;
        @include my-adac-icon-verification-dark-dim;
        background-position: 77.31958762886597% 42.64264264264264%;
    }

    // icon dimensions
    @mixin my-adac-icon-verification-dark-dim {
        width: 76px;
        height: 71px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-icon-video {
        @include my-adac-sprite-icons;
        @include my-adac-icon-video-dim;
        background-position: 0 59.05511811023622%;
    }

    // icon dimensions
    @mixin my-adac-icon-video-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-kraftstoff {
        @include my-adac-sprite-icons;
        @include my-adac-kraftstoff-dim;
        background-position: 100% 0;
    }

    // icon dimensions
    @mixin my-adac-kraftstoff-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-leistung {
        @include my-adac-sprite-icons;
        @include my-adac-leistung-dim;
        background-position: 100% 12.222222222222221%;
    }

    // icon dimensions
    @mixin my-adac-leistung-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-local_gas_station {
        @include my-adac-sprite-icons;
        @include my-adac-local_gas_station-dim;
        background-position: 100% 24.444444444444443%;
    }

    // icon dimensions
    @mixin my-adac-local_gas_station-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-local_gas_station-grey {
        @include my-adac-sprite-icons;
        @include my-adac-local_gas_station-grey-dim;
        background-position: 100% 36.666666666666664%;
    }

    // icon dimensions
    @mixin my-adac-local_gas_station-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-local_gas_station-grey2 {
        @include my-adac-sprite-icons;
        @include my-adac-local_gas_station-grey2-dim;
        background-position: 100% 48.888888888888886%;
    }

    // icon dimensions
    @mixin my-adac-local_gas_station-grey2-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin my-adac-verbrauch {
        @include my-adac-sprite-icons;
        @include my-adac-verbrauch-dim;
        background-position: 100% 61.111111111111114%;
    }

    // icon dimensions
    @mixin my-adac-verbrauch-dim {
        width: 44px;
        height: 44px;
    }


// svg sprite style sheet

    // svg src
    @mixin motorcycles-catalog-svgSprite {
        background: url("/assets/img/svg-sprite/motorcycles-catalog-sprite.svg") no-repeat;
    }

    // common name
    @mixin motorcycles-catalog-sprite-icons {
        @include motorcycles-catalog-svgSprite;
    }

    // svg-sprite icon mixin
    @mixin motorcycles-catalog-icon-leichtkraftdreirad {
        @include motorcycles-catalog-sprite-icons;
        @include motorcycles-catalog-icon-leichtkraftdreirad-dim;
        background-position: 0 0;
    }

    // icon dimensions
    @mixin motorcycles-catalog-icon-leichtkraftdreirad-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin motorcycles-catalog-icon-leichtkraftrad-roller {
        @include motorcycles-catalog-sprite-icons;
        @include motorcycles-catalog-icon-leichtkraftrad-roller-dim;
        background-position: 50% 0;
    }

    // icon dimensions
    @mixin motorcycles-catalog-icon-leichtkraftrad-roller-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin motorcycles-catalog-icon-motordreirad {
        @include motorcycles-catalog-sprite-icons;
        @include motorcycles-catalog-icon-motordreirad-dim;
        background-position: 0 100%;
    }

    // icon dimensions
    @mixin motorcycles-catalog-icon-motordreirad-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin motorcycles-catalog-icon-motorrad-roller {
        @include motorcycles-catalog-sprite-icons;
        @include motorcycles-catalog-icon-motorrad-roller-dim;
        background-position: 50% 100%;
    }

    // icon dimensions
    @mixin motorcycles-catalog-icon-motorrad-roller-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin motorcycles-catalog-icon-trike {
        @include motorcycles-catalog-sprite-icons;
        @include motorcycles-catalog-icon-trike-dim;
        background-position: 100% 0;
    }

    // icon dimensions
    @mixin motorcycles-catalog-icon-trike-dim {
        width: 120px;
        height: 85px;
    }


// svg sprite style sheet

    // svg src
    @mixin main-svgSprite {
        background: url("/assets/img/svg-sprite/main-sprite.svg") no-repeat;
    }

    // common name
    @mixin main-sprite-icons {
        @include main-svgSprite;
    }

    // svg-sprite icon mixin
    @mixin main-adac-logo {
        @include main-sprite-icons;
        @include main-adac-logo-dim;
        background-position: 69.99214341832727% 28.28267591824421%;
    }

    // icon dimensions
    @mixin main-adac-logo-dim {
        width: 133.9px;
        height: 134.1px;
    }


    // svg-sprite icon mixin
    @mixin main-arrow {
        @include main-sprite-icons;
        @include main-arrow-dim;
        background-position: 64.25675675675676% 83.78378378378379%;
    }

    // icon dimensions
    @mixin main-arrow-dim {
        width: 54px;
        height: 54px;
    }


    // svg-sprite icon mixin
    @mixin main-arrow-bottom {
        @include main-sprite-icons;
        @include main-arrow-bottom-dim;
        background-position: 40.78771695594126% 90.1685393258427%;
    }

    // icon dimensions
    @mixin main-arrow-bottom-dim {
        width: 36px;
        height: 36px;
    }


    // svg-sprite icon mixin
    @mixin main-arrow-btn-down {
        @include main-sprite-icons;
        @include main-arrow-btn-down-dim;
        background-position: 99.8632707774799% 64.59802538787024%;
    }

    // icon dimensions
    @mixin main-arrow-btn-down-dim {
        width: 42px;
        height: 42px;
    }


    // svg-sprite icon mixin
    @mixin main-arrow-btn-up {
        @include main-sprite-icons;
        @include main-arrow-btn-up-dim;
        background-position: 99.8632707774799% 67.55994358251058%;
    }

    // icon dimensions
    @mixin main-arrow-btn-up-dim {
        width: 42px;
        height: 42px;
    }


    // svg-sprite icon mixin
    @mixin main-arrow-secondary3 {
        @include main-sprite-icons;
        @include main-arrow-secondary3-dim;
        background-position: 67.9054054054054% 83.78378378378379%;
    }

    // icon dimensions
    @mixin main-arrow-secondary3-dim {
        width: 54px;
        height: 54px;
    }


    // svg-sprite icon mixin
    @mixin main-arrow-white {
        @include main-sprite-icons;
        @include main-arrow-white-dim;
        background-position: 71.55405405405405% 83.78378378378379%;
    }

    // icon dimensions
    @mixin main-arrow-white-dim {
        width: 54px;
        height: 54px;
    }


    // svg-sprite icon mixin
    @mixin main-arrow_drop_up_24px {
        @include main-sprite-icons;
        @include main-arrow_drop_up_24px-dim;
        background-position: 88.0510067114094% 41.313559322033896%;
    }

    // icon dimensions
    @mixin main-arrow_drop_up_24px-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-choose {
        @include main-sprite-icons;
        @include main-choose-dim;
        background-position: 0 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-choose-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-choose-mobile {
        @include main-sprite-icons;
        @include main-choose-mobile-dim;
        background-position: 47.03903095558547% 87.39376770538243%;
    }

    // icon dimensions
    @mixin main-choose-mobile-dim {
        width: 48px;
        height: 48px;
    }


    // svg-sprite icon mixin
    @mixin main-circle-diagram {
        @include main-sprite-icons;
        @include main-circle-diagram-dim;
        background-position: 70.29842180774749% 0;
    }

    // icon dimensions
    @mixin main-circle-diagram-dim {
        width: 140px;
        height: 140px;
    }


    // svg-sprite icon mixin
    @mixin main-circle-diagram2 {
        @include main-sprite-icons;
        @include main-circle-diagram2-dim;
        background-position: 70.29842180774749% 10.606060606060606%;
    }

    // icon dimensions
    @mixin main-circle-diagram2-dim {
        width: 140px;
        height: 140px;
    }


    // svg-sprite icon mixin
    @mixin main-communication {
        @include main-sprite-icons;
        @include main-communication-dim;
        background-position: 27.453757225433527% 0;
    }

    // icon dimensions
    @mixin main-communication-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-extern-link {
        @include main-sprite-icons;
        @include main-extern-link-dim;
        background-position: 78.02663115845539% 58.8235294117647%;
    }

    // icon dimensions
    @mixin main-extern-link-dim {
        width: 32px;
        height: 32px;
    }


    // svg-sprite icon mixin
    @mixin main-eye {
        @include main-sprite-icons;
        @include main-eye-dim;
        background-position: 88.0510067114094% 44.42090395480226%;
    }

    // icon dimensions
    @mixin main-eye-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-eye-off {
        @include main-sprite-icons;
        @include main-eye-off-dim;
        background-position: 88.0510067114094% 47.52824858757062%;
    }

    // icon dimensions
    @mixin main-eye-off-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-fahrverbot {
        @include main-sprite-icons;
        @include main-fahrverbot-dim;
        background-position: 76.3956343792633% 31.25%;
    }

    // icon dimensions
    @mixin main-fahrverbot-dim {
        width: 68px;
        height: 68px;
    }


    // svg-sprite icon mixin
    @mixin main-gas-stations-service-areas {
        @include main-sprite-icons;
        @include main-gas-stations-service-areas-dim;
        background-position: 27.453757225433527% 11.450381679389313%;
    }

    // icon dimensions
    @mixin main-gas-stations-service-areas-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-geldbusse {
        @include main-sprite-icons;
        @include main-geldbusse-dim;
        background-position: 76.3956343792633% 36.13505747126437%;
    }

    // icon dimensions
    @mixin main-geldbusse-dim {
        width: 68px;
        height: 68px;
    }


    // svg-sprite icon mixin
    @mixin main-hotels-gastronomy {
        @include main-sprite-icons;
        @include main-hotels-gastronomy-dim;
        background-position: 0 23.129770992366414%;
    }

    // icon dimensions
    @mixin main-hotels-gastronomy-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-ic_check_green_24px-01 {
        @include main-sprite-icons;
        @include main-ic_check_green_24px-01-dim;
        background-position: 88.0510067114094% 50.63559322033898%;
    }

    // icon dimensions
    @mixin main-ic_check_green_24px-01-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-360 {
        @include main-sprite-icons;
        @include main-icon-360-dim;
        background-position: 49.6551724137931% 68.58181818181818%;
    }

    // icon dimensions
    @mixin main-icon-360-dim {
        width: 84px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-360-30 {
        @include main-sprite-icons;
        @include main-icon-360-30-dim;
        background-position: 78.74493927125506% 83.69449378330373%;
    }

    // icon dimensions
    @mixin main-icon-360-30-dim {
        width: 52px;
        height: 52.5px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-accessible {
        @include main-sprite-icons;
        @include main-icon-accessible-dim;
        background-position: 69.3041018387553% 43.93454545454546%;
    }

    // icon dimensions
    @mixin main-icon-accessible-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-accident {
        @include main-sprite-icons;
        @include main-icon-accident-dim;
        background-position: 58.659217877094974% 61.54949784791965%;
    }

    // icon dimensions
    @mixin main-icon-accident-dim {
        width: 102px;
        height: 66px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-accomodation {
        @include main-sprite-icons;
        @include main-icon-accomodation-dim;
        background-position: 88.0510067114094% 53.74293785310734%;
    }

    // icon dimensions
    @mixin main-icon-accomodation-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-account-settings-variant {
        @include main-sprite-icons;
        @include main-icon-account-settings-variant-dim;
        background-position: 99.93024815560027% 58.87323943661972%;
    }

    // icon dimensions
    @mixin main-icon-account-settings-variant-dim {
        width: 43px;
        height: 40px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-account-settings-variant-grey {
        @include main-sprite-icons;
        @include main-icon-account-settings-variant-grey-dim;
        background-position: 99.93024815560027% 61.690140845070424%;
    }

    // icon dimensions
    @mixin main-icon-account-settings-variant-grey-dim {
        width: 43px;
        height: 40px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-adac-car {
        @include main-sprite-icons;
        @include main-icon-adac-car-dim;
        background-position: 85.01754385964912% 0;
    }

    // icon dimensions
    @mixin main-icon-adac-car-dim {
        width: 52px;
        height: 52px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-adac-location {
        @include main-sprite-icons;
        @include main-icon-adac-location-dim;
        background-position: 20.58823529411765% 90.29535864978902%;
    }

    // icon dimensions
    @mixin main-icon-adac-location-dim {
        width: 38px;
        height: 38px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-adac-location-22px {
        @include main-sprite-icons;
        @include main-icon-adac-location-22px-dim;
        background-position: 99.8632707774799% 70.52186177715092%;
    }

    // icon dimensions
    @mixin main-icon-adac-location-22px-dim {
        width: 42px;
        height: 42px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-adac-location-22px-star {
        @include main-sprite-icons;
        @include main-icon-adac-location-22px-star-dim;
        background-position: 99.8632707774799% 73.48377997179125%;
    }

    // icon dimensions
    @mixin main-icon-adac-location-22px-star-dim {
        width: 42px;
        height: 42px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-add {
        @include main-sprite-icons;
        @include main-icon-add-dim;
        background-position: 88.0510067114094% 56.85028248587571%;
    }

    // icon dimensions
    @mixin main-icon-add-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-add-black-big {
        @include main-sprite-icons;
        @include main-icon-add-black-big-dim;
        background-position: 23.05% 7.223001402524544%;
    }

    // icon dimensions
    @mixin main-icon-add-black-big-dim {
        width: 34px;
        height: 34px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-add-green {
        @include main-sprite-icons;
        @include main-icon-add-green-dim;
        background-position: 88.0510067114094% 59.95762711864407%;
    }

    // icon dimensions
    @mixin main-icon-add-green-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-add-grey {
        @include main-sprite-icons;
        @include main-icon-add-grey-dim;
        background-position: 23.05% 9.607293127629733%;
    }

    // icon dimensions
    @mixin main-icon-add-grey-dim {
        width: 34px;
        height: 34px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-add-lightgrey {
        @include main-sprite-icons;
        @include main-icon-add-lightgrey-dim;
        background-position: 88.0510067114094% 63.06497175141243%;
    }

    // icon dimensions
    @mixin main-icon-add-lightgrey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-addadult {
        @include main-sprite-icons;
        @include main-icon-addadult-dim;
        background-position: 69.3041018387553% 50.11636363636364%;
    }

    // icon dimensions
    @mixin main-icon-addadult-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-addkids {
        @include main-sprite-icons;
        @include main-icon-addkids-dim;
        background-position: 0 62.4%;
    }

    // icon dimensions
    @mixin main-icon-addkids-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-addpartner {
        @include main-sprite-icons;
        @include main-icon-addpartner-dim;
        background-position: 8.486562942008486% 62.4%;
    }

    // icon dimensions
    @mixin main-icon-addpartner-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-addschool {
        @include main-sprite-icons;
        @include main-icon-addschool-dim;
        background-position: 16.973125884016973% 62.4%;
    }

    // icon dimensions
    @mixin main-icon-addschool-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-alert-filled {
        @include main-sprite-icons;
        @include main-icon-alert-filled-dim;
        background-position: 76.3956343792633% 26.312005751258088%;
    }

    // icon dimensions
    @mixin main-icon-alert-filled-dim {
        width: 68px;
        height: 69px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-android {
        @include main-sprite-icons;
        @include main-icon-android-dim;
        background-position: 3.7889039242219216% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-android-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-anhaenger {
        @include main-sprite-icons;
        @include main-icon-anhaenger-dim;
        background-position: 12.956083513318934% 14.652014652014651%;
    }

    // icon dimensions
    @mixin main-icon-anhaenger-dim {
        width: 145px;
        height: 95px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-apple {
        @include main-sprite-icons;
        @include main-icon-apple-dim;
        background-position: 7.577807848443843% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-apple-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-2-left {
        @include main-sprite-icons;
        @include main-icon-arrow-2-left-dim;
        background-position: 43.190921228304404% 90.1685393258427%;
    }

    // icon dimensions
    @mixin main-icon-arrow-2-left-dim {
        width: 36px;
        height: 36px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-2-right {
        @include main-sprite-icons;
        @include main-icon-arrow-2-right-dim;
        background-position: 88.0510067114094% 66.17231638418079%;
    }

    // icon dimensions
    @mixin main-icon-arrow-2-right-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-down {
        @include main-sprite-icons;
        @include main-icon-arrow-down-dim;
        background-position: 88.0510067114094% 69.27966101694915%;
    }

    // icon dimensions
    @mixin main-icon-arrow-down-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-down-grey {
        @include main-sprite-icons;
        @include main-icon-arrow-down-grey-dim;
        background-position: 88.0510067114094% 72.38700564971751%;
    }

    // icon dimensions
    @mixin main-icon-arrow-down-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-down-grey2 {
        @include main-sprite-icons;
        @include main-icon-arrow-down-grey2-dim;
        background-position: 88.0510067114094% 75.49435028248588%;
    }

    // icon dimensions
    @mixin main-icon-arrow-down-grey2-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-drop-down {
        @include main-sprite-icons;
        @include main-icon-arrow-drop-down-dim;
        background-position: 88.0510067114094% 78.60169491525424%;
    }

    // icon dimensions
    @mixin main-icon-arrow-drop-down-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-drop-down-active {
        @include main-sprite-icons;
        @include main-icon-arrow-drop-down-active-dim;
        background-position: 88.0510067114094% 81.7090395480226%;
    }

    // icon dimensions
    @mixin main-icon-arrow-drop-down-active-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-drop-down-disabled {
        @include main-sprite-icons;
        @include main-icon-arrow-drop-down-disabled-dim;
        background-position: 88.0510067114094% 84.81638418079096%;
    }

    // icon dimensions
    @mixin main-icon-arrow-drop-down-disabled-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-drop-up {
        @include main-sprite-icons;
        @include main-icon-arrow-drop-up-dim;
        background-position: 27.651006711409394% 75.07062146892656%;
    }

    // icon dimensions
    @mixin main-icon-arrow-drop-up-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-left-black {
        @include main-sprite-icons;
        @include main-icon-arrow-left-black-dim;
        background-position: 30.604026845637584% 75.07062146892656%;
    }

    // icon dimensions
    @mixin main-icon-arrow-left-black-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-left-right-yellow {
        @include main-sprite-icons;
        @include main-icon-arrow-left-right-yellow-dim;
        background-position: 11.366711772665765% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-arrow-left-right-yellow-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-right-black {
        @include main-sprite-icons;
        @include main-icon-arrow-right-black-dim;
        background-position: 33.557046979865774% 75.07062146892656%;
    }

    // icon dimensions
    @mixin main-icon-arrow-right-black-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-right-grey {
        @include main-sprite-icons;
        @include main-icon-arrow-right-grey-dim;
        background-position: 36.51006711409396% 75.07062146892656%;
    }

    // icon dimensions
    @mixin main-icon-arrow-right-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-right-grey-dark {
        @include main-sprite-icons;
        @include main-icon-arrow-right-grey-dark-dim;
        background-position: 39.46308724832215% 75.07062146892656%;
    }

    // icon dimensions
    @mixin main-icon-arrow-right-grey-dark-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-right-grey2 {
        @include main-sprite-icons;
        @include main-icon-arrow-right-grey2-dim;
        background-position: 42.41610738255034% 75.07062146892656%;
    }

    // icon dimensions
    @mixin main-icon-arrow-right-grey2-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-right-white {
        @include main-sprite-icons;
        @include main-icon-arrow-right-white-dim;
        background-position: 45.369127516778526% 75.07062146892656%;
    }

    // icon dimensions
    @mixin main-icon-arrow-right-white-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-round-down {
        @include main-sprite-icons;
        @include main-icon-arrow-round-down-dim;
        background-position: 15.155615696887686% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-arrow-round-down-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-round-up {
        @include main-sprite-icons;
        @include main-icon-arrow-round-up-dim;
        background-position: 18.944519621109606% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-arrow-round-up-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-top-red {
        @include main-sprite-icons;
        @include main-icon-arrow-top-red-dim;
        background-position: 48.32214765100671% 75.07062146892656%;
    }

    // icon dimensions
    @mixin main-icon-arrow-top-red-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-up {
        @include main-sprite-icons;
        @include main-icon-arrow-up-dim;
        background-position: 51.2751677852349% 75.07062146892656%;
    }

    // icon dimensions
    @mixin main-icon-arrow-up-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-up-grey {
        @include main-sprite-icons;
        @include main-icon-arrow-up-grey-dim;
        background-position: 54.22818791946309% 75.07062146892656%;
    }

    // icon dimensions
    @mixin main-icon-arrow-up-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-up-grey2 {
        @include main-sprite-icons;
        @include main-icon-arrow-up-grey2-dim;
        background-position: 57.18120805369127% 75.07062146892656%;
    }

    // icon dimensions
    @mixin main-icon-arrow-up-grey2-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-upright {
        @include main-sprite-icons;
        @include main-icon-arrow-upright-dim;
        background-position: 78.02663115845539% 61.06442577030813%;
    }

    // icon dimensions
    @mixin main-icon-arrow-upright-dim {
        width: 32px;
        height: 32px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-arrow-yellow {
        @include main-sprite-icons;
        @include main-icon-arrow-yellow-dim;
        background-position: 60.13422818791946% 75.07062146892656%;
    }

    // icon dimensions
    @mixin main-icon-arrow-yellow-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-assignment {
        @include main-sprite-icons;
        @include main-icon-assignment-dim;
        background-position: 63.08724832214765% 75.07062146892656%;
    }

    // icon dimensions
    @mixin main-icon-assignment-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-assignment-grey {
        @include main-sprite-icons;
        @include main-icon-assignment-grey-dim;
        background-position: 66.04026845637584% 75.07062146892656%;
    }

    // icon dimensions
    @mixin main-icon-assignment-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-assignment-turned-in {
        @include main-sprite-icons;
        @include main-icon-assignment-turned-in-dim;
        background-position: 68.99328859060402% 75.07062146892656%;
    }

    // icon dimensions
    @mixin main-icon-assignment-turned-in-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-assignment-turned-in-grey {
        @include main-sprite-icons;
        @include main-icon-assignment-turned-in-grey-dim;
        background-position: 71.94630872483222% 75.07062146892656%;
    }

    // icon dimensions
    @mixin main-icon-assignment-turned-in-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-attachement {
        @include main-sprite-icons;
        @include main-icon-attachement-dim;
        background-position: 74.8993288590604% 75.07062146892656%;
    }

    // icon dimensions
    @mixin main-icon-attachement-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-attachement-grey {
        @include main-sprite-icons;
        @include main-icon-attachement-grey-dim;
        background-position: 0 90.67796610169492%;
    }

    // icon dimensions
    @mixin main-icon-attachement-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-backspace {
        @include main-sprite-icons;
        @include main-icon-backspace-dim;
        background-position: 2.953020134228188% 90.67796610169492%;
    }

    // icon dimensions
    @mixin main-icon-backspace-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-backspace-grey {
        @include main-sprite-icons;
        @include main-icon-backspace-grey-dim;
        background-position: 5.906040268456376% 90.67796610169492%;
    }

    // icon dimensions
    @mixin main-icon-backspace-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-banderole-left {
        @include main-sprite-icons;
        @include main-icon-banderole-left-dim;
        background-position: 0 70.3731343283582%;
    }

    // icon dimensions
    @mixin main-icon-banderole-left-dim {
        width: 120px;
        height: 120px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-banderole-left-old {
        @include main-sprite-icons;
        @include main-icon-banderole-left-old-dim;
        background-position: 8.486562942008486% 70.3731343283582%;
    }

    // icon dimensions
    @mixin main-icon-banderole-left-old-dim {
        width: 120px;
        height: 120px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-banderole-left-small {
        @include main-sprite-icons;
        @include main-icon-banderole-left-small-dim;
        background-position: 85.01754385964912% 3.6931818181818183%;
    }

    // icon dimensions
    @mixin main-icon-banderole-left-small-dim {
        width: 52px;
        height: 52px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-banderole-left-white {
        @include main-sprite-icons;
        @include main-icon-banderole-left-white-dim;
        background-position: 16.973125884016973% 70.3731343283582%;
    }

    // icon dimensions
    @mixin main-icon-banderole-left-white-dim {
        width: 120px;
        height: 120px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-banderole-right {
        @include main-sprite-icons;
        @include main-icon-banderole-right-dim;
        background-position: 25.45968882602546% 70.3731343283582%;
    }

    // icon dimensions
    @mixin main-icon-banderole-right-dim {
        width: 120px;
        height: 120px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-banderole-right-old {
        @include main-sprite-icons;
        @include main-icon-banderole-right-old-dim;
        background-position: 33.946251768033946% 70.3731343283582%;
    }

    // icon dimensions
    @mixin main-icon-banderole-right-old-dim {
        width: 120px;
        height: 120px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-banderole-right-small {
        @include main-sprite-icons;
        @include main-icon-banderole-right-small-dim;
        background-position: 85.01754385964912% 7.386363636363637%;
    }

    // icon dimensions
    @mixin main-icon-banderole-right-small-dim {
        width: 52px;
        height: 52px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-banderole-right-white {
        @include main-sprite-icons;
        @include main-icon-banderole-right-white-dim;
        background-position: 42.432814710042436% 70.3731343283582%;
    }

    // icon dimensions
    @mixin main-icon-banderole-right-white-dim {
        width: 120px;
        height: 120px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-become-member {
        @include main-sprite-icons;
        @include main-icon-become-member-dim;
        background-position: 10.83815028901734% 23.129770992366414%;
    }

    // icon dimensions
    @mixin main-icon-become-member-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-bike-full {
        @include main-sprite-icons;
        @include main-icon-bike-full-dim;
        background-position: 8.859060402684564% 90.67796610169492%;
    }

    // icon dimensions
    @mixin main-icon-bike-full-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-blackberry {
        @include main-sprite-icons;
        @include main-icon-blackberry-dim;
        background-position: 22.73342354533153% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-blackberry-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-bonus {
        @include main-sprite-icons;
        @include main-icon-bonus-dim;
        background-position: 63.86440677966102% 61.24197002141327%;
    }

    // icon dimensions
    @mixin main-icon-bonus-dim {
        width: 59px;
        height: 59px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-bonus--white {
        @include main-sprite-icons;
        @include main-icon-bonus--white-dim;
        background-position: 67.86440677966101% 61.24197002141327%;
    }

    // icon dimensions
    @mixin main-icon-bonus--white-dim {
        width: 59px;
        height: 59px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-breadcrumb-right {
        @include main-sprite-icons;
        @include main-icon-breadcrumb-right-dim;
        background-position: 11.812080536912752% 90.67796610169492%;
    }

    // icon dimensions
    @mixin main-icon-breadcrumb-right-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-breadcrump-arrow {
        @include main-sprite-icons;
        @include main-icon-breadcrump-arrow-dim;
        background-position: 14.765100671140939% 90.67796610169492%;
    }

    // icon dimensions
    @mixin main-icon-breadcrump-arrow-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-breakdown-services {
        @include main-sprite-icons;
        @include main-icon-breakdown-services-dim;
        background-position: 88.0510067114094% 87.55274261603375%;
    }

    // icon dimensions
    @mixin main-icon-breakdown-services-dim {
        width: 44px;
        height: 38px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-breakdown-services-grey {
        @include main-sprite-icons;
        @include main-icon-breakdown-services-grey-dim;
        background-position: 17.71812080536913% 90.29535864978902%;
    }

    // icon dimensions
    @mixin main-icon-breakdown-services-grey-dim {
        width: 44px;
        height: 38px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-btn-arrow {
        @include main-sprite-icons;
        @include main-icon-btn-arrow-dim;
        background-position: 71.86440677966101% 61.24197002141327%;
    }

    // icon dimensions
    @mixin main-icon-btn-arrow-dim {
        width: 59px;
        height: 59px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-btn-arrow-black-back {
        @include main-sprite-icons;
        @include main-icon-btn-arrow-black-back-dim;
        background-position: 71.59322033898304% 67.30906495360456%;
    }

    // icon dimensions
    @mixin main-icon-btn-arrow-black-back-dim {
        width: 59px;
        height: 59px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-btn-arrow-red {
        @include main-sprite-icons;
        @include main-icon-btn-arrow-red-dim;
        background-position: 0 75.87437544610992%;
    }

    // icon dimensions
    @mixin main-icon-btn-arrow-red-dim {
        width: 59px;
        height: 59px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-btn-arrow-white {
        @include main-sprite-icons;
        @include main-icon-btn-arrow-white-dim;
        background-position: 4% 75.87437544610992%;
    }

    // icon dimensions
    @mixin main-icon-btn-arrow-white-dim {
        width: 59px;
        height: 59px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-btn-arrow-white-back {
        @include main-sprite-icons;
        @include main-icon-btn-arrow-white-back-dim;
        background-position: 8% 75.87437544610992%;
    }

    // icon dimensions
    @mixin main-icon-btn-arrow-white-back-dim {
        width: 59px;
        height: 59px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-btn-arrow_large {
        @include main-sprite-icons;
        @include main-icon-btn-arrow_large-dim;
        background-position: 62.876254180602004% 56.01688951442646%;
    }

    // icon dimensions
    @mixin main-icon-btn-arrow_large-dim {
        width: 39px;
        height: 39px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-btn-arrow_large_disabled {
        @include main-sprite-icons;
        @include main-icon-btn-arrow_large_disabled-dim;
        background-position: 81.53846153846153% 82.899366643209%;
    }

    // icon dimensions
    @mixin main-icon-btn-arrow_large_disabled-dim {
        width: 39px;
        height: 39px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-bullet {
        @include main-sprite-icons;
        @include main-icon-bullet-dim;
        background-position: 91.13825503355704% 0;
    }

    // icon dimensions
    @mixin main-icon-bullet-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-bullet-yellow-6 {
        @include main-sprite-icons;
        @include main-icon-bullet-yellow-6-dim;
        background-position: 21.63515312916112% 18.41736694677871%;
    }

    // icon dimensions
    @mixin main-icon-bullet-yellow-6-dim {
        width: 32px;
        height: 32px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-calculate {
        @include main-sprite-icons;
        @include main-icon-calculate-dim;
        background-position: 23.128342245989305% 90.29535864978902%;
    }

    // icon dimensions
    @mixin main-icon-calculate-dim {
        width: 38px;
        height: 38px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-calculate-grey {
        @include main-sprite-icons;
        @include main-icon-calculate-grey-dim;
        background-position: 91.13825503355704% 3.1073446327683616%;
    }

    // icon dimensions
    @mixin main-icon-calculate-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-cancel {
        @include main-sprite-icons;
        @include main-icon-cancel-dim;
        background-position: 91.13825503355704% 6.214689265536723%;
    }

    // icon dimensions
    @mixin main-icon-cancel-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-cancel-black {
        @include main-sprite-icons;
        @include main-icon-cancel-black-dim;
        background-position: 91.13825503355704% 9.322033898305085%;
    }

    // icon dimensions
    @mixin main-icon-cancel-black-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-cancel-grey {
        @include main-sprite-icons;
        @include main-icon-cancel-grey-dim;
        background-position: 91.13825503355704% 12.429378531073446%;
    }

    // icon dimensions
    @mixin main-icon-cancel-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-cancle-grey {
        @include main-sprite-icons;
        @include main-icon-cancle-grey-dim;
        background-position: 91.13825503355704% 15.536723163841808%;
    }

    // icon dimensions
    @mixin main-icon-cancle-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-car-not-start {
        @include main-sprite-icons;
        @include main-icon-car-not-start-dim;
        background-position: 76.13596193065942% 55.52367288378766%;
    }

    // icon dimensions
    @mixin main-icon-car-not-start-dim {
        width: 63px;
        height: 66px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-check {
        @include main-sprite-icons;
        @include main-icon-check-dim;
        background-position: 91.13825503355704% 18.64406779661017%;
    }

    // icon dimensions
    @mixin main-icon-check-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-check-box {
        @include main-sprite-icons;
        @include main-icon-check-box-dim;
        background-position: 91.13825503355704% 21.75141242937853%;
    }

    // icon dimensions
    @mixin main-icon-check-box-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-check-box-green {
        @include main-sprite-icons;
        @include main-icon-check-box-green-dim;
        background-position: 91.13825503355704% 24.858757062146893%;
    }

    // icon dimensions
    @mixin main-icon-check-box-green-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-check-box-green-focus {
        @include main-sprite-icons;
        @include main-icon-check-box-green-focus-dim;
        background-position: 91.13825503355704% 27.966101694915253%;
    }

    // icon dimensions
    @mixin main-icon-check-box-green-focus-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-check-box-light-green {
        @include main-sprite-icons;
        @include main-icon-check-box-light-green-dim;
        background-position: 91.13825503355704% 31.073446327683616%;
    }

    // icon dimensions
    @mixin main-icon-check-box-light-green-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-check-box-old {
        @include main-sprite-icons;
        @include main-icon-check-box-old-dim;
        background-position: 91.13825503355704% 34.18079096045198%;
    }

    // icon dimensions
    @mixin main-icon-check-box-old-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-check-box-outline {
        @include main-sprite-icons;
        @include main-icon-check-box-outline-dim;
        background-position: 91.13825503355704% 37.28813559322034%;
    }

    // icon dimensions
    @mixin main-icon-check-box-outline-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-check-box-outline-focus {
        @include main-sprite-icons;
        @include main-icon-check-box-outline-focus-dim;
        background-position: 91.13825503355704% 40.3954802259887%;
    }

    // icon dimensions
    @mixin main-icon-check-box-outline-focus-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-check-box-outline-green {
        @include main-sprite-icons;
        @include main-icon-check-box-outline-green-dim;
        background-position: 91.13825503355704% 43.50282485875706%;
    }

    // icon dimensions
    @mixin main-icon-check-box-outline-green-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-check-box-outline-light-green {
        @include main-sprite-icons;
        @include main-icon-check-box-outline-light-green-dim;
        background-position: 91.13825503355704% 46.610169491525426%;
    }

    // icon dimensions
    @mixin main-icon-check-box-outline-light-green-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-check-box-outline-old {
        @include main-sprite-icons;
        @include main-icon-check-box-outline-old-dim;
        background-position: 91.13825503355704% 49.717514124293785%;
    }

    // icon dimensions
    @mixin main-icon-check-box-outline-old-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-check-box-outline-red {
        @include main-sprite-icons;
        @include main-icon-check-box-outline-red-dim;
        background-position: 91.13825503355704% 52.824858757062145%;
    }

    // icon dimensions
    @mixin main-icon-check-box-outline-red-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-check-box-outline-white {
        @include main-sprite-icons;
        @include main-icon-check-box-outline-white-dim;
        background-position: 91.13825503355704% 55.932203389830505%;
    }

    // icon dimensions
    @mixin main-icon-check-box-outline-white-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-check-box-red {
        @include main-sprite-icons;
        @include main-icon-check-box-red-dim;
        background-position: 91.13825503355704% 59.03954802259887%;
    }

    // icon dimensions
    @mixin main-icon-check-box-red-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-check-green {
        @include main-sprite-icons;
        @include main-icon-check-green-dim;
        background-position: 91.13825503355704% 62.14689265536723%;
    }

    // icon dimensions
    @mixin main-icon-check-green-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-check-grey {
        @include main-sprite-icons;
        @include main-icon-check-grey-dim;
        background-position: 91.13825503355704% 65.2542372881356%;
    }

    // icon dimensions
    @mixin main-icon-check-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-check-light-grey {
        @include main-sprite-icons;
        @include main-icon-check-light-grey-dim;
        background-position: 91.13825503355704% 68.36158192090396%;
    }

    // icon dimensions
    @mixin main-icon-check-light-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-check-processnavi {
        @include main-sprite-icons;
        @include main-icon-check-processnavi-dim;
        background-position: 91.13825503355704% 71.46892655367232%;
    }

    // icon dimensions
    @mixin main-icon-check-processnavi-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-check-yellow {
        @include main-sprite-icons;
        @include main-icon-check-yellow-dim;
        background-position: 91.13825503355704% 74.57627118644068%;
    }

    // icon dimensions
    @mixin main-icon-check-yellow-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-chevron-down {
        @include main-sprite-icons;
        @include main-icon-chevron-down-dim;
        background-position: 91.13825503355704% 77.68361581920904%;
    }

    // icon dimensions
    @mixin main-icon-chevron-down-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-chevron-down-grey {
        @include main-sprite-icons;
        @include main-icon-chevron-down-grey-dim;
        background-position: 91.13825503355704% 80.7909604519774%;
    }

    // icon dimensions
    @mixin main-icon-chevron-down-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-chevron-down-light-grey {
        @include main-sprite-icons;
        @include main-icon-chevron-down-light-grey-dim;
        background-position: 91.13825503355704% 83.89830508474576%;
    }

    // icon dimensions
    @mixin main-icon-chevron-down-light-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-chevron-up {
        @include main-sprite-icons;
        @include main-icon-chevron-up-dim;
        background-position: 91.13825503355704% 87.00564971751412%;
    }

    // icon dimensions
    @mixin main-icon-chevron-up-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-chevron-up-grey {
        @include main-sprite-icons;
        @include main-icon-chevron-up-grey-dim;
        background-position: 91.13825503355704% 90.11299435028249%;
    }

    // icon dimensions
    @mixin main-icon-chevron-up-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-chevron-up-light-grey {
        @include main-sprite-icons;
        @include main-icon-chevron-up-light-grey-dim;
        background-position: 0 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-chevron-up-light-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-choose {
        @include main-sprite-icons;
        @include main-icon-choose-dim;
        background-position: 26.52232746955345% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-choose-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-choose-grey {
        @include main-sprite-icons;
        @include main-icon-choose-grey-dim;
        background-position: 30.311231393775373% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-choose-grey-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-choose-small {
        @include main-sprite-icons;
        @include main-icon-choose-small-dim;
        background-position: 50.26917900403768% 87.39376770538243%;
    }

    // icon dimensions
    @mixin main-icon-choose-small-dim {
        width: 48px;
        height: 48px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-choose-small-grey {
        @include main-sprite-icons;
        @include main-icon-choose-small-grey-dim;
        background-position: 53.4993270524899% 87.39376770538243%;
    }

    // icon dimensions
    @mixin main-icon-choose-small-grey-dim {
        width: 48px;
        height: 48px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-choose-thin {
        @include main-sprite-icons;
        @include main-icon-choose-thin-dim;
        background-position: 34.10013531799729% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-choose-thin-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-choose-thin-grey {
        @include main-sprite-icons;
        @include main-icon-choose-thin-grey-dim;
        background-position: 37.88903924221921% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-choose-thin-grey-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-circle-empty {
        @include main-sprite-icons;
        @include main-icon-circle-empty-dim;
        background-position: 88.16935483870968% 0;
    }

    // icon dimensions
    @mixin main-icon-circle-empty-dim {
        width: 46px;
        height: 46px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-circle-empty-black {
        @include main-sprite-icons;
        @include main-icon-circle-empty-black-dim;
        background-position: 56.729475100942125% 87.39376770538243%;
    }

    // icon dimensions
    @mixin main-icon-circle-empty-black-dim {
        width: 48px;
        height: 48px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-circle-full {
        @include main-sprite-icons;
        @include main-icon-circle-full-dim;
        background-position: 88.16935483870968% 3.253182461103253%;
    }

    // icon dimensions
    @mixin main-icon-circle-full-dim {
        width: 46px;
        height: 46px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-circle-half {
        @include main-sprite-icons;
        @include main-icon-circle-half-dim;
        background-position: 88.16935483870968% 6.506364922206506%;
    }

    // icon dimensions
    @mixin main-icon-circle-half-dim {
        width: 46px;
        height: 46px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-clone {
        @include main-sprite-icons;
        @include main-icon-clone-dim;
        background-position: 2.953020134228188% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-clone-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-close {
        @include main-sprite-icons;
        @include main-icon-close-dim;
        background-position: 5.906040268456376% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-close-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-close-black {
        @include main-sprite-icons;
        @include main-icon-close-black-dim;
        background-position: 8.859060402684564% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-close-black-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-close-dark-grey {
        @include main-sprite-icons;
        @include main-icon-close-dark-grey-dim;
        background-position: 11.812080536912752% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-close-dark-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-close-grey {
        @include main-sprite-icons;
        @include main-icon-close-grey-dim;
        background-position: 14.765100671140939% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-close-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-close-red {
        @include main-sprite-icons;
        @include main-icon-close-red-dim;
        background-position: 17.71812080536913% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-close-red-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-close-yellow {
        @include main-sprite-icons;
        @include main-icon-close-yellow-dim;
        background-position: 20.671140939597315% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-close-yellow-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-comment {
        @include main-sprite-icons;
        @include main-icon-comment-dim;
        background-position: 23.624161073825505% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-comment-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-community {
        @include main-sprite-icons;
        @include main-icon-community-dim;
        background-position: 21.67630057803468% 23.129770992366414%;
    }

    // icon dimensions
    @mixin main-icon-community-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-community-small {
        @include main-sprite-icons;
        @include main-icon-community-small-dim;
        background-position: 84.90296495956873% 47.304964539007095%;
    }

    // icon dimensions
    @mixin main-icon-community-small-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-compositetransport {
        @include main-sprite-icons;
        @include main-icon-compositetransport-dim;
        background-position: 26.57718120805369% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-compositetransport-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-consultation {
        @include main-sprite-icons;
        @include main-icon-consultation-dim;
        background-position: 25.668449197860962% 90.29535864978902%;
    }

    // icon dimensions
    @mixin main-icon-consultation-dim {
        width: 38px;
        height: 38px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-contact {
        @include main-sprite-icons;
        @include main-icon-contact-dim;
        background-position: 29.530201342281877% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-contact-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-contact-01 {
        @include main-sprite-icons;
        @include main-icon-contact-01-dim;
        background-position: 40.70556309362279% 32.357142857142854%;
    }

    // icon dimensions
    @mixin main-icon-contact-01-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-contact-adac-location {
        @include main-sprite-icons;
        @include main-icon-contact-adac-location-dim;
        background-position: 85.01754385964912% 11.032531824611032%;
    }

    // icon dimensions
    @mixin main-icon-contact-adac-location-dim {
        width: 52px;
        height: 46px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-contact-breakdown-services {
        @include main-sprite-icons;
        @include main-icon-contact-breakdown-services-dim;
        background-position: 85.01754385964912% 14.285714285714286%;
    }

    // icon dimensions
    @mixin main-icon-contact-breakdown-services-dim {
        width: 52px;
        height: 46px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-contact-consultation {
        @include main-sprite-icons;
        @include main-icon-contact-consultation-dim;
        background-position: 85.01754385964912% 17.53889674681754%;
    }

    // icon dimensions
    @mixin main-icon-contact-consultation-dim {
        width: 52px;
        height: 46px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-contact-criticism {
        @include main-sprite-icons;
        @include main-icon-contact-criticism-dim;
        background-position: 85.01754385964912% 20.792079207920793%;
    }

    // icon dimensions
    @mixin main-icon-contact-criticism-dim {
        width: 52px;
        height: 46px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-contact-damage {
        @include main-sprite-icons;
        @include main-icon-contact-damage-dim;
        background-position: 85.01754385964912% 24.045261669024047%;
    }

    // icon dimensions
    @mixin main-icon-contact-damage-dim {
        width: 52px;
        height: 46px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-contact-damage-insurance {
        @include main-sprite-icons;
        @include main-icon-contact-damage-insurance-dim;
        background-position: 85.01754385964912% 27.298444130127297%;
    }

    // icon dimensions
    @mixin main-icon-contact-damage-insurance-dim {
        width: 52px;
        height: 46px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-contact-email {
        @include main-sprite-icons;
        @include main-icon-contact-email-dim;
        background-position: 85.01754385964912% 30.55162659123055%;
    }

    // icon dimensions
    @mixin main-icon-contact-email-dim {
        width: 52px;
        height: 46px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-contact-enter {
        @include main-sprite-icons;
        @include main-icon-contact-enter-dim;
        background-position: 99.72958500669344% 82.25352112676056%;
    }

    // icon dimensions
    @mixin main-icon-contact-enter-dim {
        width: 40px;
        height: 40px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-contact-phone {
        @include main-sprite-icons;
        @include main-icon-contact-phone-dim;
        background-position: 85.01754385964912% 33.8048090523338%;
    }

    // icon dimensions
    @mixin main-icon-contact-phone-dim {
        width: 52px;
        height: 46px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-contact-phone-circle {
        @include main-sprite-icons;
        @include main-icon-contact-phone-circle-dim;
        background-position: 84.90296495956873% 50.851063829787236%;
    }

    // icon dimensions
    @mixin main-icon-contact-phone-circle-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-contact-speechbubble {
        @include main-sprite-icons;
        @include main-icon-contact-speechbubble-dim;
        background-position: 32.48322147651007% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-contact-speechbubble-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-contact-speechbubble-white {
        @include main-sprite-icons;
        @include main-icon-contact-speechbubble-white-dim;
        background-position: 35.43624161073826% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-contact-speechbubble-white-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-contact-whatsapp-01 {
        @include main-sprite-icons;
        @include main-icon-contact-whatsapp-01-dim;
        background-position: 85.01754385964912% 37.05799151343706%;
    }

    // icon dimensions
    @mixin main-icon-contact-whatsapp-01-dim {
        width: 52px;
        height: 46px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-contact-whatsapp-02 {
        @include main-sprite-icons;
        @include main-icon-contact-whatsapp-02-dim;
        background-position: 85.01754385964912% 40.31117397454031%;
    }

    // icon dimensions
    @mixin main-icon-contact-whatsapp-02-dim {
        width: 52px;
        height: 46px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-contra {
        @include main-sprite-icons;
        @include main-icon-contra-dim;
        background-position: 30.612244897959183% 21.70487106017192%;
    }

    // icon dimensions
    @mixin main-icon-contra-dim {
        width: 64px;
        height: 64px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-copy {
        @include main-sprite-icons;
        @include main-icon-copy-dim;
        background-position: 75.20270270270271% 83.78378378378379%;
    }

    // icon dimensions
    @mixin main-icon-copy-dim {
        width: 54px;
        height: 54px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-crash-car {
        @include main-sprite-icons;
        @include main-icon-crash-car-dim;
        background-position: 38.38926174496644% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-crash-car-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-customize-view-active {
        @include main-sprite-icons;
        @include main-icon-customize-view-active-dim;
        background-position: 88.0510067114094% 35.477031802120145%;
    }

    // icon dimensions
    @mixin main-icon-customize-view-active-dim {
        width: 44px;
        height: 45px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-customize-view-inactive {
        @include main-sprite-icons;
        @include main-icon-customize-view-inactive-dim;
        background-position: 41.34228187919463% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-customize-view-inactive-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-damage {
        @include main-sprite-icons;
        @include main-icon-damage-dim;
        background-position: 28.20855614973262% 90.29535864978902%;
    }

    // icon dimensions
    @mixin main-icon-damage-dim {
        width: 38px;
        height: 38px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-dashboard {
        @include main-sprite-icons;
        @include main-icon-dashboard-dim;
        background-position: 44.29530201342282% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-dashboard-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-dashboard-grey {
        @include main-sprite-icons;
        @include main-icon-dashboard-grey-dim;
        background-position: 47.24832214765101% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-dashboard-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-delete {
        @include main-sprite-icons;
        @include main-icon-delete-dim;
        background-position: 50.20134228187919% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-delete-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-delete-grey {
        @include main-sprite-icons;
        @include main-icon-delete-grey-dim;
        background-position: 53.15436241610738% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-delete-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-description {
        @include main-sprite-icons;
        @include main-icon-description-dim;
        background-position: 56.10738255033557% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-description-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-description-grey {
        @include main-sprite-icons;
        @include main-icon-description-grey-dim;
        background-position: 59.060402684563755% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-description-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-discount {
        @include main-sprite-icons;
        @include main-icon-discount-dim;
        background-position: 76.3956343792633% 40.99066762383345%;
    }

    // icon dimensions
    @mixin main-icon-discount-dim {
        width: 68px;
        height: 67px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-discount-xs {
        @include main-sprite-icons;
        @include main-icon-discount-xs-dim;
        background-position: 62.013422818791945% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-discount-xs-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-done {
        @include main-sprite-icons;
        @include main-icon-done-dim;
        background-position: 64.96644295302013% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-done-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-download {
        @include main-sprite-icons;
        @include main-icon-download-dim;
        background-position: 67.91946308724832% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-download-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-drag {
        @include main-sprite-icons;
        @include main-icon-drag-dim;
        background-position: 70.87248322147651% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-drag-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-drive-eta {
        @include main-sprite-icons;
        @include main-icon-drive-eta-dim;
        background-position: 73.8255033557047% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-drive-eta-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-drive-file {
        @include main-sprite-icons;
        @include main-icon-drive-file-dim;
        background-position: 76.77852348993288% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-drive-file-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-drive-file-grey {
        @include main-sprite-icons;
        @include main-icon-drive-file-grey-dim;
        background-position: 79.73154362416108% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-drive-file-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-edit {
        @include main-sprite-icons;
        @include main-icon-edit-dim;
        background-position: 82.68456375838926% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-edit-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-edit-grey {
        @include main-sprite-icons;
        @include main-icon-edit-grey-dim;
        background-position: 85.63758389261746% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-edit-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-eid {
        @include main-sprite-icons;
        @include main-icon-eid-dim;
        background-position: 88.59060402684564% 93.78531073446328%;
    }

    // icon dimensions
    @mixin main-icon-eid-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-email {
        @include main-sprite-icons;
        @include main-icon-email-dim;
        background-position: 94.09127516778524% 0;
    }

    // icon dimensions
    @mixin main-icon-email-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-email-draft {
        @include main-sprite-icons;
        @include main-icon-email-draft-dim;
        background-position: 94.09127516778524% 3.1073446327683616%;
    }

    // icon dimensions
    @mixin main-icon-email-draft-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-email-draft-grey {
        @include main-sprite-icons;
        @include main-icon-email-draft-grey-dim;
        background-position: 94.09127516778524% 6.214689265536723%;
    }

    // icon dimensions
    @mixin main-icon-email-draft-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-email-grey {
        @include main-sprite-icons;
        @include main-icon-email-grey-dim;
        background-position: 94.09127516778524% 9.322033898305085%;
    }

    // icon dimensions
    @mixin main-icon-email-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-email-grey2 {
        @include main-sprite-icons;
        @include main-icon-email-grey2-dim;
        background-position: 94.09127516778524% 12.429378531073446%;
    }

    // icon dimensions
    @mixin main-icon-email-grey2-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-email-send {
        @include main-sprite-icons;
        @include main-icon-email-send-dim;
        background-position: 60.5814739688979% 83.84341637010677%;
    }

    // icon dimensions
    @mixin main-icon-email-send-dim {
        width: 55px;
        height: 55px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-english {
        @include main-sprite-icons;
        @include main-icon-english-dim;
        background-position: 84.90296495956873% 54.39716312056738%;
    }

    // icon dimensions
    @mixin main-icon-english-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-error-symbol {
        @include main-sprite-icons;
        @include main-icon-error-symbol-dim;
        background-position: 94.09127516778524% 15.536723163841808%;
    }

    // icon dimensions
    @mixin main-icon-error-symbol-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-error-symbol-36px {
        @include main-sprite-icons;
        @include main-icon-error-symbol-36px-dim;
        background-position: 41.67794316644114% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-error-symbol-36px-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-error-symbol-36px-red {
        @include main-sprite-icons;
        @include main-icon-error-symbol-36px-red-dim;
        background-position: 45.46684709066306% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-error-symbol-36px-red-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-error-symbol-red {
        @include main-sprite-icons;
        @include main-icon-error-symbol-red-dim;
        background-position: 94.09127516778524% 18.64406779661017%;
    }

    // icon dimensions
    @mixin main-icon-error-symbol-red-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-error-symbol-white {
        @include main-sprite-icons;
        @include main-icon-error-symbol-white-dim;
        background-position: 94.09127516778524% 21.75141242937853%;
    }

    // icon dimensions
    @mixin main-icon-error-symbol-white-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-euro {
        @include main-sprite-icons;
        @include main-icon-euro-dim;
        background-position: 94.09127516778524% 24.858757062146893%;
    }

    // icon dimensions
    @mixin main-icon-euro-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-euro-36px {
        @include main-sprite-icons;
        @include main-icon-euro-36px-dim;
        background-position: 49.25575101488498% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-euro-36px-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-euro-white {
        @include main-sprite-icons;
        @include main-icon-euro-white-dim;
        background-position: 94.09127516778524% 27.966101694915253%;
    }

    // icon dimensions
    @mixin main-icon-euro-white-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-event {
        @include main-sprite-icons;
        @include main-icon-event-dim;
        background-position: 94.09127516778524% 31.073446327683616%;
    }

    // icon dimensions
    @mixin main-icon-event-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-event-36px {
        @include main-sprite-icons;
        @include main-icon-event-36px-dim;
        background-position: 53.0446549391069% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-event-36px-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-event-dark-grey {
        @include main-sprite-icons;
        @include main-icon-event-dark-grey-dim;
        background-position: 94.09127516778524% 34.18079096045198%;
    }

    // icon dimensions
    @mixin main-icon-event-dark-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-event-grey {
        @include main-sprite-icons;
        @include main-icon-event-grey-dim;
        background-position: 94.09127516778524% 37.28813559322034%;
    }

    // icon dimensions
    @mixin main-icon-event-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-event-grey2 {
        @include main-sprite-icons;
        @include main-icon-event-grey2-dim;
        background-position: 94.09127516778524% 40.3954802259887%;
    }

    // icon dimensions
    @mixin main-icon-event-grey2-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-expand-less {
        @include main-sprite-icons;
        @include main-icon-expand-less-dim;
        background-position: 94.09127516778524% 43.50282485875706%;
    }

    // icon dimensions
    @mixin main-icon-expand-less-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-expand-less-30px {
        @include main-sprite-icons;
        @include main-icon-expand-less-30px-dim;
        background-position: 84.90296495956873% 57.94326241134752%;
    }

    // icon dimensions
    @mixin main-icon-expand-less-30px-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-expand-less-30px-white {
        @include main-sprite-icons;
        @include main-icon-expand-less-30px-white-dim;
        background-position: 84.90296495956873% 61.48936170212766%;
    }

    // icon dimensions
    @mixin main-icon-expand-less-30px-white-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-expand-more {
        @include main-sprite-icons;
        @include main-icon-expand-more-dim;
        background-position: 94.09127516778524% 46.610169491525426%;
    }

    // icon dimensions
    @mixin main-icon-expand-more-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-expand-more-30px {
        @include main-sprite-icons;
        @include main-icon-expand-more-30px-dim;
        background-position: 84.90296495956873% 65.0354609929078%;
    }

    // icon dimensions
    @mixin main-icon-expand-more-30px-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-facebook {
        @include main-sprite-icons;
        @include main-icon-facebook-dim;
        background-position: 84.90296495956873% 68.58156028368795%;
    }

    // icon dimensions
    @mixin main-icon-facebook-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-family {
        @include main-sprite-icons;
        @include main-icon-family-dim;
        background-position: 38.29190751445087% 0;
    }

    // icon dimensions
    @mixin main-icon-family-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-filter {
        @include main-sprite-icons;
        @include main-icon-filter-dim;
        background-position: 30.74866310160428% 90.29535864978902%;
    }

    // icon dimensions
    @mixin main-icon-filter-dim {
        width: 38px;
        height: 38px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-filter-grey {
        @include main-sprite-icons;
        @include main-icon-filter-grey-dim;
        background-position: 33.288770053475936% 90.29535864978902%;
    }

    // icon dimensions
    @mixin main-icon-filter-grey-dim {
        width: 38px;
        height: 38px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-flag-europe {
        @include main-sprite-icons;
        @include main-icon-flag-europe-dim;
        background-position: 84.90296495956873% 72.12765957446808%;
    }

    // icon dimensions
    @mixin main-icon-flag-europe-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-flag-germany {
        @include main-sprite-icons;
        @include main-icon-flag-germany-dim;
        background-position: 84.90296495956873% 75.67375886524823%;
    }

    // icon dimensions
    @mixin main-icon-flag-germany-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-fullscreen {
        @include main-sprite-icons;
        @include main-icon-fullscreen-dim;
        background-position: 56.833558863328825% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-fullscreen-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-fullscreen-ext {
        @include main-sprite-icons;
        @include main-icon-fullscreen-ext-dim;
        background-position: 60.622462787550745% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-fullscreen-ext-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-fullscreen-ext-highlight {
        @include main-sprite-icons;
        @include main-icon-fullscreen-ext-highlight-dim;
        background-position: 64.41136671177267% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-fullscreen-ext-highlight-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-fullscreen-highlight {
        @include main-sprite-icons;
        @include main-icon-fullscreen-highlight-dim;
        background-position: 68.20027063599458% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-fullscreen-highlight-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-fussgaengerschutz {
        @include main-sprite-icons;
        @include main-icon-fussgaengerschutz-dim;
        background-position: 55.44827586206897% 68.53197674418605%;
    }

    // icon dimensions
    @mixin main-icon-fussgaengerschutz-dim {
        width: 84px;
        height: 84px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-german {
        @include main-sprite-icons;
        @include main-icon-german-dim;
        background-position: 84.90296495956873% 79.21985815602837%;
    }

    // icon dimensions
    @mixin main-icon-german-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-geschaeftsstelle {
        @include main-sprite-icons;
        @include main-icon-geschaeftsstelle-dim;
        background-position: 94.09127516778524% 49.717514124293785%;
    }

    // icon dimensions
    @mixin main-icon-geschaeftsstelle-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-gift {
        @include main-sprite-icons;
        @include main-icon-gift-dim;
        background-position: 99.72958500669344% 85.01055594651653%;
    }

    // icon dimensions
    @mixin main-icon-gift-dim {
        width: 40px;
        height: 39px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-gift-grey {
        @include main-sprite-icons;
        @include main-icon-gift-grey-dim;
        background-position: 99.72958500669344% 87.75510204081633%;
    }

    // icon dimensions
    @mixin main-icon-gift-grey-dim {
        width: 40px;
        height: 39px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-glasses {
        @include main-sprite-icons;
        @include main-icon-glasses-dim;
        background-position: 71.98917456021651% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-glasses-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-glasses-highlight {
        @include main-sprite-icons;
        @include main-icon-glasses-highlight-dim;
        background-position: 75.77807848443842% 79.91452991452991%;
    }

    // icon dimensions
    @mixin main-icon-glasses-highlight-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-google {
        @include main-sprite-icons;
        @include main-icon-google-dim;
        background-position: 84.90296495956873% 82.76595744680851%;
    }

    // icon dimensions
    @mixin main-icon-google-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-gps-fixed {
        @include main-sprite-icons;
        @include main-icon-gps-fixed-dim;
        background-position: 81.45872801082544% 0;
    }

    // icon dimensions
    @mixin main-icon-gps-fixed-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-gps-fixed-highlight {
        @include main-sprite-icons;
        @include main-icon-gps-fixed-highlight-dim;
        background-position: 81.45872801082544% 3.988603988603989%;
    }

    // icon dimensions
    @mixin main-icon-gps-fixed-highlight-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-heart {
        @include main-sprite-icons;
        @include main-icon-heart-dim;
        background-position: 94.09127516778524% 52.824858757062145%;
    }

    // icon dimensions
    @mixin main-icon-heart-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-home {
        @include main-sprite-icons;
        @include main-icon-home-dim;
        background-position: 94.09127516778524% 55.932203389830505%;
    }

    // icon dimensions
    @mixin main-icon-home-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-home-light-grey {
        @include main-sprite-icons;
        @include main-icon-home-light-grey-dim;
        background-position: 94.09127516778524% 59.03954802259887%;
    }

    // icon dimensions
    @mixin main-icon-home-light-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-home-tile {
        @include main-sprite-icons;
        @include main-icon-home-tile-dim;
        background-position: 38.29190751445087% 11.450381679389313%;
    }

    // icon dimensions
    @mixin main-icon-home-tile-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-info {
        @include main-sprite-icons;
        @include main-icon-info-dim;
        background-position: 99.72958500669344% 90.56338028169014%;
    }

    // icon dimensions
    @mixin main-icon-info-dim {
        width: 40px;
        height: 40px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-info-dark {
        @include main-sprite-icons;
        @include main-icon-info-dark-dim;
        background-position: 94.09127516778524% 62.14689265536723%;
    }

    // icon dimensions
    @mixin main-icon-info-dark-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-info-grey {
        @include main-sprite-icons;
        @include main-icon-info-grey-dim;
        background-position: 94.09127516778524% 65.2542372881356%;
    }

    // icon dimensions
    @mixin main-icon-info-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-info-grey2 {
        @include main-sprite-icons;
        @include main-icon-info-grey2-dim;
        background-position: 94.09127516778524% 68.36158192090396%;
    }

    // icon dimensions
    @mixin main-icon-info-grey2-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-info-white {
        @include main-sprite-icons;
        @include main-icon-info-white-dim;
        background-position: 94.09127516778524% 71.46892655367232%;
    }

    // icon dimensions
    @mixin main-icon-info-white-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-info-yellow {
        @include main-sprite-icons;
        @include main-icon-info-yellow-dim;
        background-position: 99.72958500669344% 93.38028169014085%;
    }

    // icon dimensions
    @mixin main-icon-info-yellow-dim {
        width: 40px;
        height: 40px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-insassenschutz {
        @include main-sprite-icons;
        @include main-icon-insassenschutz-dim;
        background-position: 61.241379310344826% 68.53197674418605%;
    }

    // icon dimensions
    @mixin main-icon-insassenschutz-dim {
        width: 84px;
        height: 84px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-instagram {
        @include main-sprite-icons;
        @include main-icon-instagram-dim;
        background-position: 0 87.51773049645391%;
    }

    // icon dimensions
    @mixin main-icon-instagram-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-job {
        @include main-sprite-icons;
        @include main-icon-job-dim;
        background-position: 38.29190751445087% 22.900763358778626%;
    }

    // icon dimensions
    @mixin main-icon-job-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-key {
        @include main-sprite-icons;
        @include main-icon-key-dim;
        background-position: 75.57085020242916% 60.25824964131994%;
    }

    // icon dimensions
    @mixin main-icon-key-dim {
        width: 52px;
        height: 66px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-keyboard-arrow-down {
        @include main-sprite-icons;
        @include main-icon-keyboard-arrow-down-dim;
        background-position: 94.09127516778524% 74.57627118644068%;
    }

    // icon dimensions
    @mixin main-icon-keyboard-arrow-down-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-keyboard-arrow-up {
        @include main-sprite-icons;
        @include main-icon-keyboard-arrow-up-dim;
        background-position: 94.09127516778524% 77.68361581920904%;
    }

    // icon dimensions
    @mixin main-icon-keyboard-arrow-up-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-kindersicherheit {
        @include main-sprite-icons;
        @include main-icon-kindersicherheit-dim;
        background-position: 67.03448275862068% 68.53197674418605%;
    }

    // icon dimensions
    @mixin main-icon-kindersicherheit-dim {
        width: 84px;
        height: 84px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-kompatibel {
        @include main-sprite-icons;
        @include main-icon-kompatibel-dim;
        background-position: 40.70556309362279% 36.642857142857146%;
    }

    // icon dimensions
    @mixin main-icon-kompatibel-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-krad {
        @include main-sprite-icons;
        @include main-icon-krad-dim;
        background-position: 14.770612389589672% 0;
    }

    // icon dimensions
    @mixin main-icon-krad-dim {
        width: 179.96px;
        height: 103px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-link {
        @include main-sprite-icons;
        @include main-icon-link-dim;
        background-position: 94.09127516778524% 80.7909604519774%;
    }

    // icon dimensions
    @mixin main-icon-link-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-link-back {
        @include main-sprite-icons;
        @include main-icon-link-back-dim;
        background-position: 94.09127516778524% 83.89830508474576%;
    }

    // icon dimensions
    @mixin main-icon-link-back-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-link-back-big {
        @include main-sprite-icons;
        @include main-icon-link-back-big-dim;
        background-position: 94.09127516778524% 87.00564971751412%;
    }

    // icon dimensions
    @mixin main-icon-link-back-big-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-link-back-red {
        @include main-sprite-icons;
        @include main-icon-link-back-red-dim;
        background-position: 94.09127516778524% 90.11299435028249%;
    }

    // icon dimensions
    @mixin main-icon-link-back-red-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-link-big {
        @include main-sprite-icons;
        @include main-icon-link-big-dim;
        background-position: 79.15265635507734% 87.3319179051663%;
    }

    // icon dimensions
    @mixin main-icon-link-big-dim {
        width: 47px;
        height: 47px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-link-grey {
        @include main-sprite-icons;
        @include main-icon-link-grey-dim;
        background-position: 94.09127516778524% 93.22033898305085%;
    }

    // icon dimensions
    @mixin main-icon-link-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-link-grey2 {
        @include main-sprite-icons;
        @include main-icon-link-grey2-dim;
        background-position: 0 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-link-grey2-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-link-headline {
        @include main-sprite-icons;
        @include main-icon-link-headline-dim;
        background-position: 82.31338264963013% 87.3319179051663%;
    }

    // icon dimensions
    @mixin main-icon-link-headline-dim {
        width: 47px;
        height: 47px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-link-red {
        @include main-sprite-icons;
        @include main-icon-link-red-dim;
        background-position: 2.953020134228188% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-link-red-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-list {
        @include main-sprite-icons;
        @include main-icon-list-dim;
        background-position: 5.906040268456376% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-list-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-list-grey {
        @include main-sprite-icons;
        @include main-icon-list-grey-dim;
        background-position: 8.859060402684564% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-list-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-list-grey2 {
        @include main-sprite-icons;
        @include main-icon-list-grey2-dim;
        background-position: 11.812080536912752% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-list-grey2-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-lock {
        @include main-sprite-icons;
        @include main-icon-lock-dim;
        background-position: 14.765100671140939% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-lock-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-login {
        @include main-sprite-icons;
        @include main-icon-login-dim;
        background-position: 0 34.58015267175573%;
    }

    // icon dimensions
    @mixin main-icon-login-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-login-circle-mark {
        @include main-sprite-icons;
        @include main-icon-login-circle-mark-dim;
        background-position: 17.71812080536913% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-login-circle-mark-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-logo {
        @include main-sprite-icons;
        @include main-icon-logo-dim;
        background-position: 77.02613480055021% 5.923836389280677%;
    }

    // icon dimensions
    @mixin main-icon-logo-dim {
        width: 80px;
        height: 42px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-logo-desktop-refresh {
        @include main-sprite-icons;
        @include main-icon-logo-desktop-refresh-dim;
        background-position: 68.81741573033707% 54.97869318181818%;
    }

    // icon dimensions
    @mixin main-icon-logo-desktop-refresh-dim {
        width: 110px;
        height: 52px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-logo-tablet-refresh {
        @include main-sprite-icons;
        @include main-icon-logo-tablet-refresh-dim;
        background-position: 60.91160220994475% 53.14043754410727%;
    }

    // icon dimensions
    @mixin main-icon-logo-tablet-refresh-dim {
        width: 86px;
        height: 43px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-map {
        @include main-sprite-icons;
        @include main-icon-map-dim;
        background-position: 20.671140939597315% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-map-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-map-grey {
        @include main-sprite-icons;
        @include main-icon-map-grey-dim;
        background-position: 23.624161073825505% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-map-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-media-play-l {
        @include main-sprite-icons;
        @include main-icon-media-play-l-dim;
        background-position: 81.45872801082544% 7.977207977207978%;
    }

    // icon dimensions
    @mixin main-icon-media-play-l-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-media-play-m {
        @include main-sprite-icons;
        @include main-icon-media-play-m-dim;
        background-position: 81.45872801082544% 11.965811965811966%;
    }

    // icon dimensions
    @mixin main-icon-media-play-m-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-media-play-s {
        @include main-sprite-icons;
        @include main-icon-media-play-s-dim;
        background-position: 81.45872801082544% 15.954415954415955%;
    }

    // icon dimensions
    @mixin main-icon-media-play-s-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-media-play-xl {
        @include main-sprite-icons;
        @include main-icon-media-play-xl-dim;
        background-position: 77.02613480055021% 9.130434782608695%;
    }

    // icon dimensions
    @mixin main-icon-media-play-xl-dim {
        width: 80px;
        height: 80px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-megaphone {
        @include main-sprite-icons;
        @include main-icon-megaphone-dim;
        background-position: 35.82887700534759% 90.29535864978902%;
    }

    // icon dimensions
    @mixin main-icon-megaphone-dim {
        width: 38px;
        height: 38px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-member-child-18-23 {
        @include main-sprite-icons;
        @include main-icon-member-child-18-23-dim;
        background-position: 81.45872801082544% 19.943019943019944%;
    }

    // icon dimensions
    @mixin main-icon-member-child-18-23-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-member-child-18-23-plus {
        @include main-sprite-icons;
        @include main-icon-member-child-18-23-plus-dim;
        background-position: 81.45872801082544% 23.931623931623932%;
    }

    // icon dimensions
    @mixin main-icon-member-child-18-23-plus-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-member-child-18-smaller {
        @include main-sprite-icons;
        @include main-icon-member-child-18-smaller-dim;
        background-position: 81.45872801082544% 27.92022792022792%;
    }

    // icon dimensions
    @mixin main-icon-member-child-18-smaller-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-member-partner {
        @include main-sprite-icons;
        @include main-icon-member-partner-dim;
        background-position: 81.45872801082544% 31.90883190883191%;
    }

    // icon dimensions
    @mixin main-icon-member-partner-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-member-personally {
        @include main-sprite-icons;
        @include main-icon-member-personally-dim;
        background-position: 81.45872801082544% 35.8974358974359%;
    }

    // icon dimensions
    @mixin main-icon-member-personally-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-member-plus {
        @include main-sprite-icons;
        @include main-icon-member-plus-dim;
        background-position: 10.83815028901734% 34.58015267175573%;
    }

    // icon dimensions
    @mixin main-icon-member-plus-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-member-three-children-18-23-plus {
        @include main-sprite-icons;
        @include main-icon-member-three-children-18-23-plus-dim;
        background-position: 81.45872801082544% 39.88603988603989%;
    }

    // icon dimensions
    @mixin main-icon-member-three-children-18-23-plus-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-member-two-children-18-23-plus {
        @include main-sprite-icons;
        @include main-icon-member-two-children-18-23-plus-dim;
        background-position: 81.45872801082544% 43.87464387464387%;
    }

    // icon dimensions
    @mixin main-icon-member-two-children-18-23-plus-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-menu {
        @include main-sprite-icons;
        @include main-icon-menu-dim;
        background-position: 26.57718120805369% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-menu-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-mfa {
        @include main-sprite-icons;
        @include main-icon-mfa-dim;
        background-position: 50.607287449392715% 43.07142857142857%;
    }

    // icon dimensions
    @mixin main-icon-mfa-dim {
        width: 52px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-misc {
        @include main-sprite-icons;
        @include main-icon-misc-dim;
        background-position: 75.92949152542373% 64.99282639885223%;
    }

    // icon dimensions
    @mixin main-icon-misc-dim {
        width: 59px;
        height: 66px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-missedCall {
        @include main-sprite-icons;
        @include main-icon-missedCall-dim;
        background-position: 88.11014103425117% 38.466947960618846%;
    }

    // icon dimensions
    @mixin main-icon-missedCall-dim {
        width: 45px;
        height: 38px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-mobile-move {
        @include main-sprite-icons;
        @include main-icon-mobile-move-dim;
        background-position: 81.45872801082544% 47.863247863247864%;
    }

    // icon dimensions
    @mixin main-icon-mobile-move-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-mobile-move-highlight {
        @include main-sprite-icons;
        @include main-icon-mobile-move-highlight-dim;
        background-position: 81.45872801082544% 51.851851851851855%;
    }

    // icon dimensions
    @mixin main-icon-mobile-move-highlight-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-motorcycle {
        @include main-sprite-icons;
        @include main-icon-motorcycle-dim;
        background-position: 21.67630057803468% 34.58015267175573%;
    }

    // icon dimensions
    @mixin main-icon-motorcycle-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-motorcycle-tile {
        @include main-sprite-icons;
        @include main-icon-motorcycle-tile-dim;
        background-position: 25.45968882602546% 62.4%;
    }

    // icon dimensions
    @mixin main-icon-motorcycle-tile-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-mountain {
        @include main-sprite-icons;
        @include main-icon-mountain-dim;
        background-position: 99.8632707774799% 76.4456981664316%;
    }

    // icon dimensions
    @mixin main-icon-mountain-dim {
        width: 42px;
        height: 42px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-move {
        @include main-sprite-icons;
        @include main-icon-move-dim;
        background-position: 77.02613480055021% 14.927536231884059%;
    }

    // icon dimensions
    @mixin main-icon-move-dim {
        width: 80px;
        height: 80px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-move-highlight {
        @include main-sprite-icons;
        @include main-icon-move-highlight-dim;
        background-position: 77.02613480055021% 20.72463768115942%;
    }

    // icon dimensions
    @mixin main-icon-move-highlight-dim {
        width: 80px;
        height: 80px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-navigate-before {
        @include main-sprite-icons;
        @include main-icon-navigate-before-dim;
        background-position: 29.530201342281877% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-navigate-before-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-navigate-next {
        @include main-sprite-icons;
        @include main-icon-navigate-next-dim;
        background-position: 32.48322147651007% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-navigate-next-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-near-me {
        @include main-sprite-icons;
        @include main-icon-near-me-dim;
        background-position: 3.3692722371967654% 87.51773049645391%;
    }

    // icon dimensions
    @mixin main-icon-near-me-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-nicht-kompatibel {
        @include main-sprite-icons;
        @include main-icon-nicht-kompatibel-dim;
        background-position: 50.88195386702849% 47.357142857142854%;
    }

    // icon dimensions
    @mixin main-icon-nicht-kompatibel-dim {
        width: 60px;
        height: 60px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-no-login {
        @include main-sprite-icons;
        @include main-icon-no-login-dim;
        background-position: 32.51445086705202% 34.58015267175573%;
    }

    // icon dimensions
    @mixin main-icon-no-login-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-pause {
        @include main-sprite-icons;
        @include main-icon-pause-dim;
        background-position: 81.45872801082544% 55.84045584045584%;
    }

    // icon dimensions
    @mixin main-icon-pause-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-pause-highlight {
        @include main-sprite-icons;
        @include main-icon-pause-highlight-dim;
        background-position: 81.45872801082544% 59.82905982905983%;
    }

    // icon dimensions
    @mixin main-icon-pause-highlight-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-pdf {
        @include main-sprite-icons;
        @include main-icon-pdf-dim;
        background-position: 35.43624161073826% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-pdf-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-pdf-grey {
        @include main-sprite-icons;
        @include main-icon-pdf-grey-dim;
        background-position: 38.38926174496644% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-pdf-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-pdf-grey2 {
        @include main-sprite-icons;
        @include main-icon-pdf-grey2-dim;
        background-position: 41.34228187919463% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-pdf-grey2-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-person {
        @include main-sprite-icons;
        @include main-icon-person-dim;
        background-position: 44.29530201342282% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-person-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-person-big {
        @include main-sprite-icons;
        @include main-icon-person-big-dim;
        background-position: 49.130057803468205% 0;
    }

    // icon dimensions
    @mixin main-icon-person-big-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-phone {
        @include main-sprite-icons;
        @include main-icon-phone-dim;
        background-position: 47.24832214765101% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-phone-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-phone-39px {
        @include main-sprite-icons;
        @include main-icon-phone-39px-dim;
        background-position: 12% 75.87437544610992%;
    }

    // icon dimensions
    @mixin main-icon-phone-39px-dim {
        width: 59px;
        height: 59px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-phone-emergency {
        @include main-sprite-icons;
        @include main-icon-phone-emergency-dim;
        background-position: 50.20134228187919% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-phone-emergency-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-phone-emergency-39px {
        @include main-sprite-icons;
        @include main-icon-phone-emergency-39px-dim;
        background-position: 16% 75.87437544610992%;
    }

    // icon dimensions
    @mixin main-icon-phone-emergency-39px-dim {
        width: 59px;
        height: 59px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-phone-grey {
        @include main-sprite-icons;
        @include main-icon-phone-grey-dim;
        background-position: 53.15436241610738% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-phone-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-phone-grey2 {
        @include main-sprite-icons;
        @include main-icon-phone-grey2-dim;
        background-position: 56.10738255033557% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-phone-grey2-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-phone-paragraph {
        @include main-sprite-icons;
        @include main-icon-phone-paragraph-dim;
        background-position: 59.060402684563755% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-phone-paragraph-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-phone-paragraph-39px {
        @include main-sprite-icons;
        @include main-icon-phone-paragraph-39px-dim;
        background-position: 20% 75.87437544610992%;
    }

    // icon dimensions
    @mixin main-icon-phone-paragraph-39px-dim {
        width: 59px;
        height: 59px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-pickup {
        @include main-sprite-icons;
        @include main-icon-pickup-dim;
        background-position: 62.013422818791945% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-pickup-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-picture-as-pdf {
        @include main-sprite-icons;
        @include main-icon-picture-as-pdf-dim;
        background-position: 64.96644295302013% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-picture-as-pdf-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-pin-cardamage-position {
        @include main-sprite-icons;
        @include main-icon-pin-cardamage-position-dim;
        background-position: 21.882828282828285% 14.316392269148174%;
    }

    // icon dimensions
    @mixin main-icon-pin-cardamage-position-dim {
        width: 49px;
        height: 63px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-pin-your-position {
        @include main-sprite-icons;
        @include main-icon-pin-your-position-dim;
        background-position: 38.34335337341349% 90.23190442726634%;
    }

    // icon dimensions
    @mixin main-icon-pin-your-position-dim {
        width: 37px;
        height: 37px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-pinterest {
        @include main-sprite-icons;
        @include main-icon-pinterest-dim;
        background-position: 6.738544474393531% 87.51773049645391%;
    }

    // icon dimensions
    @mixin main-icon-pinterest-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-pkw {
        @include main-sprite-icons;
        @include main-icon-pkw-dim;
        background-position: 14.406627048442283% 7.545787545787546%;
    }

    // icon dimensions
    @mixin main-icon-pkw-dim {
        width: 145.75px;
        height: 95px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-pkw-dropdown {
        @include main-sprite-icons;
        @include main-icon-pkw-dropdown-dim;
        background-position: 59.75609756097561% 56.21468926553672%;
    }

    // icon dimensions
    @mixin main-icon-pkw-dropdown-dim {
        width: 58px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-pkw-small {
        @include main-sprite-icons;
        @include main-icon-pkw-small-dim;
        background-position: 49.130057803468205% 11.450381679389313%;
    }

    // icon dimensions
    @mixin main-icon-pkw-small-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-pkw-tile {
        @include main-sprite-icons;
        @include main-icon-pkw-tile-dim;
        background-position: 33.946251768033946% 62.4%;
    }

    // icon dimensions
    @mixin main-icon-pkw-tile-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-place {
        @include main-sprite-icons;
        @include main-icon-place-dim;
        background-position: 67.91946308724832% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-place-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-place-36px {
        @include main-sprite-icons;
        @include main-icon-place-36px-dim;
        background-position: 81.45872801082544% 63.81766381766382%;
    }

    // icon dimensions
    @mixin main-icon-place-36px-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-place-green-36px {
        @include main-sprite-icons;
        @include main-icon-place-green-36px-dim;
        background-position: 81.45872801082544% 67.80626780626781%;
    }

    // icon dimensions
    @mixin main-icon-place-green-36px-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-place-light-grey {
        @include main-sprite-icons;
        @include main-icon-place-light-grey-dim;
        background-position: 70.87248322147651% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-place-light-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-place-light-grey-36px {
        @include main-sprite-icons;
        @include main-icon-place-light-grey-36px-dim;
        background-position: 81.45872801082544% 71.7948717948718%;
    }

    // icon dimensions
    @mixin main-icon-place-light-grey-36px-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-place-lightblue-36px {
        @include main-sprite-icons;
        @include main-icon-place-lightblue-36px-dim;
        background-position: 81.45872801082544% 75.78347578347578%;
    }

    // icon dimensions
    @mixin main-icon-place-lightblue-36px-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-place-orange-36px {
        @include main-sprite-icons;
        @include main-icon-place-orange-36px-dim;
        background-position: 81.45872801082544% 79.77207977207978%;
    }

    // icon dimensions
    @mixin main-icon-place-orange-36px-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-place-yellow {
        @include main-sprite-icons;
        @include main-icon-place-yellow-dim;
        background-position: 73.8255033557047% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-place-yellow-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-place-yellow-36px {
        @include main-sprite-icons;
        @include main-icon-place-yellow-36px-dim;
        background-position: 0 83.9031339031339%;
    }

    // icon dimensions
    @mixin main-icon-place-yellow-36px-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-placeholder {
        @include main-sprite-icons;
        @include main-icon-placeholder-dim;
        background-position: 76.77852348993288% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-placeholder-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-play-arrow {
        @include main-sprite-icons;
        @include main-icon-play-arrow-dim;
        background-position: 3.7889039242219216% 83.9031339031339%;
    }

    // icon dimensions
    @mixin main-icon-play-arrow-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-play-arrow-black {
        @include main-sprite-icons;
        @include main-icon-play-arrow-black-dim;
        background-position: 99.72958500669344% 96.19718309859155%;
    }

    // icon dimensions
    @mixin main-icon-play-arrow-black-dim {
        width: 40px;
        height: 40px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-play-arrow-black-36 {
        @include main-sprite-icons;
        @include main-icon-play-arrow-black-36-dim;
        background-position: 7.577807848443843% 83.9031339031339%;
    }

    // icon dimensions
    @mixin main-icon-play-arrow-black-36-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-play-arrow-highlight {
        @include main-sprite-icons;
        @include main-icon-play-arrow-highlight-dim;
        background-position: 11.366711772665765% 83.9031339031339%;
    }

    // icon dimensions
    @mixin main-icon-play-arrow-highlight-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-play-arrow-white {
        @include main-sprite-icons;
        @include main-icon-play-arrow-white-dim;
        background-position: 99.72958500669344% 99.01408450704226%;
    }

    // icon dimensions
    @mixin main-icon-play-arrow-white-dim {
        width: 40px;
        height: 40px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-play-arrow-white-36 {
        @include main-sprite-icons;
        @include main-icon-play-arrow-white-36-dim;
        background-position: 15.155615696887686% 83.9031339031339%;
    }

    // icon dimensions
    @mixin main-icon-play-arrow-white-36-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-plus {
        @include main-sprite-icons;
        @include main-icon-plus-dim;
        background-position: 79.73154362416108% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-plus-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-plus-grey {
        @include main-sprite-icons;
        @include main-icon-plus-grey-dim;
        background-position: 82.68456375838926% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-plus-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-print {
        @include main-sprite-icons;
        @include main-icon-print-dim;
        background-position: 85.63758389261746% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-print-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-print-grey {
        @include main-sprite-icons;
        @include main-icon-print-grey-dim;
        background-position: 88.59060402684564% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-print-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-print-grey2 {
        @include main-sprite-icons;
        @include main-icon-print-grey2-dim;
        background-position: 91.54362416107382% 96.89265536723164%;
    }

    // icon dimensions
    @mixin main-icon-print-grey2-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-private {
        @include main-sprite-icons;
        @include main-icon-private-dim;
        background-position: 49.130057803468205% 22.900763358778626%;
    }

    // icon dimensions
    @mixin main-icon-private-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-pro {
        @include main-sprite-icons;
        @include main-icon-pro-dim;
        background-position: 30.612244897959183% 26.289398280802292%;
    }

    // icon dimensions
    @mixin main-icon-pro-dim {
        width: 64px;
        height: 64px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-public {
        @include main-sprite-icons;
        @include main-icon-public-dim;
        background-position: 10.107816711590296% 87.51773049645391%;
    }

    // icon dimensions
    @mixin main-icon-public-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-quad {
        @include main-sprite-icons;
        @include main-icon-quad-dim;
        background-position: 70.07222023596711% 20.512820512820515%;
    }

    // icon dimensions
    @mixin main-icon-quad-dim {
        width: 135.5px;
        height: 95px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-radio-button-checked {
        @include main-sprite-icons;
        @include main-icon-radio-button-checked-dim;
        background-position: 97.04429530201342% 0;
    }

    // icon dimensions
    @mixin main-icon-radio-button-checked-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-radio-button-checked-focus {
        @include main-sprite-icons;
        @include main-icon-radio-button-checked-focus-dim;
        background-position: 97.04429530201342% 3.1073446327683616%;
    }

    // icon dimensions
    @mixin main-icon-radio-button-checked-focus-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-radio-button-checked-focus-black {
        @include main-sprite-icons;
        @include main-icon-radio-button-checked-focus-black-dim;
        background-position: 97.04429530201342% 6.214689265536723%;
    }

    // icon dimensions
    @mixin main-icon-radio-button-checked-focus-black-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-radio-button-checked-old {
        @include main-sprite-icons;
        @include main-icon-radio-button-checked-old-dim;
        background-position: 97.04429530201342% 9.322033898305085%;
    }

    // icon dimensions
    @mixin main-icon-radio-button-checked-old-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-radio-button-checked-old-old {
        @include main-sprite-icons;
        @include main-icon-radio-button-checked-old-old-dim;
        background-position: 97.04429530201342% 12.429378531073446%;
    }

    // icon dimensions
    @mixin main-icon-radio-button-checked-old-old-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-radio-button-checked-red {
        @include main-sprite-icons;
        @include main-icon-radio-button-checked-red-dim;
        background-position: 97.04429530201342% 15.536723163841808%;
    }

    // icon dimensions
    @mixin main-icon-radio-button-checked-red-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-radio-button-checked-red-white {
        @include main-sprite-icons;
        @include main-icon-radio-button-checked-red-white-dim;
        background-position: 97.04429530201342% 18.64406779661017%;
    }

    // icon dimensions
    @mixin main-icon-radio-button-checked-red-white-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-radio-button-checked-white {
        @include main-sprite-icons;
        @include main-icon-radio-button-checked-white-dim;
        background-position: 97.04429530201342% 21.75141242937853%;
    }

    // icon dimensions
    @mixin main-icon-radio-button-checked-white-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-radio-button-checked-yellow {
        @include main-sprite-icons;
        @include main-icon-radio-button-checked-yellow-dim;
        background-position: 97.04429530201342% 24.858757062146893%;
    }

    // icon dimensions
    @mixin main-icon-radio-button-checked-yellow-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-radio-button-unchecked {
        @include main-sprite-icons;
        @include main-icon-radio-button-unchecked-dim;
        background-position: 97.04429530201342% 27.966101694915253%;
    }

    // icon dimensions
    @mixin main-icon-radio-button-unchecked-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-radio-button-unchecked-focus {
        @include main-sprite-icons;
        @include main-icon-radio-button-unchecked-focus-dim;
        background-position: 97.04429530201342% 31.073446327683616%;
    }

    // icon dimensions
    @mixin main-icon-radio-button-unchecked-focus-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-radio-button-unchecked-focus-black {
        @include main-sprite-icons;
        @include main-icon-radio-button-unchecked-focus-black-dim;
        background-position: 97.04429530201342% 34.18079096045198%;
    }

    // icon dimensions
    @mixin main-icon-radio-button-unchecked-focus-black-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-radio-button-unchecked-old {
        @include main-sprite-icons;
        @include main-icon-radio-button-unchecked-old-dim;
        background-position: 97.04429530201342% 37.28813559322034%;
    }

    // icon dimensions
    @mixin main-icon-radio-button-unchecked-old-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-radio-button-unchecked-red {
        @include main-sprite-icons;
        @include main-icon-radio-button-unchecked-red-dim;
        background-position: 97.04429530201342% 40.3954802259887%;
    }

    // icon dimensions
    @mixin main-icon-radio-button-unchecked-red-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-radio-button-unchecked-red-white {
        @include main-sprite-icons;
        @include main-icon-radio-button-unchecked-red-white-dim;
        background-position: 97.04429530201342% 43.50282485875706%;
    }

    // icon dimensions
    @mixin main-icon-radio-button-unchecked-red-white-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-radio-button-unchecked-white {
        @include main-sprite-icons;
        @include main-icon-radio-button-unchecked-white-dim;
        background-position: 97.04429530201342% 46.610169491525426%;
    }

    // icon dimensions
    @mixin main-icon-radio-button-unchecked-white-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-refresh {
        @include main-sprite-icons;
        @include main-icon-refresh-dim;
        background-position: 97.04429530201342% 49.717514124293785%;
    }

    // icon dimensions
    @mixin main-icon-refresh-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-refresh-180px {
        @include main-sprite-icons;
        @include main-icon-refresh-180px-dim;
        background-position: 0 0;
    }

    // icon dimensions
    @mixin main-icon-refresh-180px-dim {
        width: 200px;
        height: 200px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-refresh-48px {
        @include main-sprite-icons;
        @include main-icon-refresh-48px-dim;
        background-position: 76.3956343792633% 45.833333333333336%;
    }

    // icon dimensions
    @mixin main-icon-refresh-48px-dim {
        width: 68px;
        height: 68px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-refresh-black {
        @include main-sprite-icons;
        @include main-icon-refresh-black-dim;
        background-position: 97.04429530201342% 52.824858757062145%;
    }

    // icon dimensions
    @mixin main-icon-refresh-black-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-refresh-grey {
        @include main-sprite-icons;
        @include main-icon-refresh-grey-dim;
        background-position: 97.04429530201342% 55.932203389830505%;
    }

    // icon dimensions
    @mixin main-icon-refresh-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-remove {
        @include main-sprite-icons;
        @include main-icon-remove-dim;
        background-position: 97.04429530201342% 59.03954802259887%;
    }

    // icon dimensions
    @mixin main-icon-remove-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-replacementvehicle {
        @include main-sprite-icons;
        @include main-icon-replacementvehicle-dim;
        background-position: 97.04429530201342% 62.14689265536723%;
    }

    // icon dimensions
    @mixin main-icon-replacementvehicle-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-report-problem {
        @include main-sprite-icons;
        @include main-icon-report-problem-dim;
        background-position: 97.04429530201342% 65.2542372881356%;
    }

    // icon dimensions
    @mixin main-icon-report-problem-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-report-problem-grey {
        @include main-sprite-icons;
        @include main-icon-report-problem-grey-dim;
        background-position: 97.04429530201342% 68.36158192090396%;
    }

    // icon dimensions
    @mixin main-icon-report-problem-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-report-received {
        @include main-sprite-icons;
        @include main-icon-report-received-dim;
        background-position: 77.64390896921017% 74.85915492957747%;
    }

    // icon dimensions
    @mixin main-icon-report-received-dim {
        width: 40px;
        height: 40px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-save {
        @include main-sprite-icons;
        @include main-icon-save-dim;
        background-position: 97.04429530201342% 71.46892655367232%;
    }

    // icon dimensions
    @mixin main-icon-save-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-schadenmeldung {
        @include main-sprite-icons;
        @include main-icon-schadenmeldung-dim;
        background-position: 97.04429530201342% 74.57627118644068%;
    }

    // icon dimensions
    @mixin main-icon-schadenmeldung-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-scroll-bar-handle {
        @include main-sprite-icons;
        @include main-icon-scroll-bar-handle-dim;
        background-position: 43.77104377104377% 87.45570517363572%;
    }

    // icon dimensions
    @mixin main-icon-scroll-bar-handle-dim {
        width: 49px;
        height: 49px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-search {
        @include main-sprite-icons;
        @include main-icon-search-dim;
        background-position: 97.04429530201342% 77.68361581920904%;
    }

    // icon dimensions
    @mixin main-icon-search-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-search-grey {
        @include main-sprite-icons;
        @include main-icon-search-grey-dim;
        background-position: 97.04429530201342% 80.7909604519774%;
    }

    // icon dimensions
    @mixin main-icon-search-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-search-grey2 {
        @include main-sprite-icons;
        @include main-icon-search-grey2-dim;
        background-position: 97.04429530201342% 83.89830508474576%;
    }

    // icon dimensions
    @mixin main-icon-search-grey2-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-selected {
        @include main-sprite-icons;
        @include main-icon-selected-dim;
        background-position: 18.944519621109606% 83.9031339031339%;
    }

    // icon dimensions
    @mixin main-icon-selected-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-selected-grey {
        @include main-sprite-icons;
        @include main-icon-selected-grey-dim;
        background-position: 22.73342354533153% 83.9031339031339%;
    }

    // icon dimensions
    @mixin main-icon-selected-grey-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-selected-light-grey {
        @include main-sprite-icons;
        @include main-icon-selected-light-grey-dim;
        background-position: 59.91930060524546% 87.39376770538243%;
    }

    // icon dimensions
    @mixin main-icon-selected-light-grey-dim {
        width: 47px;
        height: 48px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-selected-light-grey2 {
        @include main-sprite-icons;
        @include main-icon-selected-light-grey2-dim;
        background-position: 63.08002689979825% 87.39376770538243%;
    }

    // icon dimensions
    @mixin main-icon-selected-light-grey2-dim {
        width: 47px;
        height: 48px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-selected-small {
        @include main-sprite-icons;
        @include main-icon-selected-small-dim;
        background-position: 66.28532974427995% 87.39376770538243%;
    }

    // icon dimensions
    @mixin main-icon-selected-small-dim {
        width: 48px;
        height: 48px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-selected-small-grey {
        @include main-sprite-icons;
        @include main-icon-selected-small-grey-dim;
        background-position: 69.51547779273217% 87.39376770538243%;
    }

    // icon dimensions
    @mixin main-icon-selected-small-grey-dim {
        width: 48px;
        height: 48px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-selected-thin {
        @include main-sprite-icons;
        @include main-icon-selected-thin-dim;
        background-position: 26.52232746955345% 83.9031339031339%;
    }

    // icon dimensions
    @mixin main-icon-selected-thin-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-selected-thin-grey {
        @include main-sprite-icons;
        @include main-icon-selected-thin-grey-dim;
        background-position: 30.311231393775373% 83.9031339031339%;
    }

    // icon dimensions
    @mixin main-icon-selected-thin-grey-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-selected-thin-transparent {
        @include main-sprite-icons;
        @include main-icon-selected-thin-transparent-dim;
        background-position: 72.74562584118439% 87.39376770538243%;
    }

    // icon dimensions
    @mixin main-icon-selected-thin-transparent-dim {
        width: 48px;
        height: 48px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-selected-transparent {
        @include main-sprite-icons;
        @include main-icon-selected-transparent-dim;
        background-position: 34.10013531799729% 83.9031339031339%;
    }

    // icon dimensions
    @mixin main-icon-selected-transparent-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-selfid {
        @include main-sprite-icons;
        @include main-icon-selfid-dim;
        background-position: 97.04429530201342% 87.00564971751412%;
    }

    // icon dimensions
    @mixin main-icon-selfid-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-settings {
        @include main-sprite-icons;
        @include main-icon-settings-dim;
        background-position: 97.04429530201342% 90.11299435028249%;
    }

    // icon dimensions
    @mixin main-icon-settings-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-share {
        @include main-sprite-icons;
        @include main-icon-share-dim;
        background-position: 97.04429530201342% 93.22033898305085%;
    }

    // icon dimensions
    @mixin main-icon-share-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-share-grey {
        @include main-sprite-icons;
        @include main-icon-share-grey-dim;
        background-position: 97.04429530201342% 96.32768361581921%;
    }

    // icon dimensions
    @mixin main-icon-share-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-sicherheitssysteme {
        @include main-sprite-icons;
        @include main-icon-sicherheitssysteme-dim;
        background-position: 77.23862068965518% 0;
    }

    // icon dimensions
    @mixin main-icon-sicherheitssysteme-dim {
        width: 84px;
        height: 84px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-sight {
        @include main-sprite-icons;
        @include main-icon-sight-dim;
        background-position: 99.8632707774799% 79.40761636107193%;
    }

    // icon dimensions
    @mixin main-icon-sight-dim {
        width: 42px;
        height: 42px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-single {
        @include main-sprite-icons;
        @include main-icon-single-dim;
        background-position: 49.130057803468205% 34.35114503816794%;
    }

    // icon dimensions
    @mixin main-icon-single-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-social-facebook-white {
        @include main-sprite-icons;
        @include main-icon-social-facebook-white-dim;
        background-position: 13.477088948787062% 87.51773049645391%;
    }

    // icon dimensions
    @mixin main-icon-social-facebook-white-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-social-linkedin-white {
        @include main-sprite-icons;
        @include main-icon-social-linkedin-white-dim;
        background-position: 16.846361185983827% 87.51773049645391%;
    }

    // icon dimensions
    @mixin main-icon-social-linkedin-white-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-social-twitter-white {
        @include main-sprite-icons;
        @include main-icon-social-twitter-white-dim;
        background-position: 20.21563342318059% 87.51773049645391%;
    }

    // icon dimensions
    @mixin main-icon-social-twitter-white-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-social-whatsapp-white {
        @include main-sprite-icons;
        @include main-icon-social-whatsapp-white-dim;
        background-position: 23.58490566037736% 87.51773049645391%;
    }

    // icon dimensions
    @mixin main-icon-social-whatsapp-white-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-social-wordpress-white {
        @include main-sprite-icons;
        @include main-icon-social-wordpress-white-dim;
        background-position: 26.954177897574123% 87.51773049645391%;
    }

    // icon dimensions
    @mixin main-icon-social-wordpress-white-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-social-xing-white {
        @include main-sprite-icons;
        @include main-icon-social-xing-white-dim;
        background-position: 30.32345013477089% 87.51773049645391%;
    }

    // icon dimensions
    @mixin main-icon-social-xing-white-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-sort {
        @include main-sprite-icons;
        @include main-icon-sort-dim;
        background-position: 0 100%;
    }

    // icon dimensions
    @mixin main-icon-sort-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-sort-down {
        @include main-sprite-icons;
        @include main-icon-sort-down-dim;
        background-position: 2.953020134228188% 100%;
    }

    // icon dimensions
    @mixin main-icon-sort-down-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-sort-up {
        @include main-sprite-icons;
        @include main-icon-sort-up-dim;
        background-position: 5.906040268456376% 100%;
    }

    // icon dimensions
    @mixin main-icon-sort-up-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star {
        @include main-sprite-icons;
        @include main-icon-star-dim;
        background-position: 84.96021577882671% 43.718949609652235%;
    }

    // icon dimensions
    @mixin main-icon-star-dim {
        width: 51px;
        height: 51px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star-00 {
        @include main-sprite-icons;
        @include main-icon-star-00-dim;
        background-position: 8.859060402684564% 99.92942836979535%;
    }

    // icon dimensions
    @mixin main-icon-star-00-dim {
        width: 44px;
        height: 43px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star-10 {
        @include main-sprite-icons;
        @include main-icon-star-10-dim;
        background-position: 11.812080536912752% 99.92942836979535%;
    }

    // icon dimensions
    @mixin main-icon-star-10-dim {
        width: 44px;
        height: 43px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star-20 {
        @include main-sprite-icons;
        @include main-icon-star-20-dim;
        background-position: 14.765100671140939% 99.92942836979535%;
    }

    // icon dimensions
    @mixin main-icon-star-20-dim {
        width: 44px;
        height: 43px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star-30 {
        @include main-sprite-icons;
        @include main-icon-star-30-dim;
        background-position: 17.71812080536913% 99.92942836979535%;
    }

    // icon dimensions
    @mixin main-icon-star-30-dim {
        width: 44px;
        height: 43px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star-40 {
        @include main-sprite-icons;
        @include main-icon-star-40-dim;
        background-position: 20.671140939597315% 99.92942836979535%;
    }

    // icon dimensions
    @mixin main-icon-star-40-dim {
        width: 44px;
        height: 43px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star-50 {
        @include main-sprite-icons;
        @include main-icon-star-50-dim;
        background-position: 23.624161073825505% 99.92942836979535%;
    }

    // icon dimensions
    @mixin main-icon-star-50-dim {
        width: 44px;
        height: 43px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star-60 {
        @include main-sprite-icons;
        @include main-icon-star-60-dim;
        background-position: 26.57718120805369% 99.92942836979535%;
    }

    // icon dimensions
    @mixin main-icon-star-60-dim {
        width: 44px;
        height: 43px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star-70 {
        @include main-sprite-icons;
        @include main-icon-star-70-dim;
        background-position: 29.530201342281877% 99.92942836979535%;
    }

    // icon dimensions
    @mixin main-icon-star-70-dim {
        width: 44px;
        height: 43px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star-80 {
        @include main-sprite-icons;
        @include main-icon-star-80-dim;
        background-position: 32.48322147651007% 99.92942836979535%;
    }

    // icon dimensions
    @mixin main-icon-star-80-dim {
        width: 44px;
        height: 43px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star-90 {
        @include main-sprite-icons;
        @include main-icon-star-90-dim;
        background-position: 35.43624161073826% 99.92942836979535%;
    }

    // icon dimensions
    @mixin main-icon-star-90-dim {
        width: 44px;
        height: 43px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star-empty {
        @include main-sprite-icons;
        @include main-icon-star-empty-dim;
        background-position: 88.16935483870968% 9.75954738330976%;
    }

    // icon dimensions
    @mixin main-icon-star-empty-dim {
        width: 46px;
        height: 46px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star-empty-black {
        @include main-sprite-icons;
        @include main-icon-star-empty-black-dim;
        background-position: 88.16935483870968% 13.003533568904594%;
    }

    // icon dimensions
    @mixin main-icon-star-empty-black-dim {
        width: 46px;
        height: 45px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star-fill-black {
        @include main-sprite-icons;
        @include main-icon-star-fill-black-dim;
        background-position: 88.16935483870968% 16.18374558303887%;
    }

    // icon dimensions
    @mixin main-icon-star-fill-black-dim {
        width: 46px;
        height: 45px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star-full {
        @include main-sprite-icons;
        @include main-icon-star-full-dim;
        background-position: 88.16935483870968% 19.377652050919377%;
    }

    // icon dimensions
    @mixin main-icon-star-full-dim {
        width: 46px;
        height: 46px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star-full-black {
        @include main-sprite-icons;
        @include main-icon-star-full-black-dim;
        background-position: 38.38926174496644% 99.92942836979535%;
    }

    // icon dimensions
    @mixin main-icon-star-full-black-dim {
        width: 44px;
        height: 43px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star-green-full {
        @include main-sprite-icons;
        @include main-icon-star-green-full-dim;
        background-position: 88.16935483870968% 22.614840989399294%;
    }

    // icon dimensions
    @mixin main-icon-star-green-full-dim {
        width: 46px;
        height: 45px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star-grey-empty {
        @include main-sprite-icons;
        @include main-icon-star-grey-empty-dim;
        background-position: 88.16935483870968% 25.813295615275813%;
    }

    // icon dimensions
    @mixin main-icon-star-grey-empty-dim {
        width: 46px;
        height: 46px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star-half {
        @include main-sprite-icons;
        @include main-icon-star-half-dim;
        background-position: 88.16935483870968% 29.066478076379067%;
    }

    // icon dimensions
    @mixin main-icon-star-half-dim {
        width: 46px;
        height: 46px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-star-half-black {
        @include main-sprite-icons;
        @include main-icon-star-half-black-dim;
        background-position: 88.16935483870968% 32.29681978798587%;
    }

    // icon dimensions
    @mixin main-icon-star-half-black-dim {
        width: 46px;
        height: 45px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-suv-tile {
        @include main-sprite-icons;
        @include main-icon-suv-tile-dim;
        background-position: 42.432814710042436% 62.4%;
    }

    // icon dimensions
    @mixin main-icon-suv-tile-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-thumb-down {
        @include main-sprite-icons;
        @include main-icon-thumb-down-dim;
        background-position: 99.9973154362416% 0;
    }

    // icon dimensions
    @mixin main-icon-thumb-down-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-thumb-up {
        @include main-sprite-icons;
        @include main-icon-thumb-up-dim;
        background-position: 99.9973154362416% 3.1073446327683616%;
    }

    // icon dimensions
    @mixin main-icon-thumb-up-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-thumbs-up-down {
        @include main-sprite-icons;
        @include main-icon-thumbs-up-down-dim;
        background-position: 99.9973154362416% 6.214689265536723%;
    }

    // icon dimensions
    @mixin main-icon-thumbs-up-down-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-thumbs-up-transparent {
        @include main-sprite-icons;
        @include main-icon-thumbs-up-transparent-dim;
        background-position: 99.9973154362416% 9.322033898305085%;
    }

    // icon dimensions
    @mixin main-icon-thumbs-up-transparent-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-tipp {
        @include main-sprite-icons;
        @include main-icon-tipp-dim;
        background-position: 99.9973154362416% 12.429378531073446%;
    }

    // icon dimensions
    @mixin main-icon-tipp-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-tipp-36px {
        @include main-sprite-icons;
        @include main-icon-tipp-36px-dim;
        background-position: 37.88903924221921% 83.9031339031339%;
    }

    // icon dimensions
    @mixin main-icon-tipp-36px-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-tire {
        @include main-sprite-icons;
        @include main-icon-tire-dim;
        background-position: 75.77537212449256% 69.72740315638451%;
    }

    // icon dimensions
    @mixin main-icon-tire-dim {
        width: 56px;
        height: 66px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-traffic {
        @include main-sprite-icons;
        @include main-icon-traffic-dim;
        background-position: 0 46.030534351145036%;
    }

    // icon dimensions
    @mixin main-icon-traffic-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-transporter-tile {
        @include main-sprite-icons;
        @include main-icon-transporter-tile-dim;
        background-position: 50.91937765205092% 62.4%;
    }

    // icon dimensions
    @mixin main-icon-transporter-tile-dim {
        width: 120px;
        height: 85px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-trending-flat {
        @include main-sprite-icons;
        @include main-icon-trending-flat-dim;
        background-position: 99.9973154362416% 15.536723163841808%;
    }

    // icon dimensions
    @mixin main-icon-trending-flat-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-trike {
        @include main-sprite-icons;
        @include main-icon-trike-dim;
        background-position: 59.914094928713226% 32.967032967032964%;
    }

    // icon dimensions
    @mixin main-icon-trike-dim {
        width: 148.75px;
        height: 95px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-twitter {
        @include main-sprite-icons;
        @include main-icon-twitter-dim;
        background-position: 33.692722371967655% 87.51773049645391%;
    }

    // icon dimensions
    @mixin main-icon-twitter-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-user {
        @include main-sprite-icons;
        @include main-icon-user-dim;
        background-position: 48.19277108433735% 72.14035087719299%;
    }

    // icon dimensions
    @mixin main-icon-user-dim {
        width: 40px;
        height: 35px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-vehicle {
        @include main-sprite-icons;
        @include main-icon-vehicle-dim;
        background-position: 85.01672240802675% 86.65730337078652%;
    }

    // icon dimensions
    @mixin main-icon-vehicle-dim {
        width: 39px;
        height: 36px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-vehicle-one {
        @include main-sprite-icons;
        @include main-icon-vehicle-one-dim;
        background-position: 10.83815028901734% 46.030534351145036%;
    }

    // icon dimensions
    @mixin main-icon-vehicle-one-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-vehicle-several {
        @include main-sprite-icons;
        @include main-icon-vehicle-several-dim;
        background-position: 21.67630057803468% 46.030534351145036%;
    }

    // icon dimensions
    @mixin main-icon-vehicle-several-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-verified {
        @include main-sprite-icons;
        @include main-icon-verified-dim;
        background-position: 99.9973154362416% 18.64406779661017%;
    }

    // icon dimensions
    @mixin main-icon-verified-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-verified-grey {
        @include main-sprite-icons;
        @include main-icon-verified-grey-dim;
        background-position: 99.9973154362416% 21.75141242937853%;
    }

    // icon dimensions
    @mixin main-icon-verified-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-verified-user {
        @include main-sprite-icons;
        @include main-icon-verified-user-dim;
        background-position: 99.9973154362416% 24.858757062146893%;
    }

    // icon dimensions
    @mixin main-icon-verified-user-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-verified-user-old {
        @include main-sprite-icons;
        @include main-icon-verified-user-old-dim;
        background-position: 99.9973154362416% 27.966101694915253%;
    }

    // icon dimensions
    @mixin main-icon-verified-user-old-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-versicherungen-freizeitversicherungen {
        @include main-sprite-icons;
        @include main-icon-versicherungen-freizeitversicherungen-dim;
        background-position: 59.88167388167388% 40.22140221402214%;
    }

    // icon dimensions
    @mixin main-icon-versicherungen-freizeitversicherungen-dim {
        width: 148px;
        height: 105px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-versicherungen-haftpflichtversicherungen {
        @include main-sprite-icons;
        @include main-icon-versicherungen-haftpflichtversicherungen-dim;
        background-position: 0 55.571955719557195%;
    }

    // icon dimensions
    @mixin main-icon-versicherungen-haftpflichtversicherungen-dim {
        width: 148px;
        height: 105px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-versicherungen-kfz-versicherungen {
        @include main-sprite-icons;
        @include main-icon-versicherungen-kfz-versicherungen-dim;
        background-position: 10.678210678210679% 55.571955719557195%;
    }

    // icon dimensions
    @mixin main-icon-versicherungen-kfz-versicherungen-dim {
        width: 148px;
        height: 105px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-versicherungen-rechtsschutzversicherungen {
        @include main-sprite-icons;
        @include main-icon-versicherungen-rechtsschutzversicherungen-dim;
        background-position: 21.356421356421357% 55.571955719557195%;
    }

    // icon dimensions
    @mixin main-icon-versicherungen-rechtsschutzversicherungen-dim {
        width: 148px;
        height: 105px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-versicherungen-reiseversicherungen {
        @include main-sprite-icons;
        @include main-icon-versicherungen-reiseversicherungen-dim;
        background-position: 32.03463203463203% 55.571955719557195%;
    }

    // icon dimensions
    @mixin main-icon-versicherungen-reiseversicherungen-dim {
        width: 148px;
        height: 105px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-versicherungen-unfallversicherungen {
        @include main-sprite-icons;
        @include main-icon-versicherungen-unfallversicherungen-dim;
        background-position: 42.712842712842715% 55.571955719557195%;
    }

    // icon dimensions
    @mixin main-icon-versicherungen-unfallversicherungen-dim {
        width: 148px;
        height: 105px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-videocam {
        @include main-sprite-icons;
        @include main-icon-videocam-dim;
        background-position: 99.9973154362416% 31.073446327683616%;
    }

    // icon dimensions
    @mixin main-icon-videocam-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-volume-mute {
        @include main-sprite-icons;
        @include main-icon-volume-mute-dim;
        background-position: 41.67794316644114% 83.9031339031339%;
    }

    // icon dimensions
    @mixin main-icon-volume-mute-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-volume-mute-highlight {
        @include main-sprite-icons;
        @include main-icon-volume-mute-highlight-dim;
        background-position: 45.46684709066306% 83.9031339031339%;
    }

    // icon dimensions
    @mixin main-icon-volume-mute-highlight-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-volume-up {
        @include main-sprite-icons;
        @include main-icon-volume-up-dim;
        background-position: 49.25575101488498% 83.9031339031339%;
    }

    // icon dimensions
    @mixin main-icon-volume-up-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-volume-up-highlight {
        @include main-sprite-icons;
        @include main-icon-volume-up-highlight-dim;
        background-position: 53.0446549391069% 83.9031339031339%;
    }

    // icon dimensions
    @mixin main-icon-volume-up-highlight-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-webcam {
        @include main-sprite-icons;
        @include main-icon-webcam-dim;
        background-position: 75.97577388963661% 87.39376770538243%;
    }

    // icon dimensions
    @mixin main-icon-webcam-dim {
        width: 48px;
        height: 48px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-wikipedia {
        @include main-sprite-icons;
        @include main-icon-wikipedia-dim;
        background-position: 37.06199460916442% 87.51773049645391%;
    }

    // icon dimensions
    @mixin main-icon-wikipedia-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-windows {
        @include main-sprite-icons;
        @include main-icon-windows-dim;
        background-position: 56.833558863328825% 83.9031339031339%;
    }

    // icon dimensions
    @mixin main-icon-windows-dim {
        width: 56px;
        height: 56px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-without-membership {
        @include main-sprite-icons;
        @include main-icon-without-membership-dim;
        background-position: 32.51445086705202% 46.030534351145036%;
    }

    // icon dimensions
    @mixin main-icon-without-membership-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-wohnmobil {
        @include main-sprite-icons;
        @include main-icon-wohnmobil-dim;
        background-position: 53.160919540229884% 55.16483516483517%;
    }

    // icon dimensions
    @mixin main-icon-wohnmobil-dim {
        width: 142px;
        height: 95px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-wohnmobil-small {
        @include main-sprite-icons;
        @include main-icon-wohnmobil-small-dim;
        background-position: 43.35260115606936% 46.030534351145036%;
    }

    // icon dimensions
    @mixin main-icon-wohnmobil-small-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-wohnwagen {
        @include main-sprite-icons;
        @include main-icon-wohnwagen-dim;
        background-position: 59.88167388167388% 47.61904761904762%;
    }

    // icon dimensions
    @mixin main-icon-wohnwagen-dim {
        width: 148px;
        height: 95px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-youngtimer {
        @include main-sprite-icons;
        @include main-icon-youngtimer-dim;
        background-position: 0 14.738393515106853%;
    }

    // icon dimensions
    @mixin main-icon-youngtimer-dim {
        width: 179.96px;
        height: 103px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-youtube {
        @include main-sprite-icons;
        @include main-icon-youtube-dim;
        background-position: 40.43126684636118% 87.51773049645391%;
    }

    // icon dimensions
    @mixin main-icon-youtube-dim {
        width: 50px;
        height: 50px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-zoom-in {
        @include main-sprite-icons;
        @include main-icon-zoom-in-dim;
        background-position: 99.9973154362416% 34.18079096045198%;
    }

    // icon dimensions
    @mixin main-icon-zoom-in-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-zoom-out {
        @include main-sprite-icons;
        @include main-icon-zoom-out-dim;
        background-position: 99.9973154362416% 37.28813559322034%;
    }

    // icon dimensions
    @mixin main-icon-zoom-out-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-zweirad {
        @include main-sprite-icons;
        @include main-icon-zweirad-dim;
        background-position: 69.58707615835256% 37.2967032967033%;
    }

    // icon dimensions
    @mixin main-icon-zweirad-dim {
        width: 125.75px;
        height: 95px;
    }


    // svg-sprite icon mixin
    @mixin main-icon-zweirad-dropdown {
        @include main-sprite-icons;
        @include main-icon-zweirad-dropdown-dim;
        background-position: 23.983739837398375% 75.07062146892656%;
    }

    // icon dimensions
    @mixin main-icon-zweirad-dropdown-dim {
        width: 58px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-icons-route {
        @include main-sprite-icons;
        @include main-icons-route-dim;
        background-position: 99.9973154362416% 40.3954802259887%;
    }

    // icon dimensions
    @mixin main-icons-route-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-leisure-culture {
        @include main-sprite-icons;
        @include main-leisure-culture-dim;
        background-position: 59.96820809248555% 0;
    }

    // icon dimensions
    @mixin main-leisure-culture-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-local_gas_station-grey {
        @include main-sprite-icons;
        @include main-local_gas_station-grey-dim;
        background-position: 99.9973154362416% 43.50282485875706%;
    }

    // icon dimensions
    @mixin main-local_gas_station-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-mobility {
        @include main-sprite-icons;
        @include main-mobility-dim;
        background-position: 59.96820809248555% 11.450381679389313%;
    }

    // icon dimensions
    @mixin main-mobility-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin main-navigate-before {
        @include main-sprite-icons;
        @include main-navigate-before-dim;
        background-position: 78.03317850033179% 68.0672268907563%;
    }

    // icon dimensions
    @mixin main-navigate-before-dim {
        width: 27px;
        height: 32px;
    }


    // svg-sprite icon mixin
    @mixin main-payment {
        @include main-sprite-icons;
        @include main-payment-dim;
        background-position: 99.9973154362416% 46.610169491525426%;
    }

    // icon dimensions
    @mixin main-payment-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-payment-grey {
        @include main-sprite-icons;
        @include main-payment-grey-dim;
        background-position: 99.9973154362416% 49.717514124293785%;
    }

    // icon dimensions
    @mixin main-payment-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-playlist_add {
        @include main-sprite-icons;
        @include main-playlist_add-dim;
        background-position: 99.9973154362416% 52.824858757062145%;
    }

    // icon dimensions
    @mixin main-playlist_add-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-playlist_check {
        @include main-sprite-icons;
        @include main-playlist_check-dim;
        background-position: 99.9973154362416% 55.932203389830505%;
    }

    // icon dimensions
    @mixin main-playlist_check-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin main-punkte {
        @include main-sprite-icons;
        @include main-punkte-dim;
        background-position: 76.3956343792633% 50.7183908045977%;
    }

    // icon dimensions
    @mixin main-punkte-dim {
        width: 68px;
        height: 68px;
    }


    // svg-sprite icon mixin
    @mixin main-tire-car-accessories {
        @include main-sprite-icons;
        @include main-tire-car-accessories-dim;
        background-position: 59.96820809248555% 22.900763358778626%;
    }

    // icon dimensions
    @mixin main-tire-car-accessories-dim {
        width: 150px;
        height: 150px;
    }


// svg sprite style sheet

    // svg src
    @mixin logos-svgSprite {
        background: url("/assets/img/svg-sprite/logos-sprite.svg") no-repeat;
    }

    // common name
    @mixin logos-sprite-icons {
        @include logos-svgSprite;
    }

    // svg-sprite icon mixin
    @mixin logos-adac-logo {
        @include logos-sprite-icons;
        @include logos-adac-logo-dim;
        background-position: 0 98.54604200323101%;
    }

    // icon dimensions
    @mixin logos-adac-logo-dim {
        width: 133.9px;
        height: 134.1px;
    }


    // svg-sprite icon mixin
    @mixin logos-icon-check24 {
        @include logos-sprite-icons;
        @include logos-icon-check24-dim;
        background-position: 0 0;
    }

    // icon dimensions
    @mixin logos-icon-check24-dim {
        width: 177.94px;
        height: 61px;
    }


    // svg-sprite icon mixin
    @mixin logos-icon-logo {
        @include logos-sprite-icons;
        @include logos-icon-logo-dim;
        background-position: 99.96629213483146% 0;
    }

    // icon dimensions
    @mixin logos-icon-logo-dim {
        width: 110px;
        height: 52px;
    }


// svg sprite style sheet

    // svg src
    @mixin header-svgSprite {
        background: url("/assets/img/svg-sprite/header-sprite.svg") no-repeat;
    }

    // common name
    @mixin header-sprite-icons {
        @include header-svgSprite;
    }

    // svg-sprite icon mixin
    @mixin header-icon-arrow-top-left {
        @include header-sprite-icons;
        @include header-icon-arrow-top-left-dim;
        background-position: 0 0;
    }

    // icon dimensions
    @mixin header-icon-arrow-top-left-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin header-icon-car-accident-grey {
        @include header-sprite-icons;
        @include header-icon-car-accident-grey-dim;
        background-position: 33.333333333333336% 0;
    }

    // icon dimensions
    @mixin header-icon-car-accident-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin header-icon-car-danger {
        @include header-sprite-icons;
        @include header-icon-car-danger-dim;
        background-position: 0 33.333333333333336%;
    }

    // icon dimensions
    @mixin header-icon-car-danger-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin header-icon-car-grey {
        @include header-sprite-icons;
        @include header-icon-car-grey-dim;
        background-position: 33.333333333333336% 33.333333333333336%;
    }

    // icon dimensions
    @mixin header-icon-car-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin header-icon-login-circle {
        @include header-sprite-icons;
        @include header-icon-login-circle-dim;
        background-position: 66.66666666666667% 0;
    }

    // icon dimensions
    @mixin header-icon-login-circle-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin header-icon-login-circle-grey {
        @include header-sprite-icons;
        @include header-icon-login-circle-grey-dim;
        background-position: 66.66666666666667% 33.333333333333336%;
    }

    // icon dimensions
    @mixin header-icon-login-circle-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin header-icon-logout {
        @include header-sprite-icons;
        @include header-icon-logout-dim;
        background-position: 0 66.66666666666667%;
    }

    // icon dimensions
    @mixin header-icon-logout-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin header-icon-member-id-grey {
        @include header-sprite-icons;
        @include header-icon-member-id-grey-dim;
        background-position: 33.333333333333336% 66.66666666666667%;
    }

    // icon dimensions
    @mixin header-icon-member-id-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin header-icon-phone {
        @include header-sprite-icons;
        @include header-icon-phone-dim;
        background-position: 66.66666666666667% 66.66666666666667%;
    }

    // icon dimensions
    @mixin header-icon-phone-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin header-icon-placeholder {
        @include header-sprite-icons;
        @include header-icon-placeholder-dim;
        background-position: 100% 0;
    }

    // icon dimensions
    @mixin header-icon-placeholder-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin header-icon-schadenmeldung {
        @include header-sprite-icons;
        @include header-icon-schadenmeldung-dim;
        background-position: 100% 33.333333333333336%;
    }

    // icon dimensions
    @mixin header-icon-schadenmeldung-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin header-icon-shield-grey {
        @include header-sprite-icons;
        @include header-icon-shield-grey-dim;
        background-position: 100% 66.66666666666667%;
    }

    // icon dimensions
    @mixin header-icon-shield-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin header-icon-travel-grey {
        @include header-sprite-icons;
        @include header-icon-travel-grey-dim;
        background-position: 0 100%;
    }

    // icon dimensions
    @mixin header-icon-travel-grey-dim {
        width: 44px;
        height: 44px;
    }


// svg sprite style sheet

    // svg src
    @mixin fs-svgSprite {
        background: url("/assets/img/svg-sprite/fs-sprite.svg") no-repeat;
    }

    // common name
    @mixin fs-sprite-icons {
        @include fs-svgSprite;
    }

    // svg-sprite icon mixin
    @mixin fs-calendar-check {
        @include fs-sprite-icons;
        @include fs-calendar-check-dim;
        background-position: 27.77777777777778% 97.05882352941177%;
    }

    // icon dimensions
    @mixin fs-calendar-check-dim {
        width: 32px;
        height: 34px;
    }


    // svg-sprite icon mixin
    @mixin fs-caravan {
        @include fs-sprite-icons;
        @include fs-caravan-dim;
        background-position: 0 0;
    }

    // icon dimensions
    @mixin fs-caravan-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin fs-drift {
        @include fs-sprite-icons;
        @include fs-drift-dim;
        background-position: 33.333333333333336% 0;
    }

    // icon dimensions
    @mixin fs-drift-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin fs-fahrsicherheitstraining {
        @include fs-sprite-icons;
        @include fs-fahrsicherheitstraining-dim;
        background-position: 0 34.92063492063492%;
    }

    // icon dimensions
    @mixin fs-fahrsicherheitstraining-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin fs-lightbulb {
        @include fs-sprite-icons;
        @include fs-lightbulb-dim;
        background-position: 33.333333333333336% 34.92063492063492%;
    }

    // icon dimensions
    @mixin fs-lightbulb-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin fs-limousine {
        @include fs-sprite-icons;
        @include fs-limousine-dim;
        background-position: 66.66666666666667% 0;
    }

    // icon dimensions
    @mixin fs-limousine-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin fs-limousine-back {
        @include fs-sprite-icons;
        @include fs-limousine-back-dim;
        background-position: 66.66666666666667% 31.884057971014492%;
    }

    // icon dimensions
    @mixin fs-limousine-back-dim {
        width: 44px;
        height: 32px;
    }


    // svg-sprite icon mixin
    @mixin fs-location {
        @include fs-sprite-icons;
        @include fs-location-dim;
        background-position: 0 69.84126984126983%;
    }

    // icon dimensions
    @mixin fs-location-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin fs-motorbike {
        @include fs-sprite-icons;
        @include fs-motorbike-dim;
        background-position: 33.333333333333336% 69.84126984126983%;
    }

    // icon dimensions
    @mixin fs-motorbike-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin fs-motorrad-black {
        @include fs-sprite-icons;
        @include fs-motorrad-black-dim;
        background-position: 0 100%;
    }

    // icon dimensions
    @mixin fs-motorrad-black-dim {
        width: 40px;
        height: 38px;
    }


    // svg-sprite icon mixin
    @mixin fs-off-road {
        @include fs-sprite-icons;
        @include fs-off-road-dim;
        background-position: 66.66666666666667% 69.84126984126983%;
    }

    // icon dimensions
    @mixin fs-off-road-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin fs-transporter {
        @include fs-sprite-icons;
        @include fs-transporter-dim;
        background-position: 100% 0;
    }

    // icon dimensions
    @mixin fs-transporter-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin fs-transporter-black {
        @include fs-sprite-icons;
        @include fs-transporter-black-dim;
        background-position: 98.50746268656717% 32.11678832116788%;
    }

    // icon dimensions
    @mixin fs-transporter-black-dim {
        width: 42px;
        height: 33px;
    }


    // svg-sprite icon mixin
    @mixin fs-wohnmobil-black {
        @include fs-sprite-icons;
        @include fs-wohnmobil-black-dim;
        background-position: 98.50746268656717% 57.03703703703704%;
    }

    // icon dimensions
    @mixin fs-wohnmobil-black-dim {
        width: 42px;
        height: 35px;
    }


// svg sprite style sheet

    // svg src
    @mixin damage-declaration-svgSprite {
        background: url("/assets/img/svg-sprite/damage-declaration-sprite.svg") no-repeat;
    }

    // common name
    @mixin damage-declaration-sprite-icons {
        @include damage-declaration-svgSprite;
    }

    // svg-sprite icon mixin
    @mixin damage-declaration-icon-accident {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-accident-dim;
        background-position: 0 0;
    }

    // icon dimensions
    @mixin damage-declaration-icon-accident-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-accident2 {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-accident2-dim;
        background-position: 16.666666666666668% 0;
    }

    // icon dimensions
    @mixin damage-declaration-icon-accident2-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-adac {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-adac-dim;
        background-position: 0 16.666666666666668%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-adac-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-ambulance {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-ambulance-dim;
        background-position: 16.666666666666668% 16.666666666666668%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-ambulance-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-battery {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-battery-dim;
        background-position: 33.333333333333336% 0;
    }

    // icon dimensions
    @mixin damage-declaration-icon-battery-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-bed {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-bed-dim;
        background-position: 33.333333333333336% 16.666666666666668%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-bed-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-camera {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-camera-dim;
        background-position: 59.642147117296226% 89.46322067594433%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-camera-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-cancellation {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-cancellation-dim;
        background-position: 0 33.333333333333336%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-cancellation-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-car {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-car-dim;
        background-position: 16.666666666666668% 33.333333333333336%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-car-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-child {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-child-dim;
        background-position: 33.333333333333336% 33.333333333333336%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-child-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-child-single {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-child-single-dim;
        background-position: 50% 0;
    }

    // icon dimensions
    @mixin damage-declaration-icon-child-single-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-death {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-death-dim;
        background-position: 50% 16.666666666666668%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-death-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-driver {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-driver-dim;
        background-position: 50% 33.333333333333336%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-driver-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-elementary-events {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-elementary-events-dim;
        background-position: 0 50%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-elementary-events-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-family {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-family-dim;
        background-position: 16.666666666666668% 50%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-family-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-family-member {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-family-member-dim;
        background-position: 33.333333333333336% 50%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-family-member-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-friends {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-friends-dim;
        background-position: 50% 50%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-friends-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-hotel {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-hotel-dim;
        background-position: 64.01590457256461% 89.46322067594433%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-hotel-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-hotel-grey {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-hotel-grey-dim;
        background-position: 68.389662027833% 89.46322067594433%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-hotel-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-human-accident {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-human-accident-dim;
        background-position: 66.66666666666667% 0;
    }

    // icon dimensions
    @mixin damage-declaration-icon-human-accident-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-illness {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-illness-dim;
        background-position: 66.66666666666667% 16.666666666666668%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-illness-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-illness2 {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-illness2-dim;
        background-position: 66.66666666666667% 33.333333333333336%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-illness2-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-injury {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-injury-dim;
        background-position: 66.66666666666667% 50%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-injury-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-inoculation {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-inoculation-dim;
        background-position: 0 66.66666666666667%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-inoculation-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-key {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-key-dim;
        background-position: 16.666666666666668% 66.66666666666667%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-key-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-medical-expenses {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-medical-expenses-dim;
        background-position: 33.333333333333336% 66.66666666666667%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-medical-expenses-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-medicine {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-medicine-dim;
        background-position: 50% 66.66666666666667%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-medicine-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-misc {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-misc-dim;
        background-position: 66.66666666666667% 66.66666666666667%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-misc-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-misc2 {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-misc2-dim;
        background-position: 83.33333333333333% 0;
    }

    // icon dimensions
    @mixin damage-declaration-icon-misc2-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-no-services {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-no-services-dim;
        background-position: 83.33333333333333% 16.666666666666668%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-no-services-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-opponent {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-opponent-dim;
        background-position: 83.33333333333333% 33.333333333333336%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-opponent-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-other {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-other-dim;
        background-position: 83.33333333333333% 50%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-other-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-panne {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-panne-dim;
        background-position: 72.76341948310139% 89.46322067594433%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-panne-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-panne-ruecktransport {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-panne-ruecktransport-dim;
        background-position: 77.13717693836978% 89.46322067594433%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-panne-ruecktransport-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-panne-ruecktransport-grey {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-panne-ruecktransport-grey-dim;
        background-position: 81.51093439363817% 89.46322067594433%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-panne-ruecktransport-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-panne-schluessel {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-panne-schluessel-dim;
        background-position: 85.88469184890656% 89.46322067594433%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-panne-schluessel-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-panne-schluessel-grey {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-panne-schluessel-grey-dim;
        background-position: 90.25844930417495% 89.46322067594433%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-panne-schluessel-grey-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-partner {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-partner-dim;
        background-position: 83.33333333333333% 66.66666666666667%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-partner-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-partner2 {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-partner2-dim;
        background-position: 0 83.33333333333333%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-partner2-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-person-me {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-person-me-dim;
        background-position: 16.666666666666668% 83.33333333333333%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-person-me-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-person-me2 {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-person-me2-dim;
        background-position: 33.333333333333336% 83.33333333333333%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-person-me2-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-police {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-police-dim;
        background-position: 50% 83.33333333333333%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-police-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-pregnant-woman {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-pregnant-woman-dim;
        background-position: 66.66666666666667% 83.33333333333333%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-pregnant-woman-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-prothesis {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-prothesis-dim;
        background-position: 83.33333333333333% 83.33333333333333%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-prothesis-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-puncture {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-puncture-dim;
        background-position: 100% 0;
    }

    // icon dimensions
    @mixin damage-declaration-icon-puncture-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-questionmark {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-questionmark-dim;
        background-position: 100% 16.666666666666668%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-questionmark-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-return-travel-costs {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-return-travel-costs-dim;
        background-position: 100% 33.333333333333336%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-return-travel-costs-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-schedule {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-schedule-dim;
        background-position: 94.63220675944333% 89.46322067594433%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-schedule-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-theft {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-theft-dim;
        background-position: 100% 50%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-theft-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-thumb-down {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-thumb-down-dim;
        background-position: 100% 66.66666666666667%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-thumb-down-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-tire {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-tire-dim;
        background-position: 100% 83.33333333333333%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-tire-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-train {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-train-dim;
        background-position: 0 100%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-train-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-train-single {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-train-single-dim;
        background-position: 16.666666666666668% 100%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-train-single-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-tyre {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-tyre-dim;
        background-position: 33.333333333333336% 100%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-tyre-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-watch {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-watch-dim;
        background-position: 99.00596421471172% 89.46322067594433%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-watch-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-icon-work-situation {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-icon-work-situation-dim;
        background-position: 50% 100%;
    }

    // icon dimensions
    @mixin damage-declaration-icon-work-situation-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-refresh {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-refresh-dim;
        background-position: 59.642147117296226% 93.83697813121272%;
    }

    // icon dimensions
    @mixin damage-declaration-refresh-dim {
        width: 44px;
        height: 44px;
    }


    // svg-sprite icon mixin
    @mixin damage-declaration-telefonhoerer {
        @include damage-declaration-sprite-icons;
        @include damage-declaration-telefonhoerer-dim;
        background-position: 64.01590457256461% 93.83697813121272%;
    }

    // icon dimensions
    @mixin damage-declaration-telefonhoerer-dim {
        width: 44px;
        height: 44px;
    }


// svg sprite style sheet

    // svg src
    @mixin club-svgSprite {
        background: url("/assets/img/svg-sprite/club-sprite.svg") no-repeat;
    }

    // common name
    @mixin club-sprite-icons {
        @include club-svgSprite;
    }

    // svg-sprite icon mixin
    @mixin club-adac-haus-filiale {
        @include club-sprite-icons;
        @include club-adac-haus-filiale-dim;
        background-position: 0 0;
    }

    // icon dimensions
    @mixin club-adac-haus-filiale-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin club-callcenter {
        @include club-sprite-icons;
        @include club-callcenter-dim;
        background-position: 50% 0;
    }

    // icon dimensions
    @mixin club-callcenter-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin club-handy-video {
        @include club-sprite-icons;
        @include club-handy-video-dim;
        background-position: 0 100%;
    }

    // icon dimensions
    @mixin club-handy-video-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin club-kleinwagen {
        @include club-sprite-icons;
        @include club-kleinwagen-dim;
        background-position: 50% 100%;
    }

    // icon dimensions
    @mixin club-kleinwagen-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin club-profil {
        @include club-sprite-icons;
        @include club-profil-dim;
        background-position: 100% 0;
    }

    // icon dimensions
    @mixin club-profil-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin club-reise {
        @include club-sprite-icons;
        @include club-reise-dim;
        background-position: 100% 100%;
    }

    // icon dimensions
    @mixin club-reise-dim {
        width: 150px;
        height: 150px;
    }


// svg sprite style sheet

    // svg src
    @mixin boat-svgSprite {
        background: url("/assets/img/svg-sprite/boat-sprite.svg") no-repeat;
    }

    // common name
    @mixin boat-sprite-icons {
        @include boat-svgSprite;
    }

    // svg-sprite icon mixin
    @mixin boat-icon-hilfe {
        @include boat-sprite-icons;
        @include boat-icon-hilfe-dim;
        background-position: 87.97653958944281% 56.390977443609025%;
    }

    // icon dimensions
    @mixin boat-icon-hilfe-dim {
        width: 68px;
        height: 68px;
    }


    // svg-sprite icon mixin
    @mixin boat-icon-hilfe-mouseover {
        @include boat-sprite-icons;
        @include boat-icon-hilfe-mouseover-dim;
        background-position: 97.94721407624634% 56.390977443609025%;
    }

    // icon dimensions
    @mixin boat-icon-hilfe-mouseover-dim {
        width: 68px;
        height: 68px;
    }


    // svg-sprite icon mixin
    @mixin boat-icon-wasser-Jet-Surfbrett-gelb {
        @include boat-sprite-icons;
        @include boat-icon-wasser-Jet-Surfbrett-gelb-dim;
        background-position: 0 0;
    }

    // icon dimensions
    @mixin boat-icon-wasser-Jet-Surfbrett-gelb-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin boat-icon-wasser-eigenbau-boot {
        @include boat-sprite-icons;
        @include boat-icon-wasser-eigenbau-boot-dim;
        background-position: 25% 0;
    }

    // icon dimensions
    @mixin boat-icon-wasser-eigenbau-boot-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin boat-icon-wasser-eigenbau-boot-gelb {
        @include boat-sprite-icons;
        @include boat-icon-wasser-eigenbau-boot-gelb-dim;
        background-position: 0 33.333333333333336%;
    }

    // icon dimensions
    @mixin boat-icon-wasser-eigenbau-boot-gelb-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin boat-icon-wasser-hausboot {
        @include boat-sprite-icons;
        @include boat-icon-wasser-hausboot-dim;
        background-position: 25% 33.333333333333336%;
    }

    // icon dimensions
    @mixin boat-icon-wasser-hausboot-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin boat-icon-wasser-hausboot-gelb {
        @include boat-sprite-icons;
        @include boat-icon-wasser-hausboot-gelb-dim;
        background-position: 50% 0;
    }

    // icon dimensions
    @mixin boat-icon-wasser-hausboot-gelb-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin boat-icon-wasser-jet-surfbrett {
        @include boat-sprite-icons;
        @include boat-icon-wasser-jet-surfbrett-dim;
        background-position: 50% 33.333333333333336%;
    }

    // icon dimensions
    @mixin boat-icon-wasser-jet-surfbrett-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin boat-icon-wasser-motorboot-yacht {
        @include boat-sprite-icons;
        @include boat-icon-wasser-motorboot-yacht-dim;
        background-position: 0 66.66666666666667%;
    }

    // icon dimensions
    @mixin boat-icon-wasser-motorboot-yacht-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin boat-icon-wasser-motorboot-yacht-gelb {
        @include boat-sprite-icons;
        @include boat-icon-wasser-motorboot-yacht-gelb-dim;
        background-position: 25% 66.66666666666667%;
    }

    // icon dimensions
    @mixin boat-icon-wasser-motorboot-yacht-gelb-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin boat-icon-wasser-motorrad-fond {
        @include boat-sprite-icons;
        @include boat-icon-wasser-motorrad-fond-dim;
        background-position: 50% 66.66666666666667%;
    }

    // icon dimensions
    @mixin boat-icon-wasser-motorrad-fond-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin boat-icon-wasser-motorrad-gelb {
        @include boat-sprite-icons;
        @include boat-icon-wasser-motorrad-gelb-dim;
        background-position: 75% 0;
    }

    // icon dimensions
    @mixin boat-icon-wasser-motorrad-gelb-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin boat-icon-wasser-schlauch-motor-beiboot {
        @include boat-sprite-icons;
        @include boat-icon-wasser-schlauch-motor-beiboot-dim;
        background-position: 75% 33.333333333333336%;
    }

    // icon dimensions
    @mixin boat-icon-wasser-schlauch-motor-beiboot-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin boat-icon-wasser-schlauch-motor-beiboot-gelb {
        @include boat-sprite-icons;
        @include boat-icon-wasser-schlauch-motor-beiboot-gelb-dim;
        background-position: 75% 66.66666666666667%;
    }

    // icon dimensions
    @mixin boat-icon-wasser-schlauch-motor-beiboot-gelb-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin boat-icon-wasser-schlauchboot-paddel {
        @include boat-sprite-icons;
        @include boat-icon-wasser-schlauchboot-paddel-dim;
        background-position: 0 100%;
    }

    // icon dimensions
    @mixin boat-icon-wasser-schlauchboot-paddel-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin boat-icon-wasser-schlauchboot-paddel-gelb {
        @include boat-sprite-icons;
        @include boat-icon-wasser-schlauchboot-paddel-gelb-dim;
        background-position: 25% 100%;
    }

    // icon dimensions
    @mixin boat-icon-wasser-schlauchboot-paddel-gelb-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin boat-icon-wasser-segelboot {
        @include boat-sprite-icons;
        @include boat-icon-wasser-segelboot-dim;
        background-position: 50% 100%;
    }

    // icon dimensions
    @mixin boat-icon-wasser-segelboot-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin boat-icon-wasser-segelboot-gelb {
        @include boat-sprite-icons;
        @include boat-icon-wasser-segelboot-gelb-dim;
        background-position: 75% 100%;
    }

    // icon dimensions
    @mixin boat-icon-wasser-segelboot-gelb-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin boat-icon-wasser-sonstiges-boot {
        @include boat-sprite-icons;
        @include boat-icon-wasser-sonstiges-boot-dim;
        background-position: 100% 0;
    }

    // icon dimensions
    @mixin boat-icon-wasser-sonstiges-boot-dim {
        width: 150px;
        height: 150px;
    }


    // svg-sprite icon mixin
    @mixin boat-icon-wasser-sonstiges-boot-gelb {
        @include boat-sprite-icons;
        @include boat-icon-wasser-sonstiges-boot-gelb-dim;
        background-position: 100% 33.333333333333336%;
    }

    // icon dimensions
    @mixin boat-icon-wasser-sonstiges-boot-gelb-dim {
        width: 150px;
        height: 150px;
    }


// svg sprite style sheet

    // svg src
    @mixin autoversicherung-svgSprite {
        background: url("/assets/img/svg-sprite/autoversicherung-sprite.svg") no-repeat;
    }

    // common name
    @mixin autoversicherung-sprite-icons {
        @include autoversicherung-svgSprite;
    }

    // svg-sprite icon mixin
    @mixin autoversicherung-icon-auslandsschadenschutz {
        @include autoversicherung-sprite-icons;
        @include autoversicherung-icon-auslandsschadenschutz-dim;
        background-position: 0 0;
    }

    // icon dimensions
    @mixin autoversicherung-icon-auslandsschadenschutz-dim {
        width: 85px;
        height: 68px;
    }


    // svg-sprite icon mixin
    @mixin autoversicherung-icon-fahr-und-spar {
        @include autoversicherung-sprite-icons;
        @include autoversicherung-icon-fahr-und-spar-dim;
        background-position: 50% 0;
    }

    // icon dimensions
    @mixin autoversicherung-icon-fahr-und-spar-dim {
        width: 85px;
        height: 68px;
    }


    // svg-sprite icon mixin
    @mixin autoversicherung-icon-fahrerschutz {
        @include autoversicherung-sprite-icons;
        @include autoversicherung-icon-fahrerschutz-dim;
        background-position: 0 62.96296296296296%;
    }

    // icon dimensions
    @mixin autoversicherung-icon-fahrerschutz-dim {
        width: 85px;
        height: 68px;
    }


    // svg-sprite icon mixin
    @mixin autoversicherung-icon-info {
        @include autoversicherung-sprite-icons;
        @include autoversicherung-icon-info-dim;
        background-position: 0 100%;
    }

    // icon dimensions
    @mixin autoversicherung-icon-info-dim {
        width: 40px;
        height: 40px;
    }


    // svg-sprite icon mixin
    @mixin autoversicherung-icon-rabattschutz {
        @include autoversicherung-sprite-icons;
        @include autoversicherung-icon-rabattschutz-dim;
        background-position: 50% 62.96296296296296%;
    }

    // icon dimensions
    @mixin autoversicherung-icon-rabattschutz-dim {
        width: 85px;
        height: 68px;
    }


    // svg-sprite icon mixin
    @mixin autoversicherung-icon-unfallhilfe {
        @include autoversicherung-sprite-icons;
        @include autoversicherung-icon-unfallhilfe-dim;
        background-position: 100% 0;
    }

    // icon dimensions
    @mixin autoversicherung-icon-unfallhilfe-dim {
        width: 85px;
        height: 68px;
    }


    // svg-sprite icon mixin
    @mixin autoversicherung-icon-werkstattbonus {
        @include autoversicherung-sprite-icons;
        @include autoversicherung-icon-werkstattbonus-dim;
        background-position: 100% 62.96296296296296%;
    }

    // icon dimensions
    @mixin autoversicherung-icon-werkstattbonus-dim {
        width: 85px;
        height: 68px;
    }


@import "./partials/animations.scss";
@import "./globals/helper.scss";
@import "./partials/helper.scss";
@import "./globals/normalize.scss";
@import "./partials/fonts.scss";
@import "./partials/calendar.scss";
@import "./partials/base.scss";
@import "./partials/swiper.scss";
@import "../../node_modules/swiper/swiper-bundle.min.css";
@import "../../node_modules/magnific-popup/dist/magnific-popup.css";
@import "../../node_modules/jscrollpane/style/jquery.jscrollpane.css";
@import "../../libs/jquery-ui-datepicker//datepicker.css";
@import "../../libs/jquery-autocomplete/jquery.auto-complete.css";
@import "./partials/keyframes.scss";
@import "./partials/autocomplete.scss";
@import "./partials/sitecore.scss";
@import "./partials/print.scss";
// generated from webpack
@import "../../build/tmp/imports.scss";
// generated end
@import "../../node_modules/nouislider/dist/nouislider.css";