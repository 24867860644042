/* modifier for detail pages */
.a-basic-rating--topright {

	@include mq(desktop) {
		position: absolute;
		top: 57px;
		right: 40px;
	}

	@include mq(desktop-xl) {
		right: 0;
	}
}