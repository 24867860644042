/* m-my-adac-dashboard-box-layer--fixed */
.m-my-adac-dashboard-box-layer--fixed {
	position: fixed;
	background-color: rgba(0, 0, 0, 0.2);

	> .mm-wrapper {
		left: 50%;
		transform: translateX(-50%);
		max-width: 500px;
		width: 100%;

		> .mm-layer-content {
			max-height: calc(100vh - 100px);
			overflow-y: auto;
			overflow-x: hidden;
		}
	}
}