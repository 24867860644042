/* a-basic-input-text--location */
.a-basic-input-text--location {
	width: 100%;

	.aa-location {
		display: block;
		position: absolute;
		bottom: -8px;
		left: -10px;
		cursor: pointer;
		background-repeat: no-repeat;
		@include main-icon-near-me;

		.l-form-general & {
			left: -15px;
			bottom: 2px;
		}

		& ~ .aa-col-label {
			padding-left: 30px;
		}
	}

	> input {
		width: calc(100% - 30px);
		margin-left: 30px;

		.l-form:not(.l-form-general) .m-basic-radius-search--locksmith & {
			margin-left: 33px;
		}

		.m-basic-radius-search--mobile-wrap & {
			width: calc(100% - 25px);

			@include mq(tablet) {
				width: calc(100% - 30px);
			}
		}
	}

	// Exception m-basic-keywordfilter--filter as part of filter items
	.m-basic-keywordfilter--filter .mm-filter .mm-filtergroup > & {

		max-width: calc(100% - 30px);
		margin-bottom: 10px;

		@include mq(tablet) {
			max-width: none;
			margin-bottom: 0;
		}
	}

	// Exception ll-row--mgl-discount
	.ll-row--mgl-discount > & {

		max-width: calc(100% - 30px);
		margin-bottom: 10px;

		@include mq(tablet) {
			max-width: 300px;
			margin-right: 10px;
		}

	}
}