/* o-basic-banderole--premium */
.o-basic-banderole--premium {
	padding-bottom: 10px;
	padding-top: 7px;
	margin: 0;
	display: block;

	.oo-headline {
		padding-bottom: 0;
		color: $color-secondary-1;
		@include milo-slab-bold($font-size-24, 28px);

		.h-roboto & {
			font-family: 'Roboto Bold', sans-serif;
		}
	}

	.m-basic-teaser-across {
		> article {
			background: 0;

			> figure {
				height: 217px;

				> a {
					> img {
						height: 217px;
					}
				}
			}

			.mm-article-content {
				height: 50px;
			}
		}
	}

	.mm-footer {
		display: none;
	}
}