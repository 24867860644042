/* a-basic-link--vehicle-image */
.a-basic-link--vehicle-image {
	display: flex;
	justify-content: space-between;
	margin: 0;
	padding: 10px 50px 10px 0;
	border-bottom: 1px solid $color-secondary-9;

	&:hover {
		text-decoration: none;
	}

	&:last-child {
		border: none;
	}

	&::before {
		left: auto;
		right: -10px;
		top: 2px;
	}

	> .aa-text {
		position: relative;
		top: 2px;
		font-weight: bold;

		@include if-parent('.a-basic-link--vehicle-image:hover') {
			text-decoration: underline;
		}
	}
}