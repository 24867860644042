// .m-basic-header--reduced
.m-basic-header--reduced {

	@include mq(desktop-l) {
		transition: none !important;
		animation: none !important;
	}

	&.has-breadcrumb:not(.is-dropshadow) {
		@include mq(desktop-l) {
			box-shadow: none;
		}
	}

	> .mm-top-topics {

		@include mq(desktop-l) {
			display: none;
		}
	}

	> .mm-navi-wrap {

		@include mq(desktop-l) {
			flex-wrap: nowrap;
			min-height: 64px;
			padding-right: 24px;
			transition: height .3s ease-in-out;
		}

		&.is-active {
			@include mq(desktop-l) {
				height: 120px;
			}
		}

		> .mm-logo {

			@include mq(desktop-l) {
				top: -8px;
				transform: translate(0, 0);
				flex-grow: 0;
				margin-right: auto;
			}
		}

		> .mm-nav {

			@include mq(desktop-l) {
				display: none;
				@include absolute($top: auto, $left: 50%, $bottom: 0, $right: auto);
				width: 100%;
				transform: translate(-50%, 0);
				opacity: 0;
			}

			&.is-visible {
				display: block;
			}

			&:not(.is-no-anim) {

				@include mq(desktop-l) {
					transition: opacity .3s linear;
				}

				&.is-active {
					@include mq(desktop-l) {
						opacity: 1;
						transition-duration: .5s;
					}
				}
			}

			> .mm-list-wrapper {
				@include mq(desktop-l) {
					justify-content: flex-start;
				}

				> .mm-nav-list {

					> .mm-list {
						@include mq(desktop-l) {
							padding: 8px 11px 20px 11px;
						}

						.is-keyboard-focus &:focus {
							padding: 8px 11px 20px 11px;
						}

						&:after {
							@include mq(desktop-l) {
								content: '';
								@include absolute($left: 0px, $bottom: 0);
								width: calc(100% - 16px);
							}
						}
					}
				}
			}
		}

		> .mm-main {

			padding-top: 0;

			> .mm-main-btn {

				> .mm-section {

					&:last-child {

						@include mq(tablet) {
							margin-left: 16px;
						}

						@include mq(desktop-l) {
							margin-right: 30px;
						}
					}

					> .mm-layer {

						&.mm-contact {
							@include mq(tablet) {
								right: -105px;
							}

							@include mq(desktop-l) {
								right: -8px;
							}
						}

						&.mm-login {
							@include mq(tablet) {
								left: -170px;
							}

							@include mq(desktop-l) {
								left: auto;
								right: calc(-100px + 36px);
							}

							&:after {
								@include mq(tablet) {
									left: 178px;
								}

								@include mq(desktop-l) {
									left: auto;
									right: 72px;
								}
							}
						}
					}
				}

			}

			> .mm-main-nav {

				@include mq(desktop-l) {
					display: block;
				}

				> .mm-menu {
					@include mq(desktop-l) {
						top: 0;
						margin-left: 30px;
					}
				}
			}

		}

		> .mm-search {

			display: none;

			&.is-open {

				@include mq(tablet) {
					z-index: 20;
				}

				> .mm-close {

					@include mq(tablet) {
						display: block;
					}

					// exception if search input is filled
					@include if-parent('.m-basic-header--reduced.js-search-filled') {

						@include mq(tablet) {
							display: none;
						}
					}
				}

			}

			> .mm-close {
				@include mq(tablet) {
					right: 0;
					top: 0;
				}

				@include mq(desktop-l) {
					right: 40px;
				}
			}
		}
	}
}