.o-basic-cluster-box--imagebig {
	max-width: 926px;
	height: 100%;

	.o-basic-banderole & {
		margin-bottom: 0;
		width: 100%;
	}

	> .oo-main {
		max-height: none;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		> section.oo-img-box {
			margin-right: 0;
			order: 1;
			flex-basis: 100%;

			> .oo-figure {
				max-width: 926px;
				max-height: 383px;

				> img {
					position: static;
					left: auto;
					margin: 0;
					width: auto;
					max-width: 100%;
				}
			}
		}

		> .oo-header {
			padding: 0;
			width: 100%;
			order: 2;
			flex-basis: 100%;

			@include mq(tablet) {
				position: absolute;
				z-index: 2;
				top: 30px;
				left: 50px;
			}

			> .oo-headline {
				max-height: 80px;
				overflow: hidden;
				margin: 0;
				padding: 10px 15px 0;
				@include milo-slab-bold(24px, 24px);

				@include mq(tablet) {
					color: $color-primary-2;
					max-width: 450px;
				}

				&--black {
					color: $color-primary-3;
				}
			}
		}

		> section.oo-content-box {
			position: static;
			width: 100%;
			order: 3;
			background: none;
			padding: 10px 15px 20px 15px;
			flex-basis: 100%;

			@include mq(tablet) {
				padding: 36px 50px 20px 50px;
			}

			> .oo-subheadline {
				margin-bottom: 8px;
			}
		}
	}
}