/* a-basic-input-text */
.a-basic-input-text {
	display: inline-block;
	width: 100%;
	position: relative;
	//overflow: hidden; was since 2016, but there is no reason
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

	.l-form-general & {

		&--right-border {
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				right: 0;
				width: 2px;
				height: 20px;
				border-right: 1px solid $color-formfield-border-standard;

				&:hover {
					border-color: $color-formfield-border-hover;
				}
			}

			input:hover,
			input {
				border-right: none !important;
				border-top-right-radius: 0 !important;
				border-bottom-right-radius: 0 !important;
			}

			input + .js-clear-button {
				display: none;
			}
		}

		&.js-focus {
			&:after {
				border-right: 2px solid $color-secondary-22;
			}
		}
	}

	// needed for multirows, where label is only set on first input field
	.l-form-general .ll-multirow .ll-row:not(.is-floating) + .ll-row .ll-item-container > :first-child:not(.ll-col-label) &:not(.is-floating),
	.l-form-general .ll-multirow .ll-row:not(.is-floating) + .ll-row > :first-child:not(.ll-col-label):not(.ll-item-container) &:not(.is-floating) {
		margin-top: 22.4px;
	}

	.l-form-general .a-basic-input-text:not(.a-basic-input-text--right-border) + &:not(.a-basic-input-text--xs) {
		margin-left: 4.69%;

		@include mq(tablet) {
			margin-left: 15px;
		}
	}

	.l-form-general .a-basic-input-text--right-border + & {

		input:hover,
		input {
			border-left: none !important;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		label {
			display: none;
		}
	}

	.l-form-general .ll-col-data:hover .a-basic-input-text--right-border + &:not(:disabled) {
		input {
			border-left: none;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	//&.a-basic-input-text--s, &.a-basic-input-text--xs, &.a-basic-input-text--date {
	&.a-basic-input-text--s,
	&.a-basic-input-text--xs {
		&:before {
			// hide clear icon here
			background: initial;
		}

		& ~ .ll-check {

			.l-form-general .ll-row.is-check &,
			.m-basic-calculator-content-box-newform .ll-row.is-check & {
				opacity: 0;

				@include mq('tablet') {
					opacity: 1;
				}
			}
		}
	}

	// exception for .mm-searchbar, inside m-basic-keywordfilter
	.m-basic-keywordfilter:not(.m-basic-keywordfilter--button) .mm-searchbar & {
		&.js-focus {
			&:before {
				left: calc(100% - 72px);
			}
		}
	}

	// exception for .mm-searchbar, inside m-basic-keywordfilter
	.l-form-general .m-basic-keywordfilter:not(.m-basic-keywordfilter--button) .mm-searchbar & {
		&.js-focus {
			&:before {
				left: 100%;
			}
		}
	}

	// exception for ADAC Maps
	.m-adac-maps & {

		@include mq(tablet) {
			max-width: 307px;
			margin-right: 12px;
		}
	}

	.l-form .ll-col-label + .ll-col-data & {
		max-width: 400px;

		// exception for m-basic-socialshare
		.m-basic-socialshare & {
			max-width: none;
		}
	}

	// exception when in .o-basic-form
	.o-basic-form .oo-col-label + .oo-col-data & {
		max-width: 278px;
	}

	// exception when in .m-ves-rs-contentbox
	.m-ves-rs-contentbox & {
		margin-right: 0;
	}

	// exception when in .m-ves-rs-contentbox .mm-upload-container
	.m-ves-rs-contentbox .mm-upload-container .mm-row &,
	.m-ves-rs-contentbox .mm-tile-container--col-1-5 .mm-row & {

		// fix height to prevent height expanding when error border is set
		@include mq(desktop) {
			max-height: 36px;
		}

		// row has error class
		@include if-parent('.mm-row.is-check.is-error') {
			border-bottom: 2px solid $color-action-alert;
		}
	}

	.m-ves-rs-contentbox .mm-upload-container .mm-row .mm-row-content &,
	.m-ves-rs-contentbox .mm-tile-container--col-1-5 .mm-row .mm-row-content & {

		// row has error class
		@include if-parent('.mm-row-content.is-check.is-error') {
			border-bottom: 2px solid $color-action-alert;
		}

	}

	.m-ves-rs-contentbox .ll-item-container--full & {
		margin: 0 auto;

		@include mq(tablet) {
			margin: 0 0 0 0;
		}
	}

	// exception .m-ves-rs-contentbox--damagedeclaration
	.m-ves-rs-contentbox--damagedeclaration .l-form .ll-item-container > .ll-col-data & {
		& + p.ll-inputinfo {
			margin: 5px 0;
			text-align: left;
			@include font-size($font-size-14, 18px);
		}

		& + .m-basic-info-layer {
			.mm-infolayer-icon {
				left: calc(100% - 30px);
				bottom: 5px;

				@include mq(tablet) {
					left: auto;
					right: -35px;
				}
			}
		}
	}

	.m-ves-rs-contentbox--damagedeclaration .l-form .ll-item-container--full > .ll-col-data & {
		margin: 0;
	}

	.l-form .ll-row--full .ll-col-label + .ll-col-data & {
		max-width: 470px;
	}

	// exception for 3+ inputs next to each other in tablet+
	.m-basic-calculator-content-box .l-form .ll-multirow-container.ll-multirow-33 > .ll-row > .ll-item-container > .ll-col-data > & {
		@include mq(tablet) {
			max-width: 215px;
		}

		@include mq(desktop) {
			max-width: 400px;
		}
	}

	// exception for 3+ inputs next to each other in tablet+
	.m-basic-calculator-content-box-newform & {
		@include mq(desktop) {
			width: 400px;
			max-width: 400px;
		}
	}


	// exception for l-form--cardamage
	.l-form--cardamage-map .ll-row--full .ll-col-label + .ll-col-data & {
		max-width: 305px;
	}

	// exception for l-form--cardamage
	.l-form--cardamage-proof .ll-row--full .ll-col-data & {
		max-width: 305px;
	}

	// exception when in .a-basic-input-radio
	.a-basic-input-radio label & {
		overflow: inherit;
		margin-top: -10px;
	}

	// Exception o-layout-header-metanav
	.o-layout-header-metanav & {
		max-width: inherit;
	}

	// Exception m-basic-keywordfilter
	.m-basic-keywordfilter & {
		max-width: inherit;

		// exception for myAdac
		.l-outer--my-adac &,
		.l-outer--my-adac-content & {

			input {
				padding: 0 0 5px 12px;
			}
		}
	}

	// exception FdlCalculator
	.m-fdl-calculator &,
	.m-fdl-calculator & + .ll-description {
		width: calc(100% - 40px);
		margin: 0 20px;
	}

	// exception ll-data-input-text
	.ll-data-input-text + & {
		float: left;
		margin: 0 10px 0 0;
	}

	// exception for cardamage-forms
	.l-form .ll-row--cardamage .ll-col-label ~ .ll-col-data & + & {
		margin-left: 5px;
	}

	// exception .m-basic-calculator-content-box(-newform)
	.m-basic-calculator-content-box .mm-form-container > .mm-fieldset > .mm-row > .mm-inputcontainer > &,
	.m-basic-calculator-content-box-newform .mm-form-container > .mm-fieldset > .mm-row > .mm-inputcontainer > & {

		// if more than one input is present
		&:not(:only-of-type) {
			max-width: 145px;
			margin-left: 5px;
			margin-right: 5px;

			&:first-of-type {
				margin-left: 0;
				flex-shrink: 0;
			}

			&:last-of-type {
				margin-right: 0;
				max-width: none;
			}
		}

		&.js-focus {
			& + .mm-description {
				opacity: 1;
				max-height: 200px;
			}
		}

		// row has error class
		@include if-parent('.mm-row.is-check.is-error') {
			border-bottom: 2px solid $color-action-alert;
		}

		// exception for mm-row--column
		@include if-parent('.mm-row.mm-row--column') {
			margin-left: 0;
			order: 3;
		}

		@include if-parent('.mm-inputcontainer.is-check.is-error') {
			border-bottom: 2px solid $color-action-alert;
		}
	}

	// exception .m-basic-calculator-content-box(-newform) .mm-row--center-big
	.m-basic-calculator-content-box .mm-form-container > .mm-fieldset > .mm-row.mm-row--center-big > .mm-inputcontainer > &,
	.m-basic-calculator-content-box-newform .mm-form-container > .mm-fieldset > .mm-row.mm-row--center-big > .mm-inputcontainer > & {
		max-width: 400px;
	}

	// exception .m-basic-calculator-content-box(-newform)
	.m-basic-calculator-content-box > .mm-upload-container > .mm-row > .mm-row-content--right > &,
	.m-basic-calculator-content-box-newform > .mm-upload-container > .mm-row > .mm-row-content--right > & {
		@include mq(tablet) {
			max-width: 100px;
		}
	}

	// exception for .m-basic-radius-search--locksmith
	.m-basic-radius-search--locksmith > & {
		flex: 0 1 70%;
		margin: 0 10px 0 0;

		@include mq(tablet) {
			margin: 0 20px 0 0;
			flex: 0 1 42%;
		}
	}

	// exception for .m-basic-radius-search--locksmith in .l-form-general
	.l-form-general .m-basic-radius-search--locksmith > & {
		@include mq(tablet) {
			flex: 0 1 60%;
		}
	}

	input {
		// Mehrere werden angezeigt
		width: 100%;
		height: 36px;
		padding: 5px 30px 5px 15px;
		transition: background 0.4s;
		border-bottom: 1px solid $color-secondary-9;
		background-repeat: no-repeat;
		background-color: $color-secondary-8;

		&::placeholder {
			color: $color-secondary-23;

			.m-basic-keywordfilter & {
				color: $color-secondary-1;

				.l-form-general & {
					color: $color-secondary-23;
				}
			}
		}

		&:not([type='text']):not([type='number']):not([type='email']) {

			//exception if input field is included inside a radio button
			.l-form-general .ll-col-data .a-basic-input-radio & {
				height: 35px;
				padding: 5px 5px 5px 10px;
			}
		}

		.l-form-general & {
			background: $color-primary-2;
			height: 55px;
			padding: 5px 10px 0 15px;
			border: 1px solid $color-formfield-border-standard;
			border-radius: 4px;

			@include if-parent('.a-basic-input-text:not(.js-focus)') {
				&:hover:not(:focus):not(:disabled) {
					border: 1px solid $color-formfield-border-hover;
				}
			}
		}

		@include if-parent('.a-basic-input-text:not(.js-focus)') {
			.l-form-general .ll-col-data:hover &:not(:disabled):not(:focus) {
				border: 1px solid $color-formfield-border-hover;
			}
		}

		.l-form-general .ll-row.is-error & {
			border: 2px solid $color-action-alert;
		}

		.l-form-general .ll-row.is-error &:hover {
			border: 2px solid $color-action-alert;
		}

		.l-form-general .ll-row.is-error .ll-col-data:hover &:not(:disabled) {
			border: 2px solid $color-action-alert;
		}

		@include if-parent('.a-basic-input-text:not(.a-basic-input-text--xs):not(.a-basic-input-text--date)') {
			.l-form-general & {
				padding: 5px 50px 0 15px;
			}
		}

		//exception .m-basic-keywordfilter
		.l-form-general .ll-row.is-filled .m-basic-keywordfilter & {
			padding-top: 25px;
		}

		.l-form-general .is-filled .ll-col-label + .ll-col-data & {
			padding-top: 25px;
		}

		//exception if inside m-basic-radius-search
		.l-form-general .m-basic-radius-search & {
			padding: 30px 30px 5px 15px;
		}

		.l-form--background &,
		.l-form-general--background & {
			background-color: $color-primary-2;
		}

		.o-layout-main-stage .m-basic-radius-search & {
			@include mq(desktop) {
				background-color: $color-primary-2;
			}
		}

		.m-basic-sticky-service-contact & {
			@include mq(tablet) {
				background-color: $color-primary-2;
			}
		}

		.m-adac-maps .m-basic-radius-search & {
			background-color: $color-secondary-8;
		}

		// exception .m-ves-rs-contentbox--damagedeclaration
		.m-ves-rs-contentbox--damagedeclaration & {
			@include if-parent('.a-basic-input-text.is-uppercase') {
				text-transform: uppercase;
			}
		}

		// exception for moecule ves-rs-contentbox--grey
		.m-ves-rs-contentbox--grey & {
			background-color: $color-primary-2;
		}

		// exception for helper .h-background
		.h-background & {
			background-color: $color-primary-2;
		}


		@include if-parent('.a-basic-input-text.js-focus') {
			.l-form-general & {
				border-color: $color-secondary-17;
				border-width: 2px;
			}
		}

		&:focus {
			border-bottom: 2px solid $color-primary-1;
			padding-top: 7px;

			.l-form-general & {
				border-color: $color-secondary-17;
				border-width: 2px;
			}
		}

		@include if-parent('.a-basic-input-text:not(.a-basic-input-text--xs):not(.a-basic-input-text--s):not(.a-basic-input-text--daterange)') {
			&.a-basic-input-text--clear:not([data-dateformat]) {
				background-color: $color-secondary-8;
				background-repeat: no-repeat;
				transition: background 4s;

				.l-form--background & {
					background-color: $color-primary-2;
				}

				.l-form-general & {
					background-color: $color-primary-2;
				}

				// exception for molecule ves-rs-contentbox--grey
				.m-ves-rs-contentbox--grey & {
					background-color: $color-primary-2;
				}

				// exception for helper h-background
				.h-background & {
					background-color: $color-primary-2;
				}

				// Exception
				.mm-searchbar--searchicon & {
					padding-right: 65px;
					background-position: right 35px center;

					+ .js-clear-button {
						right: 35px;
					}
				}

				.l-form-general .ll-row.is-error.is-check .ll-col-data &,
				.l-form-general .ll-row.is-valid.is-check .ll-col-data & {
					padding-right: 75px;
				}

				.l-form-general .ll-row.is-error.is-check .ll-col-data &.is-floating,
				.l-form-general .ll-row.is-valid.is-check .ll-col-data &.is-floating {
					padding: 25px 75px 0 15px;
				}

				.m-basic-keywordfilter & + .js-clear-button {
					bottom: -4px;
				}

				.l-form-general & + .js-clear-button {
					top: 15px;
					right: -5px;
					transition: right 0.4s;
				}

				.l-form-general .m-basic-keywordfilter .mm-searchbar--searchicon & + .js-clear-button {
					right: 29px;
				}

				.l-form-general .ll-row.is-check.is-error > .ll-col-data > .m-basic-radius-search & + .js-clear-button,
				.l-form-general .ll-row.is-check.is-valid > .ll-col-data > .m-basic-radius-search & + .js-clear-button,
				.l-form-general .ll-row.is-check.is-error > .ll-col-data > & + .js-clear-button,
				.l-form-general .ll-row.is-check.is-valid > .ll-col-data > & + .js-clear-button {
					right: 20px;
					transition: right 0.4s;
				}

				// exception if button has an edit button
				@include if-parent('.a-basic-input-text.a-basic-input-text--edit') {

					+ .js-clear-button {
						right: 35px;
					}
				}
			}
		}

		.l-form & {
			&.is-error {
				border-bottom: 2px solid $color-action-alert;
			}
		}

		.ll-row.is-check.is-error & {
			border-bottom: 2px solid $color-action-alert;

			.l-form-general & {
				border-color: $color-action-alert;
				border-width: 2px;
			}
		}


		&:disabled {
			opacity: 1;
			background: $color-secondary-7;
			color: $color-secondary-20;
			-webkit-text-fill-color: $color-secondary-20;

			.l-form-general & {
				background: $color-primary-2;
				border-color: $color-secondary-5;
				color: $color-secondary-20;
			}

			& ~ .aa-col-label {
				width: calc(100% - 30px);
				height: 100%;
				color: $color-secondary-20;

				&:after {
					@include main-icon-lock;
					content: '';
					position: absolute;
					right: -10px;
					top: 3px;
					opacity: 0.3;
				}

				> .aa-label {
					color: $color-secondary-20;
				}
			}

			&.is-empty ~ .aa-col-label {
				&:after {
					top: -10px;
				}
			}
		}

		&[value]:not([value=""]),
		&:not(.is-empty),
		&:not(:placeholder-shown),
		&:not([placeholder=" "]),
		&:focus,
		&.js-clear,
		&.a-basic-input-text--clear {
			@include if-parent('.a-basic-input-text.is-floating:not(.a-basic-input-text--ds-filter)') {
				.l-form-general & {
					padding-top: 25px !important;
					transition: top 0.5s;
				}
			}
		}

		&:not(.js-clear) + .js-clear-button {
			display: none;
		}

		~ .aa-col-label {
			@include if-parent('.a-basic-input-text.is-floating') {
				@include if-parent('input[value]:not([value=""]):not(.is-empty), input:not(.is-empty), input:not(:placeholder-shown), input:not([placeholder=" "]), input:focus, input.js-clear, input.a-basic-input-text--clear') {
					.l-form-general & {
						top: 3px;
					}
				}
			}

			@include if-parent('.a-basic-input-text.a-basic-input-text--s:not(.a-basic-input-text--right-border).is-floating') {
				@include if-parent('input:not(.a-basic-input-text--clear):not(:focus)') {

					.l-form-general .ll-row.is-check.is-error &,
					.l-form-general .ll-row.is-check.is-valid & {
						max-width: 45px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.l-form-general .ll-row.is-check.is-error &,
				.l-form-general .ll-row.is-check.is-valid & {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.l-form-general .ll-row.is-check.is-error & {
					max-width: 50px;
				}

				.l-form-general .ll-row.is-check.is-valid & {
					max-width: 60px;
				}
			}

			> .aa-label {
				@include if-parent('.a-basic-input-text.is-floating') {
					@include if-parent('input[value]:not([value=""]):not(.is-empty), input:not(.is-empty), input:not(:placeholder-shown), input:not([placeholder=" "]), input:focus, input.js-clear, input.a-basic-input-text--clear') {
						.l-form-general & {
							@include milo-regular($font-size-14);
						}
					}

					.l-form-general .ll-row.is-check.is-error & {
						color: $color-action-alert;
					}
				}
			}
		}

	}

	> .aa-col-label {
		.l-form-general & {
			position: absolute;
			z-index: 1;
			top: 16px;
			left: 15px;
			transition: top 0.5s;
			max-width: 400px;
			width: calc(100% - 50px);
			overflow: hidden;
			pointer-events: none;
		}

		@include if-parent('.a-basic-input-text.a-basic-input-text--right-border') {
			width: 100%;
			overflow: visible;
		}

		> .aa-label {
			user-select: none;

			.l-form-general & {
				color: $color-secondary-20;
				@include milo-regular($font-size-16);
				white-space: nowrap;
				font-weight: normal;
			}

			.l-form-general .m-basic-keywordfilter--location & {
				display: block;
				overflow: hidden;
				max-width: 135px;
				text-overflow: ellipsis;

				@include mq(tablet) {
					max-width: 270px;
				}
			}
		}
	}

	> span {
		.l-form-general & {
			display: flex;
			margin-top: 18px;
		}
	}


	.js-clear-button {
		position: absolute;
		bottom: 6px;
		right: 0;
		height: 30px;
		width: 27px;
		cursor: pointer;
		z-index: 3; // parent has an index of 2 and clear button wouldn't emit and triggered correctly

		.l-form-general & {
			bottom: -4px;
			width: 44px;
			height: 44px;
		}

		> span {
			position: relative;
			top: 3px;
			right: 5px;
			display: inline-block;
			width: 26px;
			height: 26px;
			border-radius: 50%;

			&:after {
				content: '';
				position: absolute;
				left: -11px;
				top: -11px;
				transition: right 0.4s;
				@include main-icon-cancel;
			}
		}

		&:focus {
			outline: none;

			> span {
				.is-keyboard-focus .l-form-general & {
					border: 2px solid $color-primary-3;
					border-radius: 50%;
				}
			}
		}

		.mm-searchbar & {
			height: 100%;

			.l-form-general & {
				height: initial;
			}
		}
	}

	.js-eye {
		width: 30px;
		cursor: pointer;
		display: inline-block;
		height: 100%;
		position: absolute;
		right: 5px;
		transition: right 0.5s;
		@include main-icon-edit;
		background-repeat: no-repeat;

		.l-form-general & {
			top: 5px;
		}

		&.is-shown {
			@include main-eye-off;
			background-repeat: no-repeat;
		}
	}

	.js-clear-button + .js-eye {
		right: 33px;
		top: -2px;
	}

	input[type=number]::-webkit-outer-spin-button,
	input[type=number]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	input[type=number] {
		-moz-appearance: textfield;
	}

	// Exception iOS in body
	input[type=date],
	input[type=month] {
		.l-outer.is-ios & {
			-webkit-appearance: none;
			height: 37px;
		}

		.l-outer.is-ios .l-form-general & {
			height: 55px;
		}
	}

	input[type=search] {
		.m-basic-keywordfilter .mm-searchbar--searchicon &.a-basic-input-text--clear {
			padding-right: 65px;
			background-position: right 35px center;

			// exception for myAdac
			.l-outer--my-adac &,
			.l-outer--my-adac-content & {
				background-position: right 40px top;
			}
		}

		.l-form .m-basic-keywordfilter .mm-searchbar--searchicon & + .js-clear-button {
			right: 35px;

			// exception for myAdac
			.l-outer--my-adac &,
			.l-outer--my-adac-content & {
				right: 40px;
			}
		}
	}

	&.h-space-s {
		margin-bottom: 0;

		// exception m-adac-maps
		.m-adac-maps .mm-maps-nav & {
			margin-bottom: 15px;
		}
	}

	.m-adac-maps--mgldiscountmaps & {
		> input {
			&::placeholder {
				color: $color-secondary-2;
			}
		}
	}

	.o-layout-main-stage--search .ll-fieldset .ll-col-data & {
		max-width: none;
	}

	&.a-basic-input-text--my-adac-content {
		> input:disabled {
			color: $color-secondary-3;
		}
	}

	// exception .m-basic-radius-search
	.m-basic-radius-search > .l-form .ll-col-label + .ll-col-data & {
		width: 100%;
		max-width: none;
		display: block;

		&:not(.a-basic-input-text--location) {
			width: auto;
		}
	}

	// exception for input in stage with larger slide content wrapper
	.oo-slide-content-wrapper--big .ll-main-cols-m & {
		margin-bottom: 15px;
		width: 100%;

		@include mq(tablet) {
			margin-bottom: 0;
		}
	}

	.oo-box-flex & {
		width: 300px;
	}

	// exception ll-fieldset--search
	.l-form .ll-fieldset--search .ll-row .ll-col-data & {
		@include mq(tablet) {
			float: left;
			width: calc(50% - 5px);
		}
	}

	// exception ll-col-data-flex (for input fields with text next to it)
	.l-form .ll-fieldset .ll-col-data-flex & {
		> span {
			display: inline-block;
			padding-top: 6px;
		}
	}
}