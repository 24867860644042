/* .m-basic-hint--big */
.m-basic-hint--big {
	padding-top: 15px;
	padding-left: 36px;

	@include mq(tablet) {
		padding-top: 11px;
	}

	> img {
		top: 12px;
		left: -6px;

		@include mq(tablet) {
			top: 13px;
			left: -6px;
		}
	}
}