%h-visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

%h-visuallyhidden-reset {
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}

// Clearfix: contain floats
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    `contenteditable` attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that receive the `clearfix` class.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
%cf:before,
%cf:after {
	content: " "; // 1
	display: table; // 2
}

%cf:after {
	clear: both;
}

// display output as digits
%display-as-digits {
	font-family: "DS-Digital-Italic", sans-serif;
	font-size: 24px;
	line-height: normal;
	background-color: $color-primary-3;
	color: $color-primary-2;
	padding: 0 4px;
	min-width: 60px;
	display: inline-block;
	text-align: right;
}

%lazy-img {
	height: auto;
	display: inline-block;
	position: relative;
	max-width: 100%;
	min-width: 100%;

	// check if width is given
	&:not([width]) {
		width: 100%;
	}
}

%focus-outline {
	&:focus {
		outline: none;

		.is-keyboard-focus & {
			outline: 2px solid $color-focus-tab;
			border-radius: 2px;
		}
	}
}

%focus-outline-inverse {
	border: 2px solid transparent !important;
	box-shadow:
		0 0 0 2px transparent,
		0 0 0 4px transparent,
		0 0 0 6px transparent;

	&:focus {
		outline: none;

		.is-keyboard-focus & {
			background-color: $color-secondary-18;
			box-shadow:
				0 0 0 2px $color-secondary-18,
				0 0 0 4px $color-secondary-18,
				0 0 0 6px $color-primary-2
		}
	}
}

%focus-border {
	&:focus {
		outline: none;

		.is-keyboard-focus & {
			border: 2px solid $color-focus-tab;
			border-radius: 2px;
		}
	}
}

%focus-border-inverse {
	&:focus {
		outline: none;

		.is-keyboard-focus & {
			border: 2px solid $color-primary-2;
			border-radius: 2px;
		}
	}
}

%focus-button-styling {
	border: 2px solid $color-focus-tab;
	background-color: $color-action-btn2;
	outline: none;

	&::after {
		content: '';
		position: absolute;
		width: calc(100% + 14px);
		height: calc(100% + 14px);
		left: -7px;
		top: -7px;
		background: transparent;
		border: 3px solid black;
		border-radius: 10px;
	}
}

%focus-border-buttons {
	&:focus {
		outline: none;
		@extend %focus-button-styling;

		.is-keyboard-focus & {
			@extend %focus-button-styling;
		}
	}
}

%basic-table-paddings {
	tr {
		> td {
			&:not(:last-child) {
				padding-right: 30px;
			}
		}
	}
}

%input-chips {
	> input:not(.is-checkbox) {
		position: absolute;
		z-index: 0;
		opacity: 0;

		.is-keyboard-focus &:focus + label {
			padding: 2px 14px;
			border: 2px solid $color-primary-3;
			background-color: $color-action-btn2;
		}

		&:checked + label {
			border: 2px solid $color-primary-3;
			background-color: $color-primary-1;
			padding: 2px 14px;
		}
	}

	> label:not(.is-checkbox) {
		border: 1px solid $color-secondary-2;
		border-radius: 20px;
		padding: 3px 15px;
		display: flex;
		column-gap: 10px;
		align-items: center;
		cursor: pointer;
		min-width: 45px;
		text-align: center;
	}

	> input.is-checkbox {
		position: absolute;
		z-index: 0;
		opacity: 0;

		.is-keyboard-focus &:focus + label {
			&:before {
				border: 2px solid $color-primary-3;
			}
		}
	}

	> label.is-checkbox {
		position: relative;
		padding-left: 25px;
		cursor: pointer;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			border: 2px solid $color-secondary-2;
			width: 18px;
			height: 18px;
		}

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 44px;
			height: 44px;
		}
	}
}