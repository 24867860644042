/* a-basic-stopper--bigsingleline */
.a-basic-stopper--bigsingleline {
	position: relative;
	display: flex;
	justify-content: flex-end;
	top: 0;
	right: -15px;

	@include mq(tablet) {
		right: -40px;
	}

	@include mq(desktop) {
		right: -50px;
	}

	.aa-inner-content {
		margin-bottom: 10px;
		padding: 9px 10px 9px 8px;

		span {
			overflow: visible;

			&.aa-content {
				max-width: 230px;
				@include milo-bold($font-size-20);

				.h-roboto & {
					font-family: 'Roboto Bold', sans-serif;
				}
			}
		}

	}
}