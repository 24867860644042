/* a-basic-top */
.a-basic-time-refresh {
	> .aa-timerefresh {
		position: relative;
		line-height: 18px;

		@include mq(desktop) {
			margin-top: 14px;
		}

		> .aa-timerefresh-content {
			border-top: 1px solid $color-secondary-3;
			border-bottom: 1px solid $color-secondary-3;
			position: relative;
			padding: 10px 5px;
			margin-bottom: 30px;

			display: flex;
			flex-wrap: wrap;
			align-items: center;


			> .aa-timerefresh-wrapper {
				flex: 1;
				display: flex;

				> .aa-last {
					margin-right: 10px;
				}

				> .aa-timestamp {
					white-space: nowrap;
					color: $color-secondary-3;
				}
			}

			> .aa-refresh-icon {
				width: 30px;
				height: 30px;
				background-color: $color-primary-1;
				cursor: pointer;

				> img {
					margin: 3px 0 0 3px;
				}
			}
		}
	}
}