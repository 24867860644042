// m-basic-member-puzzle
.m-basic-member-puzzle--upsidedown {
	> .mm-member-gallery-wrapper {
		&.is-active {
			position: fixed;
			bottom: 0;
			background: white;
			z-index: 10;
			width: 100vw;
			left: 50%;
			transform: translate(-50%, 0);
			margin: 0 auto;
		}

		&.is-stuck-bottom {
			bottom: -136px;
			position: absolute;
			top: auto;

		}
	}
}