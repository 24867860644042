/* a-basic-stopper--blue */
.a-basic-stopper--blue {

	> .aa-inner-content {
		background: $color-secondary-11;

		&::before {
			border-color: $color-secondary-1 transparent transparent transparent;
		}

		&:after {
			border-color: $color-secondary-11 transparent transparent transparent;
		}

		> .aa-headline {
			color: $color-primary-2;

			@include mq(print) {
				color: $color-primary-3;
			}
		}

		> .aa-content {
			color: $color-primary-2;

			@include mq(print) {
				color: $color-primary-3;
			}
		}

		> .aa-thirdline {
			color: $color-primary-2;

			@include mq(print) {
				color: $color-primary-3;
			}
		}
	}
}