/* m-basic-list--number */
.m-basic-list--number {
	counter-reset: number;
	margin-top: 15px;

	//Exception override basicText Exception
	.m-basic-text .mm-content & {
		padding-top: 15px;
	}

	li {
		counter-increment: number;
		padding-left: 45px;
		margin-bottom: 30px;

		//Exception override basicText Exception
		.m-basic-text .mm-content & {
			margin-bottom: 30px;
		}

		&:before {
			content: counter(number)" ";
			background: $color-secondary-16;
			color: $color-secondary-1;
			border-radius: 50%;
			width: 25px;
			height: 25px;
			left: 0;
			top: -3px;
			text-align: center;
			padding-top: 3px;
			z-index: 1;

			@include if-parent('.m-basic-list--number.m-basic-list--number-yellow') {
				background: $color-primary-1;
				color: $color-primary-3;
			}
		}

		&:not(:last-child):after {
			content: '';
			position: absolute;
			top: 0;
			left: 12px;
			width: 1px;
			height: calc(100% + 30px);
			background: $color-secondary-16;
		}
	}
}