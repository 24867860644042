/* .m-basic-hint--compability */
.m-basic-hint--compability {
	padding: 20px 15px 20px 58px;

	.l-form--background &,
	.l-form-general--background & {
		padding: 20px 15px 0 50px;
		max-width: 450px;

		@include mq(tablet) {
			min-height: initial;
		}

		img {
			left: 0;
		}
	}

	img {
		left: 8px;
	}

	> .m-basic-text {
		> h2 {
			@include milo-bold(16px);
			line-height: 20px;
			margin-top: -6px;
		}
	}
}