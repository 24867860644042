/* m-my-adac-content-hint*/
.m-my-adac-content-hint {
	max-width: 960px;
	width: 100%;

	> .mm-wrapper {
		border-top: 1px solid $color-secondary-13;
		border-bottom: 1px solid $color-secondary-13;
		padding: 24px 0;

		@include mq(tablet) {
			padding: 24px 20px;
		}

		@include mq(desktop) {
			position: relative;
			margin: 0 -155px;

			@include if-parent('.m-my-adac-content-hint.m-my-adac-content-hint--regular') {
				margin: 0 auto;
			}
		}

		&:before {
			content: '';

			@include mq(desktop) {
				position: absolute;
				width: 100vw;
				height: 100%;
				left: calc(50% - 50vw); // 50% - "halbes width"
				top: 0;
				z-index: -1;
			}
		}

		> .mm-content {
			max-width: 760px;
			width: 100%;
			margin: 0 auto;
		}
	}
}