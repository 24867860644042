/* m-basic-anchornav--notoggle */
.m-basic-anchornav--notoggle {
	> .mm-anchorlist {
		display: block;
		position: absolute;
		width: 100%;
		padding: initial;
		background: $color-primary-2;
		margin: 0 0 20px;
		border-bottom: none;
		z-index: 35;
		top: 0;
		box-shadow:
			0 2px 4px -1px rgba(0, 0, 0, 0.2),
			0 1px 10px 0 rgba(0, 0, 0, 0.12),
			0 4px 5px 0 rgba(0, 0, 0, 0.14);
		border-radius: 5px 5px 0 0;

		@include mq(tablet) {
			position: absolute;
		}

		> ul {
			padding: 0;
			margin: 0;
			overflow: hidden;
			display: flex;
			flex: 0 0 auto;
			flex-direction: row;

			&:after {
				content: initial;
			}

			> .mm-anchor {
				padding: 0 10px 500px 10px;
				margin-bottom: -500px;
				height: 100%;
				border-left: initial;
				border-bottom: 1px solid $color-secondary-9;

				&:first-child {
					border-left: 0 none transparent;
					border-top: 0 none transparent;
				}

				&.is-shown {
					background: $color-primary-1;
					border-radius: 5px;
				}

				> a {
					position: relative;
					display: block;
					text-decoration: none;
					overflow: hidden;
					border: 2px solid transparent;
					font-weight: lighter;
					margin: 0 -10px -1px -10px;
					padding: 10px 15px 10px 15px;
					color: $color-secondary-20;
					@include font-size($font-size-13, 14px);
					@include milo-bold();
					@extend %focus-border;

					@include mq(tablet) {
						@include milo-slab-bold($font-size-15, 20px);
					}

					&:before {
						display: none;
					}

					&:focus {
						outline: 0 none transparent;

						.is-keyboard-focus & {
							border-radius: 5px 5px 0 0;
						}
					}

					&:hover {
						border-bottom: 2px solid black;
						color: $color-secondary-17;
					}

					@include if-parent('.mm-anchor.is-shown') {
						border-bottom: 2px solid black;
						color: $color-secondary-17;
					}

					> svg {
						display: none;
					}

					> img {
						display: none;
					}
				}
			}

			> .mm-indicator {
				transition: left 0.3s linear;
				width: 0;
				height: 0;
				padding: 0;
				position: absolute;
				display: block;
				border-left: 11px solid transparent;
				border-right: 11px solid transparent;
				border-top: 13px solid $color-primary-3;
				bottom: -13px;
				left: 15px;

				@include mq(tablet) {
					border-left: 14px solid transparent;
					border-right: 14px solid transparent;
					border-top: 17px solid $color-primary-3;
					bottom: -16px;
				}

				@include mq(print) {
					display: none;
				}
			}
		}
	}

	> .mm-contentwrapper {
		position: relative;
		top: 50px;

		@include mq(tablet) {
			top: 0;
		}

		> .mm-content {
			top: 0;
			width: 100%;
			min-height: inherit;
			background: $color-primary-2;
			padding: 20px 0 30px 0;
			position: relative;
			opacity: 1;
			left: auto;
			height: auto;
			overflow: hidden;

			.mm-sticky-anchor {
				display: initial;

				.mm-anchorpoint {
					overflow: hidden;
					display: block;
					background: $color-primary-1;
					font-weight: bold;
					cursor: pointer;
					text-decoration: none;
					position: absolute;
					height: 0;
					width: 0;
					padding: 0;
					border: 0 none transparent;

					> svg {
						position: absolute;
						left: 0;
						top: -1px;
					}
				}
			}
		}
	}
}