.m-adac-maps-table {
	display: flex;
	width: calc(100% - 460px);
	min-height: 100%;

	.l-main-cols & {
		width: 100%;
	}

	> .mm-country-informations {
		overflow: hidden;
		width: 100%;

		@include mq(tablet) {
			box-sizing: border-box;
		}

		> .mm-country-informations-head {
			@include milo-bold();
			border: 2px solid $color-secondary-1;
			padding: 9px;
			position: relative;

			> .mm-country-information-flag {
				@include absolute($top: 0, $right: 10px, $bottom: 0);
				margin: auto;
				max-width: 45px;
			}
		}

		> .mm-country-information-content {
			border: 1px solid $color-secondary-3;
			border-top: 0;
			padding: 9px 10px;

			@include mq(tablet) {
				min-height: calc(320px - 45px);
				height: calc(100% - 45px);
			}
		}

		&:last-child {
			margin-right: 0;
		}
	}
}