/* m-basic-progress-nav */
.m-basic-progress-nav-form {
	position: relative;

	&.h-space-m {
		margin-bottom: 40px;
	}

	> .mm-nav-wrapper {
		display: flex;
		padding: 20px 10px 30px 10px;
		border: 1px solid $color-secondary-16;
		border-radius: 8px;

		@include mq(desktop) {
			padding: 20px 20px 30px 20px;
		}

		> li {
			display: flex;
			align-items: center;

			&:not(.mm-active) {
				.mm-text {
					display: none;

					@include mq(desktop) {
						display: inline;
					}
				}

				.mm-mobile-styling {
					display: none;
				}
			}

			&:not(:last-child) {
				flex: 1;
				margin-right: 5px;

				@include mq(desktop) {
					margin-right: 15px;
				}
			}

			&:first-child {
				.mm-text {
					> .mm-text-inner {
						left: 0;
						transform: translateX(0);
					}

					> .mm-mobile-styling {
						left: -25px;
					}
				}
			}

			&:last-child {
				min-width: 20px;

				> .mm-line {
					display: none;
				}

				.mm-text {
					> .mm-text-inner {
						transform: translateX(-100%);
						left: 100%;

						@include mq(desktop) {
							left: 0;
							padding-right: 0;
						}
					}
				}
			}

			&.mm-mobile {
				min-width: 46px;
				height: 46px;
				border-radius: 50%;
				position: relative;
				overflow: hidden;

				@include mq(desktop) {
					display: none;
				}

				> .mm-step {
					display: block;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					width: 38px;
					height: 38px;
					background-color: $color-primary-2;
					border-radius: 50%;
					font-weight: bold;
					font-size: 14px;
					z-index: 1;
				}
			}

			&.mm-active ~ li,
			&.mm-active {
				.mm-digit {
					&:before {
						display: none;
					}

					> span {
						display: inline-block;
						width: 25px;
						height: 25px;
					}
				}
			}

			&.mm-active ~ li {
				.mm-digit {
					background-color: $color-secondary-16;

					> span {
						color: $color-secondary-20;
					}
				}
			}

			> a,
			> span {
				text-decoration: none;
				position: relative;

				@include mq(desktop) {
					display: flex;
					align-items: center;
				}
			}

			.mm-digit {
				background-color: $color-primary-1;
				width: 25px;
				height: 25px;
				border-radius: 50%;
				display: block;

				&:before {
					@include main-icon-check-processnavi();
					content: '';
					position: relative;
					width: 30px;
					height: 30px;
					display: block;
					top: 1px;
					left: 2px;
					transform: scale(0.9);
				}

				> span {
					display: none;
					width: 100%;
					height: 100%;
					text-align: center;
					padding-top: 3px;
					@include milo-regular($font-size-16, 18px);

					// exception for .mm-active
					@include if-parent('.m-basic-progress-nav-form li.mm-active .mm-digit') {
						@include milo-slab-bold($font-size-16, 18px);
					}
				}
			}

			.mm-text {
				position: absolute;
				width: 100%;
				display: inline-block;
				padding: 0 5px 0 15px;

				@include mq(desktop) {
					position: relative;
				}

				> .mm-text-inner {
					position: absolute;
					white-space: nowrap;
					left: 50%;
					transform: translateX(-50%);
					margin-top: 5px;

					@include mq(desktop) {
						position: relative;
						white-space: normal;
						left: 0;
						transform: translateX(0);
					}

					> .mm-item-text {
						font-size: 13px;

						@include mq(desktop) {
							font-size: 16px;
						}

						> br {
							display: none;

							@include mq(desktop) {
								display: block;
							}
						}
					}

					> .mm-mobile-text {
						@include mq(desktop) {
							display: none;

						}
					}
				}

				> .mm-mobile-styling {
					position: absolute;
					white-space: nowrap;
					padding-right: 15px;
					transform: translateX(-50%);
					margin-top: 17px;
				}
			}

			.mm-no-padding {
				padding: 0;
			}

			> .mm-line {
				flex: 1;
				height: 1px;
				background-color: $color-secondary-16;
				margin-left: 5px;

				@include mq(desktop) {
					min-width: 30px;
					margin-left: 10px;
				}
			}
		}
	}
}