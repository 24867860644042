/* m-adac-maps */
.m-adac-maps {
	position: relative;
	display: flex;
	flex-flow: column nowrap;

	.ll-main-cols-m & {
		min-height: 100%;
	}

	&.h-visuallyhidden {
		margin: 0;
		padding: 0;
	}

	&--small {
		max-width: 517px;
	}

	> .mm-maps-nav {

		@include mq(tablet) {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			margin-bottom: 20px;
		}

		.ll-description {
			width: 307px;
			max-width: 307px;
			margin-right: 10px;
		}
	}

	> .mm-switch-map {
		display: none;

		> a {
			float: right;
			position: relative;
			width: 24px;
			height: 30.3906px;

			&::before {
				content: '';
				position: absolute;
				top: -10px;
				left: -10px;
			}

			&.icon-maps {
				&::before {
					@include main-icon-map;
				}
			}

			&.icon-list {
				&::before {
					@include main-icon-list;
				}
			}

			> svg {
				display: none;
			}
		}
	}

	.mm-map-wrapper {
		position: relative;
		padding-bottom: 95%;
		height: 0;
		min-height: 200px;
		overflow: hidden;

		@include mq(tablet) {
			min-height: 350px;
			padding-bottom: 39%;
		}

		img {
			width: 100%;
		}

		// exception for cardamage-confirmation
		.mm-right & {
			@include mq(tablet) {
				min-height: 200px;
				padding-bottom: 56%;
			}
		}

		.ll-aside & {
			padding-bottom: 75%;

			@include mq(tablet) {
				padding-bottom: 56%;
			}

			@include mq(desktop) {
				min-height: 233px;
				padding-bottom: 82%;
			}
		}
	}

	> .mm-user-location-wrap {
		> .mm-user-location {
			display: block;
			margin-right: 15px;
			margin-bottom: 10px;
			padding: 0;

			@include mq(tablet) {
				display: inline-block;
			}

			> li {

				@include mq(tablet) {
					display: inline-block;
				}
			}
		}
	}

	// @EW72 fyi:
	// no direct child selector on purpose bc element might be wrapped via Backend
	.mm-result-list-wrap {
		background-color: $color-secondary-7;
		border: 1px solid $color-secondary-9;
		max-width: 320px;

		@include mq(tablet) {
			max-height: 147px;
			max-width: 307px;
			overflow: hidden;
		}

		.mm-result-list {
			margin: 0 0 10px 0;
			padding: 10px;
			position: relative;

			li {
				min-height: 95px;
			}

			.mm-pin + .m-basic-text {
				padding-right: 35px;
				min-height: 75px;
			}

			.mm-pin {
				@include main-icon-place-light-grey-36px;
				@include absolute(-5px, $right: -17px);
				text-align: center;
				padding-top: 45px;

				&.is-active {
					@include main-icon-place-yellow-36px;
				}

				> span {
					display: inline-block;
					width: 35px;
				}
			}
		}
	}

	> .mm-location {
		width: 100%;
		margin-bottom: 5px;
		padding: 15px 20px;

		@include font-size($font-size-16);
		background: $color-secondary-6;

		// if there is no map caption
		&:last-child {
			margin-bottom: 20px;
		}

		.ll-main-cols-m & {
			@include mq('tablet') {
				margin-bottom: 0;
			}
		}

		> .mm-location-head {
			@include milo-bold();
			display: block;

			.h-roboto & {
				font-family: 'Roboto Bold', sans-serif;
			}
		}

		> .mm-location-text {
			display: block;
			max-width: 170px;

			@include mq(tablet) {
				max-width: 100%;
			}
		}
	}
}