/* m-basic-list--link */
.m-basic-list--link {

	// exception m-basic-newsbox
	.m-basic-newsbox & {
		margin-bottom: 13px;
		padding-bottom: 0;
	}

	> li {
		margin-bottom: 8px;
		padding: 0 0 0 14px;

		@include mq(desktop) {
			padding: 0 0 0 18px;

			.m-basic-toggle--directory & {
				padding: 0 0 0 15px;
			}
		}

		.m-ds-result & {
			padding: 0 0 0 20px;
		}

		&:before {
			content: '';
			display: inline-block;
			@include main-icon-arrow-right-black;
			width: 31px;
			height: 31px;
			position: absolute;
			top: 0;
			left: -10px;

			@include mq(desktop) {
				top: -3px;
			}

			.m-basic-toggle--directory & {
				//was specifically asked for this way. Will probably be claimed as bug later
				@include main-arrow-bottom;
				top: -9px;
				left: -20px;
			}

			.m-ds-result & {
				top: -4px;
			}

			.m-basic-newsbox & {
				@include main-icon-arrow-right-grey;
				width: 31px;
				height: 31px;
				left: -15px;
			}
		}

		.oo-flex-box-content & {
			margin: 0;
		}

		.l-outer--my-adac-content &,
		.l-outer--my-adac & {
			background: none;
			padding: 0;
			position: static;
			margin: 0;
		}

		@include mq(print) {
			background: none;

			&:before {
				width: 0;
				height: 0;
				border-top: 7px solid transparent;
				border-left: 7px solid #CC3300;
				border-bottom: 7px solid transparent;
				content: "";
				display: inline-block;
				position: absolute;
				top: 5px;
				left: -2px;
			}
		}

		> a {
			text-decoration: none;
			display: inline-block;
			padding: 4px;

			@include mq(desktop) {
				padding: 0;
			}

			&:hover {
				text-decoration: underline;
			}

			.l-outer--my-adac & {
				display: flex;
				flex-flow: row-reverse;
				justify-content: flex-end;
				padding: 10px 25px 10px 46px;
				position: relative;
				@include font-size(18px, 26px);

				&:after {
					position: absolute;
					content: '';
					display: block;
					@include main-icon-arrow-right-grey;
					width: 31px;
					height: 32px;
					right: 0;
					top: 12px;
				}

				&:hover {
					text-decoration: none;
				}

				&:visited {
					color: $color-secondary-1;
				}
			}
		}

		> .js-regionalclubinfo {

			> p {
				margin: 0;
			}

			> p > a,
			> a {
				display: block;
				width: 100%;
				margin: 0 -8px 0 -8px;
				padding: 10px 25px 10px 48px;
				position: relative;
				@include font-size(18px, 26px);
				text-decoration: none;

				.l-outer--my-adac-content & {
					background: none;
					margin: 0;
				}

				&:before {
					position: absolute;
					content: '';
					display: block;
					@include main-icon-pdf;
					width: 40px;
					height: 42px;
					left: -4px;
					top: 4px;

					// exception for my-adac-content
					.l-outer--my-adac-content & {
						left: -4px;
						@include main-icon-pdf-grey;
						height: 31px;
					}

					.l-outer--my-adac-dashboard & {
						@include main-icon-pdf-grey;
						top: 0;
						left: -8px;
					}
				}

				&:after {
					position: absolute;
					content: '';
					display: block;
					right: -12px;
					top: 2px;
					@include main-icon-arrow-right-grey;

					// exception for my-adac-content
					.l-outer--my-adac-content & {
						right: -8px;
						top: 2px;
						@include main-icon-arrow-right-grey2;
					}

					.l-outer--my-adac-dashboard & {
						@include main-icon-arrow-right-grey2;
					}
				}

				&:hover {
					text-decoration: none;
				}

				&:visited {
					color: $color-secondary-1;
				}
			}
		}

		// exception for m-vehicles-results
		.m-vehicles-results & {
			margin-bottom: 0;
		}
	}

	.l-outer--my-adac .m-my-adac-dashboard-box & {
		li {
			padding: 8px 0 8px 0px;

			.a-basic-link {
				margin: 0;

				&:before {
					left: -12px;
				}

				&:after {
					right: -16px;
					top: 1px;
				}
			}
		}
	}
}