/* m-basic-keywordfilter--filter */
.m-basic-keywordfilter--filter {
	position: relative;
	z-index: 20;
	vertical-align: top;

	@include mq('tablet') {
		width: 100%;
	}

	.mm-searchbar {
		top: 1px;
		z-index: 20;
		width: calc(100% - 122px);
		float: left;

		@include mq('tablet') {
			width: 228px;
		}
	}

	.mm-controller {
		display: block;
		position: relative;
		float: left;
		width: 122px;
		height: 36px;
		margin-top: 0;
		vertical-align: top;
		z-index: 10;

		> button {
			position: relative;
			top: 1px;
			padding: 7px 8px 6px 20px;
			text-align: left;

			&:before {
				content: '';
				@include main-icon-link;
				position: absolute;
				left: -10px;
				top: -4px;

				@include if-parent('button.is-active') {
					@include main-icon-arrow-drop-down;
					left: -11px;
				}
			}

			&.is-active {
				background-color: $color-primary-2;
				width: 122px;
				border-top: 1px solid $color-secondary-4;
				border-right: 1px solid $color-secondary-4;

				&:before {
					top: -3px;
				}
			}

			&:focus {
				outline: none;
			}
		}
	}

	.mm-filter-col-wrapper {
		display: flex;
		justify-content: space-between;
	}

	.mm-filter {
		display: none;
		clear: both;
		position: relative;
		top: -6px;
		width: 100%;
		padding: 10px 20px;
		background: $color-primary-2;
		z-index: 9;
		border: 1px solid $color-secondary-4;

		&--columns {
			display: flex;
			flex-flow: column nowrap;

			@include mq(tablet) {
				flex-flow: row nowrap;
			}
		}

		.mm-filter-col {
			position: relative;
			padding: 20px 0;
			margin: 0;

			@include mq(tablet) {
				padding: 0 20px;
				width: auto;
				min-width: 30%;
			}

			&--first {

				padding-top: 0;

				@include mq(tablet) {
					padding-left: 0;
				}
			}

			&--last {
				padding-bottom: 0;

				@include mq(tablet) {
					padding-right: 0;
				}
			}

			&--columnborder {
				border-bottom: 1px solid $color-secondary-5;

				@include mq(tablet) {
					border-left: 1px solid $color-secondary-5;
					border-bottom: 0;
				}

				&.mm-filter-col--first {
					border-left: 0;
				}

				&.mm-filter-col--last {
					border-bottom: 0;
				}
			}
		}

		> .mm-filter-col-wrapper {
			display: flex;
			flex-flow: column nowrap;
			width: 100%;

			@include mq(tablet) {
				width: 50%;
			}

			@include mq(desktop) {
				flex-flow: row wrap;
				width: 70%;
			}

			&:first-child {
				@include mq(desktop) {
					width: 30%;
				}
			}

			&:only-child {
				width: 100%;
			}

			> .mm-filter-col {
				padding: 0;
				margin-bottom: 9px;

				&--first {
					order: -1;

					.mm-col-head--spacer {
						@include mq(tablet) {
							display: inline-block;
							height: 20px;
						}
					}
				}

				&--1 {
					order: 3;

					.mm-col-head--spacer {
						@include mq(desktop) {
							display: inline-block;
							height: 20px;
						}
					}
				}

				&--2 {
					order: 2;
				}

				&--last {
					order: 99;
				}

				&:only-child {
					@include mq(desktop) {
						width: auto;
					}
				}

				@include mq(desktop) {
					order: 0;
					width: 50%;
				}
			}
		}

		li {
			@extend %cf;
			margin: 0 0 9px 0;

			&:last-child {
				margin-bottom: 0;
			}

			&.mm-separator ~ .mm-separator {
				padding-bottom: 4px;
				border-bottom: 1px solid $color-secondary-5;
			}

			&.mm-child {
				margin-left: 25px;
			}

			> .mm-col-head {
				display: inline-block;
				width: 100%;
				margin-bottom: 5px;
				@include milo-bold();

				&--spacer {
					display: none;
					margin: 0;
				}
			}

			> .mm-filtergroup {
				@extend %cf;
				padding: 0;
				border-bottom: 0;

				@include mq(tablet) {
					display: flex;
				}

				> .mm-filtergroup-head {
					padding-bottom: 5px;
					font-weight: normal;

					@include mq(tablet) {
						display: flex;
						width: 200px;
					}
				}

				// if filter-group is followed by another elem, e.g. mm-filter-button;
				&:not(:last-child) {
					margin-bottom: 10px;
				}
			}

			.mm-filter-button {
				float: right;

				@include mq(tablet) {
					float: none;
				}
			}
		}
	}
}