/* a-basic-link--my-adac */
.a-basic-link--my-adac {
	position: relative;
	width: 100%;
	margin: 0;
	padding: 10px 25px 10px 48px;
	background: none;
	text-decoration: none;
	display: block;
	outline: none;
	@include font-size(18px, 26px);

	&:before {
		content: none;

		// exception .m-basic-header
		.m-basic-header > .mm-navi-wrap > .mm-main > .mm-main-btn > .mm-section > .mm-layer & {
			content: none;
		}
	}

	&:after {
		position: absolute;
		content: '';
		display: block;
		@include main-icon-arrow-right-grey2;
		right: -16px;
		top: 1px;

		//exception dashboard
		.m-my-adac-dashboard-box--block & {
			@include main-icon-arrow-right-black;
		}

		// exception for the top/bottom site-links - Zurück zu Mein ADAC
		@include if-parent('.a-basic-link--my-adac.a-basic-link--my-adac-back') {
			position: static;
			display: none;
		}

		// exception for
		@include if-parent('.a-basic-link--my-adac.a-basic-link--my-adac-bank') {
			position: static;
			display: none;
		}

		@include if-parent('.a-basic-link--my-adac.a-basic-link--my-adac-no-arrow') {
			display: none;
		}

		// exception for a myAdac-Link is in a toggle element
		.mm-toggle-content & {
			right: 0;
		}

		// exception for a myAdac-content-sites is in a toggle element
		.l-outer--my-adac-content & {
			right: -8px;
		}

		// exception for a myAdac-theme-option-layer is in a toggle element
		.mm-dashbox-theme-option-layer & {
			display: none;
		}

		// exception .m-basic-header
		.m-basic-header > .mm-navi-wrap > .mm-main > .mm-main-btn > .mm-section > .mm-layer & {
			top: 0;
			right: -10px;
		}

		// exception .m-basic-hint -> horizontal orientation
		.m-basic-hint .mm-flex-container & {
			display: none;
		}
	}

	&:hover {

		// exception .m-basic-header
		.m-basic-header > .mm-navi-wrap > .mm-main > .mm-main-btn > .mm-section > .mm-layer & {
			background: $color-secondary-19;

			&:after {
				background-color: transparent;
				top: 8px;
			}
		}
	}

	&:visited {
		color: $color-secondary-1;
	}

	// exception .m-basic-hint -> horizontal orientation
	.m-basic-hint .mm-flex-container & {
		width: fit-content;
		min-width: initial;
		padding: 10px 0 10px 40px;
		font-size: $font-size-16;
	}

	// exception for the top/bottom site-links - Zurück zu Mein ADAC
	&.a-basic-link--my-adac-back {
		@include font-size(16px, 20px);
		padding: 10px 20px 10px 45px;

		&:before {
			position: absolute;
			content: '';
			display: inline-block;
			@include main-icon-arrow-right-grey2;
			left: -7px;
			transform: rotate(-180deg);
			top: -2px;

			// exception for theme-option-layer
			.mm-dashbox-theme-option-layer & {
				transform: translate(0, -50%) rotate(0deg);
				top: 20px;
			}
		}
	}

	//exception dashboard
	.m-my-adac-dashboard-box--block & {
		@include mq(tablet) {
			width: auto;
			display: inline-block;
		}
	}

	&.a-basic-link--mailto {
		display: flex;

		.m-my-adac-dashboard-box--block & {
			display: inline-flex;
		}

		> .aa-info-icon {
			width: 15px;
			height: 15px;
			display: inline-block;
			cursor: pointer;
			@include main-icon-info-grey;
			background-position: center;
			background-repeat: no-repeat;
			background-size: 15px;
			margin-left: 5px;

			.m-my-adac-dashboard-box--block &,
			.l-outer--my-adac-dashboard & {
				@include main-icon-info-grey2;
				transform: scale(0.6);
				margin-left: 0;
				top: -9px;
				position: relative;
				left: -5px;
			}
		}
	}

	> .aa-link-icon-wrapper,
	> svg {
		position: absolute;
		left: 0;
		top: 6px;
		width: 24px;
		height: 24px;
		background-size: 100%;
		max-height: 45px;

		&--account-settings {
			left: -8px;
			@include main-icon-account-settings-variant-grey;
			top: 2px;
		}

		&--pdf {
			@include main-icon-pdf-grey;
			left: -10px;
			top: 2px;
		}

		&--plus {
			@include main-icon-plus-grey;
			left: -14px;
			top: 1px;
		}

		&--plus-circle {
			@include my-adac-icon-add-circle;
			left: -14px;
			top: 0;
		}

		// http://localhost:8081/pages-myadac-examples-dashboard?default=1 Optionen
		&--description {
			@include main-icon-description-grey;
			left: -12px;
			top: -1px;
		}

		&--arrow {
			@include main-icon-arrow-right-grey2;
			top: 1px;
			left: -12px;
		}

		&--link {
			@include main-icon-link-grey2;
			left: -12px;
			top: 1px;
		}

		&--list {
			@include main-icon-list-grey2;
			left: -12px;
			top: 1px;
		}

		&--assignment-turned-in {
			@include main-icon-assignment-turned-in-grey;
			left: -12px;
			top: 0;
		}

		&--assignment {
			@include main-icon-assignment-grey;
			left: -12px;
			top: 1px;
		}

		&--drive-file {
			@include main-icon-drive-file-grey;
			left: -12px;
			top: 0;
		}

		&--email {
			@include main-icon-email-grey2;
			left: -12px;
			top: 0;
		}

		&--event {
			@include main-icon-event-grey2;
			left: -12px;
			top: 0;
		}

		&--info {
			@include main-icon-info-grey2;
			left: -12px;
			top: 0;
		}

		&--report {
			@include main-icon-report-problem-grey;
			left: -12px;
			top: -2px;
		}

		&--print {
			@include main-icon-print-grey2;
			left: -12px;
			top: 0;
		}

		&--breakdown-services {
			@include main-icon-breakdown-services-grey;
			left: -12px;
			top: 0;
		}

		&--map {
			@include main-icon-map-grey;
			left: -12px;
			top: 0;
		}

		&--card {
			@include main-payment-grey;
			left: -12px;
			top: 0;
		}

		&--cancle {
			@include my-adac-icon-cancle-grey;
			left: -12px;
			top: 0;
		}

		&--search {
			@include main-icon-search-grey2;
			left: -12px;
			top: 0;
		}

		&--gift {
			@include main-icon-gift-grey;
			left: -12px;
			top: 2px;
		}

		&--calculate {
			@include my-adac-icon-calculate-grey;
			left: -12px;
			top: 0;
		}

		&--gas-station {
			@include my-adac-local_gas_station-grey2;
			top: -1px;
		}

		&--car {
			@include header-icon-car-grey;
			left: -5px;
			top: 1px;

			@include mq(tablet) {
				top: -2px;
			}
		}

		&--car-damage {
			@include header-icon-car-accident-grey;
			top: 1px;
			left: -5px;

			@include mq(tablet) {
				top: -2px;
			}
		}

		&--schadenmeldung {
			@include header-icon-schadenmeldung;
			top: 1px;
			left: -5px;

			@include mq(tablet) {
				top: -2px;
			}
		}

		&--travel {
			@include header-icon-travel-grey;
			top: 1px;
			left: -11px;
		}

		&--route {
			@include main-icons-route;
			top: 1px;
			left: -11px;
		}

		&--mfa {
			@include main-icon-mfa;
			top: -1px;
			left: -11px;
			transform: scale(0.6);
		}

		&--person {
			@include header-icon-login-circle-grey;
			top: 2px;
			left: -5px;

			@include mq(tablet) {
				top: -2px;
				left: -5px;
			}
		}

		&--membership {
			@include header-icon-member-id-grey;
			left: -5px;
			top: 2px;

			@include mq(tablet) {
				top: -2px;
			}
		}

		&--shield {
			@include header-icon-shield-grey;
			top: 1px;
			left: -5px;

			@include mq(tablet) {
				top: -3px;
				left: -5px;
			}
		}

		&--logout {
			@include header-icon-logout;
			top: 0;
			left: -12px;
		}

		&--delete {
			@include my-adac-icon-delete-grey;
			left: -12px;
			top: 0;
		}

		&--carrental {
			@include damage-declaration-icon-panne-schluessel-grey;
			left: -12px;
			top: 0;
		}

		&--clock {
			@include damage-declaration-icon-schedule;
			left: -12px;
			top: 0;
		}

		&--hotel {
			@include damage-declaration-icon-hotel-grey;
			left: -12px;
			top: 0;
		}

		&--transport {
			@include damage-declaration-icon-panne-ruecktransport-grey;
			left: -12px;
			top: 0;
		}

		&--delete-forever {
			@include my-adac-icon-delete-forever;
			top: 0;
		}

		&--edit {
			@include my-adac-icon-edit-grey;
			left: -12px;
			top: 0;
		}

		&--placeholder {
			@include main-icon-placeholder;
			width: 33px;
			height: 28px;
		}

		&--backspace {
			@include main-icon-backspace-grey;
			left: -9px;
		}

		&--thumb {
			@include main-icon-thumbs-up-transparent;
			left: -9px;
			top: -1px;
		}

		// exception for a myAdac-content-sites is in a toggle element
		.l-outer--my-adac-content & {
			left: -4px;

			&--delete-forever {
				left: -14px;
			}

			&--plus-circle {
				left: -14px;
			}

			&--edit {
				left: -14px;
			}
		}
	}

	// exception for a myAdac-theme-option-layer is in a toggle element
	.mm-dashbox-theme-option-layer & {
		@include font-size(16px, 20px);
	}

	// exception for a myAdac-Link is in a toggle element
	.mm-toggle-content & {
		padding-right: 40px;
		margin: 0;
	}

	// exception for my-adac content site´s
	.l-outer--my-adac-content & {
		min-width: 280px;
		max-width: 650px;
		width: 100%;
		margin: 0;
	}

	.m-ves-rs-contentbox & {
		max-width: 370px;
	}

	// exception .m-basic-header
	.m-basic-header > .mm-navi-wrap > .mm-main > .mm-main-btn > .mm-section > .mm-layer & {
		margin: 0;
		padding: 12px 12px 12px 45px;
		@include font-size($font-size-16, 24px);
		background: none;

		@include mq(tablet) {
			padding: 8px 8px 8px 35px;
		}
	}
}