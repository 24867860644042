// .m-basic-teaser-dashboard
.m-basic-teaser-dashboard {
	background: $color-secondary-8;

	@include mq(tablet) {
		width: calc(50% - 5px);
	}

	@include mq(desktop) {
		width: auto;
		max-width: 100%;
	}

	.ll-dashboard & {
		position: relative;
		padding-bottom: 20px;

		@include mq(tablet) {
			width: 50%;
			border-left: 10px solid $color-primary-2;
		}

		@include mq(desktop) {
			width: calc(100% / 3);
		}
	}

	.ll-dashboard > .ll-dashboard-left & {
		@include mq(tablet) {
			width: calc(50%);
		}

		@include mq(desktop) {
			width: calc(100%);
		}
	}

	.ll-dashboard > .ll-dashboard-right & {
		@include mq(tablet) {
			width: calc(50%);
		}
	}

	&--full {
		@include mq(tablet) {
			flex-basis: 100%;
		}

		@include mq(desktop) {
			max-width: 100%;
		}
	}

	&--row {
		@include mq(tablet) {
			width: 100%;
		}

		@include mq(desktop) {
			max-width: 100%;
		}

		.m-basic-teaser-dashboard {
			background: $color-primary-2;
		}
	}

	> .mm-headline {
		background: $color-secondary-8;
		box-shadow: inset 0 0 0 1000px $color-secondary-8;
		padding: 10px 10px 9px 16px;

		> .mm-headline-text {
			padding: 0;
			@include milo-slab-bold($font-size-19);

			.h-roboto & {
				font-family: 'Roboto Bold', sans-serif;
			}
		}
	}

	> a > .mm-figure,
	> .mm-figure {
		margin: 0;
		padding: 0;
		border-top: 1px solid $color-primary-2;
		border-bottom: 1px solid $color-primary-2;

		> img {
			width: 100%;
			vertical-align: middle;

			@include mq(tablet) {
				max-width: 100%;
			}
		}
	}

	> .mm-content {
		background: $color-secondary-8;
		padding: 17px 15px 60px 15px;

		.ll-col-dashboard--full & {
			position: static;
		}

		> .mm-subheadline {
			@include milo-bold($font-size-16);

			.h-roboto & {
				font-family: 'Roboto Bold', sans-serif;
			}
		}

		@include if-parent('.m-basic-teaser-dashboard.m-basic-teaser-dashboard--full') {

			@include mq(tablet) {
				padding-right: 20px;
			}

			> .js-basic-table-wrap {
				margin: -46px -10px 0 1px;
			}
		}

		@include if-parent('.m-basic-teaser-dashboard.m-basic-teaser-dashboard--row') {
			display: block;
			background: $color-primary-2;
			padding-left: 0;
			padding-right: 0;

			> .mm-content-teaser {
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;

				> .m-basic-teaser-dashboard {
					width: 50%;
					padding-right: 5px;
					padding-left: 5px;

					&:nth-child(odd) {
						padding-left: 0;
					}

					&:nth-child(even) {
						padding-right: 0;
					}


					@include mq(tablet) {
						width: 25%;
						min-width: 125px;
						padding-right: 10px;
						padding-left: 0;

						&:nth-child(even) {
							padding-right: 10px;
						}

					}

					.a-basic-btn {
						width: 100%;
					}
				}
			}

			.mm-content {
				padding-left: 0;
				padding-right: 0;
				padding-bottom: 0;
				background: $color-primary-2;
			}
		}

		> .mm-rating {
			border-bottom: 1px solid $color-secondary-5;
			margin: 0 -10px;
			padding: 10px 30px 10px 40px;

			&--first {
				border-top: 1px solid $color-secondary-5;
			}

			&--last {
				margin-bottom: 20px;
			}

			> p {
				margin: 0;
				position: relative;

				&:before {
					width: 21px;
					height: 21px;
					color: $color-primary-2;
					font-weight: bold;
					top: 0;
					left: -27px;
					position: absolute;
					text-align: center;
				}

				@include if-parent('.mm-rating.mm-rating--pro') {
					&:before {
						content: '+';
						background: $color-rating-1;
						line-height: 20px;
					}
				}

				@include if-parent('.mm-rating.mm-rating--contra') {
					&:before {
						content: '\2013';
						background: $color-rating-5;
						line-height: 20px;
					}
				}
			}
		}
	}

	&--nobutton {

		> .mm-content {
			padding-bottom: 0 !important;

			.m-basic-text {
				.mm-content {
					padding-bottom: 0 !important;
				}

				.mm-flex {
					.mm-aside {
						> figure {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}