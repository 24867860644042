/* m-basic-list--yellow */
.m-basic-list--yellow {

	li {
		position: relative;
		height: auto;
		margin: 0 0 0 10px;
		padding: 4px 0 4px 30px;
		background: none;
		@include font-size(16px, 20px);

		&:hover {
			text-decoration: none;
		}

		&:before {
			position: absolute;
			width: 8px;
			height: 8px;
			top: 10px;
			left: 0;
			content: '';
			background-color: $color-secondary-17;
			border-radius: 50%;
		}

		// exception for .o-compare-product--dynamic
		.o-compare-product--dynamic & {
			list-style: none;
			margin-left: 0;
			word-wrap: break-word;
			hyphens: auto;
		}
	}

}